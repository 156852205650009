import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as ls from 'local-storage';

import checkAccess from './checkAccess';

import * as act from 'redux/actions/common/userActions';

// import * as actUI from 'redux/actions/live/uiActionsLive';

import NoAccess from 'components/app/views/noAccess';

function PrivateRoute({ component: Component, requiredRole, ...rest }) {
    const dispatch = useDispatch();
    const isInStarterPlan = rest?.isInStarterPlan;

    return (
        <Route
            {...rest}
            render={(props) => {
                const auth = ls.get('auth');
                const sponsorAuth = ls.get('sponsorAuth');
                const sponsorTempAuth = ls.get('sponsorTempAuth');
                const user = rest?.user;

                // venue feature
                if (
                    sponsorTempAuth &&
                    window.location.pathname.includes(
                        '/sponsor/companyProfiles'
                    )
                ) {
                    return (
                        <Component
                            {...props}
                            isInStarterPlan={isInStarterPlan}
                            user={user}
                        />
                    );
                }

                if (sponsorAuth && window.location.pathname === '/') {
                    return (
                        <Redirect
                            to={{
                                pathname: '/sponsor/companyProfiles',
                            }}
                        />
                    );
                }

                if (auth || sponsorAuth) {
                    const role = user.role;
                    const checkAccessControl = checkAccess(role, rest);

                    // Switch Event
                    // Lets dipatch our menu's here
                    // if(user?.roleId === 4) {
                    //     dispatch(actUI.enableSwitchEvent())
                    // } else {
                    //     // show normal menu
                    //     dispatch(actUI.disabledSwitchEvent())
                    // }

                    if (sponsorAuth) {
                        window.SPONSOR_AUTH = sponsorAuth;
                    }

                    if (checkAccessControl) {
                        dispatch(
                            act.setRestrictAccess(
                                checkAccessControl.restrictAccess
                            )
                        );

                        if (checkAccessControl.isUserAuthorised) {
                            return (
                                <Component
                                    {...props}
                                    isInStarterPlan={isInStarterPlan}
                                    user={user}
                                />
                            );
                        } else {
                            return <NoAccess />;
                        }
                    } else {
                        dispatch(act.setRestrictAccess(null));

                        return (
                            <Component
                                {...props}
                                isInStarterPlan={isInStarterPlan}
                                user={user}
                            />
                        );
                    }
                }

                return (
                    <Redirect
                        to={{
                            pathname: window.location.pathname.includes(
                                'sponsor'
                            )
                                ? '/sponsor/login'
                                : '/login',
                        }}
                    />
                );
            }}
        />
    );
}

export default PrivateRoute;
