import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import GeneralPreloader from 'components/app/common/preloader/generalPreloader';
import './userList.scss';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Select } from 'antd';
import { updateActivation } from 'apis/rest/LeadsUsers';
import InviteUsers from '../inviteUsers';

const { Option } = Select;
const selectOptions = [
    {
        label: 'All users',
        value: 'all',
    },
    {
        label: 'Active users',
        value: 'activated',
    },
    {
        label: 'Deactivated users',
        value: 'deactivated',
    },
];
function UserList(props) {
    const {
        showAlert,
        users,
        handleGetLeadsUsers,
        isBusy,
        projectId,
        disableActivate,
        setModalVisibility,
        setModalView,
        leadsData,
    } = props;
    const [filter, setFilter] = useState('');

    const userMenu = ({ isActive, id }) => (
        <Menu>
            <Menu.Item
                onClick={() =>
                    handleActivation({
                        id,
                        status: isActive ? 'suspend' : 'activate',
                    })
                }
                disabled={!isActive && disableActivate}
            >
                {isActive ? 'Deactivate' : 'Activate'}
            </Menu.Item>
        </Menu>
    );
    /**
     *
     * @param {status} activate/suspend
     * @param {id} user id
     */
    const handleActivation = async ({ status, id }) => {
        try {
            const resp = await updateActivation({ projectId, id, status });
            if (resp?.status) {
                showAlert(resp?.message, 'success');
                handleGetLeadsUsers();
            } else showAlert(resp?.message, 'error');
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * renders the table rows
     * @param {object} user user object to be rendered per row
     * @param {number} index user row index
     */
    const renderRows = (user, index) => {
        return (
            <Fragment key={index}>
                <tr key={user.id}>
                    <td>
                        {user.firstname || '-'} {user.lastname}
                    </td>
                    <td>{user.email || '-'}</td>
                    <td>{user.phoneNo || '-'}</td>
                    <td
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <p>
                            {user.isSuspended ? 'Deactivated' : 'Activated'}
                            <br />
                            <span className="text-subtle text-small">
                                Invited by {user.invitationType} on{' '}
                                {moment(user.createdAt).format('DD MMM YYYY')}
                            </span>
                        </p>
                        <span className="dotContainer">
                            <Dropdown
                                overlay={userMenu({
                                    isActive: !user.isSuspended,
                                    id: user.id,
                                })}
                            >
                                <EllipsisOutlined
                                    style={{ fontSize: '20px' }}
                                />
                            </Dropdown>
                        </span>
                    </td>
                </tr>
            </Fragment>
        );
    };
    /**
     * filters `users` array based from status
     */
    const filteredElements = () => {
        if (filter !== '') {
            return users
                .filter((user) => {
                    switch (filter) {
                        case 'activated':
                            return !!user.isSuspended === false;
                        case 'deactivated':
                            return !!user.isSuspended === true;
                        default:
                            break;
                    }
                })
                .map((filteredUser, index) => renderRows(filteredUser, index));
        }
        return users.map((filteredUser, index) =>
            renderRows(filteredUser, index)
        );
    };

    /**
     * counts the number of users depending on filter
     * @param {string} filter type of filter to use for counting
     */
    const countFilter = (filter) => {
        if (filter !== '') {
            switch (filter) {
                case 'activated':
                    return users.filter((user) => !!user.isSuspended === false)
                        .length;
                case 'deactivated':
                    return users.filter((user) => !!user.isSuspended === true)
                        .length;
                default:
                    break;
            }
        } else {
            return users.length;
        }
    };
    const renderOptions = () => {
        if (selectOptions?.length) {
            return selectOptions.map(({ label, value }, index) => (
                <Option key={value} value={value}>
                    <span>{label}</span>
                </Option>
            ));
        }
    };
    return (
        <>
            <div className="tw-w-full tw-mb-5 tw-block md:tw-hidden ">
                <Select
                    showSearch
                    className="selectMobileView"
                    allowClear={false}
                    value={filter || 'all'}
                    onChange={(val) => {
                        switch (val) {
                            case 'all':
                                setFilter('');
                                break;
                            case 'activated':
                                setFilter('activated');
                                break;
                            case 'deactivated':
                                setFilter('deactivated');
                                break;
                            default:
                                break;
                        }
                    }}
                    style={{ width: '100%' }}
                >
                    {renderOptions()}
                </Select>
            </div>
            <style>{`
                @media screen and (max-width: 640px) {
            .selectMobileView .ant-select-selection-item {
                display: flex !important;
                align-items: center !important;
            }
            .selectMobileView .ant-select-selector {
                border-radius: 9px !important;
                height: 44px !important;
            }
        }
            `}</style>
            <div className="user-list">
                {isBusy && <GeneralPreloader />}

                {!isBusy && users.length > 0 && (
                    <>
                        <ul className="user-list__filters tw-hidden md:tw-flex">
                            <li
                                onClick={() => {
                                    setFilter('');
                                }}
                                className={
                                    filter === ''
                                        ? 'user-list__filter--active'
                                        : ''
                                }
                            >
                                All Users ({countFilter('')})
                            </li>{' '}
                            <li
                                onClick={() => {
                                    setFilter('activated');
                                }}
                                className={
                                    filter === 'activated'
                                        ? 'user-list__filter--active'
                                        : ''
                                }
                            >
                                Activated Users ({countFilter('activated')})
                            </li>
                            <li
                                onClick={() => {
                                    setFilter('deactivated');
                                }}
                                className={
                                    filter === 'deactivated'
                                        ? 'user-list__filter--active'
                                        : ''
                                }
                            >
                                Deactivated ({countFilter('deactivated')})
                            </li>
                        </ul>

                        <div className="user-list__table-wrap">
                            <table className="user-list__table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone No.</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>

                                <tbody>{filteredElements()}</tbody>
                            </table>
                        </div>
                    </>
                )}

                {!isBusy && users.length === 0 && (
                    <>
                        <div className="panel-empty tw-hidden md:tw-block">
                            <h2>No invited or active users</h2>

                            <p>Get started by inviting your users.</p>
                        </div>
                        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-hidden tw-py-8 ">
                            <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
                                <h2>No invited or active users</h2>
                                <p className="tw-text-text-gray-dark md:tw-text-black">
                                    Get started by inviting your users.
                                </p>
                            </div>
                            <InviteUsers
                                setModalVisibility={setModalVisibility}
                                setModalView={setModalView}
                                leadsQuota={leadsData.quota}
                                totalLeadsQuota={leadsData.total}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

UserList.propTypes = {
    showAlert: PropTypes.func.isRequired,
    handleGetLeadsUsers: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    isBusy: PropTypes.bool.isRequired,
    disableActivate: PropTypes.bool.isRequired,
};

export default withRouter(UserList);
