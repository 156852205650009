import React, { useEffect, useState } from "react";

import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function Video(props) {
  const {
    handleInputChange,
    input,
    handleFileDelete,
    prepareFileUpload,
    renderFieldError,
    validateInput,
    blockContentData = null,
    permission = null,
    preventEditName = false,
    preventEditSize = false,
    preventEditTitle = false,
  } = props;

  const showcaseblock = blockContentData?.showcaseblock
  const [permissionSize, setPermissionSize] = useState(null)
  const [permissionDimension, setPermissionDimension] = useState(null)

  useEffect(() => {
    if (permission !== permissionSize) {
      const currentPermissonSize = permission[0].dimension
      setPermissionSize(permission)
      if (currentPermissonSize) {
        Object.keys(currentPermissonSize).map(k => {
          if (showcaseblock?.name === k) {
            setPermissionDimension(currentPermissonSize[k])
          }
        })
      }

    }
  }, [permission])

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="input-set">
            <label>Block title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={showcaseblock?.title || input.title}
              disabled={preventEditTitle}
              onChange={handleInputChange}
              autoFocus
              maxLength={70}
            />
            {renderFieldError("title")}
          </div>

          {!preventEditSize &&
            <>
              <label>Block size</label>
              <div className="radio-set">
                <span>
                  <input
                    type="radio"
                    value={false}
                    name="largeTile"
                    onChange={handleInputChange}
                    defaultChecked
                    disabled={preventEditSize}

                    id="normalBlockSize"
                  />{" "}
                  <label htmlFor="normalBlockSize">Normal</label>
                </span>
                <span>
                  <input
                    type="radio"
                    value={true}
                    name="largeTile"
                    onChange={handleInputChange}
                    id="bigBlockSize"
                    disabled={preventEditSize}

                  />{" "}
                  <label htmlFor="bigBlockSize">Big</label>
                </span>
              </div>
            </>
          }
        </div>
        <div className="col-6">
          <label>
            Thumbnail{" "}
            <span className="small">(.gif, .jpg/jpeg, .png, .svg)</span>
          </label>
          <ImageUpload
            prepareFilesUpload={files => {
              prepareFileUpload(files, 'image')
            }}
            handleFileDelete={(files) => {
              handleFileDelete(files, 'image')
            }}
            imageCropRecommendedSize={{
              width: (permissionDimension &&
                permissionDimension[0]) ||
                null,
              height: (permissionDimension &&
                permissionDimension[1]) ||
                null
            }}
          />
          {permissionDimension &&
            <p>Recommended size: within {permissionDimension[0]} x {permissionDimension[1]} px</p>
          }
          {renderFieldError("image")}
        </div>
      </div>

      <label>Video URL</label>

      <p>Enter the URL of the video below.</p>

      <div className="mb-4">
        <input
          type="text"
          name="url"
          className="form-control mb-2"
          placeholder="e.g. http://www.youtube.com/watch?v=abcde"
          onChange={handleInputChange}
          maxLength={200}
        />
        {renderFieldError("url")}
      </div>
    </div>
  );
}

export default Video;
