import React from 'react';

export default function UploadErrorIcon({ stroke = '#D93024' }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99 20C4.468 19.994 -0.00399732 15.515 2.68127e-06 9.993C0.00400268 4.472 4.482 -0.00199933 10.003 6.7028e-07C15.525 0.00200067 20 4.478 20 10C19.997 15.525 15.515 20.002 9.99 20ZM2 10.172C2.02273 12.2862 2.88145 14.3054 4.38848 15.7883C5.89551 17.2712 7.92829 18.0972 10.0425 18.0859C12.1568 18.0745 14.1806 17.2267 15.6716 15.7276C17.1626 14.2286 17.9995 12.2003 17.9995 10.086C17.9995 7.97172 17.1626 5.94341 15.6716 4.44437C14.1806 2.94533 12.1568 2.09748 10.0425 2.08612C7.92829 2.07475 5.89551 2.9008 4.38848 4.38372C2.88145 5.86664 2.02273 7.88584 2 10V10.172ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
                fill={stroke || '#D93024'}
            />
        </svg>
    );
}
