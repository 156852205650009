import React from "react";
import "./eclipsePreloader.scss";
import PropTypes from "prop-types";

function EclipsePreloader(props) {
  const { style } = props;

  return (<img src="/assets/loader-eclipse.svg" alt="loading" style={style}/>);
}


EclipsePreloader.propTypes = {
  style: PropTypes.object
};

export default EclipsePreloader;
