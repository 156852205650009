import React, { useEffect, useState } from 'react';
import { establishChargbeeSession } from '../API';

export default function useChargebee(companyId) {
    const [instance, setInstance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [sessionData, setSessionData] = useState({});
    const [portals, setPortals] = useState({});
    const [error, setError] = useState('');

    const scriptAlreadyExist = () =>
        document.querySelector('script#gevme-chargebee') !== null;

    const openChargeBeeeSession = async (companyId) => {
        try {
            return await establishChargbeeSession(companyId);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!scriptAlreadyExist()) {
            const script = document.createElement('script');
            script.id = 'gevme-chargebee';
            script.src = 'https://js.chargebee.com/v2/chargebee.js';
            script.async = true;
            script.defer = true;
            script.onload = () => setScriptLoaded(true);
            document.body.append(script);
        } else setScriptLoaded(true);

        return () => {
            const cbScript = document.querySelector('script#gevme-chargebee');
            if (cbScript) document.body.removeChild(cbScript);
            if (instance?.logout) instance.logout();
        };
    }, []);

    useEffect(() => {
        const checkSessionData = async (chargeBeeInstance, companyId) => {
            try {
                const session = await openChargeBeeeSession(companyId);

                if (session && session.status) {
                    chargeBeeInstance.setPortalSession(session.portal_session);
                    setSessionData(session);
                    setInstance(chargeBeeInstance);
                    setLoading(false);
                } else throw session;
            } catch (error) {
                console.error(error?.message || error);
                setError(error?.message || error);
            }
        };

        if (scriptLoaded && window && window.Chargebee) {
            window.Chargebee.init({
                site: process.env.REACT_APP_CHARGEBEE_SITE_URL,
            });

            const chargeBeeInstance = window.Chargebee.getInstance();
            const portals = window.Chargebee.getPortalSections();

            setPortals(portals);
            checkSessionData(chargeBeeInstance, companyId);
        }

        return () => {
            if (instance?.logout) instance.logout();
        };
    }, [scriptLoaded]);

    return {
        loading,
        instance,
        sessionData,
        portals,
        error,
    };
}
