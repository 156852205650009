import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * gets the list of leads users
 */
export async function GetUser() {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/exhibitors/${user.id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } else return false;
}
