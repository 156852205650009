import React from 'react';

import './errorBoundary.scss';
import UnexpectedErrorIcon from './UnexpectedErrorIcon';

/**
 * @note there is not equivalent for `componentDidCatch()` in hooks yet so need
 * to use class-based component here first
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorDetails: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error, errorDetails: info });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary text-center">
                    <div className="error-boundary__content">
                        <div className="tw-w-screen tw-h-screen tw-flex tw-flex-row tw-gap-16 tw-justify-center tw-items-center">
                            <div className="tw-flex tw-flex-col tw-gap-1 tw-text-left tw-justify-center tw-items-start">
                                <p className="tw-text-2xl tw-font-bold tw-font-montserrat tw-text-black tw-text-left tw-m-0 tw-p-0">
                                    We are sorry, an unexpected error occured.
                                </p>
                                <p className="tw-text-xssm tw-font-semibold tw-font-openSans tw-text-black tw-text-left tw-m-0 tw-p-0">
                                    Apparently something went wrong, please
                                    refresh the page.
                                </p>
                                <div
                                    className="tw-w-fit tw-h-fit tw-text-xssm tw-font-semibold tw-font-openSans tw-text-white tw-text-left tw-bg-blue-antd tw-rounded tw-px-3 tw-py-1.5 tw-my-2 tw-mx-0 tw-cursor-pointer hover:tw-bg-blue-600"
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Refresh page
                                </div>
                                <p className="tw-text-[11px] tw-font-semibold tw-font-openSans tw-text-black tw-text-left tw-m-0 tw-p-0">
                                    If the issue persists, please contact us{' '}
                                    <a
                                        href="mailto:support@gevme.com"
                                        className="tw-text-blue-antd tw-no-underline tw-m-0 tw-p-0"
                                    >
                                        support@gevme.com
                                    </a>
                                    <br />
                                    with details about what’s causing this error
                                    to occur
                                </p>
                                {process.env.NODE_ENV !== 'production' && (
                                    <div className="tw-mt-20">
                                        <details className="mb-5">
                                            {this.state.error &&
                                                this.state.error.toString()}
                                            <br />
                                            {
                                                this.state.errorDetails
                                                    .componentStack
                                            }
                                        </details>
                                    </div>
                                )}
                            </div>
                            <div className="tw-flex tw-flex-row tw-gap-1 tw-justify-center tw-items-center">
                                <UnexpectedErrorIcon />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
