export const initialState = {
    sponsorMenuData: [
        {
            name: 'Home',
            link: 'sponsor-home',
            icon: 'home.svg',
        },
        {
            name: 'Setup',
            icon: 'settings.svg',
            sublinks: [
                {
                    name: 'Company Profile',
                    link: 'company-profile',
                    icon: '',
                },
                // {
                //     name: 'Team',
                //     link: '',
                //     icon: '',
                // },
                {
                    name: 'Booth',
                    link: 'venue-builder',
                    icon: '',
                },
                {
                    name: 'Products',
                    link: 'products',
                    icon: '',
                },
                {
                    name: 'Agenda',
                    link: 'agenda',
                    icon: '',
                },
            ],
        },
        {
            name: 'Live Streaming',
            link: 'channels',
            icon: 'streaming.svg',
        },
        // {
        //     name: 'Meetings',
        //     link: 'meetings/info',
        //     icon: 'meeting.svg',
        // },
        {
            name: 'Engagement',
            icon: 'engagement.svg',
            sublinks: [
                {
                    name: 'Chat Moderation',
                    link: 'chat-moderation',
                    icon: '',
                },
                {
                    name: 'Announcements',
                    link: 'notification/announcements',
                    icon: '',
                },
                {
                    name: 'Polls',
                    link: 'notification/polls',
                    icon: '',
                },
            ],
        },
        {
            name: 'Analytics',
            link: 'analytics',
            icon: 'analytics.svg',
        },
    ],
    sponsorMenuOpenKeys: ['Setup'],
    singleCompany: false,
    activeMenu: {},
};

function sponsorMenuReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_ACTIVE_MENU':
            return {
                ...state,
                activeMenu: action.payload,
            };
        case 'UPDATE_SPONSOR_MENU':
            return {
                ...state,
                sponsorMenuOpenKeys: action.payload.sponsorMenuOpenKeys,
            };
        case 'UPDATE_SPONSOR_MENU_DATA':
            return { ...state, sponsorMenuData: action.payload };
        case 'UPDATE_SINGLECOMPANY_SPONSOR':
            return { ...state, singleCompany: action.payload };
        default:
            return state;
    }
}

export default sponsorMenuReducer;
