import { isURL, isEmail, isNumeric } from "validator";

const showcaseInfoValidationCriteria = (input, type) => {
  const formErrors = {};

  switch (type) {
    case "callToAction":
      if (!input.callToActionValue) {
        formErrors.callToActionValue = "Please enter a Call to Action value.";
      } else {
        switch (input.callToActionAction) {
          case "phone":
            if (isNumeric(input.callToActionValue)) {
              formErrors.callToActionValue = "";
            } else {
              formErrors.callToActionValue =
                "Please enter the correct mobile number format.";
            }
            break;
          case "email":
            if (isEmail(input.callToActionValue)) {
              formErrors.callToActionValue = "";
            } else {
              formErrors.callToActionValue =
                "Please enter the correct email format.";
            }
            break;
          case "link":
            if (isURL(input.callToActionValue)) {
              formErrors.callToActionValue = "";
            } else {
              formErrors.callToActionValue =
                "Please enter a correct URL format.";
            }
            break;
          default:
            break;
        }
      }

      if (!input.callToActionText) {
        formErrors.callToActionText = "Please enter a Call to Action text.";
      }
      break;
    case "overview":
      if (!input.overviewTitle) {
        formErrors.overviewTitle = "Please enter an Overview Title.";
      }

      if (!input.overviewSummary) {
        formErrors.overviewSummary = "Please enter an Overview Summary.";
      }

      if (input.overview.length < 3) {
        formErrors.overview = "Please enter an Overview Content.";
      }
      break;
    default:
      break;
  }

  return formErrors;
};

export default showcaseInfoValidationCriteria;
