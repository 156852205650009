import * as React from 'react';

const VimeoIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={props.width || 18}
                height={props.height || 17}
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                >
                <path
                    d="M17.99 4.092c-.081 1.747-1.304 4.14-3.67 7.179-2.447 3.172-4.518 4.758-6.211 4.758-1.049 0-1.936-.967-2.662-2.903L3.995 7.802C3.457 5.866 2.879 4.898 2.26 4.898c-.135 0-.605.283-1.412.847L.002 4.656a230.06 230.06 0 002.622-2.339C3.807 1.295 4.694.757 5.285.704c1.399-.134 2.259.82 2.582 2.863.35 2.205.591 3.576.725 4.114.404 1.828.847 2.743 1.331 2.743.377 0 .941-.592 1.694-1.775.753-1.183 1.156-2.084 1.21-2.702.108-1.022-.296-1.533-1.21-1.533-.43 0-.874.094-1.33.283.887-2.877 2.58-4.275 5.081-4.195 1.855.054 2.729 1.25 2.621 3.59z"
                    fill="#000"
                />
            </svg>
        </div>
    );
};

export default VimeoIcon;
