import React from "react";
import { withRouter, Link } from "react-router-dom";

import "./noMatch.scss";

function NoMatch(props) {
  return (
    <div className="main-container no-match text-center">
      <div className="no-match__content">
        <img src="/assets/icon_404.svg" alt="404 Error" />
        <h2>Sorry, there is no such page!</h2>

        <p>
          Bring me back to my <Link to="/">Dashboard</Link>.
        </p>
      </div>
    </div>
  );
}

export default withRouter(NoMatch);
