import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Get all templates
 */
export async function getProjectTemplates({
    projectId,
    searchQuery = '',
    page = 1,
    limit = 0,
    includeGlobal = 0,
    sortBy = 'name',
    sortOrder = true,
    type = 'private',
    templateOnly = 0
}) {
    setAuthBearer();
    if (authBearer) {
        searchQuery = searchQuery.length ? `&search=${searchQuery}` : '';
        let includeGlobalQuery = `includeGlobal=${includeGlobal}`,
            limitQuery = `&limit=${limit}`,
            pageQuery = `&page=${page}`,
            sortByQuery = `&sortBy=${sortBy}`,
            sortOrderQuery = `&sortOrder=${sortOrder ? 'desc' : 'asc'}`,
            sortQuery = sortByQuery + sortOrderQuery,
            pageType = `&type=${type}`,
            isTemplateOnly = `&templateOnly=${templateOnly}`,
            buildQuery =
                includeGlobalQuery +
                limitQuery +
                pageQuery +
                searchQuery +
                sortQuery +
                pageType +
                isTemplateOnly;

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/templates?projectId=${projectId}&${buildQuery}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },

            }
        );
        return await response.json();
    }
}

/**
 * Assign a template
 */

export async function assignTemplateToLivepage({
    projectId,
    showcaseId,
    templateId,
    type = 'private',
}) {
    const payload = { templateId: templateId?.length ? templateId[0] : null, showcaseId, type }
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/change-template/${projectId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),

            }
        );
        return await response.json();
    }
}

/**
 * Get specific template
 */

export async function getLivePageTemplate(projectId, showcaseId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/projects/${projectId}/showcase/${showcaseId}/template`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    }
}
