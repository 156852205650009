import React from "react";

import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import "./optionTooltip.scss";

function OptionTooltip(props) {
  const { title, options, visibility, leftMargin } = props;

  return (
    <CSSTransition
      in={visibility}
      timeout={300}
      classNames="option-tooltip"
      unmountOnExit
    >
      <div
        className="option-tooltip"
        onClick={e => e.stopPropagation()}
        style={{ left: `${leftMargin}px` }}
      >
        <p>{title}</p>
        <ul className="option-tooltip__list">
          {options.map((option, index) => {
            return (
              <li key={index} onClick={option.action}>
                {option.name}
              </li>
            );
          })}
        </ul>
      </div>
    </CSSTransition>
  );
}

OptionTooltip.propTypes = {
  visibility: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  leftMargin: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired
};

export default OptionTooltip;
