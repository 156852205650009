import * as React from 'react';

const TabletIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={18}
                height={20}
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <rect
                    x={1}
                    y={1}
                    rx={2}
                    width={16}
                    height={18}
                    stroke={props.active ? '#fff' : '#000'}
                    strokeWidth={2}
                />
            </svg>
        </div>
    );
};

export default TabletIcon;
