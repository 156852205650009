import { produce } from 'immer';
import moment from 'moment';

export const initialState = {
    draftMapFieldsInput: {},
    hasFieldMappingDraft: false,
    draftProjectId: '',
    currImportFile: '',
    isImportInProgress: false,
    savedFilters: [],
    savedSort: [],
    peoples: {},
    pagination: {},
    formDefinition: {}
};

function peopleReducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_DRAFT_MAP_FIELDS_INPUT':
            return produce(state, (draft) => {
                draft.draftMapFieldsInput = action.payload.mapFieldsInput;
                draft.draftProjectId = action.payload.projectId;
            });
        case 'SET_HAS_FIELD_MAPPING_DRAFT':
            return produce(state, (draft) => {
                draft.hasFieldMappingDraft = action.payload;
            });
        case 'SET_CURR_IMPORT_FILE':
            return produce(state, (draft) => {
                draft.currImportFile = action.payload;
            });
        case 'SET_IS_IMPORT_IN_PROGRESS':
            return produce(state, (draft) => {
                draft.isImportInProgress = action.payload;
            });
        case 'SET_SAVED_FILTERS':
            return produce(state, (draft) => {
                draft.savedFilters = action.payload;
            });
        case 'SET_SAVED_SORT':
            return produce(state, (draft) => {
                draft.savedSort = action.payload;
            });
        case 'SAVE_PEOPLES_DATA':
            return produce(state, (draft) => {
                draft.peoples = action.payload.people;
                draft.pagination = action.payload.pagination;
            });
        case 'SAVE_EDIT_PEOPLE_DATA':
            return produce(state, (draft) => {

                const timestamp = moment(new Date()).format(
                    'MMM Do YYYY, h:mm a'
                );

                draft.peoples[action.payload.key] = {
                    ...action.payload,
                    updatedon: timestamp
                };
                // draft.peoples = action.payload
            });
        case 'SAVE_FORM_DEFINITION':
            return produce(state, (draft) => {
                draft.formDefinition = action.payload
            })
        default:
            return state;
    }
}

export default peopleReducers;
