import accessControl from 'config/accessControl';

const checkAccess = (role, componentProps) => {
    let isUserAuthorised = false;
    const userRoleAccessControl = accessControl[role?.name.toLowerCase()];

    if (userRoleAccessControl) {
        const restrictAccess = userRoleAccessControl.restrictAccess;
        const allowedRoutes = userRoleAccessControl.allowedRoutes;

        if (Array.isArray(allowedRoutes) && allowedRoutes.length > 0) {
            isUserAuthorised = allowedRoutes.find(
                (route) => route === componentProps.path
            );
        }

        return {
            isUserAuthorised,
            restrictAccess,
        };
    } else {
        return null;
    }
};

export default checkAccess;
