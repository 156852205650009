import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { List, Row, Col } from 'antd';
import * as ls from 'local-storage';
import { useSelector } from 'react-redux';

const UsageBreakdown = ({ isCompanyOnFreePlan, usage }) => {
    const history = useHistory();
    const params = useParams();
    const { companyId } = params;
    const user = useSelector((state) => state.user);

    const handleProjectRedirect = (projectId) => {
        history.push(
            `/company/${companyId}/project/${projectId}/dashboard/overview${
                user?.isFirstProject ? '?firstProject=1' : ''
            }`
        );
    };

    return (
        <div className="mt-3">
            {isCompanyOnFreePlan ? (
                <List
                    header={
                        <Row gutter={16} className="my-1">
                            <Col
                                className="gutter-row attendee-usage-title"
                                flex={4}
                            >
                                Attendees usage by project
                            </Col>
                            <Col
                                className="gutter-row text-bold attendee-count"
                                flex={1}
                            >
                                Qty
                            </Col>
                        </Row>
                    }
                    dataSource={usage?.breakdown || []}
                    renderItem={(item) => (
                        <Row key={item.id} className="my-2">
                            <Col className="gutter-row project-name" flex={4}>
                                {item.name}
                                <img
                                    className="project-redirect-image"
                                    src="/assets/arrow-square-out.svg"
                                    alt=""
                                    onClick={() =>
                                        handleProjectRedirect(item.id)
                                    }
                                />
                            </Col>
                            <Col className="gutter-row attendee-count" flex={1}>
                                {item.peopleCount}
                            </Col>
                        </Row>
                    )}
                />
            ) : (
                <List
                    header={
                        <Row gutter={16} className="my-1">
                            <Col
                                className="gutter-row attendee-usage-title"
                                flex={4}
                            >
                                Breakdown by project
                            </Col>
                        </Row>
                    }
                    dataSource={usage?.breakdown || []}
                    renderItem={({
                        name,
                        id,
                        peopleCount = 0,
                        sponsorUserCount = 0,
                        venue,
                    }) => {
                        const records = [
                            {
                                label: 'Attendees',
                                value: peopleCount,
                            },
                            {
                                label: 'Sponsors Users',
                                value: sponsorUserCount,
                            },
                            {
                                label: 'Venue',
                                value: venue?.name || '(No venue chosen)',
                            },
                        ];
                        return (
                            <div key={id} className="border-bottom my-4">
                                <Row className="my-3 border-bottom">
                                    <Col
                                        className="gutter-row project-breakdown-name"
                                        flex={4}
                                    >
                                        {name}
                                        <img
                                            className="project-redirect-image"
                                            src="/assets/arrow-square-out.svg"
                                            alt=""
                                            onClick={() =>
                                                handleProjectRedirect(id)
                                            }
                                        />
                                    </Col>
                                    <Col
                                        className="gutter-row project-breakdown-name attendee-count"
                                        flex={1}
                                    >
                                        Qty
                                    </Col>
                                </Row>
                                {records.map((record) => (
                                    <Row key={record.label} className="my-2">
                                        <Col
                                            className="gutter-row record-label"
                                            flex={4}
                                        >
                                            {record.label}
                                        </Col>
                                        <Col
                                            className="gutter-row attendee-count"
                                            flex={1}
                                        >
                                            {record.value}
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        );
                    }}
                />
            )}
        </div>
    );
};

export default UsageBreakdown;
