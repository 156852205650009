export const limitsByPlans = {
    '09534317-8b60-494b-bbc4-03aab9a0e074': {
        id: '09534317-8b60-494b-bbc4-03aab9a0e074',
        planName: 'Starter (Free)',
        peopleCount: 0,
        sponsorUserCount: 0,
        organiserUserCount: 0,
    },
    // '2fc7a349-67fa-449a-843b-7963b8c45045'
    'b2e2d0a6-d5f1-46c5-b9cb-af78193c7baa': {
        // id: '2fc7a349-67fa-449a-843b-7963b8c45045',
        id: 'b2e2d0a6-d5f1-46c5-b9cb-af78193c7baa',
        planName: 'Starter',
        peopleCount: 10,
        sponsorUserCount: 0,
        organiserUserCount: 3,
    },
    '27364dcd-bbb2-43dc-8476-25d1226a98a9': {
        id: '27364dcd-bbb2-43dc-8476-25d1226a98a9',
        planName: 'Pro',
        peopleCount: 3000,
        sponsorUserCount: 15,
        organiserUserCount: 5,
    },
    '911f8e0f-be50-4d4d-88c9-234b171575ea': {
        id: '911f8e0f-be50-4d4d-88c9-234b171575ea',
        planName: 'Enterprise',
        peopleCount: 9600,
        sponsorUserCount: 25,
        organiserUserCount: 10,
    },
};
