import React from 'react';

import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './alert.scss';

function Alert(props) {
    const {
        alert: { visibility, message, type },
    } = props;

    const renderFontAwesome = () => {
        let fontAwesomeObj = {};

        if (type === 'error') {
            fontAwesomeObj = {
                icon: 'exclamation-triangle',
                color: '#e75353',
            };
        } else if (type === 'success') {
            fontAwesomeObj = {
                icon: 'check-circle',
                color: '#355330',
            };
        }

        return fontAwesomeObj;
    };

    return (
        <CSSTransition
            in={visibility}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div className={`alert alert__${type}`}>
                <FontAwesomeIcon
                    icon={renderFontAwesome().icon}
                    className="mr-2"
                    color={renderFontAwesome().color}
                />{' '}
                {message}
            </div>
        </CSSTransition>
    );
}

Alert.propTypes = {
    alert: PropTypes.object.isRequired,
};

export default Alert;
