import React, { Fragment, useEffect, useState } from 'react';
import VisitorsAnalytics from 'apis/rest/VisitorsAnalytics';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import './media.scss';
import PageSpinner from 'components/app/common/pageSpinner';
import { Select, DatePicker, Tooltip, Divider, Button, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import FileSaver from 'file-saver';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { debounce, uniqBy, isEmpty } from 'lodash';

const MediaImpressionsList = ({
    enableVenue,
    projectId,
    livePageId,
    startDate,
    endDate,
    isExportVisible,
    enableExport,
}) => {
    const [MediaTrackingData, setMediaTracking] = useState([]);
    const [mediaTrackingDataLoading, setMediaTrackingDataLoading] =
        useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [pageData, setPagination] = useState({});
    const [detailUserData, setDetailUserData] = useState([]);
    const [detailUserDataLoading, setDetailUserDataLoading] = useState(true);
    const [modalVisibility, setModalVisibility] = useState(false);

    const getFetchUserData = async (mediaUrl) => {
        setMediaUrl(mediaUrl);
        setDetailUserDataLoading(true);
        try {
            let { status, matomo } = await VisitorsAnalytics.getMediaUsers(
                projectId,
                livePageId,
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD'),
                mediaUrl,
                false
            );
            if (status && matomo && matomo.users && matomo.users.length > 0) {
                setDetailUserData(matomo.users);
                setDetailUserDataLoading(false);
            } else {
                setDetailUserData([]);
                setDetailUserDataLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const exportFetchUserData = async () => {
        try {
            if (!isExporting) {
                setExporting(true);
                const response = await VisitorsAnalytics.getMediaUsers(
                    projectId,
                    livePageId,
                    moment(startDate).format('YYYY-MM-DD'),
                    moment(endDate).format('YYYY-MM-DD'),
                    mediaUrl,
                    true
                );
                FileSaver.saveAs(response, `MediaUsers.csv`);
                setExporting(false);
            }
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };

    const getMediaTracking = async (selPage = 1) => {
        setMediaTrackingDataLoading(true);
        try {
            let { status, matomo, pagination } =
                await VisitorsAnalytics.getMediaImpressions(
                    projectId,
                    livePageId,
                    moment(startDate).format('YYYY-MM-DD'),
                    moment(endDate).format('YYYY-MM-DD'),
                    false,
                    selPage
                );
            if (status && matomo && matomo.media && matomo.media.length > 0) {
                setMediaTracking(matomo.media);
                if (pagination) setPagination(pagination);
                if (!isExportVisible) enableExport();
            } else setMediaTracking([]);
        } catch (error) {
            console.error(error);
        } finally {
            setMediaTrackingDataLoading(false);
        }
    };
    const [isExporting, setExporting] = useState(false);
    const exportMediaTrackingData = async () => {
        try {
            if (!isExporting) {
                setExporting(true);
                const response = await VisitorsAnalytics.getMediaImpressions(
                    projectId,
                    livePageId,
                    moment(startDate).format('YYYY-MM-DD'),
                    moment(endDate).format('YYYY-MM-DD'),
                    true
                );
                FileSaver.saveAs(response, `${livePageId}MediaImpressions.csv`);
                setExporting(false);
            }
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };
    const { totalPage, currentPage } = pageData;
    useEffect(() => {
        if (livePageId && startDate && endDate) {
            getMediaTracking();
        }
    }, [livePageId, startDate, endDate]);
    return (
        <>
            {MediaTrackingData.length > 0 ? (
                <div className="visitorsCard">
                    <div className="visitorsTable">
                        <div
                            style={{
                                fontFamily: "'Open Sans'",
                                fontSize: '18px',
                                fontWeight: 'bold',
                                padding: '20px',
                                marginBottom: '-80px',
                            }}
                        >
                            What media did visitors watch?
                        </div>
                        <Table borderless hover size="sm" responsive>
                            <thead>
                                <tr>
                                    <th>Media Url</th>
                                    <th>Play Rate</th>
                                    <th>Finish Rate</th>
                                    <th>Avg Time Watched</th>
                                    <th>Total Finishes</th>
                                    <th>Total Plays</th>
                                </tr>
                            </thead>
                            <tbody>
                                {MediaTrackingData.map(
                                    (
                                        {
                                            url,
                                            play_rate,
                                            finish_rate,
                                            avg_time_watched,
                                            nb_plays,
                                            nb_finishes,
                                        },
                                        index
                                    ) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {url && url.length > 0
                                                        ? decodeURIComponent(
                                                              url
                                                          )
                                                        : 'NA'}
                                                </td>
                                                <td>
                                                    {play_rate && play_rate > 0
                                                        ? `${play_rate * 100}%`
                                                        : '0%'}
                                                </td>
                                                <td>
                                                    {finish_rate &&
                                                    finish_rate > 0
                                                        ? `${
                                                              finish_rate * 100
                                                          }%`
                                                        : '0%'}
                                                </td>
                                                <td>
                                                    {avg_time_watched &&
                                                    avg_time_watched > 0
                                                        ? `${avg_time_watched}s`
                                                        : '0s'}
                                                </td>
                                                <td>{nb_finishes ?? '0'}</td>
                                                <td>
                                                    {nb_plays > 0 ? (
                                                        <a
                                                            style={{
                                                                color: 'blue',
                                                                textDecoration:
                                                                    'underline',
                                                            }}
                                                            onClick={() => {
                                                                getFetchUserData(
                                                                    url
                                                                );
                                                                setModalVisibility(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {nb_plays ?? '0'}
                                                        </a>
                                                    ) : (
                                                        nb_plays ?? '0'
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </Table>
                        {totalPage && totalPage > 1 && (
                            <div className="paginationBar">
                                <ReactPaginate
                                    previousLabel="prev"
                                    nextLabel="next"
                                    breakLabel="..."
                                    breakClassName="break-me"
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    containerClassName="pagination"
                                    subContainerClassName="pages pagination"
                                    activeClassName="active"
                                    pageCount={totalPage}
                                    initialPage={currentPage - 1}
                                    onPageChange={({ selected }) => {
                                        if (
                                            !isNaN(selected) &&
                                            selected !== currentPage - 1
                                        )
                                            getMediaTracking(selected + 1);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="exportButton">
                        <span onClick={() => exportMediaTrackingData()}>
                            {isExporting ? 'Exporting...' : 'Export'}
                        </span>
                    </div>
                </div>
            ) : (
                <div
                    className="visitorsCard"
                    style={{
                        height: '57px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '15px',
                        color: '#5f6e94',
                    }}
                >
                    {mediaTrackingDataLoading ? (
                        <PageSpinner
                            type="Oval"
                            color="#ACBDC9"
                            height={48}
                            width={48}
                        />
                    ) : (
                        'No media tracked'
                    )}
                </div>
            )}
            <Modal
                width={1200}
                title={
                    <p className="mb-0">
                        <small>
                            {' '}
                            Note: Max of 100 latest records will be displayed.
                            Export the data for the full set.
                        </small>
                    </p>
                }
                visible={modalVisibility}
                footer={null}
                onCancel={() => setModalVisibility(false)}
            >
                {detailUserDataLoading && isEmpty(detailUserData) && (
                    <PageSpinner
                        type="Oval"
                        color="#ACBDC9"
                        height={48}
                        width={48}
                    />
                )}
                {!isEmpty(detailUserData) && (
                    <>
                        <div style={{ maxHeight: 600, overflow: 'scroll' }}>
                            <Table borderless hover size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Job Title</th>
                                        <th>Company</th>
                                        {livePageId === 'all' && <th>Page</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailUserData &&
                                        detailUserData?.map((item, index) => {
                                            let user = item.crowdflowUser;
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {user?.firstname
                                                            ? user.firstname
                                                            : 'NA'}{' '}
                                                    </td>
                                                    <td>
                                                        {user?.lastname
                                                            ? user.lastname
                                                            : 'NA'}{' '}
                                                    </td>
                                                    <td>
                                                        {user?.email
                                                            ? user.email
                                                            : 'NA'}{' '}
                                                    </td>
                                                    <td>
                                                        {user?.jobTitle
                                                            ? user.jobTitle
                                                            : 'NA'}
                                                    </td>
                                                    <td>
                                                        {user?.company
                                                            ? user.company
                                                            : 'NA'}{' '}
                                                    </td>
                                                    {livePageId === 'all' && (
                                                        <td>
                                                            {item.livepageName ||
                                                                'NA'}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                        <div className="exportButton">
                            <span
                                onClick={() => exportFetchUserData('Download')}
                            >
                                {isExporting ? 'Exporting...' : 'Export'}
                            </span>
                        </div>
                    </>
                )}
                {!detailUserDataLoading && isEmpty(detailUserData) && (
                    <p>There is no data</p>
                )}
            </Modal>
        </>
    );
};

const getDefaultTimeZone = () => {
    try {
        const timezoneDate = new Date();
        const getzone = moment(timezoneDate)
            ?.tz(momentTimezone.tz.guess())
            ?.format('Z');
        const userZone = getzone === '+08:00' ? '+8' : getzone;
        return 'GMT' + userZone;
    } catch (error) {
        console.error(error);
        return '';
    }
};

export default class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: false,
            startDate: new Date(),
            endDate: new Date(),
            livePages: [],
            livePageId: '',
            livePageSearchText: '',
            livePageSearchOffset: 0,
            livePageTotal: 20,
            isExportVisible: false,
        };
        this.debouncedLivePageSearch = debounce(
            this.debouncedLivePageSearch,
            500
        );
    }

    componentDidMount() {
        this.fetchData('', 0).then(({ showcases, pagination, status }) => {
            if (status && showcases && showcases.length > 0) {
                this.setState({
                    livePages: showcases,
                    livePageId: 'all', // showcases[0].id,
                    livePageTotal: pagination?.total || 20,
                });
            } else {
                this.setState({ isEmpty: true });
            }
        });
    }

    componentDidUpdate(_prevProps, prevState) {
        if (this.state.livePageSearchText !== prevState.livePageSearchText) {
            this.setState({ livePageSearchOffset: 0 });
        }
        if (
            this.state.livePageSearchOffset !== prevState.livePageSearchOffset
        ) {
            this.debouncedLivePageSearch(this.state.livePageSearchText);
        }
    }

    enableExport = () => {
        this.setState({ isExportVisible: true });
    };

    debouncedLivePageSearch = async (livePageSearchText) => {
        try {
            this.setState({ loading: true });
            const { showcases, pagination } = await this.fetchData(
                livePageSearchText,
                this.state.livePageSearchOffset ?? 0
            );
            if (showcases && showcases.length > 0) {
                const currentLivePages =
                    livePageSearchText === this.state.livePageSearchText
                        ? [...this.state.livePages]
                        : [];
                let livePages = [...currentLivePages, ...showcases];
                livePages = uniqBy(livePages, 'id');
                this.setState({
                    livePages,
                    livePageTotal: pagination?.total || 20,
                    livePageSearchText,
                });
            } else {
                this.setState({
                    isEmpty: true,
                    livePageTotal: 20,
                    livePageSearchText,
                });
            }
        } catch (error) {
            console.error(error);
            this.setState({ livePageSearchText });
        } finally {
            this.setState({ loading: false });
        }
    };

    fetchData = async (search = '', offset = 0) => {
        try {
            const response = await getLivePages(
                this.props.projectId,
                'title',
                'asc',
                20,
                offset,
                search,
                []
            );
            if (response.status) {
                return {
                    showcases: response.showcases,
                    pagination: response.pagination,
                };
            } else throw new Error(response.message);
        } catch (error) {
            console.error(error);
            return { status: false, showcases: [], pagination: null };
        }
    };

    render() {
        return (
            <div className="visitorAnalysisContainer">
                {this.state.isExportVisible && (
                    <div className="visitorNote">
                        Note: Max of 100 latest records will be displayed.
                        Export the data for the full set.
                    </div>
                )}
                {this.state.livePages?.length > 0 ? (
                    <>
                        <div className="visitLiveBox controls">
                            <Select
                                id="visitsLivePageDrop"
                                placeholder="Select a livepage"
                                size="small"
                                showSearch={true}
                                optionFilterProp="data-search"
                                value={this.state.livePageId}
                                onChange={($event) => {
                                    this.setState({ livePageId: $event });
                                }}
                                onSearch={($event) => {
                                    this.debouncedLivePageSearch($event);
                                }}
                                autoClearSearchValue={false}
                                virtual={false}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                                loading={this.state.loading}
                                dropdownRender={(menu) => {
                                    return (
                                        <div>
                                            {menu}
                                            {this.state.livePages &&
                                                this.state.livePageTotal >
                                                    this.state.livePages
                                                        .length && (
                                                    <Fragment>
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding:
                                                                    '2px 8px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        'rgba(0, 0, 0, 0.075)',
                                                                    textAlign:
                                                                        'center',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Button
                                                                    type="link"
                                                                    htmlType="button"
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                livePageSearchOffset:
                                                                                    this
                                                                                        .state
                                                                                        .livePages
                                                                                        .length,
                                                                            }
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        this
                                                                            .state
                                                                            .loading
                                                                    }
                                                                    loading={
                                                                        this
                                                                            .state
                                                                            .loading
                                                                    }
                                                                    className="livepage-search-load-more-btn"
                                                                >
                                                                    Load more
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                        </div>
                                    );
                                }}
                                className="livepage-search"
                            >
                                <Select.OptGroup label="All Pages">
                                    <Select.Option
                                        key="all"
                                        value="all"
                                        data-search="All Pages"
                                    >
                                        All Pages
                                    </Select.Option>
                                </Select.OptGroup>
                                <Select.OptGroup label="Select Pages">
                                    {this.state.livePages?.map((livePage) => {
                                        return (
                                            <Select.Option
                                                key={livePage.id}
                                                value={livePage.id}
                                                data-search={
                                                    livePage.title +
                                                    livePage.slug
                                                }
                                            >
                                                {livePage.title}
                                            </Select.Option>
                                        );
                                    })}
                                </Select.OptGroup>
                            </Select>
                            <div className="visitor-live-page-search-tooltip">
                                <Tooltip
                                    placement="top"
                                    title="Search by your livepage URL slug"
                                >
                                    <InfoCircleOutlined
                                        style={{
                                            marginLeft: 24,
                                            position: 'absolute',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div className="visitorDate">
                                <DatePicker.RangePicker
                                    placeholder={['Start Date', 'End Date']}
                                    format="MMM D, YYYY"
                                    showTime={false}
                                    disabledDate={(current) => {
                                        return (
                                            current &&
                                            current > moment().startOf('day')
                                        );
                                    }}
                                    value={[
                                        moment(this.state.startDate).startOf(
                                            'day'
                                        ),
                                        moment(this.state.endDate).endOf('day'),
                                    ]}
                                    onChange={(dates) => {
                                        if (dates) {
                                            if (dates[0]) {
                                                this.setState({
                                                    startDate: dates[0]
                                                        .startOf('day')
                                                        .toDate(),
                                                });
                                            }
                                            if (dates[1]) {
                                                this.setState({
                                                    endDate: dates[1]
                                                        .endOf('day')
                                                        .toDate(),
                                                });
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className="visitLiveBox"
                            style={{ marginTop: '10px' }}
                        >
                            <MediaImpressionsList
                                {...{
                                    projectId: this.props.projectId,
                                    livePageId: this.state.livePageId,
                                    startDate: this.state.startDate,
                                    endDate: this.state.endDate,
                                    isExportVisible: this.state.isExportVisible,
                                    enableExport: this.enableExport,
                                }}
                            />
                        </div>
                    </>
                ) : this.state.isEmpty ? (
                    'No Data'
                ) : (
                    <PageSpinner
                        type="Oval"
                        color="#ACBDC9"
                        height={48}
                        width={48}
                    />
                )}
            </div>
        );
    }
}
