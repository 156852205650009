import React from 'react';

export default function SelectedIcon({ stroke = '#377BF7' }) {
    return (
        <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0.730469C4.925 0.730469 0 5.65547 0 11.7305C0 17.8055 4.925 22.7305 11 22.7305C17.075 22.7305 22 17.8055 22 11.7305C22 5.65547 17.075 0.730469 11 0.730469ZM15.768 9.87047C15.8558 9.77011 15.9226 9.65321 15.9646 9.52664C16.0065 9.40007 16.0227 9.26638 16.0123 9.13345C16.0018 9.00052 15.9648 8.87103 15.9036 8.7526C15.8423 8.63416 15.758 8.52917 15.6555 8.44381C15.5531 8.35845 15.4346 8.29443 15.3071 8.25553C15.1796 8.21663 15.0455 8.20363 14.9129 8.2173C14.7802 8.23096 14.6517 8.27102 14.5347 8.3351C14.4178 8.39919 14.3149 8.48601 14.232 8.59047L9.932 13.7495L7.707 11.5235C7.5184 11.3413 7.2658 11.2405 7.0036 11.2428C6.7414 11.2451 6.49059 11.3502 6.30518 11.5357C6.11977 11.7211 6.0146 11.9719 6.01233 12.2341C6.01005 12.4963 6.11084 12.7489 6.293 12.9375L9.293 15.9375C9.39126 16.0357 9.50889 16.1123 9.63842 16.1625C9.76794 16.2127 9.9065 16.2354 10.0453 16.2291C10.184 16.2228 10.32 16.1877 10.4444 16.1259C10.5688 16.0642 10.6791 15.9772 10.768 15.8705L15.768 9.87047Z"
                fill={stroke || '#377BF7'}
            />
        </svg>
    );
}
