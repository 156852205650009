import * as React from 'react';

const ChevronDown = (props) => {
    return (
        <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.984 4.50001C3.89042 4.50056 3.79765 4.48255 3.71102 4.44702C3.62438 4.41148 3.54559 4.35913 3.47914 4.29296L0.634899 1.43698C0.5686 1.37041 0.51601 1.29138 0.480129 1.2044C0.444249 1.11742 0.425781 1.0242 0.425781 0.930049C0.425781 0.835902 0.444249 0.742677 0.480129 0.655697C0.51601 0.568717 0.5686 0.489686 0.634899 0.423114C0.701197 0.356542 0.779904 0.303734 0.866527 0.267706C0.95315 0.231678 1.04599 0.213135 1.13975 0.213135C1.23351 0.213135 1.32635 0.231678 1.41298 0.267706C1.4996 0.303734 1.57831 0.356542 1.64461 0.423114L3.984 2.78643L6.3305 0.515933C6.39591 0.442899 6.47563 0.384202 6.56466 0.343515C6.6537 0.302827 6.75013 0.281024 6.84794 0.279468C6.94575 0.277912 7.04283 0.296636 7.1331 0.334471C7.22337 0.372307 7.3049 0.428439 7.37258 0.499355C7.44027 0.570272 7.49265 0.654449 7.52646 0.746622C7.56026 0.838794 7.57476 0.936979 7.56906 1.03504C7.56335 1.13309 7.53755 1.22891 7.49328 1.3165C7.44901 1.40409 7.38722 1.48157 7.31177 1.54408L4.46752 4.3001C4.33749 4.42598 4.16461 4.49746 3.984 4.50001Z"
                fill="#0B1C2C"
            />
        </svg>
    );
};

export default ChevronDown;
