import * as React from 'react';

const CodeIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                d="M6.188 5L1.125 9.5 6.188 14M11.813 5l5.062 4.5-5.063 4.5"
                stroke="#000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </svg>
        </div>
    );
};

export default CodeIcon;
