import React from 'react'

function Heading({
    count,
    text
}) {
    return (
        <>
            <h3>{count}</h3>
            <span>{text}</span>
        </>
    )
}

export default Heading;