import React, { useRef } from "react";
import PropTypes from "prop-types";

import useOutsideAlerter from "components/app/utils/hooks/useOutsideAlerter";

function OutsideAlerter(props) {
  const { payload } = props;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, payload);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  payload: PropTypes.object.isRequired
};

export default OutsideAlerter;
