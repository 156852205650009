import React from 'react';

import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import { useHistory, useParams } from 'react-router-dom';

const BlockFooter = ({
    onSave,
    onCancel,
    disabledSave,
    disabledCancel = false,
}) => {
    const history = useHistory();
    const { projectId, companyId } = useParams();
    const pathname = `/company/${companyId}/project/${projectId}/dashboard`;

    const blockLoadingState = useSelector((state) => state?.sidebar?.loading);
    const previewStateLoading = useSelector((state) => state?.preview?.loading);
    const selection = useSelector((state) => state?.header?.selection);

    const handleOnCancel = () => {
        onCancel();
    };

    return (
        <div className={styles.BlockFooterContainer}>
            {disabledCancel ||
            blockLoadingState?.status ||
            previewStateLoading ? (
                <span className={`${styles.ButtonCancel} ${styles.NotAllowed}`}>
                    Cancel
                </span>
            ) : (
                <span onClick={handleOnCancel} className={styles.ButtonCancel}>
                    Cancel
                </span>
            )}

            <button
                type="submit"
                onClick={onSave}
                disabled={disabledSave || blockLoadingState?.status}
                className={styles.ButtonSave}
            >
                {blockLoadingState?.status || previewStateLoading ? (
                    <>
                        <LoadingOutlined />{' '}
                        {['delete', 'upload'].includes(blockLoadingState?.type)
                            ? ''
                            : 'Saving'}
                    </>
                ) : (
                    'Save'
                )}
            </button>
        </div>
    );
};

export default BlockFooter;
