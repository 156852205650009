import React, { useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';

import { Row, Col, Tag, Button, Menu, Dropdown } from 'antd';
import 'antd/dist/antd.css';
import { DownOutlined } from '@ant-design/icons';
import * as ls from 'local-storage';
import Layout from '.././../common/layout';

import './venuecollections.scss';

// components
import Search from 'components/app/components/venue/search';
import Uploadvenue from 'components/app/components/venue/uploadvenue';
import Loader from 'components/app/components/venue/Loader';
// import FilterAndLabels from 'components/app/components/venue/filterAndLabels';

// redux
import { useDispatch, useSelector } from 'react-redux';
import * as venuesActions from 'redux/actions/venues/actionsVenues';

// API
import {
    getCompanyVenues,
    deleteVenueCollection,
} from 'apis/rest/venue/Venues';

import useAlertBar from 'components/app/utils/hooks/useAlertBar';

const VenueCollections = (props) => {
    const { match } = props;

    const getUserProject = ls.get('project');

    const dispatch = useDispatch();
    const { showAlertBar } = useAlertBar();

    // modal
    const [addVenueModal, setAddVenueModal] = useState(false);
    const [loading, setLoading] = useState(true);

    // search
    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState('');

    // state
    const venues = useSelector((state) => state?.venues?.venues);

    const handleSearch = (value) => {
        setSearchValue(value);
        searchDebounce(value);
    };

    const searchDebounce = useCallback(
        debounce((text) => setSearchDebounceText(text), 500),
        []
    );

    const deleteVenue = async (id) => {
        const deleteVenueItem = await deleteVenueCollection(
            getUserProject?.projectId,
            id
        );
        if (deleteVenueItem.status) {
            dispatch(venuesActions.deleteVenueCollection(id));
            showAlertBar(`Venue successfully deleted.`, 'success');
        } else {
            showAlertBar(`${deleteVenueItem.message}`, 'error');
        }
    };

    const getVenues = async (search = null) => {
        const getVenuesData = await getCompanyVenues(
            getUserProject?.projectId,
            search
        );
        if (getVenuesData.status) {
            dispatch(venuesActions.setVenues(getVenuesData));
            setLoading(false);
        }
    };

    useEffect(() => {
        getVenues();
    }, []);

    useEffect(() => {
        setLoading(true);
        getVenues(searchDebounceText);
    }, [searchDebounceText]);

    const actionMenus = (id) => (
        <Menu>
            {/* <Menu.Item>Rename</Menu.Item> */}
            {/* <Menu.Item>Edit Code</Menu.Item> */}
            {/* <Menu.Item>Download</Menu.Item> */}
            <Menu.Item onClick={() => deleteVenue(id)}>Delete</Menu.Item>
        </Menu>
    );

    return (
        <Layout>
            <div className="container" style={{ width: '950px' }}>
                <div className="dashboard dashboard__xpmgr project-list">
                    <div className="dashboard__left">
                        <Link to="/">
                            <h1>Projects</h1>
                        </Link>
                        <Link
                            to={`/venue-collections/${getUserProject?.projectId}`}
                        >
                            <h1 className="active">Venue Collection</h1>
                        </Link>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-2">
                    <div className="d-flex align-items-center">
                        {/* Search venue collections */}
                        <Search
                            handleSearch={handleSearch}
                            searchValue={searchValue}
                        />
                        <Button
                            onClick={() => setAddVenueModal(true)}
                            className="myxp btn btn-primary ml-3"
                        >
                            Add a Theme
                        </Button>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    {/* <span>Label</span> */}
                    {/* <FilterAndLabels
                        type="label"
                    />
                    <FilterAndLabels
                        type="filterLabel"
                    /> */}
                </div>
                <div
                    className={`mt-3 venues-items ${loading ? 'loading' : ''}`}
                >
                    {loading ? (
                        <Loader text="Loading theme collections..." />
                    ) : venues?.venues.length > 0 &&
                      venues?.pagination?.total !== 0 ? (
                        venues?.venues.map((venue, index) => {
                            return (
                                <Row
                                    key={index}
                                    justify="space-between"
                                    align="start"
                                    className="venue-item"
                                >
                                    <Col span={4}>
                                        {venue?.thumbnail ? (
                                            <img
                                                width={128}
                                                height={84}
                                                alt={venue?.name}
                                                src={venue?.thumbnail}
                                            />
                                        ) : (
                                            <div className="card-image-placeholder" />
                                        )}
                                    </Col>
                                    <Col span={16}>
                                        <h2>{venue.name}</h2>
                                        <div className="venue-labels">
                                            <label>Labels:</label>
                                            <div className="venue-labels-tags">
                                                {venue?.goodFor
                                                    ?.split(', ')
                                                    .map((tag, idx) => {
                                                        return (
                                                            <Tag
                                                                key={idx}
                                                                closable
                                                            >
                                                                {tag}
                                                            </Tag>
                                                        );
                                                    })}
                                                {/* <Tag closable color="#CCCCCC">Singapore Map</Tag>
                                                    <Tag closable color="#F5D882">Exhibition</Tag>
                                                    <Tag closable color="#A4C5FB">3D</Tag> */}
                                                <button className="btn btn-plus label-pos">
                                                    <i className="fa fa-plus">
                                                        {' '}
                                                    </i>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div className="venue-projects">
                                                <label>Used by the following projects:</label>
                                                <div className="venue-projects-tags">
                                                    <Tag>SWITCH 2020</Tag>
                                                    <Tag>Project B</Tag>
                                                    <Tag>Project C</Tag>
                                                </div>
                                            </div> */}
                                        <div className="venue-edits-logs">
                                            <span>
                                                Uploaded by{' '}
                                                <b>{venue.createdBy}</b> on{' '}
                                                <b>
                                                    {moment(
                                                        venue.createdAt
                                                    ).format(
                                                        'DD MMM YYYY'
                                                    )}{' '}
                                                    at{' '}
                                                    {moment(
                                                        venue.createdAt
                                                    ).format('HH:mm')}
                                                </b>
                                            </span>
                                            <span>
                                                Last updated by{' '}
                                                <b>{venue.updatedBy}</b> on{' '}
                                                <b>
                                                    {moment(
                                                        venue.updatedAt
                                                    ).format(
                                                        'DD MMM YYYY'
                                                    )}{' '}
                                                    at{' '}
                                                    {moment(
                                                        venue.updatedAt
                                                    ).format('HH:mm')}
                                                </b>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <Link
                                            to={`/venue-collections/${getUserProject?.projectId}`}
                                            className="myxp btn btn-preview ml-3"
                                        >
                                            Preview
                                        </Link>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={actionMenus(venue.id)}
                                        >
                                            <Button className="myxp btn btn-preview ml-3">
                                                More actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            );
                        })
                    ) : (
                        <>
                            <h2>No record found.</h2>
                        </>
                    )}
                    {/* <Row
                        justify="space-between"
                        align="start"
                        className="venue-item">
                        <Col span={4}>
                            <img
                                width={128}
                                height={84}
                                alt="logo"
                                src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png" />
                        </Col>
                        <Col span={16}>
                            <h2>SWITCH v1</h2>
                            <div className="venue-labels">
                                <label>Labels:</label>
                                <div className="venue-labels-tags">
                                    <Tag closable color="#CCCCCC">Singapore Map</Tag>
                                    <Tag closable color="#F5D882">Exhibition</Tag>
                                    <Tag closable color="#A4C5FB">3D</Tag>
                                    <button className="btn btn-plus label-pos">
                                        <i className="fa fa-plus">
                                            {' '}
                                        </i>
                                    </button>
                                </div>
                            </div>
                            <div className="venue-projects">
                                <label>Used by the following projects:</label>
                                <div className="venue-projects-tags">
                                    <Tag>SWITCH 2020</Tag>
                                    <Tag>Project B</Tag>
                                    <Tag>Project C</Tag>
                                </div>
                            </div>
                            <div className="venue-edits-logs">
                                <span>Uploaded by <b>Peter Lam</b> on <b>12 Dec 2020 at 12:38</b></span>
                                <span>Last updated by <b>Peter Lam</b> on <b>14 Dec 2020 at 14:02</b></span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <Link to={`/venue-collections`}
                            className="myxp btn btn-preview ml-3" >Preview</Link>
                            <Dropdown
                                trigger={['click']}
                                overlay={actionMenus}>
                                <Button className="myxp btn btn-preview ml-3">
                                    More actions <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row> */}
                    {/* add venue */}
                    {addVenueModal && (
                        <Uploadvenue
                            isVisible={addVenueModal}
                            setUploadModal={setAddVenueModal}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(VenueCollections);
