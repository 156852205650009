import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import FileSaver from 'file-saver';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
    downloadBackupCodes,
    generateBackupCodes,
    getTwoFAConfiguration,
} from 'apis/rest/TwoFAAuth';

import Modal from 'components/app/common/modal';
import { getFullname } from 'components/app/utils/functions/common';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { beautifySecretCode } from './TwoFASetup';
import { TwoFAThirdPartyIcon, BackupCodesIcon } from './icons';

const showQrCodeUI = false;

export default function TwoFASecurityModal({
    visible = false,
    setVisible = () => {},
}) {
    const user = useSelector((state) => state.user);

    const [isLoading, setIsLoading] = useState(true);
    const [qrCodeLoading, setQrCodeLoading] = useState(false);
    const [qrCodeUri, setQrCodeUri] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [backupCodes, setBackupCodes] = useState([]);
    const [backupCodesCreatedAt, setBackupCodesCreatedAt] = useState('');
    const [isBackupCodesGenerating, setIsBackupCodesGenerating] =
        useState(false);
    const [isBackupCodesDownloading, setIsBackupCodesDownloading] =
        useState(false);
    const email = user?.email;
    const fullName = getFullname(user);
    const maxNameLength = 15;

    async function handleGetTwoFAConfiguration() {
        try {
            if (!showQrCodeUI) return;
            setQrCodeLoading(true);
            const resp = await getTwoFAConfiguration();
            if (!resp?.status) throw resp;
            const { uri, code } = resp;
            setQrCodeUri(uri);
            setSecretCode(beautifySecretCode(code));
        } catch (error) {
            console.error(error);
        } finally {
            setQrCodeLoading(false);
        }
    }

    async function handleDownloadBackupCodes(isJson) {
        try {
            setIsBackupCodesDownloading(true);
            const resp = await downloadBackupCodes(isJson);
            if (isJson) {
                setBackupCodes(resp?.recoveryCodes || []);
                setBackupCodesCreatedAt(
                    resp?.createdAt
                        ? moment(resp?.createdAt).format(
                              'MMM DD, YYYY [at] HH:mm (Z)'
                          )
                        : ''
                );
            } else {
                FileSaver.saveAs(
                    resp,
                    `gevme-backup-codes-${email}-${moment().format(
                        'YYYY-MM-DD-HH-mm-ss'
                    )}.txt`
                );
            }
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setIsLoading(false);
            setIsBackupCodesDownloading(false);
        }
    }

    async function handleGenerateBackupCodes() {
        try {
            setIsBackupCodesGenerating(true);
            const resp = await generateBackupCodes();
            if (!resp?.status) throw resp;
            await handleDownloadBackupCodes(true);
            openNotification('success', { message: resp.message });
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setIsBackupCodesGenerating(false);
        }
    }

    useEffect(() => {
        handleGetTwoFAConfiguration();
        handleDownloadBackupCodes(true);
    }, []);

    const getLoadingScreen = () => (
        <div>
            <LoadingOutlined style={{ scale: '5' }} />
        </div>
    );

    const getBackupCodesAddedOn = () =>
        backupCodesCreatedAt ? (
            <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-flex tw-flex-row tw-gap-2 tw-flex-no-wrap">
                <div className="tw-text-gray-400">Added on</div>{' '}
                <div className="tw-font-semibold tw-text-gray-600">
                    {backupCodesCreatedAt}
                </div>
            </p>
        ) : null;

    const getQrCode = () =>
        showQrCodeUI ? (
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-mt-4 tw-p-2  tw-rounded tw-border tw-border-solid tw-border-gray-200">
                {qrCodeLoading ? (
                    getLoadingScreen()
                ) : (
                    <>
                        <div className="tw-w-fit tw-bg-gray-200 tw-rounded tw-p-2 tw-w-[152px] tw-h-[152px] tw-flex tw-flex-row tw-items-center tw-justify-center">
                            {qrCodeUri ? (
                                <QRCode
                                    value={qrCodeUri}
                                    size={128}
                                    includeMargin
                                />
                            ) : (
                                <div className="tw-w-[167px] tw-h-[140px] tw-text-base tw-font-bold tw-uppercase tw-text-gray-300 tw-flex tw-flex-row tw-justify-center tw-items-center tw-rounded tw-border tw-border-solid tw-border-gray-200">
                                    No QR code
                                </div>
                            )}
                        </div>
                        <p className="tw-w-12 tw-h-12 tw-flex tw-flex-row tw-items-center tw-justify-center tw-rounded-full tw-p-0 tw-m-0 tw-mx-2 tw-font-montserrat tw-font-semibold tw-text-xl tw-bg-gray-200">
                            OR
                        </p>
                        <div className="tw-flex tw-w-auto tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-rounded tw-p-0 tw-mt-4">
                            <div className="tw-w-full">
                                <p className="tw-w-full tw-p-0 tw-m-0 tw-text-xs tw-text-gray-600 tw-text-left">
                                    Enter this code into your authentication app
                                </p>
                            </div>
                            <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-base tw-font-bold tw-uppercase tw-bg-gray-200 tw-rounded tw-px-3 tw-py-1.5">
                                {secretCode || (
                                    <span className="tw-text-gray-300">
                                        No secret code
                                    </span>
                                )}
                            </p>
                        </div>
                    </>
                )}
            </div>
        ) : null;

    const getSecurityScreen = () => (
        <>
            <div className="tw-w-full tw-text-gray-400 tw-font-semibold tw-flex tw-flex-row tw-justify-start tw-items-end tw-text-left">
                <div className="tw-text-base tw-text-gray-400 tw-pr-2">
                    {fullName?.length > maxNameLength ? (
                        <Tooltip placement="top" title={fullName} zIndex={9999}>
                            <div className="tw-p-0 tw-m-0 tw-break-words">
                                {fullName.slice(0, maxNameLength - 1) + ' ...'}
                            </div>
                        </Tooltip>
                    ) : (
                        fullName
                    )}
                </div>
                &#x2f;
                <div className="tw-pl-2 tw-text-base tw-text-black tw-font-semibold">
                    Security
                </div>
            </div>
            <p className="tw-p-0 tw-m-0 tw-mt-4 tw-text-base tw-w-auto tw-mr-auto tw-font-semibold tw-font-openSans">
                Two-Factor Authentication
            </p>
            <p className="tw-p-0 tw-m-0 tw-text-sm tw-w-auto tw-mr-auto tw-font-light tw-font-openSans">
                We’ll ask for a code if we notice an attempted login from an
                unrecognised device or browser.
            </p>
            <div className="tw-mt-6 tw-mr-auto tw-flex tw-w-full tw-flex-row tw-items-start tw-justify-center tw-gap-3">
                <div>
                    <TwoFAThirdPartyIcon />
                </div>
                <div className="tw-flex tw-flex-col tw-w-full tw-items-start tw-justify-start tw-gap-2">
                    <div className="tw-w-full">
                        <div className="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2">
                            <p className="tw-p-0 tw-m-0 tw-text-base tw-w-auto tw-font-semibold tw-font-openSans">
                                Authentication App
                            </p>
                            <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-openSans">
                                You’ll receive a login code via an authencation
                                app
                            </p>
                            {getBackupCodesAddedOn()}
                        </div>
                        {getQrCode()}
                    </div>
                </div>
            </div>
            <div className="tw-mt-6 tw-mr-auto tw-flex tw-w-auto tw-flex-row tw-items-start tw-justify-center tw-gap-2">
                <div>
                    <BackupCodesIcon />
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2">
                    <p className="tw-p-0 tw-m-0 tw-text-base tw-w-auto tw-font-semibold tw-font-openSans">
                        Backup Codes
                    </p>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-flex tw-flex-nowrap tw-font-openSans">
                        Use as an alternative to authentication app
                    </p>
                    <div className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-openSans tw-flex tw-gap-1 tw-flex-nowrap tw-justify-start tw-items-end">
                        You have
                        <div className="tw-font-bold">
                            {backupCodes?.length}
                        </div>
                        unused backup codes.
                    </div>
                    {getBackupCodesAddedOn()}
                </div>
                <div className="tw-ml-16 tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2">
                    <button
                        type="button"
                        className={`${
                            isBackupCodesGenerating
                                ? 'tw-opacity-50'
                                : 'tw-border-gray-300 tw-bg-white hover:tw-border-gray-400 hover:tw-bg-gray-50'
                        } tw-w-full tw-whitespace-nowrap tw-rounded tw-border tw-border-solid tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-font-openSans`}
                        disabled={isBackupCodesGenerating}
                        onClick={handleGenerateBackupCodes}
                    >
                        Generate new codes
                    </button>
                    <button
                        type="button"
                        className={`${
                            isBackupCodesDownloading
                                ? 'tw-border-blue-600/50 tw-bg-blue-600/50 hover:tw-border-blue-500/50 hover:tw-bg-blue-500/50'
                                : 'tw-border-blue-600 tw-bg-blue-600 hover:tw-border-blue-500 hover:tw-bg-blue-500'
                        } tw-w-full tw-whitespace-nowrap tw-rounded tw-border tw-border-solid tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-font-openSans tw-text-white`}
                        disabled={isBackupCodesDownloading}
                        onClick={() => handleDownloadBackupCodes(false)}
                    >
                        Save text file
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <Modal
            visible={visible}
            onClose={() => setVisible(false)}
            showCloseIcon
            preventCloseOnOutsideClick
            style={{ maxWidth: '650px' }}
        >
            <div
                className={`${
                    isLoading ? 'tw-justify-center' : 'tw-justify-start'
                } tw-mx-auto tw-flex tw-min-h-[360px] tw-w-auto tw-flex-col tw-items-center tw-cursor-default`}
            >
                {isLoading ? getLoadingScreen() : getSecurityScreen()}
            </div>
        </Modal>
    );
}
