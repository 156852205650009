import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useSponsorMenu = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams() || '';
    const { pathname } = useLocation() || '';
    const activeMenu = pathname.split(`${projectId}/`)[1];
    const { sponsorMenuData } = useSelector((state) => state?.sponsorMenu);

    useEffect(() => {
        if (sponsorMenuData?.length) {
            const activeSponsorMenu = sponsorMenuData.reduce((acc, menu) => {
                if (
                    menu.link === activeMenu ||
                    (menu.sublinks &&
                        menu.sublinks.some(
                            (sublink) => sublink.link === activeMenu
                        ))
                ) {
                    acc.push(menu);
                }
                return acc;
            }, []);

            if (activeSponsorMenu?.length) {
                const activeMenuKey =
                    activeSponsorMenu[0]?.sublinks?.find(
                        (link) =>
                            link?.link === activeMenu ||
                            link?.link?.includes(activeMenu)
                    )?.name || '';

                dispatch({
                    type: 'UPDATE_ACTIVE_MENU',
                    payload: {
                        ...activeSponsorMenu[0],
                        activeMenuKey,
                    },
                });
            }
        }
    }, [activeMenu, sponsorMenuData, dispatch]);
};

export default useSponsorMenu;
