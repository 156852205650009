import * as React from 'react';
import PropTypes from 'prop-types';

const RightArrowIcon = (props) => {
    return (
        <svg
            {...props}
            viewBox="0 0 18 28"
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2.23223 5.76777L10.4645 14L2.23223 22.2322C1.25592 23.2085 1.25592 24.7915 2.23223 25.7678C3.20854 26.7441 4.79146 26.7441 5.76777 25.7678L15.7678 15.7678C16.7441 14.7915 16.7441 13.2085 15.7678 12.2322L5.76777 2.23223C4.79146 1.25592 3.20854 1.25592 2.23223 2.23223C1.25592 3.20854 1.25592 4.79146 2.23223 5.76777Z"
                fill="#181818"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

    );
};

RightArrowIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

RightArrowIcon.defaultProps = {
    width: 18,
    height: 28,
};

export default RightArrowIcon;
