import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';

import LivePagesIcon from 'Apps/VenueBuilder/Icons/LivePagesIcon';
import { Tooltip, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import { isValidUrl } from 'ComponentsV2/CommonUtils/isValidUrl';

import styles from './index.module.css';

const LivePagesList = ({
    title,
    placeholder,
    link,
    setLink,
    returnShowcaseId,
    logoRedirectLinkType,
    isSubmitted,
    tooltipText,
    isRequired,
    livePagesList = [],
    paddingReset = false,
    openInNewWindow,
    setOpenInNewWindow,
    setSelectPageId = null,
    hideOpenInNewTabSwitch = false,
    error = {},
}) => {
    const { projectId } = useParams();
    const livePages =
        useSelector((state) => state?.header?.selection?.livePagesList) ||
        livePagesList;
    const [visibleLivePages, setVisibleLivePages] = useState(false);
    const [selectedLivePage, setSelectedLivePage] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [livepageList, setLivepageList] = useState(livePages);

    const labelContainer = useRef(null);
    const linkInputRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (e.composedPath().includes(labelContainer.current)) return;
        setVisibleLivePages(false);
    };

    const searchDebounce = useCallback(
        debounce((searchTerm) => setSearchDebounceText(searchTerm), 500),
        []
    );

    // on click outside of filter
    useEffect(() => {
        // For Filter box, add when mounted
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        searchDebounceText && handleSearch();
    }, [searchDebounceText]);

    const handleSearch = async () => {
        try {
            setLoading(true);
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                searchVal.split(' ').join('-'),
                [],
                '',
                1
            );
            if (!showcases || showcases.length === 0) throw 'failed';

            setLivepageList(showcases);
            setLoading(false);
        } catch (error) {
            setLivepageList([]);
            setLoading(false);
        }
    };

    const handleChange = (value) => {
        if (!value) {
            setSelectedLivePage('');
            setLink('');
            setLivepageList(livePages);
            const findIndex = livePages.findIndex((page) =>
                link.match(page.slug)
            );
            if (findIndex !== -1) {
                setSelectPageId(livePages[findIndex]?.key);
            } else {
                setSelectPageId(null);
            }
        } else {
            setLink(value);
            setSearchVal(value);
            searchDebounce(value);
            setSelectPageId(null);
        }
    };

    const onBlur = () => {
        // setVisibleLivePages(false);
    };

    // const onFocus = () => {
    //     setVisibleLivePages(true);
    // }

    const onKeyDown = (e) => {
        if (e.keyCode === 8) {
            // console.log(e.target.value,'delete');
        }
    };

    useEffect(() => {
        setLivepageList(livePages);
    }, [livePages]);

    useEffect(() => {
        if (link) {
            const findIndex = livepageList.findIndex((page) =>
                logoRedirectLinkType === 'gevme' && !isValidUrl(link) ? link.match(page.id) : link.match(page.slug)  
            );
            if (findIndex !== -1) {
                if(logoRedirectLinkType === 'gevme' && !isValidUrl(link)){
                    const newLink = process.env.REACT_APP_TEMPLATE_URL_APP + '/website/' + livepageList[findIndex].slug +  '/showcaseId=' + link;
                    setLink(newLink);
                }
                setSelectedLivePage(`${livepageList[findIndex].title}`);
                setSelectPageId(livepageList[findIndex].key);
            } else {
                setSelectedLivePage(link);
                setSelectPageId(null);
            }
        }
    }, [link, livepageList]);

    useEffect(() => {
        if (!selectedLivePage || !linkInputRef?.current) return;
        linkInputRef.current.value = selectedLivePage;
    }, [selectedLivePage, linkInputRef?.current]);

    return (
        <div
            className={`${styles.CTAIconContainer} link-select ${paddingReset ? 'pr-0 pl-0' : ''
                }`}
        >
            <label>
                {title}
                {isRequired && title && <span>*</span>}
                {tooltipText && (
                    <Tooltip placement="right" title={tooltipText}>
                        <InfoCircleOutlined />
                    </Tooltip>
                )}
            </label>
            <div ref={labelContainer}>
                <input
                    ref={linkInputRef}
                    type='search'
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={onBlur}
                    // onFocus={onFocus}
                    allowClear
                    placeholder={placeholder}
                    className={
                        link?.length > 0 || isRequired
                            ? (isSubmitted && !isValidUrl(link)) ||
                                error?.ctaLink
                                ? 'error'
                                : ''
                            : ''
                    }
                    defaultValue={selectedLivePage}
                    onKeyDown={onKeyDown}
                    onClick={() => setVisibleLivePages(!visibleLivePages)}
                    style={{ width: '100%' }}
                />

                {link?.length > 0
                    ? isSubmitted &&
                    !isValidUrl(link) && (
                        <span className={`${styles.ErrorTxt} error`}>
                            Invalid URL
                        </span>
                    )
                    : isRequired &&
                    isSubmitted && (
                        <span className={`${styles.ErrorTxt} error`}>
                            Required
                        </span>
                    )}
                {visibleLivePages && (
                    <div className={`${styles.LivePagesItems} livepage-items`}>
                        {isLoading ? (
                            <Spin size="small" />
                        ) : isEmpty(livepageList) ? (
                            <span>No Livepage found</span>
                        ) : (
                            livepageList.map(({ id, title, slug, type }) => (
                                <span
                                    onMouseDown={() => {
                                        setLink(
                                            `${process.env
                                                .REACT_APP_TEMPLATE_URL_APP
                                                }/${type === 'website'
                                                    ? 'site'
                                                    : 'page'
                                                }/${slug}${returnShowcaseId ? `/showcaseId=${id}` : ''}`
                                        );
                                        setSelectedLivePage(title);
                                        setVisibleLivePages(false);
                                    }}
                                    className="livepage-item-select"
                                    key={id}
                                >
                                    <LivePagesIcon /> {title}
                                </span>
                            ))
                        )}
                    </div>
                )}
            </div>

            {!hideOpenInNewTabSwitch ? (
                <div className={`${styles.BorderedSwitch} switches`}>
                    <SwitchComponent
                        background="white"
                        label="Open In New Window"
                        switchStatus={
                            typeof openInNewWindow === 'boolean'
                                ? openInNewWindow
                                : true
                        }
                        onChange={setOpenInNewWindow}
                        disabled={link === ''}
                    />
                </div>
            ) : null}

            <style jsx={true}>
                {' '}
                {`
                    .link-select input.error {
                        border: 1px solid #dc3545;
                    }
                    .link-select label span.anticon {
                        color: #0b1c2c !important;
                        font-size: 13px;
                    }
                `}
            </style>
        </div>
    );
};

LivePagesList.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    link: PropTypes.string,
    setLink: PropTypes.string,
    isSubmitted: PropTypes.string,
    tooltipText: PropTypes.string,
    isRequired: PropTypes.string,
    livePagesList: PropTypes.array,
    paddingReset: PropTypes.bool,
    openInNewWindow: PropTypes.string,
    setOpenInNewWindow: PropTypes.string,
    setSelectPageId: PropTypes.func,
    returnShowcaseId: PropTypes.bool,
    logoRedirectLinkType: PropTypes.string,
};

LivePagesList.defaultProps = {
    livePagesList: [],
    paddingReset: false,
    setSelectPageId: () => { },
    returnShowcaseId: false,
    logoRedirectLinkType: '',
};

export default LivePagesList;
