import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import OTPInput from 'otp-input-react';
import ls from 'local-storage';
import moment from 'moment';
import FileSaver from 'file-saver';
import './TwoFA.scss';
import {
    getTwoFAConfiguration,
    enableTwoFA,
    downloadBackupCodes,
} from 'apis/rest/TwoFAAuth';

import TwoFAWrapper from './TwoFAWrapper';
import { BackupCodesIcon, TwoFASetupIcon, TwoFAThirdPartyIcon } from './icons';
import { updateUser } from '../../../redux/actions/common/userActions';

export function beautifySecretCode(secretCode) {
    return secretCode.match(/.{1,4}/g).join(' ');
}

const inputStyles = {
    width: '55px',
    height: '60px',
    borderWidth: '1px',
    // borderRadius: '0.375rem',
    fontSize: '1.85rem',
    lineHeight: '2.25rem',
    fontWeight: 800,
};
function TwoFASetup({
    payload,
    identifier,
    isLoading,
    setIsLoading,
    handleGoToLogin,
    handleGoToDashboard,
    checkUserRole,
    getLoadingScreen,
}) {
    const dispatch = useDispatch();
    const require2FASetup = ls.get('require2FASetup');
    const user = useSelector((state) => state.user);

    const [stepCount, setStepCount] = useState(0);
    const [qrCodeUri, setQrCodeUri] = useState('');
    const [secretCode, setSecretCode] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [enable2FAError, setEnable2FAError] = useState('');
    const [backupCodes, setBackupCodes] = useState([]);
    const [backupCodesCreatedAt, setBackupCodesCreatedAt] = useState('');
    const [isBackupCodesDownloading, setIsBackupCodesDownloading] =
        useState(false);
    async function handleGetTwoFAConfiguration() {
        try {
            if (isLoading) return;
            setIsLoading(true);
            const resp = await getTwoFAConfiguration();
            if (!resp?.status) throw resp;
            const { uri, code } = resp;
            setQrCodeUri(uri);
            setSecretCode(beautifySecretCode(code));
            setStepCount(1);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    async function handleEnableTwoFA() {
        try {
            if (isLoading) return;
            setIsLoading(true);
            const resp = await enableTwoFA({
                code: verificationCode,
                identifier,
            });
            if (!resp?.status) throw resp;
            ls.remove('require2FASetup');
            dispatch(updateUser({ user: { require2FASetup: false } }));
            setStepCount(3);
        } catch (error) {
            console.error(error);
            setEnable2FAError(error?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }

    async function handleDownloadBackupCodes(isJson) {
        try {
            if (isJson) {
                setIsLoading(true);
            } else {
                setIsBackupCodesDownloading(true);
            }
            const resp = await downloadBackupCodes(isJson);
            if (isJson) {
                if (!resp?.status) throw resp;
                setBackupCodes(resp?.recoveryCodes || []);
                setBackupCodesCreatedAt(
                    resp?.createdAt
                        ? moment(resp?.createdAt).format(
                              'MMM DD, YYYY [at] HH:mm (Z)'
                          )
                        : ''
                );
            } else {
                FileSaver.saveAs(
                    resp,
                    `gevme-backup-codes-${payload}-${moment().format(
                        'YYYY-MM-DD-HH-mm-ss'
                    )}.txt`
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsBackupCodesDownloading(false);
        }
    }

    useEffect(() => {
        if (stepCount !== 3) return;
        handleDownloadBackupCodes(true);
    }, [stepCount]);

    useEffect(() => {
        dispatch(updateUser({ user: { require2FASetup: !!require2FASetup } }));
        if (require2FASetup) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        if (user?.email) {
            checkUserRole();
        } else {
            handleGoToLogin();
            setIsLoading(false);
        }
    }, []);

    const getSetupScreen = () => (
        <>
            <div className="tw-w-auto tw-flex tw-flex-row tw-gap-3 tw-justify-center tw-items-center">
                <img
                    src="/assets/gevme-new-logo.png"
                    alt="GEVME"
                    className="tw-text-2xl tw-font-semibold tw-font-montserrat"
                />
            </div>
            <div className="tw-mt-8 tw-w-auto tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div>
                    <TwoFASetupIcon />
                </div>
            </div>
            <p className="tw-m-0 tw-p-0 tw-mt-2 tw-text-3xl tw-font-bold tw-font-montserrat">
                Help protect your account
            </p>
            <p className="tw-m-0 tw-p-0 tw-mt-2 tw-text-xs tw-font-light tw-text-center">
                For security, your administrator has made it compulsory for you
                to setup Two-Factor Authentication for your account. If we
                notice an attempted login from a device or browser we don’t
                recognise, we’ll ask for a verification code.
            </p>
            <p className="tw-m-0 tw-p-0 tw-mt-8 tw-w-full tw-text-sm tw-font-semibold tw-text-left">
                Authentication App
            </p>
            <p className="tw-m-0 tw-p-0 tw-mt-1.5 tw-w-full tw-text-xs tw-font-light tw-text-left">
                <span className="tw-text-green-500 tw-font-semibold">
                    Recommended
                </span>{' '}
                - Use an app like Google Authenticator to generate verification
                codes for more protection.
            </p>
            <button
                type="button"
                className="tw-w-auto tw-mt-4 tw-mr-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-blue-600 tw-bg-blue-600 tw-px-4 tw-py-2 tw-text-sm tw-text-white hover:tw-border-blue-500 hover:tw-bg-blue-500"
                disabled={isLoading}
                onClick={handleGetTwoFAConfiguration}
            >
                Use Authentication App
            </button>
            <button
                type="button"
                className="tw-w-auto tw-mt-4 tw-ml-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-semibold hover:tw-border-gray-400 hover:tw-bg-gray-50"
                disabled={isLoading}
                onClick={handleGoToLogin}
            >
                Back to login
            </button>
        </>
    );

    const getQRCodeScreen = () => (
        <>
            <div className="tw-flex tw-w-auto tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                <img
                    src="/assets/gevme-new-logo.png"
                    alt="GEVME"
                    className="tw-text-2xl tw-font-semibold tw-font-montserrat"
                />
            </div>
            <div className="tw-mt-6 tw-mr-auto tw-flex tw-w-auto tw-flex-row tw-items-start tw-justify-center tw-gap-3">
                <div>
                    <TwoFAThirdPartyIcon />
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-1">
                    <p className="tw-p-0 tw-m-0 tw-text-base tw-font-semibold tw-w-auto tw-text-left">
                        Set up via Third Party Authenticator
                    </p>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light tw-text-left">
                        Please use your authentication app (such as Google
                        Authenticator) to scan this QR code.
                    </p>
                </div>
            </div>
            <p className="tw-p-0 tw-m-0 tw-my-6 tw-h-px tw-w-full tw-bg-gray-200" />
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-around">
                <div>
                    {qrCodeUri ? (
                        <QRCode value={qrCodeUri} size={180} includeMargin />
                    ) : (
                        <div className="tw-w-[167px] tw-h-[140px] tw-text-base tw-font-bold tw-uppercase tw-text-gray-300 tw-flex tw-flex-row tw-justify-center tw-items-center tw-rounded tw-border tw-border-solid tw-border-gray-200">
                            No QR code
                        </div>
                    )}
                </div>
                <div className="tw-flex tw-w-auto tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-p-8">
                    <div>
                        <p className="tw-p-0 tw-m-0 tw-w-40 tw-text-xs tw-text-gray-600">
                            Or enter this code into your
                        </p>
                        <p className="tw-p-0 tw-m-0 tw-w-40 tw-text-xs tw-text-gray-600">
                            authentication app
                        </p>
                    </div>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-base tw-font-bold tw-uppercase">
                        {secretCode || (
                            <span className="tw-text-gray-300">
                                No secret code
                            </span>
                        )}
                    </p>
                </div>
            </div>
            <p className="tw-p-0 tw-m-0 tw-my-6 tw-h-px tw-w-full tw-bg-gray-200" />
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-end tw-gap-3">
                <button
                    type="button"
                    className="tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-semibold hover:tw-border-gray-400 hover:tw-bg-gray-50"
                    disabled={isLoading}
                    onClick={() => setStepCount(0)}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-blue-600 tw-bg-blue-600 tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold hover:tw-border-blue-500 hover:tw-bg-blue-500"
                    disabled={isLoading}
                    onClick={() => setStepCount(2)}
                >
                    Continue
                </button>
            </div>
        </>
    );

    const getVerifyCodeScreen = () => (
        <>
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                <img
                    src="/assets/gevme-new-logo.png"
                    alt="GEVME"
                    className="tw-text-2xl tw-font-semibold tw-font-montserrat"
                />
            </div>
            <div className="Twofa tw-mt-6 tw-w-full break-normal tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-center">
                <p className="tw-p-0 tw-m-0 tw-text-base tw-font-semibold tw-text-left">
                    Enter confirmation code
                </p>
                <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light tw-text-left">
                    Please enter the confirmation code you see on your
                    authentication app
                </p>
                <div className="tw-mt-12 tw-w-full">
                    <OTPInput
                        inputStyles={inputStyles}
                        className="tw-font-extrabold tw-text-2xl tw-justify-center"
                        value={verificationCode}
                        onChange={setVerificationCode}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={isLoading}
                    />
                </div>
                {enable2FAError ? (
                    <p className="tw-p-0 tw-m-0 tw-text-sm tw-text-rose-600 tw-font-semibold tw-text-left tw-mt-2">
                        {enable2FAError}
                    </p>
                ) : null}
            </div>
            <button
                type="button"
                className={`${
                    isLoading || verificationCode.length !== 6
                        ? 'tw-border-blue-600/50 tw-bg-blue-600/50 hover:tw-border-blue-500/50 hover:tw-bg-blue-500/50'
                        : 'tw-border-blue-600 tw-bg-blue-600 hover:tw-border-blue-500 hover:tw-bg-blue-500'
                } tw-mt-auto tw-ml-auto tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold`}
                disabled={isLoading || verificationCode.length !== 6}
                onClick={handleEnableTwoFA}
            >
                Continue
            </button>
        </>
    );

    const getBackupCodesAddedOn = () =>
        backupCodesCreatedAt ? (
            <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light">
                <span className="tw-text-gray-400">Added on</span>{' '}
                <span className="tw-font-semibold tw-text-gray-600">
                    {backupCodesCreatedAt}
                </span>
            </p>
        ) : null;

    const getBackupCodesScreen = () => (
        <>
            <div className="tw-flex tw-w-auto tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                <img
                    src="/assets/gevme-new-logo.png"
                    alt="GEVME"
                    className="tw-text-2xl tw-font-semibold tw-font-montserrat"
                />
            </div>
            <div className="tw-mt-6 tw-mr-auto tw-flex tw-w-auto tw-flex-row tw-items-start tw-justify-center tw-gap-3">
                <div>
                    <TwoFAThirdPartyIcon />
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-2">
                    <p className="tw-p-0 tw-m-0 tw-text-base tw-w-auto tw-font-semibold">
                        Two-factor authentication is{' '}
                        <span className="tw-text-green-500">on</span>
                    </p>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light tw-text-left">
                        We’ll ask for a code if we notice an attempted login
                        from an unrecognised device or browser.
                    </p>
                    {getBackupCodesAddedOn()}
                </div>
            </div>
            <div className="tw-mt-6 tw-mr-auto tw-flex tw-w-auto tw-flex-row tw-items-start tw-justify-center tw-gap-3">
                <div>
                    <BackupCodesIcon />
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-2">
                    <p className="tw-p-0 tw-m-0 tw-text-base tw-w-auto tw-font-semibold">
                        Backup Codes
                    </p>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light">
                        Use as an alternative to authentication app
                    </p>
                    <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light">
                        You have{' '}
                        <span className="tw-font-bold">
                            {backupCodes?.length}
                        </span>{' '}
                        unused backup codes.
                    </p>
                    {getBackupCodesAddedOn()}
                    <button
                        type="button"
                        className={`${
                            isBackupCodesDownloading
                                ? 'tw-border-blue-600/50 tw-bg-blue-600/50 hover:tw-border-blue-500/50 hover:tw-bg-blue-500/50'
                                : 'tw-border-blue-600 tw-bg-blue-600 hover:tw-border-blue-500 hover:tw-bg-blue-500'
                        } tw-mr-auto tw-mt-2 tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold`}
                        disabled={isBackupCodesDownloading}
                        onClick={() => handleDownloadBackupCodes(false)}
                    >
                        Save text file
                    </button>
                </div>
            </div>
            <button
                type="button"
                className="tw-mt-auto tw-ml-auto tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-blue-600 tw-bg-blue-600 tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold hover:tw-border-blue-500 hover:tw-bg-blue-500"
                disabled={isLoading}
                onClick={handleGoToDashboard}
            >
                Done
            </button>
        </>
    );

    const getStepScreen = () => {
        switch (stepCount) {
            case 0:
                return isLoading ? getLoadingScreen() : getSetupScreen();
            case 1:
                return isLoading ? getLoadingScreen() : getQRCodeScreen();
            case 2:
                return getVerifyCodeScreen();
            case 3:
                return isLoading ? getLoadingScreen() : getBackupCodesScreen();
            default:
                return null;
        }
    };

    return getStepScreen();
}

export default (props) => <TwoFAWrapper {...props} Component={TwoFASetup} />;
