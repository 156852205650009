import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, Carousel } from 'antd';
import { GetCompany } from 'apis/rest/sponsorship/GetCompany';

import RightArrowIcon from 'Apps/VenueBuilder/Icons/RightArrowIcon';
import LeftArrowIcon from 'Apps/VenueBuilder/Icons/LeftArrowIcon';

import { isEmpty } from 'lodash';
import MyBookmarksIcon from 'Apps/VenueBuilder/Icons/MyBookmarksIcon';
import 'antd/dist/antd.css';

import './previewModal.scss';
import {
    getYouTubeVideoId,
    getVimeoVideoId,
    checkYoutubeOrVimeoLink,
    createYouTubeThumbnailURL,
    createVimeoThumbnailURL,
} from '../helpers';
import sanitizeContent from 'utils/InputSanitization';
function PreviewModal({ visible, setVisible, data }) {
    const ref = useRef();
    const [currentCarouselItem, setCurrentCarouselItem] = useState(0);
    const [companyData, setCompanyData] = useState(null);

    useEffect(() => {
        if (!isEmpty(data.exhibitorCompanyProfileId)) {
            handleGetCompany(data?.exhibitorCompanyProfileId);
        }
    }, [data.exhibitorCompanyProfileId]);

    const handleGetCompany = async (companyId) => {
        try {
            const response = await GetCompany(companyId);
            if (response.status) {
                const resultData = response?.sponsor;
                setCompanyData(resultData);
            }
        } catch (error) {
            console.error('ERROR handleGetCompany', error);
        }
    };

    const Arrow = ({ type, width, height, className, onClick }) => {
        if (type === 'left') {
            return (
                <div onClick={onClick} className={className}>
                    <LeftArrowIcon width={width} height={height} />
                </div>
            );
        }
        if (type === 'right') {
            return (
                <div onClick={onClick} className={className}>
                    <RightArrowIcon width={width} height={height} />
                </div>
            );
        }
    };

    const VideoEmbed = ({ videoURL }) => {
        if (videoURL.includes('youtube')) {
            let videoId = getYouTubeVideoId(videoURL);
            return (
                <div>
                    <iframe
                        width="500"
                        height="380"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            );
        } else {
            let videoId = getVimeoVideoId(videoURL);
            return (
                <div>
                    <iframe
                        width="500"
                        height="380"
                        src={`https://player.vimeo.com/video/${videoId}`}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    />
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            );
        }
    };

    const renderItem = (item, i) => {
        if (checkYoutubeOrVimeoLink(item)) {
            return (
                <div className="product-carousel-item">
                    <VideoEmbed videoURL={item} />
                </div>
            );
        } else {
            return (
                <div className="product-carousel-item">
                    <img src={item} />
                </div>
            );
        }
    };

    const renderThumbnailItem = (item, i) => {
        if (checkYoutubeOrVimeoLink(item)) {
            if (item.includes('youtube')) {
                let videoId = getYouTubeVideoId(item);
                return (
                    <div
                        onClick={() => ref?.current?.goTo(i, false)}
                        className={`product-carousel-thumbnail-item ${
                            currentCarouselItem === i
                                ? 'border-dark'
                                : 'border-white'
                        }`}
                    >
                        <img
                            className="img-fluid"
                            src={createYouTubeThumbnailURL(videoId)}
                        ></img>
                    </div>
                );
            } else {
                let videoId = getVimeoVideoId(item);

                return (
                    <div
                        onClick={() => ref?.current?.goTo(i, false)}
                        className={`product-carousel-thumbnail-item ${
                            currentCarouselItem === i
                                ? 'border-dark'
                                : 'border-white'
                        }`}
                    >
                        <img
                            className="img-fluid"
                            src={createVimeoThumbnailURL(videoId)}
                        ></img>
                    </div>
                );
            }
        } else {
            return (
                <div
                    onClick={() => ref?.current?.goTo(i, false)}
                    className={`product-carousel-thumbnail-item ${
                        currentCarouselItem === i
                            ? 'border-dark'
                            : 'border-white'
                    }`}
                >
                    <img className="img-fluid" src={item} />
                </div>
            );
        }
    };
    const handleCarouselChange = useCallback(
        (newPosition) => {
            setCurrentCarouselItem(newPosition);
        },
        [currentCarouselItem, setCurrentCarouselItem]
    );

    return (
        <Modal
            destroyOnClose={true}
            forceRender={true}
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            width={936}
            title="Preview"
            className="product-preview-modal"
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        {!isEmpty(data?.productGalleryItems) && (
                            <>
                                <Carousel
                                    dots={false}
                                    arrows={true}
                                    prevArrow={<Arrow type="left" />}
                                    nextArrow={<Arrow type="right" />}
                                    ref={ref}
                                    swipe={true}
                                    afterChange={handleCarouselChange}
                                >
                                    {data?.productGalleryItems.map(
                                        (item, i) => {
                                            return renderItem(item, i);
                                        }
                                    )}
                                </Carousel>
                                <Carousel
                                    className="mt-2"
                                    dots={false}
                                    arrows={true}
                                    prevArrow={
                                        <Arrow
                                            type="left"
                                            width={10}
                                            height={20}
                                        />
                                    }
                                    nextArrow={
                                        <Arrow
                                            type="right"
                                            width={10}
                                            height={20}
                                        />
                                    }
                                    slidesToShow={4}
                                    infinite={false}
                                >
                                    {data?.productGalleryItems.map(
                                        (item, i) => {
                                            return renderThumbnailItem(item, i);
                                        }
                                    )}
                                </Carousel>
                            </>
                        )}
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <h1 className="product-header-text">
                                    {data?.name}
                                </h1>
                                <MyBookmarksIcon />
                            </div>
                            {!isEmpty(companyData) && (
                                <div className="col-12 mb-3">
                                    <p className="product-company-name mb-0">
                                        by{' '}
                                        <a
                                            target="_blank"
                                            className="text-primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let companyPageURL =
                                                    companyData?.defaultBoothUrl;
                                                if (!isEmpty(companyPageURL)) {
                                                    window.open(companyPageURL);
                                                }
                                            }}
                                        >
                                            {companyData?.name}
                                        </a>
                                    </p>
                                </div>
                            )}
                            {data?.topicTags?.length > 0 && (
                                <div className="col-12 mb-3">
                                    {data?.topicTags.map((item, i) => (
                                        <span
                                            className="badge badge-topics"
                                            key={'topic-tags-' + i}
                                        >
                                            {item}
                                        </span>
                                    ))}
                                </div>
                            )}
                            {!isEmpty(data?.description) && (
                                <div className="col-12 mb-3">
                                    <div className="product-company-description-container ">
                                        {data?.description.length > 370 && (
                                            <>
                                                <input
                                                    id="ch"
                                                    type="checkbox"
                                                />
                                                <label for="ch"></label>
                                            </>
                                        )}

                                        <p
                                            className="product-company-description"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeContent({
                                                    str: data?.description?.replace(
                                                        /\n/g,
                                                        '<br />'
                                                    ),
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.resources) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Resources
                                        </p>
                                        <ul className="list-unstyled">
                                            {data?.resources.map((item, i) => {
                                                return (
                                                    <li
                                                        key={'resources-' + i}
                                                        className="d-flex align-items-center mb-1"
                                                    >
                                                        <img
                                                            src={item?.icon}
                                                            className="img-fluid"
                                                            style={{
                                                                height: 25,
                                                            }}
                                                        />
                                                        <a
                                                            className="ml-2"
                                                            target="_blank"
                                                            href={item?.url}
                                                        >
                                                            {item?.name}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.custom1) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Custom field 1
                                        </p>
                                        <p className="product-company-content-desc">
                                            {data?.custom1}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.custom2) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Custom field 2
                                        </p>
                                        <p className="product-company-content-desc">
                                            {data?.custom2}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.custom3) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Custom field 3
                                        </p>
                                        <p className="product-company-content-desc">
                                            {data?.custom3}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.custom4) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Custom field 4
                                        </p>
                                        <p className="product-company-content-desc">
                                            {data?.custom4}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isEmpty(data?.custom5) && (
                                <div className="col-12">
                                    <div className="product-company-content">
                                        <p className="product-company-content-header mb-1">
                                            Custom field 5
                                        </p>
                                        <p className="product-company-content-desc">
                                            {data?.custom5}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {data?.enableCta && (
                                <div className="col-12">
                                    <hr className="my-3" />
                                    <button
                                        className={`myxp btn btn-sm ml-0 mt-2 w-100`}
                                        onClick={() =>
                                            !isEmpty(data?.ctaLink)
                                                ? window.open(
                                                      data?.ctaLink,
                                                      '_blank'
                                                  )
                                                : false
                                        }
                                        style={{
                                            whiteSpace: 'nowrap',
                                            backgroundColor:
                                                data?.ctaBackgroundColor,
                                            color: data?.ctaColor,
                                        }}
                                    >
                                        {data?.ctaLabel}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

PreviewModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func,
    data: PropTypes.object,
};

PreviewModal.defaultProps = {
    visible: false,
    setVisible: () => {},
    data: null,
};

export default PreviewModal;
