import * as React from 'react';

const ArrowUpIcon = (props) => {
    return (
        <svg
            width={15}
            height={8}
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                d="M0 6.83a1 1 0 01.37-.78l6-4.83a1 1 0 011.27 0l6 5a1.001 1.001 0 11-1.28 1.54L7 3.29 1.64 7.61A1 1 0 010 6.83z"
                fill="#000"
            />
        </svg>
    );
};

export default ArrowUpIcon;
