import React from 'react';

export default function PlayIcon({ stroke = '#fff' }) {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91973 3.89776 2.12532 6.08427 1.06569 8.64243C0.00606598 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9957 10.2883 26.5193 6.72982 23.8948 4.10524C21.2702 1.48066 17.7117 0.00428993 14 0V0ZM18.9051 14.896L12.4435 19.2037C12.2814 19.312 12.0928 19.3743 11.8981 19.3838C11.7033 19.3933 11.5097 19.3497 11.3377 19.2577C11.1658 19.1657 11.0221 19.0287 10.922 18.8614C10.8219 18.6941 10.7691 18.5027 10.7692 18.3077V9.69231C10.7691 9.49732 10.8219 9.30594 10.922 9.13862C11.0221 8.9713 11.1658 8.83433 11.3377 8.74232C11.5097 8.65031 11.7033 8.60674 11.8981 8.61624C12.0928 8.62574 12.2814 8.68797 12.4435 8.79627L18.9051 13.104C19.0526 13.2023 19.1735 13.3355 19.2572 13.4918C19.3408 13.6482 19.3846 13.8227 19.3846 14C19.3846 14.1773 19.3408 14.3518 19.2572 14.5081C19.1735 14.6645 19.0526 14.7977 18.9051 14.896V14.896Z"
                fill={stroke || '#fff'}
            />
        </svg>
    );
}
