import { HttpLink } from 'apollo-link-http';
import * as ls from 'local-storage';

const getProjectId = () => {
    try {
        const pathname = window.location.pathname;
        return pathname.split('/project/')[1].split('/')[0];
    } catch (error) {
        console.error(error);
        return null;
    }
};

const customStrapiFetch = (options) => {
    let projectId = getProjectId();

    if (projectId) {
        return `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/graphql?projectId=${projectId}`;
    }
    return `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/graphql`;
};

const customCrowdFlowFetch = (options) => {
    let projectId = getProjectId();

    return projectId
        ? `${process.env.REACT_APP_MYXP_API}/manager/livestream/people/graphql?projectId=${projectId}`
        : `${process.env.REACT_APP_MYXP_API}/manager/livestream/people/graphql`;
};

// Crowdflow Client
const getToken = () => {
    const auth = ls.get('auth');
    if (auth) {
        return `Bearer ${auth?.access_token}`;
    }
};

// Strapi Client
export const strapi = new HttpLink({
    uri: customStrapiFetch,
    headers: {
        Authorization: getToken(),
    },
    credentials: 'include',
});

export const crowdflow = new HttpLink({
    uri: customCrowdFlowFetch,
    headers: {
        Authorization: getToken(),
    },
    credentials: 'include',
});
