import * as React from 'react';

const FeaturedContentBlockIcon = (props) => {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <rect width={32} height={32} rx={4} fill="#E1E7FA" />
            <rect x={9} y={6} width={14} height={3} rx={1.5} fill="#B9C1DB" />
            <rect x={3} y={11} width={8} height={14} rx={2} fill="#7A829D" />
            <rect x={12} y={11} width={8} height={14} rx={2} fill="#7A829D" />
            <rect x={21} y={11} width={8} height={14} rx={2} fill="#7A829D" />
        </svg>
    );
};

export default FeaturedContentBlockIcon;
