import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Space, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';

import moment from 'moment';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import './scheduledTable.scss';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../../../../../../../ComponentsV2/CommonComponents/MobileView/noDataComponent/NoDataComponent';

const ScheduledTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickCancelPoll,
    onClickDuplicatePoll,
    setSorter,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const sortDirections = ['descend', 'ascend', 'descend'];

    const [searchText, setSearchText] = useState('');

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    useEffect(() => {
        const trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder="Search"
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        const searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (a, b, sorter) => {
        const { field, order } = sorter;

        setSorter({
            sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
            sortBy: field,
        });
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            sorter: true,
            sortDirections,
            ...getColumnSearchProps('question'),
        },
        {
            title: 'Scheduled to be sent on',
            dataIndex: 'scheduledTime',
            render: (scheduledTime, item) => {
                if (!scheduledTime) return null;
                return (
                    <span>
                        {moment(scheduledTime)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] HH:mm')}
                    </span>
                );
            },
        },
        {
            title: 'Scheduled to be sent in',
            dataIndex: 'scheduledTime',
            render: (scheduledTime, item) => {
                if (!scheduledTime) return null;
                return (
                    <Countdown
                        date={scheduledTime}
                        renderer={({ days, hours, minutes, seconds }) => {
                            if (!days && !hours && !minutes && !seconds) {
                                return <span>Notification sent</span>;
                            }
                            return (
                                <span>{`${days ? `${days} Days` : ''} ${
                                    hours ? `${hours} Hrs` : ''
                                } ${minutes} Mins ${seconds} Secs`}</span>
                            );
                        }}
                    />
                );
            },
        },
        {
            title: 'Target',
            dataIndex: 'target',
            sorter: true,
            sortDirections,
            render: (target, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : target?.length}
                    </span>
                );
            },
        },
        {
            title: 'Created by',
            dataIndex: 'createdByUser',
            render: (createdByUser, item) => {
                return <span>{createdByUser}</span>;
            },
        },

        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link text-danger"
                            onClick={() => onClickCancelPoll(item.id)}
                        >
                            Cancel schedule
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link tw-hidden md:tw-block"
                            onClick={() =>
                                history.push({
                                    pathname: `/company/${companyId}/project/${projectId}/notification/edit-poll/${item.id}`,
                                    state: { previewMode: true },
                                })
                            }
                        >
                            View
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => onClickDuplicatePoll(item.id)}
                        >
                            Duplicate
                        </button>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: (
            <GlobalLoading
                loadingText="Fetching data..."
                height={45}
                width={45}
            />
        ),
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            {!loading && data?.polls.length ? (
                <Table
                    onChange={handleChange}
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.polls}
                    loading={globalLoadingComponent}
                    pagination={{
                        itemRender: renderPagination,
                        pageSize: 10,
                        total: data?.pagination?.total,
                        current: data?.pagination?.currentPage,
                    }}
                    scroll={{ x: 767 }}
                />
            ) : (
                <NoDataComponent
                    loading={loading}
                    buttonText="Create poll"
                    description="No poll have been Created"
                    onClickHandler={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/notification/create-poll`
                        )
                    }
                />
            )}
        </>
    );
};

ScheduledTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    loading: PropTypes.bool,
    setSorter: PropTypes.func,
};

ScheduledTable.defaultProps = {
    data: null,
    loading: false,
};

export default ScheduledTable;
