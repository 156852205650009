import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * Get all projects for Livestream
 */
export async function getProjects(
    page = 1,
    limit = 10,
    searchQuery = '',
    archive = false,
    sortBy = 'updatedAt',
    sortOrder = true,
    companyId
) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;
        searchQuery = searchQuery.length ? `&search=${searchQuery}` : '';
        let limitQuery = `limit=${limit}`,
            pageQuery = `&page=${page}`,
            archiveQuery = !archive ? `&archive=false` : '', // remove archive query when showing archive, it defaults to true
            sortByQuery = `&sortBy=${sortBy}`,
            sortOrderQuery = `&sortOrder=${sortOrder ? 'desc' : 'asc'}`,
            sortQuery = sortByQuery + sortOrderQuery,
            buildQuery =
                limitQuery + pageQuery + searchQuery + archiveQuery + sortQuery;

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/projects?companyId=${companyId}&${buildQuery}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
