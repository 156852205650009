import React from 'react'
import PropTypes from 'prop-types'

import { CloseOutlined } from '@ant-design/icons';

import './notificationBox.scss';

export const POSITIONS = {
    TOP_LEFT: 'topLeft',
    TOP_CENTERED: 'topCentered',
    TOP_RIGHT: 'topRight',
    CENTERED: 'centered',
    BOTTOM_LEFT: 'bottomLeft',
    BOTTOM_CENTERED: 'bottomCentered',
    BOTTOM_RIGHT: 'bottomRight'
}
function NotificationBox({ position, image, subTitle, title, description, buttonLabel, buttonURL, buttonColor, buttonTextColor, ctaVisible, senderVisible, previewMode }) {
    const renderPositionCSS = () => {
        switch (position) {
            case POSITIONS.TOP_LEFT:
                return ({ top: 10, left: 15 })
            case POSITIONS.TOP_CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto', top: 15, maxWidth: 540 })
            case POSITIONS.TOP_RIGHT:
                return ({ top: 10, right: 15 })
            case POSITIONS.CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto' })
            case POSITIONS.BOTTOM_LEFT:
                return ({ bottom: 10, left: 15 })
            case POSITIONS.BOTTOM_CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto', bottom: 15, maxWidth: 540 })
            case POSITIONS.BOTTOM_RIGHT:
                return ({ bottom: 10, right: 15 })
            default:
                return ({ bottom: 10, left: 15 })
        }
    }
    if (!title) return null
    return (
        <div className={`notification-box-container shadow p-3 mb-5 bg-white rounded ${previewMode ? 'position-relative mx-auto' : 'position-absolute'}`} style={!previewMode ? renderPositionCSS() : null}>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {senderVisible &&
                        <>
                            {image &&
                                <img src={image} className="img-fluid rounded-circle mr-2 " />
                            }
                            <p className="mb-0 sub-title">{subTitle}</p>
                        </>
                    }
                </div>
                <div>
                    <CloseOutlined role="button" />
                </div>
            </div>
            <div className={`${senderVisible ? 'mt-2' : ''} ${position === POSITIONS.TOP_CENTERED ? 'd-flex' : ''} ${position === POSITIONS.BOTTOM_CENTERED ? 'd-flex' : ''}`}>
                <div className={`${position === POSITIONS.TOP_CENTERED ? 'w-100' : ''} ${position === POSITIONS.BOTTOM_CENTERED ? 'w-100' : ''}`}>
                    <h3 className="mb-1 title">{title}</h3>
                    <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>

                {ctaVisible &&
                    <button className={`myxp btn btn-sm ml-0 mt-2 w-100 ${position === POSITIONS.TOP_CENTERED ? 'w-auto ml-2' : ''} ${position === POSITIONS.BOTTOM_CENTERED ? 'w-auto ml-2' : ''}`} style={{ whiteSpace: 'nowrap', backgroundColor: buttonColor, color: buttonTextColor, }}>{buttonLabel}</button>
                }
            </div>
        </div>
    )
}

NotificationBox.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.string,
    image: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    ctaVisible: PropTypes.bool,
    senderVisible: PropTypes.bool,
    previewMode: PropTypes.bool,
}

NotificationBox.defaultProps = {
    position: POSITIONS.BOTTOM_LEFT,
    image: null,
    subTitle: null,
    title: null,
    description: null,
    buttonLabel: null,
    buttonColor: '#e2e9f3',
    buttonTextColor: '#000000',
    ctaVisible: false,
    senderVisible: false,
    previewMode: false
};

export default NotificationBox

