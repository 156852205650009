export const UPDATE_VIEW = 'UPDATE_VIEW',
    UPDATE_SIDEBAR_MENU = 'UPDATE_SIDEBAR_MENU',
    UPDATE_BLOCK_ORDER = 'UPDATE_BLOCK_ORDER',
    CATCH_ERROR = 'CATCH_ERROR',
    UPDATE_VENUE_BUILDER = 'UPDATE_VENUE_BUILDER',
    UPDATE_LIVEPAGES_LIST = 'UPDATE_LIVEPAGES_LIST',
    UPDATE_VERSION = 'UPDATE_VERSION',
    UPDATE_PREVIEW_TOKEN = 'UPDATE_PREVIEW_TOKEN',
    ADD_CONTENT_BLOCK_SUCCESS = 'ADD_CONTENT_BLOCK_SUCCESS',
    EDIT_CONTENT_BLOCK_SUCCESS = 'EDIT_CONTENT_BLOCK_SUCCESS',
    UPDATE_LIVEPAGE_STATUS = 'UPDATE_LIVEPAGE_STATUS',
    UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS',
    DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS',
    UPDATE_LIVEBAR_SETTINGS = 'UPDATE_LIVEBAR_SETTINGS',
    UPDATE_BLOCK_VISIBILITY = 'UPDATE_BLOCK_VISIBILITY',
    UPDATE_MENU_LIST = 'UPDATE_MENU_LIST',
    DELETE_IMAGE = 'DELETE_IMAGE',
    REMOVE_IMAGE_URL = 'REMOVE_IMAGE_URL',
    GET_SHOWCASE_BLOCK_CRITERIA = 'GET_SHOWCASE_BLOCK_CRITERIA',
    CREATE_SHOWCASE_BLOCK_CRITERIA = 'CREATE_SHOWCASE_BLOCK_CRITERIA',
    DELETE_SHOWCASE_BLOCK_CRITERIA = 'DELETE_SHOWCASE_BLOCK_CRITERIA',
    SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT',
    SET_PREVIEW_LOADING = 'SET_PREVIEW_LOADING',
    SET_SUBMIT_LOADING = 'SET_SUBMIT_LOADING',
    GEVME_LIVE_CHANNELS = 'GEVME_LIVE_CHANNELS',
    GEME_LIVE_PEOPLE = 'GEME_LIVE_PEOPLE',
    SET_VENUE_DETAILS = 'SET_VENUE_DETAILS',
    SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS',
    UPDATE_VERSION_COUNT = 'UPDATE_VERSION_COUNT';
