import React from 'react';
import { Button, Space, notification } from 'antd';
import { isEmpty } from 'lodash';
import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';

export const openNotification = (
    type,
    data,
    duration = 3,
    placement = 'bottomLeft'
) => {
    try {
        const { message, title, confirm, customClass } = data;

        if (message === 'ACCESS_TOKEN_EXPIRED') {
            handleLogout(true);
        }

        const key = Date.now();
        const btn = (
            <Space>
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => notification.close(key)}
                >
                    Close
                </Button>
            </Space>
        );

        const close = () => {};

        if (!isEmpty(data)) {
            if (type !== 'confirm') {
                notification[type]({
                    message: title || type,
                    description: message,
                    key,
                    onClose: close,
                    placement,
                    duration,
                    className: `${type} ${customClass || ''}`,
                });
            } else {
                notification[type]({
                    message: title || type,
                    description: message,
                    btn,
                    key,
                    onClose: close,
                    placement,
                    duration: 0,
                    className: `${type} ${customClass || ''}`,
                });
            }
        }
    } catch (error) {
        console.error(error.message);
    }
};
