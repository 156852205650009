

export function setVenues(payload) {
    return { type: 'SET_VENUES', payload };
}

export function setVenueStore(payload) {
    return { type: 'SET_VENUE_STORE', payload };
}

export function setVenueCollection(payload) {
    return { type: 'SET_VENUE_COLLECTIONS', payload };
}

export function setVenueDetails(payload) {
    return { type: 'SET_VENUE_STORE_DETAILS', payload };
}

export function setCompanyVenue(payload) {
    return { type: 'COMPANY_HAS_VENUE', payload };
}

export function setVenueSettings(payload) {
    return { type: 'SAVE_VENUE_SETTINGS', payload };
}

export function deleteVenueCollection(payload) {
    return { type: 'DELETE_VENUE_COLLECTION', payload };
}

export function setVenueId(payload) {
    return { type: 'SET_VENUE_ID', payload };
}

export function setCompanyVenueDetails(payload) {
    return { type: 'SET_COMPANY_VENUE', payload };
}
