import TagManager from 'react-gtm-module';
import {
    UPDATE_LIVEPAGES_LIST,
    UPDATE_VERSION,
    UPDATE_VIEW,
    UPDATE_LIVEPAGE_STATUS,
    SET_PREVIEW_LOADING,
    SET_SUBMIT_LOADING,
} from '../../../redux/actionTypes';
import { getLivePages } from '../../../../../apis/rest/livestream/GetLivePages';
import setLivepageStatus from '../../../apis/setLivepageStatus';

import { openNotification } from '../../../helpers/openNotification';
import setWidgetPageStatus from '../../../apis/setWidgetPageStatus';

export const updateLivepagelist =
    (srchVal, projectId) => async (dispatch, getState) => {
        try {
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                0,
                srchVal ? srchVal : '',
                [],
                '',
                1,
                '',
                ''
            );
            if (!showcases || showcases.length === 0) throw 'failed';

            const upHeader = { ...getState()['header'] };
            upHeader.selection.livePagesList = showcases;

            dispatch({
                type: UPDATE_LIVEPAGES_LIST,
                payload: {
                    header: upHeader,
                },
            });

            return showcases;
        } catch (error) {
            console.error(error);
            return 'failed';
        }
    };

export const livePagePaginate = (projectId) => async (dispatch, getState) => {
    try {
        const { header } = getState();
        const pageList = header.selection?.livePagesList;

        if (pageList.length < header.selection?.totalPages) {
            const { showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                20,
                pageList.length,
                '',
                [],
                '',
                1,
                '',
                ''
            );

            if (!showcases || showcases.length === 0) throw 'Failed';

            const mergedShowcases = [...pageList, ...showcases];

            // Removing duplicate(s)
            let set = new Set();
            const unionArray = mergedShowcases.filter((item) => {
                if (!set.has(item.id)) {
                    set.add(item.id);
                    return true;
                }
                return false;
            }, set);

            dispatch({
                type: UPDATE_LIVEPAGES_LIST,
                payload: {
                    header: {
                        ...header,
                        selection: {
                            ...header.selection,
                            livePagesList: unionArray,
                        },
                    },
                },
            });
        }
    } catch (error) {
        console.error(error);
        return 'failed';
    }
};

export const updateView = (displayMode) => (dispatch) => {
    const payload = {
        display: {
            mobile: displayMode === 'mobile',
            tablet: displayMode === 'tablet',
            desktop: displayMode === 'desktop',
        },
    };

    dispatch({
        type: UPDATE_VIEW,
        payload: payload,
    });
};

export const updateLivePageStatus =
    (livepageId, updatedPageStatus, projectId, callback) =>
    async (dispatch, getState) => {
        try {
            let res;
            const selection = getState()?.header?.selection;
            dispatch({ type: SET_SUBMIT_LOADING, payload: { status: true } });
            dispatch({ type: SET_PREVIEW_LOADING, payload: true });
            dispatch({
                type: UPDATE_LIVEPAGE_STATUS,
                payload: { isPublished: updatedPageStatus },
            });

            if (selection?.type === 'widget') {
                res = await setWidgetPageStatus({
                    livepageId,
                    status: updatedPageStatus ? 'publish' : 'unpublish',
                    projectId,
                });
            } else {
                res = await setLivepageStatus({
                    livepageId,
                    status: updatedPageStatus ? 'publish' : 'unpublish',
                    projectId,
                });
            }

            if (res.status) {
                if (res?.firstLivepagePublished) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'firstLivepagePublished',
                            info: {
                                livepageId,
                            },
                        },
                    });

                    if (window?.userGuiding?.previewGuide) {
                        window.userGuiding.previewGuide(
                            parseInt(process.env.REACT_APP_AHA_MOMENT_GUIDE_ID)
                        );
                    }
                }

                openNotification('success', { message: res.message });
                dispatch({ type: UPDATE_VERSION, payload: 'saved' });
                dispatch({
                    type: SET_SUBMIT_LOADING,
                    payload: { status: false },
                });
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: UPDATE_LIVEPAGE_STATUS,
                payload: { isPublished: !updatedPageStatus },
            });
            openNotification('error', { message: error.message });
            dispatch({ type: SET_SUBMIT_LOADING, payload: { status: false } });
            dispatch({ type: SET_PREVIEW_LOADING, payload: false });
        } finally {
            callback && callback();
        }
    };
