import * as React from 'react';

const UnexpectedErrorIcon = () => {
    return (
        <svg
            width="399"
            height="345"
            viewBox="0 0 399 345"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3780_156908)">
                <path
                    d="M198.89 316.64C305.972 316.64 392.78 311.572 392.78 305.32C392.78 299.068 305.972 294 198.89 294C91.8075 294 5 299.068 5 305.32C5 311.572 91.8075 316.64 198.89 316.64Z"
                    fill="white"
                />
                <path
                    d="M142.37 132.44L129.72 299.44H251.72L264.37 132.44H142.37ZM219.08 250.44C218.49 257.106 215.451 263.317 210.549 267.873C205.647 272.429 199.232 275.007 192.54 275.11C189.372 275.159 186.229 274.54 183.317 273.293C180.404 272.045 177.787 270.198 175.637 267.871C173.486 265.544 171.85 262.79 170.836 259.788C169.822 256.786 169.452 253.604 169.75 250.45L174.2 191.75C174.79 185.086 177.828 178.876 182.728 174.32C187.627 169.764 194.041 167.185 200.73 167.08C203.9 167.029 207.046 167.648 209.961 168.895C212.876 170.142 215.495 171.99 217.648 174.318C219.8 176.646 221.437 179.402 222.453 182.406C223.468 185.409 223.839 188.593 223.54 191.75L219.08 250.44Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M290.41 299.4H112.54L113.57 288.45H291.44L290.41 299.4Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M358.76 237.41H374.54C374.751 237.41 374.961 237.367 375.155 237.284C375.349 237.201 375.524 237.078 375.669 236.925C375.814 236.771 375.927 236.589 375.999 236.391C376.071 236.192 376.102 235.981 376.09 235.77L376.03 234.65C376.005 234.212 375.814 233.8 375.497 233.498C375.179 233.195 374.759 233.024 374.32 233.02H358.54C358.329 233.018 358.119 233.06 357.925 233.142C357.73 233.224 357.554 233.346 357.408 233.498C357.262 233.651 357.149 233.832 357.075 234.03C357.002 234.228 356.969 234.439 356.98 234.65L357.04 235.77C357.065 236.21 357.257 236.625 357.577 236.929C357.896 237.234 358.319 237.406 358.76 237.41V237.41Z"
                    fill="#E0E0E0"
                />
                <path
                    d="M332.43 237.41H348.18C348.392 237.412 348.602 237.37 348.797 237.287C348.991 237.204 349.167 237.082 349.312 236.928C349.458 236.774 349.57 236.592 349.642 236.392C349.714 236.193 349.744 235.981 349.73 235.77L349.67 234.65C349.645 234.212 349.454 233.8 349.137 233.498C348.819 233.195 348.399 233.024 347.96 233.02H332.21C331.999 233.018 331.789 233.06 331.595 233.142C331.4 233.224 331.224 233.346 331.078 233.498C330.932 233.651 330.819 233.832 330.745 234.03C330.671 234.228 330.639 234.439 330.65 234.65L330.71 235.77C330.738 236.21 330.93 236.623 331.249 236.927C331.568 237.231 331.99 237.403 332.43 237.41V237.41Z"
                    fill="#E0E0E0"
                />
                <path
                    d="M386.77 236.17H19.1402C17.074 236.148 15.093 235.343 13.597 233.918C12.1011 232.493 11.2016 230.553 11.0802 228.49L0.0101621 7.67C-0.0518063 6.67877 0.0913963 5.68541 0.430816 4.75204C0.770237 3.81867 1.29857 2.96537 1.98279 2.24548C2.66701 1.5256 3.49239 0.954623 4.40732 0.568254C5.32225 0.181885 6.30706 -0.011563 7.30016 1.21945e-06H374.93C376.995 0.0192291 378.976 0.821865 380.473 2.24566C381.969 3.66946 382.869 5.6082 382.99 7.67L394.06 228.49C394.122 229.482 393.979 230.475 393.64 231.409C393.3 232.343 392.772 233.197 392.088 233.918C391.404 234.639 390.579 235.21 389.664 235.598C388.749 235.985 387.764 236.18 386.77 236.17V236.17Z"
                    fill="#E0E0E0"
                />
                <path
                    d="M391.27 236.17H23.6499C21.5765 236.156 19.5857 235.355 18.0801 233.93C16.5746 232.504 15.6666 230.56 15.5399 228.49L4.53993 7.67C4.4781 6.68046 4.62074 5.6888 4.95895 4.75681C5.29716 3.82481 5.8237 2.97246 6.50576 2.25288C7.18782 1.53329 8.01077 0.961895 8.92333 0.574297C9.83589 0.186699 10.8185 -0.00879427 11.8099 1.88852e-06H379.43C381.502 0.0113397 383.493 0.809933 384.999 2.23404C386.505 3.65815 387.413 5.60132 387.54 7.67L398.54 228.49C398.602 229.48 398.459 230.472 398.121 231.405C397.783 232.337 397.256 233.19 396.575 233.911C395.893 234.631 395.07 235.203 394.157 235.592C393.245 235.981 392.262 236.177 391.27 236.17Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M25.8003 220.76L15.5403 15.41C15.5032 14.7889 15.5943 14.1669 15.8077 13.5825C16.0211 12.9981 16.3524 12.4638 16.7809 12.0127C17.2094 11.5617 17.7261 11.2035 18.2988 10.9605C18.8716 10.7175 19.4881 10.5948 20.1103 10.6H372.23C373.525 10.6106 374.768 11.1136 375.706 12.0069C376.644 12.9002 377.207 14.1169 377.28 15.41L387.54 220.76C387.579 221.381 387.489 222.002 387.277 222.587C387.064 223.171 386.734 223.706 386.306 224.157C385.879 224.608 385.363 224.966 384.79 225.209C384.218 225.453 383.602 225.575 382.98 225.57H30.8503C29.5558 225.557 28.3145 225.053 27.3772 224.16C26.4398 223.268 25.8762 222.052 25.8003 220.76V220.76Z"
                    fill="#F5F5F5"
                />
                <path
                    d="M37.2 21.1C37.2356 21.6467 37.1585 22.195 36.9735 22.7107C36.7885 23.2264 36.4995 23.6986 36.1245 24.0981C35.7494 24.4975 35.2963 24.8156 34.7932 25.0327C34.2902 25.2497 33.7479 25.3612 33.2 25.36C32.0543 25.3469 30.9561 24.9001 30.1267 24.1096C29.2973 23.3191 28.7982 22.2437 28.73 21.1C28.6958 20.5541 28.7739 20.0069 28.9596 19.4924C29.1453 18.9779 29.4345 18.5069 29.8094 18.1085C30.1843 17.7102 30.6369 17.393 31.1392 17.1765C31.6416 16.9601 32.183 16.8489 32.73 16.85C33.8755 16.8583 34.9749 17.3021 35.8051 18.0914C36.6353 18.8807 37.134 19.9564 37.2 21.1V21.1Z"
                    fill="white"
                />
                <path
                    d="M56.47 21.1C56.5056 21.6467 56.4285 22.195 56.2435 22.7107C56.0585 23.2264 55.7695 23.6986 55.3945 24.0981C55.0194 24.4975 54.5663 24.8156 54.0633 25.0327C53.5602 25.2497 53.0179 25.3612 52.47 25.36C51.3243 25.3469 50.2262 24.9001 49.3967 24.1096C48.5673 23.3191 48.0682 22.2437 48 21.1C47.9658 20.5541 48.044 20.0069 48.2296 19.4924C48.4153 18.9779 48.7045 18.5069 49.0794 18.1085C49.4543 17.7102 49.9069 17.393 50.4092 17.1765C50.9116 16.9601 51.453 16.8489 52 16.85C53.1455 16.8583 54.2449 17.3021 55.0751 18.0914C55.9053 18.8807 56.404 19.9564 56.47 21.1V21.1Z"
                    fill="white"
                />
                <path
                    d="M75.75 21.1C75.7857 21.6467 75.7086 22.195 75.5236 22.7107C75.3385 23.2264 75.0496 23.6986 74.6745 24.0981C74.2995 24.4975 73.8464 24.8156 73.3433 25.0327C72.8402 25.2497 72.2979 25.3612 71.75 25.36C70.6047 25.3491 69.5063 24.9027 68.678 24.1115C67.8497 23.3204 67.3535 22.2437 67.29 21.1C67.2559 20.5541 67.334 20.0069 67.5197 19.4924C67.7053 18.9779 67.9945 18.5069 68.3694 18.1085C68.7443 17.7102 69.1969 17.393 69.6993 17.1765C70.2016 16.9601 70.743 16.8489 71.29 16.85C72.4331 16.8631 73.5287 17.3089 74.3563 18.0975C75.1838 18.8861 75.6818 19.9589 75.75 21.1V21.1Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M62.4102 225.57H113.16L159.56 10.6H108.81L62.4102 225.57Z"
                        fill="white"
                    />
                    <path
                        opacity="0.6"
                        d="M280.82 225.57H293.67L340.07 10.6H327.22L280.82 225.57Z"
                        fill="white"
                    />
                    <path
                        opacity="0.6"
                        d="M124.44 225.57H196.45L242.85 10.6H170.84L124.44 225.57Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M155.75 28L103.69 80.07C101.07 137.78 101.28 198.4 103.69 261.25H275.85C272.44 180.15 272.12 102.08 275.85 28H155.75Z"
                    fill="white"
                />
                <path
                    d="M275.85 262.12H103.69C103.459 262.123 103.236 262.036 103.068 261.877C102.9 261.718 102.8 261.501 102.79 261.27C100.3 196 100.3 135 102.79 80C102.803 79.7766 102.9 79.5663 103.06 79.41L155.12 27.34C155.203 27.2574 155.301 27.1919 155.409 27.1473C155.517 27.1027 155.633 27.0798 155.75 27.08H275.85C275.971 27.0803 276.092 27.1053 276.204 27.1534C276.315 27.2016 276.416 27.2718 276.5 27.36C276.582 27.4486 276.645 27.5526 276.686 27.6659C276.727 27.7792 276.746 27.8996 276.74 28.02C273.15 99.36 273.15 175.63 276.74 261.19C276.744 261.31 276.724 261.429 276.681 261.54C276.638 261.652 276.573 261.754 276.49 261.84C276.409 261.929 276.31 262.001 276.2 262.049C276.09 262.097 275.97 262.121 275.85 262.12V262.12ZM104.54 260.34H274.92C271.38 175.48 271.37 99.75 274.92 28.87H156.12L104.54 80.43C102.09 135.07 102.09 195.59 104.54 260.34Z"
                    fill="#263238"
                />
                <path
                    d="M244.98 236.46H226.2C226.112 236.46 226.025 236.443 225.943 236.409C225.862 236.375 225.788 236.326 225.726 236.264C225.664 236.202 225.614 236.128 225.581 236.046C225.547 235.965 225.53 235.878 225.53 235.79C225.53 235.702 225.547 235.615 225.581 235.534C225.614 235.452 225.664 235.378 225.726 235.316C225.788 235.254 225.862 235.205 225.943 235.171C226.025 235.137 226.112 235.12 226.2 235.12H244.98C245.157 235.12 245.328 235.191 245.454 235.316C245.579 235.442 245.65 235.612 245.65 235.79C245.65 235.968 245.579 236.138 245.454 236.264C245.328 236.389 245.157 236.46 244.98 236.46V236.46Z"
                    fill="#407BFF"
                />
                <path
                    d="M218.42 236.46H134.54C134.362 236.46 134.192 236.389 134.066 236.264C133.94 236.138 133.87 235.968 133.87 235.79C133.87 235.612 133.94 235.442 134.066 235.316C134.192 235.191 134.362 235.12 134.54 235.12H218.4C218.577 235.12 218.748 235.191 218.873 235.316C218.999 235.442 219.07 235.612 219.07 235.79C219.07 235.968 218.999 236.138 218.873 236.264C218.748 236.389 218.577 236.46 218.4 236.46H218.42Z"
                    fill="#407BFF"
                />
                <path
                    d="M165.82 136.19C165.62 131.83 163.15 128.39 160.29 128.49C157.43 128.59 155.29 132.2 155.47 136.56C155.65 140.92 158.14 144.36 161 144.25C163.86 144.14 166.02 140.54 165.82 136.19Z"
                    fill="#407BFF"
                />
                <path
                    d="M226.19 134.82C225.99 130.47 223.52 127.03 220.66 127.13C217.8 127.23 215.66 130.84 215.84 135.2C216.02 139.56 218.52 143 221.37 142.89C224.22 142.78 226.39 139.18 226.19 134.82Z"
                    fill="#407BFF"
                />
                <path
                    d="M157.18 95.34C157.623 95.3522 158.057 95.476 158.44 95.7C159.024 96.0461 159.449 96.6088 159.621 97.2659C159.793 97.9231 159.699 98.6217 159.36 99.21C157.37 102.757 154.553 105.771 151.15 107.998C147.747 110.224 143.857 111.597 139.81 112C139.129 112.027 138.465 111.785 137.962 111.327C137.459 110.868 137.156 110.23 137.12 109.55C137.105 109.215 137.156 108.88 137.271 108.565C137.386 108.25 137.561 107.961 137.788 107.714C138.015 107.467 138.288 107.267 138.592 107.126C138.896 106.984 139.225 106.904 139.56 106.89V106.89C139.95 106.89 149.18 106.31 154.92 96.62C155.145 96.2216 155.474 95.8919 155.872 95.6664C156.27 95.4409 156.722 95.3281 157.18 95.34V95.34Z"
                    fill="#407BFF"
                />
                <path
                    d="M217.59 93.87C218.007 93.7877 218.439 93.8104 218.846 93.936C219.252 94.0617 219.621 94.2864 219.92 94.59C227.92 102.59 237.06 100.98 237.44 100.91C238.101 100.791 238.783 100.933 239.343 101.306C239.902 101.678 240.295 102.253 240.44 102.91C240.507 103.238 240.508 103.577 240.444 103.906C240.379 104.235 240.25 104.548 240.064 104.826C239.878 105.105 239.639 105.345 239.36 105.531C239.081 105.717 238.769 105.846 238.44 105.91C237.94 106 226.3 108.22 216.23 98.16C215.901 97.8345 215.667 97.4254 215.554 96.9768C215.441 96.5282 215.452 96.0572 215.587 95.6146C215.722 95.172 215.975 94.7747 216.319 94.4654C216.663 94.1562 217.085 93.9469 217.54 93.86L217.59 93.87Z"
                    fill="#407BFF"
                />
                <path
                    d="M204.307 159.436C203.925 159.624 203.502 159.714 203.077 159.697C202.651 159.681 202.237 159.56 201.87 159.344C192.072 153.687 183.66 157.608 183.311 157.774C182.703 158.06 182.007 158.1 181.37 157.884C180.734 157.669 180.205 157.215 179.896 156.618C179.746 156.318 179.657 155.992 179.634 155.657C179.611 155.323 179.655 154.987 179.762 154.67C179.87 154.352 180.039 154.059 180.26 153.808C180.481 153.556 180.75 153.35 181.051 153.203C181.511 152.986 192.179 147.829 204.51 154.94C204.912 155.17 205.243 155.504 205.469 155.908C205.695 156.312 205.805 156.77 205.79 157.233C205.774 157.695 205.632 158.144 205.38 158.532C205.128 158.92 204.774 159.231 204.358 159.433L204.307 159.436Z"
                    fill="#407BFF"
                />
                <path
                    opacity="0.2"
                    d="M160.54 84.69C155.45 70.8 150.36 48.57 155.73 27.98L103.67 80.05C118.4 85 141.86 87.11 160.54 84.69Z"
                    fill="black"
                />
                <path
                    d="M155.75 80.05C150.04 63.19 152.37 40.27 155.75 27.98L103.69 80.05C119.45 82.92 137.06 82.46 155.75 80.05Z"
                    fill="white"
                />
                <path
                    d="M127.54 82.92C119.496 82.9797 111.463 82.3103 103.54 80.92C103.379 80.8936 103.229 80.823 103.106 80.7161C102.983 80.6093 102.892 80.4703 102.843 80.3148C102.794 80.1592 102.79 79.9932 102.83 79.8352C102.87 79.6772 102.953 79.5335 103.07 79.42L155.13 27.35C155.269 27.2088 155.451 27.1179 155.647 27.0917C155.843 27.0655 156.043 27.1055 156.213 27.2053C156.384 27.3052 156.517 27.4591 156.591 27.6429C156.664 27.8267 156.675 28.0298 156.62 28.22C153.48 39.66 150.87 62.82 156.62 79.76C156.66 79.8858 156.672 80.0187 156.657 80.1497C156.641 80.2807 156.598 80.407 156.53 80.52C156.461 80.6323 156.368 80.7276 156.257 80.7986C156.146 80.8696 156.021 80.9145 155.89 80.93C146.492 82.1986 137.023 82.8633 127.54 82.92V82.92ZM105.54 79.46C119.38 81.75 135.45 81.7 154.54 79.3C149.83 64.36 150.9 44.59 154.06 30.9L105.54 79.46Z"
                    fill="#263238"
                />
            </g>
            <path
                d="M135.528 201.708C133.624 201.708 132.14 201.162 131.076 200.07C130.012 198.978 129.48 197.389 129.48 195.303V186.882H131.034V195.24C131.034 196.948 131.419 198.222 132.189 199.062C132.959 199.902 134.072 200.322 135.528 200.322C136.998 200.322 138.118 199.902 138.888 199.062C139.658 198.222 140.043 196.948 140.043 195.24V186.882H141.555V195.303C141.555 197.389 141.023 198.978 139.959 200.07C138.909 201.162 137.432 201.708 135.528 201.708Z"
                fill="black"
            />
            <path
                d="M150.921 190.452C152.307 190.452 153.406 190.858 154.218 191.67C155.044 192.468 155.457 193.637 155.457 195.177V201.582H153.966V195.324C153.966 194.176 153.679 193.301 153.105 192.699C152.531 192.097 151.712 191.796 150.648 191.796C149.458 191.796 148.513 192.153 147.813 192.867C147.127 193.567 146.784 194.54 146.784 195.786V201.582H145.293V190.536H146.721V192.573C147.127 191.901 147.687 191.383 148.401 191.019C149.129 190.641 149.969 190.452 150.921 190.452Z"
                fill="black"
            />
            <path
                d="M168.534 196.521H159.294C159.378 197.669 159.819 198.6 160.617 199.314C161.415 200.014 162.423 200.364 163.641 200.364C164.327 200.364 164.957 200.245 165.531 200.007C166.105 199.755 166.602 199.391 167.022 198.915L167.862 199.881C167.372 200.469 166.756 200.917 166.014 201.225C165.286 201.533 164.481 201.687 163.599 201.687C162.465 201.687 161.457 201.449 160.575 200.973C159.707 200.483 159.028 199.811 158.538 198.957C158.048 198.103 157.803 197.137 157.803 196.059C157.803 194.981 158.034 194.015 158.496 193.161C158.972 192.307 159.616 191.642 160.428 191.166C161.254 190.69 162.178 190.452 163.2 190.452C164.222 190.452 165.139 190.69 165.951 191.166C166.763 191.642 167.4 192.307 167.862 193.161C168.324 194.001 168.555 194.967 168.555 196.059L168.534 196.521ZM163.2 191.733C162.136 191.733 161.24 192.076 160.512 192.762C159.798 193.434 159.392 194.316 159.294 195.408H167.127C167.029 194.316 166.616 193.434 165.888 192.762C165.174 192.076 164.278 191.733 163.2 191.733Z"
                fill="black"
            />
            <path
                d="M177.687 201.582L174.18 196.962L170.652 201.582H168.972L173.34 195.912L169.182 190.536H170.862L174.18 194.862L177.498 190.536H179.136L174.978 195.912L179.388 201.582H177.687Z"
                fill="black"
            />
            <path
                d="M186.931 190.452C187.967 190.452 188.905 190.69 189.745 191.166C190.585 191.628 191.243 192.286 191.719 193.14C192.195 193.994 192.433 194.967 192.433 196.059C192.433 197.165 192.195 198.145 191.719 198.999C191.243 199.853 190.585 200.518 189.745 200.994C188.919 201.456 187.981 201.687 186.931 201.687C186.035 201.687 185.223 201.505 184.495 201.141C183.781 200.763 183.193 200.217 182.731 199.503V205.656H181.24V190.536H182.668V192.72C183.116 191.992 183.704 191.432 184.432 191.04C185.174 190.648 186.007 190.452 186.931 190.452ZM186.826 200.364C187.596 200.364 188.296 200.189 188.926 199.839C189.556 199.475 190.046 198.964 190.396 198.306C190.76 197.648 190.942 196.899 190.942 196.059C190.942 195.219 190.76 194.477 190.396 193.833C190.046 193.175 189.556 192.664 188.926 192.3C188.296 191.936 187.596 191.754 186.826 191.754C186.042 191.754 185.335 191.936 184.705 192.3C184.089 192.664 183.599 193.175 183.235 193.833C182.885 194.477 182.71 195.219 182.71 196.059C182.71 196.899 182.885 197.648 183.235 198.306C183.599 198.964 184.089 199.475 184.705 199.839C185.335 200.189 186.042 200.364 186.826 200.364Z"
                fill="black"
            />
            <path
                d="M204.461 196.521H195.221C195.305 197.669 195.746 198.6 196.544 199.314C197.342 200.014 198.35 200.364 199.568 200.364C200.254 200.364 200.884 200.245 201.458 200.007C202.032 199.755 202.529 199.391 202.949 198.915L203.789 199.881C203.299 200.469 202.683 200.917 201.941 201.225C201.213 201.533 200.408 201.687 199.526 201.687C198.392 201.687 197.384 201.449 196.502 200.973C195.634 200.483 194.955 199.811 194.465 198.957C193.975 198.103 193.73 197.137 193.73 196.059C193.73 194.981 193.961 194.015 194.423 193.161C194.899 192.307 195.543 191.642 196.355 191.166C197.181 190.69 198.105 190.452 199.127 190.452C200.149 190.452 201.066 190.69 201.878 191.166C202.69 191.642 203.327 192.307 203.789 193.161C204.251 194.001 204.482 194.967 204.482 196.059L204.461 196.521ZM199.127 191.733C198.063 191.733 197.167 192.076 196.439 192.762C195.725 193.434 195.319 194.316 195.221 195.408H203.054C202.956 194.316 202.543 193.434 201.815 192.762C201.101 192.076 200.205 191.733 199.127 191.733Z"
                fill="black"
            />
            <path
                d="M211.464 201.687C210.372 201.687 209.392 201.449 208.524 200.973C207.67 200.497 206.998 199.832 206.508 198.978C206.018 198.11 205.773 197.137 205.773 196.059C205.773 194.981 206.018 194.015 206.508 193.161C206.998 192.307 207.67 191.642 208.524 191.166C209.392 190.69 210.372 190.452 211.464 190.452C212.416 190.452 213.263 190.641 214.005 191.019C214.761 191.383 215.356 191.922 215.79 192.636L214.677 193.392C214.313 192.846 213.851 192.44 213.291 192.174C212.731 191.894 212.122 191.754 211.464 191.754C210.666 191.754 209.945 191.936 209.301 192.3C208.671 192.65 208.174 193.154 207.81 193.812C207.46 194.47 207.285 195.219 207.285 196.059C207.285 196.913 207.46 197.669 207.81 198.327C208.174 198.971 208.671 199.475 209.301 199.839C209.945 200.189 210.666 200.364 211.464 200.364C212.122 200.364 212.731 200.231 213.291 199.965C213.851 199.699 214.313 199.293 214.677 198.747L215.79 199.503C215.356 200.217 214.761 200.763 214.005 201.141C213.249 201.505 212.402 201.687 211.464 201.687Z"
                fill="black"
            />
            <path
                d="M224.03 200.91C223.75 201.162 223.4 201.358 222.98 201.498C222.574 201.624 222.147 201.687 221.699 201.687C220.663 201.687 219.865 201.407 219.305 200.847C218.745 200.287 218.465 199.496 218.465 198.474V191.796H216.491V190.536H218.465V188.121H219.956V190.536H223.316V191.796H219.956V198.39C219.956 199.048 220.117 199.552 220.439 199.902C220.775 200.238 221.251 200.406 221.867 200.406C222.175 200.406 222.469 200.357 222.749 200.259C223.043 200.161 223.295 200.021 223.505 199.839L224.03 200.91Z"
                fill="black"
            />
            <path
                d="M235.342 196.521H226.102C226.186 197.669 226.627 198.6 227.425 199.314C228.223 200.014 229.231 200.364 230.449 200.364C231.135 200.364 231.765 200.245 232.339 200.007C232.913 199.755 233.41 199.391 233.83 198.915L234.67 199.881C234.18 200.469 233.564 200.917 232.822 201.225C232.094 201.533 231.289 201.687 230.407 201.687C229.273 201.687 228.265 201.449 227.383 200.973C226.515 200.483 225.836 199.811 225.346 198.957C224.856 198.103 224.611 197.137 224.611 196.059C224.611 194.981 224.842 194.015 225.304 193.161C225.78 192.307 226.424 191.642 227.236 191.166C228.062 190.69 228.986 190.452 230.008 190.452C231.03 190.452 231.947 190.69 232.759 191.166C233.571 191.642 234.208 192.307 234.67 193.161C235.132 194.001 235.363 194.967 235.363 196.059L235.342 196.521ZM230.008 191.733C228.944 191.733 228.048 192.076 227.32 192.762C226.606 193.434 226.2 194.316 226.102 195.408H233.935C233.837 194.316 233.424 193.434 232.696 192.762C231.982 192.076 231.086 191.733 230.008 191.733Z"
                fill="black"
            />
            <path
                d="M247.848 186V201.582H246.42V199.398C245.972 200.14 245.377 200.707 244.635 201.099C243.907 201.491 243.081 201.687 242.157 201.687C241.121 201.687 240.183 201.449 239.343 200.973C238.503 200.497 237.845 199.832 237.369 198.978C236.893 198.124 236.655 197.151 236.655 196.059C236.655 194.967 236.893 193.994 237.369 193.14C237.845 192.286 238.503 191.628 239.343 191.166C240.183 190.69 241.121 190.452 242.157 190.452C243.053 190.452 243.858 190.641 244.572 191.019C245.3 191.383 245.895 191.922 246.357 192.636V186H247.848ZM242.283 200.364C243.053 200.364 243.746 200.189 244.362 199.839C244.992 199.475 245.482 198.964 245.832 198.306C246.196 197.648 246.378 196.899 246.378 196.059C246.378 195.219 246.196 194.47 245.832 193.812C245.482 193.154 244.992 192.65 244.362 192.3C243.746 191.936 243.053 191.754 242.283 191.754C241.499 191.754 240.792 191.936 240.162 192.3C239.546 192.65 239.056 193.154 238.692 193.812C238.342 194.47 238.167 195.219 238.167 196.059C238.167 196.899 238.342 197.648 238.692 198.306C239.056 198.964 239.546 199.475 240.162 199.839C240.792 200.189 241.499 200.364 242.283 200.364Z"
                fill="black"
            />
            <path
                d="M179.014 226.238V227.582H168.619V212.882H178.699V214.226H170.173V219.455H177.775V220.778H170.173V226.238H179.014Z"
                fill="black"
            />
            <path
                d="M183.15 218.699C183.5 217.957 184.018 217.397 184.704 217.019C185.404 216.641 186.265 216.452 187.287 216.452V217.901L186.93 217.88C185.768 217.88 184.858 218.237 184.2 218.951C183.542 219.665 183.213 220.666 183.213 221.954V227.582H181.722V216.536H183.15V218.699Z"
                fill="black"
            />
            <path
                d="M190.785 218.699C191.135 217.957 191.653 217.397 192.339 217.019C193.039 216.641 193.9 216.452 194.922 216.452V217.901L194.565 217.88C193.403 217.88 192.493 218.237 191.835 218.951C191.177 219.665 190.848 220.666 190.848 221.954V227.582H189.357V216.536H190.785V218.699Z"
                fill="black"
            />
            <path
                d="M201.404 227.687C200.34 227.687 199.381 227.449 198.527 226.973C197.673 226.483 197.001 225.811 196.511 224.957C196.021 224.103 195.776 223.137 195.776 222.059C195.776 220.981 196.021 220.015 196.511 219.161C197.001 218.307 197.673 217.642 198.527 217.166C199.381 216.69 200.34 216.452 201.404 216.452C202.468 216.452 203.427 216.69 204.281 217.166C205.135 217.642 205.8 218.307 206.276 219.161C206.766 220.015 207.011 220.981 207.011 222.059C207.011 223.137 206.766 224.103 206.276 224.957C205.8 225.811 205.135 226.483 204.281 226.973C203.427 227.449 202.468 227.687 201.404 227.687ZM201.404 226.364C202.188 226.364 202.888 226.189 203.504 225.839C204.134 225.475 204.624 224.964 204.974 224.306C205.324 223.648 205.499 222.899 205.499 222.059C205.499 221.219 205.324 220.47 204.974 219.812C204.624 219.154 204.134 218.65 203.504 218.3C202.888 217.936 202.188 217.754 201.404 217.754C200.62 217.754 199.913 217.936 199.283 218.3C198.667 218.65 198.177 219.154 197.813 219.812C197.463 220.47 197.288 221.219 197.288 222.059C197.288 222.899 197.463 223.648 197.813 224.306C198.177 224.964 198.667 225.475 199.283 225.839C199.913 226.189 200.62 226.364 201.404 226.364Z"
                fill="black"
            />
            <path
                d="M210.853 218.699C211.203 217.957 211.721 217.397 212.407 217.019C213.107 216.641 213.968 216.452 214.99 216.452V217.901L214.633 217.88C213.471 217.88 212.561 218.237 211.903 218.951C211.245 219.665 210.916 220.666 210.916 221.954V227.582H209.425V216.536H210.853V218.699Z"
                fill="black"
            />
            <defs>
                <clipPath id="clip0_3780_156908">
                    <rect width="398.57" height="344.57" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UnexpectedErrorIcon;
