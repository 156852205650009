import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { setPreferredExperience } from 'redux/actions/common/userActions';

import styles from './index.module.scss';
import otpRoutes from './routes';

// Components
import { checkIfAuthorize } from 'utils/auth';
import { Helmet } from 'react-helmet';
import { Collapse } from 'antd';
import NotificationPopup from 'ComponentsV2/CommonComponents/NotificationPopup';

const { Panel } = Collapse;

const GoogleReCaptchaProviderConfig = {
    reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    useEnterprise: true,
    scriptProps: {
        async: true,
        defer: true,
    },
    container: {
        parameters: {
            badge: 'bottomleft',
        },
    },
};

const SignUp = () => {
    const history = useHistory();
    const [activeItem, setActiveItem] = useState(['1', '2', '3']);

    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(history.location.search);
    const preferredExperienceId = queryParams.get('venueId') || null;
    const preferredExperienceName = decodeURIComponent(
        queryParams.get('expName') || ''
    );

    useEffect(() => {
        if (checkIfAuthorize()) history.replace('/');
    }, []);

    useEffect(() => {
        if (!preferredExperienceId) return;
        dispatch(
            setPreferredExperience({
                preferredExperienceId,
                preferredExperienceName,
            })
        );
    }, [preferredExperienceId, preferredExperienceName]);

    return (
        <>
            <Helmet>
                <link rel="preload" href="./assets/bg.jpg" as="image"></link>
            </Helmet>
            <div className={styles.SignUpWrapper}>
                <div className={styles.WithRadialBackground}>
                    <NotificationPopup />
                    <div className={styles.SignUpWrapperInner}>
                        <section className={styles.SignUpDetails}>
                            <h1 className={styles.GevmeLogo}>
                                <img
                                    src={'/assets/gevme-new-logo.png'}
                                    alt="Gevme"
                                />
                            </h1>

                            <GoogleReCaptchaProvider
                                {...GoogleReCaptchaProviderConfig}
                            >
                                <Switch>
                                    {otpRoutes.map((e) => (
                                        <Route key={e.component} {...e} />
                                    ))}
                                </Switch>
                            </GoogleReCaptchaProvider>
                        </section>
                        <div className="tw-flex tw-w-full tw-py-4 tw-px-2.5 tw-justify-center tw-items-center tw-gap-2 tw-self-stretch tw-bg-[#E9ECF0] tw-rounded-[20px] tw-rounded-t-none tw-shadow-md">
                            <span className="tw-text-gray-700 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal">
                                Already have an account?
                            </span>
                            <span
                                className="tw-text-blue-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal tw-text-right tw-cursor-pointer "
                                onClick={() => {
                                    history.push('/login');
                                }}
                            >
                                Log in
                            </span>
                        </div>
                    </div>
                    <div className={styles.FAQ}>
                        <span className='tw-text-black tw-font-["Open_Sans"] tw-text-[32px] tw-font-bold tw-leading-normal'>Frequently Asked Questions</span>
                        <Collapse
                            defaultActiveKey={activeItem}
                            showArrow={false}
                            expandIcon={() => null}
                            onChange={(e) => setActiveItem(e)}
                            ghost
                            bordered={true}
                            style={{
                                width: '100%',
                            }}
                        >
                            <Panel
                                header="What am I signing up to?"
                                key="1"
                                extra={
                                    <div
                                        className={`tw-w-full`}
                                    ></div>
                                }
                                showArrow={false}
                            >
                                <span className="tw-text-black tw-font-['Open_Sans'] tw-text-base tw-font-normal">
                                    You are signing to the free version of the
                                    Gevme platform. Once signed up, you’ll get
                                    to try out the different features of the
                                    platform for free to set up your event. You
                                    can later add your payment details and
                                    upgrade to access premium features.
                                </span>
                            </Panel>
                            <Panel
                                header="How much does Gevme cost?"
                                key="2"
                                extra={
                                    <div
                                        className={`tw-w-full`}
                                    ></div>
                                }
                            >
                                <span className="tw-text-black tw-font-['Open_Sans'] tw-text-base tw-font-normal">
                                    Gevme offers different pricing plans to
                                    cater for your event needs. You can get
                                    started for free (no credit card required)
                                    and later upgrade to our Pay Per Use or
                                    Subscription plans. The full breakdown of
                                    our pricing can be found{' '}
                                    <a
                                        href="https://web.gevme.com/pricing/"
                                        target="_blank"
                                    >
                                        here
                                    </a>
                                    . Feel free to{' '}
                                    <a
                                        href="https://web.gevme.com/support/"
                                        target="_blank"
                                    >
                                        contact us
                                    </a>{' '}
                                    if you have any questions.
                                </span>
                            </Panel>
                            <Panel
                                header="What kind of support is provided?"
                                key="3"
                                extra={
                                    <div
                                        className={`tw-w-full`}
                                    ></div>
                                }
                            >
                                <span className="tw-text-black tw-font-['Open_Sans'] tw-text-base tw-font-normal">
                                    Gevme offers many ways to contact our
                                    Support team to ensure that you always get
                                    the help you need easily and quickly. The
                                    best and easies way to reach out to us is to
                                    use the live chat inside our product.
                                    Alternatively, you can use our{' '}
                                    <a
                                        href="https://web.gevme.com/support/"
                                        target="_blank"
                                    >
                                        Support page
                                    </a>{' '}
                                    to access our Knowledge Base, create a
                                    ticket or contact us via WhatsApp/Live chat.
                                </span>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
            <style>
                {`
                    .grecaptcha-badge {
                        bottom: 94px !important;
                        z-index: 4;
                    }
                `}
            </style>
        </>
    );
};

export default React.memo(SignUp);
