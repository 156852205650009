import { isUndefined } from "lodash";

export const checkYoutubeOrVimeoLink = (URL) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/

    return regex.test(URL)

}

export const getYouTubeVideoId = (videoURL) => {
    if (checkYoutubeOrVimeoLink(videoURL)) {
        return videoURL?.match(/youtu(?:.*\/v\/|.*v\=|\.be\/)([A-Za-z0-9_\-]{11})/)[1];
    }
}

export const getVimeoVideoId = (videoURL) => {
    if (checkYoutubeOrVimeoLink(videoURL)) {
        return videoURL.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i)[1] || '';
    }

}

export const createYouTubeThumbnailURL = (videoId) => {
    if (!isUndefined(videoId)) {
        return `//img.youtube.com/vi/${videoId}/0.jpg`
    }
}

export const createVimeoThumbnailURL = (videoId) => {
    if (!isUndefined(videoId)) {
        return `https://vumbnail.com/${videoId}.jpg`
    }
}

export const createVideoThumbnailURL = (videoURL) => {

    if (videoURL.includes("youtube")) {
        let videoYouTubeId = getYouTubeVideoId(videoURL)
        let videoThumbnailYouTubeURL = createYouTubeThumbnailURL(videoYouTubeId)
        return videoThumbnailYouTubeURL
    }
    if (videoURL.includes("vimeo")) {
        let videoVimeoId = getVimeoVideoId(videoURL)
        let videoThumbnailVimeoURL = createVimeoThumbnailURL(videoVimeoId)
        return videoThumbnailVimeoURL
    }

    return null
}