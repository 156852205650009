/**
 * @note If adding a new action, should use the actions in `redux/actions` folder instead of this file
 * will move the actions below to the proper folder once there's more time to refactor / do clean-up
 * meantime, do not update this file anymore
 */

/** XpManager */
export const CHANGE_PROJECT = 'CHANGE_PROJECT';

export function changeProject(payload) {
    return { type: CHANGE_PROJECT, payload };
}
