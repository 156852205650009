import React from 'react';

export default function NoPageCreatedIcon() {
    return (
        <svg width="186" height="119" viewBox="0 0 186 119" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M185.31 0.108307H0.788803V118.284H185.31V0.108307Z" fill="#E6E6E6"/>
            <path d="M180 14.9191H6.03187V59.9687H180V14.9191Z" fill="white"/>
            <path d="M86.1016 21.4317H49.311V23.5436H86.1016V21.4317Z" fill="#E6E6E6"/>
            <path d="M106.123 29.1462H49.311V31.2582H106.123V29.1462Z" fill="#377BF7"/>
            <path d="M95.612 36.3946H49.311V38.5065H95.612V36.3946Z" fill="#E6E6E6"/>
            <path d="M76.3408 43.6307H49.311V45.7427H76.3408V43.6307Z" fill="#E6E6E6"/>
            <path d="M90.1059 50.8611H49.311V52.973H90.1059V50.8611Z" fill="#E6E6E6"/>
            <path d="M37.0902 20.678H32.5852V25.1829H37.0902V20.678Z" fill="#E6E6E6"/>
            <path d="M37.0902 27.9438H32.5852V32.4487H37.0902V27.9438Z" fill="#377BF7"/>
            <path d="M37.0902 35.1916H32.5852V39.6966H37.0902V35.1916Z" fill="#E6E6E6"/>
            <path d="M37.0902 42.4623H32.5852V46.9672H37.0902V42.4623Z" fill="#E6E6E6"/>
            <path d="M37.0902 49.7106H32.5852V54.2155H37.0902V49.7106Z" fill="#E6E6E6"/>
            <path d="M155.719 30.949H140.702V45.9655H155.719V30.949Z" fill="#E6E6E6"/>
            <path d="M180 66.2358H6.03187V111.285H180V66.2358Z" fill="white"/>
            <path d="M44.8062 70.2317H40.3012V74.7366H44.8062V70.2317Z" fill="#E6E6E6"/>
            <path d="M37.0902 70.2317H32.5852V74.7366H37.0902V70.2317Z" fill="#377BF7"/>
            <path d="M108.922 80.0807C107.549 77.7562 105.644 75.7907 103.363 74.3458C101.082 72.901 98.4913 72.0182 95.803 71.7698C93.1146 71.5215 90.4057 71.9148 87.899 72.9173C85.3922 73.9199 83.1592 75.5031 81.3836 77.5368L80.2857 76.5775C82.2127 74.3695 84.6364 72.6506 87.3573 71.5621C90.0782 70.4735 93.0187 70.0465 95.9369 70.316C98.8551 70.5855 101.668 71.5439 104.143 73.1124C106.619 74.6809 108.686 76.8146 110.177 79.3381L108.922 80.0807Z" fill="#E6E6E6"/>
            <path d="M109.609 99.0739L108.399 98.2603C110.191 95.5876 111.191 92.4628 111.283 89.2462C111.376 86.0297 110.557 82.8525 108.922 80.0812L110.176 79.3386C111.952 82.347 112.841 85.7962 112.741 89.2881C112.64 92.7801 111.555 96.1725 109.609 99.0739Z" fill="#377BF7"/>
            <path d="M80.4667 101.113C77.4531 97.7469 75.7742 93.3954 75.7462 88.8778C75.7182 84.3601 77.3431 79.9882 80.3148 76.5854L81.4127 77.5448C78.6752 80.6795 77.1784 84.7071 77.2041 88.8688C77.2299 93.0305 78.7765 97.0392 81.5527 100.14L80.4667 101.113Z" fill="#377BF7"/>
            <path d="M94.271 107.271C91.6676 107.27 89.0937 106.719 86.717 105.657C84.3402 104.594 82.2138 103.043 80.4761 101.105L81.562 100.132C83.2954 102.069 85.4485 103.584 87.8568 104.561C90.2651 105.539 92.8649 105.953 95.4578 105.772C98.0506 105.592 100.568 104.821 102.817 103.519C105.067 102.217 106.989 100.418 108.437 98.26L109.647 99.0735C107.955 101.598 105.666 103.666 102.985 105.096C100.303 106.526 97.3102 107.273 94.271 107.271Z" fill="#377BF7"/>
            <path d="M155.719 79.9962H140.702V95.0127H155.719V79.9962Z" fill="#E6E6E6"/>
            <path d="M46.9101 89.764H31.8937V104.781H46.9101V89.764Z" fill="#E6E6E6"/>
            <path d="M185.211 0H0.689728V7.83916H185.211V0Z" fill="#377BF7"/>
            <path d="M6.5271 5.46911C7.32953 5.46911 7.98002 4.81862 7.98002 4.01619C7.98002 3.21376 7.32953 2.56326 6.5271 2.56326C5.72467 2.56326 5.07417 3.21376 5.07417 4.01619C5.07417 4.81862 5.72467 5.46911 6.5271 5.46911Z" fill="white"/>
            <path d="M12.0538 5.46911C12.8562 5.46911 13.5067 4.81862 13.5067 4.01619C13.5067 3.21376 12.8562 2.56326 12.0538 2.56326C11.2514 2.56326 10.6009 3.21376 10.6009 4.01619C10.6009 4.81862 11.2514 5.46911 12.0538 5.46911Z" fill="white"/>
            <path d="M17.5183 5.46911C18.3207 5.46911 18.9712 4.81862 18.9712 4.01619C18.9712 3.21376 18.3207 2.56326 17.5183 2.56326C16.7159 2.56326 16.0654 3.21376 16.0654 4.01619C16.0654 4.81862 16.7159 5.46911 17.5183 5.46911Z" fill="white"/>
        </svg>
    );
}
