import { gql } from "apollo-boost";

const PEOPLE = gql`
  query People($where: JSON) {
    people(where: $where) {
      id
      companyName
    }
  }
`;

export default PEOPLE;
