import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table, Tabs } from 'antd';
import moment from 'moment';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import FileSaver from 'file-saver';

import { GetInteractionsNotification } from 'apis/rest/notifications/GetInteractionsNotification';
import { GetExportInteractionsNotification } from 'apis/rest/notifications/GetExportInteractionsNotification';

import './notificationAnalytics.scss';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const NotificationAnalytics = ({ selectedNotification }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [interactions, setInteractions] = useState(null);
    const [currentTab, setCurrentTab] = useState('all');
    const [currentPage, setCurrentPage] = useState({
        all: 1,
        views: 1,
        clicks: 1,
    });

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    useEffect(() => {
        handleGetInteractions();
    }, [currentTab, currentPage]);

    const handleGetInteractions = async () => {
        setIsLoading(true);
        let projectId = selectedNotification.projectId;
        let notificationId =
            selectedNotification?.id || selectedNotification?.notificationId;
        let currentTabKey = currentTab === 'all' ? '' : currentTab;

        try {
            const response = await GetInteractionsNotification(
                projectId,
                notificationId,
                currentTabKey, //type
                currentPage[currentTab] //page
            );
            if (response.status) {
                setInteractions(response);
            }
        } catch (error) {
            console.error('ERROR handleGetNotifications', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetExportInteractions = async () => {
        let projectId = selectedNotification.projectId;
        let notificationId =
            selectedNotification?.id || selectedNotification?.notificationId;
        let currentTabKey = currentTab === 'all' ? '' : currentTab;

        try {
            const response = await GetExportInteractionsNotification(
                projectId,
                notificationId,
                currentTabKey, //type
                currentPage, //page
                '' //search text;
            );

            FileSaver.saveAs(
                response,
                `${notificationId}-${currentTab}-report.csv`
            );
        } catch (error) {
            console.error('ERROR handleGetExportInteractions', error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'Time',
            dataIndex: 'updatedAt',
            sorter: (a, b) =>
                moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            render: (updatedAt, item) => {
                if (!updatedAt) return null;
                return (
                    <span>
                        {moment(updatedAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] h:mm:ss a')}
                    </span>
                );
            },
        },
        {
            title: 'Type',
            dataIndex: 'event',
            render: (event, item) => {
                return <span>{startCase(event)}</span>;
            },
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Job Title',
            dataIndex: 'jobTitle',
        },
        {
            title: 'Company',
            dataIndex: 'company',
        },
    ];

    const globalLoadingComponent = {
        spinning: isLoading,
        indicator: <GlobalLoading />,
    };

    return (
        <>
            <div
                className="tw-flex tw-justify-end sm:tw-absolute sm:tw-top-[20px] sm:tw-right-[20px] sm:tw-z-20 "
            >
                <button
                    className="myxp btn btn-sm btn-link text-dark tw-border tw-border-solid tw-border-[#d3d3d3] sm:tw-border-none"
                    onClick={() => handleGetExportInteractions()}
                >
                    Export
                </button>
            </div>
            <Tabs
                className="bg-white py-3 sponsors-tabs"
                defaultActiveKey={'all'}
                onChange={(tabKey) => {
                    setCurrentTab(tabKey);
                }}
            >
                <TabPane
                    forceRender={true}
                    tab={`All (${interactions?.totalInteractions})`}
                    key="all"
                >
                    <Table
                        columns={columns}
                        className="sponsors-company-table"
                        dataSource={interactions?.interactions}
                        loading={globalLoadingComponent}
                        pagination={{
                            onChange: (num) =>
                                setCurrentPage((val) => ({
                                    ...val,
                                    [currentTab]: num,
                                })),
                            pageSize: 8,
                            total: interactions?.pagination?.total,
                        }}
                    />
                </TabPane>
                <TabPane
                    forceRender={true}
                    tab={`Total Views (${interactions?.totalViews})`}
                    key="views"
                >
                    <Table
                        columns={columns}
                        className="sponsors-company-table"
                        dataSource={interactions?.interactions}
                        loading={globalLoadingComponent}
                        pagination={{
                            onChange: (num) =>
                                setCurrentPage((val) => ({
                                    ...val,
                                    [currentTab]: num,
                                })),
                            pageSize: 8,
                            total: interactions?.pagination?.total,
                        }}
                    />
                </TabPane>
                <TabPane
                    forceRender={true}
                    tab={`Total Clicks (${interactions?.totalClicks})`}
                    key="clicks"
                >
                    <Table
                        columns={columns}
                        className="sponsors-company-table"
                        dataSource={interactions?.interactions}
                        loading={globalLoadingComponent}
                        pagination={{
                            onChange: (num) =>
                                setCurrentPage((val) => ({
                                    ...val,
                                    [currentTab]: num,
                                })),
                            pageSize: 8,
                            total: interactions?.pagination?.total,
                        }}
                    />
                </TabPane>
            </Tabs>
        </>
    );
};

NotificationAnalytics.propTypes = {
    selectedNotification: PropTypes.object,
};

NotificationAnalytics.defaultProps = {
    selectedNotification: null,
};

export default NotificationAnalytics;
