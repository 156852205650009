import React from 'react';
import PropTypes from 'prop-types';

import { CloseIcon } from './icons';

export default function Modal({
    children,
    className,
    style,
    visible,
    onClose,
    closeIcon,
    showCloseIcon,
    otherClassNames,
    otherStyles,
    preventCloseOnOutsideClick,
    backdropClassName,
}) {
    const { modalBackdropClassName = '', closeIconClassName = '' } =
        otherClassNames;

    const { closeIconStyle = {} } = otherStyles;

    function onCloseHandler() {
        if (preventCloseOnOutsideClick) return;
        onClose();
    }

    if (!visible) {
        return null;
    }

    return (
        <div
            className={`tw-fixed tw-z-[9999] tw-inset-0 tw-overflow-y-auto ${modalBackdropClassName}`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            onClick={onCloseHandler}
        >
            <div
                className="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center md:tw-block md:tw-p-0"
                onClick={onCloseHandler}
            >
                <div
                    className={`tw-fixed tw-inset-0 tw-transition-opacity ${backdropClassName}`}
                    aria-hidden="true"
                    onClick={onCloseHandler}
                />
                <span
                    className="tw-hidden md:tw-inline-block md:tw-align-middle md:tw-h-screen"
                    aria-hidden="true"
                    onClick={onCloseHandler}
                >
                    &#8203;
                </span>
                <div
                    className={`tw-relative tw-inline-block tw-align-bottom tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-transform tw-transition-all tw-duration-300 tw-ease-in-out md:tw-my-8 md:tw-align-middle md:tw-py-8 md:tw-px-8 md:tw-w-full ${className}`}
                    style={style}
                    onClick={(e) => e.stopPropagation()}
                >
                    {showCloseIcon && (
                        <div
                            className={`tw-hidden md:tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-1 tw-pr-1 ${closeIconClassName}`}
                            style={closeIconStyle}
                        >
                            <button
                                type="button"
                                className="tw-bg-transparent tw-rounded-md tw-text-gray-400 tw-border-0 tw-border-solid hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                                onClick={onClose}
                            >
                                <span className="tw-sr-only">Close</span>
                                {closeIcon || <CloseIcon />}
                            </button>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    closeIcon: PropTypes.node,
    showCloseIcon: PropTypes.bool,
    otherClassNames: PropTypes.shape({
        modalBackdropClassName: PropTypes.string,
        closeIconClassName: PropTypes.string,
    }),
    otherStyles: PropTypes.shape({
        closeIconStyle: PropTypes.object,
    }),
    preventCloseOnOutsideClick: PropTypes.bool,
    backdropClassName: PropTypes.string,
};

Modal.defaultProps = {
    className: 'tw-bg-white tw-shadow-xl',
    style: { maxWidth: '600px' },
    visible: false,
    onOk: () => {},
    onClose: () => {},
    closeIcon: null,
    showCloseIcon: true,
    otherClassNames: {
        modalBackdropClassName: '',
        closeIconClassName: '',
    },
    otherStyles: {
        closeIconStyle: {},
    },
    preventCloseOnOutsideClick: false,
    backdropClassName: 'tw-bg-gray-500 tw-bg-opacity-75',
};
