import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import copy from 'copy-to-clipboard';
import moment from 'moment';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, Menu, Dropdown } from 'antd';
import { CloseOutlined, CopyOutlined, MoreOutlined } from '@ant-design/icons';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Prompt from 'components/app/common/prompt';
import GlobalLoading from 'components/app/components/common/globalLoading';

import ChannelTypes from 'apis/rest/channels/ChannelTypes';
import { CreateChannel } from 'apis/rest/channels/CreateChannel';
import { UpdateChannel } from 'apis/rest/channels/UpdateChannel';
import { DeleteChannel } from 'apis/rest/channels/DeleteChannel';
import { GetChannel } from 'apis/rest/channels/GetChannel';
import { ResetChannelStreamKey } from 'apis/rest/channels/ResetChannelStreamKey';

import './channelEdit.scss';

const { Option } = Select;
function ChannelEdit() {
    const history = useHistory();
    const { projectId, channelId, companyId } = useParams();

    const { register, errors, handleSubmit, trigger } = useForm();

    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [channelQuality, setChannelQuality] = useState('HD');
    const [RTMPURL, setRTMPURL] = useState('');
    const [streamKey, setStreamKey] = useState('');
    const [playbackUrl, setPlaybackUrl] = useState('');
    const [createdAt, setCreatedAt] = useState(null);
    const [channelStatus, setChannelStatus] = useState('live');

    const [deletePromptVisibility, setDeletePromptVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [channelType, setChannelType] = useState(ChannelTypes.MUX_CHANNEL);

    useEffect(() => {
        if (channelId) {
            handleGetChannel();
        } else {
            setIsLoading(false);
        }
    }, []);

    const handleCreateOrUpdateChannel = async () => {
        if (channelId) {
            // edit channel
            updateChannel();
        } else {
            //create channel
            createChannel();
        }
    };

    const handleGetChannel = async () => {
        setIsLoading(true);

        try {
            const response = await GetChannel(projectId, channelId);
            if (response.status) {
                const {
                    name,
                    description,
                    type,
                    playbackUrl,
                    streamKey,
                    ingestEndpoint,
                    createdAt,
                    status,
                } = response.channel;
                setChannelName(name);
                setChannelDescription(description);
                setChannelQuality(type);
                setRTMPURL(ingestEndpoint);
                setStreamKey(streamKey);
                setPlaybackUrl(playbackUrl);
                setCreatedAt(createdAt);
                setChannelStatus(status);
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            console.error('ERROR handleGetChannel', error);
        } finally {
            setIsLoading(false);
        }
    };

    const createChannel = async () => {
        const inputs = {
            name: channelName,
            description: channelDescription,
        };

        if (channelType === ChannelTypes.IVS_CHANNEL) {
            inputs.type = channelQuality;
        }
        if (channelType === ChannelTypes.MUX_CHANNEL) {
            inputs.channelType = channelType;
        }

        const response = await CreateChannel(projectId, inputs);
        if (response.status) {
            openNotification('success', { message: 'Successfully created' });
            history.push(
                `/company/${companyId}/project/${projectId}/channels/`
            );
        } else {
            openNotification('error', { message: response.message });
        }
    };

    const updateChannel = async () => {
        const updateInputs = {
            name: channelName,
            description: channelDescription,
        };

        if (channelType === ChannelTypes.IVS_CHANNEL) {
            updateInputs.type = channelQuality;
        }

        const response = await UpdateChannel(
            projectId,
            channelId,
            updateInputs
        );
        if (response.status) {
            openNotification('success', { message: 'Successfully updated!' });
            handleGetChannel();
        } else {
            openNotification('error', { message: response.message });
        }
    };

    const handleDeleteChannel = async () => {
        if (channelId) {
            try {
                const response = await DeleteChannel(projectId, channelId);
                if (response.status) {
                    openNotification('success', {
                        message: 'Successfully deleted!',
                    });
                    history.push(
                        `/company/${companyId}/project/${projectId}/channels/`
                    );
                } else throw response;
            } catch (error) {
                openNotification('error', { message: error.message });
            } finally {
                setDeletePromptVisibility(false);
            }
        }
    };

    const handleResetChannelStreamKey = async () => {
        try {
            const response = await ResetChannelStreamKey(projectId, channelId);
            if (response.status) {
                openNotification('success', {
                    message: 'Successfully reset stream key!',
                });
                handleGetChannel();
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {}
    };

    const copyKey = (key) => {
        copy(key);
        openNotification('success', { message: 'Copied to clipboard!' });
    };

    const renderMoreMenu = () => (
        <Menu>
            <Menu.Item key="0">
                <a onClick={() => setDeletePromptVisibility(true)}>
                    Delete Channel
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout disableSubMenuWidth className="tw-p-0" hideBreadcrumbs>
            {isLoading && <GlobalLoading />}
            {!isLoading && (
                <div className="container channel-container tw-px-0 md:tw-px-4 tw-h-full">
                    <div className="row tw-mt-0 md:tw-mt-5 ">
                        <div className="col-sm-8 align-items-center tw-hidden md:tw-flex">
                            <button
                                className="btn btn-secondary mr-2"
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/`
                                    )
                                }
                            >
                                <FontAwesomeIcon icon="chevron-left" />
                            </button>
                            <h1 className="mr-5 mb-0">
                                {channelName || 'Create channel'}
                            </h1>
                        </div>
                        {/* Mobile View heading */}
                        <div className="col-sm-8 tw-flex-col tw-flex md:tw-hidden tw-gap-6 tw-p-5 tw-pt-8 tw-relative tw-justify-center tw-items-center">
                            <div className="tw-flex tw-flex-row tw-items-center">
                                <button
                                    className="btn btn-secondary tw-absolute tw-top-5 tw-right-5 tw-cursor-pointer"
                                    onClick={() =>
                                        history.push(
                                            `/company/${companyId}/project/${projectId}/channels/`
                                        )
                                    }
                                >
                                    <CloseOutlined />
                                </button>
                            </div>
                            <h1 className="mb-0 tw-text-2xl">
                                {channelName
                                    ? 'Channel details'
                                    : 'Create channel'}
                            </h1>
                        </div>
                        <div className="col-sm-4 justify-content-end align-items-center tw-hidden md:tw-flex">
                            {channelId && (
                                <Dropdown
                                    overlay={renderMoreMenu}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.preventDefault()}
                                        className="myxp btn btn-secondary p-1 mx-0"
                                        style={{ fontSize: '20px', width: 35 }}
                                    />
                                </Dropdown>
                            )}
                            <button
                                className="myxp btn btn-secondary px-3 tw-hidden md:tw-flex"
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/`
                                    )
                                }
                            >
                                Cancel
                            </button>
                            <button
                                className="myxp btn btn-primary tw-hidden md:tw-flex"
                                onClick={handleSubmit(
                                    handleCreateOrUpdateChannel
                                )}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="tw-bg-white tw-p-7">
                        <div
                            className="row md:tw-my-12 tw-my-"
                            id="mobileInput"
                        >
                            {channelId && (
                                <>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>RTMP URL</label>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    className="form-control"
                                                    name="RTMPURL"
                                                    defaultValue={RTMPURL}
                                                    disabled
                                                />
                                                <CopyOutlined
                                                    onClick={() =>
                                                        copyKey(RTMPURL)
                                                    }
                                                    className="ml-2"
                                                    style={{ fontSize: '20px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Stream Key</label>
                                            <div className="d-flex align-items-center mb-2">
                                                <input
                                                    className="form-control"
                                                    name="streamKey"
                                                    defaultValue={streamKey}
                                                    disabled
                                                />
                                                <CopyOutlined
                                                    onClick={() =>
                                                        copyKey(streamKey)
                                                    }
                                                    className="ml-2"
                                                    style={{ fontSize: '20px' }}
                                                />
                                            </div>
                                            <a
                                                onClick={() =>
                                                    handleResetChannelStreamKey()
                                                }
                                                className="btn-link tw-text-sm md:tw-text-[12px]"
                                            >
                                                Reset stream key
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Playback URL</label>
                                            <div className="d-flex align-items-center mb-2">
                                                <input
                                                    className="form-control"
                                                    name="playbackUrl"
                                                    defaultValue={playbackUrl}
                                                    disabled
                                                />
                                                <CopyOutlined
                                                    onClick={() =>
                                                        copyKey(playbackUrl)
                                                    }
                                                    className="ml-2"
                                                    style={{ fontSize: '20px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="col-12">
                                <div className="form-group">
                                    <label>
                                        Channel name{' '}
                                        <span className="font-weight-bold text-danger">
                                            *
                                        </span>
                                    </label>
                                    <input
                                        className="form-control"
                                        name="channelName"
                                        defaultValue={channelName}
                                        placeholder="Channel name"
                                        onChange={(e) =>
                                            setChannelName(e.target.value)
                                        }
                                        ref={register({
                                            required: true,
                                            maxLength: 80,
                                            minLength: 3,
                                        })}
                                    />
                                    {errors.channelName?.type ===
                                        'required' && (
                                        <div className="invalid-feedback d-block">
                                            Channel name is required
                                        </div>
                                    )}
                                    {errors.channelName?.type ===
                                        'maxLength' && (
                                        <div className="invalid-feedback d-block">
                                            Channel name exceed max length(80)
                                        </div>
                                    )}
                                    {errors.channelName?.type ===
                                        'minLength' && (
                                        <div className="invalid-feedback d-block">
                                            Channel name min length(3)
                                        </div>
                                    )}
                                </div>
                                <div className="form-group mt-4">
                                    <label>Channel description</label>
                                    <input
                                        className="form-control"
                                        name="channelDescription"
                                        defaultValue={channelDescription}
                                        placeholder="Add some description for this channel"
                                        onChange={(e) =>
                                            setChannelDescription(
                                                e.target.value
                                            )
                                        }
                                        ref={register({ maxLength: 80 })}
                                    />
                                    {errors.channelDescription?.type ===
                                        'maxLength' && (
                                        <div className="invalid-feedback d-block">
                                            Channel description exceed max
                                            length(80)
                                        </div>
                                    )}
                                </div>
                                {channelType === ChannelTypes.IVS_CHANNEL && (
                                    <div className="form-group mt-4">
                                        <label>Quality</label>
                                        <Select
                                            defaultValue={channelQuality}
                                            onChange={setChannelQuality}
                                            className="w-100"
                                        >
                                            <Option value="HD">HD</Option>
                                            <Option value="SD">SD</Option>
                                        </Select>
                                    </div>
                                )}
                            </div>
                            {createdAt && (
                                <div className="col-12">
                                    <p className="font-weight-light text-danger tw-text-sm md:tw-text-[13px]">
                                        This channel{' '}
                                        {channelStatus === 'expired'
                                            ? 'expired'
                                            : 'expires'}{' '}
                                        on{' '}
                                        {moment(createdAt)
                                            .add(1, 'M')
                                            .format('D MMM YYYY')}
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="tw-w-full tw-flex tw-justify-start tw-mt-4 md:tw-hidden">
                            {channelId && (
                                <button
                                    className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-gray-btn-bg tw-rounded-md tw-bg-gray-btn-bg tw-text-danger tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer "
                                    onClick={() =>
                                        setDeletePromptVisibility(true)
                                    }
                                >
                                    Delete
                                </button>
                            )}
                            <button
                                className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-gray-btn-bg tw-rounded-md tw-bg-gray-btn-bg tw-text-black tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer tw-ml-2 "
                                onClick={() =>
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/channels/`
                                    )
                                }
                            >
                                Cancel
                            </button>
                            <button
                                className="tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-bg-blue-500 tw-text-white tw-text-sm tw-font-medium tw-text-center tw-cursor-pointer tw-ml-2"
                                onClick={handleSubmit(
                                    handleCreateOrUpdateChannel
                                )}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Prompt
                show={deletePromptVisibility}
                message="Are you sure you want to delete?"
                buttons={['Cancel', 'Proceed to Delete']}
                onHide={() => setDeletePromptVisibility(false)}
                callback={() => handleDeleteChannel()}
            />
            <SidebarContainer>
                <MainMenu priActive="channels" all />
            </SidebarContainer>
        </Layout>
    );
}

ChannelEdit.propTypes = {};

export default ChannelEdit;
