import * as React from 'react';

const DirectMessagesIcon = (props) => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M22.007 8.78L5.207.377A3.6 3.6 0 00.311 5.06l2.88 6.446c.132.314.132.669 0 .984l-2.88 6.446A3.602 3.602 0 003.6 24a3.766 3.766 0 001.62-.384l16.8-8.402a3.601 3.601 0 000-6.434h-.012zm-1.068 4.285l-16.8 8.402a1.2 1.2 0 01-1.62-1.56l2.868-6.446c.037-.086.07-.174.096-.264h8.268a1.2 1.2 0 000-2.4H5.483a2.405 2.405 0 00-.096-.265L2.52 4.086a1.2 1.2 0 011.62-1.56l16.8 8.402a1.2 1.2 0 010 2.137z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default DirectMessagesIcon;
