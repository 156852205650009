import * as React from 'react';

const YouTubeIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={props.width || 14}
                height={props.height || 11}
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                >
                <path
                    d="M7.31 10.487l-2.872-.054c-.93-.02-1.862.018-2.774-.177C.277 9.963.179 8.529.076 7.326a21.22 21.22 0 01.18-5.093C.407 1.293 1.001.73 1.92.67 5.02.447 8.142.474 11.236.577c.327.01.656.062.978.12 1.59.288 1.629 1.914 1.732 3.283.103 1.382.06 2.772-.137 4.145-.158 1.137-.46 2.09-1.732 2.183-1.595.12-3.154.217-4.753.186 0-.007-.01-.007-.014-.007zM5.622 7.608c1.202-.712 2.38-1.413 3.576-2.12-1.205-.713-2.381-1.414-3.576-2.122v4.242z"
                    fill="red"
                />
            </svg>
        </div>
    );
};

export default YouTubeIcon;
