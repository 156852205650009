import React from 'react';

import EyeIcon from 'Apps/VenueBuilder/Icons/EyeIcon';
import EyeClosedIcon from 'Apps/VenueBuilder/Icons/EyeClosedIcon';

import styles from './index.module.css';

const SettingsHeadingVisibility = ({
    setValue,
    value,
    visibleName
}) => {
    return (
        <div className={styles.VisibilityContainer}>
            {value ? (
                <span onClick={() => setValue(visibleName,false)}>
                    <EyeIcon isVisible={value} />
                </span>
            ) : (
                <span onClick={() => setValue(visibleName,true)}>
                    <EyeClosedIcon isVisible={value} />
                </span>
            )}
        </div>
    )
};

export default SettingsHeadingVisibility;