import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams, } from "react-router-dom";
import { Form, Input, Button, Checkbox, Collapse } from 'antd';


import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import { GetContentSecurity } from 'apis/rest/admin/GetContentSecurity';
import { UpdateContentSecurity } from 'apis/rest/admin/UpdateContentSecurity';
import * as ls from 'local-storage';

import "./contentSecurityPolicy.scss";

const { Panel } = Collapse;
function ContentSecurityPolicy() {
    const history = useHistory();
    const { projectId } = useParams();

    const companyData = history?.location?.state?.company || null

    const [isLoading, setIsLoading] = useState(true)
    const [contentSecurityPolicy, setContentSecurityPolicy] = useState(null)

    const [company, setCompany] = useState(null)

    useEffect(() => {
        if (companyData) {
            const jsonCompany = JSON.stringify(companyData)
            ls('superAdminCompany', jsonCompany);
            setCompany(companyData)
        } else {
            const jsonCompany = ls.get('superAdminCompany');
            if (jsonCompany) {
                const parseCompany = JSON.parse(jsonCompany)
                setCompany(parseCompany)
            }
        }
    }, [companyData])

    useEffect(() => {
        handleGetContentSecurty()
    }, [])

    const handleGetContentSecurty = async () => {
        setIsLoading(true)
        try {
            const response = await GetContentSecurity(projectId);
            if (response.status) {
                let data = response?.contentSecurityPolicy
                let inputs = []
                Object.keys(data).forEach(function (name) {
                    let value = data[name];
                    inputs.push({ name, value })
                });
                setContentSecurityPolicy(inputs)
            }

        } catch (error) {
            console.error('ERROR handleGetContentSecurty', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateContentSecurtyPolicy = async () => {
        let inputs = {}
        contentSecurityPolicy.map((item, i) => {
            inputs[item.name] = item.value;
        })

        try {
            const response = await UpdateContentSecurity(projectId, inputs);
            if (response.status) {
                openNotification('success', { message: "Successfully updated!", });
            } else {
                openNotification('error', { message: response.message });
            }
        } catch (error) {
            openNotification('error', { message: 'Encountered an error' });
            console.error('ERROR UpdateContentSecurity', error)
        }
    }

    const onChangeInput = async (value, index) => {
        let contentSecurityPolicyData = [...contentSecurityPolicy]
        contentSecurityPolicyData[index].value = value
        setContentSecurityPolicy([...contentSecurityPolicy])
    }

    return (
        <Layout disableMenuWidth={false} >
            <div className="container" style={{ width: "950px" }}>
                <div className="dashboard dashboard__xpmgr project-list">
                    <div className="dashboard__left justify-content-between w-100">
                        <div className="d-flex">
                            <button className="btn btn-secondary mr-2" onClick={() => history.goBack()}>
                                <FontAwesomeIcon
                                    icon="chevron-left"
                                />
                            </button>
                            <h1 className="mb-0">{company?.name}</h1>
                        </div>
                        <Button className="myxp btn btn-primary my-3" variant="primary" type="primary" onClick={() => handleUpdateContentSecurtyPolicy()}>
                            Save
        </Button>
                    </div>
                </div>
                {isLoading &&
                    <GlobalLoading />
                }
                {!isLoading && contentSecurityPolicy &&
                    <Form
                        className="mt-4"
                    >
                        <Collapse  >
                            {contentSecurityPolicy.map((item, i) => {
                                return (
                                    <Panel header={item.name} key={i}>
                                        <Form.Item
                                            className="mb-0"
                                            name={item.name}
                                        >
                                            <Input.TextArea
                                                className="mb-0"
                                                name={item.name}
                                                defaultValue={item.value}
                                                onChange={(e) => onChangeInput(e.target.value, i)}
                                            />
                                        </Form.Item>
                                    </Panel>
                                )
                            })}
                        </Collapse>
                    </Form>
                }

                <SidebarContainer>
                    <MainMenu
                        priActive="projects"
                        all
                    />
                </SidebarContainer>
            </div>
        </Layout>
    )
}

ContentSecurityPolicy.propTypes = {

}

export default ContentSecurityPolicy

