import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './alertSimple.scss';

function AlertSimple(props) {
    const {
        alert: { visibility, message, type },
    } = props;

    return (
        <CSSTransition
            in={visibility}
            timeout={300}
            classNames="alert-simple"
            unmountOnExit
        >
            <div className={`alert-simple`}>
                <div
                    className={`alert-simple__box alert-simple__box--${
                        type === 'processing' ? 'info' : type
                    }`}
                >
                    {type === 'processing' ? (
                        <div className="alert-simple__box process-wrap">
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status" />
                            </div>
                        </div>
                    ) : (
                        <i
                            style={{
                                background: `url(/assets/icon_alert_${type}.svg) no-repeat center`,
                            }}
                        />
                    )}
                    <p>{parse(message)}</p>
                </div>
            </div>
        </CSSTransition>
    );
}

AlertSimple.propTypes = {
    alert: PropTypes.object.isRequired,
};

export default AlertSimple;
