import React from 'react';

export default function MinimizeIcon() {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 1C6.425 1 1.5 5.925 1.5 12C1.5 18.075 6.425 23 12.5 23C18.575 23 23.5 18.075 23.5 12C23.5 5.925 18.575 1 12.5 1ZM8.5 11C8.23478 11 7.98043 11.1054 7.79289 11.2929C7.60536 11.4804 7.5 11.7348 7.5 12C7.5 12.2652 7.60536 12.5196 7.79289 12.7071C7.98043 12.8946 8.23478 13 8.5 13H16.5C16.7652 13 17.0196 12.8946 17.2071 12.7071C17.3946 12.5196 17.5 12.2652 17.5 12C17.5 11.7348 17.3946 11.4804 17.2071 11.2929C17.0196 11.1054 16.7652 11 16.5 11H8.5Z"
                fill="black"
            />
        </svg>
    );
}
