import React from 'react';

function Verifier() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                stroke="#DB3314"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M13.25 7.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 012.373 2.798C4.187.8 7.25.25 9.75 1.25"
            ></path>
            <path
                stroke="#DB3314"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M4.75 6.75l2.5 2.5 6-6.5"
            ></path>
        </svg>
    );
}

export default Verifier;
