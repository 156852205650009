import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, useParams } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Modal as AntdModal } from 'antd';
import useAlert from 'components/app/utils/hooks/useAlert';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import Alert from 'components/app/common/alert';

import InviteUsersPanel from 'components/app/components/leads/inviteUsersPanel';

import { getLeadsUsers } from 'apis/rest/LeadsUsers';

import UserAccountStatus from './userAccountStatus';
import InviteUsers from './inviteUsers';
import UserList from './userList';
import './leadsDashboard.scss';
import useScreenSize from 'components/app/utils/hooks/useScreenSize/useScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InviteUserMobileForm from '../../../../../../ComponentsV2/CommonComponents/MobileView/leads/InviteUserMobileForm';
import { CloseOutlined } from '@ant-design/icons';

function LeadsDashboard(props) {
    const { projectId, companyId } = useParams();
    const screenType = useScreenSize();
    const [leadsData, setLeadsData] = useState({ quota: 0, total: 0 });

    const [isModalVisible, setModalVisibility] = useState(false);
    const [users, setUsers] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [modalView, setModalView] = useState('inviteUsers');

    const { alert, showAlert } = useAlert();

    useEffect(() => {
        handleGetLeadsUsers();
    }, []);

    /**
     * retrieve users listing every 10mins.
     * to get the latest `login` status
     */
    useEffect(() => {
        const tenMinutes = 600000;

        const timer = setInterval(() => {
            handleGetLeadsUsers();
        }, tenMinutes);
        return () => clearTimeout(timer);
    }, []);

    /**
     * gets the list of leads users
     */
    const handleGetLeadsUsers = async (type = 'all') => {
        try {
            setIsBusy(true);
            const response = await getLeadsUsers({ projectId, companyId });
            if (response.status && response?.users) {
                setUsers(response.users);
                if (type === 'all')
                    setLeadsData({
                        total: response.leadsLimit,
                        quota: response.currentUsage,
                    });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsBusy(false);
        }
    };

    const determineModalView = () => {
        switch (modalView) {
            case 'inviteUsers':
                return (
                    <InviteUsersPanel
                        setModalVisibility={setModalVisibility}
                        handleGetLeadsUsers={handleGetLeadsUsers}
                        showAlert={showAlert}
                        leadsQuota={leadsData.quota}
                        totalLeadsQuota={leadsData.total}
                    />
                );

            default:
                break;
        }
    };

    /**
     * renders the modal size required for a particular modal view
     */
    const renderModalSize = () => {
        if (modalView === 'inviteUsers') {
            return 'large';
        }
        return 'small';
    };

    const inviteUserMobileFormRender = () => {
        return (
            <>
                <div className=" tw-w-full tw-flex-col tw-gap-4 tw-flex sm:tw-hidden">
                    {/* header  */}
                    <div className="col-sm-8 tw-flex-col tw-flex md:tw-hidden tw-gap-6 tw-p-5 tw-pt-8 tw-relative tw-justify-center tw-items-center">
                        <div className="tw-flex tw-flex-row tw-items-center">
                            <button
                                className="btn btn-secondary tw-absolute tw-top-5 tw-right-5 tw-cursor-pointer"
                                onClick={() => setModalVisibility(false)}
                            >
                                <CloseOutlined />
                            </button>
                        </div>

                        <h1 className="mb-0">Invite users</h1>
                        <span className="tw-text-sm -tw-mt-5">
                            You can invite up to 50 user(s)
                        </span>
                    </div>
                </div>
                <div className="tw-bg-white tw-p-7">
                    {/* form */}
                    <InviteUserMobileForm
                        setModalVisibility={setModalVisibility}
                        handleGetLeadsUsers={handleGetLeadsUsers}
                        showAlert={showAlert}
                        leadsQuota={leadsData.quota}
                        totalLeadsQuota={leadsData.total}
                    />
                </div>
            </>
        );
    };

    return (
        <Layout background="#F7F9FA" className="tw-p-0">
            <SidebarContainer>
                <MainMenu priActive="sponsorHome" all />
            </SidebarContainer>
            <Alert alert={alert} />
            {isModalVisible && screenType === 'mobile' ? (
                inviteUserMobileFormRender()
            ) : (
                <div className="leads-dashboard tw-m-0 md:tw-mx-[76px] tw-relative">
                    <h1 className="mb-3 tw-hidden md:tw-block tw-leading-10">
                        Manage Users
                        <br />
                        Lead Generation
                    </h1>
                    <div className="leads-inner-wrapper tw-m-7 tw-p-4 tw-flex tw-flex-col-reverse md:tw-flex-col tw-bg-white md:tw-m-0 tw-rounded-md tw-gap-4 md:tw-gap-0 md:tw-bg-none md:tw-p-0 md:tw-bg-transparent">
                        {users?.length ? (
                            <div className="col-12 col-sm-5 col-md-3 tw-inline md:tw-hidden">
                                <InviteUsers
                                    setModalVisibility={setModalVisibility}
                                    setModalView={setModalView}
                                    leadsQuota={leadsData.quota}
                                    totalLeadsQuota={leadsData.total}
                                    className="tw-p-0 tw-w-full md:tw-p-5"
                                />
                            </div>
                        ) : null}

                        <div className="row tw-mb-0 md:tw-mb-4">
                            <div className="col-12 col-sm-7 col-md-9">
                                <UserAccountStatus
                                    leadsQuota={leadsData.quota}
                                    setModalVisibility={setModalVisibility}
                                    setModalView={setModalView}
                                    totalLeadsQuota={leadsData.total}
                                />
                            </div>

                            <div className="col-12 col-sm-5 col-md-3 tw-hidden md:tw-inline">
                                <InviteUsers
                                    setModalVisibility={setModalVisibility}
                                    setModalView={setModalView}
                                    leadsQuota={leadsData.quota}
                                    totalLeadsQuota={leadsData.total}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="tw-flex tw-flex-1 tw-p-7 tw-flex-col tw-bg-white tw-mb-10">
                        <UserList
                            showAlert={showAlert}
                            users={users}
                            handleGetLeadsUsers={handleGetLeadsUsers}
                            isBusy={isBusy}
                            projectId={projectId}
                            disableActivate={leadsData.quota >= leadsData.total}
                            setModalVisibility={setModalVisibility}
                            setModalView={setModalView}
                            leadsData={leadsData}
                        />
                    </div>

                    <Modal
                        show={isModalVisible && screenType === 'desktop'}
                        centered={false}
                        onHide={setModalVisibility}
                        className="tw-hidden sm:tw-block"
                        dialogClassName={`custom-modal--${renderModalSize()}`}
                    >
                        <Modal.Body>{determineModalView()}</Modal.Body>
                    </Modal>
                </div>
            )}
        </Layout>
    );
}

export default withRouter(LeadsDashboard);
