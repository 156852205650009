import * as React from 'react';

const ImageBlockIcon = (props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 10}
            viewBox="0 0 24 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2.5l4.286 5.017 6-7.517L24 10H0l6-7.5z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default ImageBlockIcon;
