import * as React from 'react';

const EyeIcon = (props) => {
    return (
        <svg
            width={18}
            height={14}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.757.004c5.38-.18 8.547 5.389 9.123 6.498a1.087 1.087 0 010 1c-.774 1.5-3.662 6.358-8.637 6.498h-.225C3.719 14 .688 8.592.121 7.502a1.087 1.087 0 010-1C.903 5.002 3.782.144 8.757.004zM2.001 7.002c.791 1.41 3.32 5.099 7.197 4.999 3.598-.1 5.92-3.39 6.846-4.999-.8-1.41-3.338-5.108-7.197-4.998-3.599.1-5.947 3.389-6.846 4.998zm5.25-2.91A2.93 2.93 0 019 3.504c.834 0 1.635.369 2.226 1.025.59.656.922 1.546.922 2.474 0 .692-.185 1.369-.53 1.944a3.246 3.246 0 01-1.414 1.289 2.857 2.857 0 01-1.82.199 3.065 3.065 0 01-1.611-.958 3.635 3.635 0 01-.862-1.791 3.862 3.862 0 01.179-2.022 3.446 3.446 0 011.16-1.57zm1 4.157c.221.165.482.253.75.253.357 0 .7-.158.953-.44.253-.28.396-.662.396-1.06 0-.296-.08-.586-.228-.833a1.391 1.391 0 00-.606-.552 1.225 1.225 0 00-.78-.086c-.261.058-.501.201-.69.41-.189.21-.317.478-.37.769-.052.29-.025.592.077.866.102.274.275.508.497.673z"
                fill={props.isVisible ? "#0B1C2C" : "#969696"}
            />
        </svg>
    );
};

export default EyeIcon;
