import React from 'react';

export default function UndrawBuildingIcon() {
    return (
        <svg width="193" height="145" viewBox="0 0 193 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_19856_32164)">
                <path d="M111.523 7.05718C113.171 10.5634 109.679 11.5566 105.68 13.4359C101.681 15.3152 98.6877 17.369 97.0401 13.8628C95.3925 10.3565 94.0557 3.39722 99.7134 0.738608C105.566 -2.01159 109.875 3.55092 111.523 7.05718Z" fill="#2F2E41"/>
                <path d="M103.363 13.9526C106.337 13.9526 108.747 11.5421 108.747 8.56863C108.747 5.59515 106.337 3.18466 103.363 3.18466C100.39 3.18466 97.9792 5.59515 97.9792 8.56863C97.9792 11.5421 100.39 13.9526 103.363 13.9526Z" fill="#FFB8B8"/>
                <path d="M130.036 32.4454C129.85 32.2021 129.664 31.9632 129.475 31.7308C129.232 31.4283 128.987 31.1353 128.741 30.8518C125.95 27.6316 122.982 25.604 119.714 25.387H119.71L119.661 25.3826L113.149 31.7308L110.935 33.8878L110.983 34.0062L112.178 36.9809H133.11C132.167 35.4156 131.141 33.9016 130.036 32.4454Z" fill="#2F2E41"/>
                <path d="M97.5085 10.7362L99.4358 9.83056L99.1673 7.05028L100.456 9.35113L101.377 8.91827L101.221 7.29644L101.972 8.63859L108.817 5.42225C108.174 4.05419 107.014 2.99754 105.592 2.48475C104.17 1.97195 102.603 2.04502 101.235 2.68788L100.243 3.15402C98.8748 3.79689 97.8182 4.95688 97.3054 6.37881C96.7926 7.80075 96.8657 9.36815 97.5085 10.7362Z" fill="#2F2E41"/>
                <path d="M142.115 108.464H29.6965C28.7376 108.463 27.8182 108.082 27.1402 107.404C26.4621 106.725 26.0807 105.806 26.0796 104.847V37.2974C26.0804 36.5949 26.3598 35.9214 26.8565 35.4246C27.3533 34.9278 28.0268 34.6484 28.7294 34.6476H143.036C143.751 34.6484 144.436 34.9326 144.941 35.438C145.447 35.9434 145.731 36.6285 145.732 37.3432V104.847C145.731 105.806 145.349 106.725 144.671 107.404C143.993 108.082 143.074 108.463 142.115 108.464Z" fill="#F2F2F2"/>
                <path d="M33.2039 104.847H138.607C139.538 104.847 140.43 104.478 141.087 103.82C141.745 103.162 142.115 102.27 142.115 101.34V44.0065C142.115 43.0763 141.745 42.1842 141.087 41.5264C140.43 40.8687 139.538 40.4991 138.607 40.4991H33.2039C32.2737 40.4991 31.3816 40.8687 30.7238 41.5264C30.0661 42.1842 29.6965 43.0763 29.6965 44.0065V101.34C29.6965 102.27 30.0661 103.162 30.7238 103.82C31.3816 104.478 32.2737 104.847 33.2039 104.847Z" fill="white"/>
                <path d="M145.681 37.0905H26.0288V35.2491C26.0302 34.287 26.4132 33.3647 27.0937 32.6846C27.7743 32.0044 28.6968 31.622 29.6589 31.6212H142.051C143.013 31.622 143.936 32.0044 144.616 32.6846C145.297 33.3647 145.68 34.287 145.681 35.2491V37.0905Z" fill="#377BF7"/>
                <path d="M32.626 35.3003C33.1445 35.3003 33.5649 34.8799 33.5649 34.3613C33.5649 33.8427 33.1445 33.4224 32.626 33.4224C32.1074 33.4224 31.687 33.8427 31.687 34.3613C31.687 34.8799 32.1074 35.3003 32.626 35.3003Z" fill="white"/>
                <path d="M36.19 35.3003C36.7086 35.3003 37.129 34.8799 37.129 34.3613C37.129 33.8427 36.7086 33.4224 36.19 33.4224C35.6715 33.4224 35.2511 33.8427 35.2511 34.3613C35.2511 34.8799 35.6715 35.3003 36.19 35.3003Z" fill="white"/>
                <path d="M39.7541 35.3003C40.2727 35.3003 40.693 34.8799 40.693 34.3613C40.693 33.8427 40.2727 33.4224 39.7541 33.4224C39.2355 33.4224 38.8151 33.8427 38.8151 34.3613C38.8151 34.8799 39.2355 35.3003 39.7541 35.3003Z" fill="white"/>
                <path d="M89.1945 92.4134H79.7686C79.2165 92.4128 78.6871 92.1932 78.2967 91.8028C77.9063 91.4124 77.6867 90.8831 77.6861 90.3309V80.905C77.6867 80.3529 77.9063 79.8236 78.2967 79.4332C78.6871 79.0428 79.2165 78.8232 79.7686 78.8225H89.1945C89.7466 78.8232 90.2759 79.0428 90.6663 79.4332C91.0568 79.8236 91.2764 80.3529 91.277 80.905V90.3309C91.2764 90.8831 91.0568 91.4124 90.6663 91.8028C90.2759 92.1932 89.7466 92.4128 89.1945 92.4134ZM79.7686 79.261C79.3327 79.2614 78.9148 79.4348 78.6066 79.743C78.2984 80.0512 78.125 80.4691 78.1245 80.905V90.3309C78.125 90.7668 78.2984 91.1847 78.6066 91.4929C78.9148 91.8012 79.3327 91.9745 79.7686 91.975H89.1945C89.6304 91.9745 90.0483 91.8012 90.3565 91.4929C90.6647 91.1847 90.8381 90.7668 90.8386 90.3309V80.905C90.8381 80.4691 90.6647 80.0512 90.3565 79.743C90.0483 79.4348 89.6304 79.2614 89.1945 79.261H79.7686Z" fill="#CCCCCC"/>
                <path d="M87.3312 89.7829H81.6318C81.4575 89.7827 81.2903 89.7134 81.167 89.5901C81.0437 89.4668 80.9744 89.2997 80.9742 89.1253V82.1107C80.9744 81.9363 81.0437 81.7691 81.167 81.6459C81.2903 81.5226 81.4575 81.4532 81.6318 81.453H85.7616C85.9114 81.4532 86.0567 81.5043 86.1735 81.5981L87.7431 82.8594C87.82 82.9208 87.882 82.9988 87.9246 83.0876C87.9671 83.1763 87.9891 83.2735 87.9889 83.372V89.1253C87.9887 89.2997 87.9193 89.4668 87.796 89.5901C87.6728 89.7134 87.5056 89.7827 87.3312 89.7829ZM81.6318 81.8914C81.5737 81.8915 81.518 81.9146 81.4769 81.9557C81.4358 81.9968 81.4127 82.0525 81.4126 82.1107V89.1253C81.4127 89.1834 81.4358 89.2391 81.4769 89.2802C81.518 89.3213 81.5737 89.3444 81.6318 89.3445H87.3312C87.3894 89.3444 87.4451 89.3213 87.4862 89.2802C87.5273 89.2391 87.5504 89.1834 87.5504 89.1253V83.372C87.5505 83.3392 87.5432 83.3068 87.529 83.2772C87.5148 83.2476 87.4942 83.2216 87.4686 83.2011L85.8988 81.9397C85.8599 81.9085 85.8115 81.8915 85.7616 81.8914H81.6318Z" fill="#CCCCCC"/>
                <path d="M86.2352 84.5219H82.7279C82.6697 84.5219 82.614 84.4988 82.5729 84.4577C82.5318 84.4166 82.5087 84.3609 82.5087 84.3027C82.5087 84.2446 82.5318 84.1888 82.5729 84.1477C82.614 84.1066 82.6697 84.0835 82.7279 84.0835H86.2352C86.2933 84.0835 86.3491 84.1066 86.3902 84.1477C86.4313 84.1888 86.4544 84.2446 86.4544 84.3027C86.4544 84.3609 86.4313 84.4166 86.3902 84.4577C86.3491 84.4988 86.2933 84.5219 86.2352 84.5219Z" fill="#CCCCCC"/>
                <path d="M86.2352 85.8372H82.7279C82.6697 85.8372 82.614 85.8141 82.5729 85.773C82.5318 85.7319 82.5087 85.6761 82.5087 85.618C82.5087 85.5598 82.5318 85.5041 82.5729 85.463C82.614 85.4219 82.6697 85.3988 82.7279 85.3988H86.2352C86.2933 85.3988 86.3491 85.4219 86.3902 85.463C86.4313 85.5041 86.4544 85.5598 86.4544 85.618C86.4544 85.6761 86.4313 85.7319 86.3902 85.773C86.3491 85.8141 86.2933 85.8372 86.2352 85.8372Z" fill="#CCCCCC"/>
                <path d="M86.2352 87.1524H82.7279C82.6697 87.1524 82.614 87.1293 82.5729 87.0882C82.5318 87.0471 82.5087 86.9914 82.5087 86.9332C82.5087 86.8751 82.5318 86.8193 82.5729 86.7782C82.614 86.7371 82.6697 86.714 82.7279 86.714H86.2352C86.2933 86.714 86.3491 86.7371 86.3902 86.7782C86.4313 86.8193 86.4544 86.8751 86.4544 86.9332C86.4544 86.9914 86.4313 87.0471 86.3902 87.0882C86.3491 87.1293 86.2933 87.1524 86.2352 87.1524Z" fill="#CCCCCC"/>
                <path d="M111.554 92.4134H102.128C101.576 92.4128 101.046 92.1932 100.656 91.8028C100.266 91.4124 100.046 90.8831 100.045 90.3309V80.905C100.046 80.3529 100.266 79.8236 100.656 79.4332C101.046 79.0428 101.576 78.8232 102.128 78.8225H111.554C112.106 78.8232 112.635 79.0428 113.026 79.4332C113.416 79.8236 113.636 80.3529 113.636 80.905V90.3309C113.636 90.8831 113.416 91.4124 113.026 91.8028C112.635 92.1932 112.106 92.4128 111.554 92.4134ZM102.128 79.261C101.692 79.2614 101.274 79.4348 100.966 79.743C100.658 80.0512 100.484 80.4691 100.484 80.905V90.3309C100.484 90.7668 100.658 91.1847 100.966 91.4929C101.274 91.8012 101.692 91.9745 102.128 91.975H111.554C111.99 91.9745 112.407 91.8012 112.716 91.4929C113.024 91.1847 113.197 90.7668 113.198 90.3309V80.905C113.197 80.4691 113.024 80.0512 112.716 79.743C112.407 79.4348 111.99 79.2614 111.554 79.261H102.128Z" fill="#CCCCCC"/>
                <path d="M109.91 88.0293H103.772C103.597 88.0291 103.43 87.9597 103.307 87.8364C103.184 87.7132 103.114 87.546 103.114 87.3716V83.8643C103.114 83.69 103.184 83.5228 103.307 83.3995C103.43 83.2762 103.597 83.2069 103.772 83.2067H109.91C110.084 83.2069 110.251 83.2762 110.374 83.3995C110.498 83.5228 110.567 83.69 110.567 83.8643V87.3716C110.567 87.546 110.498 87.7132 110.374 87.8364C110.251 87.9597 110.084 88.0291 109.91 88.0293ZM103.772 83.6451C103.714 83.6452 103.658 83.6683 103.617 83.7094C103.576 83.7505 103.553 83.8062 103.553 83.8643V87.3716C103.553 87.4298 103.576 87.4855 103.617 87.5266C103.658 87.5677 103.714 87.5908 103.772 87.5909H109.91C109.968 87.5908 110.023 87.5677 110.065 87.5266C110.106 87.4855 110.129 87.4298 110.129 87.3716V83.8643C110.129 83.8062 110.106 83.7505 110.065 83.7094C110.023 83.6683 109.968 83.6452 109.91 83.6451H103.772Z" fill="#CCCCCC"/>
                <path d="M106.841 86.1963C106.718 86.1965 106.597 86.1621 106.492 86.0971L103.217 84.0502C103.168 84.0193 103.133 83.9702 103.12 83.9136C103.107 83.857 103.117 83.7975 103.148 83.7483C103.179 83.6991 103.228 83.664 103.284 83.6509C103.341 83.6378 103.4 83.6477 103.45 83.6784L106.725 85.7252C106.76 85.7468 106.8 85.7583 106.841 85.7583C106.882 85.7583 106.922 85.7468 106.957 85.7252L110.232 83.6784C110.281 83.6477 110.341 83.6378 110.397 83.6509C110.454 83.664 110.503 83.6991 110.534 83.7483C110.564 83.7975 110.574 83.857 110.561 83.9136C110.548 83.9702 110.513 84.0193 110.464 84.0502L107.189 86.0971C107.085 86.1621 106.964 86.1965 106.841 86.1963Z" fill="#CCCCCC"/>
                <path d="M66.8353 92.4134H57.4094C56.8573 92.4128 56.3279 92.1932 55.9375 91.8028C55.5471 91.4124 55.3275 90.8831 55.3269 90.3309V80.905C55.3275 80.3529 55.5471 79.8236 55.9375 79.4332C56.3279 79.0428 56.8573 78.8232 57.4094 78.8225H66.8353C67.3874 78.8232 67.9168 79.0428 68.3072 79.4332C68.6976 79.8236 68.9172 80.3529 68.9178 80.905V90.3309C68.9172 90.8831 68.6976 91.4124 68.3072 91.8028C67.9168 92.1932 67.3874 92.4128 66.8353 92.4134ZM57.4094 79.261C56.9735 79.2614 56.5556 79.4348 56.2474 79.743C55.9392 80.0512 55.7658 80.4691 55.7653 80.905V90.3309C55.7658 90.7668 55.9392 91.1847 56.2474 91.4929C56.5556 91.8012 56.9735 91.9745 57.4094 91.975H66.8353C67.2712 91.9745 67.6891 91.8012 67.9973 91.4929C68.3055 91.1847 68.4789 90.7668 68.4794 90.3309V80.905C68.4789 80.4691 68.3055 80.0512 67.9973 79.743C67.6891 79.4348 67.2712 79.2614 66.8353 79.261H57.4094Z" fill="#CCCCCC"/>
                <path d="M65.1913 88.0293H59.0534C58.8791 88.0291 58.7119 87.9597 58.5886 87.8364C58.4653 87.7132 58.396 87.546 58.3958 87.3716V83.8643C58.396 83.69 58.4653 83.5228 58.5886 83.3995C58.7119 83.2762 58.8791 83.2069 59.0534 83.2067H65.1913C65.3656 83.2069 65.5328 83.2762 65.6561 83.3995C65.7793 83.5228 65.8487 83.69 65.8489 83.8643V87.3716C65.8487 87.546 65.7793 87.7132 65.6561 87.8364C65.5328 87.9597 65.3656 88.0291 65.1913 88.0293ZM59.0534 83.6451C58.9953 83.6452 58.9396 83.6683 58.8985 83.7094C58.8574 83.7505 58.8343 83.8062 58.8342 83.8643V87.3716C58.8343 87.4298 58.8574 87.4855 58.8985 87.5266C58.9396 87.5677 58.9953 87.5908 59.0534 87.5909H65.1913C65.2494 87.5908 65.3051 87.5677 65.3462 87.5266C65.3873 87.4855 65.4104 87.4298 65.4105 87.3716V83.8643C65.4104 83.8062 65.3873 83.7505 65.3462 83.7094C65.3051 83.6683 65.2494 83.6452 65.1913 83.6451H59.0534Z" fill="#CCCCCC"/>
                <path d="M64.2184 88.0293H59.8071C59.7301 88.0293 59.6545 88.0091 59.5878 87.9706C59.5212 87.9321 59.4658 87.8768 59.4273 87.8101C59.3889 87.7435 59.3686 87.6678 59.3686 87.5909C59.3686 87.5139 59.3889 87.4383 59.4274 87.3717L60.7562 85.07C60.7946 85.0033 60.85 84.948 60.9166 84.9095C60.9833 84.871 61.0589 84.8508 61.1359 84.8508C61.2128 84.8508 61.2884 84.871 61.3551 84.9095C61.4217 84.948 61.4771 85.0033 61.5156 85.07L62.3738 86.5565L62.8709 85.6953C62.9094 85.6286 62.9648 85.5733 63.0314 85.5348C63.0981 85.4963 63.1737 85.4761 63.2507 85.4761C63.3276 85.4761 63.4032 85.4963 63.4699 85.5348C63.5365 85.5733 63.5919 85.6286 63.6304 85.6953L64.5982 87.3717C64.6366 87.4383 64.6569 87.5139 64.6569 87.5909C64.6569 87.6679 64.6366 87.7435 64.5981 87.8101C64.5597 87.8768 64.5043 87.9321 64.4376 87.9706C64.371 88.009 64.2954 88.0293 64.2184 88.0293H64.2184ZM64.2184 87.5909L63.2506 85.9145L62.5637 87.1045C62.5444 87.1378 62.5167 87.1655 62.4834 87.1847C62.4501 87.204 62.4123 87.2141 62.3738 87.2141C62.3353 87.2141 62.2975 87.204 62.2642 87.1847C62.2308 87.1655 62.2031 87.1378 62.1839 87.1045L61.1359 85.2892L59.8071 87.5909H64.2184Z" fill="#CCCCCC"/>
                <path d="M64.5336 84.9603C64.7757 84.9603 64.972 84.764 64.972 84.5219C64.972 84.2798 64.7757 84.0835 64.5336 84.0835C64.2915 84.0835 64.0952 84.2798 64.0952 84.5219C64.0952 84.764 64.2915 84.9603 64.5336 84.9603Z" fill="#CCCCCC"/>
                <path d="M169.362 142.08L166.675 142.079L165.396 131.713L169.362 131.714L169.362 142.08Z" fill="#FFB8B8"/>
                <path d="M170.047 144.685L161.382 144.684V144.575C161.382 144.132 161.469 143.693 161.639 143.284C161.808 142.875 162.057 142.503 162.37 142.19C162.683 141.877 163.055 141.628 163.464 141.459C163.873 141.289 164.312 141.202 164.755 141.202H164.755L170.048 141.202L170.047 144.685Z" fill="#2F2E41"/>
                <path d="M180.322 142.08L177.635 142.079L176.357 131.713L180.323 131.714L180.322 142.08Z" fill="#FFB8B8"/>
                <path d="M181.008 144.685L172.342 144.684V144.575C172.342 144.132 172.43 143.693 172.599 143.284C172.769 142.875 173.017 142.503 173.33 142.19C173.643 141.877 174.015 141.628 174.424 141.459C174.834 141.289 175.272 141.202 175.715 141.202H175.715L181.008 141.202L181.008 144.685Z" fill="#2F2E41"/>
                <path d="M179.517 137.586L177.028 136.829C176.842 136.772 176.677 136.663 176.554 136.513C176.431 136.364 176.354 136.181 176.334 135.988L174.013 113.996C174.012 113.97 174 113.945 173.98 113.927C173.961 113.908 173.935 113.898 173.908 113.898C173.882 113.896 173.855 113.904 173.834 113.92C173.813 113.937 173.8 113.961 173.797 113.988L169.91 135.295C169.886 135.424 169.837 135.547 169.765 135.657C169.693 135.767 169.6 135.862 169.491 135.935C169.382 136.009 169.26 136.06 169.131 136.085C169.002 136.111 168.869 136.11 168.74 136.084L165.559 135.428C165.326 135.38 165.118 135.248 164.974 135.059C164.83 134.869 164.759 134.633 164.774 134.396L167.138 99.473L183.171 97.7431L180.788 136.702C180.773 136.953 180.663 137.188 180.48 137.36C180.298 137.532 180.056 137.628 179.805 137.629C179.708 137.629 179.61 137.614 179.517 137.586Z" fill="#2F2E41"/>
                <path d="M179.502 69.061C182.475 69.061 184.886 66.6505 184.886 63.677C184.886 60.7035 182.475 58.293 179.502 58.293C176.528 58.293 174.118 60.7035 174.118 63.677C174.118 66.6505 176.528 69.061 179.502 69.061Z" fill="#FFB8B8"/>
                <path d="M167.114 99.6781L170.59 77.79C170.775 76.6262 171.205 75.5152 171.853 74.5308C172.501 73.5463 173.351 72.7111 174.346 72.0807C175.342 71.4503 176.46 71.0391 177.627 70.8745C178.794 70.7099 179.982 70.7957 181.113 71.1262C183.091 71.6981 184.784 72.9868 185.862 74.7405C186.941 76.4941 187.326 78.5867 186.944 80.6096L183.307 100.157L167.114 99.6781Z" fill="#377BF7"/>
                <path d="M180.988 103.594C181.257 103.417 181.484 103.183 181.652 102.908C181.821 102.633 181.927 102.325 181.963 102.005C182 101.685 181.966 101.36 181.863 101.055C181.761 100.749 181.593 100.47 181.371 100.236L184.965 93.2761L180.917 93.708L178.125 100.251C177.757 100.65 177.549 101.17 177.539 101.713C177.529 102.255 177.719 102.782 178.071 103.195C178.424 103.607 178.916 103.875 179.454 103.949C179.991 104.022 180.537 103.896 180.988 103.594V103.594Z" fill="#FFB8B8"/>
                <path d="M181.758 99.0085C181.559 98.9804 181.373 98.8924 181.226 98.7562L179.85 97.4856C179.702 97.3476 179.599 97.1674 179.556 96.9693C179.514 96.7711 179.533 96.5646 179.611 96.3777L182.359 89.8632L181.97 76.9914C182.026 76.5776 182.163 76.1789 182.373 75.8181C182.583 75.4573 182.862 75.1415 183.195 74.8887C183.527 74.6359 183.906 74.4511 184.31 74.3448C184.714 74.2386 185.134 74.2129 185.548 74.2693C185.962 74.3258 186.36 74.4632 186.721 74.6737C187.081 74.8843 187.397 75.1638 187.65 75.4964C187.902 75.829 188.086 76.2081 188.192 76.612C188.298 77.0159 188.324 77.4367 188.267 77.8503L188.933 85.9418C189.136 88.3976 188.44 90.8434 186.974 92.8242L182.688 98.6184C182.606 98.7298 182.501 98.8225 182.38 98.8906C182.259 98.9588 182.126 99.0007 181.988 99.0138C181.911 99.0211 181.834 99.0194 181.758 99.0085Z" fill="#377BF7"/>
                <path d="M148.497 60.7436C148.556 61.0605 148.683 61.3606 148.87 61.6229C149.057 61.8852 149.3 62.1033 149.581 62.2617C149.861 62.4202 150.173 62.5152 150.494 62.5401C150.816 62.5649 151.138 62.519 151.44 62.4056L156.448 68.4291L157.628 64.5333L152.692 59.4106C152.468 58.9165 152.07 58.5218 151.575 58.3012C151.079 58.0806 150.519 58.0496 150.002 58.2139C149.485 58.3782 149.046 58.7265 148.769 59.1927C148.492 59.6589 148.395 60.2108 148.497 60.7436Z" fill="#FFB8B8"/>
                <path d="M152.42 63.2404C152.523 63.0682 152.677 62.9317 152.86 62.8489L154.566 62.078C154.751 61.995 154.957 61.9708 155.156 62.0086C155.355 62.0464 155.538 62.1445 155.679 62.2896L160.607 67.3603L172.612 72.02C172.971 72.2328 173.285 72.5145 173.535 72.8487C173.786 73.183 173.968 73.5633 174.071 73.9679C174.174 74.3726 174.197 74.7936 174.137 75.207C174.078 75.6203 173.937 76.0179 173.724 76.3769C173.511 76.7359 173.229 77.0494 172.894 77.2994C172.56 77.5494 172.179 77.7309 171.774 77.8338C171.37 77.9366 170.948 77.9586 170.535 77.8985C170.122 77.8385 169.725 77.6976 169.366 77.4839L161.655 74.9429C159.314 74.1721 157.333 72.5773 156.081 70.4554L152.416 64.2495C152.346 64.1301 152.301 63.9973 152.286 63.8596C152.27 63.7219 152.283 63.5825 152.325 63.4503C152.348 63.3769 152.38 63.3064 152.42 63.2404Z" fill="#377BF7"/>
                <path opacity="0.1" d="M183.869 82.3459L183.649 89.3605L179.611 96.3776L182.983 88.5578L183.869 82.3459Z" fill="black"/>
                <path d="M178.885 64.6888C178.547 64.7327 178.204 64.6525 177.921 64.4629C177.637 64.2733 177.432 63.9873 177.344 63.658C177.258 63.279 177.071 62.9304 176.803 62.6495C176.535 62.3686 176.195 62.1661 175.82 62.0636C174.832 61.8379 173.625 62.3028 172.902 61.5545C172.747 61.3837 172.634 61.1785 172.574 60.9558C172.514 60.7332 172.508 60.4994 172.556 60.2738C172.689 59.5467 173.21 58.9974 173.776 58.558C174.967 57.6377 176.408 57.099 177.911 57.0124C178.689 56.9899 179.468 57.0286 180.239 57.1283C181.048 57.1793 181.847 57.3253 182.621 57.5635C183.503 57.8962 184.286 58.4458 184.899 59.1615C185.512 59.8773 185.935 60.736 186.129 61.6583C186.305 62.5835 186.278 63.5362 186.049 64.4498C185.82 65.3634 185.394 66.2159 184.801 66.9479C184.091 67.8317 183.176 68.5285 182.135 68.9775C181.887 69.0945 181.603 69.1089 181.345 69.0176C180.696 68.7598 180.802 67.9568 181.098 67.4212C181.414 66.848 181.901 66.293 181.646 65.5955C181.519 65.272 181.274 65.0085 180.961 64.8576C180.317 64.5289 179.596 64.6067 178.903 64.6867L178.885 64.6888Z" fill="#2F2E41"/>
                <path d="M146.698 67.7292H80.0586C79.0996 67.7281 78.1803 67.3467 77.5022 66.6687C76.8241 65.9906 76.4427 65.0712 76.4417 64.1123V49.8638C76.4427 48.9048 76.8241 47.9855 77.5022 47.3074C78.1803 46.6294 79.0996 46.2479 80.0586 46.2469H146.698C147.657 46.248 148.576 46.6294 149.254 47.3074C149.932 47.9855 150.314 48.9049 150.315 49.8638V64.1123C150.314 65.0712 149.932 65.9906 149.254 66.6686C148.576 67.3467 147.657 67.7281 146.698 67.7292Z" fill="white"/>
                <path d="M146.698 67.8388H80.0586C79.0706 67.8377 78.1234 67.4448 77.4247 66.7461C76.7261 66.0475 76.3331 65.1003 76.332 64.1123V49.8638C76.3331 48.8758 76.7261 47.9286 77.4247 47.23C78.1234 46.5314 79.0706 46.1384 80.0586 46.1373H146.698C147.686 46.1384 148.633 46.5314 149.332 47.23C150.03 47.9286 150.423 48.8758 150.424 49.8638V64.1123C150.423 65.1003 150.03 66.0475 149.332 66.7461C148.633 67.4448 147.686 67.8377 146.698 67.8388ZM80.0586 46.5757C79.1868 46.5767 78.351 46.9234 77.7346 47.5398C77.1182 48.1563 76.7714 48.992 76.7704 49.8638V64.1123C76.7714 64.9841 77.1182 65.8198 77.7346 66.4363C78.351 67.0527 79.1868 67.3994 80.0586 67.4004H146.698C147.569 67.3994 148.405 67.0527 149.022 66.4363C149.638 65.8198 149.985 64.9841 149.986 64.1123V49.8638C149.985 48.992 149.638 48.1563 149.022 47.5398C148.405 46.9234 147.569 46.5767 146.698 46.5757H80.0586Z" fill="#3F3D56"/>
                <path d="M138.587 64.2219H88.1692C87.2103 64.2208 86.2909 63.8394 85.6129 63.1614C84.9348 62.4833 84.5534 61.5639 84.5523 60.605V53.1519C84.5534 52.193 84.9348 51.2736 85.6129 50.5956C86.2909 49.9175 87.2103 49.5361 88.1692 49.535H138.587C139.546 49.5361 140.465 49.9175 141.143 50.5956C141.821 51.2736 142.203 52.193 142.204 53.1519V60.605C142.203 61.5639 141.821 62.4833 141.143 63.1614C140.465 63.8394 139.546 64.2208 138.587 64.2219Z" fill="#F2F2F2"/>
                <path d="M99.0189 60.2048C101.408 60.2048 103.344 58.2682 103.344 55.8793C103.344 53.4904 101.408 51.5539 99.0189 51.5539C96.63 51.5539 94.6935 53.4904 94.6935 55.8793C94.6935 58.2682 96.63 60.2048 99.0189 60.2048Z" fill="#FF6584"/>
                <path d="M135.454 63.7854L129.348 58.6648L123.348 53.6326C123.19 53.4998 122.99 53.4272 122.783 53.4276C122.577 53.428 122.377 53.5014 122.219 53.6348L116.238 58.6901L114.777 59.9249L112.575 58.0776L105.881 52.4635C105.722 52.3307 105.522 52.2581 105.316 52.2585C105.109 52.2589 104.909 52.3323 104.751 52.4657L98.079 58.1055L91.3017 63.8342C91.2674 63.8632 91.2428 63.9021 91.2313 63.9455C91.2198 63.989 91.2219 64.0349 91.2374 64.0771C91.2529 64.1193 91.281 64.1558 91.3179 64.1815C91.3548 64.2072 91.3987 64.2209 91.4436 64.2208L105.339 64.194L109.737 64.1856L109.694 64.2219L122.804 64.1967L135.313 64.1726C135.358 64.1725 135.402 64.1586 135.439 64.1328C135.476 64.1069 135.504 64.0704 135.519 64.0281C135.534 63.9859 135.536 63.9399 135.525 63.8965C135.513 63.8531 135.488 63.8143 135.454 63.7854Z" fill="#3F3D56"/>
                <path d="M192.696 145H150.608C150.55 145 150.494 144.977 150.453 144.936C150.412 144.895 150.389 144.839 150.389 144.781C150.389 144.723 150.412 144.667 150.453 144.626C150.494 144.585 150.55 144.562 150.608 144.562H192.696C192.754 144.562 192.81 144.585 192.851 144.626C192.892 144.667 192.915 144.723 192.915 144.781C192.915 144.839 192.892 144.895 192.851 144.936C192.81 144.977 192.754 145 192.696 145Z" fill="#CCCCCC"/>
                <path d="M133.243 28.9162C133.068 28.6317 132.837 28.3859 132.564 28.1936C132.291 28.0014 131.981 27.8669 131.654 27.7982C131.534 27.7731 131.413 27.7577 131.29 27.7522L114.589 12.8198C114.261 12.4826 113.823 12.2735 113.355 12.2301C113.262 12.2195 113.169 12.2166 113.076 12.2213C112.691 12.2389 112.319 12.3683 112.006 12.5938C111.693 12.8192 111.453 13.131 111.314 13.491C111.175 13.851 111.145 14.2436 111.226 14.6207C111.307 14.9979 111.496 15.3433 111.77 15.6147L118.164 21.1891L128.664 30.3411C128.673 30.5134 128.698 30.6845 128.741 30.8518C128.867 31.3365 129.137 31.7715 129.515 32.0991C129.894 32.4268 130.363 32.6317 130.861 32.6866C131.314 32.7396 131.774 32.6636 132.187 32.4674C132.514 32.3143 132.804 32.0922 133.037 31.8163C133.061 31.7878 133.083 31.7615 133.105 31.7308C133.412 31.3314 133.589 30.848 133.614 30.3451C133.639 29.8422 133.509 29.3437 133.243 28.9162H133.243Z" fill="#FFB8B8"/>
                <path d="M104.676 43.3072L104.521 36.9809L104.389 31.7308L104.299 28.0569L104.122 20.8954C104.087 20.512 103.941 20.1471 103.701 19.8452C103.462 19.5434 103.141 19.3177 102.775 19.1956C102.41 19.0736 102.017 19.0605 101.645 19.158C101.273 19.2555 100.936 19.4593 100.678 19.7446C100.617 19.8097 100.562 19.8802 100.513 19.955C100.244 20.3415 100.122 20.8124 100.171 21.2812L100.562 31.7308L100.757 36.9809L101.007 43.6777C100.935 43.7761 100.869 43.8794 100.812 43.9868C100.656 44.2824 100.562 44.6065 100.535 44.9397C100.508 45.2728 100.549 45.6079 100.656 45.9246C100.767 46.2567 100.948 46.5614 101.186 46.8187C101.423 47.076 101.713 47.2801 102.035 47.4174C102.357 47.5547 102.705 47.6222 103.055 47.6154C103.405 47.6086 103.75 47.5276 104.067 47.3779C104.435 47.204 104.754 46.9422 104.997 46.6155C105.239 46.2889 105.398 45.9076 105.458 45.5052C105.518 45.1028 105.478 44.6918 105.342 44.3084C105.206 43.9251 104.977 43.5812 104.676 43.3072H104.676Z" fill="#FFB8B8"/>
                <path d="M119.714 25.3869L118.164 21.1891L115.656 14.3893C115.475 13.8945 115.185 13.4469 114.808 13.0795C114.43 12.7122 113.975 12.4346 113.475 12.2674C113.436 12.2542 113.394 12.2411 113.355 12.2301C112.871 12.0832 112.361 12.0428 111.86 12.1117C111.359 12.1806 110.879 12.357 110.452 12.629L102.006 17.9865C101.233 18.476 100.68 19.247 100.464 20.1367C100.249 21.0263 100.387 21.965 100.851 22.7543C101.907 24.5806 103.058 26.3506 104.299 28.0569C105.283 29.3962 107.116 29.5387 107.434 31.7308H113.662L119.966 26.0709L119.714 25.3869Z" fill="#CCCCCC"/>
                <path d="M23.0057 76.6907C25.811 76.6907 28.0851 74.4166 28.0851 71.6113C28.0851 68.8061 25.811 66.5319 23.0057 66.5319C20.2004 66.5319 17.9263 68.8061 17.9263 71.6113C17.9263 74.4166 20.2004 76.6907 23.0057 76.6907Z" fill="#A0616A"/>
                <path d="M35.4757 68.1149C35.5257 68.1548 35.5743 68.197 35.6212 68.2416C35.8546 68.4614 36.0388 68.728 36.1619 69.024C36.2851 69.32 36.3442 69.6387 36.3356 69.9591C36.327 70.2796 36.2507 70.5946 36.1118 70.8835C35.973 71.1725 35.7746 71.4288 35.5298 71.6357C35.4727 71.6836 35.4132 71.7285 35.3514 71.7702L35.6952 83.3761C35.705 83.7561 35.592 84.129 35.373 84.4397C35.1541 84.7504 34.8408 84.9821 34.4797 85.1007L34.4587 85.1051L25.0353 86.1087C24.7791 86.1359 24.52 86.1121 24.2731 86.0387C24.0261 85.9653 23.7961 85.8437 23.5964 85.6809C23.3968 85.5181 23.2313 85.3173 23.1096 85.0902C22.9879 84.8631 22.9124 84.6142 22.8875 84.3578C22.8626 84.1013 22.8887 83.8425 22.9643 83.5962C23.04 83.3499 23.1637 83.1211 23.3283 82.9229C23.4929 82.7246 23.6951 82.561 23.9233 82.4414C24.1515 82.3218 24.4011 82.2485 24.6578 82.2259L31.3752 81.6343L32.5369 71.5834C32.4609 71.5161 32.3898 71.4436 32.324 71.3664C31.9597 70.9353 31.7687 70.3842 31.7879 69.8202C31.8072 69.2561 32.0354 68.7194 32.4282 68.3142C32.821 67.9089 33.3504 67.6641 33.9136 67.6273C34.4767 67.5905 35.0335 67.7643 35.4757 68.1149H35.4757Z" fill="#A0616A"/>
                <path d="M31.3714 142.304H33.907L35.113 132.524H31.3709L31.3714 142.304Z" fill="#A0616A"/>
                <path d="M30.7248 141.476L35.7179 141.476H35.7181C36.136 141.476 36.5497 141.558 36.9358 141.718C37.3219 141.878 37.6727 142.113 37.9681 142.408C38.2636 142.703 38.498 143.054 38.6579 143.44C38.8178 143.826 38.9001 144.24 38.9001 144.658V144.761L30.7249 144.762L30.7248 141.476Z" fill="#2F2E41"/>
                <path d="M6.17081 138.798L8.34929 140.096L14.3891 132.31L11.1738 130.396L6.17081 138.798Z" fill="#A0616A"/>
                <path d="M6.03861 137.756L10.3289 140.311L10.329 140.311C10.6881 140.525 11.0015 140.807 11.2514 141.142C11.5013 141.477 11.6828 141.858 11.7855 142.263C11.8882 142.668 11.9101 143.089 11.85 143.503C11.7899 143.916 11.6489 144.314 11.4351 144.673L11.3822 144.762L4.35782 140.579L6.03861 137.756Z" fill="#2F2E41"/>
                <path d="M34.5052 138.85H31.6661C31.4362 138.85 31.2143 138.765 31.0434 138.612C30.8724 138.458 30.7646 138.246 30.7407 138.018L27.8784 111.113C27.8712 111.045 27.8419 110.981 27.795 110.932C27.7482 110.883 27.6863 110.85 27.6191 110.839C27.5518 110.828 27.4829 110.84 27.4229 110.872C27.363 110.905 27.3153 110.956 27.2872 111.018L22.1237 122.451L12.9819 137.358C12.8636 137.549 12.6804 137.691 12.4659 137.758C12.2514 137.825 12.0199 137.813 11.8138 137.723L8.16173 136.116C8.04097 136.063 7.9329 135.985 7.84467 135.887C7.75644 135.789 7.69008 135.673 7.64997 135.547C7.60987 135.422 7.59694 135.289 7.61205 135.158C7.62716 135.027 7.66996 134.9 7.73761 134.787L16.1414 120.716L18.7188 108.86C17.9081 103.718 21.3832 96.0742 21.4185 95.9975L21.4357 95.9601L31.008 92.2188L31.056 92.2775C34.831 104.666 37.1313 117.998 35.4328 137.997C35.4119 138.229 35.3052 138.445 35.1337 138.603C34.9622 138.76 34.7381 138.849 34.5052 138.85Z" fill="#2F2E41"/>
                <path d="M21.05 97.2129L21.0055 97.1192C20.9841 97.0743 18.8557 92.5852 17.2149 87.7872C16.8996 86.8593 16.7804 85.8761 16.8648 84.8998C16.9493 83.9235 17.2356 82.9754 17.7056 82.1155C18.1817 81.2467 18.8331 80.4863 19.6184 79.8824C20.4038 79.2785 21.306 78.8443 22.2679 78.6072C23.9378 78.207 25.696 78.42 27.222 79.2074C28.7481 79.9948 29.9405 81.3042 30.582 82.8971C31.8835 86.1517 31.4687 89.6256 31.0783 92.3575L31.0706 92.4122L31.0208 92.4361L21.05 97.2129Z" fill="#3F3D56"/>
                <path d="M29.1643 68.7421H21.0988V65.2263C22.8691 64.523 24.6014 63.9249 25.6486 65.2263C26.581 65.2263 27.4752 65.5967 28.1346 66.2561C28.7939 66.9154 29.1643 67.8096 29.1643 68.742V68.7421Z" fill="#2F2E41"/>
                <path d="M20.6523 64.6059C15.8306 64.6059 14.481 70.6497 14.481 74.0593C14.481 75.9609 15.3409 76.641 16.6922 76.8711L17.1695 74.3259L18.2872 76.9806C18.6668 76.9825 19.0656 76.9752 19.479 76.9675L19.858 76.1871L20.7031 76.9535C24.0878 76.9585 26.8235 77.4519 26.8235 74.0593C26.8235 70.6497 25.6399 64.6059 20.6523 64.6059Z" fill="#2F2E41"/>
                <path d="M43.1367 145H1.0488C0.99066 145 0.934904 144.977 0.893794 144.936C0.852685 144.895 0.82959 144.839 0.82959 144.781C0.82959 144.723 0.852685 144.667 0.893794 144.626C0.934904 144.585 0.99066 144.562 1.0488 144.562H43.1367C43.1948 144.562 43.2506 144.585 43.2917 144.626C43.3328 144.667 43.3559 144.723 43.3559 144.781C43.3559 144.839 43.3328 144.895 43.2917 144.936C43.2506 144.977 43.1948 145 43.1367 145Z" fill="#CCCCCC"/>
                <path d="M43.9281 72.8806H34.5022C33.95 72.88 33.4207 72.6604 33.0303 72.27C32.6399 71.8796 32.4203 71.3503 32.4197 70.7981V61.3722C32.4203 60.8201 32.6399 60.2908 33.0303 59.9004C33.4207 59.51 33.95 59.2904 34.5022 59.2897H43.9281C44.4802 59.2904 45.0095 59.51 45.3999 59.9004C45.7903 60.2908 46.0099 60.8201 46.0106 61.3722V70.7981C46.0099 71.3503 45.7903 71.8796 45.3999 72.27C45.0095 72.6604 44.4802 72.88 43.9281 72.8806Z" fill="white"/>
                <path d="M43.9281 72.8806H34.5022C33.95 72.88 33.4207 72.6604 33.0303 72.27C32.6399 71.8796 32.4203 71.3503 32.4197 70.7981V61.3722C32.4203 60.8201 32.6399 60.2908 33.0303 59.9004C33.4207 59.51 33.95 59.2904 34.5022 59.2897H43.9281C44.4802 59.2904 45.0095 59.51 45.3999 59.9004C45.7903 60.2908 46.0099 60.8201 46.0106 61.3722V70.7981C46.0099 71.3503 45.7903 71.8796 45.3999 72.27C45.0095 72.6604 44.4802 72.88 43.9281 72.8806ZM34.5022 59.7281C34.0663 59.7286 33.6484 59.902 33.3402 60.2102C33.032 60.5184 32.8586 60.9363 32.8581 61.3722V70.7981C32.8586 71.234 33.032 71.6519 33.3402 71.9601C33.6484 72.2684 34.0663 72.4417 34.5022 72.4422H43.9281C44.364 72.4417 44.7819 72.2684 45.0901 71.9601C45.3983 71.6519 45.5717 71.234 45.5721 70.7981V61.3722C45.5717 60.9363 45.3983 60.5184 45.0901 60.2102C44.7819 59.902 44.364 59.7286 43.9281 59.7281H34.5022Z" fill="#3F3D56"/>
                <path d="M42.284 69.0445H36.1462C35.9719 69.0443 35.8047 68.975 35.6814 68.8517C35.5581 68.7284 35.4888 68.5612 35.4886 68.3869V63.7835C35.4888 63.6092 35.5581 63.442 35.6814 63.3187C35.8047 63.1954 35.9719 63.1261 36.1462 63.1259H37.8741C37.9865 63.1255 38.0971 63.1541 38.1952 63.2089C38.2933 63.2637 38.3756 63.3428 38.4342 63.4388L38.9161 64.2219H42.284C42.4584 64.2221 42.6255 64.2915 42.7488 64.4148C42.8721 64.538 42.9415 64.7052 42.9417 64.8796V68.3869C42.9415 68.5612 42.8721 68.7284 42.7488 68.8517C42.6255 68.975 42.4584 69.0443 42.284 69.0445Z" fill="#377BF7"/>
                <path d="M42.0844 71.3701C42.115 71.4262 42.1434 71.484 42.1694 71.5433C42.2991 71.8365 42.3655 72.1537 42.3641 72.4743C42.3627 72.7948 42.2936 73.1115 42.1613 73.4035C42.029 73.6955 41.8365 73.9562 41.5964 74.1686C41.3563 74.381 41.074 74.5403 40.768 74.636C40.6968 74.658 40.6245 74.6763 40.5514 74.6907L36.3647 85.5207C36.2263 85.8746 35.9774 86.1745 35.6551 86.3759C35.3327 86.5772 34.9541 86.6693 34.5752 86.6384L34.5542 86.6344L25.4797 83.9026C25.233 83.8283 25.0035 83.7059 24.8043 83.5424C24.6052 83.3789 24.4404 83.1776 24.3195 82.95C24.1986 82.7225 24.124 82.4733 24.1 82.2167C24.0759 81.9602 24.103 81.7014 24.1795 81.4554C24.256 81.2094 24.3805 80.9809 24.5459 80.7833C24.7112 80.5857 24.914 80.4227 25.1426 80.3039C25.3713 80.1851 25.6212 80.1128 25.8779 80.0911C26.1347 80.0694 26.3932 80.0988 26.6385 80.1776L33.0592 82.239L38.03 73.4264C37.9861 73.3349 37.9486 73.2405 37.9179 73.1438C37.7495 72.6051 37.7873 72.0231 38.0239 71.5108C38.2606 70.9984 38.6792 70.5923 39.1984 70.3713C39.7177 70.1502 40.3005 70.13 40.8339 70.3146C41.3672 70.4992 41.8129 70.8754 42.0844 71.3701H42.0844Z" fill="#A0616A"/>
            </g>
            <defs>
                <clipPath id="clip0_19856_32164">
                    <rect width="192.086" height="145" fill="white" transform="translate(0.82959)"/>
                </clipPath>
            </defs>
        </svg>
    )
}