import * as React from "react"

const GEVMELiveIcon = (props) => {
  return (
    <div
        className="icon-wrapper"
        style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '4px',
            padding: '5px'
        }}
    >
        <svg
        width={18}
        height={19}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <rect y={0.5} width={18} height={18} rx={3} fill="#000" />
        <rect
            y={0.5}
            width={18}
            height={18}
            rx={3}
            fill="url(#prefix__paint0_linear)"
        />
        <g clipPath="url(#prefix__clip0)">
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.532 3.16l-5.14 6.619a.373.373 0 00.223.595l2.702.525c.202.04.334.236.295.438l-.844 4.34a.373.373 0 00.677.279l4.01-5.979a.373.373 0 00-.238-.574l-2.672-.52a.373.373 0 01-.276-.503l1.905-4.855a.373.373 0 00-.642-.365z"
            fill="#fff"
            />
        </g>
        <defs>
            <linearGradient
            id="prefix__paint0_linear"
            x1={0}
            y1={0.5}
            x2={18}
            y2={18.5}
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor="#FF00A3" />
            <stop offset={1} stopColor="#7E0096" />
            </linearGradient>
            <clipPath id="prefix__clip0">
            <path
                fill="#fff"
                transform="translate(5 3)"
                d="M0 0h7.998v13.33H0z"
            />
            </clipPath>
        </defs>
        </svg>
    </div>
  )
}

export default GEVMELiveIcon
