import React from 'react';

import { useSelector } from 'react-redux';

import MediaViews from './mediaViews';
import Media from './media';

const Main = () => {
    const enableVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    return <>{enableVenue ? <MediaViews /> : <Media />}</>;
};

export default Main;
