import React from 'react';

import { Drawer } from 'antd';

const DrawerComponent = ({
    title,
    visible,
    placement = 'left',
    style = { top: 0, position: 'absolute' },
    mask = false,
    children,
}) => {
    return (
        <Drawer
            title={title || ''}
            placement={placement}
            closable={false}
            headerStyle={{ display: 'none' }}
            visible={visible}
            mask={mask}
            width={300}
            style={style}
            getContainer={false}
            bodyStyle={{ padding: 0 }}
        >
            {children}
        </Drawer>
    );
};

export default DrawerComponent;
