import * as ls from 'local-storage';
import { getAuth } from './helpers';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function getTwoFAConfiguration() {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/profile/2fa-setup`,
        {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function enableTwoFA({ code, identifier }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/enable-2fa`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, identifier }),
        }
    );
    return await response.json();
}

export async function generateBackupCodes() {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/profile/generate-recovery-codes`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function downloadBackupCodes(isJson = false) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2
        }/manager/profile/recovery-codes?download=${isJson ? 0 : 1}`,
        {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    if (!response?.status) throw response;
    if (isJson) {
        return await response.json();
    }
    return await response.blob();
}

export async function validateTwoFA(
    { code, identifier, trustedDevice },
    isTwoFAMethod = false
) {
    const twoFAAuthToken = ls('2FAToken');

    const authBearer = `Bearer ${twoFAAuthToken}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/${isTwoFAMethod ? '2fa' : 'recovery-code'
        }`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                Authorization: authBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, identifier, trustedDevice }),
        }
    );
    return response;
}
