export function toggleMainMenu(payload) {
    return { type: 'TOGGLE_MAINMENU', payload };
}

export function toggleSubMenu(payload) {
    return { type: 'TOGGLE_SUBMENU', payload };
}

export function updateHeaderTitle(payload) {
    return { type: 'UPDATE_HEADER_TITLE', payload };
}

export function updateActiveLivePageName(payload) {
    return { type: 'UPDATE_LIVEPAGE_NAME', payload };
}

export function updateProjectId(payload) {
    return { type: 'UPDATE_PROJECT_ID', payload };
}

export function updateShowcaseBlocksGroup(payload) {
    return { type: 'UPDATE_SHOWCASE_BLOCKS_GROUP', payload };
}

export function updateLivepageErrorStat(payload) {
    return { type: 'UPDATE_LIVEPAGE_ERROR_STAT', payload };
}

export function updateShowcaseId(payload) {
    return { type: 'UPDATE_SHOWCASE_ID', payload };
}

export function updateDistanceWidth(payload) {
    return { type: 'UPDATE_DISTANCE_WIDTH', payload };
}

export function updateAllowFX(payload) {
    return { type: 'UPDATE_ALLOW_FX', payload };
}

export function updateMainMenuVisibility(payload) {
    return { type: 'UPDATE_MAIN_MENU_VISIBILITY', payload };
}

export function updateSubMenuVisibility(payload) {
    return { type: 'UPDATE_SUB_MENU_VISIBILITY', payload };
}

export function enableSwitchEvent() {
    return { type: 'ENABLE_SWITCH_EVENT' };
}

export function disabledSwitchEvent() {
    return { type: 'DISABLED_SWITCH_EVENT' };
}

export function enableVenue() {
    return { type: 'ENABLE_VENUE' };
}
export function enableSponsorVenue() {
    return { type: 'ENABLE_SPONSOR_VENUE' };
}
export function enableSponsor() {
    return { type: 'ENABLE_SPONSOR' };
}

export function disbleVenue() {
    return { type: 'DISABLED_VENUE' };
}
