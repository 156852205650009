import * as React from 'react';

export const PositionBottomLeftIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 17H5V13"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    );
};

export const PositionTopLeftIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 9L5 5L9 5"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const PositionTopRightIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 5L17 5L17 9"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const PositionBottomRightIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 13L17 17L13 17"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const PositionTopCenteredIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 5H15"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    );
};

export const PositionBottomCenteredIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 17H15"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    );
};

export const PositionCenteredIcon = (props) => {
    const { color = '#DADEEB' } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.1667 1H1.83333C1.3731 1 1 1.44772 1 2V20C1 20.5523 1.3731 21 1.83333 21H20.1667C20.6269 21 21 20.5523 21 20V2C21 1.44772 20.6269 1 20.1667 1Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 11H15"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M11 7L11 15"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    );
};











export default PositionBottomLeftIcon;
