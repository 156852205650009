import * as React from 'react';

import UploadNewFileIconSvg from 'Apps/VenueBuilder/Icons/UploadNewFileIcon';

const UploadNewFileIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
            }}
        >
            <UploadNewFileIconSvg {...props} />
        </div>
    );
};

export default UploadNewFileIcon;
