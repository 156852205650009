import React, { useState, useEffect } from 'react';
import validator from 'validator';

import { Radio, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ImageUpload from 'components/app/common/imageUpload';

import styles from './index.module.css';

/**
 * CardDisplay Component
 *

 * @CardDisplay
 * @category Venue Builder
 * @subcategory Common
 * @description Block/Tags wrapper
 * @param {string} title A heading title
 * @param {string} value Value of the card
 * @param {string} tooltipText Tooltip text value
 * @param {string} previewImage Preview image value
 */

const CardDisplay = ({
    title,
    tooltipText,
    value,
    setValue,
    videoUrl,
    setVideoLink,
    previewImage,
    imageDescription,
    videoDescription,
    videoHint,
    removeImageUrl,
    isSubmitted,
    isRequired = false,
    setImage,
    videoLabel,
    imageLabel,
    autoUpload = true,
    withoutRedux = false,
    isClickSave,
}) => {
    const [videoLinkError, setVideoLinkError] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [visibleImageComponent, setVisibleImageComponent] = useState(false);

    useEffect(() => {
        setVisibleImageComponent(true);
    }, [previewImage]);

    const onVideoLinkChange = (value) => {
        if (validator.isURL(value)) {
            setVideoLink(value);
            setVideoLinkError(false);
        } else {
            setVideoLinkError(true);
        }
    };
    const onRemoveClick = () => {
        setIsRemove(true);
        removeImageUrl();

        setTimeout(() => {
            setIsRemove(false);
        }, 300);
    };

    return (
        <div className={styles.CardDisplayContainer}>
            <div className={`${styles.Heading} cardheading`}>
                <h3>
                    {title}
                    {isRequired && <span className="required">*</span>}
                    {tooltipText && (
                        <Tooltip placement="right" title={tooltipText}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    )}
                </h3>
            </div>
            <div className={`card-display ${styles.RadioGroup}`}>
                <Radio.Group
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                >
                    <Radio value="image">
                        <div className={styles.RadioDescription}>
                            <h3>Image</h3>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: imageDescription,
                                }}
                            />
                            {value === 'image' && (
                                <div
                                    className={`image-video ${
                                        !previewImage && isClickSave
                                            ? 'error'
                                            : ''
                                    }`}
                                >
                                    <div>
                                        <h3>{imageLabel || 'Image'}</h3>
                                        {visibleImageComponent && (
                                            <ImageUpload
                                                previewImages={
                                                    previewImage
                                                        ? [previewImage]
                                                        : []
                                                }
                                                prepareFilesUpload={(files) => {
                                                    setImage(files[0]);
                                                }}
                                                handleFileDelete={(file) => {
                                                    onRemoveClick(true);
                                                }}
                                                autoUpload={autoUpload}
                                            />
                                        )}
                                        {!previewImage && isClickSave && (
                                            <span className="error">
                                                Required
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Radio>

                    {/* <Radio value="video">
                        <div className={styles.RadioDescription}>
                            <h3>Live stream or video</h3>
                            <span dangerouslySetInnerHTML={{
                                    __html: videoDescription,
                                }} />

                            {value === 'video' && (
                                <div className={`image-video ${!videoUrl && isSubmitted ? 'error' : ''}`}>
                                    <div>
                                        <h3 style={{display:'flex'}}>{videoLabel || 'Video'} <span className="required">*</span></h3>
                                        <Input
                                            type="text"
                                            onChange={(e) => onVideoLinkChange(e.target.value)}
                                            defaultValue={videoUrl}
                                            required
                                            className={`${videoLinkError ? 'error' : '' }`}
                                        />
                                        {videoHint && <span className="hint">{videoHint}</span>}
                                        {!videoUrl && isSubmitted && <span className="error">Required</span>}
                                        {videoLinkError && <span className="error">Invalid URL</span>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Radio> */}
                </Radio.Group>
            </div>
            <style jsx="true">{`
                .card-display .ant-radio-group {
                    border: 0px !important;
                    border-radius: 4px;
                    width: 100%;
                }
                .card-display .ant-radio {
                    position: absolute;
                    top: 10px;
                    left: 0px;
                }
                .card-display .uploader {
                    margin: 0 !important;
                }
                .image-video {
                    margin-top: 15px;
                    margin-left: -25px;
                    margin-bottom: 15px;
                    width: 231px;
                }
                .card-display .ant-radio-wrapper {
                    display: flex !important;
                    align-items: center;
                    border-bottom: 1px solid #e3e8f7;
                    margin: 0;
                    padding: 10px 0;
                }
                .card-display span.ant-radio + * {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    padding: 0;
                }
                .image-video .hint {
                    display: block;
                    font-size: 80%;
                    font-weight: 400;
                }
                .image-video .error {
                    color: red;
                    display: block;
                    font-size: 0.9em;
                }
                .image-video input[type='text'] {
                    margin-bottom: 0px;
                    width: 86%;
                }
                .image-video .change-file-txt,
                .image-video .remove-file-txt {
                    padding: 0px !important;
                }
                .image-video .remove-file-txt {
                    padding-left: 10px !important;
                }
                .image-video .required {
                    color: red;
                    font-size: 0.9em;
                    margin-left: 5px;
                }
                .cardheading span.required {
                    color: #dc3545 !important;
                }
                .image-video .ant-input.error {
                    border: 1px solid red;
                }
                .image-video.error .uploader,
                .image-video.error input {
                    border: 1px solid red;
                }
            `}</style>
        </div>
    );
};

export default CardDisplay;
