import * as React from 'react';

const PhoneIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={14}
                height={20}
                viewBox="0 0 14 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M7 16h.01H7zm-4 3h8a2 2 0 002-2V3a2 2 0 00-2-2H3a2 2 0 00-2 2v14a2 2 0 002 2z"
                    stroke={props.active ? '#fff' : '#000'}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default PhoneIcon;
