import { gql } from "apollo-boost";

const CREATE_SHOWCASE = gql`
  mutation CreateShowcase($input: createShowcaseInput) {
    createShowcase(input: $input) {
      showcase {
        id
        title
        slug
      }
    }
  }
`;

export default CREATE_SHOWCASE;
