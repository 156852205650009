import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useSponsorMenu from 'hooks/useSponsorMenu';
import { useParams, Link } from 'react-router-dom';

const SponsorDropdownMenu = () => {
    useSponsorMenu();
    const { companyId, projectId } = useParams();
    const { activeMenu } = useSelector((state) => state?.sponsorMenu) || {};

    if (!activeMenu?.sublinks && activeMenu?.link !== 'sponsor-home')
        return (
            <h2 className="tw-block md:tw-hidden tw-font-semibold tw-mb-3 tw-text-xl">
                {activeMenu?.name}
            </h2>
        );

    if (isEmpty(activeMenu) || activeMenu?.activeMenuKey === '') return '';

    const items = activeMenu?.sublinks?.map((link) => ({
        key: link.link,
        label: link.name,
    }));

    const menu = (
        <Menu selectable defaultSelectedKeys={[activeMenu?.activeMenuKey]}>
            {items.map((link) =>
                link.label === activeMenu?.activeMenuKey ? (
                    <Menu.Item disabled key={link.label}>
                        {link.label}
                    </Menu.Item>
                ) : (
                    <Menu.Item key={link.label}>
                        <Link
                            className="tw-flex tw-gap-1 tw-items-center tw-flex-1"
                            to={`/company/${companyId}/project/${projectId}/${link?.key}?sponsor=1`}
                        >
                            <span>{link.label}</span>
                        </Link>
                    </Menu.Item>
                )
            )}
        </Menu>
    );

    return (
        <Dropdown
            trigger={['click']}
            overlay={menu}
            className="tw-block md:tw-hidden"
        >
            <h2 className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-text-xl tw-capitalize tw-font-semibold tw-mb-3">
                {activeMenu?.activeMenuKey}
                <DownOutlined />
            </h2>
        </Dropdown>
    );
};

export default SponsorDropdownMenu;
