import React from 'react';
import { Row, Button, Tag } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Loader from 'components/app/components/venue/Loader';
import Search from 'components/app/components/venue/search';
import { NoPageCreatedIcon } from 'ComponentsV2/ProjectDashboard/Components/Website/Pages/icons';

const VenueCollections = ({
    loading,
    venues,
    onSearch,
    onAddVenue,
    handleChooseVenue,
    venueId,
    handleConfigureThis,
    showPreview,
    enablePreview,
    searchVal
}) => {
    return (
        <div className={`venue-collections tw-h-full`}>
            <div className="d-flex align-items-center justify-content-end mt-2 mb-2">
                <div className="d-flex align-items-center">
                    <Search handleSearch={onSearch} searchValue={searchVal}/>
                    <Button
                        onClick={onAddVenue}
                        className={`myxp btn btn-primary ml-3 ${
                            showPreview && 'mr-5'
                        }`}
                    >
                        {showPreview ? 'Add Experience' : 'Add a Theme'}
                    </Button>
                </div>
            </div>
            <div className={`venue-cols ${loading ? 'loading' : ''}`}>
                {loading ? (
                    <Loader text="Loading theme collections..." />
                ) : venues?.length === 0 ? (
                    <div className="mr-auto ml-auto text-center tw-pt-8 tw-pb-5">
                        <NoPageCreatedIcon />
                        <p className="tw-text-black tw-font-semibold tw-text-xl">
                            No Data Found
                        </p>
                        <p className="tw-w-full tw-max-w-md tw-text-black tw-font-light tw-text-sm tw-pt-3 tw-pb-6 tw-text-center m-auto">
                            Oops! It seems like there are no venues available at
                            the moment. Why not be the first to add a venue?
                            <span className="tw-p-0 tw-m-0 tw-font-semibold">
                            {' '}Click on the Add Experience button
                            </span>
                        </p>
                    </div>
                ) : (
                    venues?.map((item, index) => (
                        <VenueCollectionItem
                            key={index}
                            item={item}
                            handleChooseVenue={handleChooseVenue}
                            venueId={venueId}
                            handleConfigureThis={handleConfigureThis}
                            showPreview={showPreview}
                            enablePreview={enablePreview}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

const VenueCollectionItem = ({
    item,
    handleChooseVenue,
    venueId,
    handleConfigureThis,
    showPreview,
    enablePreview,
}) => {
    return (
        <Row
            key={item.id}
            justify="space-between"
            align="start"
            className="venue-item-collection"
        >
            <div className="venue-details">
                {item?.thumbnail ? (
                    <img
                        width={128}
                        height={84}
                        alt={item?.name}
                        src={item?.thumbnail}
                    />
                ) : (
                    <div className="card-image-placeholder" />
                )}
                <div className="venue-details-section">
                    <h2>{item.name}</h2>
                    <div className="venue-labels">
                        <label>Labels:</label>
                        <div className="venue-labels-tags">
                            {item?.goodFor?.split(', ')?.map((tag, idx) => (
                                <Tag key={idx}>{tag}</Tag>
                            ))}
                        </div>
                    </div>
                    <div className="venue-edits-logs">
                        <div className="venue-edits-logs">
                            {item?.github && !isEmpty(item?.github) ? (
                                <>
                                    <span>
                                        Github Repository:{' '}
                                        <a
                                            href={`https://www.github.com/${item?.github?.repo}/tree/${item?.github?.branch}`}
                                            target="_blank"
                                        >
                                            <b>{item?.github?.repo}</b>
                                        </a>
                                    </span>
                                    <span>
                                        Branch: <b>{item?.github?.branch}</b>
                                    </span>
                                    {item?.github?.commit && (
                                        <span>
                                            Last Commit:{' '}
                                            <b>
                                                {item?.github?.commit.substring(
                                                    0,
                                                    6
                                                )}
                                            </b>
                                        </span>
                                    )}
                                    {/*<span>*/}
                                    {/*    <a*/}
                                    {/*        href="#"*/}
                                    {/*        onClick={(e) => {*/}
                                    {/*            e.preventDefault();*/}
                                    {/*            handleConfigureThis({*/}
                                    {/*                ...item.github,*/}
                                    {/*                name: item?.name,*/}
                                    {/*            });*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        Configure*/}
                                    {/*    </a>*/}
                                    {/*</span>*/}
                                </>
                            ) : (
                                <>
                                    <span>
                                        Uploaded by{' '}
                                        <b>{item?.createdByUser?.firstname}</b>{' '}
                                        on{' '}
                                        <b>
                                            {moment(item.createdAt).format(
                                                'DD MMM YYYY'
                                            )}
                                        </b>{' '}
                                        at{' '}
                                        <b>
                                            {moment(item.createdAt).format(
                                                'HH:mm'
                                            )}
                                        </b>
                                    </span>
                                    <span>
                                        Last updated by{' '}
                                        <b>{item.updatedByUser?.firstname}</b>{' '}
                                        on{' '}
                                        <b>
                                            {moment(item.updatedAt).format(
                                                'DD MMM YYYY'
                                            )}
                                        </b>{' '}
                                        at{' '}
                                        <b>
                                            {moment(item.updatedAt).format(
                                                'HH:mm'
                                            )}
                                        </b>
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="venue-ctas">
                {showPreview && (
                    <Button
                        className="myxp btn btn-preview"
                        onClick={() => enablePreview(item)}
                    >
                        Preview
                    </Button>
                )}
                <Button
                    onClick={() => handleChooseVenue(item)}
                    className="myxp btn btn-preview"
                    disabled={venueId}
                >
                    Choose
                </Button>
            </div>
        </Row>
    );
};

export default VenueCollections;
