import { gql } from "apollo-boost";

const UPDATE_SHOWCASEBLOCK = gql`
  mutation UpdateShowcaseblock($input: updateShowcaseblockInput) {
    updateShowcaseblock(input: $input) {
      showcaseblock {
        id
      }
    }
  }
`;

export default UPDATE_SHOWCASEBLOCK;
