import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Tabs, Button, Tooltip } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { InfoCircleOutlined } from '@ant-design/icons';
import ContentLayout from 'Apps/VenueBuilder/Components/Common/ContentLayout';
import SettingsText from 'Apps/VenueBuilder/Components/Common/SettingsText';
import ProductFileUploader from 'components/app/common/ProductFileUploader';
import ImageUpload from 'components/app/common/imageUpload';
import { UploadFile } from 'apis/rest/products/UploadFile';

import BlockFooter from 'Apps/VenueBuilder/Components/Common/BlockFooter';

import styles from './index.module.css';
import { DRAWER_TYPES } from '../../createProduct/createProduct';

const { TabPane } = Tabs;

const AddItem = ({
    visible,
    setVisible,
    setResource,
    formStyle,
    projectId = null,
}) => {
    const dropdownContainer = useRef(null);
    const { control, handleSubmit, errors, setValue, watch, getValues } =
        useForm();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [ctaIconState, setCtaIcon] = useState(
        `https://files-myxp.gevme.com/misc/default${visible.item}.svg`
    );
    const [uploadedIcon, setUploadedIcon] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { ctaIcon } = watch();

    const handleCancel = () => {
        setVisible({ visible: false });
    };

    const onSubmit = (formData) => {
        try {
            setIsSubmitted(true);
            if (visible.item === DRAWER_TYPES.RESOURCE_LINK) {
                let name = formData?.label;
                let link = formData?.link;
                handleResourceLinkUpload(name, link, ctaIconState);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleResourceLinkUpload = async (name, link, icon) => {
        let input = {
            name,
            link,
            icon,
        };
        let reponse = await UploadFile(projectId, input);
        if (reponse.status) {
            setResource(reponse?.id);
        }
    };

    const handleOutsideClick = (e) => {
        if (e.composedPath().includes(dropdownContainer.current)) return;
        setDropdownVisible(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setUploadedIcon(ctaIcon);
    }, [ctaIcon]);

    const availableIcons = [
        'https://files-myxp.gevme.com/misc/defaultPDF.svg',
        'https://files-myxp.gevme.com/misc/defaultLink.svg',
        'https://files-myxp.gevme.com/misc/defaultImage.svg',
        'https://files-myxp.gevme.com/misc/redPDF.svg',
        'https://files-myxp.gevme.com/misc/redLink.svg',
        'https://files-myxp.gevme.com/misc/redImage.svg',
        'https://files-myxp.gevme.com/misc/purplePDF.svg',
        'https://files-myxp.gevme.com/misc/purpleLink.svg',
        'https://files-myxp.gevme.com/misc/purpleImage.svg',
    ];

    const mapIcons = (icon) => {
        switch (icon) {
            case 'https://files-myxp.gevme.com/misc/defaultpdf.svg':
            case 'https://files-myxp.gevme.com/misc/defaultPDF.svg':
            case 'https://files-myxp.gevme.com/misc/defaultPdf.svg':
                return 'https://files-myxp.gevme.com/misc/defaultPDF.svg';
            case 'https://files-myxp.gevme.com/misc/defaultLink.svg':
            case 'https://files-myxp.gevme.com/misc/defaultlink.svg':
                return 'https://files-myxp.gevme.com/misc/defaultLink.svg';
            case 'https://files-myxp.gevme.com/misc/defaultImage.svg':
            case 'https://files-myxp.gevme.com/misc/defaultimage.svg':
                return 'https://files-myxp.gevme.com/misc/defaultImage.svg';
            case 'https://files-myxp.gevme.com/misc/redpdf.svg':
            case 'https://files-myxp.gevme.com/misc/redPdf.svg':
            case 'https://files-myxp.gevme.com/misc/redPDF.svg':
                return 'https://files-myxp.gevme.com/misc/redPDF.svg';
            case 'https://files-myxp.gevme.com/misc/redLink.svg':
            case 'https://files-myxp.gevme.com/misc/redlink.svg':
                return 'https://files-myxp.gevme.com/misc/redLink.svg';
            case 'https://files-myxp.gevme.com/misc/redImage.svg':
            case 'https://files-myxp.gevme.com/misc/redimage.svg':
                return 'https://files-myxp.gevme.com/misc/redImage.svg';
            case 'https://files-myxp.gevme.com/misc/purplePDF.svg':
            case 'https://files-myxp.gevme.com/misc/purplepdf.svg':
            case 'https://files-myxp.gevme.com/misc/purplePdf.svg':
                return 'https://files-myxp.gevme.com/misc/purplePDF.svg';
            case 'https://files-myxp.gevme.com/misc/purpleLink.svg':
            case 'https://files-myxp.gevme.com/misc/purplelink.svg':
                return 'https://files-myxp.gevme.com/misc/purpleLink.svg';
            case 'https://files-myxp.gevme.com/misc/purpleimage.svg':
            case 'https://files-myxp.gevme.com/misc/purpleImage.svg':
                return 'https://files-myxp.gevme.com/misc/purpleImage.svg';
            default:
                return icon;
        }
    };

    const icons = (
        <div ref={dropdownContainer} className="dropdown-tabs">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Default" key="1">
                    <div className="icon-lists">
                        {availableIcons.map((icon) => {
                            return (
                                <div
                                    onClick={() => {
                                        setCtaIcon(icon);
                                        setDropdownVisible(false);
                                    }}
                                    className="icon"
                                >
                                    <img src={icon} />
                                </div>
                            );
                        })}
                    </div>
                </TabPane>
                <TabPane tab="Upload custom" key="2">
                    <span className="size-recom">
                        Recommended size: 32 x 32
                    </span>
                    <ImageUpload
                        prepareFilesUpload={(files) => {
                            setCtaIcon(files[0]);
                            setUploadedIcon(files[0]);
                        }}
                        handleFileDelete={(file) => {
                            setUploadedIcon('');
                        }}
                        autoUpload={true}
                        withoutRedux={true}
                        projectId={projectId}
                        imageCropRecommendedSize={{ width: 32, height: 32 }}
                        imageCropAspectRatio={1}
                    />

                    {uploadedIcon && (
                        <span
                            onClick={() => {
                                setCtaIcon(uploadedIcon);
                                setDropdownVisible(false);
                            }}
                            className={styles.UseCTAICON}
                        >
                            Use
                        </span>
                    )}
                </TabPane>
            </Tabs>
        </div>
    );

    const renderIcon = () => (
        <>
            <h3 className={styles.ImageH3}>Icon</h3>
            <Dropdown
                onVisibleChange={() => setDropdownVisible(true)}
                visible={dropdownVisible}
                trigger={['click']}
                overlay={icons}
            >
                <div className={styles.CTAIconContainer}>
                    <div className={styles.IconSelected}>
                        <img src={mapIcons(ctaIconState)} />
                    </div>
                    <Button>Change</Button>
                </div>
            </Dropdown>
        </>
    );

    return (
        <div className={styles.ImageContentContainer}>
            <form onSubmit={(e) => e.preventDefault()} style={formStyle}>
                <ContentLayout
                    title={`${'Add'} ${visible.item}`}
                    onBack={() => {
                        setVisible({ visible: false });
                        handleSubmit(onSubmit);
                    }}
                    enableSwap={false}
                >
                    <Controller
                        as={
                            <SettingsText
                                headingTitle="Label"
                                tooltipText={`${visible.item} label`}
                                isVisible={true}
                                // setVisibility={setLabelVisibility}
                                isToggleble={false}
                                setValue={setValue}
                                isRequired={true}
                                error={errors?.label}
                            />
                        }
                        name="label"
                        control={control}
                        defaultValue={visible?.data?.label || ''}
                        rules={{ required: true }}
                    />

                    {visible?.item !== DRAWER_TYPES.RESOURCE_LINK ? (
                        <>
                            <h3 className={styles.ImageH3}>
                                {visible?.item === DRAWER_TYPES.RESOURCE_PDF
                                    ? 'PDF File'
                                    : 'Image'}
                                <span className="required">*</span>
                                <Tooltip
                                    placement="right"
                                    title={`${
                                        visible?.item ===
                                        DRAWER_TYPES.RESOURCE_PDF
                                            ? 'PDF'
                                            : 'Image'
                                    } max file size: 25mb`}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </h3>

                            <ProductFileUploader
                                setResourceId={(id) => {
                                    setResource(id);
                                }}
                                // onRemoveClick={() => setPreviewFile('')}
                                fileType={
                                    visible?.item === DRAWER_TYPES.RESOURCE_PDF
                                        ? 'pdf'
                                        : visible?.item
                                }
                                projectId={projectId}
                                label={getValues()?.label || ''}
                                ctaIcon={ctaIconState}
                                isSubmitted={isSubmitted}
                            />
                            {renderIcon()}
                        </>
                    ) : (
                        <>
                            <Controller
                                as={
                                    <SettingsText
                                        headingTitle="Link"
                                        tooltipText={`${visible.item} URL`}
                                        isVisible={true}
                                        placeholder="Paste a link"
                                        setValue={setValue}
                                        error={errors?.link}
                                        isRequired={true}
                                        errorType="message"
                                    />
                                }
                                name="link"
                                control={control}
                                // defaultValue={visible?.data?.link || ''}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                                        message: 'URL invalid',
                                    },
                                }}
                            />

                            {errors?.link?.type === 'required' && (
                                <span className={styles.ErrorTxt}>
                                    Required
                                </span>
                            )}

                            {renderIcon()}
                        </>
                    )}
                </ContentLayout>

                <BlockFooter
                    onCancel={handleCancel}
                    disabledSave={false}
                    onSave={handleSubmit(onSubmit)}
                />
            </form>
            <style jsx={true}>
                {`
                    .dropdown-tabs {
                        background: #fff;
                        border: 1px solid #e2e4e7;
                        box-shadow: 0px 8px 20px rgb(0 0 0 / 16%);
                        border-radius: 4px;
                        padding: 0px 16px 16px;
                        width: 268px;
                    }
                    .dropdown-tabs .icon-lists {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                    }
                    .dropdown-tabs .icon-lists .icon {
                        width: 32px;
                        height: 32px;
                        margin-right: 7px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    .dropdown-tabs .uploader {
                        margin: 0 !important;
                    }
                    span.required {
                        color: #dc3545 !important;
                        font-size: 0.8em;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    span.required.txt {
                        color: #dc3545 !important;
                        font-size: 0.8em;
                        padding-left: 16px;
                    }
                    .dropdown-tabs .error.uploader {
                        border: 1px solid #dc3545 !important;
                    }
                    .dropdown-tabs .ant-tabs-tab:hover {
                        color: #000;
                    }
                    .dropdown-tabs .ant-tabs-ink-bar {
                        background: #000;
                    }
                    .dropdown-tabs .change-file-txt {
                        padding: 0px;
                    }
                    .dropdown-tabs .remove-file-txt {
                        padding: 0px;
                        padding-left: 10px;
                    }
                    .size-recom {
                        font-size: 12px;
                        margin-top: -5px;
                        display: block;
                    }
                `}
            </style>
        </div>
    );
};

export default AddItem;
