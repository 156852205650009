import * as React from "react"

const MeetingIcon = (props) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.75 27h22.5a2.25 2.25 0 002.25-2.25V9a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 9v15.75A2.25 2.25 0 006.75 27zM22.5 31.5h-9"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20a4 4 0 100-8 4 4 0 000 8z"
        stroke="#7A829D"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M11.937 23.5a7.003 7.003 0 0112.126 0"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MeetingIcon
