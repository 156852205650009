const accessControl = {
    exhibitor: {
        id: 3,
        name: 'Exhibitor',
        allowedRoutes: [
            '/company/:companyId/project/:projectId/live/pages/:showcaseId/content',
            '/company/:companyId/project/:projectId/live/pages/:showcaseId/widgets',
            '/company/:companyId/project/:projectId/live/pages/:showcaseId/settings',
            '/previewer',
        ],
        restrictAccess: {
            mainMenu: false,
            subMenu: {
                backToLivePages: false,
            },
            header: {
                title: false,
            },
        },
    },
};

export default accessControl;
