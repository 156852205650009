import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;
let headers = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }

        headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
    } catch (error) {
        console.error(error);
    }
};

/**
 * List of venues under company
 */
export async function getCompanyVenues(projectId, search,page,limit) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${
                process.env.REACT_APP_MYXP_API
            }/manager/livestream/venues?projectId=${projectId}${
                search ? `&search=${search}` : ''
            }${page ?`&page=${page}&limit=${limit}:`:''}`,
            {
                method: 'GET',
                headers,
            }
        );

        return await response.json();
    }
}

/**
 * Get physical venue by projectId
 */
export async function getVenue(projectId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues?projectId=${projectId}`,
            {
                method: 'GET',
                headers,
            }
        );

        return await response.json();
    }
}
export async function getVenueStore(projectId, page = 1, limit = 12) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venue-store?projectId=${projectId}&page=${page}&limit=${limit}`,
            {
                method: 'GET',
                headers,
            }
        );

        return await response.json();
    }
}

/**
 * Create physical venue
 */
export async function createPhysicalVenue(projectId, venueId, data) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${venueId}?projectId=${projectId}`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
}

/**
 * Update physical venue
 */
export async function updatePhysicalVenue(projectId, venueId, data) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${venueId}?projectId=${projectId}`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
}

/**
 * Delete physical venue
 */
export async function deleteLiveStreamPhysicalVenue(projectId, venueId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${venueId}?projectId=${projectId}`,
            {
                method: 'DELETE',
                headers,
            }
        );

        return await response.json();
    }
}

/**
 * Get physical venue detail
 */
export async function getPhysicalVenueDetail(projectId, venueId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venue-store/${venueId}?projectId=${projectId}`,
            {
                method: 'GET',
                headers,
            }
        );

        return await response.json();
    }
}

/**
 * Associate venue with company
 */
export async function setCompanyVenue(projectId, data) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venues/associate?projectId=${projectId}`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
}

/**
 * Associate venue with company new
 */
export async function setChooseVenue(projectId, data) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/projects/${projectId}/associate`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
}

// Delete a venue from venue collection
export async function deleteVenueCollection(projectId, venueId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venues/${venueId}?projectId=${projectId}`,
            {
                method: 'DELETE',
                headers,
            }
        );

        return await response.json();
    }
}

// Copy a venue from venue store to venue collection
export async function copyVenueFromStore(projectId, venueId) {
    setAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venue-store/${venueId}/clone?projectId=${projectId}`,
            {
                method: 'POST',
                headers,
            }
        );

        return await response.json();
    }
}

// Upload venue using .zip file
export async function uploadZipVenue(projectId, body) {
    setAuthBearer();

    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/venues?projectId=${projectId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body,
            }
        );

        return await response.json();
    }
}
