import { produce } from 'immer';

export const initialState = {
    mainMenuOpen: true,
    subMenuOpen: true,
    mainMenuVisible: false,
    subMenuVisible: false,
    mainMenuData: {
        mainLinks: [
            { name: 'People', link: 'people' },
            { name: 'LivePages', link: 'live/pages' },
            { name: 'Sponsorship', link: 'sponsorship/packages' },
            {
                name: 'Company Profile',
                link: 'company-profile',
                key: 'company-profile',
            },
            { name: 'Analytics', link: 'analytics' },
            { name: 'LiveManager', link: 'live-manager' },
            { name: 'Projects', link: '/' },
        ],
        allPeopleSub: ['Fields', 'Data Integration'],
        allProjectSub: ['Content Security'],
        allSponsorshipSub: ['Packages', 'Sponsors'],
        allNotificationSub: ['Announcements', 'Polls'],
        allLiveSub: ['LivePages', 'LiveSite', 'Chat Moderation'],
        allChannelsSub: [
            {
                name: 'Live Stream',
                link: '',
                key: 'livestream',
            },
            {
                name: 'Q&A',
                link: 'qna',
                key: 'qna',
            },
        ],
    },
    subMenuData: {
        mainLinks: [],
        allLinks: [
            { name: 'Settings', link: 'settings' },
            { name: 'Live Widgets', link: 'widgets' },
            { name: 'Content', link: 'content' },
            { name: 'Agenda', link: 'agenda', key: 'agenda' },
            { name: 'Polls', link: 'polls', key: 'polls' },
        ],
    },
    activeLivepageName: '',
};

function menuReducer(state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_MAINMENU':
            return produce(state, (draft) => {
                draft.mainMenuOpen = action.payload;
            });
        case 'TOGGLE_SUBMENU':
            return produce(state, (draft) => {
                draft.subMenuOpen = action.payload;
            });
        case 'UPDATE_MAIN_MENU_VISIBILITY':
            return produce(state, (draft) => {
                draft.mainMenuVisible = action.payload;
            });
        case 'UPDATE_SUB_MENU_VISIBILITY':
            return produce(state, (draft) => {
                draft.subMenuVisible = action.payload;
            });
        case 'UPDATE_LIVEPAGE_NAME':
            return produce(state, (draft) => {
                draft.activeLivepageName = action.payload;
            });
        case 'ENABLE_SWITCH_EVENT':
            return {
                ...state,
                mainMenuData: {
                    ...state.mainMenuData,
                    mainLinks: [
                        { name: 'LivePages', link: 'live/pages' },
                        { name: 'Company Profile', link: 'company-profile' },
                        { name: 'Analytics', link: 'analytics' },
                        { name: 'LiveManager', link: 'live-manager' },
                    ],
                },
                subMenuData: {
                    ...state.subMenuData,
                    allLinks: [
                        { name: 'Settings', link: 'settings', key: 'setting' },
                        {
                            name: 'Live Widgets',
                            link: 'widgets',
                            key: 'liveWidget',
                        },
                        { name: 'Content', link: 'content', key: 'content' },
                        { name: 'Agenda', link: 'agenda', key: 'agenda' },
                        { name: 'Polls', link: 'polls', key: 'polls' },
                    ],
                },
            };
        case 'ENABLE_VENUE':
            return {
                ...state,
                mainMenuData: {
                    ...state.mainMenuData,
                    mainLinks: [
                        { name: 'People', link: 'people' },
                        { name: 'Registration', link: 'registration' },
                        { name: 'Virtual', link: 'virtual' },
                        { name: 'Company Profile', link: 'company-profile' },
                        { name: 'Agenda', link: 'agenda' },
                        { name: 'LivePages', link: 'live/pages' },
                        { name: 'Sponsorship', link: 'sponsorship/packages' },
                        { name: 'LiveManager', link: 'live-manager' },
                        { name: 'Channels', link: 'channels' },
                        { name: 'Videos', link: 'videos' },
                        { name: 'Analytics', link: 'analytics' },
                        {
                            name: 'Push Engagement',
                            link: 'notification/announcements',
                        },
                        { name: 'Meetings', link: 'meetings' },
                        { name: 'Products', link: 'products' },
                        { name: 'Chat Moderation', link: 'chat-moderation' },
                        { name: 'Recommendation', link: 'recommendation' },
                        { name: 'Videos', link: 'videos' },
                        { name: 'Tags', link: 'tags' },
                        { name: 'Apps', link: 'apps' },
                        { name: 'Badges', link: 'badges' },
                    ],
                    allSponsorshipSub: ['Packages', 'Sponsors'],
                    allNotificationSub: ['Announcements', 'Polls'],
                    allVirtualSub: [
                        { name: 'Venue', link: 'virtual', key: 'venue' },
                        {
                            name: 'LivePages',
                            link: 'live/pages',
                            key: 'livepages',
                        },
                        { name: 'Menus', link: 'virtual/menus', key: 'menus' },
                        // {
                        //     name: 'Navigation',
                        //     link: 'virtual/navigation',
                        //     key: 'navigation',
                        // },
                        {
                            name: 'Authentication',
                            link: 'virtual/authentication',
                            key: 'authentication',
                        },
                        // { name: 'Settings', link: 'settings', key: 'settings'},
                    ],
                    allChannelsSub: [
                        {
                            name: 'Live Stream',
                            link: '',
                            key: 'livestream',
                        },
                        {
                            name: 'Q&A',
                            link: 'qna',
                            key: 'qna',
                        },
                    ],
                },
            };
        case 'ENABLE_SPONSOR_VENUE':
            return {
                ...state,
                mainMenuData: {
                    ...state.mainMenuData,
                    mainLinks: [
                        { name: 'Virtual', link: 'virtual' },
                        { name: 'Company Profile', link: 'company-profile' },
                        { name: 'Agenda', link: 'agenda' },
                        { name: 'LivePages', link: 'live/pages' },
                        { name: 'Analytics', link: 'analytics' },
                        {
                            name: 'Push Engagement',
                            link: 'notification/announcements',
                        },
                        { name: 'Products', link: 'products' },
                        { name: 'Channels', link: 'channels' },
                    ],
                    allNotificationSub: ['Announcements', 'Polls'],
                    allVirtualSub: [
                        { name: 'Venue', link: 'virtual', key: 'venue' },
                        {
                            name: 'LivePages',
                            link: 'live/pages',
                            key: 'livepages',
                        },
                    ],
                },
            };
        case 'ENABLE_SPONSOR':
            return {
                ...state,
                mainMenuData: {
                    ...state.mainMenuData,
                    mainLinks: [
                        { name: 'LivePages', link: 'live/pages' },
                        { name: 'Company Profile', link: 'company-profile' },
                        { name: 'Agenda', link: 'agenda' },
                        { name: 'Analytics', link: 'analytics' },
                        {
                            name: 'Push Engagement',
                            link: 'notification/announcements',
                        },
                        { name: 'Products', link: 'products' },
                    ],
                    allNotificationSub: ['Announcements', 'Polls'],
                    allVirtualSub: [
                        { name: 'Venue', link: 'virtual', key: 'venue' },
                        {
                            name: 'LivePages',
                            link: 'live/pages',
                            key: 'livepages',
                        },
                    ],
                },
            };

        case 'DISABLED_SWITCH_EVENT':
            return {
                ...state,
                mainMenuData: {
                    ...state.mainMenuData,
                    mainLinks: initialState.mainMenuData.mainLinks,
                },
                subMenuData: {
                    ...state.subMenuData,
                    allLinks: initialState.subMenuData.allLinks,
                },
            };
        case 'DISABLED_VENUE':
            return {
                ...state,
                mainMenuData: {
                    ...initialState.mainMenuData,
                },
                subMenuData: {
                    ...state.subMenuData,
                    allLinks: initialState.subMenuData.allLinks,
                },
            };
        default:
            return state;
    }
}

export default menuReducer;
