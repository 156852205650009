import * as ls from 'local-storage';
import { store } from 'redux/store';
import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';

// export const apiInterceptor = async (url, options) => {
//     try {
//         const response = await fetch(url, options);
//         switch (response.status) {
//             case 403:
//                 if (response.message === 'ACCESS_TOKEN_EXPIRED') {
//                     handleLogout();
//                 } else window.location.href = '/?err=no_access';
//             default:
//                 return response;
//         }
//     } catch (error) {
//         console.error(error);
//     }
// };

async function validateResponse(res) {
    switch (res.status) {
        case 401: {
            await handleLogout(false);
            break;
        }
        case 403: {
            window.location.href = '/?err=no_access';
            break;
        }
        default:
            break;
    }
    return res;
}

async function fetcher(request, opt, signal, includeCredentials = true) {
    try {
        const options = { ...opt };
        if (includeCredentials) {
            options.credentials = 'include';
        }
        const res = await fetch(request, { ...options, signal });
        if (!options?.ignoreResponseValidation) {
            await validateResponse(res);
        }
        return res;
    } catch (error) {
        console.error(error);
        return error;
    }
}

function addRequiredParams(url) {
    try {
        const user = store?.getState()?.user;

        const urlObj = new URL(url);
        const searchParams = urlObj?.searchParams;
        if (ls.get('sponsorAuth')) {
            const hostUrlObj = new URL(window.location.href);
            const companyId = hostUrlObj.pathname.includes('company/')
                ? hostUrlObj.pathname.split('/')[2]
                : '';
            const exhibitorCompanyProfileId =
                companyId ||
                searchParams.get('exhibitorCompanyProfileId') ||
                user?.exhibitorCompanyProfileId;
            if (exhibitorCompanyProfileId) {
                searchParams.set(
                    'exhibitorCompanyProfileId',
                    exhibitorCompanyProfileId
                );
            }
        }

        return urlObj.toString();
    } catch (error) {
        return '';
    }
}

export async function apiInterceptor(url, options, includeCredentials = true) {
    url = addRequiredParams(url);
    const controller = new AbortController();
    const { signal } = controller;
    return {
        response: await fetcher(url, options, signal, includeCredentials),
        abort: () => controller.abort(),
    };
}
