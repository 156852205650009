import React from 'react';

function BackIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                clipPath="url(#clip0_21262_3586)"
            >
                <path d="M13.5 3.75h6v16.5h-6M13.5 12H3M6.75 8.25L3 12l3.75 3.75" />
            </g>
            <defs>
                <clipPath id="clip0_21262_3586">
                    <path
                        fill="#fff"
                        d="M0 0H24V24H0z"
                        transform="matrix(-1 0 0 1 24 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BackIcon;
