import { gql } from "apollo-boost";

const CREATE_SHOWCASEBLOCK = gql`
  mutation CreateShowcaseblock($input: createShowcaseblockInput) {
    createShowcaseblock(input: $input) {
      showcaseblock {
        id
      }
    }
  }
`;

export default CREATE_SHOWCASEBLOCK;
