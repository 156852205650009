import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

const speakerEndPoints = {
    getSpeakersList: async (projectId, page, search,pagelimit=10) => {
        setAuthBearer();
        const { response } = await apiInterceptor(
            `${
                process.env.REACT_APP_MYXP_API
            }/manager/livestream/speakers?projectId=${projectId}&page=${page}&limit=${pagelimit}${
                search ? `&search=${search}` : ''
            }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    },
    getFilteredSpeakersList: async (projectId, speakers) => {
        setAuthBearer();

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/filtered?projectId=${projectId}&speakers=${speakers}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    },
    createSpeaker: async (projectId, speakerData) => {
        setAuthBearer();

        speakerData.append('projectId', projectId);

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: speakerData,
            }
        );
        return await response.json();
    },
    updateSpeaker: async (projectId, speakerData, speakerId) => {
        setAuthBearer();
        speakerData.append('projectId', projectId);

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/${speakerId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: speakerData,
            }
        );
        return await response.json();
    },
    getSpeakerDetails: async (projectId, speakerId) => {
        setAuthBearer();

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/${speakerId}?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    },
    deleteSpeaker: async (projectId, speakerId) => {
        setAuthBearer();

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/speakers/${speakerId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: authBearer,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId: projectId }),
            }
        );
        return await response.json();
    },
};

export default speakerEndPoints;
