import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';

import { Select } from 'antd';

import { debounce } from 'lodash';
import * as ls from 'local-storage';

import DateTimeRangePicker from 'components/app/common/dateTimeRangePicker';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { getAnalyticsToken } from '../api';

export const LivePageSelector = ({
    setLoading,
    projectId,
    setError,
    isLoading,
    isVisiblePages = true,
}) => {
    const livePageSearchOffset = 0;
    const livePageTotal = 20;

    const dispatch = useDispatch();
    const {
        livePageId,
        livePages,
        livePageSearchText,
        isFetching,
        selLivepageData,
    } = useSelector((state) => state?.venueAnalytics);

    const fetchLivePages = async () => {
        try {
            dispatch({ type: 'SET_FETCHING', payload: { isFetching: true } });
            const { status = false, showcases } = await getLivePages(
                projectId,
                'title',
                'asc',
                livePageTotal,
                livePageSearchOffset,
                livePageSearchText,
                [],
                '',
                1,
                ''
            );
            if (status) {
                if (
                    selLivepageData &&
                    !showcases.find((ele) => ele.id === selLivepageData.id)
                )
                    showcases.push(selLivepageData);
                dispatch({
                    type: 'SET_LIVEPAGES',
                    payload: { livePages: showcases },
                });

                dispatch({
                    type: 'SET_FETCHING',
                    payload: { isFetching: false },
                });

                isLoading && setLoading(false);
            } else throw new Error('No Livepages found');
        } catch (error) {
            console.error(error);
            setError(error?.message);

            dispatch({ type: 'SET_FETCHING', payload: { isFetching: false } });
        }
    };

    const debouncedLivePageSearch = debounce(async () => {
        await fetchLivePages();
    }, 500);

    useEffect(() => {
        if (livePages.length !== 0 && isLoading) setLoading(false);
    }, [livePages]);

    useEffect(() => {
        if (livePageSearchText) debouncedLivePageSearch();
        else if (!(livePageId === 'all' && livePages.length > 0))
            fetchLivePages();
    }, [livePageSearchText]);

    return (
        <div className="tw-flex tw-w-full tw-relative tw-flex-col md:tw-flex-row tw-items-center tw-gap-2 tw-bg-white tw-p-0 md:tw-p-4">
            <div className="tw-flex tw-flex-1 tw-w-full">
                {isVisiblePages && (
                    <div
                        className="tw-flex tw-flex-row tw-gap-2 tw-w-full tw-mb-4 md:tw-mb-0 "
                        id="visitsLivePageDropWrapper"
                    >
                        <Select
                            id="visitsLivePageDrop"
                            placeholder="Select a livepage"
                            size="small"
                            showSearch
                            optionFilterProp="data-search"
                            value={livePageId}
                            searchValue={livePageSearchText}
                            onChange={(val) => {
                                const payload = { livePageId: val };
                                let selLivePage = null;
                                if (val !== 'all')
                                    selLivePage = livePages.find(
                                        (ele) => ele.id === val
                                    );

                                if (selLivePage)
                                    payload.selLivepageData = selLivePage;
                                dispatch({
                                    type: 'SET_LIVEPAGEID',
                                    payload,
                                });
                            }}
                            onSearch={(srchVal) => {
                                dispatch({
                                    type: 'SET_LIVEPAGE_SEARCH',
                                    payload: { livePageSearchText: srchVal },
                                });
                            }}
                            autoClearSearchValue={false}
                            virtual={false}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            loading={isFetching}
                            dropdownRender={(menu) => menu}
                            className="livepage-search tw-font-openSans tw-font-bold tw-w-full md:tw-w-[290px]"
                            style={{ width: '100%' }}
                        >
                            <Select.OptGroup label="All Pages">
                                <Select.Option
                                    key="all"
                                    value="all"
                                    data-search="All Pages"
                                >
                                    All Pages
                                </Select.Option>
                            </Select.OptGroup>
                            <Select.OptGroup label="Select Pages">
                                {livePages?.map((livePage) => {
                                    return (
                                        <Select.Option
                                            key={livePage.id}
                                            value={livePage.id}
                                            data-search={
                                                livePage.title + livePage.slug
                                            }
                                        >
                                            {livePage.title}
                                        </Select.Option>
                                    );
                                })}
                            </Select.OptGroup>
                        </Select>
                    </div>
                )}
            </div>
            <DateTimeRangePicker showTime />
            <TimezoneSelector
                wrapperClass="tw-relative tw-w-full md:tw-w-[290px]"
                selectClassnames="tw-w-full"
            />
            <style jsx>{`
                #visitsLivePageDropWrapper div[class='ant-select-selector'] {
                    height: 32px;
                    display: flex;
                    align-items: center;
                }
            `}</style>
        </div>
    );
};

export const handleAnalyticsToken = async () => {
    try {
        const { status, accessToken, message } = await getAnalyticsToken();
        if (status) {
            if (window?.SPONSOR_AUTH?.companyId) {
                const analyticsAuthValue = ls.get('analyticsAuth') || {};

                ls.set('analyticsAuth', {
                    ...analyticsAuthValue,
                    [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                });
            } else {
                ls.set('analyticsAuth', { accessToken });
            }
            window.location.reload();
        } else throw new Error(message);
    } catch (error) {
        console.error(error);
    }
};
