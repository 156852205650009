import * as React from "react"

const MarginCopyIcon = (props) => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5V3.5M10 13.5V12M4.5 6.5H3M13 10.5h-1.5M4.44 8.525l-.707.707a2.5 2.5 0 003.535 3.536l.707-.707M11.56 8.475l.708-.707a2.5 2.5 0 00-3.536-3.536l-.707.707"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MarginCopyIcon
