import { useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideAlerter(ref, payload) {
  const { elementVisibility, setElementVisibility } = payload;
  /**
   * alert if clicked on outside of element
   * @param {object} e event object
   */
  function handleClickOutside(e) {
    if (elementVisibility) {
      if (ref.current && !ref.current.contains(e.target)) {
        setElementVisibility(false);
      }
    }
  }

  useEffect(() => {
    // bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

useOutsideAlerter.propTypes = {
  ref: PropTypes.object.isRequired,
  payload: PropTypes.object.isRequired
};

export default useOutsideAlerter;
