import { gql } from "apollo-boost";

const UPDATE_SHOWCASE = gql`
  mutation UpdateShowcase($input: updateShowcaseInput) {
    updateShowcase(input: $input) {
      showcase {
        id
      }
    }
  }
`;

export default UPDATE_SHOWCASE;
