import React, { useState, useRef, useEffect } from 'react';

import { Tooltip, Input, Menu, Dropdown, Button } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import CodeIcon from 'Apps/VenueBuilder/Icons/CodeIcon';
import StreamURLIcon from 'Apps/VenueBuilder/Icons/StreamURLIcon';
import YouTubeIcon from 'Apps/VenueBuilder/Icons/YouTubeIcon';
import VimeoIcon from 'Apps/VenueBuilder/Icons/VimeoIcon';
import FacebookIcon from 'Apps/VenueBuilder/Icons/FacebookIcon';
import MSStreamIcon from 'Apps/VenueBuilder/Icons/MSStreamIcon';
import GEVMELiveIcon from 'Apps/VenueBuilder/Icons/GEVMELiveIcon';
import { VideoUploadIcon, UploadNewFileIcon } from './icons';
import * as ls from 'local-storage';
import { isEmpty } from 'lodash';

import styles from './index.module.css';

const StreamingService = ({
    headingTitle,
    selectedItem,
    streamType,
    type,
    error,
    required = true,
    customStyle = {},
    isVideoService = false,
}) => {
    const dropdownContainer = useRef(null);

    const [visible, setVisible] = useState(false);
    const [selectedType, setSelectedType] = useState(false);
    const [done, setDone] = useState(false);

    const onDone = (currentSelectedType) => {
        setVisible(false);
        setDone(true);
        type(currentSelectedType);
    };

    const handleOutsideClick = (e) => {
        if (e.composedPath().includes(dropdownContainer.current)) return;
        setVisible(false);
    };

    // on click outside of filter
    useEffect(() => {
        // For Filter box, add when mounted
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (streamType) {
            setDone(true);
            setSelectedType(streamType);
        }
    }, [streamType]);

    const renderIcon = (type) => {
        switch (type.toUpperCase()) {
            case 'CUSTOMSTREAM':
            case 'CUSTOM':
                return isVideoService ? <StreamURLIcon /> : <CodeIcon />;
            case 'YOUTUBE':
                return <YouTubeIcon />;
            case 'VIMEO':
                return <VimeoIcon />;
            case 'FACEBOOK':
                return <FacebookIcon />;
            case 'MSTEAMS':
                return <MSStreamIcon />;
            case 'GEVMELIVE':
                return <GEVMELiveIcon />;
            case 'UPLOAD':
                return <UploadNewFileIcon />;
            case 'MYVIDEO':
            case 'MYRECORDINGS':
                return <VideoUploadIcon />;
            default:
                return '';
        }
    };

    const renderName = (type) => {
        switch (type.toUpperCase()) {
            case 'CUSTOMSTREAM':
                return 'Custom Streaming URL';
            case 'CUSTOM':
                return isVideoService ? 'Direct URL' : 'Custom Embed Code';
            case 'YOUTUBE':
                return 'YouTube';
            case 'VIMEO':
                return 'Vimeo';
            case 'FACEBOOK':
                return 'Facebook';
            case 'MSTEAMS':
                return 'Microsoft Stream';
            case 'GEVMELIVE':
                return 'GEVME Live';
            case 'UPLOAD':
                return 'Upload new file';
            case 'MYVIDEO':
            case 'MYRECORDINGS':
                return 'My video uploads';
            default:
                return 'Choose a streaming service';
        }
    };

    const liveStreamServices = [
        { name: 'GEVME Live', icon: 'gevmelive' },
        { name: 'Youtube', icon: 'youtube' },
        { name: 'Vimeo', icon: 'vimeo' },
        { name: 'Facebook', icon: 'facebook' },
        { name: 'Microsoft Stream', icon: 'msteams' },
        { name: 'Custom Streaming URL', icon: 'customstream' },
        { name: 'No Streaming Service', icon: '' },
        // { name: 'Custom Embed Code', icon: 'custom' }
    ];

    const videoServices = [
        { name: 'My video uploads', icon: 'myVideo' },
        { name: 'Upload new file', icon: 'upload' },
        { name: 'Youtube', icon: 'youtube' },
        { name: 'Vimeo', icon: 'vimeo' },
        { name: 'Facebook', icon: 'facebook' },
        { name: 'Direct URL', icon: 'custom' },
        // { name: 'My meeting recordings', icon: 'myRecordings' },
        // { name: 'Custom Embed Code', icon: 'custom' }
    ];

    const services = isVideoService ? videoServices : liveStreamServices;

    const menu = (
        <Menu getPopupContainer={(trigger) => trigger.parentNode}>
            <div ref={dropdownContainer} className={styles.MenuWrapper}>
                <div className={styles.AppItemWrapper}>
                    {services?.map((item) => {
                        return (
                            <div
                                onClick={() => {
                                    setSelectedType(item.icon);
                                    onDone(item.icon);
                                }}
                                role="button"
                                type="submit"
                                className={`${styles.AppItem} app-item ${
                                    selectedType === item.icon
                                        ? `${styles.AppItemActive} selected`
                                        : ''
                                }`}
                            >
                                {renderIcon(item.icon)}
                                <h4>{item.name}</h4>
                            </div>
                        );
                    })}
                </div>

                {/* <div className={styles.BtnFooter}>
                    <button
                        onClick={() => setVisible(false)}
                        className={styles.ButtonCancel}>Cancel</button>

                    <button
                        type="submit"
                        onClick={() => onDone(onDone)}
                        className={styles.ButtonSave}>Done</button>
                </div> */}
            </div>
        </Menu>
    );
    return (
        <div
            className={`${styles.StreamingServiceContainer} streaming-service ${
                error ? 'error' : ''
            }`}
            style={customStyle}
        >
            <div className={styles.Heading}>
                <h3>
                    {headingTitle}
                    {required && <span>*</span>}
                </h3>
            </div>
            {/* {["vimeo", "youtube", "facebook", "msteams"].includes(streamType)
                ? <span className={styles.SelectedAppContainer}>{renderIcon(streamType)} {renderName(streamType)}</span>
                :  */}
            <Dropdown
                // disabled={!isEmpty(ls.get('sponsorAuth'))}
                className={styles.CustomDropdown}
                onVisibleChange={() => setVisible(true)}
                trigger={['click']}
                visible={visible}
                overlay={menu}
                getPopupContainer={(trigger) => trigger.parentNode}
                // disabled={["vimeo", "youtube", "facebook", "msteams"].includes(streamType)}
            >
                <Button>
                    {!done ? (
                        <span>Choose a streaming service</span>
                    ) : (
                        <span className={`${styles.SelectedApp} selected-app`}>
                            {renderIcon(streamType)} {renderName(streamType)}
                        </span>
                    )}
                    <DownOutlined />
                </Button>
            </Dropdown>
            {/* } */}

            <style jsx="true">{`
                .streaming-service .ant-btn[disabled] {
                    color: inherit !important;
                    background: inherit !important;
                }
                .streaming-service.error .ant-btn {
                    border: 1px solid red;
                }
                .app-item .icon-wrapper {
                    width: 33px;
                    justify-content: center;
                }
                .app-item:hover {
                    cursor: pointer;
                    background-color: #eff3f6;
                }
                .app-item:hover .icon-wrapper {
                    background-color: #eff3f6 !important;
                }
                .app-item.selected .icon-wrapper {
                    background-color: #eff3f6 !important;
                }
                .selected-app .icon-wrapper {
                    margin-right: 8px;
                }
            `}</style>
        </div>
    );
};

export default StreamingService;
