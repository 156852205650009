import * as React from 'react';

const DraggableIcon = (props) => {
    return (
        <svg
            width={6}
            height={14}
            viewBox="0 0 6 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
                <rect width={2} height={2} rx={1} fill="#878DA1" />
                <rect y={3} width={2} height={2} rx={1} fill="#878DA1" />
                <rect y={6} width={2} height={2} rx={1} fill="#878DA1" />
                <rect y={9} width={2} height={2} rx={1} fill="#878DA1" />
                <rect y={12} width={2} height={2} rx={1} fill="#878DA1" />
                <rect x={4} width={2} height={2} rx={1} fill="#878DA1" />
                <rect x={4} y={3} width={2} height={2} rx={1} fill="#878DA1" />
                <rect x={4} y={6} width={2} height={2} rx={1} fill="#878DA1" />
                <rect x={4} y={9} width={2} height={2} rx={1} fill="#878DA1" />
                <rect x={4} y={12} width={2} height={2} rx={1} fill="#878DA1" />
        </svg>
    );
};

export default DraggableIcon;
