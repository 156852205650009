import * as ls from 'local-storage';
import { produce } from 'immer';

const userSignUp = ls('userSignUp') || {};

export const initialState = {
    id: '',
    companyId: '',
    email: '',
    firstname: '',
    lastname: '',
    restrictAccess: null,
    preferredExperienceId: null,
    preferredExperienceName: null,
    ...userSignUp,
};

function userReducers(state = initialState, action) {
    const user = action?.payload?.user;

    switch (action.type) {
        case 'UPDATE_USER':
            return produce(state, (draft) => ({
                ...draft,
                ...user,
            }));
        case 'SET_USER':
            return produce(state, (draft) => ({
                ...draft,
                ...user,
            }));

        case 'SET_RESTRICT_ACCESS':
            return produce(state, (draft) => {
                draft.restrictAccess = action.payload;
            });

        case 'SET_PREFERRED_EXPERIENCE':
            return {
                ...state,
                preferredExperienceId:
                    action.payload?.preferredExperienceId ||
                    state?.preferredExperienceId,
                preferredExperienceName:
                    action.payload?.preferredExperienceName ||
                    state?.preferredExperienceName,
            };

        default:
            return state;
    }
}

export default userReducers;
