export const initialState = {
    list: [],
    currentProject: {},
    company: {},
};

function updateProject(prev, next) {
    let updatedProject = [next, ...prev];

    if (!prev.length) return updatedProject;

    const isExisting = prev.filter((p) => p.id === next.id).length;
    if (isExisting)
        updatedProject = prev.map((p) => (p.id === next.id ? next : p));

    if (updatedProject.length >= 10) return updatedProject.slice(0, -1);

    return updatedProject;
}

function projectReducer(state = initialState, action) {

    switch (action.type) {
        case 'SET_PROJECT':
            return {
                ...state,
                currentProject: { id: action?.payload?.id, title: action?.payload?.title },
                list: updateProject(state.list, action.payload),
            };
        case 'UPDATE_PROJECT':
            return {
                ...state,
                list: state.list.map((p) =>
                    p.id === action.payload.id ? { ...p, ...action.payload } : p
                ),
            };
        case 'SET_COMPANY_DETAILS':
            return {
                ...state,
                company: action.payload,
            };
        case 'UPDATE_COMPANY_DETAILS':
            return {
                ...state,
                company: { ...action.company, ...action.payload },
            };
        default:
            return state;
    }
}

export default projectReducer;
