import React from 'react';

export default function MusicIcon({ stroke = 'white' }) {
    return (
        <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.875 17.8838C7.875 19.3341 6.405 20.5088 4.59375 20.5088C2.7825 20.5088 1.3125 19.3341 1.3125 17.8838C1.3125 16.4348 2.7825 15.2588 4.59375 15.2588C6.405 15.2588 7.875 16.4348 7.875 17.8838ZM19.6875 15.2588C19.6875 16.7091 18.2175 17.8838 16.4062 17.8838C14.595 17.8838 13.125 16.7091 13.125 15.2588C13.125 13.8085 14.595 12.6338 16.4062 12.6338C18.2175 12.6338 19.6875 13.8085 19.6875 15.2588Z"
                fill={stroke || 'white'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.375 15.2588V3.44629H19.6875V15.2588H18.375ZM7.875 4.75879V17.8838H6.5625V4.75879H7.875Z"
                fill={stroke || 'white'}
            />
            <path
                d="M6.5625 4.63412C6.5625 4.30875 6.68335 3.99497 6.90162 3.75366C7.11989 3.51235 7.42 3.36072 7.74375 3.32819L18.2437 2.27819C18.4263 2.25984 18.6106 2.27996 18.7848 2.33724C18.9591 2.39452 19.1194 2.48769 19.2555 2.61074C19.3915 2.73379 19.5002 2.88398 19.5746 3.05164C19.6491 3.2193 19.6875 3.40069 19.6875 3.58412V4.75881L6.5625 6.07131V4.63412Z"
                fill={stroke || 'white'}
            />
        </svg>
    );
}
