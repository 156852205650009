import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IFrameResizer from 'iframe-resizer-react';
import { useParams, withRouter } from 'react-router-dom';
import PageSpinner from 'components/app/common/pageSpinner';
import DateTimeRangePicker from 'components/app/common/dateTimeRangePicker';
import toggleFullscreen from 'ComponentsV2/ProjectDashboard/Components/analytics/utils/fullscreen';

import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { getMetabaseToken } from './api';
import { Select, Tooltip, Tabs, Switch } from 'antd';
import moment from 'moment';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import './index.scss';

const { TabPane } = Tabs;
const { Option } = Select;
const NIGHT_MODE_TEXT = '#dedfe0';
const NIGHT_MODE_BG = '#2e353b';

const autoRefreshOptions = [
    { value: 1, label: '1 minute' },
    { value: 2, label: '2 minutes' },
    { value: 5, label: '5 minutes' },
    { value: 10, label: '10 minutes' },
    { value: 15, label: '15 minutes' },
    { value: 30, label: '30 minutes' },
    { value: 60, label: '60 minutes' },
];

const Overview = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const siteId = useSelector(
        (state) => state?.venuesettings?.analyticsSiteId
    );

    const { startDate, endDate, activeMenu } = useSelector(
        (state) => state?.venueAnalytics
    );
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [metabaseToken, setMetabaseToken] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(1);
    const [isNightMode, setIsNightMode] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [dashboardId, setDashboradId] = useState(
        process.env.REACT_APP_OVERVIEW_METABASE_DASHBOARD_ID
    );
    const [startTimer, setStartTimer] = useState(false);

    const getMetaToken = async () => {
        const json = await getMetabaseToken(
            projectId,
            siteId,
            startDate,
            endDate,
            timezone,
            dashboardId
        );

        setMetabaseToken(json.token);
    };

    const readyStartTimer = (start = true) => {
        setStartTimer(start);
    };

    const handleChange = (val) => {
        switch (val) {
            case 'visitors': {
                setDashboradId(
                    process.env.REACT_APP_OVERVIEW_METABASE_DASHBOARD_ID
                );
                break;
            }
            case 'hourlyBreakdown': {
                setDashboradId(
                    process.env
                        .REACT_APP_OVERVIEW_HOURLY_BREAKDOWN_METABASE_DASHBOARD_ID
                );
                break;
            }
            case 'topPages': {
                setDashboradId(
                    process.env.REACT_APP_TOP_PAGES_METABASE_DASHBOARD_ID
                );
                break;
            }
        }
    };

    const handleNightMode = () => {
        setIsNightMode(!isNightMode);
    };

    const handleFullscreen = () => {
        toggleFullscreen('_dashboard', setShowFullScreen);
    };

    const updateAutoRefreshStartEndDate = () => {
        const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

        if (moment(currentDate).isAfter(endDate)) {
            let start = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            let end = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            dispatch({
                type: 'SET_DATE',
                payload: { startDate: start, endDate: end },
            });
        }
    };

    useEffect(() => {
        if (projectId && siteId && dashboardId) getMetaToken(dashboardId);
    }, [
        projectId,
        siteId,
        startDate,
        endDate,
        autoRefresh,
        timezone,
        isNightMode,
        dashboardId,
    ]);

    useEffect(() => {
        if (activeMenu && activeMenu !== 'live') readyStartTimer(false);
    }, [activeMenu]);

    return (
        <div className="interactionsAnalysisContainer">
            <div style={{ position: 'relative', marginBottom: 10 }}>
                <div className="visitLiveBox controls">
                    <DateTimeRangePicker showTime showRelativePicker showLive />
                </div>
                <TimezoneSelector top={0} />
                {activeMenu === 'live' && (
                    <Tooltip title="Set the refresh frequency of the dashboard">
                        <div className="autoRefresh d-flex align-items-center">
                            {autoRefresh && autoRefresh !== 'off' && (
                                <CountdownCircleTimer
                                    key={autoRefresh}
                                    style={{ marginLeft: 10 }}
                                    strokeLinecap="butt"
                                    size={25}
                                    isPlaying={startTimer}
                                    duration={autoRefresh * 60}
                                    colors={[
                                        '#004777',
                                        '#F7B801',
                                        '#A30000',
                                        '#A30000',
                                    ]}
                                    colorsTime={[10, 6, 3, 0]}
                                    onComplete={() => {
                                        updateAutoRefreshStartEndDate();
                                        return {
                                            shouldRepeat: true,
                                            delay: 0.2,
                                        };
                                    }}
                                ></CountdownCircleTimer>
                            )}
                            <Select
                                placeholder="Auto-Refresh"
                                defaultValue="1 minute"
                                style={{ width: '150px', marginLeft: '5px' }}
                                onChange={(value) => setAutoRefresh(value)}
                            >
                                {autoRefreshOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Tooltip>
                )}
            </div>
            <div id="_dashboard">
                <Tabs
                    tabBarStyle={{
                        color: '#0000',
                        background: `${
                            isNightMode ? NIGHT_MODE_BG : '#FFFFFF'
                        }`,
                        margin: '0px',
                        padding: '15px 15px 0px',
                        height: '56px',
                    }}
                    defaultActiveKey="1"
                    onChange={handleChange}
                    tabBarExtraContent={
                        <>
                            <div className="night-mode-and-fullscreen">
                                <Tooltip title="Night Mode/Day Mode">
                                    <Switch
                                        size="small"
                                        onChange={handleNightMode}
                                    />
                                </Tooltip>
                                <Tooltip title="Switch to fullscreen mode">
                                    <button
                                        style={{
                                            background: `${
                                                isNightMode
                                                    ? NIGHT_MODE_BG
                                                    : 'white'
                                            }`,
                                            fontSize: '18px',
                                        }}
                                        className="border-0 mx-2 text-primary"
                                        onClick={handleFullscreen}
                                    >
                                        {showFullScreen ? (
                                            <FullscreenExitOutlined />
                                        ) : (
                                            <FullscreenOutlined />
                                        )}
                                    </button>
                                </Tooltip>
                            </div>
                        </>
                    }
                >
                    <TabPane tab="Visitors" key="visitors">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        '#bordered=false&titled=false' +
                                        `${
                                            activeMenu === 'live' &&
                                            autoRefresh &&
                                            autoRefresh !== 'off'
                                                ? `&refresh=${autoRefresh * 60}`
                                                : ''
                                        }` +
                                        `&${isNightMode && 'theme=night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                    onLoad={(e) =>
                                        activeMenu === 'live'
                                            ? readyStartTimer(true)
                                            : null
                                    }
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                    <TabPane tab="Hourly Breakdown" key="hourlyBreakdown">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        '#bordered=false&titled=false' +
                                        `${
                                            activeMenu === 'live' &&
                                            autoRefresh &&
                                            autoRefresh !== 'off'
                                                ? `&refresh=${autoRefresh * 60}`
                                                : ''
                                        }` +
                                        `&${isNightMode && 'theme=night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                    onLoad={(e) =>
                                        activeMenu === 'live'
                                            ? readyStartTimer(true)
                                            : null
                                    }
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                    <TabPane tab="Top Pages" key="topPages">
                        {metabaseToken && (
                            <div className="visitLiveBox">
                                <IFrameResizer
                                    src={
                                        'https://bi.gevme.com' +
                                        '/embed/dashboard/' +
                                        metabaseToken +
                                        '#bordered=false&titled=false' +
                                        `${
                                            activeMenu === 'live' &&
                                            autoRefresh &&
                                            autoRefresh !== 'off'
                                                ? `&refresh=${autoRefresh * 60}`
                                                : ''
                                        }` +
                                        `&${isNightMode && 'theme=night'}`
                                    }
                                    style={{
                                        width: '1px',
                                        minWidth: '100%',
                                        border: 'none',
                                    }}
                                    onLoad={(e) =>
                                        activeMenu === 'live'
                                            ? readyStartTimer(true)
                                            : null
                                    }
                                />
                            </div>
                        )}
                        {!metabaseToken && (
                            <PageSpinner
                                type="Oval"
                                color="#ACBDC9"
                                height={45}
                                width={45}
                                msg="Fetching data..."
                            />
                        )}
                    </TabPane>
                </Tabs>
            </div>
            <style>
                {`
                    .ant-tabs-tab>div {
                        color:${isNightMode ? NIGHT_MODE_TEXT : 'black'};
                    }

                    .ant-tabs-tab {
                        color: ${isNightMode ? NIGHT_MODE_TEXT : 'black'};
                        font-family: 'Open Sans';
                        letter-spacing: -0.2px;
                        font-weight: 400;
                    }
                    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                        color: #2190ff !important;
                    }
                    @media (max-width: ${
                        activeMenu === 'live' ? '1299' : '1112'
                    }px) {
                        .interactionsAnalysisContainer .visitLiveBox {
                            min-height: 104px;
                        }
                        .interactionsAnalysisContainer .visitLiveBox .visitorDate {
                            top: 48px;
                            right: 6px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default withRouter(Overview);
