import * as React from 'react';

const ChatIcon = (props) => {
    return (
        <svg
            width={22}
            height={24}
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.333 0H3.667C1.642 0 0 1.612 0 3.6v13.2c0 1.988 1.642 3.6 3.667 3.6h3.165l3.3 3.252c.23.225.543.35.868.348.291 0 .573-.102.794-.288L15.73 20.4h2.603c2.025 0 3.667-1.612 3.667-3.6V3.6C22 1.612 20.358 0 18.333 0zm1.223 16.8c0 .663-.548 1.2-1.223 1.2h-3.055c-.292 0-.573.102-.795.288l-3.422 2.88-2.86-2.82A1.233 1.233 0 007.333 18H3.667a1.211 1.211 0 01-1.223-1.2V3.6c0-.663.548-1.2 1.223-1.2h14.666c.675 0 1.223.537 1.223 1.2v13.2z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default ChatIcon;
