import * as React from 'react';

import VideoUploadIconSvg from 'Apps/VenueBuilder/Icons/VideoUploadIcon';

const VideoUploadIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
            }}
        >
            <VideoUploadIconSvg {...props} />
        </div>
    );
};

export default VideoUploadIcon;
