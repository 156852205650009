import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';
import { Drawer } from 'antd';

const defaultFooter = ({
    onSave,
    onCancel,
    onSaveAndCreate,
    editMode = false,
}) => {
    return (
        <div className={styles.footerContainer}>
            <div onClick={onCancel} type="btn" className={styles.defBtn}>
                Cancel {editMode ? ' editing' : ''}
            </div>
            {onSaveAndCreate && !editMode && (
                <div
                    onClick={onSaveAndCreate}
                    type="btn"
                    className={styles.primBtn}
                >
                    Save and create another
                </div>
            )}
            <div onClick={onSave} type="btn" className={styles.primBtn}>
                Save
            </div>
        </div>
    );
};
function FormContainer({
    children,
    onCancel,
    onSave,
    onSaveAndCreate,
    title,
    footer,
    visible = true,
    setVisible,
    editMode = false,
}) {
    return (
        <>
            <Drawer
                title={
                    typeof title === 'object' ? (
                        title
                    ) : (
                        <h1 className={styles.formHead}>{title}</h1>
                    )
                }
                placement="right"
                closable={true}
                destroyOnClose={true}
                visible={visible}
                onClose={() => setVisible(false)}
                closeIcon={
                    <img
                        src="/assets/products/xpmanager/iconsv2/closeIcon.svg"
                        alt=""
                    />
                }
                width={600}
                footer={
                    footer
                        ? footer
                        : defaultFooter({
                              onSave,
                              onCancel,
                              onSaveAndCreate,
                              editMode,
                          })
                }
            >
                <div className={styles.formContainer}>{children}</div>
            </Drawer>
            <style>{`
			.ant-picker-input
			{
				flex-direction: row-reverse;
				margin-left:-5px;
			}
			`}</style>
        </>
    );
}

FormContainer.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    visible: PropTypes.bool.isRequired,
    editMode: PropTypes.bool,
    setVisible: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
    footer: PropTypes.element,
};
export default FormContainer;
