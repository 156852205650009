import React from 'react';

import { useSelector } from 'react-redux';

import UserVisits from './userVisits';
import Visits from './visits';

const Main = ({ isAnalyticsAuthSet }) => {
    const enableVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    return (
        <>
            {enableVenue ? (
                <UserVisits {...{ isAnalyticsAuthSet }} />
            ) : (
                <Visits />
            )}
        </>
    );
};

export default Main;
