import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';

import { useParams, useHistory } from 'react-router-dom';
import {
    Modal,
    DatePicker,
    TimePicker,
    List,
    notification,
    Select,
} from 'antd';
import moment from 'moment';

import { CreateNotification } from 'apis/rest/notifications/CreateNotification';
import { PublishNotification } from 'apis/rest/notifications/PublishNotification';
import { UpdateNotification } from 'apis/rest/notifications/UpdateNotification';
import TimezoneSelector from 'components/app/common/timezoneSelector';

import './scheduleModal.scss';
import { decodeHtml } from 'utils/decodeHTML';
import isEmpty from 'lodash/isEmpty';
import sanitizeContent from 'utils/InputSanitization';
import { debounce } from 'lodash';
import NotificationBox from '../notificationBox';

function ScheduleModal({
    visible,
    setVisible,
    notificationSendNow,
    notificatonData,
    onRefreshData,
    notificationEditMode,
    sendDirect = false,
    scheduleDate,
    setScheduleDate,
    sendMode,
    selectedTrigger,
    triggerTime,
    timeUnit,
}) {
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const { projectId, companyId } = useParams();
    const history = useHistory();

    const handleCreateNotification = async () => {
        const {
            title,
            description,
            subTitle,
            image,
            position,
            autoCloseTime,
            targetType,
            targetId,
            ctaVisible,
            buttonLabel,
            buttonURL,
            buttonColor,
            buttonTextColor,
            openInNewWindow,
            senderVisible,
            autoCloseVisible,
            ctaId,
        } = notificatonData;

        const createInputs = {
            ...(targetType === 'project' && {
                targetType: 'project',
            }),
            ...(targetType === 'livepage' && {
                targetType: 'livepage',
                targetId: targetId.map((value) => value.key),
            }),
            data: {
                title,
                description: sanitizeContent({
                    str: description,
                    type: 'description',
                }),
                position,
                type: 'recommendation-session',
                ...(ctaVisible && {
                    cta: [
                        {
                            id: ctaId,
                            label: buttonLabel,
                            url: buttonURL,
                            color: buttonColor,
                            textColor: buttonTextColor,
                            openInNewWindow,
                        },
                    ],
                }),
                ...(senderVisible && {
                    subTitle,
                    image,
                }),
                ...(autoCloseVisible && {
                    autoCloseTime: Number(autoCloseTime),
                }),
            },
        };

        const response = await CreateNotification(createInputs, projectId);
        if (response.status) {
            return response.id;
        }
        if (!response.status) {
            openNotification('error', 'An error!', response.message);
        }
    };

    const handleSendNotification = debounce(async (notificationStatus) => {
        if (notificationStatus === 'sendNow') {
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();

                const response = await PublishNotification(
                    projectId,
                    newNotificationId
                );
                if (response.status) {
                    openNotification(
                        'success',
                        'Successful!',
                        'Notification published!'
                    );
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', 'An error!', response.message);
                }
            }
            //Publish exist notification
            if (notificationEditMode) {
                const updateInputs = {
                    data: {
                        title: notificatonData?.title,
                        description: notificatonData?.description,
                        position: notificatonData?.position,
                        ...(notificatonData?.ctaVisible && {
                            cta: [
                                {
                                    id: notificatonData?.ctaId,
                                    label: notificatonData?.buttonLabel,
                                    url: notificatonData?.buttonURL,
                                    color: notificatonData?.buttonColor,
                                    textColor: notificatonData?.buttonTextColor,
                                    openInNewWindow:
                                        notificatonData?.openInNewWindow,
                                },
                            ],
                        }),
                        ...(!notificatonData?.ctaVisible && {
                            cta: [],
                        }),
                        ...((notificatonData?.senderVisible ||
                            (!notificatonData?.hasOwnProperty(
                                'senderVisible'
                            ) &&
                                (notificatonData?.subTitle ||
                                    notificatonData?.image))) && {
                            subTitle: notificatonData?.subTitle,
                            image: notificatonData?.image,
                        }),
                        ...(notificatonData?.hasOwnProperty('senderVisible') &&
                            !notificatonData?.senderVisible && {
                                subTitle: null,
                                image: null,
                            }),
                        ...(notificatonData?.autoCloseVisible && {
                            autoCloseTime: Number(
                                notificatonData?.autoCloseTime
                            ),
                        }),
                        ...(!notificatonData?.autoCloseVisible && {
                            autoCloseTime: null,
                        }),

                        ...(notificatonData?.targetType === 'project' && {
                            targetType: 'project',
                        }),
                        ...(notificatonData?.targetType === 'livepage' && {
                            targetType: 'livepage',
                            targetId: notificatonData?.targetId.map(
                                (value) => value.key
                            ),
                        }),
                    },
                };

                if (sendDirect) {
                    const response = await PublishNotification(
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (response.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification Published!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                }

                if (!sendDirect) {
                    const response = await UpdateNotification(
                        updateInputs,
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (response.status) {
                        const publishResponse = await PublishNotification(
                            projectId,
                            notificatonData?.id ||
                                notificatonData?.notificationId
                        );
                        if (publishResponse.status) {
                            openNotification(
                                'success',
                                'Successful!',
                                'Notification Published!'
                            );
                            setVisible(false);
                            onRefreshData();
                        }
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                }
            }
        } else if (notificationStatus === 'schedule') {
            const notificationSchedule = scheduleDate.utc().format();

            if (
                moment()
                    .add(9, 'minutes')
                    .diff(moment(notificationSchedule), 'second') > 0
            ) {
                const userResponse = window.confirm(
                    'Scheduled Time should be atleast 10 minutes from now.Click on "OK" to reset the time.'
                );
                if (userResponse)
                    setScheduleDate(moment().tz(timezone).add(10, 'minutes'));
                return;
            }

            const updateInputs = {
                data: {
                    title: notificatonData?.title,
                    description: notificatonData?.description,
                    scheduledTime: notificationSchedule,
                    position: notificatonData?.position,
                    ...(notificatonData?.ctaVisible && {
                        cta: [
                            {
                                id: notificatonData?.ctaId,
                                label: notificatonData?.buttonLabel,
                                url: notificatonData?.buttonURL,
                                color: notificatonData?.buttonColor,
                                textColor: notificatonData?.buttonTextColor,
                                openInNewWindow:
                                    notificatonData?.openInNewWindow,
                            },
                        ],
                    }),
                    ...(!notificatonData?.ctaVisible && {
                        cta: [],
                    }),
                    ...(!isEmpty(notificatonData?.cta) && {
                        cta: notificatonData?.cta,
                    }),
                    ...((notificatonData?.senderVisible ||
                        (!notificatonData?.hasOwnProperty('senderVisible') &&
                            (notificatonData?.subTitle ||
                                notificatonData?.image))) && {
                        subTitle: notificatonData?.subTitle,
                        image: notificatonData?.image,
                    }),
                    ...(notificatonData?.hasOwnProperty('senderVisible') &&
                        !notificatonData?.senderVisible && {
                            subTitle: null,
                            image: null,
                        }),
                    ...(notificatonData?.autoCloseVisible && {
                        autoCloseTime: Number(notificatonData?.autoCloseTime),
                    }),
                    ...(!notificatonData?.autoCloseVisible && {
                        autoCloseTime: null,
                    }),

                    ...(notificatonData?.targetType === 'project' && {
                        targetType: 'project',
                    }),
                    ...(notificatonData?.targetType === 'livepage' && {
                        targetType: 'livepage',
                        targetId: notificatonData?.targetId.map(
                            (value) => value.key
                        ),
                    }),
                },
            };
            //Create new notification
            if (!notificationEditMode) {
                const newNotificationId = await handleCreateNotification();
                const response = await UpdateNotification(
                    updateInputs,
                    projectId,
                    newNotificationId
                );
                if (response.status) {
                    const publishResponse = await PublishNotification(
                        projectId,
                        newNotificationId
                    );
                    if (publishResponse.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification scheduled!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                    if (!response.status) {
                        openNotification(
                            'error',
                            'An error!',
                            response.message
                        );
                    }
                } else if (newNotificationId) {
                    openNotification(
                        'error',
                        'An error',
                        'Notification created, but an error was encountered while scheduled.'
                    );
                    history.push({
                        pathname: `/company/${companyId}/project/${projectId}/notification/announcements`,
                        state: { redirectTab: 'created' },
                    });
                } else {
                    openNotification(
                        'error',
                        'An error',
                        'An error was encountered please try again'
                    );
                }
            }
            //Schedule exist notification
            if (notificationEditMode) {
                const response = await UpdateNotification(
                    updateInputs,
                    projectId,
                    notificatonData?.id || notificatonData?.notificationId
                );
                if (response.status) {
                    const publishResponse = await PublishNotification(
                        projectId,
                        notificatonData?.id || notificatonData?.notificationId
                    );
                    if (publishResponse.status) {
                        openNotification(
                            'success',
                            'Successful!',
                            'Notification scheduled!'
                        );
                        setVisible(false);
                        onRefreshData();
                    }
                }
                if (!response.status) {
                    openNotification('error', 'An error!', response.message);
                }
            }
        } else {
            //trigger

            let inputData = {
                data: {
                    title: notificatonData?.title,
                    description: notificatonData?.description,
                    position: notificatonData?.position,
                    triggerCode: selectedTrigger.code,
                    oncePerParticipant: true,
                    ...(notificatonData?.ctaVisible && {
                        cta: [
                            {
                                id: notificatonData?.ctaId,
                                label: notificatonData?.buttonLabel,
                                url: notificatonData?.buttonURL,
                                color: notificatonData?.buttonColor,
                                textColor: notificatonData?.buttonTextColor,
                                openInNewWindow:
                                    notificatonData?.openInNewWindow,
                            },
                        ],
                    }),
                    ...(!notificatonData?.ctaVisible && {
                        cta: [],
                    }),
                    ...((notificatonData?.senderVisible ||
                        (!notificatonData?.hasOwnProperty('senderVisible') &&
                            (notificatonData?.subTitle ||
                                notificatonData?.image))) && {
                        subTitle: notificatonData?.subTitle,
                        image: notificatonData?.image,
                    }),
                    ...(notificatonData?.hasOwnProperty('senderVisible') &&
                        !notificatonData?.senderVisible && {
                            subTitle: null,
                            image: null,
                        }),
                    ...(notificatonData?.autoCloseVisible && {
                        autoCloseTime: Number(notificatonData?.autoCloseTime),
                    }),
                    ...(!notificatonData?.autoCloseVisible && {
                        autoCloseTime: null,
                    }),
                    type: 'recommendation-session',
                },
                ...(notificatonData?.targetType === 'project' && {
                    targetType: 'project',
                }),
                ...(notificatonData?.targetType === 'livepage' && {
                    targetType: 'livepage',
                    targetId: notificatonData?.targetId.map(
                        (value) => value.key
                    ),
                }),
            };
            let timeInSecs = null;

            if (
                [
                    'LIVEPAGE_SPECIFIC_TIME',
                    'LIVEPAGE_USER_INACTIVE_TIME',
                ].includes(selectedTrigger.code)
            ) {
                timeInSecs =
                    timeUnit === 'secs' ? triggerTime : triggerTime * 60;
                inputData = {
                    ...inputData,
                    data: {
                        ...inputData.data,
                        triggerConfig: { timeInSecs, timeUnit },
                    },
                };
            }
            if (notificatonData?.id) {
                //edit mode
                const updateResponse = await UpdateNotification(
                    inputData,
                    projectId,
                    notificatonData?.id || notificatonData?.notificationId
                );
                if (!updateResponse.status) {
                    openNotification(
                        'error',
                        'An error!',
                        updateResponse?.message
                    );
                    return;
                }
            }

            let notifId =
                notificatonData?.id || notificatonData?.notificationId;
            if (!notifId) {
                const { id = null } = await CreateNotification(
                    inputData,
                    projectId
                );
                notifId = id;
            }

            if (notifId) {
                const response = await PublishNotification(projectId, notifId);
                if (response.status) {
                    openNotification(
                        'success',
                        'Successful!',
                        'Notification published!'
                    );
                    setVisible(false);
                    onRefreshData();
                }
                if (!response.status) {
                    openNotification('error', 'An error!', response.message);
                }
            } else {
                openNotification('error', {
                    message: 'invalid notification id',
                });
            }
        }
    }, 500);

    const openNotification = (
        type = 'success',
        alertTitle = '',
        alertMessage = ''
    ) => {
        notification[type]({
            message: alertTitle,
            description: alertMessage,
            placement: 'bottomLeft',
        });
    };

    return (
        <Modal
            destroyOnClose
            forceRender
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            width={905}
            placement="top"
        >
            <div
                className="tw-p-0 md:tw-p-4 tw-flex tw-items-start md:tw-items-center"
                style={{ height: 560, overflow: 'auto' }}
            >
                <div className="tw-flex tw-flex-col-reverse md:tw-flex-row">
                    <div className="col-sm-5 d-flex flex-column justify-content-between">
                        <div className="row">
                            <div className="col-12 mb-3 modalHead tw-mt-4 md:tw-mt-0 ">
                                <h2>Review your settings</h2>
                                <p>
                                    The notification will be sent based on your
                                    settings.
                                </p>
                            </div>

                            <div className="col-12 mb2">
                                {notificatonData?.targetType === 'project' ? (
                                    <div className="infoBox">
                                        <label>Livepages(All)</label>
                                        <div>All Livepages</div>
                                    </div>
                                ) : (
                                    <>
                                        <label className="font-weight-normal tw-text-left md:tw-text-center">
                                            It will be sent to the following{' '}
                                            <strong>
                                                {
                                                    notificatonData?.targetId?.filter(
                                                        function (e) {
                                                            return e;
                                                        }
                                                    ).length
                                                }{' '}
                                                livepages:
                                            </strong>
                                        </label>
                                        <List
                                            className="live-pages-list"
                                            size="small"
                                            bordered
                                            dataSource={notificatonData?.targetId?.filter(
                                                function (e) {
                                                    return e;
                                                }
                                            )}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    {item.value}
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                )}

                                <div className="infoBox mt-3">
                                    <label>When</label>
                                    <div>
                                        {sendMode === 'sendNow'
                                            ? 'Now'
                                            : sendMode === 'trigger'
                                            ? 'Based on trigger'
                                            : 'Scheduled'}
                                    </div>
                                    {sendMode === 'trigger' && (
                                        <>
                                            <div className="mt-2">
                                                {selectedTrigger.description}
                                                {[
                                                    'LIVEPAGE_SPECIFIC_TIME',
                                                    'LIVEPAGE_USER_INACTIVE_TIME',
                                                ].includes(
                                                    selectedTrigger.code
                                                ) && (
                                                    <>
                                                        <br />- {triggerTime}{' '}
                                                        {timeUnit}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {!notificationSendNow && (
                                <>
                                    <div className="col-6">
                                        <label className="mb-1 timeLabel">
                                            Date
                                        </label>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            disabledDate={(current) =>
                                                current <
                                                moment()
                                                    .tz(timezone)
                                                    .startOf('day')
                                            }
                                            allowClear={false}
                                            value={scheduleDate}
                                            onChange={setScheduleDate}
                                            showToday={false}
                                            format="D MMM YYYY"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className="mb-1 timeLabel">
                                            Time
                                        </label>
                                        <TimePicker
                                            allowClear={false}
                                            style={{ width: '100%' }}
                                            disabledDate={(current) =>
                                                current <
                                                moment()
                                                    .tz(timezone)
                                                    .add(9, 'minutes')
                                            }
                                            value={scheduleDate}
                                            onChange={setScheduleDate}
                                            format="hh:mm a"
                                            showNow={false}
                                        />
                                    </div>
                                    <div className="col-12 mt-3 ">
                                        <label className="mb-1 timeLabel">
                                            Time Zone
                                        </label>
                                        <TimezoneSelector
                                            selectStyle={{ width: '100%' }}
                                            wrapperClass=""
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mx-auto">
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="myxp btn btn-secondary"
                                            onClick={() => setVisible(false)}
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            className="myxp btn btn-sm btn-dark mx-2"
                                            onClick={() =>
                                                handleSendNotification(sendMode)
                                            }
                                        >
                                            Send now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="AppContent rounded h-100">
                            <div className="w-100">
                                <h3 className="tw-my-2 md:tw-mt-3 md:tw-mb-4 text-center">
                                    Have a final look:
                                </h3>
                                <div className="position-relative">
                                    <NotificationBox
                                        previewMode
                                        position={null}
                                        title={notificatonData?.title}
                                        senderVisible={
                                            notificatonData?.hasOwnProperty(
                                                'senderVisible'
                                            )
                                                ? notificatonData?.senderVisible
                                                : !!(
                                                      notificatonData?.subTitle ||
                                                      notificatonData?.image
                                                  )
                                        }
                                        subTitle={notificatonData?.subTitle}
                                        image={notificatonData?.image}
                                        description={decodeHtml(
                                            notificatonData?.description
                                        )}
                                        ctaVisible={
                                            !!notificatonData?.cta?.length
                                        }
                                        buttonLabel={
                                            notificatonData?.cta?.length
                                                ? notificatonData?.cta[0].label
                                                : ''
                                        }
                                        buttonURL={
                                            notificatonData?.cta?.length
                                                ? notificatonData?.cta[0].url
                                                : ''
                                        }
                                        buttonColor={
                                            notificatonData?.cta?.length
                                                ? notificatonData?.cta[0].color
                                                : '#e2e9f3'
                                        }
                                        buttonTextColor={
                                            notificatonData?.cta?.length
                                                ? notificatonData?.cta[0]
                                                      .textColor
                                                : '#000000'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ScheduleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func,
    notificationSendNow: PropTypes.bool,
    notificatonData: PropTypes.object,
    onRefreshData: PropTypes.func,
    notificationEditMode: PropTypes.bool,
};

ScheduleModal.defaultProps = {
    visible: false,
    notificationSendNow: false,
    notificatonData: null,
    notificationEditMode: false,
};

export default ScheduleModal;
