import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Input, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getNumbers, separatePixels } from '../../../helpers/common';
import BlockViewConfiguration from '../BlockViewConfiguration';

import styles from './index.module.css';

const { Option } = Select;

const SettingsPixels = ({
    property,
    relatedProperties,
    headingTitle,
    tooltipText,
    value,
    fitToContent,
    setValue,
    getValues,
    name,
    error,
    noPadding,
    displayMode,
    desktopFitToContent,
    tabletFitToContent,
    mobileFitToContent,
    refId,
    required,
    linkedSettings,
    setLinkedSettings,
}) => {
    const displayModeRedux = useSelector((state) => state?.header.display);
    const [pixelSelection, setPixelSelection] = useState(separatePixels(value));
    const [heightVal, SetHeightVal] = useState(getNumbers(value, pixelSelection)|| '')
    const isLinked = !!linkedSettings?.[property];

    const isFitToContent = {
        desktop: desktopFitToContent,
        mobile: mobileFitToContent,
        tablet: tabletFitToContent,
    };

    useEffect(() => {
        if (isFitToContent[displayMode] && getValues('fitHeightToContent')) SetHeightVal('');
    }, [isFitToContent[displayMode]]);
    
    
    const onChange = (e) => {
        SetHeightVal(e.target.value)
        setValue(
            `${name}`,
            e.target.value ? e.target.value + pixelSelection : ''
        );
    };

    const onChangeSelection = (pix) => {
        const num = getNumbers(value, pixelSelection);
        setValue(`${name}`, num ? num + pix : num);
        setPixelSelection(pix);
    };

    // useEffect(() => {
    //     if (property !== 'blockHeight' || !value) return;
    //     setPixelSelection(separatePixels(value));
    // }, [property, value]);

    useEffect(() => {
        if (property === 'blockHeight') {
            if (linkedSettings?.blockHeight && getValues('fitHeightToContent')) {
                setValue(`${property}`, 'fit-content');
                setValue(`tabletBreakpoints.${property}`, 'fit-content');
                setValue(`mobileBreakpoints.${property}`, 'fit-content');
            }else{
                if(desktopFitToContent){
                    setValue(`${property}`, 'fit-content');
                }
                if (tabletFitToContent) {
                    setValue(`tabletBreakpoints.${property}`, 'fit-content');
                }
                if (mobileFitToContent) {
                    setValue(`mobileBreakpoints.${property}`, 'fit-content');
                }
            }
        }
    }, [
        property,
        linkedSettings,
        fitToContent,
        desktopFitToContent,
        tabletFitToContent,
        mobileFitToContent,
    ]);

    const selectAfter = (
        <Select
            value={pixelSelection}
            onChange={onChangeSelection}
            className="select-after"
            disabled={fitToContent}
        >
            <Option value="px">px</Option>
            <Option value="rem">rem</Option>
            <Option value="em">em</Option>
            <Option value="%">%</Option>
            <Option value="vh">vh</Option>
        </Select>
    );

    const displayStyle =
        displayMode && ['Height', 'Padding'].includes(headingTitle)
            ? {
                  display:
                      (displayMode === 'desktop' && displayModeRedux.desktop) ||
                      (displayMode === 'tablet' && displayModeRedux.tablet) ||
                      (displayMode === 'mobile' && displayModeRedux.mobile)
                          ? 'block'
                          : 'none',
              }
            : { display: 'block' };

    return (
        <div
            style={
                noPadding
                    ? { padding: '0px', ...displayStyle }
                    : { ...displayStyle }
            }
            className={`${styles.SettingsHeightContainer} container`}
        >
            {headingTitle && (
                <div className={styles.Heading}>
                    <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                        <h3>
                            {headingTitle}{' '}
                            {required && (
                                <span className={styles.Required}>*</span>
                            )}
                            {tooltipText && (
                                <Tooltip placement="right" title={tooltipText}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            )}
                        </h3>
                        {displayMode &&
                        ['blockHeight', 'contentPadding'].includes(property) ? (
                            <BlockViewConfiguration
                                property={property}
                                relatedProperties={relatedProperties}
                                setValue={setValue}
                                getValues={getValues}
                                linkedSettings={linkedSettings}
                                setLinkedSettings={setLinkedSettings}
                            />
                        ) : null}
                    </div>
                </div>
            )}
            <div className={`input-pixels ${error ? styles.InputError : ''}`}>
                <Input
                    type="number"
                    onChange={onChange}
                    value={heightVal}
                    addonAfter={selectAfter}
                    disabled={isLinked? getValues('fitHeightToContent') : fitToContent}
                    name={name}
                    ref={refId}
                />
            </div>
            {error && <p className={styles.ErrorMessage}>{error.type}</p>}
            <style jsx="true">{`
                .ant-input-affix-wrapper > input.ant-input {
                    margin-bottom: 0px;
                }

                .ant-input-group .ant-input {
                    padding: 8px;
                }

                .ant-checkbox + span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                .ant-input-affix-wrapper {
                    border-radius: 4px;
                }
                /* Chrome, Safari, Edge, Opera */
                .input-pixels input::-webkit-outer-spin-button,
                .input-pixels input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                .input-pixels input[type='number'] {
                    -moz-appearance: textfield;
                }
            `}</style>
        </div>
    );
};

export default SettingsPixels;
