import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input } from 'antd';

import { produce } from 'immer';
import { CloseOutlined } from '@ant-design/icons';
import DraggableIcon from 'Apps/VenueBuilder/Icons/DraggableIcon';
import PlusIcon from 'Apps/VenueBuilder/Icons/PlusIcon';

import './draggableInputList.scss';

function DraggableInputList({ itemsData, setItemsData, disabled = false }) {

    const [items, setItems] = useState(itemsData)

    useEffect(() => {
        setItemsData(items)
    }, [items])

    const reorderItems = (list, startIndex, endIndex) => {
        const items = Array.from(list);
        const [removed] = items.splice(startIndex, 1);
        items.splice(endIndex, 0, removed);
        setItems(items)
    };

    const onDragEnd = result => {
        const { source, destination } = result;
        if (!destination) return;
        reorderItems(items, source.index, destination.index);
    };

    const handleChange = (key, e) => {
        if (disabled) return null
        const newArray = [].concat(items);
        newArray[key] = e.target.value;
        setItems(newArray)
    };

    const onClickAddItem = () => {
        if (disabled) return null
        const lists = Array.from(items);
        lists.push("");
        setItems(lists)
    };

    const onClickRemoveItem = (removeItemIndex) => {
        if (disabled) return null
        const nextState = produce(items, (draftState) => {
            draftState.splice(removeItemIndex, 1);
        });
        setItems(nextState);
    }

    return (
        <div className="border rounded">
            <button
                className="btn btn-add-option"
                onClick={() => onClickAddItem()}
                disabled={disabled}
            >
                <PlusIcon />
                <span className="pl-3">Add Option</span>
            </button>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppabe-list">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                        >
                            {items.map((value, key) => (
                                <Draggable
                                    draggableId={`draggable-${key}`}
                                    key={key}
                                    index={key}
                                    isDragDisabled={disabled}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="drag-item-content"
                                        >
                                            <div className="d-flex align-items-baseline justify-content-between w-100">
                                                <Input
                                                    type="text"
                                                    value={value}
                                                    onChange={handleChange.bind(this, key)}
                                                    className="mb-0"
                                                    disabled={disabled}
                                                />
                                                <div className="d-flex ml-2">
                                                    <span className="draggable-icon-close pr-3 pl-2" onClick={() => onClickRemoveItem(key)}  >
                                                        <CloseOutlined />
                                                    </span>
                                                    <span>
                                                        <DraggableIcon />
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

DraggableInputList.propTypes = {
    itemsData: PropTypes.array,
    setItemsData: PropTypes.func,
}

DraggableInputList.defaultProps = {
    itemsData: [],
    setItemsData: () => { },
};

export default DraggableInputList

