export function setProject(payload) {
    return { type: 'SET_PROJECT', payload };
}

export function updateProject(payload) {
    return { type: 'UPDATE_PROJECT', payload };
}

export function setCompanyDetails(payload) {
    return { type: 'SET_COMPANY_DETAILS', payload };
}

export function updateCompanyDetails(payload) {
    return { type: 'UPDATE_COMPANY_DETAILS', payload };
}
