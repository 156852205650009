import React, { Suspense } from 'react';
import { Layout, Skeleton, Input, Space } from 'antd';
const { Header, Sider, Content } = Layout;

const ProjectDashboard = React.lazy(() =>
    import(/* webpackChunkName: "project-dashboard" */ '../index.js')
);

const FallBackLayout = () => (
    <>
        <Layout style={{ height: '100vh' }}>
            <Header
                style={{
                    padding: 20,
                    minHeight: 'unset',
                    lineHeight: 'initial',
                    height: 'auto',
                    background: '#fff',
                    border: '0.5px solid #eee',
                }}
            >
                <Skeleton.Input
                    style={{ width: 200, height: 22 }}
                    active={true}
                    size={'default'}
                />
            </Header>
            <Layout>
                <Sider
                    width={286}
                    style={{
                        padding: 20,
                        width: 300,
                        background: '#fff',
                        border: '0.5px solid #eee',
                    }}
                >
                    <Space>
                        <Skeleton.Button
                            style={{ width: 22, height: 22 }}
                            active={true}
                            size={'default'}
                            shape={'default'}
                            block={false}
                        />
                        <Skeleton.Input
                            style={{ width: 200, height: 22 }}
                            active={true}
                            size={'default'}
                        />
                    </Space>
                    <br />
                    <br />
                    <Space>
                        <Skeleton.Button
                            style={{ width: 22, height: 22 }}
                            active={true}
                            size={'default'}
                            shape={'default'}
                            block={false}
                        />
                        <Skeleton.Input
                            style={{ width: 200, height: 22 }}
                            active={true}
                            size={'default'}
                        />
                    </Space>
                    <br />
                    <br />
                    <Space>
                        <Skeleton.Button
                            style={{ width: 22, height: 22 }}
                            active={true}
                            size={'default'}
                            shape={'default'}
                            block={false}
                        />
                        <Skeleton.Input
                            style={{ width: 200, height: 22 }}
                            active={true}
                            size={'default'}
                        />
                    </Space>
                    <br />
                    <br />
                    <Space>
                        <Skeleton.Button
                            style={{ width: 22, height: 22 }}
                            active={true}
                            size={'default'}
                            shape={'default'}
                            block={false}
                        />
                        <Skeleton.Input
                            style={{ width: 200, height: 22 }}
                            active={true}
                            size={'default'}
                        />
                    </Space>
                </Sider>
                <Content
                    style={{
                        padding: 30,
                        background: '#fff',
                        border: '0.5px solid #eee',
                    }}
                >
                    <Skeleton.Input
                        style={{ width: 300 }}
                        aactive={true}
                        size={'default'}
                    />
                    <Skeleton />
                </Content>
            </Layout>
        </Layout>
    </>
);

const ProjectDashboardLazyLoaded = () => {
    return (
        <Suspense fallback={<FallBackLayout />}>
            <ProjectDashboard />
        </Suspense>
    );
};

export default ProjectDashboardLazyLoaded;
