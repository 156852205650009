import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import BlockPrivate from '../../blockPrivate/blockPrivate';
import FileUploader from 'components/app/common/fileUploader';
import NameEditor from 'components/app/components/showcase/blockEditor/nameEditor';
import { PERMISSION_TYPE } from 'components/app/utils/permissions';
import { deleteFile } from 'apis/rest/strapi/DeleteFile';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function ImageGallery(props) {
    const {
        handleInputChange,
        handleFileDelete,
        prepareFileUpload,
        input,
        currUploads,
        renderFieldError,
        preventEditName = false,
        preventEditSize = false,
        preventEditTitle = false,
        permission = null,
    } = props;

    const { largeTile, title, name } = input;

    const [currentImages, setCurrentImages] = useState(currUploads.image);
    const [permissionSize, setPermissionSize] = useState(null);
    const [permissionDimension, setPermissionDimension] = useState(null);
    const [currentVisibilityControl, setCurrentVisibilityControl] = useState(
        PERMISSION_TYPE.VISIBLE
    );

    const onDeleteImage = async (id) => {
        try {
            let deleteImage = currentImages.filter((item) => item.id !== id);
            await deleteFile(id);
            setCurrentImages(deleteImage);
        } catch (error) {
            console.error('ERROR delete image', error);
        }
    };

    useEffect(() => {
        if (permission !== permissionSize) {
            const currentPermissonSize = permission[0]?.dimension;
            const currentVisibilityControl =
                permission[0]?.access?.content?.visibilityControl;

            if (currentVisibilityControl === PERMISSION_TYPE.HIDDEN) {
                setCurrentVisibilityControl(PERMISSION_TYPE.HIDDEN);
            }

            setPermissionSize(permission);
            if (currentPermissonSize) {
                Object.keys(currentPermissonSize).map((k) => {
                    if (name === k) {
                        setPermissionDimension(currentPermissonSize[k]);
                    }
                });
            }
        }
    }, [permission]);

    return (
        <div>
            <Tabs
                className="visibility-tabs"
                defaultActiveKey="content"
                id="visibility-tab-content"
            >
                <Tab eventKey="content" title="Content">
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="input-set">
                                <label>Block title</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    className="form-control"
                                    onChange={handleInputChange}
                                    autoFocus
                                    maxLength={70}
                                    disabled={preventEditTitle}
                                />
                                {renderFieldError('title')}
                            </div>

                            {/* <NameEditor
                                disabled={preventEditName}
                                handleInputChange={handleInputChange}
                                name={name}
                            /> */}
                            {!preventEditSize && (
                                <>
                                    <label>Block size</label>
                                    <div className="radio-set">
                                        <span>
                                            <input
                                                type="radio"
                                                value={false}
                                                checked={largeTile === false}
                                                name="largeTile"
                                                onChange={handleInputChange}
                                                id="normalBlockSize"
                                                disabled={preventEditSize}
                                            />{' '}
                                            <label htmlFor="normalBlockSize">
                                                Normal
                                            </label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={largeTile === true}
                                                name="largeTile"
                                                onChange={handleInputChange}
                                                id="bigBlockSize"
                                                disabled={preventEditSize}
                                            />{' '}
                                            <label htmlFor="bigBlockSize">
                                                Big
                                            </label>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-6"></div>
                    </div>

                    <label>Your images</label>
                    <p>
                        Upload your image(s) below. (File types accepted: .gif,
                        .jpg/jpeg, .png, .svg)
                    </p>
                    <p className="form-text text-muted">Max File Size - 5MB, Max Files - 10, Block Capacity - 10 MB</p>
                    <ImageUpload
                        multiple={true}
                        previewImages={currentImages ? currentImages : []}
                        prepareFilesUpload={(files) => {
                            let updatedFiles = [];
                            for (let file of files) {
                                if (file.constructor === File) {
                                    updatedFiles.push(file);
                                }
                            }
                            prepareFileUpload(updatedFiles, 'image');
                        }}
                        handleFileDelete={(file) => {
                            handleFileDelete(file, 'image');
                            // onDeleteImage(file.id)
                        }}
                        imageCropRecommendedSize={{
                            width:
                                (permissionDimension &&
                                    permissionDimension[0]) ||
                                null,
                            height:
                                (permissionDimension &&
                                    permissionDimension[1]) ||
                                null,
                        }}
                    />
                    {permissionDimension && (
                        <p>
                            Recommended size: within {permissionDimension[0]} x{' '}
                            {permissionDimension[1]} px
                        </p>
                    )}
                    {renderFieldError('image')}
                </Tab>
                {currentVisibilityControl !== PERMISSION_TYPE.HIDDEN && (
                    <Tab
                        eventKey="visibility-control"
                        title="Visibility control"
                    >
                        <div>
                            <BlockPrivate {...props} />
                        </div>
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default ImageGallery;
