export function showAlert(payload) {
  return { type: "SHOW_ALERT", payload };
}

export function hideAlert(payload) {
  return { type: "HIDE_ALERT" };
}

export function updateLoadingStatus(payload) {
  return { type: "UPDATE_LOADING_STATUS", payload };
}
