import React from "react";
import {
    Spin
} from 'antd';
import 'antd/dist/antd.css';
import {
    LoadingOutlined
} from '@ant-design/icons';


const Loader = ({text}) => {

    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

    return <Spin tip={text} indicator={antIcon} />
}

export default Loader