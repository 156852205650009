import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * Github apis
 */
export async function githubInstall(payload) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/install`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(payload),
            }
        );
        return await response.json();
    }
}

export async function getInstallationIds(projectId, companyId) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/installations?projectId=${projectId}&companyId=${companyId}`,
            {
                method: 'GET',
                headers,
            }
        );
        return await response.json();
    }
}

export async function getRepos(installationId, projectId, companyId) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/installations/${installationId}/repos?projectId=${projectId}&companyId=${companyId}&per_page=100&page=1`,
            {
                method: 'GET',
                headers,
            }
        );
        return await response.json();
    }
}

export async function getBranches(
    installationId,
    owner,
    reponame,
    projectId,
    companyId
) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/installations/${installationId}/repos/branches?owner=${owner}&repo=${reponame}&projectId=${projectId}&companyId=${companyId}`,
            {
                method: 'GET',
                headers,
            }
        );
        return await response.json();
    }
}

export async function saveRepoBranch(payload) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/save`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(payload),
            }
        );
        return await response.json();
    }
}

export async function updateRepoBranch(payload, venueId) {
    const auth = ls.get('auth');
    let headers = '';
    if (auth) {
        headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.access_token}`,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/github/${venueId}/save`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(payload),
            }
        );
        return await response.json();
    }
}
