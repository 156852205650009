export const contentBlocks = [
    {
        name: 'Text',
        data: [
            {
                action: 'RICH_TEXT_BLOCK',
                type: 'RichText',
                name: 'Rich Text',
                description: 'Just some good old rich text.',
            },
        ],
    },
    {
        name: 'Video',
        data: [
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'LiveStream',
                name: 'Live Stream',
                description:
                    'Integrate with a streaming service, e.g. YouTube, Vimeo, Facebook, etc.',
            },
            {
                action: 'MEETING_BLOCK',
                type: 'Meeting',
                name: 'Meeting',
                description:
                    'Add a meeting block to your livepage and allow participants to join.',
            },
            {
                action: 'VIDEO_BLOCK',
                type: 'Video',
                name: 'Video',
                description: 'Add a video block to your livepage.',
            },
        ],
    },
    {
        name: 'Images',
        data: [
            {
                action: 'IMAGE_BLOCK',
                type: 'Image',
                name: 'Image',
                description:
                    'Just an image by itself. Note: this can be converted into an image list.',
            },
            {
                action: 'IMAGE_LIST_BLOCK',
                type: 'ImageList',
                name: 'Image List',
                description:
                    'One or more images in a horizontal listing format. Perfect for sponsor images.',
            },
            {
                action: 'IMAGE_SLIDESHOW_BLOCK',
                type: 'ImageSlideshow',
                name: 'Image Slideshow',
                description: 'One or more images in a slideshow format.',
            },
        ],
    },
    {
        name: 'Documents',
        data: [
            {
                action: 'PDF_BLOCK',
                type: 'Pdf',
                name: 'PDF',
                description: 'Just a block with a PDF to be downloaded.',
            },
            {
                action: 'RESOURCES_BLOCK',
                type: 'Resources',
                name: 'Resources',
                description:
                    'A listing of content with icons and links to download.',
            },
        ],
    },
    {
        name: 'Event Information',
        data: [
            {
                action: 'SPEAKERS_BLOCK',
                type: 'Speakers',
                name: 'Speakers',
                description: 'Showcase your speakers.',
            },
            {
                action: 'AGENDA_BLOCK',
                type: 'Agenda',
                name: 'Agenda',
                description: 'Showcase your event agenda.',
            },
            {
                action: 'HERO_CONTENT_BLOCK',
                type: 'HeroContent',
                name: 'Hero Content',
                description: 'Show your key content prominently.',
            },
            {
                action: 'FEATURED_CONTENT_BLOCK',
                type: 'FeaturedContent',
                name: 'Featured Content',
                description:
                    'Series of blocks with thumbnail and linking to other pages and content.',
            },
            {
                action: 'SPONSOR_BLOCK',
                type: 'Sponsor',
                name: 'Sponsors',
                description: 'Display your sponsors in a block.',
            },
            {
                action: 'PRODUCT_BLOCK',
                type: 'Product',
                name: 'Products',
                description: 'Display your product in a block.',
            },
            {
                action: 'ATTENDEES_DIRECTORY_BLOCK',
                type: 'People',
                name: 'People Directory',
                description: 'Display your people directory in a block.',
            },
            {
                action: 'SEARCH_BLOCK',
                type: 'Search',
                name: 'Search',
                description: 'Add a search component to your livepage.',
            },
        ],
    },
    // {
    //     name: 'CTA',
    //     data: [
    //         {
    //             action: 'CTA_CARD_BLOCK',
    //             type: 'ctaCard',
    //             name: 'CTA Card',
    //             description:
    //                 'A card with a Call-To-Action button and content.',
    //         },
    //         {
    //             action: 'SOCIAL_MEDIA_BLOCK',
    //             type: 'socialMedia',
    //             name: 'Social Media',
    //             description: 'Drive traffic to your social media presence.',
    //         },
    //     ],
    // },
    {
        name: 'Custom',
        data: [
            {
                action: 'LINK_BLOCK',
                type: 'Link',
                name: 'Link',
                description:
                    'The Link block allows you to have a cover image which links to a URL.',
            },
            {
                action: 'HTML_BLOCK',
                type: 'Html',
                name: 'HTML',
                description:
                    'Build your own custom HTML content, embed apps into your block, etc.',
            },
            {
                action: 'BUTTON_BLOCK',
                type: 'Button',
                name: 'Button',
                description: 'Build your own custom Button.',
            },
        ],
    },
    {
        name: 'Video and Live Stream',
        type: 'apps',
        data: [
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'gevmelive',
                name: 'GEVME Live',
            },
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'youtube',
                name: 'YouTube',
            },
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'vimeo',
                name: 'Vimeo',
            },
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'facebook',
                name: 'Facebook',
            },
            {
                action: 'LIVE_STREAM_BLOCK',
                type: 'msteams',
                name: 'Microsoft Stream',
            },
            {
                action: 'MEETING_BLOCK',
                type: 'zoom',
                name: 'Zoom',
                meetingType: 'zoom',
            },
        ],
    },
];

export const websiteBuilderContentBlocks = [
    {
        name: 'Text',
        data: [
            {
                action: 'RICH_TEXT_BLOCK',
                type: 'RichText',
                name: 'Rich Text',
                description: 'Just some good old rich text.',
            },
        ],
    },
    {
        name: 'Video',
        data: [
            {
                action: 'VIDEO_BLOCK',
                type: 'Video',
                name: 'Video',
                description: 'Add a video block to your page.',
            },
        ],
    },
    {
        name: 'Images',
        data: [
            {
                action: 'IMAGE_BLOCK',
                type: 'Image',
                name: 'Image',
                description:
                    'Just an image by itself. Note: this can be converted into an image list.',
            },
            {
                action: 'IMAGE_LIST_BLOCK',
                type: 'ImageList',
                name: 'Image List',
                description:
                    'One or more images in a horizontal listing format. Perfect for sponsor images.',
            },
            {
                action: 'IMAGE_SLIDESHOW_BLOCK',
                type: 'ImageSlideshow',
                name: 'Image Slideshow',
                description: 'One or more images in a slideshow format.',
            },
        ],
    },
    {
        name: 'Documents',
        data: [
            {
                action: 'PDF_BLOCK',
                type: 'Pdf',
                name: 'PDF',
                description: 'Just a block with a PDF to be downloaded.',
            },
            {
                action: 'RESOURCES_BLOCK',
                type: 'Resources',
                name: 'Resources',
                description:
                    'A listing of content with icons and links to download.',
            },
        ],
    },
    {
        name: 'Event Information',
        data: [
            {
                action: 'SPEAKERS_BLOCK',
                type: 'Speakers',
                name: 'Speakers',
                description: 'Showcase your speakers.',
            },
            {
                action: 'AGENDA_BLOCK',
                type: 'Agenda',
                name: 'Agenda',
                description: 'Showcase your event agenda.',
            },
            {
                action: 'HERO_CONTENT_BLOCK',
                type: 'HeroContent',
                name: 'Hero Content',
                description: 'Show your key content prominently.',
            },
            {
                action: 'FEATURED_CONTENT_BLOCK',
                type: 'FeaturedContent',
                name: 'Featured Content',
                description:
                    'Series of blocks with thumbnail and linking to other pages and content.',
            },
            {
                action: 'SPONSOR_BLOCK',
                type: 'Sponsor',
                name: 'Sponsors',
                description: 'Display your sponsors in a block.',
            },
            {
                action: 'PRODUCT_BLOCK',
                type: 'Product',
                name: 'Products',
                description: 'Display your product in a block.',
            },
            // {
            //     action: 'ATTENDEES_DIRECTORY_BLOCK',
            //     type: 'People',
            //     name: 'People Directory',
            //     description: 'Display your people directory in a block.',
            // },
            {
                action: 'SEARCH_BLOCK',
                type: 'Search',
                name: 'Search',
                description: 'Add a search component to your page.',
            },
        ],
    },
    // {
    //     name: 'CTA',
    //     data: [
    //         {
    //             action: 'CTA_CARD_BLOCK',
    //             type: 'ctaCard',
    //             name: 'CTA Card',
    //             description:
    //                 'A card with a Call-To-Action button and content.',
    //         },
    //         {
    //             action: 'SOCIAL_MEDIA_BLOCK',
    //             type: 'socialMedia',
    //             name: 'Social Media',
    //             description: 'Drive traffic to your social media presence.',
    //         },
    //     ],
    // },
    {
        name: 'Custom',
        data: [
            {
                action: 'LINK_BLOCK',
                type: 'Link',
                name: 'Link',
                description:
                    'The Link block allows you to have a cover image which links to a URL.',
            },
            {
                action: 'HTML_BLOCK',
                type: 'Html',
                name: 'HTML',
                description:
                    'Build your own custom HTML content, embed apps into your block, etc.',
            },
            {
                action: 'BUTTON_BLOCK',
                type: 'Button',
                name: 'Button',
                description: 'Build your own custom Button.',
            },
        ],
    },
];
