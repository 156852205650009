import { produce } from 'immer';

export const initialState = {
    visibility: false,
    message: 'Success!',
    type: 'success',
};

function alertReducers(state = initialState, action) {
    switch (action.type) {
        case 'HIDE_ALERT':
            return produce(state, (draft) => {
                draft.visibility = false;
            });
        case 'SHOW_ALERT':
            let displayMessage;

            if (action.payload.type === 'error') {
                displayMessage = `${action.payload.message}.`;
            } else {
                displayMessage = action.payload.message;
            }

            return produce(state, (draft) => {
                draft.visibility = true;
                draft.message = displayMessage;
                draft.type = action.payload.type;
            });
        default:
            return state;
    }
}

export default alertReducers;
