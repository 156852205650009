import axios from 'axios';

/**
 * handles file deletion (triggers post request and returns resulting `uploadId`)
 * @note this is only file deletion using "Strapi" endpoint
 */
export const deleteFile = (fileID) => {
    return axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/upload/files/${fileID}`,
        headers: {
            credentials: 'include',
        },
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
};
