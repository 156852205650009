import React from 'react';

export default function LockIcon() {
    return (
        <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.00008 0.333374C4.16208 0.333374 2.66675 1.82871 2.66675 3.66671V5.66671H2.00008C1.64646 5.66671 1.30732 5.80718 1.05727 6.05723C0.807224 6.30728 0.666748 6.64642 0.666748 7.00004V12.3334C0.666748 12.687 0.807224 13.0261 1.05727 13.2762C1.30732 13.5262 1.64646 13.6667 2.00008 13.6667H10.0001C10.3537 13.6667 10.6928 13.5262 10.9429 13.2762C11.1929 13.0261 11.3334 12.687 11.3334 12.3334V7.00004C11.3334 6.64642 11.1929 6.30728 10.9429 6.05723C10.6928 5.80718 10.3537 5.66671 10.0001 5.66671H9.33341V3.66671C9.33341 1.82871 7.83808 0.333374 6.00008 0.333374ZM4.00008 3.66671C4.00008 2.56404 4.89741 1.66671 6.00008 1.66671C7.10275 1.66671 8.00008 2.56404 8.00008 3.66671V5.66671H4.00008V3.66671ZM6.66675 10.8154V12.3334H5.33341V10.8154C5.10034 10.6819 4.91319 10.4809 4.79663 10.2389C4.68008 9.99696 4.6396 9.72534 4.68054 9.45989C4.72147 9.19444 4.84189 8.94763 5.02592 8.752C5.20996 8.55636 5.44896 8.4211 5.71141 8.36404C5.90636 8.32094 6.10849 8.32214 6.30291 8.36756C6.49733 8.41299 6.67907 8.50147 6.83474 8.62649C6.9904 8.75151 7.11602 8.90987 7.20233 9.08991C7.28864 9.26994 7.33343 9.46705 7.33341 9.66671C7.33303 9.89987 7.27121 10.1288 7.15417 10.3305C7.03713 10.5321 6.86901 10.6994 6.66675 10.8154Z"
                fill="white"
            />
        </svg>
    );
}
