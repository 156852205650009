import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.css';
import ContentLayout from 'Apps/VenueBuilder/Components/Common/ContentLayout';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import SettingsText from 'Apps/VenueBuilder/Components/Common/SettingsText';
import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import CommonBlockSettings from 'Apps/VenueBuilder/Components/Common/CommonBlockSettings';
import {
    getDefaultBlockLinkedSettings,
    getUserRole,
} from 'Apps/VenueBuilder/helpers/common';

import getPeopleDetails from 'Apps/VenueBuilder/apis/getPeopleDetails';

import BlockFooter from 'Apps/VenueBuilder/Components/Common/BlockFooter';
import { DatePicker, Select, Switch, Tooltip, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';

import switchStyles from 'Apps/VenueBuilder/Components/Common/SwitchComponent/index.module.css';
import getPeopleById from 'Apps/VenueBuilder/apis/getPeopleById';
import { useSelector } from 'react-redux';

const { Option } = Select;
const MODERATORS_LIMIT = 10;
/**
 * MeetingBlock Component
 *

 * @MeetingBlock
 * @category Venue Builder
 * @subcategory AppSideBar
 * @description Component that wraps up a specific type of block
 */

const SwitchContainer = ({
    icon,
    label,
    switchStatus,
    onChange,
    children,
    tooltipText,
    hint,
    padding,
    background,
    disabled = false,
}) => {
    return (
        <>
            <div
                className={`switch-wrapper ${switchStyles.SwitchComponent} ${
                    !switchStatus ? `disabled ${switchStyles.Disabled}` : ''
                }`}
                style={{
                    padding: padding,
                    backdround: background,
                }}
            >
                <div className={switchStyles.Content}>
                    <div className={switchStyles.SwitchContent}>
                        <div className={switchStyles.LeftContent}>
                            {icon && (
                                <span className={switchStyles.Icon}>
                                    {icon}
                                </span>
                            )}
                            <span
                                className={`${switchStyles.Label} ${
                                    padding ? switchStyles.NoPadding : ''
                                }`}
                            >
                                {label}
                                {tooltipText && (
                                    <Tooltip
                                        placement="right"
                                        title={tooltipText}
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                        <div className={switchStyles.RightContent}>
                            <Switch
                                size={'small'}
                                onChange={onChange}
                                disabled={disabled}
                                checked={switchStatus}
                            />
                        </div>
                    </div>
                    {hint && <span className={switchStyles.Hint}>{hint}</span>}
                    {children}
                </div>
            </div>
            <style jsx="true">{`
                .ant-switch-checked {
                    background: #0b1c2c;
                }
            `}</style>
        </>
    );
};

const handleCSSUpdates = () => {
    let customCSS = '';
    return {
        getCustomCSS: () => customCSS,
        updateCustomCSS: (cssUpdates = '') => {
            customCSS = cssUpdates;
            return;
        },
    };
};
const { updateCustomCSS, getCustomCSS } = handleCSSUpdates();
export { updateCustomCSS as updateMeetCustomCSS };

const getDefaultSettings = (settings, meetingType = 'meet') => {
    return [
        {
            label: 'Show the meeting chat panel',
            field: 'disableChat',
            value:
                typeof settings?.disableChat === 'boolean'
                    ? settings.disableChat
                    : true,
        },
        meetingType === 'meet'
            ? {
                  label: 'Show the participants list panel',
                  field: 'disableParticipantList',
                  value:
                      typeof settings?.disableParticipantList === 'boolean'
                          ? settings.disableParticipantList
                          : true,
              }
            : null,
        meetingType === 'meet'
            ? {
                  label: 'Allow participants to turn on their camera',
                  field: 'disableCamera',
                  value:
                      typeof settings?.disableCamera === 'boolean'
                          ? settings.disableCamera
                          : false,
              }
            : null,
        meetingType === 'meet'
            ? {
                  label: 'Allow participants to share their screen',
                  field: 'disableShareScreen',
                  value:
                      typeof settings?.disableShareScreen === 'boolean'
                          ? settings.disableShareScreen
                          : false,
              }
            : null,
        meetingType === 'meet'
            ? {
                  label: 'Show the front door screen',
                  field: 'disableFrontDoor',
                  value:
                      typeof settings?.disableFrontDoor === 'boolean'
                          ? settings.disableFrontDoor
                          : false,
                  tooltipText:
                      'Disabling this would make the participant join the meeting automatically.',
              }
            : null,
        meetingType === 'meet'
            ? {
                  label: 'Background image',
                  field: 'disableBackground',
                  value:
                      typeof settings?.disableBackground === 'boolean'
                          ? settings.disableBackground
                          : true,
              }
            : null,
    ].filter(Boolean);
};

const MeetingBlock = ({
    updateSidebarMenu,
    data,
    createContentBlock,
    updateSpecificBlock,
    livepageId,
    deleteBlock,
    projectId,
    display,
    selectedItem,
}) => {
    const {
        control,
        handleSubmit,
        errors,
        setValue: setValueHandler,
        getValues,
        register,
        trigger,
    } = useForm();
    const debouncedTrigger = debounce(trigger, 100);
    const setValue = (...args) => {
        setValueHandler(...args);
        debouncedTrigger();
    };
    const [linkedSettings, setLinkedSettings] = useState({});
    const methods = useForm();

    const [imgErr, setImgErr] = useState(false);

    const preventBlockSettingsState = useSelector(
        (state) => state?.sidebar?.item?.preventBlockSettings
    );

    const role = getUserRole();
    const preventBlockSettings = preventBlockSettingsState
        ? preventBlockSettingsState[role]
        : false;

    //handles the background preview in case of updation
    const isBackgroundDisabled =
        typeof data.settings?.disableBackground === 'boolean'
            ? data.settings.disableBackground
            : true;
    const [previewImage, setPreviewImage] = useState(
        data.settings?.background && !isBackgroundDisabled
            ? data.settings.background
            : null
    );
    const showSelectImg = useRef(!isBackgroundDisabled);

    // const [muted, toggleMuted] = useState(
    //     typeof data?.settings?.muted === 'boolean' ? data.settings.muted : false
    // );

    const [startTime, setStartTime] = useState(
        data?.startTime ? moment(data.startTime) : moment()
    );

    const [endTime, setEndTime] = useState(
        data?.endTime ? moment(data.endTime) : moment().add(5, 'minutes')
    );

    const [enableStartTime, toggleStartTime] = useState(
        data?.startTime ? true : false
    );
    const [enableEndTime, toggleEndTime] = useState(
        data?.endTime ? true : false
    );

    const [moderators, setModerators] = useState(
        data?.settings?.moderators ? data?.settings?.moderators : ''
    );

    const [enableModerators, toggleModerators] = useState(
        moderators ? true : false
    );

    const [peopleDetails, setPeopleDetails] = useState([]);

    const [peopleLoading, setPeopleLoading] = useState(false);

    const [peopleSrchVal, setPeopleSrchVal] = useState('');

    const [meetingType, setMeetingType] = useState(
        data?.meetingType
            ? data.meetingType
            : data?.settings?.meetingType
            ? data?.settings?.meetingType
            : 'meet'
    );
    const [meetingSetttings, setMeetingSettings] = useState([]);

    const [disableVideoControl, setDisableVideoControl] = useState(
        typeof data?.disableVideoControl === 'boolean'
            ? data?.disableVideoControl
            : false
    );

    const [disableVideoInformation, setDisableVideoInformation] = useState(
        typeof data?.disableVideoInformation === 'boolean'
            ? data?.disableVideoInformation
            : false
    );

    const [allowMute, setallowMute] = useState(
        typeof data.settings?.allowMute !== 'undefined'
            ? data.settings.allowMute
            : false
    );

    const updateMeetingSettings = (e, field) => {
        if (!field) return;

        setMeetingSettings((oldSettings) => {
            const fieldIndex = oldSettings.findIndex(
                (val) => val.field === field
            );

            if (fieldIndex < 0) return oldSettings;

            if (field === 'disableBackground') showSelectImg.current = e;

            oldSettings[fieldIndex]['value'] = !e;

            return [...oldSettings];
        });
    };

    const handleCancel = () => {
        if (data.id) {
            updateSidebarMenu('default');
        } else {
            updateSidebarMenu('ADD_BLOCK');
        }
    };

    const onSubmit = async (formData) => {
        if (showSelectImg.current && !previewImage) {
            setImgErr(true);
            return;
        }

        if (enableModerators && moderators.length === 0)
            toggleModerators(false);

        const settings = {};
        meetingSetttings.map((item) => {
            settings[item.field] = item.value;
            if (item.field === 'disableBackground' && !item.value)
                settings['background'] = previewImage;
        });
        if (meetingType === 'meet') {
            const customCss = getCustomCSS();
            settings.customCss = customCss;
            settings.allowMute = allowMute;
        }

        if (enableModerators && moderators.length === 0)
            toggleModerators(false);
        else if (enableModerators && moderators.length > 0)
            settings.moderators = moderators;
        else settings.moderators = '';

        const meetingData = {
            ...formData,
            settings,
            // settings: { ...settings, muted },
            type: 'Meeting',
            meetingType,
            blockVisibility: true,
            startTime: enableStartTime ? startTime.utc() : null,
            endTime: enableEndTime ? endTime.utc() : null,
            linkedSettings,
        };

        if (data?.item?.backgroundCoverImageDimension)
            meetingData.backgroundCoverImageDimension =
                data?.item?.backgroundCoverImageDimension;

        // if (meetingData.tabletBreakpoints) {
        //     meetingData.tabletBreakpoints.widthType = blockWidthType(
        //         formData?.tabletBreakpoints?.blockWidth
        //     );
        // }

        // if (meetingData.mobileBreakpoints) {
        //     meetingData.mobileBreakpoints.widthType = blockWidthType(
        //         formData?.mobileBreakpoints?.blockWidth
        //     );
        // }

        if (meetingType === 'zoom') {
            meetingData.disableVideoControl = disableVideoControl;
            meetingData.disableVideoInformation = disableVideoInformation;
        }

        if (!data?.tabletBreakpoints?.heading) {
            setValue('tabletBreakpoints.heading', formData?.heading);
        }
        if (!data?.mobileBreakpoints?.heading) {
            setValue('mobileBreakpoints.heading', formData?.heading);
        }

        if (typeof data?.tabletBreakpoints?.headingVisibility === 'undefined') {
            setValue(
                'tabletBreakpoints.headingVisibility',
                !!meetingData.headingVisibility
            );
        }
        if (typeof data?.mobileBreakpoints?.headingVisibility === 'undefined') {
            setValue(
                'mobileBreakpoints.headingVisibility',
                !!meetingData.headingVisibility
            );
        }

        if (preventBlockSettings && meetingData?.widthType)
            delete meetingData.widthType;

        const enabledBlocks = data?.item?.enabledBlocks || data?.enabledBlocks;
        if (Array.isArray(enabledBlocks)) {
            meetingData.enabledBlocks = enabledBlocks;
        }

        if (data.id && !data?.updateBlockType) {
            meetingData.id = data.id;
            meetingData.blockVisibility = data.blockVisibility;
            meetingData.name = data?.name;
            updateSpecificBlock(meetingData, livepageId);
        } else {
            const preventBlockSettingsProps = [
                'preventBlockSettings',
                'preventBlockVisibilityChange',
                'preventChangeType',
                'preventDelete',
                'preventEdit',
            ];
            preventBlockSettingsProps.forEach((prop) => {
                const propValue = data?.item?.[prop] || data?.[prop];
                if (propValue) {
                    meetingData[prop] = propValue;
                }
            });

            if (!data?.id && !display.desktop) {
                openNotification('info', {
                    message: 'Please save desktop value first',
                });
            } else {
                if (data?.updateBlockType?.to === 'Meeting') {
                    const deleteMeetingBlock = await deleteBlock(
                        data?.id,
                        livepageId,
                        false
                    );

                    if (deleteMeetingBlock) {
                        meetingData.name = data?.name;
                        meetingData.backgroundCoverImageDisablePopup =
                            data?.backgroundCoverImageDisablePopup;
                        meetingData.backgroundCoverImageDistortion =
                            data?.backgroundCoverImageDistortion;

                        createContentBlock(meetingData, livepageId);
                    }
                } else {
                    createContentBlock(meetingData, livepageId);
                }
            }
        }
    };

    const getPeopleData = async () => {
        try {
            setPeopleLoading(true);

            const { status, data } = await getPeopleDetails(
                projectId,
                peopleSrchVal
            );

            if (status) setPeopleDetails(data);
            setPeopleLoading(false);
        } catch (error) {
            console.error(error);
            setPeopleLoading(false);
        }
    };

    const initPeopleData = async (peopleIds) => {
        try {
            const {
                status,
                data,
                message = 'Failed to get people data`',
            } = await getPeopleById(projectId, peopleIds);

            if (status) setPeopleDetails(data);
            else throw new Error(message);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (previewImage && imgErr) setImgErr(false);
    }, [previewImage]);

    useEffect(() => {
        if (
            moment(endTime).isBefore(moment(startTime)) ||
            startTime.unix() === endTime.unix()
        ) {
            setEndTime(moment(startTime).add(5, 'minute'));
        }
    }, [startTime, endTime]);

    const debouncedPeopleSearch = debounce(async () => {
        await getPeopleData();
    }, 500);

    useEffect(() => {
        if (enableModerators) debouncedPeopleSearch();
    }, [peopleSrchVal, projectId, enableModerators]);

    useEffect(() => {
        updateCustomCSS(data?.customCss || '');
        return () => {
            updateCustomCSS('');
        };
    }, []);

    useEffect(() => {
        const moderatorIds = moderators ? moderators.split(',') : null;
        if (moderatorIds) initPeopleData(moderatorIds);
    }, []);

    useEffect(() => {
        setMeetingSettings(getDefaultSettings(data.settings, meetingType));
    }, [meetingType]);

    useEffect(() => {
        setLinkedSettings((prev) => ({
            ...prev,
            ...getDefaultBlockLinkedSettings(selectedItem),
            ...(selectedItem?.id ? selectedItem?.linkedSettings : undefined),
        }));
    }, [selectedItem]);

    return (
        <div className={styles.MeetingContainer}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ContentLayout
                        itemData={data}
                        title={data.heading ? data.heading : 'Meeting'}
                        menu={data?.id ? true : false}
                        onBack={() => {
                            updateSidebarMenu(
                                data?.id ? 'default' : 'ADD_BLOCK'
                            );
                        }}
                        enabledVisibilityControl={true}
                    >
                        <div className={styles.MeetingContent}>
                            <CommonBlockSettings
                                blockName="Meeting"
                                cardType="single"
                                config={{
                                    heading: { enabled: true, required: true },
                                    coverImage: {
                                        enabled: true,
                                    },
                                    blockWidth: { enabled: true },
                                    blockHeight: {
                                        enabled: true,
                                        defValue: '550px',
                                    },
                                    fitHeightToContent: { enabled: true },
                                    boundingBox: { enabled: true },
                                    contentMargin: { enabled: true },
                                    contentPadding: { enabled: true },
                                }}
                                values={data}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors}
                                control={control}
                                linkedSettings={linkedSettings}
                                setLinkedSettings={setLinkedSettings}
                            >
                                <>
                                    <Controller
                                        as={
                                            <SettingsText
                                                headingTitle="Meeting title"
                                                tooltipText="Meeting content block title"
                                                error={errors?.title}
                                                isVisible={true}
                                                placeholder="Meeting Name"
                                                setValue={setValue}
                                                isRequired={true}
                                            />
                                        }
                                        name="title"
                                        control={control}
                                        defaultValue={data.title || ''}
                                        rules={{ required: true }}
                                    />
                                    <div className={styles.MeetingType}>
                                        <h3>
                                            Meeting Type&nbsp;
                                            <span style={{ color: '#dc3545' }}>
                                                *
                                            </span>
                                        </h3>
                                        <Select
                                            value={meetingType}
                                            onChange={setMeetingType}
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="meet">
                                                Gevme Meet
                                            </Option>
                                            <Option value="zoom">Zoom</Option>
                                        </Select>
                                    </div>
                                    {meetingSetttings.map((setting, index) => (
                                        <div
                                            key={index}
                                            className={styles.BorderedSwitch}
                                        >
                                            <SwitchComponent
                                                background="white"
                                                tooltipText={
                                                    setting?.tooltipText
                                                }
                                                label={setting.label}
                                                switchStatus={!setting.value}
                                                onChange={(e) =>
                                                    updateMeetingSettings(
                                                        e,
                                                        setting.field
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                                    {meetingType === 'meet' && (
                                        <>
                                            {showSelectImg.current && (
                                                <div
                                                    style={{
                                                        marginRight: 16,
                                                        marginLeft: 16,
                                                        marginBottom: 10,
                                                    }}
                                                    className={
                                                        styles.SettingsImage
                                                    }
                                                >
                                                    <ImageUpload
                                                        previewImages={
                                                            previewImage
                                                                ? [previewImage]
                                                                : []
                                                        }
                                                        prepareFilesUpload={(
                                                            files
                                                        ) => {
                                                            setPreviewImage(
                                                                files[0]
                                                            );
                                                        }}
                                                        handleFileDelete={(
                                                            file
                                                        ) => {
                                                            setPreviewImage(
                                                                null
                                                            );
                                                        }}
                                                        autoUpload={true}
                                                    />
                                                    {imgErr && (
                                                        <p
                                                            style={{
                                                                margin: '6px 16px',
                                                                color: 'red',
                                                                fontWeight:
                                                                    '600',
                                                            }}
                                                        >
                                                            Image not Selected
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            {/* <div
                                                className={
                                                    styles.BorderedSwitch
                                                }
                                            >
                                                <SwitchContainer
                                                    background="white"
                                                    tooltipText="Mute participants by default when they join the meeting"
                                                    label="mute participants by default"
                                                    switchStatus={muted}
                                                    onChange={toggleMuted}
                                                />
                                            </div> */}

                                            <div
                                                className={
                                                    styles.BorderedSwitch
                                                }
                                            >
                                                <SwitchContainer
                                                    background="white"
                                                    tooltipText="enable to set a start time for the meeting"
                                                    label="meeting start time"
                                                    switchStatus={
                                                        enableStartTime
                                                    }
                                                    onChange={(e) => {
                                                        toggleStartTime(e);
                                                        if (!e)
                                                            toggleEndTime(
                                                                false
                                                            );
                                                    }}
                                                >
                                                    {enableStartTime && (
                                                        <div
                                                            className={
                                                                styles.SwitchContent
                                                            }
                                                        >
                                                            <DatePicker
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                showTime
                                                                value={startTime.local()}
                                                                allowClear={
                                                                    false
                                                                }
                                                                onChange={(
                                                                    start
                                                                ) => {
                                                                    if (
                                                                        start.isBefore(
                                                                            moment()
                                                                        )
                                                                    )
                                                                        setStartTime(
                                                                            moment()
                                                                        );
                                                                    else
                                                                        setStartTime(
                                                                            start
                                                                        );
                                                                }}
                                                                disabledDate={(
                                                                    current
                                                                ) =>
                                                                    current <
                                                                    moment().startOf(
                                                                        'day'
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </SwitchContainer>
                                            </div>
                                            <div
                                                className={
                                                    styles.BorderedSwitch
                                                }
                                            >
                                                <SwitchContainer
                                                    background="white"
                                                    tooltipText="enable to set an end time for the meeting"
                                                    label="meeting end time"
                                                    switchStatus={
                                                        enableEndTime &&
                                                        enableStartTime
                                                    }
                                                    onChange={toggleEndTime}
                                                    disabled={!enableStartTime}
                                                >
                                                    {enableEndTime &&
                                                        enableStartTime && (
                                                            <div
                                                                className={
                                                                    styles.SwitchContent
                                                                }
                                                            >
                                                                <DatePicker
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    showTime
                                                                    value={endTime.local()}
                                                                    allowClear={
                                                                        false
                                                                    }
                                                                    onChange={
                                                                        setEndTime
                                                                    }
                                                                    disabledDate={(
                                                                        current
                                                                    ) =>
                                                                        current <
                                                                        startTime
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </SwitchContainer>
                                            </div>
                                            <div
                                                className={
                                                    styles.BorderedSwitch
                                                }
                                            >
                                                <SwitchContainer
                                                    background="white"
                                                    tooltipText="enable to add moderators to the meeting"
                                                    label={`moderators${
                                                        moderators &&
                                                        enableModerators &&
                                                        moderators.split(',')
                                                            .length > 0
                                                            ? ` (${
                                                                  moderators.split(
                                                                      ','
                                                                  ).length
                                                              })`
                                                            : ''
                                                    }`}
                                                    switchStatus={
                                                        enableModerators
                                                    }
                                                    onChange={toggleModerators}
                                                >
                                                    {enableModerators && (
                                                        <div
                                                            className={
                                                                styles.SwitchContent
                                                            }
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                placeholder="Select moderators (max 10)"
                                                                showSearch
                                                                onSearch={
                                                                    setPeopleSrchVal
                                                                }
                                                                loading={
                                                                    peopleLoading
                                                                }
                                                                filterOption={
                                                                    false
                                                                }
                                                                notFoundContent={
                                                                    peopleLoading ? (
                                                                        <Spin size="small" />
                                                                    ) : null
                                                                }
                                                                onChange={(
                                                                    val
                                                                ) => {
                                                                    if (
                                                                        !val ||
                                                                        val.length ===
                                                                            0
                                                                    )
                                                                        setModerators(
                                                                            ''
                                                                        );
                                                                    else {
                                                                        if (
                                                                            val.length >
                                                                            MODERATORS_LIMIT
                                                                        )
                                                                            val.length =
                                                                                MODERATORS_LIMIT;

                                                                        setModerators(
                                                                            val.join()
                                                                        );
                                                                    }
                                                                }}
                                                                value={
                                                                    moderators.length >
                                                                    0
                                                                        ? moderators.split(
                                                                              ','
                                                                          )
                                                                        : []
                                                                }
                                                                optionLabelProp="label"
                                                            >
                                                                {peopleDetails.map(
                                                                    (
                                                                        {
                                                                            firstname,
                                                                            lastname,
                                                                            id,
                                                                        },
                                                                        index
                                                                    ) => (
                                                                        <Option
                                                                            label={`${firstname} ${lastname}`}
                                                                            key={
                                                                                id
                                                                            }
                                                                            value={
                                                                                id
                                                                            }
                                                                        >{`${firstname} ${lastname}`}</Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </SwitchContainer>
                                            </div>
                                            <div
                                                className={`${styles.BorderedSwitch} switch-wrapper-stream`}
                                            >
                                                <SwitchComponent
                                                    label="Allow member's to mute each other"
                                                    switchStatus={allowMute}
                                                    onChange={(e) =>
                                                        setallowMute(e)
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                    {meetingType === 'zoom' && (
                                        <>
                                            <Controller
                                                as={
                                                    <SettingsText
                                                        headingTitle="Meeting Id"
                                                        isVisible={true}
                                                        setValue={setValue}
                                                        isRequired={true}
                                                        error={errors?.videoId}
                                                    />
                                                }
                                                name="videoId"
                                                control={control}
                                                defaultValue={
                                                    data?.videoId
                                                        ? data?.videoId
                                                        : data?.settings
                                                              ?.videoId
                                                }
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            <Controller
                                                as={
                                                    <SettingsText
                                                        headingTitle="Meeting Password"
                                                        isVisible={true}
                                                        setValue={setValue}
                                                        isRequired={true}
                                                        error={
                                                            errors?.videoPassword
                                                        }
                                                    />
                                                }
                                                name="videoPassword"
                                                control={control}
                                                defaultValue={
                                                    data?.videoPassword
                                                        ? data?.videoPassword
                                                        : data?.settings
                                                              ?.videoPassword
                                                }
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            <div
                                                className={`${styles.BorderedSwitch} switch-wrapper-stream`}
                                            >
                                                <SwitchComponent
                                                    label="Disable Meeting Controls"
                                                    switchStatus={
                                                        disableVideoControl
                                                    }
                                                    onChange={(e) =>
                                                        setDisableVideoControl(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`${styles.BorderedSwitch} switch-wrapper-stream`}
                                            >
                                                <SwitchComponent
                                                    label="Disable Meeting Info"
                                                    switchStatus={
                                                        disableVideoInformation
                                                    }
                                                    onChange={(e) =>
                                                        setDisableVideoInformation(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            </CommonBlockSettings>
                        </div>
                    </ContentLayout>
                    <BlockFooter onCancel={handleCancel} disabledSave={false} />
                </form>
            </FormProvider>
            <style>
                {`
                    span.anticon.anticon-info-circle
                    {
                        margin-left:4px
                    }
                    .ant-switch-checked {
                        background: #0b1c2c;
                    }
                    .ant-select-selection-placeholder
                    {
                        font-size:12px;
                    }
                    `}
            </style>
        </div>
    );
};

export default MeetingBlock;
