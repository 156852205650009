import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import copy from 'copy-to-clipboard';

const browserImageSize = require('browser-image-size');

/**
 * converts string to boolean
 * @param {string} value string to be changed to boolean
 */
export const stringToBoolean = (value) => {
    if (value && typeof value === 'string') {
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
    }

    return value;
};

/**
 * extracts only the property indicated from an array to another array
 * @param {array} arr array to extract properties from
 * @param {string} property property to be extracted
 */
export const extractPropFromArr = (arr, property) => {
    return arr.map((arrItem) => {
        return arrItem[property];
    });
};

/**
 * looks for a property in an array of objects
 * @param {object} mainArrOfObj
 * @param {object} subArrOfObj
 * @param {string} objectKey
 * @param {string} propertyToCompare
 */
export const findNestedPropertyInArrayOfObjects = (
    mainArrOfObj,
    objectKey,
    subArrOfObj,
    propertyToCompare
) => {
    return mainArrOfObj.filter((object) => {
        return object[objectKey].some((object) => {
            return object[propertyToCompare] === subArrOfObj[propertyToCompare];
        });
    });
};

/**
 * finds an object in an array of objects depending on specified key-value pair
 * @param {array} arrayToBeSearched array of objects to search into
 * @param {string} key key to use to find object
 * @param {string} value search value
 */
export const findObjectByKeyName = (arrayToBeSearched, key, value) => {
    return arrayToBeSearched.find((object) => {
        return object[key] === value;
    });
};

/**
 * converts a text into a slug
 * @param {string} text text to convert into a slug
 */
export const convertToSlug = (text) => {
    return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
};

/**
 * converts a camel-cased string into separate words then capitalise it
 * @param {string} text camel-cased string that needs to be separated
 */
export const separateCamelCase = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};

/**
 * determines what upload URL to use
 * "local" environment needs to have the domain in the URL (it's using `{domain}/uploads` path)
 * "production/staging/development" on the other hand uses a separate AWS S3 link so no need to append domain at the beginning
 * @param {string} path upload link / path
 */
export const uploadsUrlToUse = (path) => {
    const currEnv = process.env.NODE_ENV;

    if (currEnv === 'local') {
        return `${process.env.REACT_APP_API_URL}${path}`;
    } else {
        return `${path}`;
    }
};

/**
 * masks email
 * @param {string} email email to be masked
 */
export const maskEmail = (email) => {
    const mask = (str) => {
        const strLen = str.length;
        if (strLen > 4) {
            return (
                str.substr(0, 1) +
                str.substr(1, strLen - 1).replace(/\w/g, '*') +
                str.substr(-1, 1)
            );
        }
        return str.replace(/\w/g, '*');
    };

    return email.replace(
        /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
        function (m, p1, p2, p3) {
            return mask(p1) + '@' + mask(p2) + p3;
        }
    );
};

/**
 * removes a string of text from another string
 * @param {string} originalStr string to remove text from
 * @param {string} strToRemove string to be removed
 */
export const textRemover = (originalStr, strToRemove) => {
    let finalString = '';

    if (originalStr.includes(strToRemove)) {
        finalString = originalStr.replace(strToRemove, '');
    } else {
        finalString = originalStr;
    }

    return finalString;
};

/**
 * extracts initials from a text
 * @param {string} text text to get initials from
 */
export const extractInitials = (text) => {
    return text
        .split(' ')
        .map((n) => n[0])
        .join('');
};

/**
 * capitalises a string
 * @param {string} text text to capitalise
 */
export const capitalise = (text) => {
    return text[0].toUpperCase() + text.slice(1);
};

/**
 * transforms an array of objects into a select option object format
 * @param {array} arrToTransform array of objects that needs to be transformed
 * @param {string} label label to use for select option
 * @param {string} value value to use for select option
 * @param {string} key key to use for select option
 */
export const transformArrIntoSelectOption = (
    arrToTransform,
    label,
    value,
    key
) => {
    return arrToTransform.map((arrayItem) => {
        return {
            label: arrayItem[label],
            value: arrayItem[value],
            key: arrayItem[key],
        };
    });
};

/**
 * sorts an object alphabetically depending on specified sorting property
 * @param {object} objectToSort object to be sorted
 * @param {string} sortingProp property to use for sorting (e.g. name)
 */
export const sortObjectAlphabetically = (objectToSort, sortingProp) => {
    return objectToSort.sort((a, b) =>
        a[sortingProp].localeCompare(b[sortingProp])
    );
};

/**
 * extracts the string after the last instance of a character
 * @param {string} stringToExtractFrom string to extract from
 * @param {string} lastCharacter extract string after this character
 */
export const extractStringAfterLastCharacter = (
    stringToExtractFrom,
    lastCharacter
) => {
    const parts = stringToExtractFrom.split(lastCharacter);

    return parts[parts.length - 1];
};

/**
 * copies text to clipboard
 * @param {}
 * @param {}
 * @param {}
 */
export const copyToClipboard = async (e, textToCopy, showAlert) => {
    e.preventDefault();
    // showAlert('Link copied to clipboard.', 'success');
    openNotification('success', { message: 'Copied to clipboard' });
    copy(textToCopy);
};

/**
 * strips the "__typename" property from the graphQL response
 * @param {object} value object to be stripped off of "__typename"
 * @todo find a better way to do this through apollo set-up
 */
export const stripTypenames = (value) => {
    if (Array.isArray(value)) {
        return value.map(this.stripTypenames);
    } else if (value !== null && typeof value === 'object') {
        const newObject = {};
        for (const property in value) {
            if (property !== '__typename') {
                newObject[property] = stripTypenames(value[property]);
            }
        }
        return newObject;
    } else {
        return value;
    }
};

/**
 * converts values in an object into another value (recursive since it should cater for nested objects)
 * @param {obj} obj object that needs to have some values replaced
 * @param {*} target value to be replaced
 * @param {*} replacement replacement value
 */
export const convertObjectValuesRecursive = (obj, target, replacement) => {
    obj = { ...obj };

    Object.keys(obj).forEach((key) => {
        if (obj[key] === target) {
            obj[key] = replacement;
        } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            obj[key] = convertObjectValuesRecursive(
                obj[key],
                target,
                replacement
            );
        }
    });

    return obj;
};

/**
 * generate a random string without spaces
 * @param radix
 */
export const getRandomString = (radix = 36) => {
    return (
        Math.random().toString(radix).substring(2, 8) +
        Math.random().toString(radix).substring(2, 8)
    );
};

/**
 *
 * @param {*} file
 */
export const renameFile = (file) => {
    let fileExtension = file.name.split('.').pop(),
        blob = file.slice(0, file.size, file.type);

    return new File([blob], getRandomString() + '.' + fileExtension, {
        type: file.type,
    });
};

/**
 * gets initials from full name
 * @param {*} user
 */
export const getNameInitials = (user) => {
    return (
        user?.firstname?.trim()?.substring(0, 1) +
        user?.lastname?.trim()?.substring(0, 1)
    );
};

/**
 * Will create name initials - enhanced
 * @param personArr {Array} - can accept single arr or multiple arrs
 *
 * => ['Hugh Caluscsuin'] will return 'HJ'
 * => ['Ava Guerrero', 'Vin Diesel'] will return ['AG', 'VD'];
 *
 * If single name was passed ['Hugh'], this will return 'H'
 * Even if three, ['Hugh Grant ThirdName'], this will return 'HG' just first two
 * @returns {string|null|*}
 */
export const createNameInitials = (persons) => {
    if (persons.length === 0) return null;

    const initialiser = (str) => {
        if (str === undefined) return '';
        return str.trim().toUpperCase().substring(0, 1);
    };

    if (persons.length < 2) {
        const [fname, lname] = persons[0].trim().replace('  ', ' ').split(' ');
        const onePersonInitial = `${initialiser(fname)}${initialiser(lname)}`;
        return onePersonInitial.trim();
    }

    const allIntials = [];
    persons.map((p) => {
        const [fname, lname] = p.trim().replace('  ', ' ').split(' ');
        allIntials.push(`${initialiser(fname)}${initialiser(lname)}`.trim());
    });
    return allIntials;
};

/**
 * combines first name and last name
 * @param {*} user
 */
export const getFullname = (user) => {
    return user.firstname + ' ' + user.lastname;
};

/**
 * checks the image dimension
 * if width / height exceeds recommended width / height then returns `false`
 * @param {*} imageFile
 * @param {string} recommendedHeight
 * @param {string} recommendedWidth
 */
export const isValidDimension = async (
    imageFile,
    recommendedWidth,
    recommendedHeight
) => {
    if (imageFile) {
        return await browserImageSize(imageFile).then((size) => {
            return (
                size.height <= recommendedHeight &&
                size.width <= recommendedWidth
            );
        });
    }

    /**
     * If there is no image, then just send `true`
     * so that the error message will not trigger.
     */
    return true;
};

export const isDimensionInRange = async (
    imageFile,
    minResolution = { width: 0, height: 0 },
    maxResolution = { width: 0, height: 0 }
) => {
    if (imageFile) {
        return await browserImageSize(imageFile).then((size) => {
            return (
                size.height >= minResolution.height &&
                size.height <= maxResolution.height &&
                size.width >= minResolution.width &&
                size.width <= maxResolution.width
            );
        });
    }

    return true;
};
