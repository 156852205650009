/* eslint-disable max-len */
import React from 'react';

function GevmeLogoLandscape() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="28"
            fill="none"
            viewBox="0 0 112 28"
        >
            <path
                fill="#000"
                d="M60.358 20.868c2.603 0 3.974-1.209 4.404-1.784l.353 1.515h1.625v-7.404h-5.345v1.995h2.976c-.118 1.592-1.155 3.51-3.896 3.51-2.721 0-4.58-2.071-4.58-4.814 0-2.628 1.84-4.815 4.541-4.815 1.997 0 3.034.998 3.563 2.321l2.251-.767c-.802-2.072-2.623-3.721-5.931-3.721-3.994 0-6.813 3.05-6.813 6.982 0 4.047 2.78 6.982 6.852 6.982zM77.235 16.571v-.882c0-2.916-1.781-4.93-4.62-4.93-2.78 0-4.933 2.149-4.933 5.045 0 2.954 2.153 5.064 4.953 5.064 2.388 0 3.798-1.228 4.365-2.762l-1.977-.595c-.235.652-1.018 1.458-2.388 1.458-1.547 0-2.604-1.19-2.702-2.398h7.302zm-2.075-1.765h-5.207c.215-1.093 1.096-2.148 2.682-2.148 1.683 0 2.466 1.285 2.525 2.148zM84.436 11.028l-2.525 6.963-2.526-6.963h-2.408l3.7 9.571h2.467l3.7-9.571h-2.408zM87.318 20.6h2.35v-5.43c0-1.534.841-2.282 1.898-2.282.98 0 1.684.557 1.684 2.014V20.6h2.35v-5.428c0-1.515.821-2.283 1.898-2.283 1.018 0 1.664.557 1.664 2.014V20.6h2.349v-6.33c0-2.455-1.331-3.51-3.151-3.51-1.508 0-2.526.672-3.133 1.439-.391-.748-1.174-1.439-2.643-1.439-1.39 0-2.427.633-3.014 1.4l-.314-1.131h-1.938v9.571zM111.999 16.571v-.882c0-2.916-1.781-4.93-4.62-4.93-2.78 0-4.933 2.149-4.933 5.045 0 2.954 2.153 5.064 4.952 5.064 2.389 0 3.798-1.228 4.366-2.762l-1.977-.595c-.235.652-1.018 1.458-2.389 1.458-1.546 0-2.603-1.19-2.701-2.398h7.302zm-2.075-1.765h-5.207c.215-1.093 1.096-2.148 2.681-2.148 1.684 0 2.467 1.285 2.526 2.148z"
            />
            <path
                fill="#FADA30"
                fillRule="evenodd"
                d="M.308 1.57c-.314.616-.314 1.422-.314 3.033v9.205c0 1.611 0 2.417.314 3.032.275.541.716.982 1.257 1.257.615.314 1.42.314 3.032.314h6.896V13.756c0-.761 0-1.412.044-1.947.046-.562.147-1.114.416-1.643a4.219 4.219 0 011.844-1.843c.529-.27 1.08-.37 1.643-.417.535-.043 1.185-.043 1.946-.043h15.019v-3.26c0-1.611 0-2.417-.313-3.032A2.877 2.877 0 0030.834.314C30.22 0 29.414 0 27.802 0H4.597C2.986 0 2.18 0 1.565.314A2.877 2.877 0 00.308 1.57zm32.097 7.635H17.44c-1.611 0-2.417 0-3.032.314a2.877 2.877 0 00-1.258 1.257c-.313.615-.313 1.42-.313 3.032v4.603h14.966c1.611 0 2.417 0 3.032-.314a2.877 2.877 0 001.258-1.257c.313-.615.313-1.42.313-3.032V9.205z"
                clipRule="evenodd"
            />
            <path
                fill="url(#paint0_linear_21262_3445)"
                fillRule="evenodd"
                d="M.308 1.57c-.314.616-.314 1.422-.314 3.033v9.205c0 1.611 0 2.417.314 3.032.275.541.716.982 1.257 1.257.615.314 1.42.314 3.032.314h6.896V13.756c0-.761 0-1.412.044-1.947.046-.562.147-1.114.416-1.643a4.219 4.219 0 011.844-1.843c.529-.27 1.08-.37 1.643-.417.535-.043 1.185-.043 1.946-.043h15.019v-3.26c0-1.611 0-2.417-.313-3.032A2.877 2.877 0 0030.834.314C30.22 0 29.414 0 27.802 0H4.597C2.986 0 2.18 0 1.565.314A2.877 2.877 0 00.308 1.57zm32.097 7.635H17.44c-1.611 0-2.417 0-3.032.314a2.877 2.877 0 00-1.258 1.257c-.313.615-.313 1.42-.313 3.032v4.603h14.966c1.611 0 2.417 0 3.032-.314a2.877 2.877 0 001.258-1.257c.313-.615.313-1.42.313-3.032V9.205z"
                clipRule="evenodd"
            />
            <rect
                width="32.411"
                height="18.411"
                x="12.846"
                y="9.206"
                fill="#FADA30"
                rx="2.877"
            />
            <path
                fill="url(#paint1_linear_21262_3445)"
                fillRule="evenodd"
                d="M32.42 9.206v4.602c0 1.611 0 2.417-.313 3.032a2.877 2.877 0 01-1.257 1.258c-.615.313-1.42.313-3.032.313H12.852v-4.603c0-1.61 0-2.416.313-3.032a2.877 2.877 0 011.257-1.257c.616-.313 1.421-.313 3.032-.313h14.967z"
                clipRule="evenodd"
                opacity="0.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_21262_3445"
                    x1="0.032"
                    x2="32.388"
                    y1="0.011"
                    y2="18.61"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF2CFF" />
                    <stop offset="1" stopColor="#FF0C1B" stopOpacity="0.2" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21262_3445"
                    x1="0.036"
                    x2="32.398"
                    y1="0.011"
                    y2="18.621"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF2CFF" />
                    <stop offset="1" stopColor="#FF0C1B" stopOpacity="0.2" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default GevmeLogoLandscape;
