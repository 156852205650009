import { gql } from 'apollo-boost';

const SHOWCASEBLOCK = gql`
    query Showcaseblock($id: ID!) {
        showcaseblock(id: $id) {
            id
            name
            type
            url
            url2
            category
            textContent
            contentPadding
            showContentMobile
            pdf {
                id
                url
                name
            }
            title
            largeTile
            image {
                id
                url
                name
            }
            socialLinks
        }
    }
`;

export default SHOWCASEBLOCK;
