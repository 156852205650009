import React from 'react';

export default function LogoutButton() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_17_28116)">
                <path
                    d="M9.99976 3.75V10"
                    stroke="black"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.7498 4.375C15.631 5.60156 16.8748 7.58672 16.8748 10C16.8748 11.8234 16.1504 13.572 14.8611 14.8614C13.5718 16.1507 11.8231 16.875 9.99976 16.875C8.17639 16.875 6.42771 16.1507 5.1384 14.8614C3.84908 13.572 3.12476 11.8234 3.12476 10C3.12476 7.58672 4.36851 5.60156 6.24976 4.375"
                    stroke="black"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_17_28116">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
