import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import './userAccountStatus.scss';

function UserAccountStatus(props) {
    const { setModalVisibility, setModalView, leadsQuota, totalLeadsQuota } =
        props;

    const calculateInvitesUsed = () => {
        return (leadsQuota / totalLeadsQuota) * 100;
    };

    return (
        <div className="user-account-status">
            <div className="row">
                <div className="user-account-status__bar">
                    <div
                        className="user-account-status__progress"
                        style={{ width: `${calculateInvitesUsed()}%` }}
                    />
                </div>
            </div>
            {/* <div className="col-12 col-md-4 col-lg-3">
          <button
            className="btn btn-secondary"
            onClick={() => {
              setModalVisibility(true);
              setModalView("buyAccounts");
            }}
          >
            Buy More User Accounts
          </button>
        </div> */}
            <p className="tw-text-text-gray-dark md:tw-text-black tw-text-xssm md:tw-text-sm tw-text-center md:tw-text-left">
                You are using{' '}
                <strong>
                    {leadsQuota} out of {totalLeadsQuota}
                </strong>{' '}
                user accounts that you purchased.
            </p>
        </div>
    );
}

UserAccountStatus.propTypes = {
    setModalVisibility: PropTypes.func.isRequired,
    setModalView: PropTypes.func.isRequired,
    leadsQuota: PropTypes.number.isRequired,
    totalLeadsQuota: PropTypes.number.isRequired,
};

export default withRouter(UserAccountStatus);
