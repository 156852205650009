import React, { useState, useEffect } from 'react';
import * as ls from 'local-storage';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import WizardLayout from 'components/app/common/wizardLayout';
import FilePondUpload from 'components/app/common/filePondUpload';
import isNull from 'lodash/isNull';
import { FILE_TYPES } from 'components/app/utils/fileTypes';

import {
    ImportSpeaker,
    downloadSampleSpeakers,
} from 'apis/rest/speakers/importSpeaker';
import PageSpinner from 'components/app/common/pageSpinner';

import './speakerImport.scss';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Papa from 'papaparse';
import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import FileSaver from 'file-saver';

const SpeakerImport = () => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const isSponsor = !!ls.get('sponsorAuth');
    const activeApp =
        location?.state?.activeApp || location?.pathname?.split('/')[6];
    const pathname = isSponsor
        ? `/company/${companyId}/project/${projectId}/agenda#speakers`
        : `/company/${companyId}/project/${projectId}/dashboard/content/programme/speakers`;

    const [uploadedFile, setUploadedFile] = useState(null);
    const [nextEnabledCondition, setNextEnabledCondition] = useState(false);
    const [isUploadingFile, setUploadingStats] = useState(false);

    const handleUploadFile = async () => {
        setUploadingStats(true);
        const inputs = {
            file: uploadedFile,
        };
        try {
            const response = await ImportSpeaker(inputs, projectId);
            if (response.status) {
                if (response?.info) {
                    openNotification('info', {
                        message: response?.message || 'Speakers Imported!',
                    });
                } else {
                    openNotification('success', {
                        message: response?.message || 'Speakers Imported!',
                    });
                }
                history.push(`${pathname}`, { activeApp });
            } else {
                openNotification('error', { message: response.message });
            }
            setUploadingStats(false);
        } catch (error) {
            console.error('ERROR handleUploadFile', error);
            setUploadingStats(false);
        }
    };

    const handleDownloadSampleCSV = async (e) => {
        try {
            e.preventDefault();
            const res = await downloadSampleSpeakers(projectId);
            const file = new File([res], `import-speakers-${projectId}.csv`, {
                type: 'text/csv;charset=utf-8',
            });
            FileSaver.saveAs(file);
        } catch (error) {
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        }
    };

    useEffect(() => {
        if (!isNull(uploadedFile)) {
            Papa.parse(uploadedFile, {
                delimiter: '',
                complete({ data }) {
                    setTimeout(() => {
                        setNextEnabledCondition(true);
                    }, 2000);
                },
            });
        } else {
            setNextEnabledCondition(false);
        }
    }, [uploadedFile]);

    return isSponsor ? (
        <Layout background="#F7F9FA" className="tw-p-0">
            <div className="container">
                <WizardLayout
                    name="Import Speakers"
                    cancelAction={() => history.push(`${pathname}`)}
                    nextAction={() => handleUploadFile()}
                    nextEnabledCondition={
                        nextEnabledCondition && !isUploadingFile
                    }
                    cancelEnabledCondition={isUploadingFile}
                >
                    {isUploadingFile && (
                        <PageSpinner
                            className="z1"
                            type="Oval"
                            color="#ACBDC9"
                            height={40}
                            width={40}
                        />
                    )}
                    <div className="upload-people-file tw-mx-auto tw-text-center">
                        <div className="upload-people-file__wrap tw-mx-auto">
                            <h1>Upload Your File</h1>
                            <p className="upload-people-file__intro">
                                You can import CSV files.
                                <br />
                                <a
                                    href=""
                                    target="_blank"
                                    onClick={handleDownloadSampleCSV}
                                >
                                    <img
                                        src="/assets/icon_download_black.svg"
                                        alt=""
                                    />{' '}
                                    Download a sample file{' '}
                                </a>
                            </p>
                            <FilePondUpload
                                currentFile={uploadedFile}
                                uploadFile={(file) => setUploadedFile(file)}
                                fileType={FILE_TYPES.CSV}
                                localServer
                            />
                        </div>
                    </div>
                </WizardLayout>
            </div>
            <SidebarContainer>
                <MainMenu priActive="agenda" all />
            </SidebarContainer>
        </Layout>
    ) : (
        <div className="container2" style={{ position: 'static' }}>
            <WizardLayout
                name="Import Speakers"
                cancelAction={() => history.push(`${pathname}`)}
                nextAction={() => handleUploadFile()}
                nextEnabledCondition={nextEnabledCondition && !isUploadingFile}
                cancelEnabledCondition={isUploadingFile}
            >
                {isUploadingFile && (
                    <PageSpinner
                        className="z1"
                        type="Oval"
                        color="#ACBDC9"
                        height={40}
                        width={40}
                    />
                )}
                <div className="upload-people-file tw-mx-auto tw-text-center">
                    <div className="upload-people-file__wrap tw-mx-auto">
                        <h1>Upload Your File</h1>
                        <p className="upload-people-file__intro">
                            You can import CSV files.
                            <br />
                            <a
                                href=""
                                target="_blank"
                                onClick={handleDownloadSampleCSV}
                            >
                                <img
                                    src="/assets/icon_download_black.svg"
                                    alt=""
                                />{' '}
                                Download a sample file{' '}
                            </a>
                        </p>
                        <FilePondUpload
                            currentFile={uploadedFile}
                            uploadFile={(file) => setUploadedFile(file)}
                            fileType={FILE_TYPES.CSV}
                            localServer
                        />
                    </div>
                </div>
            </WizardLayout>
        </div>
    );
};

SpeakerImport.propTypes = {};

SpeakerImport.defaultProps = {};

export default SpeakerImport;
