import React, { useState, useRef } from 'react';

const Search = ({
    handleSearch,
    searchValue,
    placeholderText = '',
    searchRef,
    disabled,
}) => {
    const projectSearchRef = useRef('');

    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [projectSearchWidth, setProjectSearchWidth] = useState('132px');

    const handleInputSearchEvent = (width, status) => {
        setProjectSearchWidth(width);
        setSearchIsFocused(status);
    };

    return (
        <div className="project-search tw-flex tw-items-center tw-gap-2 tw-w-full md:tw-w-auto">
            <input
                value={searchValue}
                placeholder={placeholderText}
                ref={searchRef || projectSearchRef}
                type="text"
                className="form-control m-0 tw-w-full"
                onChange={(e) => handleSearch(e.target.value)}
                onFocus={() => handleInputSearchEvent('210px', true)}
                onBlur={() => handleInputSearchEvent('132px', false)}
                disabled={disabled}
            />
            <i
                className={`fa ${
                    searchValue === '' && !searchIsFocused
                        ? 'fa-search'
                        : 'fa-close'
                }`}
                onClick={() => {
                    searchValue === '' && !searchIsFocused
                        ? handleInputSearchEvent('132px', false)
                        : handleSearch('');
                }}
            >
                {' '}
            </i>
        </div>
    );
};

export default Search;
