import { isURL } from "validator";

const showcaseblockValidationCriteria = input => {
  const formErrors = {};

  let error = "";

  if (!input.title) {
    formErrors.title = "Please enter a title.";
  } else {
    formErrors.title = "";
  }

  // formErrors.image = !input.image ? "Please upload image(s)." : "";

  switch (input.type) {
    case "Video":
      if (input.url) {
        if (isURL(input.url)) {
          error = "";
        } else {
          error = "Please enter a correct URL format.";
        }
      } else {
        // error = "Please enter a video URL.";
      }
      formErrors.url = error;
      break;
    // case "Pdf":
    //   formErrors.pdf = !input.pdf ? "Please upload a PDF." : "";
    //   break;
    case "Post":
      formErrors.textContent =
        !input.textContent || input.textContent.toString("markdown").length <= 2
          ? "Please enter a rich text content."
          : "";
      break;
    default:
      break;
  }

  return formErrors;
};

export default showcaseblockValidationCriteria;
