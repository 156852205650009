import * as React from "react"

const PDFIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.699 10.284l-6.992-6.991-.031-.028-.04-.037-.03-.022-.05-.037-.023-.014a1.025 1.025 0 00-.06-.036l-.018-.009a1 1 0 00-.071-.034l-.012-.004a.988.988 0 00-.08-.029c-.005 0-.009-.002-.013-.003a.99.99 0 00-.082-.02l-.027-.005A.989.989 0 0019 3H6.999a2.002 2.002 0 00-2 2v22a2.002 2.002 0 002 2H25a2.002 2.002 0 002-2V11.02l.001-.02a.995.995 0 00-.302-.716zM19 11V5.414L24.586 11H19z"
        fill="#7A829D"
      />
    </svg>
  )
}

export default PDFIcon
