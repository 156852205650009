import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @note this is a legacy form validator, use `useValidator` instead to validate an input object
 */
const useFormValidator = ({ input, type, validationCriteria }) => {
  const [formErrors, setFormErrors] = useState({});

  /**
   * renders error text for a field
   * @param {string} name name of the field that has error
   */
  const renderFieldError = name => {
    if (formErrors[name]) {
      return (
        <small className="text-error">
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="mr-1"
            color="#dc3545"
          />{" "}
          {formErrors[name]}
        </small>
      );
    }
  };

  /**
   * returns true if there are still errors in formErrors state
   * @param {object} formErrors object that contains the form errors
   */
  const hasErrors = formErrors => {
    return Object.keys(formErrors).some(key => {
      return formErrors[key] !== "";
    });
  };

  /**
   * reset form erros
   */
  const resetFormErrors = () => {
    setFormErrors({});
  };

  /**
   * validates content input
   */
  const validateInput = () => {
    const formErrors = validationCriteria(input, type);

    /**
     * need to save error to local state so that it can be used by other functions
     * but also need to have a non-stateful version of `formErrors` as setting
     * state doesn't immediately return the latest value and becomes problematic
     * during `onSubmit()`
     */
    setFormErrors(formErrors);

    return formErrors;
  };

  return {
    hasErrors,
    resetFormErrors,
    validateInput,
    renderFieldError
  };
};

export default useFormValidator;
