import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function CreateChannel(projectId, inputs) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/channels?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(inputs),
        }
    );
    return await response.json();
}
