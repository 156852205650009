import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './empty.scss';

const defaultIcon = () => {
    return <div className="empty-component-rectangle" />;
};

const Empty = ({
    iconPath,
    iconWidth,
    title,
    description,
    callToActionText,
    route,
    titleColor,
    contentWidth,
    onPress,
    disabled
}) => {
    return (
        <div className="empty-component tw-overflow-y-scroll">
            {iconPath && (
                <img
                    style={{ width: `${iconWidth}px` || 'inherit' }}
                    className="empty-component__icon"
                    src={iconPath}
                />
            )}
            <p className="empty-component-title" style={{ color: titleColor }}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </p>
            <p className="empty-component-sub" style={{ width: contentWidth }}>
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </p>
            {callToActionText && !onPress && (
                <Link to={route} className={`myxp btn btn-primary large ${disabled ? 'disabled' : ''}`}>
                    {callToActionText}
                </Link>
            )}
            {callToActionText && onPress && (
                <div onClick={onPress} className="myxp btn btn-primary large">
                    {callToActionText}
                </div>
            )}
        </div>
    );
};

export default withRouter(Empty);
