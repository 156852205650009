import { useDispatch } from 'react-redux';

import * as act from 'redux/actions/manager/uiActionsManager';

const useAlertBar = () => {
    const dispatch = useDispatch();

    /**
     * shows what type of message to render and triggers alert visibility
     * @param {string} message message to be shown
     * @param {string} alertType type of alert (success or error)
     * @param {boolean} autoClose, will auto close notif or not
     */
    const showAlertBar = (message, alertType, autoClose = true) => {
        dispatch(act.showAlert({ message, type: alertType }));

        if (autoClose) {
            const visibleTimer = alertType === 'success' ? 2000 : 5000;

            setTimeout(() => {
                dispatch(act.hideAlert());
            }, visibleTimer);
        }
    };

    return {
        showAlertBar,
    };
};

export default useAlertBar;
