import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ls from 'local-storage';
import { LoadingOutlined } from '@ant-design/icons';

import { getUser } from 'apis/rest/User';
import * as act from 'redux/actions/common/userActions';
import { changeProject } from 'redux/actions';

import { handleLogout } from '../../ProjectDashboard/utils/handleLogout';
import styles from 'components/app/views/login/index.module.scss';

export default function TwoFAWrapper({
    location = {},
    className = '',
    Component = null,
}) {
    const { payload, identifier, callbackUrl } = location;
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [isLoading, setIsLoading] = useState(true);

    async function handleGoToLogin() {
        try {
            setIsLoading(true);
            await handleLogout(!!user?.email);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            history.push('/login');
        }
    }

    async function handleGoToDashboard() {
        try {
            setIsLoading(true);

            await handleGetUser();
            checkUserRole();

            if (callbackUrl) {
                history.push(callbackUrl);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function handleGetUser() {
        const user = await getUser();
        const project = user.user.projects[0];

        dispatch(act.setUser(user));

        const {
            firstname,
            lastname,
            email,
            company,
            id,
            companyId,
            position,
            role,
            isVerified,
        } = user?.user;

        const userDetails = {
            email,
            firstname,
            lastname,
            company,
            id,
            companyId,
            position,
            isVerified,
            roleName: role?.name,
        };

        ls('userDetails', userDetails);
        ls('project', project);

        dispatch(changeProject(project));
    }

    function checkUserRole() {
        if (user.roleId === 4) {
            history.push('/sponsor');
        } else if (user.roleId === 1) {
            setTimeout(() => {
                history.push('/admin/dashboard');
            }, 500);
        } else {
            const customProjectUrl = ls.get('customProjectUrl');
            if (customProjectUrl) {
                window.location.href = `${customProjectUrl}`;
                ls.remove('customProjectUrl');
            } else {
                window.location.href = '/';
            }
        }
    }

    useEffect(() => {
        if (payload) {
            setIsLoading(false);
            return;
        }
        if (user?.email) {
            checkUserRole();
        } else {
            handleGoToLogin();
        }
    }, [payload]);

    const getLoadingScreen = () => (
        <div className="tw-my-auto">
            <LoadingOutlined style={{ scale: '5' }} />
        </div>
    );

    return (
        <div className={styles.login}>
            <div className={styles.wrapper}>
                <div className={styles.innerWrapper}>
                    <div
                        className={`tw-mx-auto tw-w-full tw-h-auto tw-min-h-[535px] tw-p-12 tw-flex tw-flex-col tw-justify-start tw-items-center ${className}`}
                    >
                        {Component ? (
                            <Component
                                payload={payload}
                                identifier={identifier}
                                handleGoToLogin={handleGoToLogin}
                                handleGoToDashboard={handleGoToDashboard}
                                checkUserRole={checkUserRole}
                                getLoadingScreen={getLoadingScreen}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
