import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

function Dropdown({
    title,
    titleClassName,
    optionsTitle,
    options,
    optionType,
    isOptionsFetching,
    optionsFetchingText,
    icon,
    showOnlyIcon,
    defaultOption,
    placeholder,
    selectedOptions,
    setSelectedOptions,
    selectedOption,
    setSelectedOption,
    showDropDown,
    setShowDropDown,
    onSelectBoxClick,
    hasSearch,
    searchClassName,
    iconClassName,
    searchPlaceholder,
    search,
    setSearch,
    noSearchText,
    className,
    style,
    dropdownClassName,
    dropdownStyle,
    dropdownMaxHeight,
}) {
    const debouncedSetSearch = useCallback(debounce(setSearch, 300), []);

    const onShowDropDownHandler = (event) => {
        event.stopPropagation();
        if (showDropDown) {
            setShowDropDown(false);
        } else {
            setShowDropDown(true);
            if (typeof onSelectBoxClick === 'function') {
                onSelectBoxClick();
            }
        }
    };

    const onInputChangeHandler = (event) => {
        if (optionType === 'radio') {
            setSelectedOption(event.target.value);
            return;
        }

        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, event.target.value]);
        } else {
            setSelectedOptions(
                selectedOptions?.filter((item) => item !== event.target.value)
            );
        }
    };

    const filteredOptions = options
        ?.filter((option) =>
            search
                ? option.value.toLowerCase().includes(search.toLowerCase())
                : true
        )
        ?.map((option) => {
            if (!search) return option;
            const modifiedOption = { ...option };
            const matchRegex = new RegExp(search, 'i');
            const labelMatch = modifiedOption.label.match(matchRegex);
            if (!labelMatch) return option;
            modifiedOption.label = modifiedOption.label.replace(
                matchRegex,
                `<span style="background-color: #ffd60a; color: #003566;">${labelMatch[0]}</span>`
            );
            return modifiedOption;
        });

    return (
        <div
            className={`tw-relative ${className}`}
            style={style}
            onClick={onShowDropDownHandler}
        >
            <div className="tw-h-full">
                {!showOnlyIcon &&
                    (hasSearch ? (
                        <input
                            type="text"
                            className={`tw-text-xs tw-font-semibold tw-w-full tw-h-full tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-6 tw-shadow-sm focus:tw-ring-blue-100 focus:tw-border-2 focus:tw-border-blue-100 ${
                                !showDropDown ? searchClassName || '' : ''
                            }`}
                            placeholder={
                                search ||
                                (selectedOptions?.length > 1
                                    ? `${selectedOptions[0].split(' ')[0]} & ${
                                          selectedOptions?.length - 1
                                      } more`
                                    : selectedOptions[0]) ||
                                (showDropDown
                                    ? searchPlaceholder || placeholder
                                    : placeholder)
                            }
                            defaultValue={search}
                            onChange={(e) => debouncedSetSearch(e.target.value)}
                        />
                    ) : (
                        <div
                            className={`tw-w-full tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-cursor-default tw-rounded tw-border tw-border-solid tw-bg-white tw-py-2 tw-pl-3 tw-pr-6 tw-shadow-sm ${titleClassName}`}
                        >
                            <p
                                className={`${
                                    title || selectedOptions[0]
                                        ? 'tw-text-black'
                                        : 'tw-text-gray-500'
                                } tw-text-xs tw-font-semibold tw-p-0 tw-m-0`}
                            >
                                {title ||
                                    selectedOptions[0] ||
                                    defaultOption ||
                                    placeholder}
                            </p>
                        </div>
                    ))}
                {icon &&
                    (showOnlyIcon ? (
                        <button
                            type="button"
                            className={`tw-w-auto tw-h-full flex flex-row items-center justify-center tw-text-gray-900 tw-bg-white tw-border tw-border-solid tw-border-gray-300 focus:tw-outline-none hover:tw-bg-gray-100 hover:tw-cursor-pointer focus:tw-ring-4 focus:tw-ring-gray-200 tw-font-medium tw-rounded tw-text-xs tw-tracking-tight tw-px-2.5 tw-py-2 tw-mb-0 ${iconClassName}`}
                            onClick={onShowDropDownHandler}
                        >
                            {icon}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={`tw-absolute tw-border-0 tw-bg-transparent tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-rounded-r-md tw-px-2 focus:tw-outline-none ${iconClassName}`}
                            onClick={onShowDropDownHandler}
                        >
                            {icon}
                        </button>
                    ))}
            </div>

            <div
                className={`${
                    showDropDown
                        ? 'tw-visible tw-opacity-100'
                        : 'tw-invisible tw-opacity-0'
                } tw-shadow-xl tw-transition-all tw-duration-500 tw-ease-out tw-z-50 tw-overflow-y-scroll tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-py-3.5 tw-px-5 tw-w-56 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-bg-white focus:tw-outline-none ${dropdownClassName}`}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                style={{ ...dropdownStyle, maxHeight: dropdownMaxHeight }}
            >
                {optionsTitle && (
                    <div className="tw-text-xs tw-pb-2 tw-font-medium tw-text-gray-500">
                        {optionsTitle}
                    </div>
                )}
                {(isOptionsFetching || filteredOptions?.length === 0) && (
                    <div className="tw-text-xs tw-text-gray-500">
                        {isOptionsFetching ? optionsFetchingText : noSearchText}
                    </div>
                )}
                {!isOptionsFetching &&
                    filteredOptions?.map((option, index) => (
                        <div
                            key={option.label}
                            className="tw-w-fit tw-px-1 tw-py-0.5 tw-relative tw-flex tw-items-start tw-rounded hover:tw-bg-gray-100"
                        >
                            <div className="tw-flex tw-self-center tw-items-center">
                                <input
                                    id={`${option.label}_${index}`}
                                    type={
                                        optionType === 'checkbox'
                                            ? 'checkbox'
                                            : 'radio'
                                    }
                                    value={option.value}
                                    checked={
                                        optionType === 'checkbox'
                                            ? selectedOptions.includes(
                                                  option.value
                                              )
                                            : selectedOption === option.value
                                    }
                                    onChange={onInputChangeHandler}
                                    className="focus:tw-ring-indigo-500 tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300 tw-rounded"
                                />
                            </div>
                            <div className="tw-ml-3 self-center">
                                <label
                                    htmlFor={`${option.label}_${index}`}
                                    className="tw-p-0 tw-m-0 tw-font-light tw-text-gray-500 tw-text-sm tw-cursor-pointer tw-whitespace-nowrap"
                                    dangerouslySetInnerHTML={{
                                        __html: option.label,
                                    }}
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

Dropdown.propTypes = {
    title: PropTypes.string,
    titleClassName: PropTypes.string,
    optionsTitle: PropTypes.string,
    options: PropTypes.array.isRequired,
    optionType: PropTypes.string,
    isOptionsFetching: PropTypes.bool,
    optionsFetchingText: PropTypes.string,
    icon: PropTypes.node,
    showOnlyIcon: PropTypes.bool,
    defaultOption: PropTypes.string,
    placeholder: PropTypes.string,
    selectedOptions: PropTypes.array,
    setSelectedOptions: PropTypes.func,
    selectedOption: PropTypes.string,
    setSelectedOption: PropTypes.func,
    showDropDown: PropTypes.bool,
    setShowDropDown: PropTypes.func,
    onSelectBoxClick: PropTypes.func,
    hasSearch: PropTypes.bool,
    search: PropTypes.string,
    setSearch: PropTypes.func,
    noSearchText: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    dropdownClassName: PropTypes.string,
    dropdownStyle: PropTypes.object,
    dropdownMaxHeight: PropTypes.string,
    searchClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    searchPlaceholder: PropTypes.string,
};

Dropdown.defaultProps = {
    title: '',
    titleClassName: 'tw-border-gray-300',
    optionsTitle: '',
    optionType: 'radio',
    isOptionsFetching: false,
    optionsFetchingText: 'Searching...',
    icon: null,
    showOnlyIcon: false,
    defaultOption: 'Select option',
    placeholder: 'Select option',
    selectedOptions: [],
    setSelectedOptions: () => {},
    selectedOption: '',
    setSelectedOption: () => {},
    showDropDown: false,
    setShowDropDown: () => {},
    onSelectBoxClick: () => {},
    hasSearch: false,
    search: '',
    setSearch: () => {},
    noSearchText: 'No options found!',
    className: 'tw-w-1/5',
    style: {},
    dropdownClassName: '',
    dropdownStyle: {},
    dropdownMaxHeight: '200px',
    searchClassName: '',
    iconClassName: '',
    searchPlaceholder: '',
};

export default Dropdown;
