import React from 'react';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './pageSpinner.scss';

/*
 * SHAPE TYPE available:
 *
 * - Audio
 * - Ball-Triangle
 * - Bars
 * - Circles
 * - Grid
 * - Hearts
 * - Oval
 * - Puff
 * - Rings
 * - TailSpin
 * - ThreeDots
 */

/**
 * Props
 * @param type {string} the shape type, see above
 * @param color {string}
 * @param height {number}
 * @param width {number}
 * @param custom {Object} custom style
 * @param msg {string} message if ever
 * @param sColor {string} secondary color, if applicable
 * @param timeout {number} length if applicable
 * @returns {*}
 * @constructor
 */
const PageSpinner = ({
    type,
    color,
    height,
    width,
    custom = {},
    msg = '',
    sColor = '',
    timeout = 0,
    className
}) => {
    return (
        <section id="spinnerContainer" className={className} style={custom}>
            <Loader
                type={type}
                color={color}
                secondaryColor={sColor}
                height={height}
                width={width}
                timeout={timeout}
            />
            {msg !== '' && <p className="msg">{msg}</p>}
        </section>
    );
};

export default PageSpinner;
