import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNull, isString, isEmpty } from 'lodash';
import * as ls from 'local-storage';
import { useParams } from 'react-router-dom';

import { FilePond, registerPlugin } from 'react-filepond';
import * as Doka from '../react-doka/lib/doka.esm.min.js';
import '../react-doka/lib/doka.min.css';
import { FILE_TYPES } from 'components/app/utils/fileTypes';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

import 'filepond/dist/filepond.min.css';
import './filePondUpload.scss';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateType,
    FilePondPluginFileEncode,
    FilePondPluginFileValidateSize,
    FilePondPluginFileMetadata,
    FilePondPluginFilePoster
);
function FilePondUpload(props) {
    const {
        uploadFile,
        currentFile,
        cropWithSize,
        cropHeightSize,
        fileType,
        multiple,
        localServer,
        type,
    } = props;
    const [files, setFiles] = useState([]);
    const { projectId } = useParams();

    useEffect(() => {
        if (currentFile !== files) {
            const isCondition =
                isString(currentFile) &&
                !isNull(currentFile) &&
                !isEmpty(currentFile) &&
                currentFile !== 'null';
            if (isCondition) {
                setFiles([
                    {
                        source: currentFile,
                        options: {
                            type: 'local',
                            file: {},
                            metadata: {
                                poster: currentFile,
                            },
                        },
                    },
                ]);
            }
        }
        if (currentFile === currentFile) {
            if (currentFile === null) {
                uploadFile(null);
                setFiles([]);
            }
        }
    }, [currentFile]);

    const serverConfig = {
        process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
        ) => {
            if (fileType === FILE_TYPES.IMAGE) {
                //Fake server for image
                load(Date.now());
            }
            if (fileType === FILE_TYPES.CSV) {
                if (localServer) {
                    //Fake server for CSV
                    load(Date.now());
                    return;
                }
                const auth = ls.get('auth');
                let url = `${process.env.REACT_APP_CROWDFLOW_API_URL}/v1/projects/${projectId}/import`;
                if (type === 'peoplemodule') {
                    url = `${process.env.REACT_APP_MYXP_API}/manager/livestream/people/import?projectId=${projectId}`;
                }

                const formData = new FormData();
                formData.append('file', file);
                formData.append('name', file.name);
                formData.append('overrideOnDuplicate', 'true');
                formData.append('fieldName', fieldName);

                const request = new XMLHttpRequest();
                request.open('POST', url);
                request.setRequestHeader(
                    'Authorization',
                    'Bearer ' + auth?.access_token
                );
                request.withCredentials = true;

                request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                };

                request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                        load(request.responseText);
                    } else {
                        error('not uploaded');
                    }
                };

                request.send(formData);

                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        request.abort();
                        // Let FilePond know the request has been cancelled
                        abort();
                    },
                };
            }
        },
    };

    const cropAspectRatio = `${cropWithSize.toString()}:${cropHeightSize.toString()}`;

    return (
        <FilePond
            files={files}
            onupdatefiles={(files) => {
                setFiles(files);
            }}
            acceptedFileTypes={fileType}
            fileValidateTypeDetectType={(source, type) =>
                new Promise((resolve, reject) => {
                    if (fileType === FILE_TYPES.CSV) {
                        if (window.navigator.platform.indexOf('Win') > -1) {
                            type = 'text/csv';
                        }
                    }
                    return resolve(type);
                })
            }
            allowMultiple={multiple}
            imageResizeMode={'contain'}
            beforePrepareFile={(fileItem, shouldPrepareOutput) => {
                // already has prepared image
                if (fileItem.file.image) return false;
                return shouldPrepareOutput;
            }}
            imageCropAspectRatio={cropAspectRatio}
            filePosterMaxHeight={250} // Preview image height
            imageResizeTargetWidth={cropWithSize}
            imageResizeTargetHeight={cropHeightSize}
            imageResizeUpscale={false}
            imageResizeMode={'contain'}
            server={serverConfig}
            onremovefile={(error, file) => {
                if (file) {
                    uploadFile(null);
                    setFiles([]);
                }
            }}
            imageEditInstantEdit={true}
            onpreparefile={(output, file) => {
                const newFile = new File([file], file.name, {
                    type: file.type,
                    lastModified: new Date(),
                });
                uploadFile(newFile);
            }}
            imageEditEditor={Doka.create({
                cropLimitToImageBounds: true,
                outputFile: true,
            })}
        />
    );
}

FilePondUpload.propTypes = {
    uploadFile: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    currentFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cropWithSize: PropTypes.number,
    cropHeightSize: PropTypes.number,
    fileType: PropTypes.array,
    multiple: PropTypes.bool, //Not supported yet
    localServer: PropTypes.bool,
};

FilePondUpload.defaultProps = {
    cropWithSize: 100,
    cropHeightSize: 100,
    currentFile: null,
    fileType: FILE_TYPES.IMAGE,
    multiple: false, //Not supported yet
    localServer: false,
};

export default FilePondUpload;
