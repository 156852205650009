import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Switch } from 'antd';
import * as ls from 'local-storage';
import TagManager from 'react-gtm-module';

import styles from './index.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import SuccessCircleIcon from 'ComponentsV2/ProjectDashboard/Icons/Common/SuccessCircleIcon';
import {
    getCheckoutPayload,
    upgradePlan,
} from 'ComponentsV2/ProjectDashboard/API';
import CloseIcon from 'ComponentsV2/ProjectDashboard/Icons/Common/CloseIcon';
import useChargebee from 'ComponentsV2/ProjectDashboard/utils/useChargebee';
import Loader from 'components/app/components/venue/Loader';
import Plans from './components/Plans';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { getCompanyDetails } from 'apis/rest/livestream/GetSettings';
import plans from 'ComponentsV2/CommonUtils/IdentifyPlan.js';

const defaultPlans = [
    {
        name: 'Starter',
        rank: 3,
        isMonthly: true,
        desc: 'Unlock additional features with a subscription plan',
        planType: '<b>$80</b> / month',
        sgdPlanType: '<b>$110</b> / month',
        frequency: 'Gevme-Omnichannel-Starter-Upgrade-USD-Monthly',
        button: 'Upgrade to Starter',
        downgradeBtn: 'Contact Us',
        planId: 'Gevme-Omnichannel-Starter-Upgrade-USD-Monthly',
        billAnually: false,
        package: {
            name: 'Starter',
            // id: '2fc7a349-67fa-449a-843b-7963b8c45045',
            id: 'b2e2d0a6-d5f1-46c5-b9cb-af78193c7baa',
        },
        includes: {
            desc: '<b>Includes: </b>',
            items: [
                {
                    module: 'Custom Domain',
                    count: 1,
                    module: 'website',
                    desc: 'Custom Domain',
                },
                {
                    module: 'Attendee',
                    count: 10,
                    module: 'event',
                    desc: 'Attendee Records',
                },
                {
                    module: 'Organizer Users',
                    count: 3,
                    module: 'organization',
                    desc: 'Organizer Users',
                },
            ],
        },
        upgrade: {
            desc: 'Overages:',
            package: [
                {
                    item: '<b>$25</b> / domain / month',
                    sgdItem: '<b>$6.75</b> / domain / month',
                },
                {
                    item: '<b>$5</b> / attendee',
                    sgdItem: '<b>$6.75</b> / attendee',
                },
                {
                    item: '<b>$300</b> / exhibitor',
                    sgdItem: '<b>$405</b> / exhibitor',
                },
                {
                    item: '<b>$100</b> / user / month',
                    sgdItem: '<b>$1,620</b> / user / month',
                },
            ],
        },
        features: {
            desc: 'Includes all apps under Free plus:',
            list: ['Networking', 'Exhibitors', 'Analytics'],
        },
    },
    {
        name: 'Pro',
        rank: 2,
        desc: 'Unlock all features with a subscription plan',
        isMonthly: true,
        planType: '<b>$180</b> / month',
        sgdPlanType: '<b>$250</b> / month',
        button: 'Upgrade to Pro',
        downgradeBtn: 'Contact Us',
        planId: 'gevme-virtual-pro-USD-Monthly',
        frequency: 'gevme-virtual-pro-USD-Monthly',
        billAnually: false,
        package: {
            name: 'Pro',
            id: '27364dcd-bbb2-43dc-8476-25d1226a98a9',
        },
        includes: {
            desc: '<b>Includes:</b>',
            items: [
                {
                    module: 'Custom Domain',
                    count: 3,
                    module: '',
                    desc: 'Custom Domain',
                },
                {
                    module: 'Attendee Records',
                    count: '10',
                    module: '',
                    desc: 'Attendee Records',
                },
                {
                    module: 'Organizer Users',
                    count: 3,
                    module: '',
                    desc: 'Organizer Users',
                },
            ],
        },
        upgrade: {
            desc: 'Overages:',
            package: [
                {
                    item: '<b>$25</b> / domain / month',
                    sgdItem: '',
                },
                {
                    item: '<b>$4.50</b> / attendee',
                    sgdItem: '<b>$6</b> / atendee',
                },
                {
                    item: '<b>$300</b> / exhibitor',
                    sgdItem: '<b>$405</b> / exhibitor',
                },
                {
                    item: '<b>$100</b> / user / month',
                    sgdItem: '<b>$100</b> / user / month',
                },
            ],
        },
        features: {
            desc: 'Includes all apps under Starter plus:',
            list: ['Recommendation Engine'],
        },
    },
    {
        name: 'Enterprise',
        rank: 1,
        desc: 'Access enterprise-grade solutions and more',
        planType: "<b>Let's Talk</b>",
        button: 'Get in touch',
        package: {
            name: 'Enterprise',
            id: '911f8e0f-be50-4d4d-88c9-234b171575ea',
        },
        includes: {
            desc: '<b>Includes:</b>',
            items: [
                {
                    module: '',
                    count: 'Custom',
                    module: 'year',
                    desc: 'Domain',
                },
                {
                    module: '',
                    count: 'Custom',
                    module: 'event',
                    desc: 'Attendee Records',
                },
                {
                    module: '',
                    count: 'Custom',
                    module: 'organization',
                    desc: 'Exhibitor Users',
                },
                {
                    module: '',
                    count: 'Custom',
                    module: 'organization',
                    desc: 'Organizer Users',
                },
            ],
        },
        upgrade: {
            desc: 'Overages:',
            package: [
                {
                    item: '<b>$25</b> / domain / month',
                    sgdItem: '<b>$4.70</b> / domain / month',
                },
                {
                    item: '<b>Negotiable</b> / attendee records',
                    sgdItem: '<b>Negotiable</b> / attendee records',
                },
                {
                    item: '<b>Negotiable</b> / exhibitor users',
                    sgdItem: '<b>Negotiable</b> / exhibitor users',
                },
                {
                    item: '<b>Negotiable</b> / organizer users',
                    sgdItem: '<b>Negotiable</b> / organizer users',
                },
            ],
        },
        features: {
            desc: 'Includes everything in Pro, plus:',
            list: ['Custom Dashboard'],
        },
    },
];

const withBillAnnually = [
    {
        name: 'Starter',
        frequency: 'Gevme-Omnichannel-Starter-Upgrade-USD-Yearly',
        rank: 2,
        planId: 'Gevme-Omnichannel-Starter-Upgrade-USD-Yearly',
        planType: '<b>$960</b> / year',
        sgdPlanType: '<b>$1,340</b> / year',
        isMonthly: false,
        billAnually: true,
        includes: {
            desc: '<b>Includes: </b>',
            items: [
                {
                    module: 'Custom Domain',
                    count: 1,
                    module: 'website',
                    desc: 'Custom Domain',
                },
                {
                    module: 'Attendee',
                    count: 10,
                    module: 'event',
                    desc: 'Attendee Records',
                },
                {
                    module: 'Organizer Users',
                    count: 3,
                    module: 'organization',
                    desc: 'Organizer Users',
                },
            ],
        },
        upgrade: {
            desc: 'Overages:',
            package: [
                {
                    item: '<b>$4.5</b> /Attendee',
                    sgdItem: '<b>$6.75</b> /Attendee',
                },
                {
                    item: '<b>$300</b> /Sponsor User',
                    sgdItem: '<b>$405</b> /Sponsor User',
                },
                {
                    item: '<b>$1,200</b> /Organizer User',
                    sgdItem: '<b>$1,620</b> /Organizer User',
                },
            ],
        },
    },
    {
        name: 'Pro',
        frequency: 'gevme-virtual-pro-USD-Yearly',
        rank: 2,
        planId: 'gevme-virtual-pro-USD-Yearly',
        planType: '<b>$2,160</b> / year',
        sgdPlanType: '<b>$3,020</b> / year',
        isMonthly: false,
        billAnually: true,
        includes: {
            desc: '<b>Includes:</b>',
            items: [
                {
                    module: 'Custom Domain',
                    count: 3,
                    module: '',
                    desc: 'Custom Domain',
                },
                {
                    module: 'Attendee Records',
                    count: '10',
                    module: '',
                    desc: 'Attendee Records',
                },
                {
                    module: 'Organizer Users',
                    count: 3,
                    module: '',
                    desc: 'Organizer Users',
                },
            ],
        },
        upgrade: {
            desc: 'Overages:',
            package: [
                {
                    item: '<b>$4.5</b> /Attendee',
                    sgdItem: '<b>$6.75</b> /Attendee',
                },
                {
                    item: '<b>$300</b> /Sponsor User',
                    sgdItem: '<b>$405</b> /Sponsor User',
                },
                {
                    item: '<b>$1,200</b> /Organizer User',
                    sgdItem: '<b>$1,620</b> /Organizer User',
                },
            ],
        },
    },
];

const { STARTER_PLAN } = plans;

const UpgradeScreen = ({ isStandAlone = true }) => {
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const { companyId } = useParams();
    const { instance, loading, portals, error } = useChargebee(companyId);

    const companyDetails = useSelector((state) => state?.projects?.company);
    const isUserOnFreePlan = STARTER_PLAN === companyDetails?.package?.id;
    const showSGDPricing = companyDetails?.country === 'Singapore';

    const billingPeriodUnit = companyDetails.billingPeriodUnit;
    const [requestVisible, setRequestVisible] = useState(false);
    const [request, setRequest] = useState({});
    const [requestLoading, setRequestLoading] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [requestBilAnually, setRequestBillAnuually] = useState({});
    const planUpdateSuccessful = useRef(false);
    const [errorMessage, setErrorMessage] = useState(error);
    const [plans, setPlans] = useState(defaultPlans);

    const renderTitle = (planType) => {
        if (planType === 'Enterprise')
            return 'Request to upgrade to Enterprise plan';
        if (planType === 'Pro') return 'Request to upgrade to Pro plan';
        return 'Request to remove limits on Starter plan';
    };

    const handleEditSubscription = () => {
        try {
            const cbPortal = instance?.createChargebeePortal();
            const callbacks = {};
            cbPortal.open(callbacks, {
                sectionType: portals['EDIT_SUBSCRIPTION'],
                params: {
                    subscriptionId: companyDetails?.subscriptionId,
                },
            });
        } catch (error) {
            openNotification('error', { message: error.message });
        }
    };

    const handleSendRequest = async (plan, isDowngrade) => {
        try {
            planUpdateSuccessful.current = false;
            setRequestSubmitted(false);
            if (!isUserOnFreePlan || plan?.name === 'Enterprise') {
                let payload = plan || request;

                if (request?.name === requestBilAnually?.name) {
                    payload = { ...payload, ...request, ...requestBilAnually };
                }

                if (payload?.package?.id) {
                    const res = await upgradePlan(companyId, {
                        packageId: payload?.package?.id,
                        billAnually: payload?.billAnually,
                    });
                    if (res && res.status) {
                        return setRequestSubmitted(true);
                    } else throw res;
                }
            }

            // - Checkout
            if (instance && plan?.name !== 'Enterprise' && isUserOnFreePlan) {
                instance.openCheckout({
                    hostedPage: () => {
                        return new Promise(async (resolve, reject) => {
                            try {
                                const res = await getCheckoutPayload(
                                    companyId,
                                    {
                                        planId: showSGDPricing
                                            ? plan.planId.replace('USD', 'SGD')
                                            : plan.planId,
                                    }
                                );
                                if (res && !res.status) {
                                    openNotification('error', {
                                        message: res.message,
                                    });
                                }
                                resolve(res.hosted_page);
                            } catch (error) {
                                console.error(error);
                                openNotification('error', {
                                    message: error.message,
                                });
                                reject(error);
                            }
                        });
                    },
                    success: (data) => {
                        if (plan?.name === 'Starter') {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'upgradeStarter',
                                    data: {
                                        companyId,
                                        id: data,
                                    },
                                },
                            });
                        } else if (plan?.name === 'Pro') {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'upgradePro',
                                    data: {
                                        companyId,
                                        id: data,
                                    },
                                },
                            });
                        }

                        planUpdateSuccessful.current = true;
                    },
                    close: () => {
                        if (planUpdateSuccessful.current)
                            setRequestVisible(true);
                    },
                });
            }
            // - Checkout
        } catch (error) {
            console.error(error);
            setErrorMessage(error?.message);
            openNotification('error', { message: error.message });
        } finally {
            setRequestLoading(false);
        }
    };

    const handleBillAnnuallyChange = (val, plan) => {
        try {
            const selectedBillAnually = withBillAnnually?.filter(
                (b) => b.name === plan.name
            )[0];

            if (!val) {
                const defaultPlanDetails = defaultPlans?.filter(
                    (p) => p?.name === plan?.name
                )[0];
                setPlans((plans) =>
                    plans.map((pl) =>
                        pl?.name === defaultPlanDetails?.name
                            ? defaultPlanDetails
                            : pl
                    )
                );
            } else
                setPlans((plans) =>
                    plans.map((defaultPlan) =>
                        defaultPlan.name === plan.name && selectedBillAnually
                            ? {
                                  ...defaultPlan,
                                  ...selectedBillAnually,
                                  //   includes: selectedBillAnually.includes,
                                  //   upgrade: selectedBillAnually.upgrade,
                              }
                            : defaultPlan
                    )
                );

            setRequestBillAnuually({ billAnually: val, name: plan?.name });

            setTimeout(() => {
                if (window?.Chargebee) window.Chargebee.registerAgain();
            }, 200);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!loading) {
            if (window?.Chargebee) window.Chargebee.registerAgain();

            // var cbPortal = instance.createChargebeePortal();
            // var callbacks = {};
            // cbPortal.openSection(
            //     {
            //         sectionType: portals.SUBSCRIPTION_DETAILS,
            //         params: {
            //             subscriptionId: companyDetails?.subscriptionId
            //         },
            //         callbacks
            //     }
            // )
        }
    }, [loading]);

    useEffect(() => {
        if (billingPeriodUnit && billingPeriodUnit === 'year') {
            setPlans((plans) =>
                plans.map((defaultPlan) => {
                    const selectedWithBillAnnually =
                        withBillAnnually?.filter(
                            (wb) => defaultPlan.name === wb.name
                        )[0] || {};
                    if (
                        defaultPlan.name === 'Pro' ||
                        defaultPlan.name === 'Starter'
                    ) {
                        return {
                            ...defaultPlan,
                            ...selectedWithBillAnnually,
                            includes: selectedWithBillAnnually.includes,
                            upgrade: selectedWithBillAnnually.upgrade,
                        };
                    }
                    return defaultPlan;
                })
            );
        }
    }, [billingPeriodUnit]);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            await getCompanyDetails(companyId);
        };

        fetchCompanyDetails();
    }, []);

    if (error)
        return (
            <div className={styles.UpgradeScreen}>
                <div className={styles.LoaderWrapper}>
                    <p>{'Something went wrong!'}</p>
                    <button
                        className="cancel"
                        onClick={() =>
                            history?.location?.state?.previewUrl
                                ? history.push(
                                      history?.location?.state?.previewUrl
                                  )
                                : history.push('/')
                        }
                    >
                        Go Back
                    </button>
                </div>
            </div>
        );

    return (
        <div
            className={styles.UpgradeScreen}
            style={{ marginTop: isStandAlone ? '0px' : '15px' }}
        >
            {loading ? (
                <>
                    <div className={styles.LoaderWrapper}>
                        <Loader />
                    </div>
                </>
            ) : (
                <>
                    <button
                        onClick={() =>
                            history?.location?.state?.previewUrl
                                ? history.push(
                                      history?.location?.state?.previewUrl
                                  )
                                : history.goBack()
                        }
                        className={styles.CloseButton}
                    >
                        <CloseIcon />
                    </button>

                    {isStandAlone && <h1>Upgrade</h1>}
                    {showSGDPricing && (
                        <p className={styles.Disclaimer}>
                            All prices shown are in Singapore Dollar.
                        </p>
                    )}
                    <Plans
                        plans={plans}
                        handleBillAnnuallyChange={handleBillAnnuallyChange}
                        handleSendRequest={handleSendRequest}
                        setRequest={setRequest}
                        setRequestVisible={setRequestVisible}
                        handleEditSubscription={handleEditSubscription}
                        companyDetails={companyDetails}
                        isUserOnFreePlan={isUserOnFreePlan}
                    />
                    <Modal
                        centered
                        confirmLoading={requestLoading}
                        visible={requestVisible}
                        okText={null}
                        cancelText={null}
                        width={426}
                        style={{ maxWidth: 426 }}
                        footer={null}
                        className={styles.RequestModal}
                        onCancel={() => {
                            setRequestVisible(false);
                            setRequestLoading(false);
                            setRequestSubmitted(false);
                            setErrorMessage('');
                        }}
                    >
                        {requestSubmitted || planUpdateSuccessful.current ? (
                            <>
                                <h2>
                                    <SuccessCircleIcon />
                                    {planUpdateSuccessful.current
                                        ? 'Plan update successful'
                                        : 'Request successfully sent'}
                                </h2>
                                {planUpdateSuccessful.current ? (
                                    <p>
                                        Wait for few minutes to reflect on your
                                        account. Thanks!
                                    </p>
                                ) : (
                                    <p>
                                        One of our team members will get back to
                                        you within 24 hours.
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                <h2>{renderTitle(request?.name)}</h2>
                                <p>
                                    Below are the details that will be submitted
                                    with your request.
                                </p>
                                <div className="user-details">
                                    <p>
                                        <span className={'label'}>
                                            First Name
                                        </span>
                                        <span className={'value'}>
                                            {user?.firstname}
                                        </span>
                                    </p>
                                    <p>
                                        <span className={'label'}>
                                            Last Name
                                        </span>
                                        <span className={'value'}>
                                            {user?.lastname}
                                        </span>
                                    </p>
                                    <p>
                                        <span className={'label'}>Email</span>
                                        <span className={'value'}>
                                            {user?.email}
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}
                        <div className="action-buttons">
                            {requestSubmitted ||
                            planUpdateSuccessful.current ? (
                                <>
                                    <button
                                        className="cancel"
                                        onClick={() =>
                                            history?.location?.state?.previewUrl
                                                ? history.push(
                                                      history?.location?.state
                                                          ?.previewUrl
                                                  )
                                                : history.push('/')
                                        }
                                    >
                                        Done
                                    </button>
                                </>
                            ) : (
                                <>
                                    {requestLoading ? (
                                        <button className="cancel" disabled>
                                            Cancel
                                        </button>
                                    ) : (
                                        <button
                                            className="cancel"
                                            onClick={() => {
                                                setRequestVisible(false);
                                                setRequestLoading(false);
                                                setRequestSubmitted(false);
                                                setErrorMessage('');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    )}
                                    {requestLoading ? (
                                        <button className="submit" disabled>
                                            Sending request...
                                        </button>
                                    ) : (
                                        <button
                                            className="submit"
                                            onClick={(e) =>
                                                handleSendRequest(request)
                                            }
                                        >
                                            Send request
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                        {errorMessage && (
                            <p className="error-message">{errorMessage}</p>
                        )}
                    </Modal>
                    <style jsx="true">{`
                        .ant-modal-content {
                            padding: 60px 32px 40px 32px;
                            background: #ffffff;
                            box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.15);
                            border-radius: 4px;
                        }
                        .ant-modal-content h2 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-family: Montserrat;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 24px;
                            text-align: center;
                            letter-spacing: -0.04em;
                            color: #000000;
                            margin-bottom: 16px;
                        }
                        .ant-modal-content p,
                        .ant-modal-content button {
                            font-family: Open Sans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 15px;
                            text-align: center;
                            letter-spacing: -0.01em;
                            color: #000000;
                            margin-bottom: 16px;
                        }
                        .ant-modal-content .user-details {
                            background: #f0f0f0;
                            border-radius: 8px;
                            padding: 16px;
                            text-align: left;
                            margin-bottom: 30px;
                        }
                        .ant-modal-content .user-details p {
                            text-align: left;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 16px;
                        }
                        .ant-modal-body {
                            padding: 0;
                        }
                        .ant-modal-body .error-message {
                            color: #f81d22;
                            text-align: left;
                        }
                        .ant-modal-content .user-details p:last-child {
                            margin-bottom: 0;
                        }
                        .ant-modal-content .user-details .label {
                            font-size: 11px;
                            font-weight: bold;
                        }
                        .ant-modal-content .user-details .value {
                            font-size: 15px;
                        }
                        .ant-modal-content .action-buttons {
                            display: flex;
                            align-items: center;
                        }
                        .ant-modal-content .action-buttons button {
                            font-size: 13px;
                            padding: 7px;
                            font-weight: bold;
                            border-radius: 4px;
                            border: none;
                            width: calc(100% - 8px);
                        }
                        .ant-modal-content .action-buttons .cancel {
                            color: #000;
                            background: #e2e9f3;
                            margin-right: 8px;
                        }
                        .ant-modal-content .action-buttons .submit {
                            background: #008060;
                            color: #fff;
                        }
                    `}</style>
                </>
            )}
        </div>
    );
};

export default UpgradeScreen;
