const emptyInput = {
    title: '',
    type: '',
    textContent: '',
    pdf: null,
    url: '',
    image: [],
    name: '',
    largeTile: false,
};

export default emptyInput;
