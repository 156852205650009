import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import "./qrCodeDisplay.scss";

function QrCodeDisplay(props) {
  const { project, showcaseId, setModalVisibility } = props;

  const projectId = project.projectId;

  /**
   * gets the underling ref of the component
   * prop comes with the `Link` component
   */
  const refCallback = node => {
    const canvas = document.querySelector(".qr-code-display__wrapper > canvas");
    if (node) {
      node.href = canvas.toDataURL();
      node.download = `showcase-${showcaseId}-qrcode.png`;
    }
  };

  return (
    <div className="qr-code-display">
      <img
        src="/assets/icon_close.svg"
        alt=""
        className="icon-close"
        onClick={() => setModalVisibility(false)}
      />

      <h2>Your Showcase's QR Code Under</h2>

      <h3>{project.name}</h3>

      <p>
        Scanning the QR code below will lead to your content showcase and
        activities of the viewer will be tracked.
      </p>

      <div className="qr-code-display__wrapper mb-3">
        <QRCode
          value={`${process.env.REACT_APP_DOMAIN_URL}/p/${projectId}/s/${showcaseId}`}
          size={360}
          level="Q"
          includeMargin={true}
        />
      </div>

      <Link
        className="btn btn-primary"
        to="/"
        target="_blank"
        innerRef={refCallback}
      >
        <img src="/assets/icon_download.svg" alt="" />
        <span>Download</span>
      </Link>
    </div>
  );
}

QrCodeDisplay.propTypes = {
  showcaseId: PropTypes.string.isRequired,
  setModalVisibility: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    project: state.dashboardManager.project
  };
}

export default connect(
  mapStateToProps,
  null
)(QrCodeDisplay);
