import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import OTPInput from 'otp-input-react';

import Modal from 'components/app/common/modal';

const inputStyles = {
    width: '55px',
    height: '60px',
    borderWidth: '1px',
    // borderRadius: '0.375rem',
    fontSize: '1.85rem',
    lineHeight: '2.25rem',
    fontWeight: 800,
};

export default function TwoFAOtpModal({
    verificationCode = '',
    setVerificationCode = () => {},
    visible = false,
    setVisible = () => {},
    isLoading = false,
    onCancel = () => {},
    onConfirm = () => {},
}) {
    const companyDetails = useSelector((state) => state?.projects?.company);
    const companyName = companyDetails?.name;
    const maxNameLength = 15;

    function onClose() {
        setVisible && setVisible(false);
        onCancel && onCancel();
    }

    const getOtpScreen = () => (
        <>
            <div className="tw-w-full tw-text-gray-400 tw-font-semibold tw-flex tw-flex-row tw-justify-start tw-items-end tw-text-left">
                <div className="tw-text-base tw-text-gray-400 tw-pr-2">
                    {companyName?.length > maxNameLength ? (
                        <Tooltip
                            placement="top"
                            title={companyName}
                            zIndex={9999}
                        >
                            <div className="tw-p-0 tw-m-0 tw-break-words">
                                {companyName.slice(0, maxNameLength - 1) +
                                    ' ...'}
                            </div>
                        </Tooltip>
                    ) : (
                        companyName
                    )}
                </div>
                &#x2f;
                <div className="tw-px-2 tw-text-base tw-text-gray-400">
                    Security
                </div>
                &#x2f;
                <div className="tw-pl-2 tw-text-base tw-text-black tw-font-semibold">
                    Two-Factor Authentication
                </div>
            </div>
            <div className="tw-mt-6 tw-w-auto tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-center">
                <p className="tw-p-0 tw-m-0 tw-text-base tw-font-semibold tw-text-left">
                    Enter confirmation code
                </p>
                <p className="tw-p-0 tw-m-0 tw-w-auto tw-text-sm tw-font-light tw-text-left tw-whitespace-nowrap">
                    Please enter the confirmation code you see on your
                    authentication app
                </p>
                <div className="tw-mt-12 tw-w-full">
                    <OTPInput
                        inputStyles={inputStyles}
                        className="tw-font-extrabold tw-text-2xl tw-justify-center"
                        value={verificationCode}
                        onChange={setVerificationCode}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={isLoading}
                    />
                </div>
            </div>
            <div className="tw-mt-auto tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                <button
                    type="button"
                    className="tw-w-auto tw-cursor-pointer tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-semibold hover:tw-border-gray-400 hover:tw-bg-gray-50"
                    disabled={isLoading}
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className={`${
                        isLoading || verificationCode.length !== 6
                            ? 'tw-border-blue-600/50 tw-bg-blue-600/50 hover:tw-border-blue-500/50 hover:tw-bg-blue-500/50'
                            : 'tw-border-blue-600 tw-bg-blue-600 hover:tw-border-blue-500 hover:tw-bg-blue-500'
                    } tw-w-auto tw-rounded tw-border tw-border-solid tw-px-4 tw-py-2 tw-text-sm tw-text-white tw-font-semibold`}
                    disabled={isLoading || verificationCode.length !== 6}
                    onClick={onConfirm}
                >
                    Save
                </button>
            </div>
        </>
    );

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            showCloseIcon
            preventCloseOnOutsideClick
            style={{ maxWidth: '600px' }}
        >
            <div className="tw-mx-auto tw-flex tw-min-h-[360px] tw-w-auto tw-flex-col tw-justify-start tw-items-center tw-cursor-default">
                {getOtpScreen()}
            </div>
        </Modal>
    );
}
