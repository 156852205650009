import React from 'react';

export default function LinkIcon({ stroke = '#000' }) {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.62305 9.5H12.373"
                stroke={stroke || '#000'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.31055 12.875H4.49805C3.60294 12.875 2.7445 12.5194 2.11156 11.8865C1.47863 11.2535 1.12305 10.3951 1.12305 9.5C1.12305 8.60489 1.47863 7.74645 2.11156 7.11351C2.7445 6.48058 3.60294 6.125 4.49805 6.125H7.31055"
                stroke={stroke || '#000'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6855 12.875H13.498C14.3932 12.875 15.2516 12.5194 15.8845 11.8865C16.5175 11.2535 16.873 10.3951 16.873 9.5C16.873 8.60489 16.5175 7.74645 15.8845 7.11351C15.2516 6.48058 14.3932 6.125 13.498 6.125H10.6855"
                stroke={stroke || '#000'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
