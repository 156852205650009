import { getAuth } from '../helpers';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function getAllReactions({ projectId, type = 'all' }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/reactions?projectId=${projectId}&type=${type}`,
        {
            method: 'GET',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function createReaction({ projectId, payload }) {
    const auth = getAuth();
    const formData = new FormData();
    for (const property in payload) {
        formData.append(property, payload[property]);
    }

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/reactions?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        }
    );
    return await response.json();
}

export async function deleteReaction({ projectId, reactionId }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/reactions/${reactionId}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function setFrontEmojiReaction({ projectId, reactionId }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/reactions/${reactionId}/front-emoji?projectId=${projectId}`,
        {
            method: 'PUT',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function restoreAllReactions({ projectId }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/reactions/reset?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
