import * as React from 'react';

const FacebookIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={props.width || 10}
                height={props.height || 19}
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                >
                <path
                    d="M6.066 18.498v-8.21h2.755l.413-3.198H6.066V5.047c0-.926.257-1.557 1.586-1.557h1.694V.627A22.704 22.704 0 006.877.502c-2.442 0-4.114 1.49-4.114 4.229V7.09H0v3.199h2.762v8.209h3.304z"
                    fill="#3C5A9A"
                />
            </svg>
        </div>
    );
};

export default FacebookIcon;
