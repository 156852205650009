import React from "react";
import ImageLoader from "react-load-image";

import EclipsePreloader from "../preloader/eclipsePreloader";
import "./image.scss";

const Image= (props)=>{
    return (
      <ImageLoader {...props}>
          {props.children}
          <div>Error!</div>
          <EclipsePreloader style={props.style}/>
      </ImageLoader>
  );
};

export default Image;
