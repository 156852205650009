import { gql } from "apollo-boost";

const SHOWCASE_PUBLISHED = gql`
  query Showcase($id: ID!) {
    showcase(id: $id) {
      id
      slug
      isPublished
    }
  }
`;

export default SHOWCASE_PUBLISHED;
