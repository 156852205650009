import React, { useState } from 'react';
import {
    Query,
    Builder,
    BasicConfig,
    Utils as QbUtils,
} from 'react-awesome-query-builder';
import throttle from 'lodash/throttle'

import PropTypes from 'prop-types';

import './queryBuilder.scss';

const QueryBuilder = (props) => {
    let queryDefaultValue = {
        id: QbUtils.uuid(),
        type: 'group',
        children1: {},
        properties: { conjunction: 'AND', not: false },
    };
    const queryValue =
        props.value == null || Object.keys(props.value)?.length === 0
            ? queryDefaultValue
            : props.value;

    const queryFields = props?.fields?.SponsorFieldList
        ? props?.fields?.SponsorFieldList?.fields
        : props?.fields?.ProductFieldList?.fields
        ? props?.fields?.ProductFieldList?.fields
        : props?.fields?.AllFieldsUnderProject?.fields;

    const overWriteFieldKey = (
        oldKey,
        newKey,
        { [oldKey]: old, ...othersFields }
    ) => ({ [newKey]: old, ...othersFields });

    const createField = (field, label) => {
        return overWriteFieldKey('defaultFieldKey', field, {
            defaultFieldKey: {
                label: label,
                type: 'text',
                valueSources: ['value'],
                operators: [
                    'equal',
                    'like',
                    'not_equal',
                    'ends_with',
                    'is_empty',
                    'is_not_empty',
                ],
            },
        });
    };

    let overWriteFields = {};
    if (queryFields) {
        queryFields.map((value) => {
            if (!value?.field || !value?.label) return null;
            const result = createField(value.field, value.label);
            overWriteFields = { ...overWriteFields, ...result };
        });
    }

    const InitialConfig = BasicConfig;
    const defaultConfig = {
        ...InitialConfig,
        fields: {
            ...overWriteFields,
        },
        settings: {
            showNot: false,
        },
        ...(props.withoutOrConjunction && {
            conjunctions: ['AND'],
        }),
    };
    const [state, setState] = useState({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), defaultConfig),
        config: defaultConfig,
    });

    const onChange = (immutableTree, config) => {
        throttle(() => {
            setState({ tree: immutableTree, config });
        }, 100);
        const jsonTree = QbUtils.getTree(immutableTree);
        props.onChange(jsonTree);
    };

    const renderBuilder = (props) => (
        <div className="query-builder-container">
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    );

    return (
        <div>
            <Query
                {...state.config}
                value={state.tree}
                onChange={onChange}
                renderBuilder={renderBuilder}
            />
        </div>
    );
};
QueryBuilder.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    fields: PropTypes.object,
    withoutOrConjunction: PropTypes.bool,
};

QueryBuilder.defaultProps = {
    withoutOrConjunction: false,
};
export default QueryBuilder;
