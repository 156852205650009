import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import './noAccess.scss';

function NoAccess(props) {
    return (
        <div className="main-container no-access text-center">
            <div className="no-access__content">
                <img
                    className="no-access__icon"
                    src="/assets/icon_no_access.svg"
                    alt=""
                />
                <h1>No Access</h1>

                <p>
                    Sorry, your access to this space is restricted or has
                    expired.
                </p>

                <Link
                    to="/login"
                    onClick={() => {
                        localStorage.clear();
                    }}
                >
                    Login with another account
                </Link>
            </div>
        </div>
    );
}

export default withRouter(NoAccess);
