import SignupOtp from 'ComponentsV2/SignUp/Otp';
import SignUp from 'ComponentsV2/SignUp/LandingPage';
import SignupSteps from 'ComponentsV2/SignUp/Steps';

export default [
    {
        path: '/signup/steps',
        component: SignupSteps,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/signup/otp',
        component: SignupOtp,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/signup/*',
        component: SignUp,
        isPrivate: false,
        isExact: true,
    },
    {
        path: '/signup',
        component: SignUp,
        isPrivate: false,
        isExact: true,
    },
];
