import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { truncate } from "lodash";

import "./headerShowcase.scss";

function HeaderShowcase(props) {
  const {
    project,
    pagePayload: { title, displayQrCode },
    match: { params }
  } = props;
  const showcaseId = params.showcaseId;
  const personId = params.personId;

  return (
    <div className="header-showcase">
      <div className="row">
        <div className="col-8 col-md-6">
          <div className="header__inner header-showcase__left-menu">
            <Link
              to={`/p/${personId}/showcase/dashboard`}
              className="header__back"
            >
              <img src="/assets/icon_back_arrow.svg" alt="" />
            </Link>
            <img
              src="/assets/apps/showcase/icon_showcase_small.svg"
              className="header-showcase__icon"
              alt=""
            />
            <h2 className="header__title">
              {truncate(project.name, {
                length: 20
              })}{" "}
              / {title}
            </h2>
          </div>
        </div>
        <div className="col-4 col-md-6">
          <div className="header__inner header-showcase__right-menu">
            <button className="btn btn-primary" onClick={displayQrCode}>
              <img src="assets/icon_qr.svg" alt="" />
              <span>Generate QR</span>
            </button>
            <a
              href={`${process.env.REACT_APP_SHOWCASE_CONTENT_URL}/content/${showcaseId}`}
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/icon_preview.svg" alt="" />
              <span>Preview</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    project: state.dashboardManager.project
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(HeaderShowcase)
);
