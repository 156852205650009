import React from 'react';

export default function SearchIcon({ stroke = '#C8D3DC' }) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.7372 14.9753L12.7159 11.963C13.6907 10.7211 14.2196 9.18756 14.2176 7.60882C14.2176 6.20283 13.8007 4.82841 13.0196 3.65937C12.2385 2.49033 11.1282 1.57918 9.82925 1.04113C8.53028 0.50308 7.10094 0.362302 5.72196 0.636597C4.34299 0.910893 3.07631 1.58794 2.08213 2.58213C1.08794 3.57631 0.410893 4.84299 0.136597 6.22196C-0.137698 7.60094 0.00308012 9.03028 0.54113 10.3293C1.07918 11.6282 1.99033 12.7385 3.15937 13.5196C4.32841 14.3007 5.70283 14.7176 7.10882 14.7176C8.68756 14.7196 10.2211 14.1907 11.463 13.2159L14.4753 16.2372C14.5579 16.3204 14.6562 16.3865 14.7645 16.4317C14.8728 16.4768 14.9889 16.5 15.1062 16.5C15.2236 16.5 15.3397 16.4768 15.448 16.4317C15.5563 16.3865 15.6545 16.3204 15.7372 16.2372C15.8204 16.1545 15.8865 16.0563 15.9317 15.948C15.9768 15.8397 16 15.7236 16 15.6062C16 15.4889 15.9768 15.3728 15.9317 15.2645C15.8865 15.1562 15.8204 15.0579 15.7372 14.9753ZM1.77721 7.60882C1.77721 6.55433 2.0899 5.52352 2.67575 4.64674C3.26159 3.76996 4.09428 3.08659 5.0685 2.68305C6.04273 2.27952 7.11474 2.17393 8.14897 2.37965C9.1832 2.58538 10.1332 3.09316 10.8788 3.8388C11.6245 4.58444 12.1323 5.53444 12.338 6.56868C12.5437 7.60291 12.4381 8.67492 12.0346 9.64914C11.6311 10.6234 10.9477 11.4561 10.0709 12.0419C9.19413 12.6277 8.16332 12.9404 7.10882 12.9404C5.69479 12.9404 4.33867 12.3787 3.3388 11.3788C2.33893 10.379 1.77721 9.02285 1.77721 7.60882Z"
                fill={stroke || '#C8D3DC'}
            />
        </svg>
    );
}
