import * as React from 'react';

const EyeClosedIcon = (props) => {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                d="M23.227 15.891a13.02 13.02 0 001.62-1.727.731.731 0 00-.104-1.005.676.676 0 00-.973.107 11.317 11.317 0 01-1.84 1.872c-.006.003-.01.007-.014.01a9.284 9.284 0 01-5.911 2.062 9.285 9.285 0 01-5.912-2.063l-.016-.012a11.314 11.314 0 01-1.837-1.87.698.698 0 00-.465-.261.67.67 0 00-.508.155.71.71 0 00-.254.48.738.738 0 00.15.525 13.017 13.017 0 001.616 1.724l-1.686 3.017a.73.73 0 00.253.977.679.679 0 00.945-.262l1.6-2.861a10.534 10.534 0 003.048 1.454l-.503 2.947a.734.734 0 00.115.534.69.69 0 00.567.305.68.68 0 00.444-.168.722.722 0 00.237-.423l.494-2.897c1.133.172 2.283.172 3.416.001l.494 2.897a.72.72 0 00.237.423.679.679 0 001.012-.137.733.733 0 00.114-.534l-.502-2.945a10.535 10.535 0 003.052-1.455l1.59 2.845a.696.696 0 00.42.333.673.673 0 00.525-.071.712.712 0 00.322-.434.735.735 0 00-.069-.543l-1.677-3z"
                fill={props.isVisible ? "#000" : "#969696"}
            />
        </svg>
    );
};

export default EyeClosedIcon;
