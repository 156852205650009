import * as React from "react"

const PeopleDirectory = (props) => {
  return (
     <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 8h30v32H9zM32 34H16"
      />
      <path
        d="M30.444 28v-1.555a3.111 3.111 0 00-3.11-3.111H21.11A3.111 3.111 0 0018 26.444V28M24.111 19.222a3.111 3.111 0 100-6.222 3.111 3.111 0 000 6.222z"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PeopleDirectory
