import React from 'react';

export default function MinimizeIcon({ stroke = "white" }) {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill="#377BF7"/>
            <rect x="12" y="23" width="26" height="4" rx="2" fill={stroke || "white"}/>
        </svg>
    );
}
