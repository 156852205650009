import React from 'react';

export default function ShareIcon() {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.83329 11.0833H12.1666V8.16663H13.3333V11.6666C13.3333 11.8213 13.2718 11.9697 13.1624 12.0791C13.053 12.1885 12.9047 12.25 12.75 12.25H2.24996C2.09525 12.25 1.94688 12.1885 1.83748 12.0791C1.72808 11.9697 1.66663 11.8213 1.66663 11.6666V8.16663H2.83329V11.0833ZM7.49996 5.83329H5.74996C5.19758 5.83291 4.65297 5.96344 4.16079 6.2142C3.66861 6.46495 3.24286 6.82879 2.91846 7.27588C3.12553 6.21273 3.69582 5.25467 4.53166 4.56581C5.36749 3.87695 6.41684 3.50015 7.49996 3.49996V1.16663L12.1666 4.66663L7.49996 8.16663V5.83329Z"
                fill="black"
            />
        </svg>
    );
}
