import { gql } from "apollo-boost";

const UPDATE_PERSON = gql`
  mutation UpdatPerson($input: updatePersonInput) {
    updatePerson(input: $input) {
      person {
        id
      }
    }
  }
`;

export default UPDATE_PERSON;
