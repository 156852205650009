import getAuthBearer from '../../../utils/getAuthBearer';
import { apiInterceptor } from 'utils/apiInterceptor';

export const saveLivepageSettings = async (
    livepageId,
    projectId,
    isPublished = true
) => {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${
            process.env.REACT_APP_MYXP_API_VIRTUAL
        }/v1/manager/livepages/${livepageId}/${
            isPublished ? 'save' : 'save-draft'
        }?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
};

export const saveWidgetSettings = async (livepageId, projectId) => {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/widgets/${livepageId}/save?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
};
