import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { Button } from 'antd';
import { getAnnouncements } from 'apis/rest/GetAnnouncements';
import { isEmpty } from 'lodash';
import './notificationPopup.css';

export default function NotificationPopup({ bannerType = 'strip' }) {
    const [popupData, setPopupData] = useState({});

    const fetchPopupData = async () => {
        try {
            const res = await getAnnouncements();
            if (!res.status) throw Error('unable to fetch announcements');
            setPopupData(res?.announcement);
        } catch (err) {
            openNotification('error', {
                message: err.message || 'Unable to fetch popup sata!',
            });
        }
    };

    const renderBannerStrip = () => {
        return (
            <div className="tw-w-full tw-flex tw-justify-center tw-rounded-lg tw-mb-12 PopupBannerStripWrapper">
                <div className="tw-flex tw-p-3 tw-bg-[#DFEDF5] tw-justify-between tw-rounded-lg tw-shadow-lg tw-w-fit tw-h-fit tw-min-w-[677px] tw-min-h-[90px]">
                    {/* content  */}
                    <div className="tw-flex  tw-gap-16 tw-items-center">
                        <div className="tw-flex  tw-gap-6 tw-items-center">
                            {/* Image */}
                            {popupData?.titleImageUrl &&
                            popupData?.titleImageUrl.length ? (
                                <>
                                    <img
                                        src={popupData?.titleImageUrl}
                                        alt="title Image"
                                        className="tw-max-w-[144px] tw-max-h-[68px]"
                                    />
                                    <div className="tw-w-[1px] notification_Banner_Seperator tw-h-[62px] bg-black"></div>
                                </>
                            ) : null}
                            {/* seperator  */}
                            <div className="tw-flex tw-flex-col tw-items-start">
                                {/* description */}
                                {popupData?.title &&
                                popupData?.title?.length ? (
                                    <span className="tw-text-2xl tw-font-extrabold tw-text-black">
                                        {popupData?.title}
                                    </span>
                                ) : null}
                                {/* subheading  */}
                                <div className="tw-flex tw-flex-col tw-items-start tw-w-full">
                                    {popupData?.subTitle &&
                                    popupData?.subTitle?.length ? (
                                        <span className="tw-font-ModernEra tw-text-xs tw-font-medium tw-text-[#282828]">
                                            {popupData?.subTitle}
                                        </span>
                                    ) : null}
                                    {popupData?.description &&
                                    popupData?.description?.length ? (
                                        <span className="tw-font-ModernEra tw-text-xs tw-font-medium tw-text-[#282828]">
                                            {popupData?.description}{' '}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {/* CTA button */}
                        {popupData?.ctaLink && popupData?.ctaLink?.length ? (
                            <a
                                className="NotificationPopup-CTA-anchor"
                                href={popupData?.ctaLink}
                                target="_blank"
                            >
                                <button className="NotificationPopup-CTA tw-w-full  tw-rounded  ">
                                    <span className=" tw-text-white tw-font-modernEraExtraBold tw-font-extrabold">
                                        {popupData?.ctaText || 'Register'}
                                    </span>
                                </button>
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const imageBanner = () => {
        return (
            <div className="tw-w-full tw-flex tw-justify-center tw-rounded-lg tw-mb-12 PopupBannerStripWrapper">
                <div className="tw-flex tw-bg-[#DFEDF5] tw-justify-between tw-rounded-lg tw-shadow-lg tw-w-fit tw-h-fit tw-max-w-[700px] tw-min-h-[90px]">
                    {/* content  */}
                    <a
                        className="tw-w-full"
                        href={popupData?.ctaLink}
                        target="_blank"
                    >
                        <div className="tw-flex  tw-gap-16 tw-items-center">
                            <img
                                src={popupData?.titleImageUrl}
                                alt="Banner Image"
                                className=' tw-w-full'
                            />
                        </div>
                    </a>
                </div>
            </div>
        );
    };

    const renderBannerThick = () => (
        <div className="tw-flex tw-gap-2 tw-p-6 tw-py-6 tw-bg-[#fcfaee] tw-fixed tw-bottom-0 tw-left-0 tw-z-10 tw-w-[47%]">
            {/* content  */}
            <div className="tw-flex tw-w-[98%] tw-gap-11 tw-items-center">
                {/* description + CTA Button  */}
                <div className="tw-flex tw-flex-col tw-w-[50%] tw-items-center">
                    {/* description */}
                    <p className="tw-font-modernEra tw-text-base tw-text-black tw-font-normal">
                        {popupData?.description}
                    </p>
                    {/* CTA button */}
                    <a
                        className="tw-w-full"
                        href={popupData?.ctaLink}
                        target="_blank"
                    >
                        <Button className="NotificationPopup-button tw-w-full tw-h-[38.24px] tw-rounded  ">
                            <span className=" tw-text-white tw-font-modernEraExtraBold  tw-text-sm tw-font-extrabold">
                                {popupData?.ctaText || 'Register'}
                            </span>
                        </Button>
                    </a>
                </div>
                {/* Image + subheading */}
                <div className="tw-flex tw-flex-col tw-w-[50%] tw-items-start">
                    {/* Image */}
                    <div className="tw-w-full">
                        <img
                            src={popupData?.titleImageUrl}
                            alt="title Image"
                            className="tw-w-full"
                        />
                    </div>

                    {/* subheading  */}
                    <span className="tw-font-modernEraBold tw-text-sm tw-font-bold tw-text-[#282828]">
                        {popupData?.subTitle}
                    </span>
                </div>
            </div>
            {/* cross Button  */}
            <div className="tw-flex tw-items-start">
                <CloseOutlined
                    onClick={() => {
                        setPopupData({
                            status: true,
                            announcement: null,
                        });
                    }}
                />
            </div>
        </div>
    );

    const bannerSelection = () => {
        const { description, ctaText } = popupData;
        if (description && ctaText) {
            switch (bannerType) {
                case 'thick':
                    return renderBannerThick();
                case 'strip':
                    return renderBannerStrip();
            }
        } else {
            return imageBanner();
        }
    };

    useEffect(() => {
        fetchPopupData();
    }, []);

    if (!isEmpty(popupData) && popupData) return bannerSelection();
    else return null;
}
