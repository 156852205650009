import * as React from 'react';

const ZipIcon = (props) => {
    return (
        <svg
            width={22}
            height={18}
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 16h3V4h-4v1h1v2h-1v1h1v2h-1v1h1v2h-1v1h1v2zm-3 0v-1h1v-1h-1v-2h1v-1h-1V9h1V8h-1V6h1V5h-1V4h-3.005c-.718-.004-1.186-.34-1.69-.963-.069-.086-.29-.373-.323-.416C8.607 2.15 8.384 2 8 2H2v14h12zm6-14a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h6c1.12 0 1.833.475 2.549 1.379.048.06.261.337.314.402.157.195.189.219.139.219H20z"
                fill="#000"
            />
        </svg>
    );
};

export default ZipIcon;
