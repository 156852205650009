import React from 'react'
import PropTypes from 'prop-types'

import './previewWindow.scss';

function PreviewWindow({ children }) {

    return (
        <div className="notification-preview-container bg-white w-100 h-100 rounded-lg">
            <div className="d-flex border-bottom p-2">
                <div className="window-icons m-1"></div>
                <div className="window-icons my-1"></div>
                <div className="window-icons m-1"></div>
            </div>
            <div className="d-flex justify-content-center align-items-center h-100 position-relative">
                <h1>Preview</h1>
                {children}
            </div>
        </div>

    )
}

PreviewWindow.propTypes = {
    children: PropTypes.node,
}

PreviewWindow.defaultProps = {
    children: null

};

export default PreviewWindow

