import moment from 'moment';
import momentTimezone from 'moment-timezone';

function getStartDate() {
    return moment()
        .startOf('day')
        .subtract(30, 'days')
        .format('YYYY-MM-DDTHH:mm');
}

function getEndDate() {
    return moment().endOf('day').format('YYYY-MM-DDTHH:mm');
}

export const actions = {
    fetchReports: (payload) => ({ type: 'ONSITE_FETCH_REPORTS', payload }),
    addNewReport: (payload) => ({ type: 'ONSITE_ADD_NEW_REPORT', payload }),
    deleteReport: (payload) => ({ type: 'ONSITE_DELETE_REPORT', payload }),
    updateReport: (payload) => ({ type: 'ONSITE_UPDATE_REPORT', payload }),
    reportLoading: (payload) => ({ type: 'ONSITE_IS_LOADING', payload }),
    refreshReports: (payload) => ({ type: 'ONSITE_REFRESH_REPORTS', payload }),
    setActiveReport: (payload) => ({
        type: 'ONSITE_SET_ACTIVE_REPORT',
        payload,
    }),
    pinReport: (payload) => ({ type: 'ONSITE_PIN_REPORT', payload }),
    exportReport: (payload) => ({ type: 'ONSITE_EXPORT_STARTED', payload }),
    fetchReportData: (payload) => ({ type: 'ONSITE_REPORT_DATA', payload }),
    updateActiveReport: (payload) => ({ type: 'ONSITE_UPDATE_ACTIVE_REPORT', payload }),
    updateActiveMenu: (payload) => ({ type: 'SET_ACTIVE_MENU_ONSITE', payload }),
    setDateOnsite: (payload) => ({ type: 'SET_DATE_ONSITE', payload }),
    setTimeZoneOnsite: (payload) => ({ type: 'SET_TIMEZONE_ONSITE', payload }),
};

const initialState = {
    activeList: {},
    list: [],
    loading: false,
    pagination: {},
    error: {},
    reportSearching: false,
    exportStarted: false,
    reportData: {
        loading: false,
    },
    timeData: {
        startDate: getStartDate(),
        endDate: getEndDate(),
        timezone: momentTimezone.tz.guess(),
        activeMenu: 'absolute',
    }
};

function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case 'ONSITE_FETCH_REPORTS':
            return {
                ...state,
                ...action.payload,
            };
        case 'ONSITE_ADD_NEW_REPORT':
            return {
                ...state,
                list: action.payload,
            };
        case 'ONSITE_UPDATE_REPORT':
            return {
                ...state,
                list: state.list.map((item) =>
                    item._id === action.payload._id
                        ? { ...item, ...action.payload }
                        : item
                ),
            };
        case 'ONSITE_SET_ACTIVE_REPORT':
            return {
                ...state,
                activeList: state?.list?.filter(
                    (item) => item._id === action.payload._id
                )[0],
            };
        case 'ONSITE_UPDATE_ACTIVE_REPORT':
            return {
                ...state,
                activeList: { ...state.activeList, ...action.payload },
            };
        case 'ONSITE_PIN_REPORT':
            return {
                ...state,
                list: state.list.map((item) =>
                    item._id === action.payload._id
                        ? { ...item, ...action.payload }
                        : item
                ),
            };
        case 'ONSITE_EXPORT_STARTED':
            return {
                ...state,
                exportStarted: action.payload.exportStarted,
            };
        case 'ONSITE_REPORT_DATA':
            return {
                ...state,
                reportData: {
                    id: action.payload._id,
                    data: action?.payload?.data
                        ? action?.payload?.data
                        : state?.reportData?.data,
                    pagination: action?.payload?.pagination
                        ? action?.payload?.pagination
                        : state?.reportData?.pagination,
                    loading:
                        typeof action?.payload?.loading !== 'undefined'
                            ? action?.payload?.loading
                            : false,
                },
            };
        case 'ONSITE_IS_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'ONSITE_REFRESH_REPORTS':
            return {
                ...state,
                list: action.payload,
            };
        case 'ONSITE_DELETE_REPORT':
            return {
                ...state,
                list: state.list.filter(
                    (item) => item._id !== action.payload._id
                ),
            };
        case 'SET_ACTIVE_MENU_ONSITE':
            return {
                ...state,
                timeData: { ...state.timeData, ...action.payload },
            };
        case 'SET_DATE_ONSITE':
            return {
                ...state,
                timeData: { ...state.timeData, ...action.payload },
            };
        case 'SET_TIMEZONE':
            return {
                ...state,
                timeData: { ...state.timeData, ...action.payload },
            };
        default:
            return state;
    }
}

export default reportsReducer;
