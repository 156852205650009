import * as React from 'react';

const StreamURLIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}  >
                <path
                    d="M5.624 9.5h6.75M7.31 12.875H4.498a3.375 3.375 0 010-6.75H7.31M10.687 12.875h2.812a3.375 3.375 0 000-6.75h-2.813"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default StreamURLIcon;
