import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PageSpinner from 'components/app/common/pageSpinner';
import {
    getPageViews,
    exportPageViews,
    getPageViewsUsers,
    exportPageViewsUsers,
} from '../../api';
import FileSaver from 'file-saver';

import moment from 'moment';
import momentTimezone from 'moment-timezone';
import './index.scss';
import { Table, Modal, Menu, Dropdown, Button } from 'antd';
import { LivePageSelector, handleAnalyticsToken } from '../../helpers';
import { DownOutlined } from '@ant-design/icons';

const PAGE_SIZE = 10;

const PageViews = () => {
    const projectId = useSelector((state) => state?.header?.projectId);
    const [pageViews, setPageViews] = useState([]);
    const [isPageViewsLoading, setIsPageViewsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationInfo, setPagination] = useState({});
    const [isExporting, setExporting] = useState(false);
    const [hasNoData, setNoData] = useState(false);
    const pageFlag = useRef(false);
    const isMounted = useRef(false);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [peopleData, setPeopleData] = useState([]);
    const [peoplePaginationData, setPeoplePaginationData] = useState([]);
    const peoplePageFlag = useRef(false);
    const selectedPageName = useRef(null);
    const [currentPeoplePage, setPeopleCurrentPage] = useState(1);
    const [isPeopleDataLoading, setPeopleLoading] = useState(true);
    const [isPeopleExporting, setPeopleExporting] = useState(false);
    const [sortDetails, setSortDetails] = useState({
        order: 'DESC',
        by: 'totalViews',
    });
    const [pageViewsUsersSortDetails, setPageViewsUsersSortDetails] = useState({
        order: 'DESC',
        by: 'visit_last_action_time',
    });

    const { startDate, endDate, livePageId } = useSelector(
        (state) => state?.venueAnalytics
    );
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setLoading] = useState(true);

    const [error, setError] = useState('');

    const columns = [
        {
            title: <span className="columnHeading">Page Title</span>,
            dataIndex: 'pageName',
            key: 'pageName',
            sorter: true,
            render: (val, rowData) => {
                return rowData.pageName || '-';
            },
        },
        {
            title: <span className="columnHeading">Page Url</span>,
            dataIndex: 'pageUrl',
            key: 'pageUrl',
            sorter: true,
            render: (val, rowData) => {
                return rowData.pageUrl || '-';
            },
        },
        {
            title: <span className="columnHeading">Average Time Spent</span>,
            dataIndex: 'avgTimeSpent',
            key: 'avgTimeSpent',
            sorter: true,
            render: (val, rowData) => {
                return rowData.avgTimeSpent || '-';
            },
        },
        {
            title: <span className="columnHeading">Unique Views</span>,
            dataIndex: 'uniqueViews',
            key: 'uniqueViews',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalPlays"
                        onClick={() =>
                            handleUserModal({ ...rowData, view: 'unique' })
                        }
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
        {
            title: <span className="columnHeading">Total Views</span>,
            dataIndex: 'totalViews',
            key: 'totalViews',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalPlays"
                        onClick={() =>
                            handleUserModal({ ...rowData, view: 'total' })
                        }
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
    ];
    const pageViewsUsersColumns = [
        {
            title: <span className="columnHeading">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (val, rowData) => {
                return rowData.firstName || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (val, rowData) => {
                return rowData.lastName || '-';
            },
        },
        {
            title: <span className="columnHeading">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (val, rowData) => {
                return rowData.email || '-';
            },
        },

        {
            title: <span className="columnHeading">Company</span>,
            dataIndex: 'company',
            key: 'company',
            render: (val, rowData) => {
                return rowData.company || '-';
            },
        },

        {
            title: <span className="columnHeading">Job Title</span>,
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (val, rowData) => {
                return rowData.jobTitle || '-';
            },
        },
        {
            title: <span className="columnHeading">Page Title</span>,
            dataIndex: 'pageName',
            key: 'pageName',
            render: (val, rowData) => {
                return rowData.pageName || '-';
            },
        },
        {
            title: <span className="columnHeading">Page Url</span>,
            dataIndex: 'pageUrl',
            key: 'pageUrl',
            render: (val, rowData) => {
                return rowData.pageUrl || '-';
            },
        },
        {
            title: (
                <span className="columnHeading">
                    {selectedPageName.current?.view === 'total'
                        ? 'Total'
                        : 'Average'}{' '}
                    Time Spent
                </span>
            ),
            dataIndex:
                selectedPageName.current?.view === 'total'
                    ? 'timeSpent'
                    : 'avgTimeSpent',
            key:
                selectedPageName.current?.view === 'total'
                    ? 'timeSpent'
                    : 'avgTimeSpent',
            sorter: true,
            render: (val, rowData) => {
                return selectedPageName.current?.view === 'total'
                    ? rowData.timeSpent || '-'
                    : rowData.avgTimeSpent || '-';
            },
        },
    ];
    if (selectedPageName.current?.view === 'total')
        pageViewsUsersColumns.push({
            title: <span className="columnHeading">Timestamp</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (val, rowData) => {
                return rowData.timestamp || '-';
            },
        });

    const getPageViewsUsersData = async () => {
        try {
            setPeopleLoading(true);
            const { status, message, pageViews, pagination } =
                await getPageViewsUsers(
                    livePageId,
                    projectId,
                    timezone.toLowerCase(),
                    selectedPageName.current?.pageName,
                    selectedPageName.current?.view,
                    startDate,
                    endDate,
                    currentPeoplePage,
                    pageViewsUsersSortDetails?.by,
                    pageViewsUsersSortDetails?.order
                );
            if (status && pageViews.length > 0) {
                const parsedViews = pageViews.map((data, index) => ({
                    key: `${data.userId}-${index}`,
                    firstName: data?.firstname ? data.firstname : '',
                    lastName: data?.lastname ? data.lastname : '',
                    email: data?.email ? data.email : '',
                    company: data?.company ? data.company : '',
                    jobTitle: data?.jobTitle ? data.jobTitle : '',
                    pageName: data?.pageName ? data.pageName : '',
                    pageUrl: data?.pageUrl ? data.pageUrl : '',
                    timeSpent: data?.timeSpent ? data.timeSpent : '',
                    avgTimeSpent: data?.avgTimeSpent ? data.avgTimeSpent : '',
                    timestamp: data?.timestamp || '',
                }));
                setPeopleData(parsedViews);
                setPeoplePaginationData(pagination);
                setPeopleLoading(false);
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
            setPeopleData([]);
            setPeopleLoading(false);
        }
    };
    const handleUserModal = ({ pageName, view }) => {
        setModalVisibility(true);
        selectedPageName.current = { pageName, view };
        getPageViewsUsersData();
    };
    const exportPageViewsUsersData = async () => {
        try {
            setPeopleExporting(true);
            const response = await exportPageViewsUsers(
                livePageId,
                projectId,
                timezone.toLowerCase(),
                selectedPageName.current?.pageName,
                selectedPageName.current?.view,
                startDate,
                endDate
            );

            FileSaver.saveAs(
                response,
                `Page-Views-Users-Data-${moment().unix()}.csv`
            );

            setPeopleExporting(false);
        } catch (error) {
            console.error(error);
            setPeopleExporting(false);
        }
    };
    const handlePageViews = async (page = null) => {
        try {
            setIsPageViewsLoading(true);
            const { status, message, pageViews, pagination } =
                await getPageViews(
                    livePageId,
                    projectId,
                    timezone.toLowerCase(),
                    startDate,
                    endDate,
                    page ? page : currentPage,
                    PAGE_SIZE,
                    sortDetails?.by,
                    sortDetails?.order
                );
            if (status) {
                if (pageViews.length === 0) {
                    setNoData(true);
                } else {
                    setNoData(false);
                }

                const parsedPageViews = pageViews.map((data, index) => ({
                    key: `pageView-${index}-${currentPage}`,
                    ...data,
                }));

                setPageViews(parsedPageViews);
                setPagination(pagination);
                setIsPageViewsLoading(false);
            } else {
                setIsPageViewsLoading(false);
                throw new Error(message);
            }
        } catch (error) {
            if (error && error.message.includes('jwt expired')) {
                handleAnalyticsToken();
            }
            setIsPageViewsLoading(false);
            console.error(error);
        }
    };
    const handleExport = async (viewType = null) => {
        try {
            setExporting(true);
            const response = await exportPageViews(
                livePageId,
                projectId,
                timezone.toLowerCase(),
                startDate,
                endDate,
                viewType
            );

            FileSaver.saveAs(
                response,
                `Page-${viewType ? 'Views' : 'Viewership'}-${
                    viewType ? `${viewType}-` : ''
                }${moment().unix()}.csv`
            );
            setExporting(false);
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };

    const exportMenu = (
        <Menu
            onClick={({ key }) => {
                if (key === '1') handleExport('unique');
                else if (key === '2') handleExport('all');
                else handleExport();
            }}
        >
            <Menu.Item key="1">Unique visitors page views</Menu.Item>
            <Menu.Item key="2">All visitors page views</Menu.Item>
            <Menu.Item key="3">All pages viewership</Menu.Item>
        </Menu>
    );

    const handleChange = (pagination, filters, sorter) => {
        const { field = null, order = null } = sorter;
        if (field && order)
            setSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: field,
            });
    };
    const handleModalChange = (pagination, filters, sorter) => {
        const { order = null } = sorter;
        if (order)
            setPageViewsUsersSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: pageViewsUsersSortDetails?.by,
            });
    };
    useEffect(() => {
        if (livePageId && startDate && endDate) {
            if (currentPage !== 1) {
                setCurrentPage(1);
                pageFlag.current = false;
            }
            handlePageViews(1);
        }
    }, [livePageId, startDate, endDate, timezone]);

    useEffect(() => {
        if (pageFlag.current) {
            handlePageViews();
        }
        pageFlag.current = true;
    }, [currentPage]);

    useEffect(() => {
        if (peoplePageFlag.current) {
            getPageViewsUsersData();
        }
        peoplePageFlag.current = true;
    }, [currentPeoplePage]);

    useEffect(() => {
        if (!modalVisibility) {
            setPeopleData([]);
            setPeoplePaginationData([]);
            setPeopleCurrentPage(1);
            peoplePageFlag.current = false;
            selectedPageName.current = null;
        } else {
            peoplePageFlag.current = true;
        }
    }, [modalVisibility]);

    useEffect(() => {
        if (sortDetails?.order && sortDetails?.by && isMounted.current) {
            handlePageViews();
        }
        isMounted.current = true;
    }, [sortDetails]);
    useEffect(() => {
        if (
            modalVisibility && pageViewsUsersSortDetails?.order
            && pageViewsUsersSortDetails?.by && isMounted.current
        ) {
            getPageViewsUsersData();
        }
    }, [pageViewsUsersSortDetails]);

    return (
        <div className="interactionsAnalysisContainer">
            {!error && (
                <>
                    <LivePageSelector
                        {...{
                            projectId,
                            setLoading,
                            setError,
                            isLoading,
                        }}
                    />
                </>
            )}
            {error && <div className="errorContainer">{error}</div>}

            {isPageViewsLoading && (!pageViews || pageViews.length === 0) && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            )}

            {!error && pageViews.length > 0 && (
                <div className="visitsContainer">
                    <div className="titleContainer">
                        <h2 className="pageHeading">
                            Which page did visitors view?
                        </h2>
                        <span>
                            {`${(currentPage - 1) * PAGE_SIZE + 1}-${
                                (currentPage - 1) * PAGE_SIZE + pageViews.length
                            } of ${paginationInfo.total} records`}
                        </span>
                    </div>
                    <Table
                        dataSource={pageViews}
                        columns={columns}
                        onChange={handleChange}
                        loading={isPageViewsLoading}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: paginationInfo?.total,
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                    <div className="exportContainer">
                        <Dropdown overlay={exportMenu}>
                            <Button loading={isExporting}>
                                Export <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            )}

            {hasNoData && !isPageViewsLoading && (
                <div className="visitsContainer">
                    <div className="noUserContainer">No page views found</div>
                </div>
            )}
            <Modal
                width={1200}
                visible={modalVisibility}
                footer={null}
                onCancel={() => setModalVisibility(false)}
            >
                <div className="peopleModal">
                    <Table
                        style={{ width: '1200px' }}
                        dataSource={peopleData}
                        columns={pageViewsUsersColumns}
                        onChange={handleModalChange}
                        loading={isPeopleDataLoading}
                        bordered
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setPeopleCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: peoplePaginationData?.total,
                            showSizeChanger: false,
                            current: currentPeoplePage,
                        }}
                    />
                    {peopleData.length > 0 && !isPeopleDataLoading && (
                        <div className="exportContainer">
                            <div
                                onClick={() => {
                                    if (!isPeopleExporting)
                                        exportPageViewsUsersData();
                                }}
                            >
                                {isPeopleExporting
                                    ? 'Exporting...'
                                    : 'Export'}
                            </div>
                        </div>
                    )}
                    {peopleData.length === 0 && !isPeopleDataLoading && (
                        <div className="noRecords">No records found</div>
                    )}
                </div>
            </Modal>

            <style>{`
            ant-select-selection-search
            {
                height: 100%;
                line-height: 46px;

            }
            `}</style>
        </div>
    );
};
export default PageViews;
