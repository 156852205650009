import React, { useState, useEffect, useRef, useCallback, memo } from 'react';

import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { useDropzone } from 'react-dropzone';

import {
    uploadsUrlToUse,
    isValidDimension,
} from 'components/app/utils/functions/common';

import Prompt from '../prompt';

import './fileUploader.scss';
import uuid from 'react-uuid';
import ZipIcon from '../icons/ZipIcon';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

function FileUploader(props) {
    const {
        fileType = 'image', // image, logo, backgroundImage, pdf, csv
        size = 'small', // small, large, xlarge
        multiple = false,
        currentFile,
        recommendedWidth,
        recommendedHeight,
        prepareFileUpload,
        prepareFileDelete,
        handleFileUpload,
        handleFileDelete,
        shouldClearFilesToBeUploaded,
        deleteUpload,
        showControls,
        controlsPosition,
        progress,
        inVisiblePreviousImages,
        uploadText,
        maxSize = 26214400,
    } = props;

    const [inputID, setInputID] = useState(uuid());
    const [input2ID, setInput2ID] = useState(uuid());
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
    const [deletePrompt, setDeletePrompt] = useState({ show: false });
    const [currentFiles, setCurrentFiles] = useState(currentFile);

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (currentFile !== currentFiles) {
            setCurrentFiles(currentFile);
        }
    }, [currentFile]);

    const renderIcon = () => {
        switch (fileType) {
            case 'venueFile':
                return <ZipIcon />;
            default:
                return null;
        }
    };

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        // /**
        //  * If there is a current file and the user clicks on the "Select File" in the UI,
        //  * the current file will be marked as file to be deleted in the server
        //  */
        // if (!isEmpty(currentFileArr)) {
        //     setFilesToBeDeleted(currentFileArr);
        // }

        if (recommendedWidth && recommendedHeight) {
            const isValidResponse = await isValidDimension(
                acceptedFiles[0],
                recommendedWidth,
                recommendedHeight
            );

            if (!isValidResponse) {
                openNotification('error', {
                    message: `The image dimension is too big. Please try max ${recommendedWidth}px (w) and ${recommendedHeight}px (h)`,
                });

                return;
            }
        }

        setFilesToBeUploaded(acceptedFiles);
    }, []);

    /**
     * gets the accepted file types
     */
    const getAcceptedFileTypes = () => {
        let acceptedFileType = '';
        switch (fileType) {
            case 'pdf':
                acceptedFileType = 'application/pdf';
                break;
            case 'video':
                acceptedFileType = 'video/*';
                break;
            case 'csv':
                acceptedFileType = '.csv';
                break;
            case 'venueFile':
                acceptedFileType = '.zip';
                break;
            default:
                acceptedFileType = 'image/*';
        }

        return acceptedFileType;
    };

    let { getRootProps, getInputProps, isDragActive, isDragReject, inputRef } =
        useDropzone({
            accept: getAcceptedFileTypes(),
            multiple,
            onDrop,
            maxSize,
        });

    useEffect(() => {
        if (currentFile !== currentFiles) {
            setCurrentFiles(currentFile);
        }
    }, [currentFile]);

    useEffect(() => {
        if (shouldClearFilesToBeUploaded) {
            setFilesToBeUploaded([]);
        }
    }, [shouldClearFilesToBeUploaded]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        /**
         * `handleFileUpload` is instant upload (upon drag)
         * `prepareFileUpload` is non-instant upload (triggered by a separate button and not upon drag)
         */
        if (handleFileUpload) {
            handleFileUpload(filesToBeUploaded, fileType);
        } else {
            if (prepareFileUpload) {
                prepareFileUpload(filesToBeUploaded, fileType);
            }
        }
    }, [filesToBeUploaded]);

    useEffect(() => {
        if (handleFileDelete) {
            handleFileDelete(filesToBeDeleted, fileType);
        } else {
            if (prepareFileDelete) {
                prepareFileDelete(filesToBeDeleted, fileType);
            }
        }
    }, [filesToBeDeleted]);

    useEffect(() => {
        if (currentFile && !isArray(currentFile) && !isEmpty(currentFile)) {
            setCurrentFiles([currentFile]);
        }
    }, [currentFile]);

    /**
     * Need to convert currentFile into an array so that the mapping will still work whether it's
     * an array or an object (some content types will return just an object instead of an array of objects)
     */
    // let currentFileArr = [];

    // if (!isEmpty(currentFile)) {
    //     if (
    //         (!Array.isArray(currentFile) && typeof currentFile === 'object') ||
    //         typeof currentFile === 'string'
    //     ) {
    //         currentFileArr.push(currentFile);
    //     } else {
    //         currentFileArr = currentFile;
    //     }
    // }

    /**
     * renders the upload text depending on the uploader size
     */
    const renderUploaderInnerUi = () => {
        const pluralization = multiple ? 's' : '';

        switch (size) {
            case 'xlarge':
                return (
                    <div className="file-uploader__text-group">
                        <img
                            src="/assets/icon_upload.svg"
                            alt=""
                            className="mb-3"
                        />
                        <h3>Upload your file{pluralization} here</h3>

                        <p>
                            Drag and drop your file{pluralization} here or click
                            to browse
                            <br />
                            Only {fileType} files are supported.
                        </p>
                    </div>
                );
            case 'large':
                return (
                    <span>
                        Drop {fileType} image{pluralization} here or click to
                        browse
                    </span>
                );
            default:
                return filesToBeUploaded &&
                    filesToBeUploaded.length &&
                    fileType === 'venueFile' ? (
                    <p className="preview-file">
                        {renderIcon()}
                        {filesToBeUploaded[0].name && (
                            <span className="name">
                                {filesToBeUploaded[0].name}
                            </span>
                        )}
                        {filesToBeUploaded[0].size && (
                            <span className="size">
                                {bytesToSize(filesToBeUploaded[0].size)}
                            </span>
                        )}
                    </p>
                ) : (
                    <span className="file-uploader__text">Drop</span>
                );
        }
    };

    /**
     * Wraps a text in a link
     * @param {string} text text to be wrapped in link
     * @param {string} link link to wrap text in
     */
    const wrapTextInLink = (text, link) => {
        let linkPath;

        if (link.includes('blob')) linkPath = link;
        else linkPath = uploadsUrlToUse(link);

        return (
            <a href={linkPath} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
    };

    /**
     * Render Thumbnail Uploader behavior
     * based on whether there's an existing thumbnail or none
     */
    const renderThumbnailUploader = () => {
        const hasFilesToBeUploaded = filesToBeUploaded.length > 0;

        /** as long as there are files to be uploaded, means displaying it must take precedence over any current uploads */
        if (hasFilesToBeUploaded) {
            return (
                <div
                    className="file-uploader__image-preview 1"
                    style={{
                        backgroundImage: `url(${URL.createObjectURL(
                            filesToBeUploaded[0]
                        )}`,
                    }}
                />
            );
        }

        /** if there are no files to be uploaded but there is a current file, show current first */
        if (currentFiles && currentFiles.length > 0 && currentFiles[0]) {
            return (
                <div className="file-uploader__image-preview 2">
                    <img
                        src={`${currentFiles?.[0]?.url || currentFiles[0]}`}
                        alt=""
                        style={{ width: 'inherit' }}
                    />
                </div>
            );
        }
    };

    /**
     * Action Controls handlers
     * Select, View, Delete
     */
    const hasCurrentFile = () => {
        return !isEmpty(currentFiles);
    };

    const hasImageToShow = () => {
        return filesToBeUploaded.length > 0 || hasCurrentFile();
    };

    // const createRefId = () => {
    //     return uuid();
    //     // naghanap lang ako nang pwedeng reference para sa id
    //     const boxCount = document.querySelectorAll('.box-settings');

    //     // kung walang mahanap na reference, random number nalang ginawa ko
    //     const randomNumber = Math.floor(Math.random() * (100 - 1 + 1) + 1);

    //     // pero kung may maisip ka na unique at walang palya sa mga ibang field mas okay

    //     return boxCount.length ? `${boxCount.length + 1}` : randomNumber;
    // };

    const selectControl = () => {
        return (
            <li style={size === 'large' ? { marginLeft: '0' } : {}}>
                {filesToBeUploaded && filesToBeUploaded.length === 0 && (
                    <label
                        htmlFor={fileType + '-upload-' + inputID}
                        className="m-0 upload-control"
                    >
                        {uploadText || 'Select File'}
                    </label>
                )}
                {/* ang id nang input ay magkakapareho kaya laging nasa taas ang naaupdate */}
                <input
                    id={fileType + '-upload-' + inputID}
                    {...getInputProps()}
                />
            </li>
        );
    };

    const viewControl = () => {
        let href =
            filesToBeUploaded.length > 0
                ? URL.createObjectURL(filesToBeUploaded[0])
                : hasCurrentFile()
                ? (currentFiles && currentFiles.url) ||
                  (isArray(currentFiles) &&
                      currentFiles[0] &&
                      currentFiles[0].url) ||
                  currentFiles
                : '';

        return hasImageToShow() && typeof href === 'string' && href ? (
            <li>
                <a
                    className="upload-control"
                    href={href}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    View
                </a>
            </li>
        ) : (
            ''
        );
    };

    const deleteControl = () => {
        return hasImageToShow() ? (
            <li>
                <a
                    className="upload-control"
                    onClick={() => handleDeletePrompt()}
                >
                    Delete
                </a>
            </li>
        ) : (
            ''
        );
    };

    /** render select, view, delete controls of the uploader */
    const renderControls = () => {
        if (size === 'small' && showControls) {
            const renderedClass = `m-0 file-uploader2__controls __${size}`;
            const renderedStyle = {
                flexDirection:
                    controlsPosition === 'bottom' || controlsPosition === 'top'
                        ? 'row'
                        : 'column',
            };
            return (
                <ul className={renderedClass} style={renderedStyle}>
                    {selectControl()}
                    {viewControl()}
                    {deleteControl()}
                </ul>
            );
        }

        return '';
    };

    const onDelete = () => {
        if (!isEmpty(currentFiles)) {
            setFilesToBeDeleted(currentFiles);
            setCurrentFiles([]);
        }

        // reset the input
        inputRef.current.value = '';

        setFilesToBeUploaded([]);
        setDeletePrompt({ show: false });
    };

    const handleClosePrompt = () => setDeletePrompt({ show: false });
    const handleDeletePrompt = () => setDeletePrompt({ show: true });

    const renderPrompt = () => {
        return (
            <Prompt
                show={deletePrompt.show}
                message="Are you sure you want to delete?"
                buttons={['Cancel', 'Delete']}
                onHide={() => handleClosePrompt()}
                callback={() => onDelete()}
            />
        );
    };

    /**
     * renders currently uploaded files from server
     */
    const renderCurrentFiles = () => {
        const hasCurrentFiles =
            currentFiles && currentFiles.length > 0 && currentFiles[0];

        const renderCurrentFilesXlarge = () => {
            return (
                <div className="file-uploader__file-list file-uploader__file-list--xlarge">
                    <img
                        src="/assets/icon_complete.svg"
                        alt=""
                        className="mb-2"
                    />
                    <h3>Upload complete</h3>
                    <ul className="mb-2">
                        {currentFiles.map((file, index) => {
                            return (
                                <li key={file.id}>
                                    <div>
                                        {file.name}{' '}
                                        {deleteUpload && (
                                            <span
                                                className="file-uploader__file-thumbnail-action"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteUpload(file.id);
                                                }}
                                            >
                                                (Remove)
                                            </span>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="file-uploader__progress">
                        <div
                            className="file-uploader__progress-bar"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            );
        };

        const renderCurrentFilesLarge = () => {
            if (inVisiblePreviousImages && !isEmpty(filesToBeUploaded))
                return null;
            return (
                <div className="file-uploader__file-list file-uploader__file-list--large">
                    <ul>
                        {currentFiles.map((file, index) => {
                            return (
                                <li key={file.id}>
                                    <div>
                                        {fileType === 'image' && (
                                            <img
                                                src={`${uploadsUrlToUse(
                                                    currentFiles[index].url
                                                )}`}
                                                className="file-uploader__file-thumbnail"
                                                alt="Thumbnail Preview"
                                            />
                                        )}{' '}
                                        {fileType === 'pdf' &&
                                            wrapTextInLink(file.name, file.url)}
                                        {(fileType === 'csv' ||
                                            fileType === 'image') &&
                                            file.name}
                                    </div>

                                    {deleteUpload && (
                                        <div>
                                            <span className="mr-3">
                                                <a
                                                    target="_blank"
                                                    href={`${uploadsUrlToUse(
                                                        currentFiles[index].url
                                                    )}`}
                                                >
                                                    View
                                                </a>
                                            </span>
                                            <span
                                                className="file-uploader__file-thumbnail-action"
                                                onClick={() =>
                                                    deleteUpload(file.id)
                                                }
                                            >
                                                Remove
                                            </span>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        };

        if (hasCurrentFiles) {
            if (size === 'large') {
                return renderCurrentFilesLarge();
            }

            if (size === 'xlarge') {
                return renderCurrentFilesXlarge();
            }
        }
    };

    /**
     * renders files to be uploaded from local computer
     */
    const renderFilesToBeUploaded = () => {
        const renderFilesToBeUploadedLarge = () => {
            return (
                <div className="file-uploader__file-list-pending file-uploader__file-list-pending--large">
                    <ul>
                        {filesToBeUploaded.map((file, index) => {
                            return (
                                <li key={index}>
                                    <div>
                                        {fileType === 'image' && (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                className="file-uploader__file-thumbnail"
                                                alt="Thumbnail Preview"
                                            />
                                        )}{' '}
                                        {fileType === 'pdf' ||
                                        fileType === 'csv'
                                            ? wrapTextInLink(
                                                  file.name,
                                                  URL.createObjectURL(file)
                                              )
                                            : file.name}
                                    </div>

                                    <span
                                        className="file-uploader__file-thumbnail-action"
                                        onClick={() => {
                                            let filterFiles = [];
                                            for (var f in filesToBeUploaded) {
                                                if (
                                                    filesToBeUploaded.hasOwnProperty(
                                                        f
                                                    ) &&
                                                    filesToBeUploaded[f]
                                                        .name !== file.name
                                                ) {
                                                    filterFiles.push(
                                                        filesToBeUploaded[f]
                                                    );
                                                }
                                            }
                                            setFilesToBeUploaded(filterFiles);
                                        }}
                                    >
                                        Remove
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        };

        const renderFilesToBeUploadedXlarge = () => {
            return (
                <div className="file-uploader__file-list-pending file-uploader__file-list-pending--xlarge">
                    <img
                        src="/assets/icon_upload.svg"
                        alt=""
                        className="mb-3"
                    />
                    <ul className="mb-2">
                        {filesToBeUploaded.map((file, index) => {
                            return (
                                <li key={index}>
                                    <div>{file.name}</div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="file-uploader__progress">
                        <div
                            className="file-uploader__progress-bar"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                </div>
            );
        };

        if (filesToBeUploaded.length > 0) {
            if (size === 'large') {
                return renderFilesToBeUploadedLarge();
            }

            if (size === 'xlarge') {
                return renderFilesToBeUploadedXlarge();
            }
        }
    };

    return (
        <div className="file-uploader mb-2">
            <div
                style={{
                    display:
                        controlsPosition === 'bottom' ||
                        controlsPosition === 'top'
                            ? 'inherit'
                            : 'flex',
                }}
            >
                {/* image box container */}
                <div
                    {...getRootProps()}
                    className={
                        filesToBeUploaded && filesToBeUploaded.length
                            ? 'file-preview'
                            : `file-uploader__drag-area file-uploader__drag-area--${size}`
                    }
                >
                    <input
                        id={`${fileType}-main-${input2ID}`}
                        {...getInputProps()}
                    />

                    {isDragReject && (
                        <p className="file-uploader__reject">
                            Sorry, the file must be in {fileType} format!
                        </p>
                    )}

                    {!isDragActive &&
                        !(filesToBeUploaded.length > 0) &&
                        renderUploaderInnerUi()}
                    {isDragActive && !isDragReject && <p>Drop your files!</p>}

                    {size === 'small' &&
                        fileType !== 'venueFile' &&
                        renderThumbnailUploader()}
                </div>

                {/* show thumbnails and name of files, xlarge layout is within this area */}
                {size === 'xlarge' && (
                    <>
                        {renderCurrentFiles()}
                        {renderFilesToBeUploaded()}
                    </>
                )}

                {/* select, view, delete */}
                {renderControls()}

                {/* delete prompt */}
                {renderPrompt()}
            </div>

            {/* show thumbnails and name of files */}
            {size === 'large' && (
                <>
                    {renderCurrentFiles()}
                    {renderFilesToBeUploaded()}
                </>
            )}
        </div>
    );
}

FileUploader.propTypes = {
    fileType: PropTypes.string,
    size: PropTypes.string,
    recommendedWidth: PropTypes.number,
    recommendedHeight: PropTypes.number,
    multiple: PropTypes.bool,
    currentFile: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),

    prepareFileUpload: PropTypes.func,
    deleteUpload: PropTypes.func,

    shouldClearFilesToBeUploaded: PropTypes.bool,
    showControls: PropTypes.bool,
    controlsPosition: PropTypes.string,

    progress: PropTypes.number,
    inVisiblePreviousImages: PropTypes.bool,
};

FileUploader.defaultProps = {
    size: 'small',
    multiple: false,
    showControls: true,
    controlsPosition: 'right',
    inVisiblePreviousImages: false,
};

const MemoizedFileUploader = memo(FileUploader);

export default MemoizedFileUploader;
