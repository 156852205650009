import React, { useEffect, useState } from "react";

import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function ImageGallery(props) {
  const { handleInputChange,
    prepareFileUpload,
    input,
    renderFieldError,
    handleFileDelete,
    blockContentData = null,
    preventEditName = false,
    preventEditSize = false,
    preventEditTitle = false,
    permission = null,
  } = props;

  const showcaseblock = blockContentData?.showcaseblock
  const [permissionSize, setPermissionSize] = useState(null)
  const [permissionDimension, setPermissionDimension] = useState(null)

  useEffect(() => {
    if (permission !== permissionSize) {
      const currentPermissonSize = permission[0].dimension
      setPermissionSize(permission)
      if (currentPermissonSize) {
        Object.keys(currentPermissonSize).map(k => {
          if (showcaseblock?.name === k) {
            setPermissionDimension(currentPermissonSize[k])
          }
        })
      }

    }
  }, [permission])

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="input-set">
            <label>Block title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              onChange={handleInputChange}
              autoFocus
              value={showcaseblock?.title || input.title}
              disabled={preventEditTitle}
              maxLength={70}
            />
            {renderFieldError("title")}
          </div>
          {!preventEditSize &&
            <>
              <label>Block size</label>
              <div className="radio-set">
                <span>
                  <input
                    type="radio"
                    value={false}
                    name="largeTile"
                    defaultChecked
                    onChange={handleInputChange}
                    disabled={preventEditSize}
                    id="normalBlockSize"
                  />{" "}
                  <label htmlFor="normalBlockSize">Normal</label>
                </span>
                <span>
                  <input
                    type="radio"
                    value={true}
                    name="largeTile"
                    disabled={preventEditSize}
                    onChange={handleInputChange}
                    id="bigBlockSize"
                  />{" "}
                  <label htmlFor="bigBlockSize">Big</label>
                </span>
              </div>
            </>
          }

        </div>
        <div className="col-6">  </div>
      </div>

      <label>Your images </label>
      <p>
        Upload your image(s) below. (File types accepted: .gif, .jpg/jpeg, .png,
        .svg)
      </p>
      <p className="form-text text-muted">Max File Size - 5MB, Max Files - 10, Block Capacity - 10 MB</p>
      <div>
        <ImageUpload
          multiple={true}
          prepareFilesUpload={files => {
            prepareFileUpload(files, 'image');
          }}
          handleFileDelete={(file) => {
            handleFileDelete(file, 'image')
          }}
          imageCropRecommendedSize={{
            width: (permissionDimension &&
              permissionDimension[0]) ||
              null,
            height: (permissionDimension &&
              permissionDimension[1]) ||
              null
          }}
        />
        {permissionDimension &&
          <p>Recommended size: within {permissionDimension[0]} x {permissionDimension[1]} px</p>
        }
        {renderFieldError("image")}
      </div>
    </div>
  );
}

export default ImageGallery;
