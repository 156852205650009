import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * get the Integration settings if subscribed to Microsoft
 */
export async function getIntegrationSettings(projectId) {
    if (projectId) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/integrations/${projectId}`,
            {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_X_API_KEY,
                },
            }
        );

        return await response.json();
    }
}

/**
 * Login with Microsoft
 */
export async function loginWithAzure(code, projectId) {
    if (code && projectId) {
        const data = {
            code,
            projectId,
        };

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/auth/azuread`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    }
}
