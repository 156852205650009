import React from 'react';
import { Row, Col, Progress, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { limitsByPlans } from 'ComponentsV2/Plans/limits.js';

const colorForLimitExceed = '#F24343';
const colorForLimitNotExceed = '#9BA8B2';

const quotaIds = {
    organiserUserCount: 'organiserUserQuota',
    peopleCount: 'peopleQuota',
    sponsorUserCount: 'sponsorUserQuota',
    customDomainCount: 'customDomainQuota',
};

const summaries = [
    {
        title: 'Attendees',
        id: 'peopleCount',
        tooltipText: 'Attendees Usage',
    },
    {
        title: 'Sponsor Users',
        id: 'sponsorUserCount',
        tooltipText: 'Sponsor Users Usage',
    },
    {
        title: 'Organizer Users',
        id: 'organiserUserCount',
    },
    {
        title: 'Custom Domain',
        id: 'customDomainCount',
    },
];

const UsageSummary = ({ usage, companyActivePlan, isCompanyOnProPlan }) => {
    const usageTooltip = 'This will show usage per billing cycle.';
    return (
        <>
            <div className="usage-duration-heading">
                Usage for {moment(usage?.start).format('D MMM YYYY')} to{' '}
                {moment(usage?.end).format('D MMM YYYY')}
                <Tooltip
                    className="mx-1"
                    placement="right"
                    title={usageTooltip}
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <Row gutter={16}>
                {summaries.map((summary) => {
                    const currentUsage = usage?.usage[summary.id];
                    const limit =
                        Object.keys(usage?.quota || {}).length &&
                        quotaIds[summary.id]
                            ? usage?.quota[quotaIds[summary.id]]
                            : companyActivePlan[summary.id];
                    const isLimitExceed = currentUsage > limit;
                    const percent = (currentUsage / limit) * 100;
                    const showLimit = isCompanyOnProPlan;
                    return (
                        <Col span={8} key={summary.id}>
                            <div className="usage-card tw-mb-4">
                                <div className="usage-card-title mb-3">
                                    {summary.title}
                                    {summary?.tooltipText && (
                                        <Tooltip
                                            className="mx-1"
                                            placement="right"
                                            title={summary?.tooltipText}
                                        >
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    )}
                                </div>
                                <div className="usage-numbers mb-2">
                                    <span className="current-usage mr-1">
                                        {currentUsage}
                                    </span>
                                    {showLimit ? `/${limit}` : ''}
                                </div>
                                {showLimit && (
                                    <Progress
                                        percent={percent}
                                        showInfo={false}
                                        strokeColor={
                                            isLimitExceed
                                                ? colorForLimitExceed
                                                : colorForLimitNotExceed
                                        }
                                        size="small"
                                        trailColor="#fff"
                                    />
                                )}
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default UsageSummary;
