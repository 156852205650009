import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/react-hooks';

import * as ls from 'local-storage';
import { produce } from 'immer';
import { Helmet } from 'react-helmet';

import { convertToSlug } from 'components/app/utils/functions/common';

import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import useIsMount from 'components/app/utils/hooks/useIsMount';

import defaultShowcase from 'components/app/utils/dataList/defaultShowcase';

import showcaseNameValidationCriteria from 'components/app/utils/validators/showcaseNameValidator';

import PEOPLE from 'apis/graphql/People';
import SHOWCASES from 'apis/graphql/Showcases';
import UPDATE_SHOWCASE from 'apis/graphql/UpdateShowcase';
import DELETE_SHOWCASE from 'apis/graphql/DeleteShowcase';
import CREATE_SHOWCASE from 'apis/graphql/CreateShowcase';
import CREATE_SHOWCASEBLOCK from 'apis/graphql/CreateShowcaseblock';

import Layout from 'components/app/common/layout';
import Alert from 'components/app/common/alert';
import OutsideAlerter from 'components/app/common/outsideAlerter';
import OptionTooltip from 'components/app/common/optionTooltip';

import './showcaseDashboard.scss';
import { useSelector } from 'react-redux';

function ShowcaseDashboard(props) {
    const {
        match: { params },
    } = props;

    const personId = params.personId;

    const user = useSelector(state => state.user)
    const companyId = user.companyId;

    const isMount = useIsMount();

    const { alert, showAlert } = useAlert();

    const [tooltipVisibility, setTooltipVisibility] = useState(false);
    const [editMode, setEditMode] = useState({
        showcaseId: '',
        isEditMode: false,
    });
    const [input] = useState({
        person: personId,
        ...defaultShowcase,
    });
    const [showcases, setShowcases] = useState([]);
    const [selectedShowcase, setSelectedShowcase] = useState({});
    const [isValidProfileId, setIsValidProfileId] = useState(null);

    const { data } = useQuery(SHOWCASES, {
        variables: { where: { person: personId } },
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const [createShowcase] = useMutation(CREATE_SHOWCASE, {
        onCompleted: (data) => {
            const showcaseId = data.createShowcase.showcase.id;
            showAlert('Showcase created!', 'success');

            toggleEditTemplateName(showcaseId);
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const [updateShowcase] = useMutation(UPDATE_SHOWCASE, {
        onCompleted: (data) => {
            showAlert('Showcase title updated!', 'success');
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const [deleteShowcase] = useMutation(DELETE_SHOWCASE, {
        onCompleted: (data) => {
            showAlert('Showcase deleted!', 'success');
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const { data: peopleData } = useQuery(PEOPLE, {
        variables: { where: { projectId: companyId } },
        notifyOnNetworkStatusChange: true,
    });

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input: selectedShowcase,
        validationCriteria: showcaseNameValidationCriteria,
    });

    useEffect(() => {
        /**
         * @todo validates whether the current `personId` contains
         * the logged-in user's `projectId`. to prevent other users from
         * accessing another project / user's showcases. need to have proper
         * authentication later on
         */
        if (peopleData) {
            const doesShowcaseIdExist = peopleData.people.some(
                (person) => person.id === personId
            );
            setIsValidProfileId(doesShowcaseIdExist);
        }
    }, [peopleData, personId]);

    useEffect(() => {
        setShowcases(data && data.showcases);
    }, [data]);

    useEffect(() => {
        if (!isMount) {
            const formErrors = validateInput();

            if (!hasErrors(formErrors)) {
                toggleEditTemplateName(selectedShowcase.id);

                updateShowcase({
                    variables: {
                        input: {
                            data: {
                                title: selectedShowcase.title,
                                slug: convertToSlug(selectedShowcase.title),
                            },
                            where: {
                                id: selectedShowcase.id,
                            },
                        },
                    },
                    refetchQueries: [
                        {
                            query: SHOWCASES,
                            variables: { where: { person: personId } },
                        },
                    ],
                });
            }
        }
    }, [selectedShowcase]);

    /**
     * updates showcase title
     * @param {string} showcaseId showcase id to be updated
     */
    const updateShowcaseTitle = (showcaseId) => {
        const selectedShowcase = showcases.find((showcase) => {
            return showcase.id === showcaseId;
        });

        /** changing selected showcase will trigger useEffect
         * which submits the update to the API
         */
        setSelectedShowcase(selectedShowcase);
    };

    /**
     * opens template selector
     */
    const addShowcase = () => {
        setTooltipVisibility(true);
    };

    /**
     * deletes a showcase
     * @param {string} showcaseId showcase id to be deleted
     */
    const handleDeleteShowcase = (showcaseId) => {
        let isDeleteConfirmed = window.confirm(
            'Are you sure you want to delete this showcase?'
        );

        if (isDeleteConfirmed) {
            deleteShowcase({
                variables: {
                    input: {
                        where: { id: showcaseId },
                    },
                },
                refetchQueries: [
                    {
                        query: SHOWCASES,
                        variables: { where: { person: personId } },
                    },
                ],
            });
        }
    };

    /**
     * adds a new showcase (with a particular template type)
     */
    const addShowcaseWithTemplateType = () => {
        createShowcase({
            variables: {
                input: {
                    data: input,
                },
            },
            refetchQueries: [
                {
                    query: SHOWCASES,
                    variables: { where: { person: personId } },
                },
            ],
        });

        setTooltipVisibility(false);
    };

    /**
     * opens template content
     */
    const openTemplateContent = (showcaseId) => {
        const { history } = props;

        history.push(`/p/${personId}/showcase/c/${showcaseId}`);
    };

    /**
     * handles change to template name input
     * @param {object} e event object
     * @param {string} showcaseId showcase id
     */
    const handleInputChange = (e, showcaseId) => {
        const value = e.target.value;

        let nextState = produce(showcases, (draftState) => {
            const selectedShowcase = draftState.find((showcase) => {
                return showcase.id === showcaseId;
            });

            selectedShowcase.title = value;
        });

        setShowcases(nextState);
    };

    /**
     * toggles template name input field
     */
    const toggleEditTemplateName = (showcaseId) => {
        let nextState = produce(editMode, (draftState) => {
            if (draftState.isEditMode === false) {
                draftState.showcaseId = showcaseId;
            } else {
                draftState.showcaseId = '';
            }

            draftState.isEditMode = !draftState.isEditMode;
        });

        setEditMode(nextState);
    };

    /**
     * renders template list
     */
    const renderShowcaseList = () => {
        return showcases.map((showcase, index) => {
            return (
                <li key={index} className="showcase-dashboard__template">
                    <span
                        className="showcase-dashboard__template-delete"
                        onClick={() => handleDeleteShowcase(showcase.id)}
                    ></span>
                    <div
                        className={`showcase-dashboard__template-box showcase-dashboard__template-box--${showcase.templateType}`}
                        onClick={() => openTemplateContent(showcase.id)}
                    ></div>
                    <div>
                        {showcase.id !== editMode.showcaseId && (
                            <p
                                className="showcase-dashboard__template-name"
                                onClick={() =>
                                    toggleEditTemplateName(showcase.id)
                                }
                            >
                                {showcase.title}
                            </p>
                        )}
                        {showcase.id === editMode.showcaseId &&
                            editMode.isEditMode === true && (
                                <>
                                    <input
                                        className="showcase-dashboard__template-input"
                                        type="text"
                                        value={showcase.title}
                                        onChange={(e) =>
                                            handleInputChange(e, showcase.id)
                                        }
                                        onBlur={() => {
                                            updateShowcaseTitle(showcase.id);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                updateShowcaseTitle(
                                                    showcase.id
                                                );
                                            }
                                        }}
                                        maxLength={25}
                                        autoFocus
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                    <div className="text-center">
                                        {renderFieldError('title')}
                                    </div>
                                </>
                            )}
                    </div>
                </li>
            );
        });
    };

    const templateOptions = [
        {
            name: 'Tile',
            action: addShowcaseWithTemplateType,
        },
    ];

    return (
        <Layout>
            <Helmet>
                <title>Showcase Manager - GEVME XP</title>
            </Helmet>
            <Alert alert={alert}></Alert>
            <div className="showcase-dashboard">
                {showcases && isValidProfileId !== null && isValidProfileId && (
                    <ul className="showcase-dashboard__list">
                        {renderShowcaseList()}
                        <li onClick={addShowcase}>
                            <OutsideAlerter
                                payload={{
                                    elementVisibility: tooltipVisibility,
                                    setElementVisibility: setTooltipVisibility,
                                }}
                            >
                                <OptionTooltip
                                    title={'Choose a showcase template:'}
                                    visibility={tooltipVisibility}
                                    options={templateOptions}
                                    leftMargin={120}
                                ></OptionTooltip>
                            </OutsideAlerter>

                            <div className="showcase-dashboard__template-box showcase-dashboard__template-box--add-tile"></div>
                            <p className="showcase-dashboard__template-name">
                                Add a<br />
                                showcase
                            </p>
                        </li>
                    </ul>
                )}

                {showcases &&
                    isValidProfileId !== null &&
                    !isValidProfileId && (
                        <h5>Sorry that profile id is invalid! :(</h5>
                    )}

                {!showcases && (
                    <ul className="showcase-dashboard__loading">
                        {[1, 2, 3].map((item, index) => {
                            return <li key={index}></li>;
                        })}
                    </ul>
                )}
            </div>
        </Layout>
    );
}

export default withRouter(ShowcaseDashboard);
