const timeSlotOptions = [
    {
        key: 1,
        value: 5,
        text: '5 mins',
    },
    {
        key: 2,
        value: 15,
        text: '15 mins',
    },
    {
        key: 3,
        value: 30,
        text: '30 mins',
    },
    {
        key: 3,
        value: 45,
        text: '45 mins',
    },
    {
        key: 4,
        value: 60,
        text: '1 hr',
    },
    {
        key: 4,
        value: 90,
        text: '90 mins',
    },
    {
        key: 4,
        value: 120,
        text: '2 hr',
    },
];
export const initialState = {
    timeSlotOptions,
    slotDuration: timeSlotOptions[1].value,
    meetReminderTime: [{ unit: 'minute', value: 5 }],
    meetingsAllowedCount: 0,
    timeRanges: [],
    locations: [],
    defaultData: { attendees: [], organiser: [] },
    noOfAllowedParticipantPerMeeting: 5,
    disableEmailNotification: false,
    disableDailyDigestEmail: true,
    networkingPageId: '',
    bccEmail: null,
};

function meetingsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_TIME_SLOT_DURATION':
            return { ...state, slotDuration: action.payload };
        case 'SET_MEET_REMINDER_TIME':
            return { ...state, meetReminderTime: action.payload };
        case 'SET_MEET_COUNT':
            return { ...state, meetingsAllowedCount: action.payload };
        case 'SET_MEET_PARTICIPANT_COUNT':
            return {
                ...state,
                noOfAllowedParticipantPerMeeting: action.payload,
            };
        case 'SET_DISABLE_EMAIL_NOTIFICATION':
            return { ...state, disableEmailNotification: action.payload };
        case 'SET_NETWORKING_PAGE_ID':
            return { ...state, networkingPageId: action.payload };
        case 'SET_DISABLE_DAILY_DIGEST':
            return { ...state, disableDailyDigestEmail: action.payload };
        case 'SET_DISABLE_SUMMARY_EMAIL':
            return { ...state, disableSummaryEmail: action.payload };
        case 'SET_DISABLE_PHYSICAL_MEETING':
            return { ...state, disablePhysicalMeeting: action.payload };
        case 'SET_DISABLE_ONLINE_MEETING':
            return { ...state, disableOnlineMeeting: action.payload };
        case 'SET_TIME_RANGES':
            return { ...state, timeRanges: action.payload };
        case 'SET_MEET_LOCATIONS':
            return { ...state, locations: action.payload };
        case 'SET_MEET_DEFAULT_DATA':
            return { ...state, defaultData: action.payload };
        case 'SET_BCC_EMAIL':
            return { ...state, bccEmail: action.payload };
        case 'RESET_MEETINGS_STORE':
            return { ...initialState };
        default:
            return state;
    }
}

export default meetingsReducer;
