import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PageSpinner from 'components/app/common/pageSpinner';
import {
    getContentInteractions,
    exportContentInteractions,
    getContentInteractionUsers,
    exportContentInteractionUsers,
} from '../../api';
import FileSaver from 'file-saver';

import moment from 'moment';
import './index.scss';
import { Table, Modal, Menu, Dropdown, Button } from 'antd';
import { LivePageSelector, handleAnalyticsToken } from '../../helpers';
import { DownOutlined } from '@ant-design/icons';

const PAGE_SIZE = 10;

const ContentInteractions = () => {
    const projectId = useSelector((state) => state?.header?.projectId);
    const [interactions, setInteractions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationInfo, setPagination] = useState({});
    const [isExporting, setExporting] = useState(false);
    const [hasNoData, setNoData] = useState(false);
    const pageFlag = useRef(false);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [peopleData, setPeopleData] = useState([]);
    const [peoplePaginationData, setPeoplePaginationData] = useState([]);
    const peoplePageFlag = useRef(false);
    const selectedContent = useRef(null);
    const [currentPeoplePage, setPeopleCurrentPage] = useState(1);
    const [isPeopleDataLoading, setPeopleLoading] = useState(true);
    const [isPeopleExporting, setPeopleExporting] = useState(false);

    const [modalNestedVisibility, setModalNestedVisibility] = useState(false);
    const [nestedTableLoading, setNestedTableLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [nestedInteractions, setNestedInteractions] = useState([]);
    const [currentNestedPage, setCurrentNestedPage] = useState(1);
    const [paginationNestedInfo, setNestedPagination] = useState({});
    const [isNestedExporting, setNestedExporting] = useState(false);
    const [hasNoNestedData, setNoNestedData] = useState(false);
    const pageNestedFlag = useRef(false);
    const [peopleNestedData, setPeopleNestedData] = useState([]);
    const [peoplePaginationNestedData, setPeoplePaginationNestedData] =
        useState([]);
    const peoplePageNestedFlag = useRef(false);
    const selectedNestedContent = useRef(null);
    const activeNestedContentName = useRef(null);
    const activeNestedContentType = useRef(null);
    const activeNestedLivepageId = useRef(null);
    const isMounted = useRef(false);
    const [currentPeopleNestedPage, setPeopleCurrentNestedPage] = useState(1);
    const [isPeopleDataNestedLoading, setPeopleNestedLoading] = useState(true);
    const [isPeopleNestedExporting, setPeopleNestedExporting] = useState(false);
    const [contentLoading, setContentLoading] = useState(false);

    const [sortDetails, setSortDetails] = useState({
        order: 'DESC',
        by: 'total_clicks',
    });

    const [sortNestedDetails, setNestedSortDetails] = useState({
        order: false,
        by: null,
    });

    const [interationsUsersSortDetails, setInterationsUsersSortDetails] = useState({
        order: 'DESC',
        by: 'activity_time',
    });

    const htmlDecode = (input) => {
        try {
            var doc = new DOMParser().parseFromString(input, 'text/html');
            return input && input.length > 0
                ? decodeURIComponent(doc.documentElement.textContent)
                : '-';
        } catch (error) {
            return input && input.length > 0 ? decodeURIComponent(input) : '-';
        }
    };

    const {
        startDate,
        endDate,
        livePageId: livepageId,
    } = useSelector((state) => state?.venueAnalytics);
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setLoading] = useState(true);

    const [error, setError] = useState('');

    const interactionColumns = [
        {
            title: <span className="columnHeading">Content Name</span>,
            dataIndex: 'content_name',
            key: 'content_name',
            sorter: true,
            render: (val, rowData) => {
                return htmlDecode(rowData.content_name);
            },
        },
        {
            title: <span className="columnHeading">Page</span>,
            dataIndex: 'page',
            key: 'page',
            render: (val, rowData) => {
                return rowData.page || '-';
            },
        },
        {
            title: <span className="columnHeading">Total Clicks</span>,
            dataIndex: 'total_clicks',
            key: 'total_clicks',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalClicks"
                        onClick={() => handleUserModal(rowData, false)}
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
    ];

    const interactionNestedColumns = [
        {
            title: <span className="columnHeading">Content Target</span>,
            dataIndex: 'content_target',
            key: 'content_target',
            sorter: true,
            render: (val, rowData) => {
                return htmlDecode(rowData.content_target);
            },
        },
        {
            title: <span className="columnHeading">Page</span>,
            dataIndex: 'page',
            key: 'page',
            render: (val, rowData) => {
                return rowData.page || '-';
            },
        },
        {
            title: <span className="columnHeading">Total Clicks</span>,
            dataIndex: 'total_clicks',
            key: 'total_clicks',
            render: (val, rowData) => {
                return (
                    <a
                        className="totalClicks"
                        onClick={() => handleUserModal(rowData, true)}
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
    ];

    const interactionUserColumns = [
        {
            title: <span className="columnHeading">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (val, rowData) => {
                return rowData.firstName || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (val, rowData) => {
                return rowData.lastName || '-';
            },
        },
        {
            title: <span className="columnHeading">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (val, rowData) => {
                return rowData.email || '-';
            },
        },

        {
            title: <span className="columnHeading">Company</span>,
            dataIndex: 'company',
            key: 'company',
            render: (val, rowData) => {
                return rowData.company || '-';
            },
        },

        {
            title: <span className="columnHeading">Job Title</span>,
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (val, rowData) => {
                return rowData.jobTitle || '-';
            },
        },
        {
            title: <span className="columnHeading">Content Name</span>,
            dataIndex: 'contentName',
            key: 'contentName',
            render: (val, rowData) => {
                return htmlDecode(rowData.contentName);
            },
        },
        {
            title: <span className="columnHeading">Content Target</span>,
            dataIndex: 'contentTarget',
            key: 'contentTarget',
            render: (val, rowData) => {
                return htmlDecode(rowData.contentTarget);
            },
        },
        {
            title: <span className="columnHeading">Interaction Time</span>,
            dataIndex: 'interactionTime',
            key: 'interactionTime',
            sorter: true,
            render: (val, rowData) => {
                return htmlDecode(rowData.interactionTime);
            },
        },
    ];

    const getUsersData = async (isNested = false) => {
        try {
            if (isNested) {
                setPeopleNestedLoading(true);
            } else {
                setPeopleLoading(true);
            }
            const { status, message, interactions, pagination } =
                await getContentInteractionUsers(
                    isNested
                        ? selectedNestedContent.current?.livepageId
                        : selectedContent.current?.livepageId,
                    projectId,
                    timezone.toLowerCase(),
                    isNested
                        ? selectedNestedContent.current?.contentName
                        : selectedContent.current?.contentName,
                    isNested
                        ? selectedNestedContent.current?.contentType
                        : selectedContent.current?.contentType,
                    isNested
                        ? selectedNestedContent.current?.contentTarget
                        : null,
                    startDate,
                    endDate,
                    isNested ? currentPeopleNestedPage : currentPeoplePage,
                    interationsUsersSortDetails?.by,
                    interationsUsersSortDetails?.order
                );
            if (status && interactions.length > 0) {
                const parsedInteractions = interactions.map((data, index) => ({
                    key: `${data.userId}-${index}`,
                    firstName: data?.firstname ? data.firstname : '',
                    lastName: data?.lastname ? data.lastname : '',
                    email: data?.email ? data.email : '',
                    company: data?.company ? data.company : '',
                    jobTitle: data?.jobTitle ? data.jobTitle : '',
                    contentType: data?.contentType
                        ? data.contentType
                        : data.content_type
                        ? data?.content_type
                        : '',
                    contentName: data?.contentName
                        ? data.contentName
                        : data.content_name
                        ? data?.content_name
                        : '',
                    contentTarget: data?.contentTarget
                        ? data.contentTarget
                        : data.content_target
                        ? data?.content_target
                        : '',
                    interactionTime: data?.interaction_time || '',
                }));
                if (isNested) {
                    setPeopleNestedData(parsedInteractions);
                } else {
                    setPeopleData(parsedInteractions);
                }
                if (isNested) {
                    setPeoplePaginationNestedData(pagination);
                } else {
                    setPeoplePaginationData(pagination);
                }
                if (isNested) {
                    setPeopleNestedLoading(false);
                } else {
                    setPeopleLoading(false);
                }
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
            if (isNested) {
                setPeopleNestedData([]);
            } else {
                setPeopleData([]);
            }
            if (isNested) {
                setPeopleNestedLoading(false);
            } else {
                setPeopleLoading(false);
            }
        }
    };
    const handleUserModal = (data, isNested = false) => {
        if (isNested) {
            setModalNestedVisibility(true);
        } else {
            setModalVisibility(true);
        }
        if (isNested) {
            selectedNestedContent.current = {
                contentName: data?.contentName,
                contentTarget: data?.content_target,
                contentType: data?.contentType,
                livepageId: data?.livepageId,
            };
        } else {
            selectedContent.current = {
                contentName: data?.content_name,
                contentType: data?.contentType,
                livepageId: data?.livepageId,
            };
        }
        getUsersData(isNested);
    };
    const handleInteractionsData = async (page = null, isNested = false) => {
        try {
            if (isNested) {
                setNestedTableLoading(true);
                const { status, message, interactions, pagination } =
                    await getContentInteractions(
                        activeNestedLivepageId.current
                            ? activeNestedLivepageId.current
                            : livepageId,
                        projectId,
                        timezone.toLowerCase(),
                        startDate,
                        endDate,
                        activeNestedContentName.current,
                        activeNestedContentType.current,
                        page
                            ? page
                            : isNested
                            ? currentNestedPage
                            : currentPage,
                        PAGE_SIZE,
                        sortNestedDetails?.by,
                        sortNestedDetails?.order
                    );
                if (status) {
                    if (interactions.length === 0) {
                        setNoNestedData(true);
                    } else {
                        setNoNestedData(false);
                    }

                    const parsedInteractions = interactions.map(
                        (data, index) => ({
                            key: `${data.userId}-${index}`,
                            page: data?.page_slug || '',
                            contentType: data?.content_type || '',
                            contentName: data?.content_name || '',
                            content_target: data?.content_target || '',
                            interactionRate: data?.interaction_rate || '',
                            total_clicks: data?.total_clicks || '',
                            livepageId: data?.livepageId || '',
                        })
                    );
                    setNestedInteractions(parsedInteractions);
                    setNestedPagination(pagination);
                } else {
                    throw new Error(message);
                }
                setNestedTableLoading(false);
            } else {
                setContentLoading(true);
                const { status, message, interactions, pagination } =
                    await getContentInteractions(
                        livepageId,
                        projectId,
                        timezone.toLowerCase(),
                        startDate,
                        endDate,
                        null,
                        null,
                        page
                            ? page
                            : isNested
                            ? currentPage
                            : currentNestedPage,
                        PAGE_SIZE,
                        sortDetails?.by,
                        sortDetails?.order
                    );
                if (status) {
                    if (interactions.length === 0) {
                        setNoData(true);
                    } else {
                        setNoData(false);
                    }

                    const parsedInteractions = interactions.map(
                        (data, index) => ({
                            key: `${data.userId}-${index}`,
                            page: data?.page_slug || '',
                            contentType: data?.content_type || '',
                            content_name: data?.content_name || '',
                            contentTarget: data?.content_target || '',
                            interactionRate: data?.interaction_rate || '',
                            total_clicks: data?.total_clicks || '',
                            livepageId: data?.livepageId || '',
                        })
                    );

                    setInteractions(parsedInteractions);
                    setPagination(pagination);
                    setContentLoading(false);
                } else {
                    setContentLoading(false);
                    throw new Error(message);
                }
            }
        } catch (error) {
            if (error && error.message.includes('jwt expired')) {
                handleAnalyticsToken();
            }
            if (isNested) {
                setNestedTableLoading(false);
            } else setContentLoading(false);
            console.error(error);
        }
    };
    const exportInteractionsData = async (
        isNested = false,
        viewType = null
    ) => {
        try {
            if (isNested) {
                setNestedExporting(true);
            } else {
                setExporting(true);
            }
            const response = await exportContentInteractions(
                isNested
                    ? selectedNestedContent.current?.livepageId
                    : livepageId,
                projectId,
                timezone.toLowerCase(),
                startDate,
                endDate,
                isNested ? selectedNestedContent.current?.contentName : null,
                isNested ? selectedNestedContent.current?.contentType : null,
                viewType
            );

            FileSaver.saveAs(
                response,
                `Content-Interactions-${
                    viewType ? 'Visitors-' : ''
                }${moment().unix()}.csv`
            );

            if (isNested) {
                setNestedExporting(false);
            } else {
                setExporting(false);
            }
        } catch (error) {
            console.error(error);
            if (isNested) {
                setNestedExporting(false);
            } else {
                setExporting(false);
            }
        }
    };
    const exportUsersData = async (isNested = false) => {
        try {
            if (isNested) {
                setPeopleNestedExporting(true);
            } else {
                setPeopleExporting(true);
            }
            const response = await exportContentInteractionUsers(
                isNested
                    ? selectedNestedContent.current?.livepageId
                    : selectedContent.current?.livepageId,
                projectId,
                timezone.toLowerCase(),
                isNested
                    ? selectedNestedContent.current?.contentName
                    : selectedContent.current?.contentName,
                isNested
                    ? selectedNestedContent.current?.contentType
                    : selectedContent.current?.contentType,
                isNested ? selectedNestedContent.current?.contentTarget : null,
                startDate,
                endDate
            );

            FileSaver.saveAs(response, `Users-Data-${moment().unix()}.csv`);

            if (isNested) {
                setPeopleNestedExporting(false);
            } else {
                setPeopleExporting(false);
            }
        } catch (error) {
            console.error(error);
            if (isNested) {
                setPeopleNestedExporting(false);
            } else {
                setPeopleExporting(false);
            }
        }
    };

    const exportMenu = (
        <Menu
            onClick={({ key }) => {
                if (key === '1') exportInteractionsData(false, 'all');
                else exportInteractionsData();
            }}
        >
            <Menu.Item key="1">Visitor Content Interactions</Menu.Item>
            <Menu.Item key="2">Content Interactions</Menu.Item>
        </Menu>
    );

    const handleChange = (pagination, filters, sorter) => {
        const { field = null, order = null } = sorter;
        if (field && order) {
            setSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: field,
            });
            setExpandedRowKeys([]);
        }
    };

    const handleNestedChange = (pagination, filters, sorter) => {
        const { field = null, order = null } = sorter;
        if (field && order)
            setNestedSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: field,
            });
    };

    const handleModalChange = (pagination, filters, sorter) => {
        const { order = null } = sorter;
        if (order)
            setInterationsUsersSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: interationsUsersSortDetails?.by,
            });
    };

    useEffect(() => {
        if (livepageId && startDate && endDate) {
            if (currentPage !== 1) {
                setCurrentPage(1);
                pageFlag.current = false;
            }
            handleInteractionsData(1, false);
        }
    }, [livepageId, startDate, endDate, timezone]);

    useEffect(() => {
        if (pageFlag.current) {
            handleInteractionsData(null, false);
        }
        pageFlag.current = true;
    }, [currentPage]);

    useEffect(() => {
        if (pageNestedFlag.current) {
            handleInteractionsData(null, true);
        }
        pageNestedFlag.current = true;
    }, [currentNestedPage]);

    useEffect(() => {
        if (peoplePageFlag.current) {
            getUsersData(false);
        }
        peoplePageFlag.current = true;
    }, [currentPeoplePage]);

    useEffect(() => {
        if (peoplePageNestedFlag.current) {
            getUsersData(true);
        }
        peoplePageNestedFlag.current = true;
    }, [currentPeopleNestedPage]);

    useEffect(() => {
        if (!modalVisibility) {
            setPeopleData([]);
            setPeoplePaginationData([]);
            setPeopleCurrentPage(1);
            peoplePageFlag.current = false;
            selectedContent.current = {
                contentName: null,
                contentType: null,
                livepageId: '',
            };
        } else {
            peoplePageFlag.current = true;
        }
    }, [modalVisibility]);

    useEffect(() => {
        if (!modalNestedVisibility) {
            setPeopleNestedData([]);
            setPeoplePaginationNestedData([]);
            setPeopleCurrentNestedPage(1);
            peoplePageNestedFlag.current = false;
            selectedNestedContent.current = {
                contentName: null,
                contentTarget: null,
                contentType: null,
                livepageId: livepageId,
            };
        } else {
            peoplePageNestedFlag.current = true;
        }
    }, [modalNestedVisibility]);

    useEffect(() => {
        if (sortDetails?.order && sortDetails?.by && isMounted.current) {
            handleInteractionsData();
        }
        isMounted.current = true;
    }, [sortDetails]);

    useEffect(() => {
        if (sortNestedDetails?.order && sortNestedDetails?.by) {
            handleInteractionsData(null, true);
        }
    }, [sortNestedDetails]);

    useEffect(() => {
        if (
            modalVisibility && interationsUsersSortDetails?.order
            && interationsUsersSortDetails?.by && isMounted.current
        ) {
            getUsersData();
        }
    }, [interationsUsersSortDetails]);

    const expandedRowRender = () => {
        return (
            <>
                <Table
                    columns={interactionNestedColumns}
                    onChange={handleNestedChange}
                    dataSource={nestedInteractions}
                    loading={nestedTableLoading}
                    pagination={{
                        position: ['none', 'bottomCenter'],
                        onChange: (page) => {
                            setCurrentNestedPage(page);
                            //   setExpandedRowKeys([]);
                        },
                        pageSize: PAGE_SIZE,
                        total: paginationNestedInfo?.total,
                        showSizeChanger: false,
                        current: currentNestedPage,
                    }}
                />
                <div className="exportContainer">
                    <Dropdown overlay={exportMenu}>
                        <Button loading={isExporting}>
                            Export <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </>
        );
    };

    const expandedRowChange = async (
        expanded,
        { key, content_name, contentType, contentTarget, livepageId }
    ) => {
        setNoNestedData(true);
        setNestedInteractions([]);
        setNestedPagination({});
        if (expanded) {
            selectedNestedContent.current = {
                contentName: content_name,
                contentTarget: null,
                contentType,
                livepageId,
            };
            activeNestedContentName.current = content_name;
            activeNestedContentType.current = contentType;
            activeNestedLivepageId.current = livepageId;
            setExpandedRowKeys([key]);
            await handleInteractionsData(1, true);
        } else {
            selectedNestedContent.current = {
                contentName: null,
                contentTarget: null,
                contentType: null,
                livepageId: livepageId,
            };
            activeNestedContentName.current = null;
            activeNestedContentType.current = null;
            activeNestedLivepageId.current = null;
            setExpandedRowKeys([]);
        }
    };

    return (
        <div className="interactionsAnalysisContainer">
            {!error && (
                <>
                    <LivePageSelector
                        {...{
                            projectId,
                            setLoading,
                            setError,
                            isLoading,
                        }}
                    />
                </>
            )}
            {error && <div className="errorContainer">{error}</div>}

            {contentLoading && (!interactions || interactions.length === 0) && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            )}

            {!error && interactions.length > 0 && (
                <div className="visitsContainer">
                    <div className="titleContainer">
                        <h2 className="pageHeading">
                            What content did visitors interact with?
                        </h2>
                        <span>
                            {`${
                                (paginationInfo?.currentPage - 1) * PAGE_SIZE +
                                1
                            }-${
                                (paginationInfo?.currentPage - 1) * PAGE_SIZE +
                                interactions.length
                            } of ${paginationInfo.total} records`}
                        </span>
                    </div>
                    <Table
                        onChange={handleChange}
                        style={{ marginTop: '10px' }}
                        dataSource={interactions}
                        columns={interactionColumns}
                        expandedRowRender={expandedRowRender}
                        onExpand={expandedRowChange}
                        expandedRowKeys={expandedRowKeys}
                        loading={contentLoading}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: (page) => {
                                handleInteractionsData(page);
                                setExpandedRowKeys([]);
                            },
                            pageSize: PAGE_SIZE,
                            total: paginationInfo?.total,
                            showSizeChanger: false,
                            current: paginationInfo?.currentPage,
                        }}
                    />
                    <div className="exportContainer">
                        <Dropdown overlay={exportMenu}>
                            <Button loading={isExporting}>
                                Export <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            )}

            {hasNoData && !contentLoading && (
                <div className="visitsContainer">
                    <div className="noUserContainer">
                        No content interactions found
                    </div>
                </div>
            )}

            <style>{`
            ant-select-selection-search
            {
                height: 100%;
                line-height: 46px;

            }
            `}</style>
            <Modal
                width={1200}
                visible={modalVisibility}
                footer={null}
                onCancel={() => setModalVisibility(false)}
            >
                <div className="peopleModal">
                    <Table
                        style={{ width: '1200px' }}
                        dataSource={peopleData}
                        columns={interactionUserColumns}
                        onChange={handleModalChange}
                        loading={isPeopleDataLoading}
                        bordered
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setPeopleCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: peoplePaginationData?.total,
                            showSizeChanger: false,
                            current: currentPeoplePage,
                        }}
                    />
                    {peopleData.length > 0 && !isPeopleDataLoading && (
                        <div className="exportContainer">
                            <div
                                onClick={() => {
                                    if (!isPeopleExporting)
                                        exportUsersData(false);
                                }}
                            >
                                {isPeopleExporting
                                    ? 'Exporting...'
                                    : 'Export'}
                            </div>
                        </div>
                    )}
                    {peopleData.length === 0 && !isPeopleDataLoading && (
                        <div className="noRecords">No records found</div>
                    )}
                </div>
            </Modal>
            <Modal
                width={1200}
                visible={modalNestedVisibility}
                footer={null}
                onCancel={() => setModalNestedVisibility(false)}
            >
                <div className="peopleModal">
                    {isPeopleDataNestedLoading && (
                        <PageSpinner
                            type="Oval"
                            color="#ACBDC9"
                            height={48}
                            width={48}
                        />
                    )}
                    {peopleNestedData.length > 0 && !isPeopleDataNestedLoading && (
                        <>
                            <Table
                                style={{ width: '1200px' }}
                                dataSource={peopleNestedData}
                                columns={interactionUserColumns}
                                bordered
                                pagination={{
                                    position: ['none', 'bottomCenter'],
                                    onChange: setPeopleCurrentNestedPage,
                                    pageSize: PAGE_SIZE,
                                    total: peoplePaginationNestedData?.total,
                                    showSizeChanger: false,
                                    current: currentPeopleNestedPage,
                                }}
                            />
                            <div className="exportContainer">
                                <div
                                    onClick={() => {
                                        if (!isPeopleNestedExporting)
                                            exportUsersData(true);
                                    }}
                                >
                                    {isPeopleNestedExporting
                                        ? 'Exporting...'
                                        : 'Export'}
                                </div>
                            </div>
                        </>
                    )}
                    {peopleNestedData.length === 0 &&
                        !isPeopleDataNestedLoading && (
                            <div className="noRecords">No records found</div>
                        )}
                </div>
            </Modal>
        </div>
    );
};
export default ContentInteractions;
