import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { Checkbox, Select } from 'antd';
import * as ls from 'local-storage';
import TagManager from 'react-gtm-module';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { generateValidation } from 'utils/inputValidation';
import { updateSignupData } from '../Api';
import { getUser } from 'apis/rest/User';

import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import * as actUser from 'redux/actions/common/userActions';
import { debounce, isEmpty } from 'lodash';
import * as act from 'redux/actions/live/uiActionsLive';

import { LoadingOutlined } from '@ant-design/icons';
import countries from '../config/channels/countries.json';
import moment from 'moment';
import { createProject } from 'apis/rest/livestream/CreateProject';
import { chooseExperience } from '../../../Apps/ExperienceSelection/Api';
import { store } from '../../../redux/store';
import { setVenueSettings } from '../../../redux/actions/venues/actionsVenues';

const { Option } = Select;

const SignupSteps = () => {
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        formState: { errors },
        getValues,
        watch,
        reset,
    } = useForm();
    const history = useHistory();
    const id = history?.location?.state?.id;
    const email = history?.location?.state?.email;
    const user = history?.location?.state?.user || {};
    const data = history?.location?.state?.data || {};
    const dispatch = useDispatch();

    const form = useRef();
    const payload = useRef({
        ...user,
        ...data,
        company: data?.name || '',
        newsletterSubscription: true,
    });

    const [currentStep, setCurrentStep] = useState(0);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [signUpCompleted, setSignUpCompleted] = useState(false);

    const [options, setOptions] = useState([]);
    const [optionLoading, setOptionLoading] = useState(false);
    const [searchCompanyNameVal, setSearchCompanyNameVal] = useState({});
    const [project, setProject] = useState(null);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');

    const { preferredExperienceId = '', preferredExperienceName = '' } =
        useSelector((state) => state?.user || {});

    const { executeRecaptcha } = useGoogleReCaptcha();

    const renderLogo = (e, returnLogo = false) => {
        const data = e
            ? options.filter((d) => d.name.toLowerCase() === e.toLowerCase())[0]
            : null;
        if (returnLogo && data) return data?.logo;

        if (data && data?.logo)
            return <img src={data?.logo} alt={data?.name} />;
    };

    const renderBasicDetails = (e) => {
        return currentStep !== 0 ? null : (
            <>
                <ul className={styles.ChannelList}>
                    <li className={styles.ChannelItem} key={'firstname'}>
                        <input
                            className={styles.Input}
                            defaultValue={payload?.current['firstname']}
                            id={'firstname'}
                            autoFocus={true}
                            name={'firstname'}
                            placeholder={'First Name'}
                            ref={register('firstname', {
                                required: {
                                    value: true,
                                    message: 'First Name is required',
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9'-]*$/,
                                    message: "Enter valid first name."
                                }
                            })}
                            onChange={(e) => {
                                setValue('firstname', e.target.value, {
                                    shouldValidate: true,
                                });
                            }}
                        />
                        {errors['firstname'] && (
                            <span className={styles.Error}>
                                {errors['firstname'].message}
                            </span>
                        )}
                    </li>
                    <li className={styles.ChannelItem} key={'lastname'}>
                        <input
                            className={styles.Input}
                            defaultValue={payload?.current['lastname']}
                            id={'lastname'}
                            name={'lastname'}
                            placeholder={'Last Name'}
                            ref={register('lastname', {
                                required: {
                                    value: true,
                                    message: 'Last Name is required',
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9'-]*$/,
                                    message: "Enter valid last name."
                                }
                            })}
                            onChange={(e) => {
                                setValue('lastname', e.target.value, {
                                    shouldValidate: true,
                                });
                            }}
                        />
                        {errors['lastname'] && (
                            <span className={styles.Error}>
                                {errors['lastname'].message}
                            </span>
                        )}
                    </li>
                    <li className={styles.ChannelItem} key={'country'}>
                        <input
                            type="hidden"
                            name="country"
                            id="country"
                            ref={register('country', {
                                required: {
                                    value: true,
                                    message: 'Country is required',
                                },
                            })}
                        />
                        <Select
                            showSearch
                            defaultValue={payload?.current['country']}
                            style={{ width: '100%' }}
                            placeholder="Country"
                            optionFilterProp="children"
                            ref={register('country', {
                                required: {
                                    value: true,
                                    message: 'Country is required',
                                },
                            })}
                            onChange={(e) => {
                                setValue('country', e, {
                                    shouldValidate: true,
                                });
                            }}
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                        optionB.children.toLowerCase()
                                    )
                            }
                        >
                            {countries?.map((c) => (
                                <Option
                                    value={c.value}
                                    label={c.label}
                                    key={c.value}
                                >
                                    {c.label}
                                </Option>
                            ))}
                        </Select>

                        {watch('country') &&
                            renderLogo(getValues('country')) && (
                                <span className={styles.Logo}>
                                    {renderLogo(getValues('country'))}
                                </span>
                            )}

                        {errors['country'] && (
                            <span className={styles.Error}>
                                {errors['country'].message}
                            </span>
                        )}
                    </li>
                    <li className={styles.ChannelItem} key={'company'}>
                        <input
                            type="hidden"
                            name="logo"
                            id="logo"
                            ref={register('logo')}
                        />
                        <input
                            className={styles.Input}
                            defaultValue={
                                payload?.current['company'] ||
                                payload?.current['name'] ||
                                searchCompanyNameVal?.name
                            }
                            style={{ width: '100%' }}
                            placeholder="Organization"
                            optionFilterProp="children"
                            ref={register('company', {
                                required: {
                                    value: true,
                                    message: 'Organization is required',
                                },
                            })}
                            onChange={(e) => {
                                setValue('company', e.target.value, {
                                    shouldValidate: true,
                                });
                                setValue(
                                    'logo',
                                    renderLogo(getValues('company'), true)
                                );
                            }}
                        ></input>

                        {watch('company') &&
                            renderLogo(getValues('company')) && (
                                <span className={styles.Logo}>
                                    {renderLogo(getValues('company'))}
                                </span>
                            )}

                        {errors['company'] && (
                            <span className={styles.Error}>
                                {errors['company'].message}
                            </span>
                        )}
                    </li>
                    <li className={styles.ChannelItem} key={'source'}>
                        <textarea
                            className={styles.Input}
                            defaultValue={payload?.current['source']}
                            id={'source'}
                            key={'source'}
                            name={'source'}
                            placeholder={'How did you hear about us?'}
                            ref={register('source', {
                                required: {
                                    value: true,
                                    message: 'Source is required',
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'Max characters exceeded (255)',
                                },
                            })}
                            onChange={(e) => {
                                setValue('source', e.target.value, {
                                    shouldValidate: true,
                                });
                            }}
                        ></textarea>
                        {errors['source'] && (
                            <span className={styles.Error}>
                                {errors['source'].message}
                            </span>
                        )}
                    </li>
                </ul>

                <Checkbox
                    className={styles.NewsletterSubscription}
                    defaultChecked={payload?.current['newsletterSubscription']}
                    ref={register('newsletterSubscription')}
                    onChange={(e) =>
                        setValue('newsletterSubscription', e.target.checked)
                    }
                >
                    I want to receive product updates via email.
                </Checkbox>
                <i>All fields are compulsory</i>
            </>
        );
    };

    const renderWork = (e) => {
        const works = [
            'Small/Medium Business (1-250 employees)',
            'Large business (251+ employees)',
            'Agency',
            'Education',
            'Government',
            'Association',
        ];
        return (
            <>
                <div className={styles.Step2}>
                    <div className={styles.Experiences}>
                        <ul className={styles.List}>
                            {works.map((w, index) => (
                                <li
                                    key={index}
                                    className={`${styles.ListItem} ${watch('sector') === w
                                        ? styles.ListItemSelected
                                        : ''
                                        }`}
                                    onClick={(e) => {
                                        const input =
                                            document.querySelector(
                                                'input#sector'
                                            );
                                        if (input) input.value = '';
                                        setValue('sector', w);
                                    }}
                                >
                                    {w}
                                </li>
                            ))}
                        </ul>
                        <input
                            key={'sector'}
                            type="text"
                            className={`${styles.Input} ${styles.Others} ${!works.includes(watch('sector')) &&
                                watch('sector')
                                ? styles.Active
                                : ''
                                }`}
                            value={
                                works.includes(watch('sector'))
                                    ? ''
                                    : watch('sector')
                            }
                            name="sector"
                            id="sector"
                            ref={register('sector', {
                                required: {
                                    value: true,
                                    message: 'Sector is required',
                                },
                            })}
                            onChange={(e) => setValue('sector', e.target.value)}
                            placeholder="Others"
                        />

                        {errors['sector'] && (
                            <span className={styles.Error}>
                                {errors['sector'].message}
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const renderRole = (e) => {
        const roles = [
            'Event Professional',
            'Marketer',
            'Designer',
            'Developer',
            'HR Professional',
            'CEO/Founder',
        ];

        return (
            <>
                <div className={styles.Step2}>
                    <div className={styles.Works}>
                        <ul className={styles.List}>
                            {roles.map((w, index) => (
                                <li
                                    key={index}
                                    className={`${styles.ListItem} ${watch('role') === w
                                        ? styles.ListItemSelected
                                        : ''
                                        }`}
                                    onClick={(e) => {
                                        const input =
                                            document.querySelector(
                                                'input#role'
                                            );
                                        if (input) input.value = '';
                                        setValue('role', w);
                                    }}
                                >
                                    {w}
                                </li>
                            ))}
                        </ul>
                        <input
                            type="text"
                            key={'role'}
                            className={`${styles.Input} ${styles.Others} ${!roles.includes(watch('role')) && watch('role')
                                ? styles.Active
                                : ''
                                }`}
                            name="role"
                            value={
                                roles.includes(watch('role'))
                                    ? ''
                                    : watch('role')
                            }
                            id="role"
                            ref={register('role', {
                                required: {
                                    value: true,
                                    message: 'Role is required',
                                },
                            })}
                            onChange={(e) => setValue('role', e.target.value)}
                            placeholder="Others"
                        />

                        {errors['role'] && (
                            <span className={styles.Error}>
                                {errors['role'].message}
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const renderUserGoal = () => {
        const roles = [
            {
                name: 'Setup registration',
                description: 'Create seamless online registration experiences',
                icon: '/assets/signup/signup_create_unique_experience.svg',
            },
            {
                name: 'Build event website',
                description: 'Create beautiful websites to promote your event',
                icon: '/assets/signup/signup_increase_event_engagement.svg',
            },
            {
                name: 'Run virtual event',
                description: 'Built custom virtual experiences in minutes',
                icon: '/assets/signup/signup_enable_attendee_networking.svg',
            },
            {
                name: 'Manage event content',
                description: 'Generate agenda, speakers, videos & more',
                icon: '/assets/signup/signup_manage_event_content.svg',
            },
            {
                name: 'Level up networking & engagement ',
                description: 'Interactive experience for attendees to connect',
                icon: '/assets/signup/signup_level_up_networking_and_engagement.svg',
            },
            {
                name: 'Empower Sponsors',
                description: 'Self-serve virtual booths and lead generation',
                icon: '/assets/signup/signup_empower_sponsors.svg',
            },
        ];

        return (
            <>
                <div className={styles.Step2}>
                    <div className={`${styles.Works} ${styles.UserGoals}`}>
                        <ul className={styles.List}>
                            {roles.map((userGoal, index) => (
                                <li
                                    key={index}
                                    className={`${styles.ListItem} ${watch(
                                        'what_are_you_planning_to_achieve'
                                    )?.includes(userGoal.name)
                                        ? styles.ListItemSelected
                                        : ''
                                        }`}
                                    onClick={(e) => {
                                        if (
                                            watch(
                                                'what_are_you_planning_to_achieve'
                                            )?.includes(userGoal.name)
                                        ) {
                                            const filteredGoals = watch(
                                                'what_are_you_planning_to_achieve'
                                            )?.filter(
                                                (e) => e !== userGoal.name
                                            );
                                            setValue(
                                                `what_are_you_planning_to_achieve`,
                                                filteredGoals
                                            );
                                        } else
                                            setValue(
                                                `what_are_you_planning_to_achieve`,
                                                watch(
                                                    'what_are_you_planning_to_achieve'
                                                )
                                                    ? [
                                                        ...watch(
                                                            'what_are_you_planning_to_achieve'
                                                        ),
                                                        userGoal.name,
                                                    ]
                                                    : [userGoal.name]
                                            );
                                    }}
                                >
                                    <div className={styles.Icon}>
                                        <img
                                            className={styles.GoalIcon}
                                            src={userGoal.icon}
                                            alt={userGoal.name}
                                        />
                                        <span className={styles.IconSelected}>
                                            <img
                                                src="/assets/signup/signup_selected_rounded.svg"
                                                alt={`${userGoal.name} - Checked`}
                                            />
                                        </span>
                                        <span className={styles.IconUnselected}>
                                            <img
                                                src="/assets/signup/signup_unselected_rounded.svg"
                                                alt={`${userGoal.name} - Unchecked`}
                                            />
                                        </span>
                                    </div>
                                    <h3>{userGoal.name}</h3>
                                    <p>{userGoal.description}</p>
                                </li>
                            ))}
                        </ul>

                        <input
                            type="hidden"
                            name="what_are_you_planning_to_achieve"
                            id="what_are_you_planning_to_achieve"
                            ref={register('what_are_you_planning_to_achieve')}
                        />

                        {errors['what_are_you_planning_to_achieve'] && (
                            <span className={styles.Error}>
                                {
                                    errors['what_are_you_planning_to_achieve']
                                        .message
                                }
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const renderWelcome = () => (
        <>
            <div className={styles.Step2}>
                <img
                    class={styles.WelcomeImage}
                    src="/assets/signup/welcome.png"
                    alt="Welcome"
                />
            </div>
        </>
    );

    // Steps
    const steps = [
        {
            title: 'Create your account',
            subtitle:
                'Just some basic details that we need to set up your account.',
            content: renderBasicDetails,
        },
        {
            title: 'Which best describes where you work?',
            subtitle: 'This will help us personalise your experience in Gevme.',
            content: renderWork,
        },
        {
            title: 'Which best describes your role?',
            subtitle: 'This will help us personalise your experience in Gevme.',
            content: renderRole,
        },
        {
            title: 'What are you planning to achieve?',
            subtitle: 'Select as many options as you like below to get started',
            content: renderUserGoal,
        },
        // Commented for now - Please don't remove
        // {
        //     title: 'Welcome onboard!',
        //     h3: 'Let’s craft your first virtual experience',
        //     p: 'We’ll guide you and in two minutes you will learn how to build your own custom virtual experience using our visual editor',
        //     content: renderWelcome,
        // },
    ];

    const handleCreateProject = async (companyId) => {
        try {
            let formData = new FormData();
            const currentTimezone = moment?.tz?.guess();
            const startDate = moment()
                .tz(currentTimezone)
                .add(1, 'days')
                .set({ hour: 9, minute: 0 });
            const endDate = moment()
                .tz(currentTimezone)
                .add(1, 'days')
                .set({ hour: 18, minute: 0 });

            formData.append('environment', process.env.REACT_APP_ENVIRONMENT);
            formData.append('name', 'My first project');
            formData.append('timezone', currentTimezone);
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('companyId', companyId);

            const response = await createProject(formData);
            if (response && response.status) {
                // Dispatch them
                await dispatch(act.updateHeaderTitle(response?.project?.name));
                await dispatch(
                    act.updateProjectId(response?.project?.projectId)
                );

                return response;
            } else throw response;
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    const handleAssociateExperience = async (projectId) => {
        try {
            if (!projectId || !preferredExperienceId) {
                return false;
            }
            const res = await chooseExperience(projectId, {
                venueId: preferredExperienceId,
                experience: preferredExperienceName,
                type: 'website',
            });
            if (res?.status) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'selectedExperienceDetails',
                        projectId,
                        experience: preferredExperienceName,
                        venueId: preferredExperienceId,
                    },
                });
                store.dispatch(
                    setVenueSettings({
                        enableVenue: 1,
                        venueId: preferredExperienceId,
                        venueName: preferredExperienceName,
                    })
                );
                return true;
            } else throw res;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const handleOnSubmit = async (data, nextStep = false, finished = false) => {
        try {
            setError('');
            payload.current = {
                ...payload.current,
                ...data,
                goalsCompleted: false,
                goalsSkipped: false,
            };
            if (nextStep) return setCurrentStep((prev) => prev + 1);

            if (finished) {
                setFormSubmitting(true);
                const reCaptchaAction = 'SIGN_UP_STEPS';
                const res = await updateSignupData(
                    id,
                    payload.current,
                    await executeRecaptcha(reCaptchaAction),
                    reCaptchaAction
                );
                if (res && res.status) {
                    ls('auth', 'authenticated');
                    const { user, status } = await getUser();

                    if (status) {
                        const userSignUp = {
                            isNewSignup: true,
                            isFirstProject: true,
                            isFirstVenue: true,
                            showWelcomeVideo: true,
                            userGoal:
                                data?.what_are_you_planning_to_achieve.join(),
                        };

                        const userDetails = {
                            ...user,
                            ...userSignUp,
                        };

                        ls.set('userSignUp', userSignUp);

                        dispatch(actUser.setUser({ user: userDetails }));

                        setUserData(userDetails);

                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'GoalSelection',
                                email: user?.email,
                                userGoal:
                                    data?.what_are_you_planning_to_achieve.join(),
                            },
                        });

                        const {
                            firstname,
                            lastname,
                            email,
                            company,
                            id,
                            companyId,
                            position,
                            role,
                            isVerified,
                        } = userDetails;

                        ls('userDetails', {
                            email,
                            firstname,
                            lastname,
                            company,
                            id,
                            companyId,
                            position,
                            isVerified,
                            roleName: role?.name,
                            country: data?.country,
                        });

                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'signupCompleted',
                                email: user?.email,
                            },
                        });

                        if (payload?.current?.newsletterSubscription) {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'NewsletterOpted',
                                    user,
                                },
                            });
                        } else {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'NewsletterNotOpted',
                                    user,
                                },
                            });
                        }

                        const createProjectRes = await handleCreateProject(
                            user?.companyId
                        );

                        if (createProjectRes?.status) {
                            setProject(createProjectRes?.project);
                            setCurrentStep((prev) => prev + 1);
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'projectCreated',
                                    projectId:
                                        createProjectRes?.project?.projectId,
                                    companyId: user?.companyId,
                                },
                            });
                            setSignUpCompleted(true);

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'dashboardSelected',
                                    projectId:
                                        createProjectRes?.project?.projectId,
                                    companyId,
                                },
                            });

                            const projectId =
                                createProjectRes?.project?.projectId;
                            const firstProjPath = `/company/${companyId}/project/${createProjectRes?.project?.projectId}`;
                            if (preferredExperienceId) {
                                const result = await handleAssociateExperience(
                                    projectId
                                );
                                if (result) {
                                    history.push(
                                        `/company/${companyId}/project/${projectId}/venue-builder/?firstVenue=1`
                                    );
                                } else {
                                    history.push(firstProjPath);
                                }
                            } else {
                                history.push(firstProjPath);
                            }
                        } else throw createProjectRes;
                    }
                } else throw res;
            }
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong!');
        } finally {
            setFormSubmitting(false);
        }
    };

    const determineStepsToEnable = () => {
        try {
            const validFieldsKey = getValues();
            delete validFieldsKey.logo;
            delete validFieldsKey.newsletterSubscription;

            return Object.values(validFieldsKey).every((value) => value);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!id) history.push('/signup');
    }, [id, email]);

    useEffect(() => {
        if (reset && payload && payload.current && !isEmpty(payload.current)) {
            reset(payload.current);
        }
    }, [payload, reset]);

    const handleStepButtons = ({ next = true, finished = false }) => {
        handleSubmit((e) => {
            handleOnSubmit(e, next, finished);
        })();
    };

    return (
        <div className={styles.SignupSteps}>
            <div className={styles.Top}>
                {steps[currentStep]?.title && (
                    <h2>{steps[currentStep]?.title}</h2>
                )}
                {steps[currentStep]?.h3 && <h3>{steps[currentStep]?.h3}</h3>}
                {steps[currentStep]?.subtitle && (
                    <p className={styles.SubTitle}>
                        {steps[currentStep]?.subtitle}
                    </p>
                )}
                {steps[currentStep]?.p && (
                    <p className={styles.SubTitle}>{steps[currentStep]?.p}</p>
                )}
                <div
                    className={`${styles.CurrentStep} ${currentStep > 0 ? styles.CurrentStepCustom : ''
                        }`}
                >
                    <div className="steps-content">
                        <form
                            ref={form}
                            onSubmit={handleSubmit(handleOnSubmit)}
                        >
                            {steps[currentStep]?.content()}
                        </form>
                    </div>
                    {error && <p className={styles.Error}>{error}</p>}
                </div>
            </div>
            <div className={styles.Bottom}>
                <div className={styles.StatusBar}>
                    <span
                        className={styles.Bar}
                        style={{
                            width: `${(currentStep + 1) * (100 / steps.length)
                                }%`,
                        }}
                    ></span>
                </div>
                <div className={styles.ActionButton}>
                    {!signUpCompleted ? (
                        <>
                            {currentStep > 0 && (
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                            currentStep === 1 &&
                                            !watch('sector')
                                        )
                                            unregister(['sector']);
                                        if (currentStep === 2 && !watch('role'))
                                            unregister(['role']);

                                        if (
                                            currentStep === 3 &&
                                            !watch(
                                                'what_are_you_planning_to_achieve'
                                            )
                                        )
                                            unregister([
                                                'what_are_you_planning_to_achieve',
                                            ]);

                                        setCurrentStep((prev) => prev - 1);
                                    }}
                                >
                                    <span className="text">Back</span>
                                </a>
                            )}

                            {currentStep !== steps.length - 1 && (
                                <>
                                    {!determineStepsToEnable() || Object.keys(errors).length ? (
                                        <>
                                            <button
                                                className={styles.Next}
                                                disabled
                                            >
                                                <span className="text">
                                                    Next{' '}
                                                </span>
                                                <img
                                                    src="/assets/icon_arrow_right.svg"
                                                    alt="Next"
                                                />
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() =>
                                                    handleStepButtons({
                                                        next: true,
                                                    })
                                                }
                                                className={styles.Next}
                                            >
                                                <span className="text">
                                                    Next{' '}
                                                </span>
                                                <img
                                                    src="/assets/icon_arrow_right.svg"
                                                    alt="Next"
                                                />
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                            {currentStep === steps.length - 1 && (
                                <>
                                    {!determineStepsToEnable() ? (
                                        <>
                                            <button
                                                disabled
                                                className={styles.Done}
                                            >
                                                {formSubmitting && (
                                                    <LoadingOutlined />
                                                )}{' '}
                                                <span className="text">
                                                    Finish{' '}
                                                </span>
                                                <img
                                                    src="/assets/icon_arrow_right.svg"
                                                    alt="Finish"
                                                />
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() =>
                                                    handleStepButtons({
                                                        finished: true,
                                                        next: false,
                                                    })
                                                }
                                                className={styles.Done}
                                            >
                                                {formSubmitting && (
                                                    <LoadingOutlined />
                                                )}{' '}
                                                <span className="text">
                                                    Finish{' '}
                                                </span>
                                                <img
                                                    src="/assets/icon_arrow_right.svg"
                                                    alt="Finish"
                                                />
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {/* {project && (
                                <>
                                    <button
                                        onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    event: 'dashboardSelected',
                                                    projectId:
                                                        project?.projectId,
                                                    companyId:
                                                        userData?.companyId,
                                                },
                                            });

                                            window.location.href = `/v2/company/${userData?.companyId}/project/${project?.projectId}/home/overview?firstProject=1`
                                        }}
                                        type="button"
                                        className={styles.Done}
                                    >
                                        {formSubmitting && <LoadingOutlined />}{' '}
                                        <span className="text">Or go to dashboard</span>
                                        <img
                                            src="/assets/icon_arrow_right.svg"
                                            alt="Finish"
                                        />
                                    </button>
                                </>
                            )} */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SignupSteps;
