import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Tag, Button, Modal, Card, Pagination } from 'antd';
import 'antd/dist/antd.css';
import * as ls from 'local-storage';

import Venuedetails from '../venuedetails';
import Uploadvenue from '../uploadvenue';
import Search from '../search';
// import FilterAndLabels from '../filterAndLabels'
import CloseIcon from '../Icons/CloseIcon';

import { debounce, isEmpty } from 'lodash';

// API
import {
    getVenueStore,
    getCompanyVenues,
    setChooseVenue,
} from 'apis/rest/venue/Venues';

import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

// redux
import * as venuesActions from 'redux/actions/venues/actionsVenues';
import * as projectActions from 'redux/actions/projects/projectSettings';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import Loader from '../Loader';

import VenueCollections from 'ComponentsV2/ProjectDashboard/Components/Website/venuecollections/VenueCollections';
import './store.scss';

const Store = ({
    isVisible,
    setModal,
    projectId,
    activeTab,
    loadCollection,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { companyId } = useParams();
    // state
    const venueData = useSelector((state) => state?.venues?.venuestore);
    const venueCollectionsData = useSelector(
        (state) => state?.venues?.venuecollections
    );
    const [venuePagination, setVenuePagination] = useState(
        venueData?.pagination || {}
    );
    const user = useSelector((state) => state.user);

    const [activeMenu, setActiveMenu] = useState('store');
    const [loading, setLoading] = useState(false);

    const [configureThis, setConfigureThis] = useState(null);
    const [addVenueModal, setAddVenueModal] = useState(false);
    const [venueDetailsModalVisible, setVenueDetailsModalVisible] =
        useState(false);
    const venueId = useSelector((state) => state?.venuesettings?.venueId);
    const [selectedStoreData, setSelectedStoreData] = useState({});

    const [submitted, setSubmitted] = useState(false);

    // search
    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState('');
    const [choosedVenue, setChoosedVenue] = useState(false);

    const handleSearch = (value) => {
        setSearchValue(value);
        searchDebounce(value);
    };
    const searchDebounce = useCallback(
        debounce((text) => setSearchDebounceText(text), 500),
        []
    );

    useEffect(() => {
        if (!venueDetailsModalVisible) setSelectedStoreData({});
    }, [venueDetailsModalVisible]);

    const getVenueStoreData = async (page = 1) => {
        const getVenuesData = await getVenueStore(projectId, page);
        if (getVenuesData.status) {
            dispatch(venuesActions.setVenueStore(getVenuesData));
            setVenuePagination(getVenuesData?.pagination);
            setLoading(false);
        }
    };

    const getVenueCollections = async (search = null) => {
        const getVenueCollectionsData = await getCompanyVenues(
            projectId,
            search
        );
        if (getVenueCollectionsData.status) {
            dispatch(venuesActions.setVenueCollection(getVenueCollectionsData));
            setLoading(false);
        }
    };

    const chooseVenue = async (id) => {
        const setvenue = await setChooseVenue(projectId, { venueId: id });
        setSubmitted(true);
        if (setvenue.status) {
            dispatch(venuesActions.setVenueId(id));
            dispatch(
                projectActions.updateProject({ id: projectId, venueId: id })
            );
            setTimeout(() => {
                openNotification('success', {
                    message: `Venue successfully added.`,
                });
                setModal(false);
                setSubmitted(false);
                history.push(
                    `v2/company/${companyId}/project/${projectId}/venue-builder/${
                        user?.isFirstVenue ? '?firstVenue=1' : ''
                    }`
                );
            }, 1000);
        } else {
            setSubmitted(false);
            openNotification('error', { message: `${setvenue.message}` });
        }
    };

    const handleConfigureThis = (data) => {
        if (!isEmpty(data)) {
            setConfigureThis(data);
            setAddVenueModal(true);
        }
    };

    useEffect(() => {
        if (activeMenu === 'collections') {
            setLoading(true);
            getVenueCollections();
        } else {
            setLoading(true);
            getVenueStoreData();
        }
    }, [activeMenu]);

    useEffect(() => {
        setActiveMenu(activeTab);
    }, [activeTab]);

    useEffect(() => {
        setLoading(true);
        getVenueCollections(searchDebounceText);
    }, [searchDebounceText]);

    useEffect(() => {
        if (loadCollection) {
            getVenueCollections();
        }
    }, [loadCollection]);

    useEffect(() => {
        if (typeof addVenueModal === 'boolean' && addVenueModal === false) {
            setConfigureThis(null);
        }
    }, [addVenueModal]);

    useEffect(() => {
        if (venueId && choosedVenue) {
            setModal(false);
        }
    }, [venueId, choosedVenue]);

    return (
        <>
            <Modal
                centered
                visible={isVisible}
                onCancel={() => setModal(false)}
                footer={null}
                destroyOnClose={true}
                width={1072}
                maskClosable={false}
                closeIcon={<CloseIcon loading={submitted} />}
                className="venue-store-modal"
            >
                <h2>Choose a theme</h2>
                <div
                    className={`venue-modal-content ${
                        submitted ? 'onsubmit-loading' : ''
                    }`}
                >
                    {submitted && (
                        <GlobalLoading loadingText="Please wait..." />
                    )}

                    <div className="venue-selections">
                        <h3
                            className={activeMenu === 'store' ? 'active' : ''}
                            onClick={() => setActiveMenu('store')}
                        >
                            Experiences Marketplace
                        </h3>
                        <h3
                            className={
                                activeMenu === 'collections' ? 'active' : ''
                            }
                            onClick={() => setActiveMenu('collections')}
                        >
                            My theme collection
                        </h3>
                    </div>
                    {activeMenu === 'store' ? (
                        <>
                            <div
                                className={`store-items-venue ${
                                    loading ? 'loading' : ''
                                }`}
                            >
                                {loading ? (
                                    <Loader text="Loading experiences marketplace..." />
                                ) : (
                                    venueData?.venues?.map((item, index) => {
                                        return (
                                            <Card
                                                key={index}
                                                style={{
                                                    width: 270,
                                                }}
                                                cover={
                                                    item.thumbnail ? (
                                                        <img
                                                            alt={item?.name}
                                                            src={
                                                                item?.thumbnail
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="card-image-placeholder" />
                                                    )
                                                }
                                                onClick={() => {
                                                    setSelectedStoreData(item);
                                                    setVenueDetailsModalVisible(
                                                        true
                                                    );
                                                }}
                                            >
                                                <h3>{item.name}</h3>
                                                <div className="venue-store-tags">
                                                    {item?.goodFor
                                                        ?.split(', ')
                                                        .map((tag, idx) => {
                                                            return (
                                                                <Tag key={idx}>
                                                                    {tag}
                                                                </Tag>
                                                            );
                                                        })}
                                                </div>
                                            </Card>
                                        );
                                    })
                                )}
                            </div>
                            {!loading && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '10px 0',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Pagination
                                        current={Number(
                                            venuePagination?.currentPage
                                        )}
                                        pageSize={venuePagination?.limit}
                                        total={venuePagination?.total}
                                        onChange={(page, pageSize) =>
                                            getVenueStoreData(page)
                                        }
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <VenueCollections
                            loading={loading}
                            venues={venueCollectionsData?.venues}
                            onSearch={handleSearch}
                            onAddVenue={() => {
                                setAddVenueModal(true);
                                if (loadCollection) {
                                    history.replace(
                                        `v2/company/${companyId}/project/${projectId}/virtual`
                                    );
                                }}}
                            handleChooseVenue={chooseVenue}
                            venueId={venueId}
                            handleConfigureThis={handleConfigureThis}
                        />
                    )}
                </div>
            </Modal>

            {/* Collections */}
            {venueDetailsModalVisible && (
                <Venuedetails
                    isCollectionModalVisible={venueDetailsModalVisible}
                    setVenueDetailsModalVisible={setVenueDetailsModalVisible}
                    data={selectedStoreData}
                    companyId={companyId}
                    projectId={projectId}
                    setChoosedVenue={setChoosedVenue}
                    setModal={setModal}
                />
            )}
            {/* add venue */}
            {addVenueModal && (
                <Uploadvenue
                    isVisible={addVenueModal}
                    setUploadModal={setAddVenueModal}
                    configureThis={configureThis}
                    setModal={setModal}
                />
            )}
        </>
    );
};

export default Store;
