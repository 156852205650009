import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import { Table } from 'antd';
import Layout from 'components/app/common/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ls from 'local-storage';

import "./projectsDashboard.scss";
function SuperProjectDashboard() {
    const history = useHistory();
    const companyData = history?.location?.state?.company || null

    const [company, setCompany] = useState(null)

    useEffect(() => {
        if (companyData) {
            const jsonCompany = JSON.stringify(companyData)
            ls('superAdminCompanies', jsonCompany);
            setCompany(companyData)
        } else {
            const jsonCompany = ls.get('superAdminCompanies');
            if (jsonCompany) {
                const parseCompany = JSON.parse(jsonCompany)
                setCompany(parseCompany)
            }
        }

    }, [companyData])

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];

    return (
        <Layout disableMenuWidth={true} >
            <div className="container" style={{ width: "950px" }}>
                <div className="dashboard dashboard__xpmgr project-list">
                    <div className="dashboard__left">

                        <button className="btn btn-secondary mr-2" onClick={() => history.push(`/admin/dashboard/`)}>
                            <FontAwesomeIcon
                                icon="chevron-left"
                            />
                        </button>
                        <h1 className="mb-0">Projects of {company?.name}</h1>
                    </div>
                </div>
                <div className="mt-5">
                    <Table
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    history.push({ pathname: `/admin/dashboard/${company?.id}/${record.projectId}`, state: { company } })
                                    ls('superAdminProjectId', record.projectId);
                                },
                            };
                        }}
                        rowClassName="cursor-pointer"
                        rowKey="id"
                        columns={columns}
                        dataSource={company?.projects || []}
                    />
                </div>
            </div>
        </Layout>
    )
}

SuperProjectDashboard.propTypes = {

}

export default SuperProjectDashboard

