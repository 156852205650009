import React from 'react';

export default function SortIcon({ stroke = '#BCBFCA' }) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.86053 5.71636L2.86678 10.4664L2.76178 10.5792C2.57538 10.8078 2.48319 11.0932 2.50252 11.3818C2.52186 11.6704 2.65138 11.9423 2.86678 12.1467L2.98553 12.2452C3.22618 12.4223 3.52658 12.5099 3.83037 12.4915C4.13416 12.4732 4.42045 12.3501 4.63553 12.1455L7.50053 9.4178V23.1809L7.50928 23.3199C7.54482 23.6089 7.69086 23.8756 7.91969 24.0691C8.14851 24.2626 8.44417 24.3695 8.75053 24.3696L8.89678 24.3613C9.20106 24.3272 9.48157 24.1882 9.68505 23.9706C9.88853 23.753 10.0008 23.472 10.0005 23.1809V9.42611L12.8668 12.1455L12.9855 12.2452C13.2368 12.4321 13.5538 12.5205 13.8715 12.4925C14.1892 12.4645 14.4836 12.3221 14.6943 12.0945C14.9051 11.867 15.0163 11.5714 15.0052 11.2685C14.994 10.9656 14.8613 10.6782 14.6343 10.4652L9.62678 5.71517L9.50928 5.61661C9.26863 5.43953 8.96823 5.35195 8.66444 5.37032C8.36066 5.38868 8.07436 5.51173 7.85928 5.71636H7.86053ZM21.2505 5.37199L21.1043 5.3803C20.8002 5.41433 20.5199 5.55319 20.3164 5.77054C20.113 5.9879 20.0006 6.26863 20.0005 6.55949V20.3107L17.1343 17.5914L17.0155 17.4916C16.7642 17.307 16.4482 17.2202 16.1319 17.2489C15.8156 17.2777 15.5227 17.4198 15.3128 17.6463C15.1028 17.8728 14.9915 18.1668 15.0015 18.4685C15.0116 18.7702 15.1422 19.057 15.3668 19.2705L20.3718 24.0205L20.4893 24.1202C20.7299 24.2973 21.0303 24.3849 21.3341 24.3665C21.6379 24.3482 21.9242 24.2251 22.1393 24.0205L27.1343 19.2705L27.2393 19.1577C27.4257 18.9291 27.5179 18.6437 27.4985 18.3551C27.4792 18.0665 27.3497 17.7945 27.1343 17.5902L27.0155 17.4916C26.7749 17.3145 26.4745 17.227 26.1707 17.2453C25.8669 17.2637 25.5806 17.3867 25.3655 17.5914L22.5005 20.3155V6.55949L22.4918 6.42174C22.4562 6.13266 22.3102 5.86605 22.0814 5.67253C21.8526 5.47901 21.5569 5.37206 21.2505 5.37199Z"
                fill={stroke || '#BCBFCA'}
            />
        </svg>
    );
}
