import { gql } from "apollo-boost";

const DELETE_SHOWCASEBLOCK = gql`
  mutation DeleteShowcaseblock($input: deleteShowcaseblockInput) {
    deleteShowcaseblock(input: $input) {
      showcaseblock {
        id
      }
    }
  }
`;

export default DELETE_SHOWCASEBLOCK;