import * as React from 'react';

export default function LineBreakIcon(props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_20435_4739)">
                <path
                    d="M12 7.50004L12.2675 7.26754C12.7229 6.79604 12.9749 6.16453 12.9692 5.50904C12.9635 4.85355 12.7006 4.22652 12.237 3.76299C11.7735 3.29947 11.1465 3.03655 10.491 3.03086C9.83551 3.02516 9.20401 3.27715 8.7325 3.73254L8.5 4.00004"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.00053 8.5L3.73303 8.7325C3.27763 9.20401 3.02565 9.83551 3.03134 10.491C3.03704 11.1465 3.29996 11.7735 3.76348 12.237C4.227 12.7006 4.85403 12.9635 5.50953 12.9692C6.16502 12.9749 6.79652 12.7229 7.26803 12.2675L7.50053 12"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.75 10H13"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 6H4.25"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 11.75V13"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 3V4.25"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_20435_4739">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
