import React, { useEffect, useState } from 'react';
import * as ls from 'local-storage';
import { useParams } from 'react-router-dom';
// import RichTextEditor from 'react-rte';

import ImageUpload from 'components/app/common/imageUpload/imageUpload';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/code_view.min.css';

// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import 'froala-editor/js/third_party/font_awesome.min.js';
import './post.scss';

import FroalaEditor from 'react-froala-wysiwyg';
import Tooltip from 'rc-tooltip';

function Post(props) {
    const {
        handleInputChange,
        input,
        handleFileDelete,
        prepareFileUpload,
        renderFieldError,
        getRichTextEditorValue,
        getContentPaddingValue,
        blockContentData = null,
        permission = null,
        preventEditName = false,
        preventEditSize = false,
        preventEditTitle = false,
    } = props;
    let auth = ls.get('auth');
    let authBearer = `Bearer ${auth?.access_token}`;
    const { projectId } = useParams();

    const showcaseblock = blockContentData?.showcaseblock;
    const [permissionSize, setPermissionSize] = useState(null);
    const [permissionDimension, setPermissionDimension] = useState(null);

    useEffect(() => {
        if (permission !== permissionSize) {
            const currentPermissonSize = permission[0].dimension;
            setPermissionSize(permission);
            if (currentPermissonSize) {
                Object.keys(currentPermissonSize).map((k) => {
                    if (showcaseblock?.name === k) {
                        setPermissionDimension(currentPermissonSize[k]);
                    }
                });
            }
        }
    }, [permission]);

    const [padding, setPadding] = useState(`20`);
    const [froalaContent, setFroalaContent] = useState('');

    const handleModelChange = (model) => {
        setFroalaContent(model);
        getRichTextEditorValue(model);
    };

    const handleContentPaddingChange = (e) => {
        const { value } = e.target;
        setPadding(value);
        getContentPaddingValue(value);
    };

    const config = {
        key: process.env.REACT_APP_FROALA_LICENSE_KEY,
        attribution: false,
        placeholderText: 'Edit your content here!',
        iframe: true,
        htmlRemoveTags: ['script', 'base'],
        heightMin: 200,
        zIndex: 9990,
        pastePlain: true,

        imageUploadMethod: 'POST',
        imageUploadParams: {
            path: `showcase/${projectId}`,
        },
        imageUploadParam: 'files',
        imageUploadURL: `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/images`,
        imageInsertButtons: ['imageUpload', 'imageByURL'],
        requestHeaders: {
            authorization: authBearer,
        },

        fileUploadMethod: 'POST',
        fileUploadParam: 'files',
        fileUploadURL: `${process.env.REACT_APP_API_URL}/upload`,
        fileMaxSize: 1024 * 1024 * 3, // Max size is 3MB
        fileAllowedTypes: [
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'image/gif',
            'image/jpeg',
            'image/png',
        ],
        events: {
            'image.uploaded'(response) {
                const data = JSON.parse(response);

                this.image.insert(
                    data[0].url,
                    true,
                    null,
                    this.image.get(),
                    null
                );
                return false;
            },
            'file.uploaded'(response) {
                const data = JSON.parse(response);

                this.file.insert(data[0].url, data[0].name);
                return false;
            },
            'file.error'(error) {
                const popup = this.popups.get('file.insert');
                const layer = popup.find('.fr-file-progress-bar-layer');

                // Bad link.
                if (error.code == 1) {
                }

                // No link in upload response.
                else if (error.code == 2) {
                }

                // Error during file upload.
                else if (error.code == 3) {
                    layer
                        .find('h3')
                        .text(
                            'An error occured during file upload.<br />' +
                                error.message
                        );
                }

                // Parsing response failed.
                else if (error.code == 4) {
                    layer
                        .find('h3')
                        .text('There is a problem parsing the api response.');
                }

                // File too text-large.
                else if (error.code == 5) {
                    layer
                        .find('h3')
                        .text(
                            'The file is too large. File size should be less than or equal to 3MB'
                        );
                }

                // Invalid file type.
                else if (error.code == 6) {
                    layer
                        .find('h3')
                        .text('File type is invalid and cannot be uploaded');
                }

                // File can be uploaded only to same domain in IE 8 and IE 9.
                else if (error.code == 7) {
                }
            },
        },
        imageEditButtons: [
            'imageReplace',
            'imageAlign',
            'imageCaption',
            'imageRemove',
            '|',
            'imageLink',
            'linkOpen',
            'linkEdit',
            'linkRemove',
            '-',
            'imageDisplay',
            'imageStyle',
            'imageAlt',
            'imageSize',
            'imageTUI',
        ],
        videoInsertButtons: ['videoByURL'],
        toolbarButtons: {
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                ],
                buttonsVisible: 0,
            },
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'formatOLSimple',
                    'alignJustify',
                    'formatOL',
                    'formatUL',
                    'paragraphFormat',
                    'paragraphStyle',
                    'lineHeight',
                    'outdent',
                    'indent',
                    'quote',
                ],
                buttonsVisible: 0,
            },
            moreRich: {
                buttons: [
                    'insertLink',
                    'insertImage',
                    'insertTable',
                    'insertVideo',
                    'specialCharacters',
                    'insertHR',
                ],
                buttonsVisible: 0,
            },
            moreMisc: {
                buttons: [
                    'undo',
                    'redo',
                    'fullscreen',
                    'spellChecker',
                    'selectAll',
                    'html',
                    'help',
                ],
                align: 'right',
                buttonsVisible: 2,
            },
        },
        imageTUIOptions: {
            includeUI: {
                initMenu: 'filter',
                menuBarPosition: 'left',
                theme: {
                    'menu.activeIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-b.svg',
                    'menu.disabledIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-a.svg',
                    'menu.hoverIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-c.svg',
                    'menu.normalIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-d.svg',
                    'submenu.activeIcon.name': 'icon-c',
                    'submenu.activeIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-c.svg',
                    'submenu.normalIcon.name': 'icon-d',
                    'submenu.normalIcon.path':
                        'https://cdn.jsdelivr.net/npm/tui-image-editor@3.2.2/dist/svg/icon-d.svg',
                },
            },
        },
    };

    const renderTooltipContent = () => {
        return (
            <div className="project-tooltip">
                Padding values can also be in the shorthand form, e.g.
                20;20;20;20
            </div>
        );
    };

    return (
        <div>
            <div className="row mb-3 justify-content-between">
                <div className="col-6">
                    <div className="input-set">
                        <label>Block title</label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            onChange={handleInputChange}
                            autoFocus
                            value={showcaseblock?.title || input.title}
                            disabled={preventEditTitle}
                            maxLength={70}
                        />
                        {renderFieldError('title')}
                    </div>
                </div>
                <div className="col-4">
                    {!preventEditSize && (
                        <>
                            <label>Block size</label>
                            <div className="radio-set">
                                <span>
                                    <input
                                        type="radio"
                                        value={false}
                                        name="largeTile"
                                        defaultChecked
                                        onChange={handleInputChange}
                                        id="normalBlockSize"
                                        disabled={preventEditSize}
                                    />{' '}
                                    <label htmlFor="normalBlockSize">
                                        Normal
                                    </label>
                                </span>
                                <span>
                                    <input
                                        type="radio"
                                        value={true}
                                        name="largeTile"
                                        onChange={handleInputChange}
                                        disabled={preventEditSize}
                                        id="bigBlockSize"
                                    />{' '}
                                    <label htmlFor="bigBlockSize">Big</label>
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <label>Content</label>
            <p>
                This is the content which will appear when the user taps on the
                block.
            </p>

            <div className="mb-4">
                <div className="text-rich__wrap">
                    <FroalaEditor
                        tag="textarea"
                        config={config}
                        model={froalaContent}
                        onModelChange={handleModelChange}
                        className="rich-text-editor"
                    />
                </div>
                {renderFieldError('textContent')}
            </div>

            <div className="row mb-4 mt-4">
                <div className="col-2 col-md-3 content-padding">
                    <label>Content Padding:</label>
                </div>
                <div className="col-2 col-md-2 pr-1">
                    <input
                        type="contentPadding"
                        className="form-control nm fz-08"
                        name="contentPadding"
                        onChange={handleContentPaddingChange}
                        value={padding}
                    />
                </div>
                <div className="col-1 col-md-1 np fz-08 flex-mid">
                    px
                    <Tooltip
                        placement="right"
                        trigger={['hover']}
                        overlay={renderTooltipContent()}
                    >
                        <i className="fa fa-question-circle ml-1 pointer" />
                    </Tooltip>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-check">
                        <input
                            name="showContentMobile"
                            className="form-check-input"
                            defaultChecked={true}
                            onChange={handleInputChange}
                            type="checkbox"
                            id="showContentMobile"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="showContentMobile"
                        >
                            Display content as a thumbnail on mobile
                        </label>
                        <small className="form-text text-muted help-block mb-2">
                            If this option is selected, the user will have to
                            tap on the thumbnail on mobile in order to see the
                            full content.
                        </small>
                        <label>
                            Thumbnail{' '}
                            <span className="small">
                                (.gif, .jpg/jpeg, .png, .svg)
                            </span>
                        </label>
                        <ImageUpload
                            prepareFilesUpload={(files) => {
                                prepareFileUpload(files, 'image');
                            }}
                            handleFileDelete={(file) => {
                                handleFileDelete(file, 'image');
                            }}
                            imageCropRecommendedSize={{
                                width:
                                    (permissionDimension &&
                                        permissionDimension[0]) ||
                                    null,
                                height:
                                    (permissionDimension &&
                                        permissionDimension[1]) ||
                                    null,
                            }}
                        />
                        {permissionDimension && (
                            <p>
                                Recommended size: within{' '}
                                {permissionDimension[0]} x{' '}
                                {permissionDimension[1]} px
                            </p>
                        )}
                        {renderFieldError('image')}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;
