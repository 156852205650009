import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';

import { updateView } from '../../Layout/AppHeader/actions';
import DesktopIcon from '../../../Icons/DesktopIcon';
import TabletIcon from '../../../Icons/TabletIcon';
import MobileIcon from '../../../Icons/PhoneIcon';
import styles from './index.module.css';

function SwitchDisplayModeView() {
    const dispatch = useDispatch();
    const displayModeRedux = useSelector((state) => state?.header.display);

    const modes = [
        {
            key: 'desktop',
            icon: <DesktopIcon height={14} width={14} />,
        },
        {
            key: 'tablet',
            icon: <TabletIcon height={14} width={14} />,
        },
        {
            key: 'mobile',
            icon: <MobileIcon height={14} width={14} />,
        },
    ];

    return (
        <Dropdown
            trigger={['click']}
            className={styles.PixelDropdown}
            overlay={
                <Menu>
                    {modes.map((mode) => (
                        <Menu.Item
                            key={mode.key}
                            onClick={() => dispatch(updateView(mode.key))}
                        >
                            {mode.icon}
                        </Menu.Item>
                    ))}
                </Menu>
            }
            placement="bottomRight"
        >
            <Button
                style={{
                    width: 'auto',
                    height: 'auto',
                    border: 'none',
                    padding: 0,
                }}
            >
                {displayModeRedux.desktop && modes[0].icon}
                {displayModeRedux.tablet && modes[1].icon}
                {displayModeRedux.mobile && modes[2].icon}
            </Button>
        </Dropdown>
    );
}

export default SwitchDisplayModeView;
