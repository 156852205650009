import * as React from "react"

const CloseIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.585 17.633a1.374 1.374 0 010 1.967c-.276.277-.614.4-.982.4-.369 0-.707-.123-.983-.4L12 13.975 6.38 19.6c-.276.277-.614.4-.983.4-.368 0-.706-.123-.982-.4a1.374 1.374 0 010-1.967l5.62-5.625-5.62-5.626a1.374 1.374 0 010-1.967 1.371 1.371 0 011.965 0L12 10.04l5.62-5.625a1.371 1.371 0 011.965 0 1.374 1.374 0 010 1.967l-5.62 5.626 5.62 5.625z"
        fill={props?.loading ? "#ddd" : "#000"}
      />
    </svg>
  )
}

export default CloseIcon
