import React from 'react';

const QnAIcon = (props) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.5079 5.00001C14.3552 4.99677 12.2537 5.65625 10.4883 6.88899C8.72286 8.12173 7.37908 9.86807 6.6392 11.8911C5.89932 13.9142 5.79915 16.116 6.35228 18.198C6.90541 20.2799 8.08506 22.1412 9.73122 23.5294L7.26249 26H16.5079C19.2906 26 21.9593 24.8938 23.9269 22.9246C25.8946 20.9555 27 18.2848 27 15.5C27 12.7152 25.8946 10.0445 23.9269 8.07539C21.9593 6.10626 19.2906 5.00001 16.5079 5.00001Z"
                stroke="#7A829D"
                stroke-width="2.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13 12.3756C12.9998 11.7645 13.1717 11.1649 13.4974 10.6407C13.823 10.1165 14.2901 9.68734 14.8489 9.39908C15.4077 9.11083 16.0372 8.97428 16.6702 9.00399C17.3032 9.03371 17.916 9.22858 18.4431 9.56781C18.9702 9.90704 19.3919 10.3779 19.6631 10.9301C19.9344 11.4824 20.0451 12.0953 19.9833 12.7034C19.9216 13.3116 19.6898 13.8921 19.3126 14.3832C18.9354 14.8742 18.427 15.2573 17.8417 15.4915C17.4352 15.6661 17.0912 15.9524 16.8525 16.3145C16.6139 16.6767 16.4913 17.0986 16.5 17.5275V18"
                stroke="#7A829D"
                stroke-width="2.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17 22V21"
                stroke="#7A829D"
                stroke-width="2.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default QnAIcon;
