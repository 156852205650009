import React from "react";

import "./selector.scss";

function Selector(props) {
  const { handleTypeChange } = props;

  return (
    <div className="selector">
      <div className="selector__grid-wrap">
        <div className="selector__grid">
          <div
            onClick={() => handleTypeChange("Post")}
            className="selector__grid-item selector__grid-item--post"
          >
            <div className="selector__content-wrap">Rich Content</div>
          </div>
          <div
            onClick={() => handleTypeChange("Pdf")}
            className="selector__grid-item selector__grid-item--pdf"
          >
            <div className="selector__content-wrap">PDF</div>
          </div>
          <div
            onClick={() => handleTypeChange("Image")}
            className="selector__grid-item selector__grid-item--image"
          >
            <div className="selector__content-wrap">Image Gallery</div>
          </div>
          <div
            onClick={() => handleTypeChange("Video")}
            className="selector__grid-item selector__grid-item--video"
          >
            <div className="selector__content-wrap">Video</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selector;
