import React from "react";

import PropTypes from "prop-types";

import "./radioBox.scss";

function RadioBox(props) {
  const {
    value,
    name,
    onChange,
    label,
    description,
    checked,
    doesValueNeedParsing,
    children,
    disabled = false
  } = props;

  return (
    <div className={description ? 'radio-box' : 'radio-box radio-box-no-desc'}>
      <input
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        data-doesvalueneedparsing={doesValueNeedParsing}
      />
      <label htmlFor="">
        {label}
        <br />
        <span>{description}</span>

        {checked && children && (
          <>
            <div className="radio-box__divider"></div>
            <div>{children}</div>
          </>
        )}
      </label>
    </div>
  );
}

RadioBox.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  description: PropTypes.string,
  doesValueNeedParsing: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default RadioBox;
