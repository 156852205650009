import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import PageSpinner from 'components/app/common/pageSpinner';
import {
    getVisitors,
    exportVisitors,
    getVisitorUsers,
    exportVisitorUsers,
} from '../../api';
import FileSaver from 'file-saver';

import moment from 'moment';
import './index.scss';
import { Table, Modal, Menu, Dropdown, Button } from 'antd';
import { LivePageSelector, handleAnalyticsToken } from '../../helpers';
import { DownOutlined } from '@ant-design/icons';

const PAGE_SIZE = 10;
const livepageId = 'all';

const UserVisits = ({ isAnalyticsAuthSet }) => {
    const projectId = useSelector((state) => state?.header?.projectId);
    const [visitors, setVisitors] = useState([]);
    const [isVisitorsDataLoading, setIsVisitorsDataLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationInfo, setPagination] = useState({});
    const [isExporting, setExporting] = useState(false);
    const [hasNoData, setNoData] = useState(false);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [peopleData, setPeopleData] = useState([]);
    const [peoplePaginationData, setPeoplePaginationData] = useState([]);
    const peoplePageFlag = useRef(false);
    const isMounted = useRef(false);

    const selectedUser = useRef(null);
    const [currentPeoplePage, setPeopleCurrentPage] = useState(1);
    const [isPeopleDataLoading, setPeopleLoading] = useState(true);
    const [isPeopleExporting, setPeopleExporting] = useState(false);
    const [sortDetails, setSortDetails] = useState({
        order: 'DESC',
        by: 'visits',
    });
    const [visitsUsersSortDetails, setVisitsUsersSortDetails] = useState({
        order: 'DESC',
        by: 'visit_last_action_time',
    });

    const { startDate, endDate } = useSelector(
        (state) => state?.venueAnalytics
    );

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [isLoading, setLoading] = useState(true);

    const [error, setError] = useState('');

    const pageFlag = useRef(false);
    const columns = [
        {
            title: <span className="columnHeading">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (val, rowData) => {
                return rowData.firstName || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (val, rowData) => {
                return rowData.lastName || '-';
            },
        },
        {
            title: <span className="columnHeading">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (val, rowData) => {
                return rowData.email || '-';
            },
        },
        {
            title: <span className="columnHeading">Company</span>,
            dataIndex: 'company',
            key: 'company',
            render: (val, rowData) => {
                return rowData.company || '-';
            },
        },
        {
            title: <span className="columnHeading">Job Title</span>,
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (val, rowData) => {
                return rowData.jobTitle || '-';
            },
        },
        {
            title: (
                <span className="columnHeading">Average Visit Duration</span>
            ),
            dataIndex: 'avgVisitDuration',
            key: 'avgVisitDuration',
            sorter: true,
            render: (val, rowData) => {
                return rowData.avgVisitDuration || '-';
            },
        },
        {
            title: <span className="columnHeading">Visits</span>,
            dataIndex: 'visits',
            key: 'visits',
            sorter: true,
            render: (val, rowData) => {
                return (
                    <a
                        className="totalVisits"
                        onClick={() => handleUserModal({ ...rowData })}
                    >
                        {val || '-'}
                    </a>
                );
            },
        },
    ];
    const visitUsersColumns = [
        {
            title: <span className="columnHeading">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (val, rowData) => {
                return rowData.firstName || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (val, rowData) => {
                return rowData.lastName || '-';
            },
        },
        {
            title: <span className="columnHeading">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (val, rowData) => {
                return rowData.email || '-';
            },
        },
        {
            title: <span className="columnHeading">Company</span>,
            dataIndex: 'company',
            key: 'company',
            render: (val, rowData) => {
                return rowData.company || '-';
            },
        },
        {
            title: <span className="columnHeading">Job Title</span>,
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            render: (val, rowData) => {
                return rowData.jobTitle || '-';
            },
        },
        {
            title: <span className="columnHeading">Entry Page</span>,
            dataIndex: 'entry_page',
            key: 'entry_page',
            render: (val, rowData) => {
                return rowData.entry_page || '-';
            },
        },
        {
            title: <span className="columnHeading">Exit Page</span>,
            dataIndex: 'exit_page',
            key: 'exit_page',
            render: (val, rowData) => {
                return rowData.exit_page || '-';
            },
        },
        {
            title: (
                <span className="columnHeading">First Action Timestamp</span>
            ),
            dataIndex: 'visit_first_action_time',
            key: 'visit_first_action_time',
            render: (val, rowData) => {
                return rowData.visit_first_action_time || '-';
            },
        },
        {
            title: <span className="columnHeading">Last Action Timestamp</span>,
            dataIndex: 'visit_last_action_time',
            key: 'visit_last_action_time',
            render: (val, rowData) => {
                return rowData.visit_last_action_time || '-';
            },
        },
        {
            title: <span className="columnHeading">Visit Duration</span>,
            dataIndex: 'visitDuration',
            key: 'visitDuration',
            sorter: true,
            render: (val, rowData) => {
                return rowData.visitDuration || '-';
            },
        },
    ];

    const exportMenu = (
        <Menu
            onClick={({ key }) => {
                if (key === '1') exportVisitiorsData('all');
                else exportVisitiorsData();
            }}
        >
            <Menu.Item key="1">All Visits</Menu.Item>
            <Menu.Item key="2">Unique Visitors</Menu.Item>
        </Menu>
    );

    const getUsersData = async () => {
        try {
            setPeopleLoading(true);
            const { status, message, visits, pagination } =
                await getVisitorUsers(
                    livepageId,
                    projectId,
                    selectedUser.current.user_id,
                    timezone.toLowerCase(),
                    startDate,
                    endDate,
                    currentPeoplePage,
                    visitsUsersSortDetails?.by,
                    visitsUsersSortDetails?.order
                );
            if (status && visits.length > 0) {
                const parsedViews = visits.map((data, index) => ({
                    key: `${data.user_id}-${index}`,
                    firstName: data?.firstname ? data.firstname : '',
                    lastName: data?.lastname ? data.lastname : '',
                    email: data?.email ? data.email : '',
                    company: data?.company ? data.company : '',
                    jobTitle: data?.jobTitle ? data.jobTitle : '',
                    visit_first_action_time: data?.visit_first_action_time
                        ? data.visit_first_action_time
                        : '',
                    visit_last_action_time: data?.visit_last_action_time
                        ? data.visit_last_action_time
                        : '',
                    livepageId,
                    livepageSlug: data?.livepageSlug ? data.livepageSlug : '',
                    entry_page: data?.entry_page ? data.entry_page : '',
                    exit_page: data?.exit_page ? data.exit_page : '',
                    visitDuration: data?.visitDuration
                        ? data.visitDuration
                        : '',
                    avgVisitDuration: data?.avgVisitDuration
                        ? data.avgVisitDuration
                        : '',
                }));
                setPeopleData(parsedViews);
                setPeoplePaginationData(pagination);
                setPeopleLoading(false);
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
            setPeopleData([]);
            setPeopleLoading(false);
        }
    };
    const handleUserModal = ({ user_id, livepageId }) => {
        setModalVisibility(true);
        selectedUser.current = { user_id, livepageId };
        getUsersData();
    };
    const exportUsersData = async () => {
        try {
            setPeopleExporting(true);
            const response = await exportVisitorUsers(
                livepageId,
                projectId,
                selectedUser.current.user_id,
                timezone.toLowerCase(),
                startDate,
                endDate
            );

            FileSaver.saveAs(
                response,
                `Visit-Users-Data-${moment().unix()}.csv`
            );

            setPeopleExporting(false);
        } catch (error) {
            console.error(error);
            setPeopleExporting(false);
        }
    };

    const handleVisitorsData = async (page = null) => {
        try {
            setIsVisitorsDataLoading(true);
            const { status, message, visits, pagination } = await getVisitors(
                livepageId,
                projectId,
                timezone.toLowerCase(),
                startDate,
                endDate,
                page ? page : currentPage,
                PAGE_SIZE,
                sortDetails?.by,
                sortDetails?.order
            );
            if (status) {
                if (visits.length === 0) {
                    setNoData(true);
                } else {
                    setNoData(false);
                }

                const parsedVisits = visits.map((data, index) => ({
                    key: `${data.user_id}-${index}`,
                    firstName: data?.firstname ? data.firstname : '',
                    lastName: data?.lastname ? data.lastname : '',
                    email: data?.email ? data.email : '',
                    company: data?.company ? data.company : '',
                    jobTitle: data?.jobTitle ? data.jobTitle : '',
                    visitDuration: data?.visitDuration
                        ? data.visitDuration
                        : '',
                    avgVisitDuration: data?.avgVisitDuration
                        ? data.avgVisitDuration
                        : '',
                    livepageId,
                    livepageSlug: data?.livepageSlug ? data.livepageSlug : '',
                    entry_page: data?.entry_page ? data.entry_page : '',
                    exit_page: data?.exit_page ? data.exit_page : '',
                    user_id: data?.user_id ? data.user_id : '',
                    visits: data?.visits ? data.visits : '',
                }));

                setVisitors(parsedVisits);
                setPagination(pagination);
                setIsVisitorsDataLoading(false);
            } else {
                setIsVisitorsDataLoading(false);
                throw new Error(message);
            }
        } catch (error) {
            if (error && error.message.includes('jwt expired')) {
                handleAnalyticsToken();
            }
            setIsVisitorsDataLoading(false);
            console.error(error);
        }
    };
    const exportVisitiorsData = async (viewType = null) => {
        try {
            setExporting(true);
            const response = await exportVisitors(
                livepageId,
                projectId,
                timezone.toLowerCase(),
                startDate,
                endDate,
                viewType
            );

            FileSaver.saveAs(
                response,
                `Visits-${viewType ? 'All' : 'Unique'}-${moment().unix()}.csv`
            );

            setExporting(false);
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        const { field = null, order = null } = sorter;
        if (field && order)
            setSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: field,
            });
    };
    const handleModalChange = (pagination, filters, sorter) => {
        const { order = null } = sorter;
        if (order)
            setVisitsUsersSortDetails({
                order: order.includes('asc') ? 'ASC' : 'DESC',
                by: visitsUsersSortDetails?.by,
            });
    };

    useEffect(() => {
        if (startDate && endDate && isAnalyticsAuthSet) {
            if (currentPage !== 1) {
                setCurrentPage(1);
                pageFlag.current = false;
            }
            handleVisitorsData(1);
        }
    }, [startDate, endDate, isAnalyticsAuthSet, timezone]);

    useEffect(() => {
        if (pageFlag.current) {
            handleVisitorsData();
        }
        pageFlag.current = true;
    }, [currentPage]);

    useEffect(() => {
        if (peoplePageFlag.current) {
            getUsersData();
        }
        peoplePageFlag.current = true;
    }, [currentPeoplePage]);

    useEffect(() => {
        if (!modalVisibility) {
            setPeopleData([]);
            setPeoplePaginationData([]);
            setPeopleCurrentPage(1);
            peoplePageFlag.current = false;
            selectedUser.current = null;
        } else {
            peoplePageFlag.current = true;
        }
    }, [modalVisibility]);

    useEffect(() => {
        if (sortDetails?.order && sortDetails?.by && isMounted.current) {
            handleVisitorsData();
        }
        isMounted.current = true;
    }, [sortDetails]);
    useEffect(() => {
        if (
            modalVisibility && visitsUsersSortDetails?.order
            && visitsUsersSortDetails?.by && isMounted.current
        ) {
            getUsersData();
        }
    }, [visitsUsersSortDetails]);

    return (
        <div className="usersVisitorAnalysisContainer">
            {!error && (
                <>
                    <LivePageSelector
                        {...{
                            projectId,
                            setLoading,
                            setError,
                            isLoading,
                            isVisiblePages: false,
                        }}
                    />
                </>
            )}
            {error && <div className="errorContainer">{error}</div>}

            {isVisitorsDataLoading && (!visitors || visitors.length === 0) && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                    msg="Fetching data..."
                />
            )}

            {!error && visitors.length > 0 && (
                <div className="visitsContainer">
                    <div className="titleContainer">
                        <h2 className="pageHeading">Visitors List</h2>
                        <span>
                            {`${(currentPage - 1) * PAGE_SIZE + 1}-${
                                (currentPage - 1) * PAGE_SIZE + visitors.length
                            } of ${paginationInfo.total} records`}
                        </span>
                    </div>
                    <Table
                        onChange={handleChange}
                        dataSource={visitors}
                        columns={columns}
                        loading={isVisitorsDataLoading}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: paginationInfo?.total,
                            showSizeChanger: false,
                            current: currentPage,
                        }}
                    />
                    <div className="exportContainer">
                        <Dropdown overlay={exportMenu}>
                            <Button loading={isExporting}>
                                Export <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            )}

            {hasNoData && !isVisitorsDataLoading && (
                <div className="visitsContainer">
                    <div className="noUserContainer">No users found</div>
                </div>
            )}

            <Modal
                width={1200}
                visible={modalVisibility}
                footer={null}
                onCancel={() => setModalVisibility(false)}
            >
                <div className="peopleModal">
                    <Table
                        style={{ width: '1200px' }}
                        dataSource={peopleData}
                        columns={visitUsersColumns}
                        onChange={handleModalChange}
                        loading={isPeopleDataLoading}
                        bordered
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            onChange: setPeopleCurrentPage,
                            pageSize: PAGE_SIZE,
                            total: peoplePaginationData?.total,
                            showSizeChanger: false,
                            current: currentPeoplePage,
                        }}
                    />
                    {peopleData.length > 0 && !isPeopleDataLoading && (
                        <div className="exportContainer">
                            <div
                                onClick={() => {
                                    if (!isPeopleExporting)
                                        exportUsersData();
                                }}
                            >
                                {isPeopleExporting
                                    ? 'Exporting...'
                                    : 'Export'}
                            </div>
                        </div>
                    )}
                    {peopleData.length === 0 && !isPeopleDataLoading && (
                        <div className="noRecords">No records found</div>
                    )}
                </div>
            </Modal>

            <style>{`
            ant-select-selection-search
            {
                height: 100%;
                line-height: 46px;

            }
            `}</style>
        </div>
    );
};
export default UserVisits;
