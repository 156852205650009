import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

import SwitchDisplayModeView from '../SwitchDisplayModeView';
import LinkSimpleIcon from '../../../Icons/LinkSimpleIcon';
import LineBreakIcon from '../../../Icons/LineBreakIcon';
import ChevronDown from '../../../Icons/ChevronDown';

function BlockViewConfiguration({
    property = '',
    relatedProperties = [],
    setValue = () => {},
    getValues = () => undefined,
    linkedSettings = {},
    setLinkedSettings = () => {},
    isRelatedProperty = false,
    children = null,
}) {
    const allProperties = [property, ...relatedProperties];
    const isLinked = !!linkedSettings?.[property];
    const displayModeRedux = useSelector((state) => state?.header.display);

    const copyPropertyValueToAllViews = (propertyKey = property) => {
        if (!propertyKey) return;
        const currentModePropertyKey = displayModeRedux.desktop
            ? propertyKey
            : displayModeRedux.tablet
            ? `tabletBreakpoints.${propertyKey}`
            : `mobileBreakpoints.${propertyKey}`;
        const currentModePropertyValue = getValues(currentModePropertyKey);
        setValue(propertyKey, currentModePropertyValue);
        setValue(`tabletBreakpoints.${propertyKey}`, currentModePropertyValue);
        setValue(`mobileBreakpoints.${propertyKey}`, currentModePropertyValue);
    };

    const copyAllPropertiesToAllViews = () => {
        allProperties.forEach((propertyKey) => {
            copyPropertyValueToAllViews(propertyKey);
        });
    };

    const linkOrUnlinkPropertyHandler = (isLink = true) => {
        if (isLink) {
            copyAllPropertiesToAllViews();
        }
        setLinkedSettings &&
            setLinkedSettings((prevState) => ({
                ...prevState,
                ...allProperties.reduce(
                    (acc, propertyKey) => ({
                        ...acc,
                        [propertyKey]: isLink,
                    }),
                    {}
                ),
            }));
    };

    useEffect(() => {
        if (!property || !isLinked) return;
        copyAllPropertiesToAllViews();
    }, [
        property,
        isLinked,
        ...allProperties.map((propertyKey) => getValues(propertyKey)),
        ...allProperties.map((propertyKey) =>
            getValues(`tabletBreakpoints.${propertyKey}`)
        ),
        ...allProperties.map((propertyKey) =>
            getValues(`mobileBreakpoints.${propertyKey}`)
        ),
    ]);

    return isRelatedProperty ? null : !property ? (
        <div className="tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-justify-center tw-items-center">
            {children}
            <SwitchDisplayModeView />
        </div>
    ) : (
        <div className="tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-justify-center tw-items-center">
            {children}
            {isLinked ? (
                <p className="tw-m-0 tw-p-0 tw-w-fit">
                    <LinkSimpleIcon />
                </p>
            ) : (
                <SwitchDisplayModeView />
            )}
            <Popover
                className="tw-w-fit tw-h-fit"
                placement="right"
                trigger={['click', 'hover']}
                content={
                    <div className="tw-w-fit tw-h-fit tw-flex tw-flex-col tw-gap-1.5">
                        <p className="tw-m-0 tw-p-0 tw-font-openSans tw-font-semibold tw-text-sm">
                            {isLinked
                                ? 'Linked across all views'
                                : 'Specific for this view'}
                        </p>
                        <p className="tw-m-0 tw-p-0 tw-font-openSans tw-font-normal tw-text-xs">
                            {isLinked ? (
                                <>
                                    Any changes done here will reflect across
                                    <br />
                                    all other views.
                                </>
                            ) : (
                                <>
                                    This content/setting applies to this view
                                    <br />
                                    only.
                                </>
                            )}
                        </p>
                        <div
                            className="tw-py-1 tw-px-2 tw-mt-1 tw-flex tw-flex-row tw-gap-2 tw-justify-center tw-items-center tw-bg-[#F8F8F8] hover:tw-bg-gray-200 tw-rounded-lg tw-cursor-pointer"
                            onClick={() =>
                                linkOrUnlinkPropertyHandler(!isLinked)
                            }
                        >
                            <p className="tw-m-0 tw-p-0 tw-w-fit">
                                <LineBreakIcon />
                            </p>
                            <p className="tw-w-full tw-m-0 tw-p-0 tw-font-openSans tw-font-normal tw-text-xs tw-text-[#0076CB]">
                                {isLinked ? (
                                    <>
                                        Break the link and customise for
                                        <br />
                                        this view only
                                    </>
                                ) : (
                                    <>
                                        Link and sync across all the other
                                        <br />
                                        views
                                    </>
                                )}
                            </p>
                        </div>
                        {isLinked ? null : (
                            <p className="tw-m-0 tw-p-0 tw-font-openSans tw-font-normal tw-text-xxs tw-text-[#7F7E7E]">
                                <span className="tw-font-bold">Note:</span>{' '}
                                Syncing across all views will overwrite
                                <br />
                                any changes done in the other views.
                            </p>
                        )}
                    </div>
                }
            >
                <p
                    className="tw-p-0.5 tw-m-0 tw-cursor-pointer"
                    style={{ rotate: '-90deg' }}
                >
                    <ChevronDown />
                </p>
            </Popover>
        </div>
    );
}

export default BlockViewConfiguration;
