import * as React from "react"

const CustomHTMLIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 11l-6 5 6 5M24 11l6 5-6 5M20 5l-8 22"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CustomHTMLIcon
