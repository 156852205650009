import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { getRecordings } from 'apis/rest/getMeetRecordings';
import { Table } from 'antd';
import moment from 'moment';
import PageSpinner from 'components/app/common/pageSpinner';
import TimezoneSelector from 'components/app/common/timezoneSelector';
import { withRouter, useParams } from 'react-router-dom';

const RecordingListing = ({ currentTab = '' }) => {
    const { projectId } = useParams();
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    const [recordings, setRecordings] = useState(null);

    const [isLoading, setLoading] = useState(true);

    const columns = [
        {
            title: <h3>Recordings</h3>,
            dataIndex: 'url',
            render: (url) => {
                return (
                    <a
                        className={styles.downloadLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url}
                    >
                        Download
                    </a>
                );
            },
        },
        {
            title: <h3>Recorded By</h3>,
            dataIndex: 'recordedBy',
        },
        {
            title: <h3>Page Title</h3>,
            dataIndex: 'pageTitle',
        },

        {
            title: <h3>Created At</h3>,
            dataIndex: 'createdAt',
            sorter: (a, b) =>
                moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            render: (updatedAt, item) => {
                if (!updatedAt) return null;
                return (
                    <span>
                        {moment(updatedAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] h:mm:ss a')}
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        if (currentTab !== 'recorded-meetings') {
            return;
        }
        const handleGetRecordings = async () => {
            try {
                setLoading(true);
                const { recordings: recordData } = await getRecordings(
                    projectId
                );

                if (recordData) setRecordings(recordData);

                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        handleGetRecordings();
    }, [currentTab]);

    return (
        <div className={styles.Wrap}>
            <div className={styles.meetRecordContainer}>
                <TimezoneSelector
                    wrapperClass={styles.recordingTimezoneSelect}
                />
            </div>
            {isLoading && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={45}
                    width={45}
                />
            )}

            {recordings && (
                <Table
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 8,
                        showSizeChanger: false,
                    }}
                    style={{ overflow: 'auto' }}
                    columns={columns}
                    dataSource={recordings}
                />
            )}
        </div>
    );
};
export default withRouter(RecordingListing);
