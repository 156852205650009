export function setUser(payload) {
    return { type: 'SET_USER', payload };
}

export function updateUser(payload) {
    return { type: 'UPDATE_USER', payload };
}

export function setRestrictAccess(payload) {
    return { type: 'SET_RESTRICT_ACCESS', payload };
}

export function setPreferredExperience(payload) {
    return { type: 'SET_PREFERRED_EXPERIENCE', payload };
}
