import React from 'react';

export default function InfoIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_41_1278)">
                <path
                    d="M7.99967 15.3129C4.31767 15.3129 1.33301 12.3283 1.33301 8.64628C1.33301 4.96428 4.31767 1.97961 7.99967 1.97961C11.6817 1.97961 14.6663 4.96428 14.6663 8.64628C14.6663 12.3283 11.6817 15.3129 7.99967 15.3129ZM7.33301 7.97961V11.9796H8.66634V7.97961H7.33301ZM7.33301 5.31295V6.64628H8.66634V5.31295H7.33301Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_41_1278">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.64624)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
