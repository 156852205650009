import React from 'react';

export default function PrivateHomeIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.084 0.336413C7.33156 0.120374 7.65932 0 8 0C8.34068 0 8.66844 0.120374 8.916 0.336413L15.3747 5.96703C15.7747 6.31533 16 6.80024 16 7.30854V14.1539C16 14.6435 15.7893 15.1131 15.4142 15.4593C15.0391 15.8055 14.5304 16 14 16H2C1.4698 16 0.961292 15.8057 0.586258 15.4597C0.211223 15.1138 0.000353468 14.6445 0 14.1551V7.30731C0 6.80024 0.226667 6.31533 0.626667 5.96703L7.084 0.336413Z"
                fill="black"
            />
            <path
                d="M7.61 6C6.71053 6 5.97875 6.73178 5.97875 7.63125V8.61H5.6525C5.47945 8.61 5.31348 8.67875 5.19111 8.80111C5.06875 8.92348 5 9.08945 5 9.2625V11.8725C5 12.0456 5.06875 12.2115 5.19111 12.3339C5.31348 12.4563 5.47945 12.525 5.6525 12.525H9.5675C9.74055 12.525 9.90652 12.4563 10.0289 12.3339C10.1513 12.2115 10.22 12.0456 10.22 11.8725V9.2625C10.22 9.08945 10.1513 8.92348 10.0289 8.80111C9.90652 8.67875 9.74055 8.61 9.5675 8.61H9.24125V7.63125C9.24125 6.73178 8.50947 6 7.61 6ZM6.63125 7.63125C6.63125 7.09163 7.07038 6.6525 7.61 6.6525C8.14962 6.6525 8.58875 7.09163 8.58875 7.63125V8.61H6.63125V7.63125ZM7.93625 11.1296V11.8725H7.28375V11.1296C7.16969 11.0643 7.0781 10.966 7.02106 10.8475C6.96403 10.7291 6.94422 10.5962 6.96425 10.4663C6.98428 10.3364 7.04321 10.2156 7.13327 10.1199C7.22333 10.0241 7.34029 9.95793 7.46873 9.93001C7.56414 9.90891 7.66305 9.9095 7.7582 9.93173C7.85334 9.95396 7.94228 9.99726 8.01846 10.0584C8.09464 10.1196 8.15611 10.1971 8.19835 10.2852C8.24059 10.3733 8.26251 10.4698 8.2625 10.5675C8.26231 10.6816 8.23206 10.7936 8.17478 10.8923C8.1175 10.991 8.03523 11.0729 7.93625 11.1296V11.1296Z"
                fill="white"
            />
        </svg>
    );
}
