import React from 'react';

export default function DownloadIcon({ stroke = '#000' }) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 14.2144H1C0.447715 14.2144 0 14.6408 0 15.1667C0 15.6927 0.447715 16.1191 1 16.1191H15C15.5523 16.1191 16 15.6927 16 15.1667C16 14.6408 15.5523 14.2144 15 14.2144Z"
                fill={stroke || '#000'}
            />
            <path
                d="M0 13.2619L0 15.1667C0 15.6927 0.447715 16.1191 1 16.1191C1.55228 16.1191 2 15.6927 2 15.1667V13.2619C2 12.736 1.55228 12.3096 1 12.3096C0.447715 12.3096 0 12.736 0 13.2619Z"
                fill={stroke || '#000'}
            />
            <path
                d="M14 13.2619V15.1667C14 15.6927 14.4477 16.1191 15 16.1191C15.5523 16.1191 16 15.6927 16 15.1667V13.2619C16 12.736 15.5523 12.3096 15 12.3096C14.4477 12.3096 14 12.736 14 13.2619Z"
                fill={stroke || '#000'}
            />
            <path
                d="M7.99946 12.3096C7.79214 12.3111 7.58946 12.2512 7.41946 12.1381L3.41946 9.45243C3.20387 9.30678 3.05761 9.08576 3.01263 8.8377C2.96766 8.58963 3.02764 8.33469 3.17946 8.12862C3.25525 8.02562 3.35171 7.93794 3.46327 7.87063C3.57483 7.80332 3.69928 7.75773 3.82945 7.73648C3.95961 7.71523 4.09291 7.71874 4.22164 7.74681C4.35037 7.77489 4.47198 7.82697 4.57946 7.90005L7.99946 10.1762L11.3995 7.73815C11.6116 7.5866 11.8783 7.52152 12.1409 7.55724C12.4034 7.59297 12.6403 7.72655 12.7995 7.92862C12.9586 8.13069 13.0269 8.38469 12.9894 8.63474C12.9519 8.88479 12.8116 9.11041 12.5995 9.26196L8.59946 12.1191C8.42637 12.2427 8.21583 12.3096 7.99946 12.3096Z"
                fill={stroke || '#000'}
            />
            <path
                d="M8 10.4048C7.73478 10.4048 7.48043 10.3045 7.29289 10.1258C7.10536 9.94724 7 9.705 7 9.45241V1.83336C7 1.58078 7.10536 1.33853 7.29289 1.15993C7.48043 0.981321 7.73478 0.880981 8 0.880981C8.26522 0.880981 8.51957 0.981321 8.70711 1.15993C8.89464 1.33853 9 1.58078 9 1.83336V9.45241C9 9.705 8.89464 9.94724 8.70711 10.1258C8.51957 10.3045 8.26522 10.4048 8 10.4048Z"
                fill={stroke || '#000'}
            />
        </svg>
    );
}
