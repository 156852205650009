const defaultShowcase = {
  title: "Showcase Name",
  templateType: "tile",
  slug: "showcase-name",

  callToActionAction: "phone",
  callToActionText: "Call",
  callToActionValue: "",

  overviewTitle: "Overview title",
  overviewSummary:
    "Some overview content can be added here. This can be rich content with text formatting, images, links, etc.",
  overview: "Write your rich format content here."
};

export default defaultShowcase;
