import React from 'react';

function EditPencil() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="#000"
                d="M11.834 3.223a.585.585 0 00-.17-.414L9.192.336a.583.583 0 00-.414-.17.583.583 0 00-.414.17l-1.651 1.65L.336 8.363a.583.583 0 00-.169.415v2.473a.583.583 0 00.583.583h2.474a.583.583 0 00.443-.169l6.34-6.376 1.658-1.621a.695.695 0 00.128-.193.575.575 0 000-.14.398.398 0 000-.082l.04-.029zm-8.85 7.444h-1.65V9.016l5.792-5.793 1.651 1.651-5.792 5.793zM9.6 4.052L7.949 2.4l.828-.823 1.645 1.645-.822.829z"
            ></path>
        </svg>
    );
}

export default EditPencil;
