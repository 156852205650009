export const decodeHtml = (html) => {
    try {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    } catch (e) {
        console.log('Error in decoding HTML: ', e);
        return '';
    }
};
