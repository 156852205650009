import React from 'react';
import * as styles from './index.module.scss';

function SpeakerEmpty() {
    return (
        <div className={styles.emptyContainer}>
            <div className={styles.noRecord}>
                <h2>No Speakers Created</h2>
                <p>Get started by creating or importing speakers</p>
            </div>
        </div>
    );
}

export default SpeakerEmpty;
