import * as React from "react"

const PollsIcon = (props) => {
    return (
        <svg width={34} height={35} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path d="M8.22228 26L23.7778 26C25.0051 26 26.0001 25.0051 26.0001 23.7778L26.0001 8.22222C26.0001 6.99492 25.0051 6 23.7778 6L8.22228 6C6.99499 6 6.00006 6.99492 6.00006 8.22222L6.00006 23.7778C6.00006 25.0051 6.99498 26 8.22228 26Z"
                stroke={"#7A829D"}
                stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.0001 16V22" stroke={"#7A829D"} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.0001 11L19.0001 22" stroke={"#7A829D"} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default PollsIcon
