import * as React from 'react';

const ArrowRightIcon = (props) => {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                d="M5.309 12.965a.493.493 0 01-.225-.167.426.426 0 01-.084-.252V3.455c0-.09.03-.178.084-.253a.493.493 0 01.225-.167.547.547 0 01.289-.026c.097.017.186.06.256.124l5 4.546a.453.453 0 01.108.147.418.418 0 010 .348.453.453 0 01-.108.147l-5 4.546a.518.518 0 01-.256.124.547.547 0 01-.29-.026z"
                fill="#000"
            />
        </svg>
    );
};

export default ArrowRightIcon;
