import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    Tag,
    Button,
    Menu,
    Dropdown,
    Modal,
    Carousel,
    Tooltip,
    Radio,
    Space,
} from 'antd';
import 'antd/dist/antd.css';
import {
    DownOutlined,
    LeftOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import * as ls from 'local-storage';
import Loader from '../Loader';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
// API
import {
    getPhysicalVenueDetail,
    setCompanyVenue,
    getCompanyVenues,
    copyVenueFromStore,
    setChooseVenue,
} from 'apis/rest/venue/Venues';
import { getSettings } from 'apis/rest/livestream/GetSettings';
import TagManager from 'react-gtm-module';
import CloseIcon from '../Icons/CloseIcon';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
// redux
import * as venuesActions from 'redux/actions/venues/actionsVenues';

import './venuedetails.scss';
import { chooseExperience } from 'Apps/ExperienceSelection/Api';

const Venuedetails = ({
    isCollectionModalVisible,
    setVenueDetailsModalVisible,
    data,
    companyId,
    projectId,
    setChoosedVenue,
    setModal,
    setVisible
}) => {
    const dispatch = useDispatch();
    const { showAlertBar } = useAlertBar();

    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState({});

    // state
    const venueDetailsData = useSelector(
        (state) => state?.venues?.venuedetails?.venue
    );
    const venueId = useSelector((state) => state?.venuesettings?.venueId);
    const user = useSelector(state => state.user)
    const history = useHistory();

    const [selectedExperience, setSelectedExperience] = useState(
        venueDetailsData?.experiences?.length > 0
            ? venueDetailsData?.experiences[0].name
            : null
    );

    const useThisVenue = async () => {
        if (!venueId) {
            setSubmitted(true);
            const setvenue = await chooseExperience(projectId, {
                venueId: data.id,
                experience: selectedExperience?.name,
            });
            if (setvenue.status) {
                showAlertBar(`Experience successfully added.`, 'success');
                setChoosedVenue(true);
                dispatch(venuesActions.setVenueId(data.id));
                dispatch({
                    type: 'SET_PROJECT_VENUE_DETAILS',
                    payload: {
                        venueId: data.id,
                        experience: selectedExperience?.name,
                    },
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'venueSelected',
                        info: {
                            venueId: data?.id,
                            projectId,
                        },
                    },
                });
                setVenueDetailsModalVisible(false);
            } else {
                showAlertBar(`${setvenue.message}`, 'error');
            }
            setSubmitted(false);
        }
    };

    const addToVenueCollection = async () => {
        // this will add venue to venue collection
        if (!venueId) {
            setSubmitted(true);
            const addtocollection = await copyVenueFromStore(
                projectId,
                data.id
            );
            if (addtocollection.status) {
                dispatch(venuesActions.setVenueId(data.id));
                showAlertBar(`Venue successfully added.`, 'success');
                setVenueDetailsModalVisible(false);
                setChoosedVenue(true);
            } else {
                showAlertBar(`${addtocollection.message}`, 'error');
            }
            setSubmitted(false);
        }
    };

    // const actionMenusDetails = (
    //     <Menu
    //         style={{width: 202}}
    //         className="details">
    //         <Menu.Item
    //             disabled={venueId}
    //             onClick={useThisVenue}>
    //             <Tooltip
    //                 placement="right"
    //                 title="This means that the venue will sit on the Venue Store and you won’t be able to edit the code. Choose this option if you do not intend to edit the venue’s code. Choosing this option also means that you will get any upgrades done to the venue by the venue eveloper.">
    //                 Use from the Venue Store <ExclamationCircleOutlined />
    //             </Tooltip>
    //         </Menu.Item>
    //         <Menu.Item
    //             disabled={venueId}
    //             onClick={addToVenueCollection}>
    //             <Tooltip
    //                 placement="right"
    //                 title="This means that the venue will be copied to your Venue Collection and that you will be able to to edit the venue’s code. However, you will not be able to automatically get upgrades to the code venue done by the venue developer if there’s any.">
    //                 Copy venue to my collection and use it from there <ExclamationCircleOutlined />
    //             </Tooltip>
    //         </Menu.Item>
    //     </Menu>
    // );

    const getDetails = async () => {
        const { setting } = await getSettings(projectId);
        const venueDetails = await getPhysicalVenueDetail(projectId, data.id);
        if (venueDetails.status) {
            dispatch(venuesActions.setVenueDetails(venueDetails));

            if (venueDetails.venue?.experiences?.length > 0) {
                if (
                    venueDetails.venue?.experiences.find(
                        (experience) =>
                            experience.name === setting.experience?.name
                    )
                ) {
                    setSelectedExperience(
                        venueDetails.venue?.experiences.find(
                            (experience) =>
                                experience.name === setting.experience?.name
                        )
                    );
                } else {
                    setSelectedExperience(venueDetails.venue?.experiences[0]);
                }
            }
            // call venue collections for new data
            const getVenuesData = await getCompanyVenues(projectId);
            if (getVenuesData.status) {
                dispatch(venuesActions.setVenues(getVenuesData));
            }

            setLoading(false);
        } else {
            setError(venueDetails);
            setLoading(false);
        }
    };

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <>
            <Modal
                centered
                visible={isCollectionModalVisible}
                onCancel={() => {
                    setModal(false);
                    setVenueDetailsModalVisible(false);
                }}
                footer={null}
                destroyOnClose={true}
                width={1072}
                closeIcon={<CloseIcon loading={submitted} />}
                maskClosable={false}
                mask={true}
                transitionName="none"
                maskTransitionName="none"
                className="venue-store-modal-details"
            >
                <h2 onClick={() =>{ setVenueDetailsModalVisible(false); setVisible && setVisible(true);}}>
                    <LeftOutlined /> Back
                </h2>
                <div
                    className={`venue-modal-content ${
                        submitted ? 'onsubmit-loading' : ''
                    }${loading ? 'loading' : ''}`}
                >
                    {submitted && (
                        <GlobalLoading loadingText="Please wait..." />
                    )}

                    {loading ? (
                        <Loader text="Loading experience details..." />
                    ) : !error.status && error.message ? (
                        <h2
                            style={{
                                textTransform: 'capitalize',
                                margin: '10px 0 270px',
                            }}
                        >
                            <ExclamationCircleOutlined
                                style={{ color: '#FF0000' }}
                            />{' '}
                            {error.message}
                        </h2>
                    ) : (
                        <>
                            <div className="left">
                                {selectedExperience?.gallery?.length > 0 ? (
                                    <Carousel>
                                        {selectedExperience?.gallery?.map(
                                            (image, index) => {
                                                return (
                                                    <div key={index}>
                                                        <img
                                                            src={image}
                                                            alt={`screenshot_${
                                                                index + 1
                                                            }`}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Carousel>
                                ) : venueDetailsData?.gallery?.length > 0 ? (
                                    <Carousel>
                                        {venueDetailsData?.gallery?.map(
                                            (image, index) => {
                                                return (
                                                    <div key={index}>
                                                        <img
                                                            src={image}
                                                            alt={`screenshot_${
                                                                index + 1
                                                            }`}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Carousel>
                                ) : (
                                    <img
                                        style={{ width: '95%' }}
                                        alt={venueDetailsData?.name}
                                        src={venueDetailsData?.thumbnail}
                                    />
                                )}
                            </div>
                            <div className="right">
                                <div className="heading">
                                    <h1>{venueDetailsData?.name}</h1>
                                    <span>{venueDetailsData?.developer}</span>
                                </div>
                                <div className="buttons">
                                    {/* <Link
                                        to={`#`}
                                        className="btn btn-preview" >
                                        Preview this venue
                                    </Link> */}

                                    {/* <Dropdown
                                        overlay={actionMenusDetails} > */}
                                    <Button
                                        disabled={venueId}
                                        onClick={useThisVenue}
                                        className="btn btn-primary"
                                    >
                                        Choose this experience
                                        {/* <DownOutlined /> */}
                                    </Button>
                                    {/* </Dropdown> */}
                                </div>
                                {/* <div className="tags">
                                        <h2>Good For</h2>
                                        <div className="tag-details">
                                            {venueDetailsData?.goodFor?.split(', ').map((tag, idx) => {
                                                return <Tag key={idx}>{tag}</Tag>
                                            })}
                                        </div>
                                    </div> */}
                                <div className="descrition">
                                    {/* <h2>Description</h2> */}
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: venueDetailsData?.description,
                                        }}
                                    />
                                </div>
                                <div className="experiences">
                                    <h2>
                                        Includes{' '}
                                        {venueDetailsData?.experiences?.length}{' '}
                                        experiences
                                    </h2>
                                    <p style={{ marginBottom: 12 }}>
                                        Choose one to get started with:
                                    </p>
                                    <Radio.Group
                                        onChange={(e) =>
                                            setSelectedExperience(
                                                venueDetailsData?.experiences?.find(
                                                    (experience) =>
                                                        experience.name ===
                                                        e.target.value
                                                )
                                            )
                                        }
                                        value={selectedExperience?.name}
                                    >
                                        <Space direction="vertical">
                                            {venueDetailsData?.experiences?.map(
                                                (experience, index) => {
                                                    return (
                                                        <Radio
                                                            key={index}
                                                            value={
                                                                experience.name
                                                            }
                                                        >
                                                            {experience.name}
                                                        </Radio>
                                                    );
                                                }
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <style>{`
                                        span.ant-radio + * {
                                            font-weight: normal;
                                        }
                                `}</style>
                                {/* <div className="features">
                                        <h2>Features</h2>
                                        <ul>
                                            {venueDetailsData?.features?.split(', ').map((feat, idx) => {
                                                return <li key={idx}>{feat}</li>
                                            })}
                                        </ul>
                                    </div> */}
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default Venuedetails;
