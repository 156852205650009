import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import validator from 'validator';
import SettingsText from 'Apps/VenueBuilder/Components/Common/SettingsText';
import CardDisplay from 'Apps/VenueBuilder/Components/Common/CardDisplay';
import SwitchComponent from 'Apps/VenueBuilder/Components/Common/SwitchComponent';
import { GetChannels } from 'apis/rest/channels/GetChannels';

import './streamingServiceInput.scss';
import GevmeLive from 'Apps/VenueBuilder/Components/Layout/AppSideBar/Components/ContentBlocks/components/VideoLiveStreamBlock/Components/GevmeLive';
import { isEmpty } from 'lodash';

const StreamingServiceInput = forwardRef(
    (
        {
            streamType,
            ctaStreamingServiceUrl,
            setCtaStreamingServiceUrl,
            setCtaStreamingDefaultWatch,
            ctaStreamingDefaultLogo,
            setCtaStreamingDefaultLogo,
            ctaStreamingChannelId,
            setCtaStreamingChannelId,
            ctaDisableAutoPlay,
            setCtaDisableAutoPlay,
            ctaDisableVideoQuality,
            setCtaDisableVideoQuality,
            isInStarterPlan,
        },
        ref
    ) => {
        const { control, errors, setValue, trigger, watch } = useForm();
        const methods = useForm();
        const { projectId } = useParams();

        const [displaySomethingWhenNotLive, setDisplaySomethingWhenNotLive] =
            useState('image');
        const [videoLink, setVideoLink] = useState('');
        const [disableVideoQualityOptions, setDisableVideoQualityOptions] =
            useState(false);
        const [disableAutoplay, setDisableAutoplay] = useState(true);
        const [playbackUrl, setPlayBackUrl] = useState('');
        const [channelsData, setChannelsData] = useState(null);
        const [imageError, setImageError] = useState(false);
        const [imageUrl, setImageUrl] = useState('');

        useImperativeHandle(ref, () => ({
            handleSubmitForm() {
                if (streamType === 'customstream') {
                    if (isEmpty(imageUrl)) {
                        setImageError(true);
                        return null;
                    }
                    if (imageUrl === 'null') {
                        setImageError(true);
                        return null;
                    }
                    if (!isEmpty(imageUrl)) {
                        setImageError(false);
                    }
                }
                if (streamType === 'gevmelive') {
                    if (!ctaStreamingChannelId) {
                        return null;
                    }
                    if (isEmpty(imageUrl)) {
                        setImageError(true);
                        return null;
                    }
                    if (imageUrl === 'null') {
                        setImageError(true);
                        return null;
                    }
                    if (!isEmpty(imageUrl)) {
                        setImageError(false);
                    }
                }
                return trigger();
            },
        }));

        useEffect(() => {
            const watchForms = watch();
            setCtaStreamingServiceUrl(
                watchForms?.livestreamUrl ? watchForms?.livestreamUrl : ''
            );
            setImageUrl(
                watchForms?.livestreamUrlImageCustom
                    ? watchForms?.livestreamUrlImageCustom
                    : ''
            );
        }, [watch()]);

        useEffect(() => {
            if (!isInStarterPlan) handleGetChannels();

            return () => setChannelsData(null);
        }, [isInStarterPlan]);

        const handleGetChannels = async (search = '') => {
            try {
                const response = await GetChannels(projectId, 1, search);
                if (response.status) {
                    setChannelsData(response);
                    // return response.channels
                } else {
                    openNotification('error', { message: response.message });
                }
            } catch (error) {
                openNotification('error', {
                    message: 'An error was encountered please try again',
                });
            }
        };

        const validFrame = (value) => {
            const custom = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;

            if (streamType === 'custom' && !custom.test(value)) return false;

            return true;
        };

        const isValidURL = (value) => {
            const youtubePatern =
                '^.*(youtube|youtube-nocookie)[.]com[/]embed.*$';
            const vimeoPatern = '^.*vimeo.*$';
            const facebookPatern = '^.*facebook[.]com[/].*$';
            const msteamsPatern = '^.*web[.]microsoftstream.*$';

            if (!validator.isURL(value)) return false;
            if (streamType === 'youtube' && !value.match(youtubePatern))
                return false;
            if (streamType === 'vimeo' && !value.match(vimeoPatern))
                return false;
            if (streamType === 'facebook' && !value.match(facebookPatern))
                return false;
            if (streamType === 'msteams' && !value.match(msteamsPatern))
                return false;

            return true;
        };

        return (
            <FormProvider {...methods}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div
                        style={{
                            marginLeft: -15,
                            marginRight: -15,
                            marginBottom: 15,
                        }}
                    >
                        {/* Custom URL */}
                        {streamType === 'customstream' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Streaming URL"
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                            errorType="message"
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={ctaStreamingServiceUrl}
                                    rules={{
                                        required: true,
                                        // pattern: {
                                        //     value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                                        //     message: 'URL invalid',
                                        // },
                                    }}
                                />
                                {errors?.livestreamUrl?.type === 'required' && (
                                    <span>Required</span>
                                )}

                                <Controller
                                    name="livestreamUrlImageCustom"
                                    control={control}
                                    defaultValue={ctaStreamingDefaultLogo}
                                    render={({ field }) => {
                                        return (
                                            <CardDisplay
                                                title="Display something when the streaming is not live"
                                                value={
                                                    displaySomethingWhenNotLive
                                                }
                                                previewImage={
                                                    ctaStreamingDefaultLogo
                                                }
                                                setValue={
                                                    setDisplaySomethingWhenNotLive
                                                }
                                                videoUrl={videoLink}
                                                setVideoLink={setVideoLink}
                                                imageDescription="Display a static image."
                                                videoDescription="Display a looping video."
                                                videoHint="e.g. https://www.youtube.com/embed/{video_id}"
                                                removeImageUrl={() => {
                                                    setCtaStreamingDefaultLogo(
                                                        ''
                                                    );
                                                    setValue(
                                                        'livestreamUrlImageCustom',
                                                        ''
                                                    );
                                                }}
                                                isRequired={true}
                                                setImage={(file) => {
                                                    setCtaStreamingDefaultLogo(
                                                        file
                                                    );
                                                    setValue(
                                                        'livestreamUrlImageCustom',
                                                        file
                                                    );
                                                }}
                                                autoUpload={false}
                                            />
                                        );
                                    }}
                                />
                                {imageError && (
                                    <span className="text-danger m-3">
                                        Display image is required
                                    </span>
                                )}

                                <div className={` switch-wrapper-stream mt-3`}>
                                    <SwitchComponent
                                        label="Disable Video Quality Option"
                                        switchStatus={ctaDisableVideoQuality}
                                        onChange={(e) =>
                                            setCtaDisableVideoQuality(e)
                                        }
                                    />
                                </div>
                                <div className={`switch-wrapper-stream`}>
                                    <SwitchComponent
                                        label="Disable AutoPlay"
                                        switchStatus={ctaDisableAutoPlay}
                                        onChange={(e) =>
                                            setCtaDisableAutoPlay(e)
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {/* Custom Embeded */}
                        {streamType === 'custom' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Embed Code"
                                            hint="e.g. <iframe src={url}></iframe>"
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={''}
                                    rules={{
                                        required: true,
                                        validate: validFrame,
                                    }}
                                />
                            </>
                        )}
                        {/* Youtube */}
                        {streamType === 'youtube' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Livestream URL"
                                            hint="e.g. https://www.youtube.com/embed/{video_id}"
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={ctaStreamingServiceUrl}
                                    rules={{
                                        required: true,
                                        validate: isValidURL,
                                    }}
                                />
                            </>
                        )}
                        {/* Vimeo */}
                        {streamType === 'vimeo' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Livestream URL"
                                            hint={`e.g. https://player.vimeo.com/video/{video_id} or \n playlist: https://vimeo.com/showcase/{list_id}/embed`}
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={ctaStreamingServiceUrl}
                                    rules={{
                                        required: true,
                                        validate: isValidURL,
                                    }}
                                />
                            </>
                        )}
                        {/* Facebook */}
                        {streamType === 'facebook' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Livestream URL"
                                            hint="e.g. https://www.facebook.com/{video_id}"
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={ctaStreamingServiceUrl}
                                    rules={{
                                        required: true,
                                        validate: isValidURL,
                                    }}
                                />
                            </>
                        )}
                        {/* Microsoft Stream */}
                        {streamType === 'msteams' && (
                            <>
                                <Controller
                                    as={
                                        <SettingsText
                                            headingTitle="Livestream URL"
                                            hint="e.g. https://web.microsoftstream.com/video/{video_id}"
                                            isVisible={true}
                                            setValue={setValue}
                                            isRequired={true}
                                            error={errors?.livestreamUrl}
                                        />
                                    }
                                    name="livestreamUrl"
                                    control={control}
                                    defaultValue={ctaStreamingServiceUrl}
                                    rules={{
                                        required: true,
                                        validate: isValidURL,
                                    }}
                                />
                            </>
                        )}
                        {/* GEVME Live */}
                        {streamType === 'gevmelive' && (
                            <>
                                <GevmeLive
                                    // gevmeliveObj={gevmeliveObj}
                                    // setGevmeliveObj={setGevmeliveObj}
                                    error={!ctaStreamingChannelId}
                                    setChannelId={setCtaStreamingChannelId}
                                    value={ctaStreamingChannelId}
                                    channels={channelsData?.channels}
                                    setPlayBackUrl={setPlayBackUrl}
                                    getLivePageChannels={(
                                        projectId,
                                        search
                                    ) => {
                                        if (!isInStarterPlan)
                                            handleGetChannels(search);
                                    }}
                                />

                                <Controller
                                    name="livestreamUrlImageCustom"
                                    control={control}
                                    defaultValue={ctaStreamingDefaultLogo}
                                    render={({ field }) => {
                                        return (
                                            <CardDisplay
                                                title="Display something when the streaming is not live"
                                                value={
                                                    displaySomethingWhenNotLive
                                                }
                                                previewImage={
                                                    ctaStreamingDefaultLogo
                                                }
                                                setValue={
                                                    setDisplaySomethingWhenNotLive
                                                }
                                                videoUrl={videoLink}
                                                setVideoLink={setVideoLink}
                                                imageDescription="Display a static image."
                                                videoDescription="Display a looping video."
                                                videoHint="e.g. https://www.youtube.com/embed/{video_id}"
                                                removeImageUrl={() => {
                                                    setCtaStreamingDefaultLogo(
                                                        ''
                                                    );
                                                    setValue(
                                                        'livestreamUrlImageCustom',
                                                        ''
                                                    );
                                                }}
                                                isRequired={true}
                                                setImage={(file) => {
                                                    setCtaStreamingDefaultLogo(
                                                        file
                                                    );
                                                    setValue(
                                                        'livestreamUrlImageCustom',
                                                        file
                                                    );
                                                }}
                                                autoUpload={false}
                                            />
                                        );
                                    }}
                                />
                                {imageError && (
                                    <span className="text-danger m-3">
                                        Display image is required
                                    </span>
                                )}

                                <div className={`switch-wrapper-stream mt-3`}>
                                    <SwitchComponent
                                        label="Disable Video Quality Option"
                                        switchStatus={
                                            disableVideoQualityOptions
                                        }
                                        onChange={(e) =>
                                            setDisableVideoQualityOptions(e)
                                        }
                                    />
                                </div>
                                <div className={` switch-wrapper-stream`}>
                                    <SwitchComponent
                                        label="Disable AutoPlay"
                                        switchStatus={disableAutoplay}
                                        onChange={(e) => setDisableAutoplay(e)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </FormProvider>
        );
    }
);

StreamingServiceInput.propTypes = {
    // alert: PropTypes.object.isRequired,
};

export default StreamingServiceInput;
