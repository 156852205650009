import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Space, Input, Button } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import Countdown from 'react-countdown';
import NoDataComponent from '../../../../../../../../ComponentsV2/CommonComponents/MobileView/noDataComponent/NoDataComponent';

import './scheduledTable.scss';
import { useSelector } from 'react-redux';

const ScheduledTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickCancelNotification,
    onClickDeleteNotification,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );
    useEffect(() => {
        let trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search`}
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        let searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: (a, b) => a?.title?.length - b?.title?.length,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Scheduled to be sent on',
            dataIndex: 'job',
            sorter: (a, b) =>
                moment(a?.job?.runTime).unix() - moment(b?.job?.runTime).unix(),
            render: (scheduledTime, item) => {
                if (!scheduledTime?.runTime) return null;
                return (
                    <span>
                        {moment(scheduledTime?.runTime)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] h:mm a')}
                    </span>
                );
            },
        },
        {
            title: 'Scheduled to be sent in',
            dataIndex: 'job',
            sorter: (a, b) =>
                moment(a?.job?.runTime).unix() - moment(b?.job?.runTime).unix(),
            render: (scheduledTime, item) => {
                if (!scheduledTime?.runTime) return null;
                return (
                    <Countdown
                        date={scheduledTime?.runTime}
                        renderer={({ days, hours, minutes, seconds }) => {
                            if (!days && !hours && !minutes && !seconds) {
                                return <span>Notification sent</span>;
                            } else {
                                return (
                                    <span>{`${days ? days + ' Days' : ''} ${
                                        hours ? hours + ' Hrs' : ''
                                    } ${minutes} Mins ${seconds} Secs`}</span>
                                );
                            }
                        }}
                    />
                );
            },
        },
        {
            title: 'Target Livepages',
            dataIndex: 'targetId',
            sorter: (a, b) => a?.targetId?.length - b?.targetId?.length,
            render: (targetId, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : targetId?.length}
                    </span>
                );
            },
        },
        {
            render: (item) => {
                return (
                    <div className="d-flex">
                        <button
                            className="myxp btn btn-sm btn-link text-danger"
                            style={{ minWidth: 125 }}
                            onClick={() =>
                                onClickCancelNotification(
                                    item?.id || item?.notificationId
                                )
                            }
                        >
                            Cancel schedule
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link text-danger"
                            onClick={() =>
                                onClickDeleteNotification(
                                    item.id || item?.notificationId
                                )
                            }
                        >
                            Delete
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() =>
                                history.push({
                                    pathname: `/company/${companyId}/project/${projectId}/notification/edit-announcement/${
                                        item.id || item?.notificationId
                                    }`,
                                    state: { previewMode: true },
                                })
                            }
                        >
                            View
                        </button>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: <GlobalLoading />,
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            {!loading && data?.notifications?.length ? (
                <Table
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.notifications}
                    loading={globalLoadingComponent}
                    pagination={
                        pagination
                            ? {
                                  itemRender: renderPagination,
                                  pageSize: 10,
                                  total: data?.pagination?.total,
                                  current: data?.pagination?.currentPage,
                              }
                            : false
                    }
                />
            ) : (
                <NoDataComponent
                    loading={loading}
                    buttonText={'Create announcement'}
                    description={'No announcement have been Created'}
                    onClickHandler={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/notification/create-announcement`
                        )
                    }
                />
            )}
            <style>{`
            .sponsors-company-table .ant-table{
                overflow: auto;
                }
                `}</style>
        </>
    );
};

ScheduledTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    onClickCancelNotification: PropTypes.func,
    onClickDeleteNotification: PropTypes.func,
    loading: PropTypes.bool,
};

ScheduledTable.defaultProps = {
    data: null,
    loading: false,
};

export default ScheduledTable;
