import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const debouncedUpdate = useRef(
        debounce((width) => {
            setIsMobile(width < 768);
        }, 100)
    ).current; // Adjust debounce time as needed

    useEffect(() => {
        const handleResize = () => {
            debouncedUpdate(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
