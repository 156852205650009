import * as React from 'react';

const CloseIcon = (props) => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m18.505 15.997 7.637-7.62a1.783 1.783 0 0 0-2.522-2.521L16 13.493 8.382 5.856A1.783 1.783 0 1 0 5.86 8.378l7.637 7.619-7.637 7.62a1.776 1.776 0 0 0 0 2.521 1.776 1.776 0 0 0 2.522 0l7.62-7.637 7.619 7.637a1.776 1.776 0 0 0 2.522 0 1.777 1.777 0 0 0 0-2.522l-7.637-7.62Z"
            fill={props?.color ? props.color : '#000'}
        />
    </svg>
);

export default CloseIcon;
