import React from 'react';
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';

function Empty({
	heading,
	subHead1,
	subHead2,
	contentMaxWidth = '300px',
	showButton = true,
	buttonStyle,
	buttonText,
	onButtonClick
}) {
	return (
		<div className={styles.emptyContainer}>
			<div className={styles.blankDot} />

			<div style={{ maxWidth: contentMaxWidth }} className={styles.contentContainer}>
				<h2 className={styles.heading}>{heading}</h2>

				{subHead1 && <p className={styles.subHead}>{subHead1}</p>}
				{subHead2 && (
					<p style={{ marginTop: '15px' }} className={styles.subHead}>
						{subHead2}
					</p>
				)}
			</div>
			{showButton && (
				<div
					onClick={(e) => {
						if (typeof onButtonClick === 'function') onButtonClick(e);
					}}
					className={styles.button}
					style={buttonStyle ? buttonStyle : null}
				>
					{buttonText}
				</div>
			)}
		</div>
	);
}

Empty.propTypes = {
	heading: PropTypes.string.isRequired,
	subHead1: PropTypes.string,
	subHead2: PropTypes.string,
	contentMaxWidth: PropTypes.string,
	showButton: PropTypes.bool,
	buttonStyle: PropTypes.object,
	buttonText: PropTypes.string,
	onButtonClick: PropTypes.func
};
export default Empty;
