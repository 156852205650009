import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as ls from 'local-storage';

import { generateOtp } from 'apis/rest/Authentication';
import loginValidationCriteria from 'components/app/utils/validators/loginValidator';
import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import Alert from 'components/app/common/alert';
import NotificationPopup from 'ComponentsV2/CommonComponents/NotificationPopup';
import PhoneInput from 'react-phone-input-2';
import LoginWithMicrosoft from './loginWithMicrosoft/loginWithMicrosoft';

import './login.scss';

import styles from './index.module.scss';
import 'react-phone-input-2/lib/style.css';

function Login(props) {
    const logoSource = '/assets/gevme-new-logo.png';
    const callbackUrl = props.location.state
        ? props.location.state.callbackUrl
        : null;

    const { history } = props;
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loginByEmail, setLoginByEmail] = useState(true);
    const [sendBySms, setSendBySms] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const isSponsor = !!ls.get('sponsorAuth');
    const hasSponsorAuth = !!ls.get('sponsorTempAuth');

    const { alert, showAlert } = useAlert();

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input: {
            email,
            phoneNumber,
            type: loginByEmail ? 'email' : 'phoneNumber',
        },
        validationCriteria: loginValidationCriteria,
    });

    useEffect(() => {
        if (hasSponsorAuth) {
            history.replace('/sponsor/companyProfiles');
            return;
        }
        if (isSponsor) {
            history.replace('/sponsor/login');
            return;
        }

        const auth = ls.get('auth');

        if (callbackUrl) {
            ls('customProjectUrl', callbackUrl);

            const matchEmail = callbackUrl.match(/email=([^&]*)/);
            if (matchEmail && matchEmail.length && matchEmail[1]) {
                try {
                    let email = matchEmail[1];

                    if (email) {
                        email = decodeURIComponent(email);
                        setEmail(email);
                        login(
                            {
                                email,
                                sms: sendBySms,
                            },
                            false
                        );
                    } else if (auth) {
                        history.push('/');
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            // on login we get the projectId
            // lets save it to localstorage coz if we still search this in history we get undefined
            // this is only applicable on switch account
            ls('tempProjId', callbackUrl?.split('/')[2]);
        } else if (auth) {
            history.push('/');
        }
    }, [history]);

    const login = async (data, validate = true) => {
        let _hasErrors = false;

        if (validate) {
            const formErrors = validateInput();

            _hasErrors = hasErrors(formErrors);
        }

        if (!_hasErrors) {
            setIsBusy(true);

            const response = await generateOtp(data.formData, data.type);
            if (loginByEmail)
                determineFlowUponResponse(response, data.formData?.email);
            else {
                determineFlowUponResponse(response, data.formData?.phoneNo);
            }
        }
    };
    /**
     * submits email to generate magic link
     * @param {object} e event object
     */
    const submitData = async (e) => {
        e.preventDefault();
        ls.get('gevme-callback-url') && ls.remove('gevme-callback-url');
        if (loginByEmail) {
            await login({
                formData: { email },
                type: 'email',
            });
        } else {
            await login({
                formData: { phoneNo: phoneNumber?.value },
                type: 'phone',
            });
        }
    };

    /**
     * determines the flow after receiving "submit" response
     * @param {object} response response from `generateOtp()`
     */
    const determineFlowUponResponse = async (response, value) => {
        const responseJson = await response.json();

        setIsBusy(false);

        if (responseJson.status) {
            history.push({
                pathname: '/otp',
                state: value,
                callbackUrl,
                identifier: responseJson?.identifier,
            });
        } else if (response.status === 403) {
            history.push('/no-access');
        } else {
            showAlert(responseJson.message, 'error');
        }
    };

    /**
     * handles changes to input
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };
    const handleInputChangePhone = (value, countryCode) => {
        setPhoneNumber({ value, countryCode });
    };
    return (
        <>
            <Alert alert={alert} />
            <div className={`${styles.login} login`}>
                <div className={styles.wrapper}>
                    <NotificationPopup />
                    <div className={styles.innerWrapper}>
                        <div className="tw-py-14 tw-px-5">
                            <form
                                className="tw-p-0 tw-h-auto"
                                onSubmit={submitData}
                            >
                                <img
                                    src={logoSource}
                                    alt="logo"
                                    className="login__logo tw-mb-2"
                                />

                                <span className="tw-text-var-gray-900 tw-text-center tw-font-ModernEra tw-text-[44px] tw-font-bold tw-leading-normal tw-tracking-[-1.76px]">
                                    Omnichannel
                                </span>

                                <p className="tw-mb-12">
                                    Log in to your Gevme Omnichannel account
                                </p>

                                <div className="tw-flex tw-justify-between tw-w-full mb-2">
                                    <span className="tw-text-gray-700 tw-font-open-sans tw-text-sm tw-font-semibold tw-leading-normal">
                                        {loginByEmail
                                            ? 'Email'
                                            : 'Phone Number'}
                                    </span>
                                    <span
                                        className="tw-text-blue-500 tw-font-open-sans tw-text-sm tw-font-semibold tw-leading-normal tw-text-right tw-cursor-pointer "
                                        onClick={() =>
                                            setLoginByEmail(!loginByEmail)
                                        }
                                    >
                                        {loginByEmail
                                            ? 'Log in with Phone Number'
                                            : 'Log in with Email'}
                                    </span>
                                </div>

                                {loginByEmail ? (
                                    <div className="form-group tw-mb-8">
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            className="form-control tw-py-3.5 tw-px-4 tw-min-h-[48px]"
                                            onChange={handleInputChange}
                                            placeholder="Enter your email"
                                            autoComplete="off"
                                            required={loginByEmail}
                                        />
                                        {renderFieldError('email')}
                                    </div>
                                ) : (
                                    <>
                                        <div className="form-group tw-mb-8">
                                            <PhoneInput
                                                name="phoneNo"
                                                country="sg"
                                                enableSearch
                                                value={phoneNumber?.value}
                                                inputStyle={{
                                                    width: '100%',
                                                    minHeight: '48px',
                                                }}
                                                className="phoneInput-Login"
                                                onChange={(
                                                    value,
                                                    data,
                                                    _,
                                                    formattedValue
                                                ) => {
                                                    handleInputChangePhone(
                                                        formattedValue.replaceAll(
                                                            '-',
                                                            ''
                                                        ),
                                                        data.countryCode
                                                    );
                                                }}
                                            />

                                            {renderFieldError('phoneNumber')}
                                        </div>
                                        <style>{`
                                    .phoneInput-Login .country-list .country{
                                        display: flex;
                                        align-items: center;
                                    }
                                    .phoneInput-Login .country-list .search{
                                        display: flex;
                                        align-items: center;
                                    }
                                    `}</style>
                                    </>
                                )}

                                {/* <div className="checkbox-set">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={sendBySms}
                                            onChange={() => {
                                                setSendBySms(!sendBySms);
                                            }}
                                            id="sendBySms"
                                        />{' '}
                                        <span>Send OTP by SMS</span>
                                    </label>
                                </div> */}

                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={!!isBusy}
                                >
                                    {isBusy ? (
                                        <span>
                                            <img
                                                src="/assets/icon_spinner.gif"
                                                alt=""
                                            />
                                            Please wait...
                                        </span>
                                    ) : (
                                        'Next'
                                    )}
                                </button>
                            </form>

                            <div className="tw-mt-4">
                                <p className="foot-note tw-p-0">
                                    By logging in you agree to the{' '}
                                    <a
                                        href="https://www.gevme.com/en/terms-of-use/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://www.gevme.com/en/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                </p>
                            </div>
                        </div>

                        {callbackUrl && (
                            <LoginWithMicrosoft callbackUrl={callbackUrl} />
                        )}

                        <div className="tw-flex tw-w-full tw-py-4 tw-px-2.5 tw-justify-center tw-items-center tw-gap-2 tw-self-stretch tw-bg-[#E9ECF0] tw-rounded-[20px] tw-rounded-t-none">
                            <span className="tw-text-gray-700 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal">
                                Do not have an account?
                            </span>
                            <span
                                className="tw-text-blue-500 tw-font-open-sans tw-text-sm tw-font-normal tw-leading-normal tw-text-right tw-cursor-pointer "
                                onClick={() => {
                                    history.push('/signup');
                                }}
                            >
                                Sign up for free
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Login);
