import { produce } from "immer";
import * as ls from "local-storage";

import { CHANGE_PROJECT } from "../../actions";

export const initialState = {
  project: ls.get("project")
};

function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PROJECT:
      return produce(state, draft => {
        draft.project = action.payload;
      });
    default:
      return state;
  }
}

export default dashboardReducer;
