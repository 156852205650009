import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { getSpeakerImportLogById } from 'apis/rest/speakers/getSpeakerImportLogById';

export default function SpeakerImportErrors() {
    const { projectId, errorId } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorsData, setErrorsData] = useState([]);

    const fetchErrorLogs = async () => {
        try {
            setLoading(true);
            const logsData = await getSpeakerImportLogById(projectId, errorId);
            if (logsData?.status) {
                setErrorsData(logsData?.speakerLog?.errorDetails || []);
            }
        } catch (error) {
            console.error(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: (
                <span className="tw-text-lg tw-font-semibold">
                    Import errors
                </span>
            ),
            dataIndex: 'msg',
            render: (error) => {
                return (
                    <>
                        <span className="tw-text-md tw-font-semibold tw-text-[#A60606]">
                            {error}
                        </span>
                        <style jsx="true">{`
                            tbody tr td:first-child span {
                                color: #a60606 !important;
                            }
                        `}</style>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        fetchErrorLogs();
    }, []);

    return (
        <Table
            loading={loading}
            className="tw-mt-8"
            columns={columns}
            dataSource={errorsData}
            pagination={{
                position: ['none'],
            }}
            bordered
        />
    );
}
