import React from 'react';

export default function DownChevronIcon({ stroke = '#687177' }) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99479 11.8725C7.86353 11.8733 7.73341 11.848 7.6119 11.7983C7.49038 11.7485 7.37986 11.6752 7.28666 11.5825L3.29722 7.5825C3.20423 7.48926 3.13046 7.37857 3.08014 7.25675C3.02981 7.13493 3.00391 7.00436 3.00391 6.8725C3.00391 6.74064 3.02981 6.61007 3.08014 6.48825C3.13046 6.36643 3.20423 6.25574 3.29722 6.1625C3.39021 6.06926 3.50061 5.9953 3.62211 5.94484C3.74361 5.89438 3.87384 5.86841 4.00535 5.86841C4.13686 5.86841 4.26708 5.89438 4.38858 5.94484C4.51008 5.9953 4.62048 6.06926 4.71347 6.1625L7.99479 9.4725L11.2861 6.2925C11.3778 6.19021 11.4896 6.108 11.6145 6.05101C11.7394 5.99403 11.8747 5.96349 12.0119 5.96131C12.1491 5.95913 12.2852 5.98536 12.4118 6.03835C12.5385 6.09134 12.6528 6.16996 12.7477 6.26928C12.8427 6.36861 12.9162 6.4865 12.9636 6.6156C13.011 6.74469 13.0313 6.88221 13.0233 7.01954C13.0153 7.15688 12.9791 7.29108 12.917 7.41376C12.8549 7.53643 12.7683 7.64494 12.6624 7.7325L8.673 11.5925C8.4906 11.7688 8.24812 11.8689 7.99479 11.8725Z"
                fill={stroke || '#687177'}
            />
        </svg>
    );
}
