import React from 'react';

export default function TwoFASetupIcon() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_41_972)">
                <path
                    d="M26.9739 2.16895L44.7954 6.12929C45.2771 6.23635 45.7078 6.50443 46.0165 6.88927C46.3253 7.27411 46.4936 7.75272 46.4937 8.2461V29.9065C46.4935 32.0488 45.9645 34.1579 44.9535 36.0466C43.9426 37.9353 42.481 39.5453 40.6984 40.7335L26.9739 49.884L13.2493 40.7335C11.4671 39.5455 10.0057 37.9359 8.99475 36.0476C7.98382 34.1593 7.45461 32.0506 7.4541 29.9087V8.2461C7.45419 7.75272 7.62249 7.27411 7.93123 6.88927C8.23997 6.50443 8.6707 6.23635 9.15232 6.12929L26.9739 2.16895ZM26.9739 6.61295L11.7918 9.98553V29.9065C11.7919 31.3347 12.1444 32.7407 12.8183 33.9998C13.4921 35.259 14.4664 36.3323 15.6546 37.1245L26.9739 44.6722L38.2932 37.1245C39.4811 36.3325 40.4552 35.2595 41.129 34.0008C41.8028 32.742 42.1555 31.3365 42.1559 29.9087V9.98553L26.9739 6.61512V6.61295ZM26.9739 15.1821C27.9289 15.1817 28.8574 15.4965 29.6153 16.0776C30.3732 16.6587 30.9181 17.4737 31.1655 18.3961C31.4129 19.3185 31.349 20.2968 30.9836 21.1792C30.6183 22.0616 29.972 22.7988 29.1449 23.2763L29.1427 32.533H24.805V23.2763C23.9781 22.7989 23.3319 22.0619 22.9665 21.1797C22.6011 20.2976 22.537 19.3195 22.7841 18.3972C23.0313 17.4748 23.5758 16.6599 24.3334 16.0786C25.0909 15.4973 26.019 15.1822 26.9739 15.1821Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_41_972">
                    <rect
                        width="52.0527"
                        height="52.0527"
                        fill="white"
                        transform="translate(0.947266)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
