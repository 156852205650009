import * as React from 'react';

function Recommendation(props) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M26.253 26.253c2.148-2.148-.701-8.48-6.364-14.142C14.227 6.448 7.895 3.599 5.747 5.747c-2.148 2.148.701 8.48 6.364 14.142 5.663 5.663 11.994 8.512 14.142 6.364z"
                stroke="#687177"
                strokeWidth={2.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.89 19.89c5.662-5.664 8.511-11.995 6.363-14.143-2.148-2.148-8.48.701-14.142 6.364-5.663 5.662-8.512 11.994-6.364 14.142 2.148 2.148 8.48-.701 14.142-6.364z"
                stroke="#687177"
                strokeWidth={2.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16 18a2 2 0 100-4 2 2 0 000 4z" fill="#687177" />
        </svg>
    );
}

export default Recommendation;
