import React from 'react';

// Packages
import { Switch } from 'antd';

// Styles
import styles from './index.module.css';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

/**
 * Switch Component
 *

 * @SwitchComponent
 * @category Venue Builder
 * @subcategory Common
 * @param {string} icon Icon to render next to the Label
 * @param {string} label Text Label
 * @param {boolean} switchStatus Boolean for checked or unchecked; true/false
 * @param {function} onChange Callback function for observed changes
 */

const SwitchComponent = ({
    icon,
    label,
    switchStatus,
    onChange,
    children,
    padding,
    background,
    tooltipText,
    hint,
    disabled = false,
    marginReset = false,
    border = false,
}) => {
    return (
        <>
            <div
                className={`switch-wrapper ${styles.SwitchComponent} ${
                    !switchStatus ? `disabled ${styles.Disabled}` : ''
                } ${border ? 'border' : ''}`}
                style={{
                    padding,
                    background,
                    margin: `${marginReset ? '0 -1rem' : ''}`,
                }}
            >
                <div className={styles.Content}>
                    <div className={styles.SwitchContent}>
                        <div className={styles.LeftContent}>
                            {icon && (
                                <span className={styles.Icon}>{icon}</span>
                            )}
                            <span
                                className={`${styles.Label} ${
                                    padding ? styles.NoPadding : ''
                                }`}
                            >
                                {label}
                                {tooltipText && (
                                    <Tooltip
                                        overlayStyle={{
                                            maxWidth: '260px',
                                        }}
                                        placement="right"
                                        title={tooltipText}
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                        <div className={styles.RightContent}>
                            <Switch
                                size={'small'}
                                onChange={onChange}
                                disabled={disabled}
                                checked={switchStatus}
                            />
                        </div>
                    </div>
                    {hint && <span className={styles.Hint}>{hint}</span>}
                    {children}
                </div>
            </div>
            <style jsx="true">{`
                .ant-switch-checked {
                    background: #0b1c2c;
                }
            `}</style>
        </>
    );
};

export default SwitchComponent;
