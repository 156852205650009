import * as ls from 'local-storage';
import getAuthBearer from '../../utils/getAuthBearer';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * gets the list of leads users
 */
export async function getLeadsUsers({ projectId }) {
    let authBearer = getAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads?projectId=${projectId}&limit=20`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    } else return null;
}
export async function updateActivation({ projectId, id, status }) {
    let authBearer = getAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/${id}/${status}?projectId=${projectId}`,
            {
                method: 'PUT',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    } else return null;
}
export async function getLeadsCaptured({ projectId, page, limit }) {
    let authBearer = getAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/captured?projectId=${projectId}&page=${page}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    } else return null;
}
export async function exportLeadsCaptured({ projectId }) {
    let authBearer = getAuthBearer();
    if (authBearer) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/leads/captured/export?projectId=${projectId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        return await response.blob();
    } else return null;
}
