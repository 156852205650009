import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, List, Row, Col } from 'antd';
import { getCompanyUsage } from 'apis/rest/Companies';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import UsageSummary from './usageSummary';
import UsageBreakdown from './usageBreakdown';
import Loader from 'components/app/components/venue/Loader';
import moment from 'moment';

import './usage.scss';

const Usage = ({ onUpgradeClick, companyActivePlan }) => {
    const params = useParams();
    const { companyId } = params;
    const isCompanyOnFreePlan =
        companyActivePlan?.planName === 'Starter (Free)';
    const isCompanyOnProPlan = companyActivePlan?.planName === 'Pro';
    const [usage, setUsage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCompanyUsage = async () => {
        try {
            setIsLoading(true);
            const response = await getCompanyUsage(companyId);
            if (response.status) {
                setUsage(response);
            } else openNotification('error', { message: response.message });
        } catch (error) {
            openNotification('error', { message: error?.message });
        } finally {
            setIsLoading(false);
        }
    };

    const getPlanName = (planName) => {
        switch (planName) {
            case 'Starter (Free)':
            case 'Starter':
            case 'Enterprise':
                return planName;
            case 'Pro': {
                if (
                    usage?.end &&
                    moment(usage?.end).diff(moment(usage?.start), 'month') > 1
                ) {
                    return 'Pro (Annual)';
                } else return 'Pro (Monthly)';
            }
            default:
                return planName;
        }
    };

    useEffect(() => {
        fetchCompanyUsage();
    }, [companyId]);

    const YourPlan = ({ companyActivePlan }) => (
        <div className="card-side-info">
            <div className="your-plan-text">Your plan</div>
            <div className="plan-name">
                <span className="upgrade" onClick={onUpgradeClick}>
                    {isCompanyOnFreePlan ? '(Upgrade)' : '(Change plan)'}
                </span>
                {getPlanName(companyActivePlan?.planName)}
            </div>
        </div>
    );

    const PlanDescriptionBox = ({ planName }) => {
        if (isCompanyOnFreePlan) {
            return (
                <div className="descriptionBox">
                    <span>
                        Under the <b className="px-1">{planName}</b> plan you
                        are entitled to
                        <b className="px-1">10 free Attendees</b> per project
                        and <b className="px-1">1 Organizer User</b> under this
                        organization. Below is a breakdown of the number of
                        Attendees that you currently have under each project. To
                        be able to have more than 10 Attendees per project,
                        please upgrade.
                    </span>
                    <div className="upgradeBtn" onClick={onUpgradeClick}>
                        Upgrade
                    </div>
                </div>
            );
        }
        return (
            <p className="descriptionBox w-100">
                This organization is on the <b className="px-1">{planName}</b>{' '}
                plan and the next billing date is on{' '}
                <b className="pl-1">
                    {moment(usage?.end).format('D MMM YYYY')}
                </b>
                .
            </p>
        );
    };

    return (
        <Card
            title="Usage"
            className="usage-container"
            extra={<YourPlan companyActivePlan={companyActivePlan} />}
        >
            {isLoading ? (
                <div className="loader-wrapper">
                    <Loader />
                </div>
            ) : (
                <>
                    <PlanDescriptionBox
                        planName={companyActivePlan?.planName}
                    />
                    {!isCompanyOnFreePlan && (
                        <UsageSummary
                            usage={usage}
                            companyActivePlan={companyActivePlan}
                            isCompanyOnProPlan={isCompanyOnProPlan}
                        />
                    )}
                    <UsageBreakdown
                        isCompanyOnFreePlan={isCompanyOnFreePlan}
                        usage={usage}
                    />
                </>
            )}

            <style>{`
        .ant-card-head-title {
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 26px;
          line-height: 88.8%;
          letter-spacing: -0.02em;
          color: #000000;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 1;
          margin: 0px 10px;
        }
        .ant-progress-bg {
          height: 12px !important;
          border-radius: 0px !important;
        }
        .ant-progress-inner {
          border-radius: 3px !important;
        }
      `}</style>
        </Card>
    );
};

export default Usage;
