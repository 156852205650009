import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
/**
 * Get the details of blocks and widgets present on a livepage
 * @param  {string} searchQuery
 * @category Venue Builder
 */
const getPeopleById = async (projectId, peopleId) => {
    try {
        setAuthBearer();
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/projects/${projectId}/getpeople`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify({
                    peopleId: peopleId,
                }),
            }
        );

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export default getPeopleById;
