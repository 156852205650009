import { produce } from 'immer';

export const initialState = {
    labels: [],
    labelFilters: [],
    pages: [],
    agendaSessions: [],
    agendaSessionDates: [],
    agendaSpeakers: [],
    sessionTags: [],
};

function pagesReducers(state = initialState, action) {
    switch (action.type) {
        case 'SET_PAGE_LABELS':
            return {
                ...state,
                labels: action.payload,
            };
        case 'ADD_PAGE_LABEL':
            return produce(state, (draftState) => {
                draftState.labels = [...draftState.labels, action.payload];
            });
        case 'UPDATE_PAGE_LABEL':
            return produce(state, (draftState) => {
                const updateLabel = draftState.labels.findIndex(
                    (label) => label.id === action.payload.id
                );
                draftState.labels[updateLabel] = action.payload;
                draftState.labelFilters = draftState.labelFilters.map(
                    (label) => {
                        if (label.id === action.payload.id) {
                            return action.payload;
                        }
                        return label;
                    }
                );
            });
        case 'DELETE_PAGE_LABEL':
            return produce(state, (draftState) => {
                const deleteLabel = draftState.labels.findIndex(
                    (label) => label.id === action.payload
                );
                draftState.labels.splice(deleteLabel, 1);
                draftState.labelFilters = draftState.labelFilters.filter(
                    (label) => label.id !== action.payload
                );
            });
        case 'SET_PAGE_FILTER':
            return produce(state, (draftState) => {
                const filter = draftState.labelFilters.findIndex(
                    (label) => label.id === action.payload.id
                );
                if (filter === -1) {
                    draftState.labelFilters = [
                        ...draftState.labelFilters,
                        action.payload,
                    ];
                } else {
                    draftState.labelFilters.splice(filter, 1);
                }
            });
        case 'RESET_PAGE_FILTER':
            return {
                ...state,
                labelFilters: [],
            };
        case 'UPDATE_PAGE_COLLECTION':
            const joint = state.pages
                .concat(action.payload)
                .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
            return {
                ...state,
                pages: joint,
            };
        case 'GET_AGENDA_SESSIONS':
            return {
                ...state,
                agendaSessions: action.payload,
            };
        case 'GET_AGENDA_SESSIONS_DATES':
            return {
                ...state,
                agendaSessionDates: action.payload,
            };
        case 'ADD_SESSION_DATE':
            return {
                ...state,
                agendaSessionDates: [
                    ...state.agendaSessionDates,
                    action.payload,
                ],
            };
        case 'DELETE_SESSION_DATE':
            return produce(state, (draftState) => {
                const dateIndex = draftState.agendaSessionDates.findIndex(
                    (date) => date === action.payload
                );
                draftState.agendaSessionDates.splice(dateIndex, 1);
            });
        case 'EDIT_AGENDA_SESSION':
            return produce(state, (draftState) => {
                const sessionIndex =
                    draftState.agendaSessions.sessions.findIndex(
                        (session) => session.id === action.payload.id
                    );
                draftState.agendaSessions.sessions[sessionIndex] = {
                    ...draftState.agendaSessions.sessions[sessionIndex],
                    ...action.payload,
                };
            });
        case 'DELETE_AGENDA_SESSION':
            return produce(state, (draftState) => {
                const sessionIndex =
                    draftState.agendaSessions.sessions.findIndex(
                        (session) => session.id === action.payload
                    );
                draftState.agendaSessions.sessions.splice(sessionIndex, 1);
            });
        case 'UPDATE_SPEAKER':
            return produce(state, (draftState) => {
                // update all speaker data in sessions
                draftState.agendaSessions.sessions.map((session, index) => {
                    session.speakers.map((speaker, spIdx) => {
                        if (speaker.id === action.payload.speakerId) {
                            draftState.agendaSessions.sessions[index].speakers[
                                spIdx
                            ] = {
                                ...draftState.agendaSessions.sessions[index]
                                    .speakers[spIdx],
                                firstname: action.payload.firstname,
                                lastname: action.payload.lastname,
                                email: action.payload.email,
                                company: action.payload.company,
                                jobtitle: action.payload.jobtitle,
                                profilePhoto: action.payload.profilePhoto,
                            };
                        }
                    });
                    return draftState;
                });
            });
        case 'GET_AGENDA_SPEAKERS':
            return {
                ...state,
                agendaSpeakers: action.payload,
            };
        case 'GET_AGENDA_SESSION_TAGS':
            return {
                ...state,
                sessionTags: action.payload,
            };
        case 'CLEAR_ALL_AGENDA_SESSIONS_DATA':
            return {
                ...state,
                agendaSessions: [],
                agendaSessionDates: [],
                agendaSpeakers: [],
                sessionTags: [],
            };

        default:
            return state;
    }
}

export default pagesReducers;
