import React from 'react';
import * as ls from 'local-storage';

import MainMenu from './mainMenu';
import SponsorMenu from './sponsorMenu';

const Menu = (props) => {
    const isSponsor = !!ls.get('sponsorIdentifier');

    return isSponsor ? (
        <SponsorMenu {...props} />
    ) : (
        <MainMenu {...props} />
    );
};

export default Menu;
