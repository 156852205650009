import React from 'react';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';
import LivestreamChannel from './livestreamChannel';

function Channels() {
    return (
        <Layout disableSubMenuWidth className="tw-p-0">
            <div className="container tw-px-0 md:tw-p-4">
                <LivestreamChannel />
            </div>
            <SidebarContainer>
                <MainMenu priActive="channels" secActive="livestream" all />
            </SidebarContainer>
        </Layout>
    );
}

Channels.propTypes = {};

export default Channels;
