export const initialState = {
    menus: [], //{id,name,menuItems}
    selectedMenu: null, //string
    selectedMenuItem: null, //{name,link,openInNewWindow}
};

function menuReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_MENUS':
            return {
                ...state,
                menus: [...action.payload, ...state.menus],
            };
        case 'SELECT_MENU':
            return {
                ...state,
                selectedMenu: action.payload,
            };
        case 'SELECT_MENU_ITEM':
            return {
                ...state,
                selectedMenuItem: action.payload,
            };
        case 'UPDATE_MENUS':
            return {
                ...state,
                menus: action.payload,
            };
        case 'CLEAR_ALL':
            return { ...initialState };
        default:
            return state;
    }
}

export default menuReducer;
