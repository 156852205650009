export default function toggleFullscreen(elementId, setShowFullScreen) {
    let element = document.getElementById(elementId);
    if (!document.fullscreenElement) {
        element
            .requestFullscreen()
            .then(() => setShowFullScreen(true))
            .catch((err) =>
                alert(
                    `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                )
            );
    } else {
        setShowFullScreen(false);
        document.exitFullscreen();
    }
}
