import React from 'react';

export default function OfficeIcon({ stroke = 'black' }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.75 2.5V16.25H11.25V2.5H3.75ZM3.125 1.25H11.875C12.0408 1.25 12.1997 1.31585 12.3169 1.43306C12.4342 1.55027 12.5 1.70924 12.5 1.875V16.875C12.5 17.0408 12.4342 17.1997 12.3169 17.3169C12.1997 17.4342 12.0408 17.5 11.875 17.5H3.125C2.95924 17.5 2.80027 17.4342 2.68306 17.3169C2.56585 17.1997 2.5 17.0408 2.5 16.875V1.875C2.5 1.70924 2.56585 1.55027 2.68306 1.43306C2.80027 1.31585 2.95924 1.25 3.125 1.25Z"
                fill={stroke || 'black'}
            />
            <path
                d="M5 5H10V6.25H5V5ZM5 8.75H10V10H5V8.75ZM5 12.5H10V13.75H5V12.5ZM12.5 10H15V11.25H12.5V10ZM12.5 12.5H15V13.75H12.5V12.5ZM1.25 16.25H18.75V17.5H1.25V16.25Z"
                fill={stroke || 'black'}
            />
            <path
                d="M12.5 7.5V16.25H16.25V7.5H12.5ZM11.875 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H11.875C11.7092 17.5 11.5503 17.4342 11.4331 17.3169C11.3158 17.1997 11.25 17.0408 11.25 16.875V6.875C11.25 6.70924 11.3158 6.55027 11.4331 6.43306C11.5503 6.31585 11.7092 6.25 11.875 6.25Z"
                fill={stroke || 'black'}
            />
        </svg>
    );
}
