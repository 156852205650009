import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { store as mainStore } from 'redux/store';
const middleware = [thunk];

/** Define Global States to be used across all layouts here */
export const initialState = {
    user: mainStore?.getState().user,
    companyDetails: mainStore?.getState().projects?.company,
    venue: {},
    header: {
        selection: {
            livepageId: null,
            livePageSlug: null,
            livePagesList: [],
            templateId: null,
            projectId: null,
            isPublished: false,
            selLivePageTitle: '',
            totalPages: 0,
            companyId: null,
        },
        display: {
            mobile: false,
            tablet: false,
            desktop: true,
        },
    },
    sidebar: {
        menu: 'default',
        menuList: [],
        widgets: [],
        blocks: [],
    },
    preview: {
        token: null,
        loading: true,
    },
    error: {},
    version: null,
    versionCount: 0,
    preventAdd: false,
    enabledWidgets: '*',
    enabledBlocks: '*',
    preventBlockPositionChange: false,
};

export const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);
