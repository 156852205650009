import * as React from "react"

const LinkIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.312 7.91l1.456-1.456a5.5 5.5 0 117.778 7.778l-3.536 3.536a5.498 5.498 0 01-7.778 0"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.688 24.09l-1.456 1.456a5.5 5.5 0 11-7.778-7.779l3.536-3.535a5.5 5.5 0 017.778 0"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkIcon;