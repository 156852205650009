import * as React from "react"

const CTAIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 15a6.007 6.007 0 016-6h4.998c.065 0 6.547-.086 12.716-5.26A2 2 0 0129 5.272V24.73a1.98 1.98 0 01-1.154 1.812 1.989 1.989 0 01-2.133-.28C20.98 22.288 16.06 21.314 14 21.076v4.388a1.995 1.995 0 01-.89 1.664l-1.375.916a2 2 0 01-3.05-1.179l-1.48-5.92A6.008 6.008 0 012 15zm8.626 11.381L12 25.465V21H9.28l1.346 5.381zM8 19.001h4v-8H8a4 4 0 000 8z"
        fill="#7A829D"
      />
    </svg>
  )
}

export default CTAIcon
