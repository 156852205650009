import * as ls from 'local-storage';

const getAuthBearer = () => {
    let authBearer = null;
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    } finally {
        return authBearer;
    }
};
export default getAuthBearer;
