import React, { useEffect, useState } from 'react';
import { Collapse, Select, Switch, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { Controller } from 'react-hook-form';

import { getAllReactions } from 'apis/rest/reactions';

import AudioModal from '../../Layout/AppSideBar/Components/Tags/Components/Widget/AudioFeatures/AudioModal';
import { EditIcon } from './Icons';
import SwitchComponent from '../SwitchComponent';
import styles from '../SettingsCoverImage/index.module.css';

const { Panel } = Collapse;
const { Option } = Select;

export default function EmojiReactionSettings({
    config,
    values,
    setValue,
    register,
    control,
}) {
    const { projectId } = useParams();
    const [enabledReaction, setEnabledReaction] = useState(
        values?.enabledReaction ||
            config?.emojiReactionSettings?.defaultEnabledReaction
    );
    const reactionPosition =
        values?.reactionPosition ||
        config?.emojiReactionSettings?.defaultReactionPosition;
    const reactionPositionOptions = [
        { label: 'Bottom right', value: 'bottom_right' },
        { label: 'Bottom center', value: 'bottom_center' },
        { label: 'Bottom left', value: 'bottom_left' },
    ];
    const [defaultFrontEmoji, setDefaultFrontEmoji] = useState(null);
    const [showReactionsModal, setShowReactionsModal] = useState(false);

    async function setDefaultFrontEmojiHandler() {
        try {
            const resp = await getAllReactions({ projectId, type: 'all' });
            if (!resp.status) throw resp;
            const reactions = resp?.reactions || [];
            setDefaultFrontEmoji(
                reactions.find((reaction) => reaction?.isFrontEmoji) ||
                    reactions[0] ||
                    null
            );
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        setDefaultFrontEmojiHandler();
    }, []);

    if (!config?.emojiReactionSettings?.enabled) {
        return null;
    }

    return (
        <>
            <div
                className={`${styles.BorderedSwitch} bordered switch-cover ${
                    enabledReaction ? 'tw-pb-4' : ''
                }`}
            >
                <SwitchComponent
                    label="Reactions"
                    tooltipText={null}
                    switchStatus={enabledReaction}
                    onChange={(value) => {
                        setEnabledReaction(value);
                        setValue('enabledReaction', value);
                    }}
                    padding={true}
                >
                    {enabledReaction ? (
                        <div className="tw-w-full reactions">
                            <p className="tw-p-0 tw-m-0 tw-text-xssm tw-font-semibold">
                                Position
                            </p>
                            <Controller
                                as={
                                    <Select
                                        className="tw-w-full tw-mt-1 tw-text-xs"
                                        style={{
                                            fontWeight: 'unset !important',
                                        }}
                                        defaultValue={reactionPosition}
                                        onChange={(value) =>
                                            setValue('reactionPosition', value)
                                        }
                                    >
                                        {reactionPositionOptions.map(
                                            (position) => (
                                                <Option
                                                    key={position?.value}
                                                    value={position?.value}
                                                    className="tw-text-xs"
                                                    style={{
                                                        fontWeight:
                                                            'unset !important',
                                                    }}
                                                >
                                                    {position?.label}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                }
                                name="reactionPosition"
                                control={control}
                                defaultValue={reactionPosition}
                                ref={register('reactionPosition', {
                                    required: true,
                                })}
                            />
                            <p className="tw-p-0 tw-m-0 tw-mt-3 tw-text-xssm tw-font-semibold">
                                Front emoji
                            </p>
                            <div className="tw-w-full tw-py-1 tw-px-2 tw-mt-1 tw-flex tw-flex-row tw-justify-between tw-items-center tw-rounded tw-border tw-border-solid tw-border-slate-300">
                                <span>
                                    <img
                                        src={defaultFrontEmoji?.icon}
                                        alt={defaultFrontEmoji?.name}
                                        className="tw-w-8 tw-h-8"
                                    />
                                    <span className="tw-p-0 tw-m-0 tw-ml-2 tw-text-xssm">
                                        {defaultFrontEmoji?.name}
                                    </span>
                                </span>
                                <Tooltip
                                    title="Change Front Emoji"
                                    placement="top"
                                >
                                    <span
                                        className="tw-p-0 tw-m-0 tw-cursor-pointer"
                                        onClick={() =>
                                            setShowReactionsModal(true)
                                        }
                                    >
                                        <EditIcon />
                                    </span>
                                </Tooltip>
                                <AudioModal
                                    modalType="reactions"
                                    isVisible={showReactionsModal}
                                    setModal={setShowReactionsModal}
                                    mediaAsset={defaultFrontEmoji}
                                    setSelectedMediaAsset={setDefaultFrontEmoji}
                                    setError={() => {}}
                                />
                            </div>
                        </div>
                    ) : null}
                </SwitchComponent>
            </div>
            <style jsx={true}>
                {' '}
                {`
                    .switch-cover.bordered {
                        border: 1px solid #dadeeb;
                    }
                    .switch-cover span.required {
                        color: #dc3545 !important;
                    }
                    .switch-cover.bordered .reactions {
                        border-top: 1px solid #e3e8f7;
                        padding: 10px 0px;
                    }
                    .switch-cover .switch-wrapper {
                        background: #ffffff !important;
                    }
                    .switch-cover .switch-wrapper span {
                        color: #000;
                    }
                    .switch-cover .disabled .ant-switch {
                        background: #d4d9e5;
                    }
                `}
            </style>
        </>
    );
}
