import React from 'react';

export default function ExpandIcon({ stroke = 'white' }) {
    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0H6V2H3.462L8.305 6.843L6.89 8.257L2 3.367V6H0V0ZM0 18H6V16H3.376L8.305 11.072L6.89 9.658L2 14.548V12H0V18ZM12 18H18V12H16V14.524L11.133 9.658L9.719 11.072L14.647 16H12V18ZM18 0H12V2H14.562L9.719 6.843L11.133 8.257L16 3.39V6H18V0Z"
                fill={stroke || 'white'}
            />
        </svg>
    );
}
