import * as ls from 'local-storage';
import moment from 'moment';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getMetabaseToken = async (
    projectId,
    siteId,
    startDate,
    endDate,
    timezone,
    dashboardId,
    exhibitorid
) => {
    try {
        setAuthBearer();

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/getOverviewMetaBaseToken?` +
                `${projectId ? `projectId=${projectId}` : ''}` +
                `&startDate=${moment(startDate).format('YYYY-MM-DD HH:mm')}` +
                `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm')}` +
                `&timezone=${timezone}` +
                `&dashboard=${dashboardId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
