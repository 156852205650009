import * as React from "react"

const NotificationsIcon = (props) => {
    return (

        <svg
            width={22}
            height={25}
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M21.4239 15.8491L19.2217 13.6771V8.32519C19.2823 4.21565 16.2431 0.691939 12.1011 0.0692832C9.74933 -0.234487 7.37806 0.469789 5.59316 2.00218C3.80826 3.53456 2.78263 5.74662 2.7783 8.0732V13.6771L0.576057 15.8491C0.0105243 16.413 -0.155805 17.2543 0.153811 17.9847C0.463428 18.715 1.18881 19.1926 1.99528 19.1971H6.10613V19.6051C6.22511 22.1415 8.41361 24.1055 11 23.997C13.5864 24.1055 15.7749 22.1415 15.8939 19.6051V19.1971H20.0047C20.8112 19.1926 21.5366 18.715 21.8462 17.9847C22.1558 17.2543 21.9895 16.413 21.4239 15.8491ZM13.4469 19.6051C13.306 20.8042 12.2276 21.682 11 21.597C9.77237 21.682 8.69403 20.8042 8.55307 19.6051V19.1971H13.4469V19.6051ZM3.0597 16.7971L4.50339 15.3811C4.96591 14.9302 5.22575 14.3168 5.22523 13.6771V8.0732C5.22658 6.43544 5.94914 4.87808 7.20725 3.80124C8.44885 2.69786 10.1217 2.18459 11.783 2.39726C14.7085 2.86315 16.8375 5.37095 16.7748 8.27719V13.6771C16.771 14.3151 17.0263 14.9282 17.4844 15.3811L18.9403 16.7971H3.0597Z" fill="#7A829D" />
        </svg>

    )
}

export default NotificationsIcon
