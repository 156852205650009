import { updateProject } from 'redux/actions/projects/projectSettings';
import { setVenueSettings } from 'redux/actions/venues/actionsVenues';
import { store } from 'redux/store';
import getAuthBearer from '../../../utils/getAuthBearer';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * updates livestream settings
 * @param {string} projectId
 * @param {object} data
 */
export async function updateSettings(projectId, data) {
    const authBearer = getAuthBearer();

    let formData = new FormData();

    for (const property in data) {
        formData.append(property, data[property]);
    }

    /**
     * @note including "Content-Type": "multipart/form-data" will not work,
     * must remove it for this `form-data` type api call to work
     * reference: https://stackoverflow.com/questions/3508338/what-is-the-boundary-in-multipart-form-data */
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/${projectId}`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
            body: formData,
        }
    );

    const res = await response.json();
    if (res.status) {
        store.dispatch(updateProject(data));
    }

    return res;
}

export async function provisionCustomDomain(projectId, customDomain) {
    const authBearer = getAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/${projectId}/custom-domain/provision`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ customDomain }),
        }
    );

    const res = await response.json();
    if (res?.status) {
        store.dispatch(setVenueSettings({ customDomain }));
    }

    return res;
}

export async function deprovisionCustomDomain(projectId) {
    const authBearer = getAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/${projectId}/custom-domain/deprovision`,
        {
            method: 'POST',
            headers: {
                Authorization: authBearer,
            },
        }
    );

    const res = await response.json();
    if (res.status) {
        store.dispatch(setVenueSettings({ customDomain: '' }));
    }

    return res;
}

export async function updateSharedPasswordSettings(projectId, data) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/shared-password/${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );

    return await response.json();
}

export async function updateMicrosoftSettings(projectId, data) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/${projectId}/azuread-login`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );

    return await response.json();
}

export async function uploadLivepageBackgroundImage({
    showcaseId,
    projectId,
    files,
}) {
    const authBearer = getAuthBearer();

    let formData = new FormData();
    formData.append('projectId', projectId);
    formData.append('showcaseId', showcaseId);

    for (const file of files) {
        formData.append('files', file);
    }

    try {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/upload`,
            {
                method: 'POST',
                headers: {
                    Authorization: authBearer,
                },
                body: formData,
                redirect: 'follow',
            }
        );

        return await response.json();
    } catch (e) {
        return {
            success: false,
            message: 'Failed to upload images.',
        };
    }
}

export async function updateLivepageBackgroundImage({
    livepageId,
    projectId,
    payload,
}) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/livepages/${livepageId}/background-images?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(payload),
        }
    );

    return await response.json();
}

export async function deleteLivePageImageBackground(id, projectId) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/background-image/${id}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
}

export async function updateLivepageCustomCss(
    projectId,
    showcaseId,
    customCss
) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/${showcaseId}/custom-css?projectId=${projectId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ customCss }),
        }
    );

    return await response.json();
}
