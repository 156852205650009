import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";

import PropTypes from "prop-types";
import { produce } from "immer";

import useIsMount from "components/app/utils/hooks/useIsMount";

import UPDATE_SHOWCASE from "apis/graphql/UpdateShowcase";
import SHOWCASE from "apis/graphql/Showcase";

function ContentTogglePanel(props) {
  const {
    updateShowcaseInfo,
    updateUserInfo,
    data,
    showAlert,
    match: { params },
    reloadIframe
  } = props;
  const showcaseId = params.showcaseId;

  const [input, setInput] = useState({
    overviewHidden: data.showcase.overviewHidden,
    bannerHidden: data.showcase.bannerHidden,
    companyProfileHidden: data.showcase.companyProfileHidden,
    logoHidden: data.showcase.logoHidden,
    callToActionHidden: data.showcase.callToActionHidden,
    allCompanyProfileHidden: data.showcase.allCompanyProfileHidden
  });

  const isMount = useIsMount();

  const [updateShowcase] = useMutation(UPDATE_SHOWCASE, {
    onCompleted: data => {
      showAlert("Content visibility updated!", "success");
    },
    onError: err => {
      showAlert(err.message, "error");
    },
    refetchQueries: [{ query: SHOWCASE, variables: { id: showcaseId } }]
  });

  useEffect(() => {
    if (!isMount) {
      updateShowcase({
        variables: {
          input: {
            data: input,
            where: {
              id: showcaseId
            }
          }
        }
      });
    }
  }, [input]);

  /**
   * toggles the visibility of the different profile info segments
   * @param {object} e event object
   */
  const toggleContentVisibility = e => {
    const toggleType = e.target.name;
    /**
     * need to use the reverse boolean value because
     * `isSomethingHidden` (true) shouldn't light up the toggle
     */
    const toggleValue = !e.target.checked;

    let nextState = produce(input, draftState => {
      draftState[toggleType] = toggleValue;
    });

    setInput(nextState);
    reloadIframe();
  };

  return (
    <div className="content-toggle-panel content__blocks mb-5">
      <div
        className="content__block"
        onClick={() => updateUserInfo("companyProfile")}
      >
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_profile.svg" alt="" />
            </span>
            Company Profile
          </div>

          <div className="content__block-action">
            <img src="/assets/icon_edit.png" alt="" />
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="companyProfileHidden"
                onChange={toggleContentVisibility}
                checked={!input.companyProfileHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>

      <div className="content__block" onClick={() => updateUserInfo("logo")}>
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_paint.svg" alt="" />
            </span>
            Company Logo
          </div>

          <div className="content__block-action">
            <img src="/assets/icon_edit.png" alt="" />
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="logoHidden"
                onChange={toggleContentVisibility}
                checked={!input.logoHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>

      <div
        className="content__block"
        onClick={() => updateShowcaseInfo("banner")}
      >
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_image_2.svg" alt="" />
            </span>
            Banner Image
          </div>

          <div className="content__block-action">
            <img src="/assets/icon_edit.png" alt="" />
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="bannerHidden"
                onChange={e => toggleContentVisibility(e)}
                checked={!input.bannerHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>

      <div
        className="content__block"
        onClick={() => updateShowcaseInfo("callToAction")}
      >
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_call_to_action.svg" alt="" />
            </span>
            Call to Action
          </div>

          <div className="content__block-action">
            <img src="/assets/icon_edit.png" alt="" />
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="callToActionHidden"
                onChange={toggleContentVisibility}
                checked={!input.callToActionHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>

      <div
        className="content__block mb-3"
        onClick={() => updateShowcaseInfo("overview")}
      >
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_eye.svg" alt="" />
            </span>
            Overview
          </div>

          <div className="content__block-action">
            <img src="/assets/icon_edit.png" alt="" />
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="overviewHidden"
                onChange={toggleContentVisibility}
                checked={!input.overviewHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>

      <p>You can toggle the entire company profile header visibility below.</p>

      <div className="content__block">
        <div className="content__block-wrap">
          <div className="content__block-name">
            <span className="content__block-type">
              <img src="/assets/icon_profile.svg" alt="" />
            </span>
            Company Profile Header
          </div>

          <div className="content__block-action">
            <label className="switch" onClick={e => e.stopPropagation()}>
              <input
                type="checkbox"
                name="allCompanyProfileHidden"
                onChange={toggleContentVisibility}
                checked={!input.allCompanyProfileHidden}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentTogglePanel.propTypes = {
  data: PropTypes.object.isRequired,
  updateShowcaseInfo: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  reloadIframe: PropTypes.func.isRequired
};

export default withRouter(ContentTogglePanel);
