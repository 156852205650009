import * as ls from 'local-storage';
import { isEmpty, isEqual } from 'lodash';
import { store } from 'redux/store';

export const getNumbers = (string, pixelSelection) => {
    return string ? parseFloat(string) : '';
};

export const separatePixels = (string) => {
    try {
        const getPixel = string?.match(/^(\d+(?:\.\d+)?)(.*)$/);
        return getPixel?.length > 0
            ? getPixel[2]?.length > 0
                ? getPixel[2]
                : 'px'
            : 'px';
    } catch (error) {
        return 'px';
    }
};

export const getRandomString = (radix = 36) => {
    return (
        Math.random().toString(radix).substring(2, 8) +
        Math.random().toString(radix).substring(2, 8)
    );
};

export const renameFile = (file) => {
    let fileExtension = file.name.split('.').pop(),
        blob = file.slice(0, file.size, file.type);

    return new File([blob], getRandomString() + '.' + fileExtension, {
        type: file.type,
    });
};

export const isValidWebUrl = (url) => {
    let regEx =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return regEx.test(url);
};

export const getUserRole = () => {
    try {
        if (ls.get('sponsorAuth')) return 'SPONSOR';
        else return store?.getState()?.user.role.name.toUpperCase();
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const isBlockPropertySameInAllViews = (block, property) => {
    const hasTabletBreakpoint = !!block?.tabletBreakpoints?.[property];
    const hasMobileBreakpoint = !!block?.mobileBreakpoints?.[property];
    const isDesktopAndTabletSame = isEqual(
        block?.[property],
        block?.tabletBreakpoints?.[property]
    );
    const isDesktopAndMobileSame = isEqual(
        block?.[property],
        block?.mobileBreakpoints?.[property]
    );
    // it should return true if the property is same in all views or if the property is same in desktop and tablet when there is no mobile view or if the property is same in desktop and mobile when there is no tablet view
    return (
        (!hasTabletBreakpoint && !hasMobileBreakpoint) ||
        (hasTabletBreakpoint &&
            hasMobileBreakpoint &&
            isDesktopAndTabletSame &&
            isDesktopAndMobileSame) ||
        (!hasTabletBreakpoint &&
            hasMobileBreakpoint &&
            isDesktopAndMobileSame) ||
        (hasTabletBreakpoint && !hasMobileBreakpoint && isDesktopAndTabletSame)
    );
};
export const defaultLinkedOrUnlinked = {
    heading: true,
    headingVisibility: true,
    textContent: true,
    backgroundCoverImage: true,
    backgroundCoverImageVisibility: true,
    blockWidth: false,
    boundingBox: false,
    blockHeight: false,
    fitHeightToContent: false,
    contentMargin: false,
    contentPadding: false,
};

export const getDefaultBlockLinkedSettings = (block) =>
    Object.keys(defaultLinkedOrUnlinked).reduce((acc, curr) => {
        acc[curr] = isBlockPropertySameInAllViews(block, curr)
            ? defaultLinkedOrUnlinked[curr]
            : false;
        return acc;
    }, {});

export const blockWidthType = (value) => {
    let val = 'full';
    if (value === '100%') {
        val = 'full';
    } else if (value === '50%') {
        val = 'half';
    } else if (value === 'fit-content') {
        val = 'fitcontent';
    } else {
        val = 'custom';
    }

    return val;
};

export const checkIfNullQueryFieldExist = (obj) =>
    !!Object.keys(obj?.children1).find((filterFieldId) => {
        if (obj?.children1?.[filterFieldId]?.type === 'group') {
            return checkIfNullQueryFieldExist(obj?.children1?.[filterFieldId]);
        }

        return obj?.children1?.[filterFieldId]?.properties?.field === null;
    });

export const sendMessageToGevmeTemplate = (message = {}) => {
    try {
        const GevmeTemplate = document.querySelector('#GevmeTemplate');
        if (message && !isEmpty(message) && GevmeTemplate) {
            GevmeTemplate.contentWindow.postMessage(
                JSON.stringify(message),
                '*'
            );
        }
    } catch (error) {
        console.error(error);
    }
};
