import * as React from 'react';
import PropTypes from 'prop-types';

const LeftArrowIcon = (props) => {
    return (
        <svg
        {...props}
            viewBox="0 0 18 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.7678 5.76777L15.0656 5.0656L15.7678 5.76776C16.7441 4.79145 16.7441 3.20854 15.7678 2.23223L15.0607 2.93934L15.7678 2.23223C14.7915 1.25592 13.2085 1.25592 12.2322 2.23223L2.23223 12.2322C1.25592 13.2085 1.25592 14.7915 2.23223 15.7678L12.2322 25.7678C13.2085 26.7441 14.7915 26.7441 15.7678 25.7678C16.7441 24.7915 16.7441 23.2085 15.7678 22.2322L7.53553 14L15.7678 5.76777Z"
                fill="#181818"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>


    );
};

LeftArrowIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

LeftArrowIcon.defaultProps = {
    width: 18,
    height: 28,
};
export default LeftArrowIcon;
