import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import AlertSimple from 'components/app/common/alertSimple';

import './wizardLayout.scss';

function WizardLayout({
    children,
    name,

    nextAction,
    nextText = 'Next',
    nextEnabledCondition,

    prevAction,
    prevText = 'Back',

    onlyCancelButton = false,
    onlyNextButton = false,
    cancelAction,
    cancelText = 'Cancel',
    cancelEnabledCondition,
}) {
    const alert = useSelector((state) => state.alert);

    return (
        <>
            <AlertSimple alert={alert} />
            <div className="wizard-layout">
                <div className="wizard-layout__header">
                    <h3 className="wizard-layout__name">{name}</h3>
                </div>
                {children}

                <div className="wizard-layout__navigation shadow-lg">
                    <div
                        className="wizard-layout__navigation-wrap"
                        style={{
                            justifyContent:
                                onlyCancelButton || onlyNextButton
                                    ? 'center'
                                    : 'space-between',
                        }}
                    >
                        {!onlyNextButton && (
                            <button
                                className="btn btn-secondary"
                                onClick={cancelAction}
                            >
                                {cancelText}
                            </button>
                        )}
                        {!onlyCancelButton && (
                            <div className="wizard-layout__navigation-inner-wrap">
                                {prevAction && (
                                    <button
                                        className="btn btn-secondary wizard-layout__prev"
                                        onClick={prevAction}
                                        disabled={
                                            cancelEnabledCondition
                                                ? false
                                                : true
                                        }
                                    >
                                        {prevText}
                                    </button>
                                )}

                                {nextAction && (
                                    <button
                                        className="btn btn-primary"
                                        onClick={nextAction}
                                        disabled={
                                            nextEnabledCondition ? false : true
                                        }
                                    >
                                        {nextText}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

WizardLayout.defaultProps = {
    nextText: 'Next',
    nextEnabledCondition: false,
    prevText: 'Back',
    cancelText: 'Cancel',
};

WizardLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    name: PropTypes.string.isRequired,

    nextAction: PropTypes.func.isRequired,
    nextText: PropTypes.string,
    nextEnabledCondition: PropTypes.bool,

    cancelAction: PropTypes.func.isRequired,
    cancelText: PropTypes.string,

    prevAction: PropTypes.func.isRequired,
    prevText: PropTypes.string,
};

export default WizardLayout;
