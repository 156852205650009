import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function GetCompanies(
    projectId,
    page = 1,
    search = '',
    searchBy = 'name',
    sortBy = 'name',
    sortOrder = 'DESC'
) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/company-profiles?projectId=${projectId}&page=${page}&search=${search}&searchBy=${searchBy}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    }
}
