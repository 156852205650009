import React from 'react';

export default function LogoutIcon({ stroke = 'black' }) {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_18531_23385)">
                <path
                    d="M7.49994 0C7.19392 0 6.90044 0.121566 6.68405 0.337954C6.46766 0.554342 6.34609 0.847827 6.34609 1.15385V6.92308C6.34609 7.2291 6.46766 7.52258 6.68405 7.73897C6.90044 7.95536 7.19392 8.07692 7.49994 8.07692C7.80596 8.07692 8.09944 7.95536 8.31583 7.73897C8.53222 7.52258 8.65379 7.2291 8.65379 6.92308V1.15385C8.65379 0.847827 8.53222 0.554342 8.31583 0.337954C8.09944 0.121566 7.80596 0 7.49994 0V0ZM3.37148 1.49654C3.20504 1.51756 3.04829 1.58651 2.92033 1.695C2.04289 2.38673 1.33391 3.2686 0.846824 4.27415C0.359739 5.2797 0.107255 6.38269 0.108402 7.5C0.108402 11.5719 3.42686 14.8921 7.49994 14.8921C11.573 14.8921 14.8921 11.5719 14.8921 7.5C14.8921 5.15019 13.7947 3.04788 12.0796 1.69442C11.9908 1.6234 11.8888 1.57055 11.7796 1.53891C11.6704 1.50727 11.556 1.49745 11.443 1.51001C11.33 1.52258 11.2206 1.55728 11.121 1.61213C11.0214 1.66698 10.9336 1.74092 10.8625 1.82971C10.7915 1.91851 10.7387 2.02042 10.707 2.12964C10.6754 2.23885 10.6656 2.35323 10.6781 2.46624C10.6907 2.57925 10.7254 2.68868 10.7802 2.78828C10.8351 2.88788 10.909 2.9757 10.9978 3.04673C11.6725 3.57639 12.2179 4.25256 12.5927 5.02408C12.9675 5.7956 13.1619 6.64226 13.1613 7.5C13.1631 8.24397 13.0179 8.98097 12.7341 9.66866C12.4502 10.3564 12.0333 10.9812 11.5072 11.5072C10.9811 12.0333 10.3563 12.4503 9.6686 12.7341C8.98091 13.018 8.24391 13.1632 7.49994 13.1613C6.75597 13.1632 6.01897 13.018 5.33128 12.7341C4.64359 12.4503 4.01876 12.0333 3.49269 11.5072C2.96662 10.9812 2.54968 10.3564 2.26582 9.66866C1.98196 8.98097 1.83677 8.24397 1.83859 7.5C1.83811 6.64228 2.03262 5.79568 2.40742 5.02419C2.78222 4.2527 3.32751 3.57651 4.00206 3.04673C4.15484 2.93197 4.26534 2.76988 4.31634 2.58572C4.36733 2.40157 4.35593 2.20572 4.28394 2.02872C4.21194 1.85173 4.08338 1.70353 3.91832 1.60726C3.75327 1.51099 3.56099 1.47206 3.37148 1.49654Z"
                    fill={stroke || 'black'}
                />
            </g>
            <defs>
                <clipPath id="clip0_18531_23385">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
