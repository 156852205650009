import React from 'react';
import PropTypes from 'prop-types';

import './pollResult.scss';

const PollResult = ({ data }) => {
    const calculatePercent = (votes = 0) => {
        let total = data?.voters.length || 0
        if (votes === 0 && total === 0) {
            return '0%'
        }
        return `${((votes / total) * 100).toFixed(1)}%`
    }
    return (
        <div className="poll-container">
            {data?.options?.map((option, index) => {
                return (
                    <div className="poll-item border rounded my-2" key={'poll-option-' + index} >
                        <div className="poll-background-fill" style={{ width: calculatePercent(option?.voters.length), backgroundColor: '#EDEDED' }} />
                        <div className="poll-background-label w-100 px-2 d-flex justify-content-between" >
                            <span className="poll-radio-item">{option.label}</span>
                            <span className="poll-background-label-percent">{calculatePercent(option?.voters.length)} ({option?.voters.length})</span>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

PollResult.propTypes = {
    data: PropTypes.object,
};

PollResult.defaultProps = {
    data: null,
};

export default PollResult;
