import React from 'react';

export default function PreviewIcon({ stroke = '#A60606' }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.33333 3V4.66667H2.16667V13.8333H11.3333V9.66667H13V14.6667C13 14.8877 12.9122 15.0996 12.7559 15.2559C12.5996 15.4122 12.3877 15.5 12.1667 15.5H1.33333C1.11232 15.5 0.900358 15.4122 0.744078 15.2559C0.587797 15.0996 0.5 14.8877 0.5 14.6667V3.83333C0.5 3.61232 0.587797 3.40036 0.744078 3.24408C0.900358 3.0878 1.11232 3 1.33333 3H6.33333ZM15.5 0.5V7.16667H13.8333V3.34417L7.33917 9.83917L6.16083 8.66083L12.6542 2.16667H8.83333V0.5H15.5Z"
                fill={stroke}
            />
        </svg>
    );
}
