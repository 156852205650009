import React from 'react';

import { get } from 'lodash';
import { useSelector } from 'react-redux';

/**
 * @param {string} elementName element name (should match key in access control `restrictedAccess` obj)
 * @param {object} element element to be restricted
 * @param {object} restrictedElement modified version of element (e.g. no `onClick`)
 */
const RestrictedElement = ({ elementName, element, restrictedElement }) => {
    const restrictAccess = useSelector((state) => state.user.restrictAccess);

    if (restrictAccess) {
        if (get(restrictAccess, elementName)) {
            return element;
        } else {
            return restrictedElement || null;
        }
    } else {
        return element;
    }
};

export default RestrictedElement;
