import * as React from 'react';

const CopyIcon = (props) => {
    return (
        <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
            <path
                d="M12.636 12.636H17V1H5.363v4.364"
                stroke="#000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.636 5.364H1V17h11.636V5.364z"
                stroke="#000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CopyIcon;
