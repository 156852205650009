import React, { useState, useEffect } from 'react';
import { Link, withRouter, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';
import TagManager from 'react-gtm-module';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import RestrictedElement from 'components/app/common/restrictedElement';

import Prompt from 'components/app/common/prompt';
import GlobalSidebar from '../../../../common/globalBar/globalSidebar';
import { camelCase } from 'lodash';

import SHOWCASE from '../../../../../../apis/graphql/Showcase';

// Redux - actions
import * as act from '../../../../../../redux/actions/live/uiActionsLive';
import { getLivePageTemplate } from 'apis/rest/TemplateIntegrations';

import './subMenu.scss';

// <SubMenu /> Component
const SubMenu = (props) => {
    // Redux - states
    const subMenuData = useSelector((state) => state.menus.subMenuData);
    const isSubMenuOpen = useSelector((state) => state.menus.subMenuOpen);
    const dispatch = useDispatch();
    const userRole = useSelector((state) => state?.user?.roleId);
    // History
    const history = useHistory();

    const { enableVenue } = useSelector((state) => state?.venuesettings);

    // Params
    const {
        projectId: directProjectId,
        showcaseId: directShowcaseId,
        companyId,
    } = useParams();

    const [templateId, setTemplate] = useState('');

    // placement: boolean => if true then set element to right, else left
    // active: boolean => sets what link is active
    // all: boolean => determine if all menu will be returned
    // allLiveSite: boolean => return all stuff for LiveSite specifics
    // externalLiveSettings: object => for live settings
    const { placement, active, all, allLiveSite, externalLiveSettings, match } =
        props;

    // mainLinks: array => your main nav links
    // allLinks: array => all other nav links
    const { mainLinks, allLinks } = subMenuData
        ? subMenuData
        : { mainLinks: [], allLinks: [] };

    // Component states
    const [activeLivePageName, setActiveLivePageName] = useState('');
    const [subMenuClicked, setSubMenuClicked] = useState(!isSubMenuOpen);
    const [dropdownClicked, setDropdownClicked] = useState(false);
    const [liveEnabled, setLiveEnabled] = useState(
        externalLiveSettings && externalLiveSettings.isLiveSiteEnabled === 1
            ? true
            : false
    );
    const [showPrompt, setShowPrompt] = useState(false);

    // Get specific showcase
    const { loading } = useQuery(SHOWCASE, {
        variables: { id: directShowcaseId },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const { showcase } = data;
            setActiveLivePageName(showcase.title);
        },
    });

    useEffect(() => {
        externalLiveSettings &&
            setLiveEnabled(
                externalLiveSettings.isLiveSiteEnabled === 1 ? true : false
            );
    }, [externalLiveSettings]);

    // Handler for sub menu click
    const handleMenuClick = () => {
        // Update component state
        setSubMenuClicked(!subMenuClicked);
        // Update Redux state
        dispatch(act.updateAllowFX(true));
        dispatch(act.toggleSubMenu(!isSubMenuOpen));
    };

    // Handler for dropdown click
    const handleDropdownClick = () => {
        setDropdownClicked(!dropdownClicked);
    };

    const handleDropdownBlur = () => {
        setTimeout(() => {
            setDropdownClicked(false);
        }, 300);
    };

    // Temp: Handler for dropdown selection
    const handleItemClick = () => {
        setShowPrompt(true);
    };

    // Handler for arrow click
    const handleBackArrowClick = () => {
        history.push(
            `/company/${companyId}/project/${
                directProjectId !== undefined && directProjectId
            }/live/pages`
        );
    };

    // Handler for LiveSite switch
    const handleMenuSwitch = (internalToggleLiveSite) => {
        internalToggleLiveSite();
        setLiveEnabled(!liveEnabled);
    };

    // Handler for when sub menu is visible
    const handleOnReveal = () => {
        // Let layout know that this menu is visible
        dispatch(act.updateSubMenuVisibility(true));
    };

    // opens preview of particular page / site
    const openPreview = async () => {
        try {
            const { status, template } = await getLivePageTemplate(
                directProjectId,
                directShowcaseId
            );
            if (status) {
                window.open(
                    template.id
                        ? `/previewer?showcaseId=${directShowcaseId}&projectId=${directProjectId}&templateId=${template.id}`
                        : `/previewer?source=${externalLiveSettings.previewLink}`,
                    '_blank',
                    'toolbar=0,location=0,menubar=0'
                );
            } else throw 'No Template';
        } catch (e) {
            window.open(
                `/previewer?source=${externalLiveSettings.previewLink}`,
                '_blank',
                'toolbar=0,location=0,menubar=0'
            );
        }
    };

    // Handle when setting prompt
    const handleClose = () => setShowPrompt(false);

    // Handle which props value to show
    const prompt = {};
    const promptMessagePrefix =
        'Are you sure you want to change the status of this LivePage to ';

    if (externalLiveSettings.isPublished) {
        prompt.message = `${promptMessagePrefix}draft?`;
        prompt.description =
            'Attendees will be no longer be able to access this Page.';
        prompt.proceedButton = 'Proceed to Draft';
    } else {
        prompt.message = `${promptMessagePrefix}Live?`;
        prompt.description = 'Attendees will be able to access this Page.';
        prompt.proceedButton = 'Go Live';
    }

    return (
        <GlobalSidebar
            right={placement && true}
            width={subMenuClicked ? 30 : 180}
            zIndex={45}
            top={50}
        >
            {/* This is used to detect if menus are visible */}
            <Fade delay={0} onReveal={handleOnReveal}>
                <span style={{ opacity: 0 }}> </span>
            </Fade>

            {/* Hamburger Menu lines */}
            <div
                id="subMenu"
                className={`${subMenuClicked && 'submenuclicked'}`}
                onClick={handleMenuClick}
            >
                <div className="span1"></div>
            </div>

            {/* subMenu top stuffs */}
            <div
                className={`submenu-top ${subMenuClicked && 'submenuclicked'}`}
            >
                {/* If request is for LiveSite pages return specific Menu title */}
                {allLiveSite ? (
                    <div className="livesite-pages-title-container">
                        <h2 className={`livesite-pages-title`}>LiveSite</h2>
                        <label className="switch">
                            <input
                                type="checkbox"
                                name="checkbox-menu"
                                checked={
                                    externalLiveSettings &&
                                    externalLiveSettings.isLiveSiteEnabled
                                }
                                onClick={() =>
                                    handleMenuSwitch(
                                        externalLiveSettings.toggleLiveSite
                                    )
                                }
                            />
                            <span className="slider round" />
                        </label>
                    </div>
                ) : (
                    <div className="d-flex align-items-center mb-3">
                        <RestrictedElement
                            elementName="subMenu.backToLivePages"
                            element={
                                <i
                                    className="myxp-arrow-back"
                                    onClick={handleBackArrowClick}
                                ></i>
                            }
                        />
                        <h2>{activeLivePageName}</h2>
                    </div>
                )}

                <div className={`dropdown ${dropdownClicked && 'show'}`}>
                    <button
                        className={`btn dropdown-toggle ${
                            externalLiveSettings &&
                            externalLiveSettings.isPublished === true &&
                            'live'
                        }`}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={handleDropdownClick}
                        onBlur={handleDropdownBlur}
                        disabled={allLiveSite && !liveEnabled && true}
                    >
                        {externalLiveSettings &&
                        externalLiveSettings.isPublished
                            ? 'Live'
                            : 'Draft'}
                        <FontAwesomeIcon icon="chevron-down" />
                    </button>
                    <div
                        className={`dropdown-menu ${dropdownClicked && 'show'}`}
                        aria-labelledby="dropdownMenuButton"
                        onClick={handleItemClick}
                    >
                        {externalLiveSettings &&
                        externalLiveSettings.isPublished
                            ? 'Draft'
                            : 'Live'}
                    </div>
                </div>

                {!enableVenue && (
                    <button
                        className={`btn prev btn-secondary`}
                        type="button"
                        disabled={allLiveSite && !liveEnabled && true}
                        onClick={openPreview}
                    >
                        <span>Preview</span>
                        <FontAwesomeIcon icon="external-link-alt" />
                    </button>
                )}
            </div>

            {/* subMenu bottom stuffs */}

            <ul
                className={`app-submenu nav flex-column ${
                    subMenuClicked && 'submenuclicked'
                }`}
            >
                {all && (
                    <>
                        {allLinks.map((sublink) => {
                            const sl = sublink?.name
                                .toLowerCase()
                                .split(' ')
                                .join('');
                            let key = camelCase(sublink?.name);
                            if (key === 'liveWidgets') {
                                key = 'liveWidget';
                            }
                            if (
                                enableVenue &&
                                (key === 'liveWidget' || key === 'content')
                            )
                                return null;

                            let menuPermissionsData = props?.menuPermissions;

                            if (menuPermissionsData === null) {
                                //ıf there is no permissons It will be default
                                menuPermissionsData = {
                                    setting: 'visible',
                                    liveWidget: 'visible',
                                    content: 'visible',
                                    agenda: 'visible',
                                };
                            }
                            let menuKey =
                                menuPermissionsData && menuPermissionsData[key];
                            //polls and aganda visibilty settings same
                            if (sublink?.key === 'polls') {
                                menuKey =
                                    menuPermissionsData &&
                                    menuPermissionsData['agenda'];
                            }

                            if (menuKey === null || userRole !== 4) {
                                if (sl === 'agenda') return null;
                            }

                            if (sl === 'polls') return null;

                            if (userRole !== 4 && sl) {
                                return (
                                    <li
                                        key={sl}
                                        className={`nav-item nav-item__sub `}
                                    >
                                        <Link
                                            id="sub"
                                            className={`nav-link ${
                                                sl === active ||
                                                match.path.includes(sl)
                                                    ? 'active'
                                                    : ''
                                            }  ${
                                                subMenuClicked &&
                                                'submenuclicked'
                                            }`}
                                            to={`/company/${companyId}/project/${
                                                directProjectId !== undefined &&
                                                directProjectId
                                            }/${
                                                sl === 'companyprofile'
                                                    ? sublink.link
                                                    : `live/pages/${
                                                          directShowcaseId !==
                                                              undefined &&
                                                          directShowcaseId
                                                      }/${sublink.link}`
                                            }`}
                                        >
                                            <p className={`sidebar-${sl} sub`}>
                                                {sublink.name}
                                            </p>
                                        </Link>
                                    </li>
                                );
                            }

                            if (userRole === 4 && menuKey === 'visible') {
                                return (
                                    <li
                                        key={sl}
                                        className={`nav-item nav-item__sub `}
                                    >
                                        <Link
                                            id="sub"
                                            className={`nav-link ${
                                                sl === active ||
                                                match.path.includes(sl)
                                                    ? 'active'
                                                    : ''
                                            }  ${
                                                subMenuClicked &&
                                                'submenuclicked'
                                            }`}
                                            to={`/company/${companyId}/project/${
                                                directProjectId !== undefined &&
                                                directProjectId
                                            }/${
                                                sl === 'companyprofile'
                                                    ? sublink.link
                                                    : `live/pages/${
                                                          directShowcaseId !==
                                                              undefined &&
                                                          directShowcaseId
                                                      }/${sublink.link}`
                                            }`}
                                        >
                                            <p className={`sidebar-${sl} sub`}>
                                                {sublink.name}
                                            </p>
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </>
                )}

                {/* for LiveSite stuffs */}

                {allLiveSite && (
                    <>
                        {allLinks
                            .filter(
                                (unwantedLink) =>
                                    unwantedLink &&
                                    unwantedLink?.name
                                        ?.toLowerCase()
                                        ?.split(' ')
                                        ?.join('') !== 'livewidgets'
                            )
                            .map((sublink) => {
                                const sl = sublink?.name
                                    .toLowerCase()
                                    .split(' ')
                                    .join('');

                                if (userRole !== 4) {
                                    if (sublink.key === 'agenda') return null;
                                }
                                if (sublink.key === 'polls') return null;

                                return (
                                    <li
                                        key={sl}
                                        className="nav-item nav-item__sub"
                                    >
                                        <Link
                                            id="sub"
                                            className={
                                                `nav-link ` +
                                                (!liveEnabled
                                                    ? 'disabled'
                                                    : '') +
                                                (liveEnabled && sl === active
                                                    ? 'active'
                                                    : '') +
                                                (subMenuClicked
                                                    ? 'submenuclicked'
                                                    : '')
                                            }
                                            to={
                                                sl === 'settings'
                                                    ? `/company/${companyId}/project/${
                                                          directProjectId !==
                                                              undefined &&
                                                          directProjectId
                                                      }/live/site/settings`
                                                    : sl === 'content'
                                                    ? `/company/${companyId}/project/${
                                                          directProjectId !==
                                                              undefined &&
                                                          directProjectId
                                                      }/live/site/content`
                                                    : ''
                                            }
                                        >
                                            <p
                                                className={`sidebar-${sl} sub livesite-pages`}
                                            >
                                                {sublink.name}
                                            </p>
                                        </Link>
                                    </li>
                                );
                            })}
                    </>
                )}
            </ul>

            {/* The Prompt for Delete */}
            <Prompt
                show={showPrompt}
                message={prompt.message}
                description={prompt.description}
                buttons={['Cancel', `${prompt.proceedButton}`]}
                onHide={() => handleClose()}
                callback={() => {
                    externalLiveSettings.togglePublishedStatus();
                    !externalLiveSettings?.isPublished &&
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'livepagePublished',
                                info: {
                                    projectId: directProjectId,
                                    showcaseId: directShowcaseId,
                                    companyId,
                                },
                            },
                        });
                    setShowPrompt(false);
                }}
            />
        </GlobalSidebar>
    );
};

export default withRouter(SubMenu);
