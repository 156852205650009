import React from 'react';
import PropTypes from 'prop-types';

import './globalLoading.scss';

const GlobalLoading = ({
    loadingText,
    width,
    height,
    className = '',
    isAntLoader = false,
}) => {
    const style = {};
    if (width) style.width = width;
    if (height) style.height = height;

    return (
        <>
            <div className={`global-loading-content ${className}`}>
                <div className="global-loader-alignment tw-flex tw-flex-col tw-gap-2">
                    <img
                        style={style}
                        src="/assets/gvm_live_loading_loop.svg"
                        alt="Loader"
                    />
                    <p>{loadingText}</p>
                </div>
            </div>

            {isAntLoader && (
                <style>
                    {`.ant-spin-nested-loading>div> .ant-spin .ant-spin-dot{
                                position:static;
                            }
                            .global-loader-alignment{
                                position-static;
                                display:flex;
                                align-items:center;
                                justify-content:center;
                            }
                            `}
                </style>
            )}
        </>
    );
};

export default GlobalLoading;

GlobalLoading.propTypes = {
    loadingText: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

GlobalLoading.defaultProps = {
    loadingText: 'Loading your experience',
    width: '',
    height: '',
};
