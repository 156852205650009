import * as React from "react"

const AgendaIcon = (props) => {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={4} fill="#E1E7FA" />
      <rect x={8} y={8} width={9} height={5} rx={2} fill="#7A829D" />
      <rect x={8} y={20} width={9} height={4} rx={2} fill="#7A829D" />
      <rect x={8} y={30} width={9} height={5} rx={2} fill="#7A829D" />
      <rect x={19} y={8} width={21} height={10} rx={2} fill="#B9C1DB" />
      <rect x={19} y={20} width={21} height={8} rx={2} fill="#B9C1DB" />
      <rect x={19} y={30} width={21} height={10} rx={2} fill="#B9C1DB" />
    </svg>
  )
}

export default AgendaIcon
