import React from 'react';

export default function ArrowBackIcon({ classData }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            className={classData}
        >
            <path
                d="M14.4377 16.4668L21.0374 23.0664L19.1518 24.952L10.6665 16.4668L19.1518 7.98145L21.0374 9.86706L14.4377 16.4668Z"
                fill="black"
            />
        </svg>
    );
}
