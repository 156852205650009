import React from 'react';

export default function EditIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6666 4.82672C14.6671 4.73898 14.6503 4.65201 14.6171 4.57078C14.5839 4.48956 14.535 4.41568 14.4733 4.35339L11.6466 1.52672C11.5843 1.46493 11.5104 1.41605 11.4292 1.38287C11.348 1.34969 11.261 1.33288 11.1733 1.33339C11.0855 1.33288 10.9985 1.34969 10.9173 1.38287C10.8361 1.41605 10.7622 1.46493 10.6999 1.52672L8.81326 3.41338L1.5266 10.7001C1.46481 10.7623 1.41593 10.8362 1.38275 10.9174C1.34957 10.9987 1.33276 11.0856 1.33326 11.1734V14.0001C1.33326 14.1769 1.4035 14.3464 1.52853 14.4715C1.65355 14.5965 1.82312 14.6667 1.99993 14.6667H4.8266C4.91988 14.6718 5.01319 14.6572 5.10048 14.6239C5.18776 14.5906 5.26707 14.5393 5.33326 14.4734L12.5799 7.18672L14.4733 5.33338C14.5341 5.26877 14.5837 5.19441 14.6199 5.11338C14.6264 5.06025 14.6264 5.00652 14.6199 4.95338C14.623 4.92235 14.623 4.89108 14.6199 4.86005L14.6666 4.82672ZM4.55326 13.3334H2.6666V11.4467L9.2866 4.82672L11.1733 6.71339L4.55326 13.3334ZM12.1133 5.77338L10.2266 3.88672L11.1733 2.94672L13.0533 4.82672L12.1133 5.77338Z"
                fill="black"
            />
        </svg>
    );
}
