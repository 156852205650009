import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { produce } from 'immer';
import { stringToBoolean } from 'components/app/utils/functions/common';
import showcaseblockValidationCriteria from 'components/app/utils/validators/showcaseblockValidator';
import * as ls from 'local-storage';
import GeneralPreloader from 'components/app/common/preloader/generalPreloader';

import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';
import useFileUpload from 'components/app/utils/hooks/useFileUpload';

import CREATE_SHOWCASEBLOCK from 'apis/graphql/CreateShowcaseblock';
import UPDATE_SHOWCASEBLOCK from 'apis/graphql/UpdateShowcaseblock';

import SHOWCASE from 'apis/graphql/Showcase';
import SHOWCASEBLOCK from 'apis/graphql/Showcaseblock';

import Post from './post';
import ImageGallery from './imageGallery';
import Pdf from './pdf';
import Video from './video';
import SocialMedia from './socialMedia';
import Selector from './selector';

import './blockCreator.scss';
import { useSelector } from 'react-redux';
function BlockCreator(props) {
    const {
        setModalVisibility,
        refetchShowcase,
        match: { params },
        showcaseblockId,
    } = props;

    const user = useSelector(state => state.user)

    const showcaseId = params.showcaseId;
    const { showAlertBar } = useAlertBar();

    const [blockContentData, setBlockContentData] = useState(null)
    const [permission, setPermission] = useState(null)
    const [blockPrevents, setBlockPrevents] = useState({
        preventEditTitle: false,
        preventEditSize: false,
        preventEditName: false,
    });

    const { data } = useQuery(SHOWCASEBLOCK, {
        variables: { id: showcaseblockId },
    });

    useEffect(() => {
        if (user.roleId === 4) {
            if (blockContentData !== data) {
                setBlockContentData(data)
                setInput({ ...input, ...{ title: data?.showcaseblock?.title } })
            }
        }
    }, [data])

    const [createShowcaseblock] = useMutation(CREATE_SHOWCASEBLOCK, {
        onCompleted: (data) => {
            showAlertBar('Content block created!', 'success');
            refetchShowcase();
        },
        onError: (err) => {
            showAlertBar(err.message, 'error');
        },
    });

    const [updateShowcaseblock] = useMutation(UPDATE_SHOWCASEBLOCK, {
        onCompleted: (data) => {
            showAlertBar('Content block updated!', 'success');
            refetchShowcase();
        },
        onError: (err) => {
            showAlertBar(err.message, 'error');
        },
    });

    const [input, setInput] = useState({
        showcase: showcaseId,
        type: '',
        contentPadding: `20`,
        showContentMobile: true, // Default value to show content as thumbnails in mobile devices
    });
    const [thumbnail, setThumbnail] = useState('');

    const type = input.type;
    const isEditorSelectMode = input.type === '';

    const {
        resetFormErrors,
        validateInput,
        renderFieldError,
        hasErrors,
    } = useFormValidator({
        input,
        validationCriteria: showcaseblockValidationCriteria,
    });

    const {
        prepareFileUpload,
        handleFileUpload,
        handleFileDelete,
    } = useFileUpload(input, setInput);

    /**
     * handles submission of form
     * @param {object} e event object
     */
    const onSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInput();

        if (!hasErrors(formErrors)) {
            let data = omit(input, ['image']);
            const dataWithFileIds = await handleFileUpload('strapi', data);

            if (dataWithFileIds ? dataWithFileIds : true) {
                if (showcaseblockId)
                    updateShowcaseblock({
                        variables: {
                            input: {
                                data: dataWithFileIds
                                    ? dataWithFileIds
                                    : {
                                        ...data,
                                        ...{
                                            name: data.title
                                                .toLowerCase()
                                                .replace(/\s/g, '-'),
                                        },
                                    },
                                where: { id: showcaseblockId },
                            },
                        },
                    });
                else
                    createShowcaseblock({
                        variables: {
                            input: {
                                data: dataWithFileIds
                                    ? dataWithFileIds
                                    : {
                                        ...data,
                                        ...{
                                            name: data.title
                                                .toLowerCase()
                                                .replace(/\s/g, '-'),
                                        },
                                    },
                            },
                        },
                        // refetchQueries: [{ query: SHOWCASE, variables: { id: showcaseId } }]
                    });

                setModalVisibility(false);
            }
        }
    };

    /**
     * handles change to the Rich Text Editor
     * @param {object} value value produced by the editor
     */
    const handleRichTextEditorChange = (rteValue) => {
        let nextState = produce(input, (draftState) => {
            draftState.textContent = rteValue.toString('markdown');
        });

        setInput(nextState);
    };

    const handleContentPaddingChange = (value) => {
        let nextState = produce(input, (draftState) => {
            draftState.contentPadding = value;
        });

        setInput(nextState);
    };

    /**
     * gets rich text editor value from child component
     * @param {string} rteValue rich text value
     */
    const getRichTextEditorValue = (rteValue) => {
        handleRichTextEditorChange(rteValue);
    };

    const getContentPaddingValue = (value) => {
        handleContentPaddingChange(value);
    };

    /**
     * handles change input field
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let type = e.target.type;
        let isChecked = e.target.checked;

        let nextState;

        switch (type) {
            case 'text':
            case 'textarea':
            case 'select-one':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            case 'checkbox':
                nextState = produce(input, (draftState) => {
                    draftState[name] = isChecked;
                });
                break;
            case 'radio':
                nextState = produce(input, (draftState) => {
                    draftState[name] = stringToBoolean(value);
                });
                break;
            default:
                break;
        }

        setInput(nextState);
    };

    /**
     * handles change to the content editor type
     * @param {} type
     */
    const handleTypeChange = (type) => {
        let nextState = produce(input, (draftState) => {
            draftState.type = type;
            draftState.largeTile = false;
        });

        setInput(nextState);
    };
    const handleSocialMediaChange = (socialLinks) => {
        let nextState = produce(input, (draftState) => {
            draftState.socialLinks = socialLinks;
        });

        setInput(nextState);
    };
    /**
     * handles action after clicking "Back" / "Cancel" button
     */
    const handleBack = () => {
        let nextState = produce(input, (draftState) => {
            draftState.type = '';
        });

        setInput(nextState);

        setThumbnail('');
        resetFormErrors();
    };

    const { } = useQuery(SHOWCASE, {
        variables: { id: showcaseId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { showcase } = data;
            if (user.roleId === 4) {
                let blockPrevents = {
                    preventEditTitle: showcase.preventEditTitle,
                    preventEditSize: showcase.preventEditSize,
                    preventEditName: showcase.preventEditName
                }
                setBlockPrevents(blockPrevents)
                if (showcase.permission) {
                    setPermission(showcase.permission)
                }
            }
        },
    });

    const contentEditorProps = {
        handleInputChange,
        prepareFileUpload,
        handleFileDelete,
        renderFieldError,
        validateInput,
        blockContentData,
        permission,
        input,
        ...blockPrevents,
    };

    return (
        <div className="block-creator">
            <div className="row">
                <div className="col-12">
                    <div className="block-creator__body">
                        <img
                            src="/assets/icon_close.svg"
                            alt=""
                            className="icon-close"
                            onClick={() => setModalVisibility(false)}
                        />
                        {isEditorSelectMode && (
                            <>
                                <h2>Choose a block type</h2>
                                <Selector
                                    handleTypeChange={handleTypeChange}
                                    typeSelected={type}
                                    user={user}
                                ></Selector>
                            </>
                        )}

                        {!isEditorSelectMode && (
                            <form onSubmit={onSubmit}>
                                {type === 'Image' && (
                                    <>
                                        <h2 className="block-creator__title">
                                            <img
                                                src="/assets/icon_back_arrow.svg"
                                                className="arrow-back"
                                                alt="Back Button"
                                                onClick={handleBack}
                                            />{' '}
                                            Image Gallery
                                        </h2>
                                        <ImageGallery
                                            {...contentEditorProps}
                                        ></ImageGallery>
                                    </>
                                )}

                                {type === 'Pdf' && (
                                    <>
                                        <h2 className="block-creator__title">
                                            <img
                                                src="/assets/icon_back_arrow.svg"
                                                className="arrow-back"
                                                alt="Back Button"
                                                onClick={handleBack}
                                            />{' '}
                                            Pdf
                                        </h2>
                                        <Pdf {...contentEditorProps}></Pdf>
                                    </>
                                )}

                                {type === 'Video' && (
                                    <>
                                        <h2 className="block-creator__title">
                                            <img
                                                src="/assets/icon_back_arrow.svg"
                                                className="arrow-back"
                                                alt="Back Button"
                                                onClick={handleBack}
                                            />{' '}
                                            Video
                                        </h2>
                                        <Video {...contentEditorProps}></Video>
                                    </>
                                )}

                                {type === 'Post' && (
                                    <>
                                        <h2 className="block-creator__title">
                                            <img
                                                src="/assets/icon_back_arrow.svg"
                                                className="arrow-back"
                                                alt="Back Button"
                                                onClick={handleBack}
                                            />{' '}
                                            Rich Content
                                        </h2>
                                        <Post
                                            getRichTextEditorValue={
                                                getRichTextEditorValue
                                            }
                                            getContentPaddingValue={
                                                getContentPaddingValue
                                            }
                                            {...contentEditorProps}
                                        ></Post>
                                    </>
                                )}

                                {type === 'SocialLink' && (
                                    <>
                                        <h2 className="block-creator__title">
                                            <img
                                                src="/assets/icon_back_arrow.svg"
                                                className="arrow-back"
                                                alt="Back Button"
                                                onClick={handleBack}
                                            />{' '}
                                            Social Media
                                        </h2>
                                        {contentEditorProps.input.socialLinks &&
                                            <SocialMedia getSocialMediaList={(socialLinks) => handleSocialMediaChange(socialLinks)}  {...contentEditorProps} />
                                        }
                                        {!contentEditorProps.input.socialLinks &&
                                            <GeneralPreloader />
                                        }
                                    </>
                                )}

                                <div className="grouper grouper--right mt-3">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleBack}
                                    >
                                        Cancel
                                    </button>
                                    <input
                                        className="btn btn-primary"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockCreator.propTypes = {
    reloadIframe: PropTypes.func,
    setModalVisibility: PropTypes.func.isRequired,
};

export default withRouter(BlockCreator);
