import axios from 'axios';
import * as ls from 'local-storage';

/**
 * handles file upload (triggers post request and returns resulting `uploadId`)
 * @note this is only file uploads using "Strapi" endpoint
 */

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export const uploadFile = (
    files,
    companyIdFolder,
    projectIdFolder,
    type = null
) => {
    setAuthBearer();
    const formData = new FormData();

    Array.from(files).forEach((file) => {
        formData.append('files', file);
    });

    // formData.append("ref", ref);
    formData.append('path', `${companyIdFolder}/${projectIdFolder}`);

    return axios({
        method: 'post',
        headers: {
            Authorization: authBearer,
            credentials: 'include',
        },
        // url: `${process.env.REACT_APP_API_URL}/upload`,
        url: `${process.env.REACT_APP_MYXP_API}/manager/livestream/showcases/images`,
        data: formData,
    })
        .then((res) => {
            // if multiple files return an array of ids
            if (res.data.length > 1) {
                const uploadIds = res.data.map((data) => {
                    if (type === 'imgPath') return data.url;
                    else return data.id;
                });
                return uploadIds;
            } else {
                // else return just an id string
                if (type === 'imgPath') return res.data[0].url;
                else return res.data[0].id;
            }
        })
        .catch((err) => {
            return err;
        });
};
