import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * generates otp (email gets sent with otp)
 * @param {object} data data required to generate otp
 */
export async function generateOtp(data, type) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/login/${type}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );

    return response;
}

/**
 * authenticates otp received to get authentication token
 * @param {object} data data required to get authentication token
 */
export async function authenticate(data) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/validate`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return response;
}
export async function getAuthCode(data) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/code`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function logout(data) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/auth/logout`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function sponsorLogout() {
    const sponsorAuth = ls.get('sponsorAuth');
    const sponsorTempAuth = ls.get('sponsorTempAuth');
    let token = [];

    if (sponsorTempAuth) token.push(sponsorTempAuth?.access_token);
    if (sponsorAuth) {
        // Get all tokens
        token = [
            ...token,
            ...Object.keys(sponsorAuth).map(
                (key) => sponsorAuth[key].access_token
            ),
        ];

        // Find one token that's not yet expired
        const filteredNotExpiredTokens = Object.keys(sponsorAuth).find(
            (key) => {
                if (new Date(sponsorAuth[key].expire_in) > new Date())
                    return key;
            }
        );

        const bearerToken = filteredNotExpiredTokens
            ? sponsorAuth[filteredNotExpiredTokens].access_token
            : sponsorAuth[Object.keys(sponsorAuth)[0]].access_token;

        const { response: res } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/logout`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
                body: JSON.stringify({ token }),
            }
        );
        return await res.json();
    }
    return { status: true, message: 'No sponsor auth token generated yet' };
}

export async function generateSponsorOtp(data) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/login`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );

    return response;
}

export async function sponsorTempAuthenticate(data) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/otp`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}
export async function sponsorAuthorize(data) {
    const auth = ls.get('sponsorTempAuth');
    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/authorize`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function logoutSponsor() {
    const auth = ls.get('sponsorTempAuth');
    const sponsorAuth = ls.get('sponsorAuth');

    const tokens = Object.keys(sponsorAuth).map(
        (key) => sponsorAuth[key].access_token
    );

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/logout`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(tokens),
        }
    );
    return await response.json();
}
