import React from 'react';
import {
    Dropdown,
    Menu
} from 'antd';

import DraggableList from 'Apps/VenueBuilder/Components/Common/DraggableList';

import PlusIcon from 'Apps/VenueBuilder/Icons/PlusIcon';

import styles from './index.module.css';


const Items = ({
    items,
    onDeleteItem,
    onReorderImages,
    setVisible,
    submitted,
    hidden = false,
    containerStyle,
    contentStyle
}) => {

    const onVisibilityChange = (reqState, item) => {
        if (reqState)
            onDeleteItem(item)
    };

    const onItemClicked = (item, index) => {
        //handle editing of a content block based on type here.
        setVisible({
            visible: true,
            data: item,
            item: item.type,
            index,
            items
        })
    };

    const onReordered = (data) => {
        onReorderImages(data);
    };

    const menus = (
        <Menu>
            <Menu.Item onClick={() => setVisible({ visible: true, item: 'PDF' })}>Add PDF</Menu.Item>
            <Menu.Item onClick={() => setVisible({ visible: true, item: 'Link' })}>Add link</Menu.Item>
            <Menu.Item onClick={() => setVisible({ visible: true, item: 'Image' })}>Add Image</Menu.Item>
        </Menu>
    );

    return (
        <div className={`${styles.ItemContainer}`} style={containerStyle}>
            {!hidden &&
                <div className={styles.Heading}>
                    <h3>
                        Item(s)
                        <span className={styles.Required}>*</span>
                    </h3>
                </div>
            }
            <div className={`${styles.ContentContainer} ${submitted && (items.length === 0 || !items?.every(item => item.link)) ? styles.ErrorBorder : ''}`} style={contentStyle}>
                {!hidden &&
                    <Dropdown
                        trigger={['click']}
                        overlay={menus} >
                        <div
                            className={styles.AddImage}>
                            <PlusIcon />
                            <span>Add Item</span>
                        </div>
                    </Dropdown>

                }


                <div className={`${styles.ImageLists} itemlists`}>
                    {items?.length > 0 &&
                        <DraggableList
                            listData={items}
                            onReordered={onReordered}
                            visibilityKey="blockVisibility"
                            visibilityIcon="close"
                            onVisibilityChange={onVisibilityChange}
                            onItemClicked={onItemClicked} />
                    }
                </div>
            </div>
            {submitted && (items.length === 0 || !items?.every(item => item.link)) && <span className={styles.ErrorMessage}>Required</span>}
            <style jsx={true}>
                {`
                .itemlists .postleft span {
                    background: transparent;
                }
                `}
            </style>
        </div>
    )
}

export default Items;