import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function getCompanyUsers({ companyId, page, limit }) {
    const auth = ls.get('auth');
    const authBearer = `Bearer ${auth?.access_token}`;

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/manager/users?limit=${limit}&page=${page}&companyId=${companyId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );

    return await response.json();
}
