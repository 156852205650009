import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import Layout from 'components/app/common/layout';
import { useDispatch } from 'react-redux';
import * as act from '../../../../../redux/actions/live/uiActionsLive';
import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';

import { GetUser } from 'apis/rest/sponsorship/GetUser';

import './dashboard.scss';

function SponsorshipDashboard() {
    const history = useHistory();
    const dispatch = useDispatch();

    const tempProjId = ls.get('tempProjId');

    const [allCompanies, setAllCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(act.enableSwitchEvent());
        handleGetUser();
    }, []);

    const handleClickManageToProject = (projectId) => {
        ls.set('sponsorshipId', projectId);
        history.push(`/project/${tempProjId}/live/pages`);
    };

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Uniqe Id',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: null,
            key: 'action',
            render: (text, record) => (
                <button
                    onClick={() => handleClickManageToProject(record.id)}
                    className="btn myxp btn-primary ml-auto"
                >
                    Manage
                </button>
            ),
        },
    ];

    const handleGetUser = async () => {
        try {
            const response = await GetUser();
            if (response.status) {
                const companyList = response?.sponsor?.companyProfiles;
                let filterCompanyList = [];

                companyList.find((item) => {
                    if (item.projectId === tempProjId) {
                        filterCompanyList.push(item);
                    }
                });
                setAllCompanies(filterCompanyList);
            }
        } catch (error) {
            console.error('ERROR handleGetUser', error);
        } finally {
            setIsLoading(false);
        }
    };

    const globalLoadingComponent = {
        spinning: isLoading,
        indicator: <GlobalLoading />,
    };

    return (
        <Layout disableMenuWidth={true}>
            <div className="container" style={{ width: '950px' }}>
                <div className="dashboard dashboard__xpmgr project-list">
                    <div className="dashboard__left">
                        <h1>Sponsors</h1>
                    </div>
                </div>
                <div className="mt-5">
                    <Table
                        rowKey="id"
                        loading={globalLoadingComponent}
                        columns={columns}
                        dataSource={allCompanies}
                    />
                </div>
            </div>
        </Layout>
    );
}

SponsorshipDashboard.propTypes = {};

export default SponsorshipDashboard;
