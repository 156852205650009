import React from 'react';

export default function TwoFAThirdPartyIcon() {
    return (
        <svg
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_41_991)">
                <path
                    d="M25.2637 2.93945L42.3824 6.74362C43.3345 6.95612 44.0137 7.79987 44.0137 8.77695V29.5832C44.0137 33.7624 41.9241 37.6665 38.447 39.9832L25.2637 48.7728L12.0803 39.9832C8.60117 37.6645 6.51367 33.7624 6.51367 29.5853V8.77695C6.51367 7.79987 7.19284 6.95612 8.14492 6.74362L25.2637 2.93945ZM25.2637 7.2082L10.6803 10.4478V29.5832C10.6803 32.3686 12.072 34.9707 14.3908 36.5165L25.2637 43.7665L36.1366 36.5165C38.4553 34.9707 39.847 32.3707 39.847 29.5853V10.4478L25.2637 7.21029V7.2082ZM34.5387 17.9853L37.4866 20.9311L24.2283 34.1895L15.3887 25.3499L18.3345 22.404L24.2262 28.2957C24.2262 28.2957 30.5114 22.0105 34.5387 17.9832V17.9853Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_41_991">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                        transform="translate(0.263672 0.855957)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
