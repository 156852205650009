import React from "react";

import PropTypes from "prop-types";

import "./generalPreloader.scss";

function GeneralPreloader(props) {
  const { color } = props;

  return (
    <div className="general-preloader">
      <ul>
        {[1, 2, 3].map((line, i) => (
          <li key={i} style={{ backgroundColor: color }}></li>
        ))}
      </ul>
    </div>
  );
}

GeneralPreloader.propTypes = {
  color: PropTypes.string
};

export default GeneralPreloader;
