import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Modal } from 'antd';
import moment from 'moment-timezone';
import * as styles from './index.module.scss';
const { Option } = Select;

export const DisplayTimezoneSelect = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const dispatch = useDispatch();
    const activeTimezone = useSelector(
        (state) => state?.settings?.activeTimezone
    );
    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones.map((timezone) => {
            if (
                timezone.includes('/') &&
                !timezone.toLowerCase().includes('us') &&
                !timezone.toLowerCase().includes('etc')
            ) {
                const splitTimezone = timezone.split('/');
                const region = splitTimezone[0];
                if (!mappedValues[region]) {
                    mappedValues[region] = [];
                    regions.push(region);
                }
                mappedValues[region].push(timezone);
            }
        });
        return regions.map((region) => {
            const options = mappedValues[region].map((timezone) => {
                return (
                    <Option key={timezone}>
                        {`(GMT${moment.tz(timezone).format('Z')}) ` + timezone}
                    </Option>
                );
            });
            return options;
        });
    };

    return (
        <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}
        >
            <div className={styles.timezoneModalContainer}>
                <h2>Change the display timezone</h2>
                <div className={styles.timezoneSelect}>
                    <label>Display all times in</label>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        allowClear={false}
                        value={activeTimezone}
                        onChange={(val) => {
                            dispatch({
                                type: 'SET_ACTIVE_TIMEZONE',
                                payload: val,
                            });
                            setIsModalVisible(false);
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {renderOptions()}
                    </Select>
                    <p>
                        Note: Display time zone won’t change the event time
                        zone.
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export const TimezoneLabel = ({
    setIsModalVisible,
    tZstyle = { textAlign: 'right' },
    allowChange = true,
    showLocalTz = false,
}) => {
    const activeTimezone = useSelector(
        (state) => state?.settings?.activeTimezone
    );
    const dispTimezone = showLocalTz ? moment.tz.guess() : activeTimezone;
    return (
        <p style={tZstyle} className={styles.timezoneText}>
            All times in:{' '}
            {`(GMT${moment.tz(dispTimezone).format('Z')}) ${dispTimezone}`}
            {allowChange && (
                <span onClick={() => setIsModalVisible(true)}>Change</span>
            )}
        </p>
    );
};
