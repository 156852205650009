import * as React from 'react';

const ZoomIcon = (props) => {
    return (
        <div
            className="icon-wrapper"
            style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '5px',
                background: props.active ? '#000' : '#ffff',
            }}
        >
            <svg
                width={18}
                height={19}
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                >
                    <path d="M9 .5a9 9 0 110 18 9 9 0 010-18z" fill="#E5E5E4" />
                    <path
                        d="M9 .676a8.825 8.825 0 110 17.65A8.825 8.825 0 019 .675z"
                        fill="#fff"
                    />
                    <path
                        d="M8.999 1.382a8.117 8.117 0 110 16.234 8.117 8.117 0 010-16.234z"
                        fill="#4A8CFF"
                    />
                    <path
                        d="M3.705 6.956v3.815a1.561 1.561 0 001.567 1.553h5.56a.284.284 0 00.286-.282V8.228A1.561 1.561 0 009.55 6.674H3.99a.284.284 0 00-.286.282zm7.767 1.488l2.295-1.677c.2-.165.354-.123.354.176v5.113c0 .34-.189.299-.354.175l-2.295-1.673V8.444z"
                        fill="#fff"
                    />
            </svg>
        </div>
    );
};

export default ZoomIcon;
