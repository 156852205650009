import React, { useState } from 'react';

import RadioBox from "components/app/common/radioBox";

import "./blockPrivate.scss";
import QueryBuilder from '../../common/queryBuilder/queryBuilder';

function BlockPrivate(props) {

    const { privateType, onChangePrivateType, accessCriteriaValue, onChangeAccessCriteria, accessCriteriaFields } = props

    return (
        <>
            <RadioBox
                label="Visible to everyone"
                name="isPrivateType"
                value={privateType}
                onChange={(e) => {
                    onChangePrivateType(false)
                }}
                checked={privateType === false}
                doesValueNeedParsing={false}
            />
            <RadioBox
                label="Visible only to specific people"
                description="Make this content block visible only to people meeting the following rules, where:"
                name="isPrivateType"
                value={privateType}
                checked={privateType === true}
                onChange={(e) => {
                    onChangePrivateType(true)
                }}
                doesValueNeedParsing={false}
            >
                {accessCriteriaFields &&
                    <QueryBuilder fields={accessCriteriaFields} value={accessCriteriaValue} onChange={(value) => onChangeAccessCriteria(value)} />
                }
            </RadioBox>
        </>
    );
}

BlockPrivate.propTypes = {};

export default BlockPrivate;
