import React, { useState, useEffect } from 'react';

import { generateOtp, getAuthCode } from 'apis/rest/Authentication';
import loginValidationCriteria from 'components/app/utils/validators/loginValidator';
import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import Alert from 'components/app/common/alert';
import { maskEmail } from 'components/app/utils/functions/common';
import OTPInput from 'otp-input-react';

import '../login.scss';
import '../otp/otp.scss';

function LoginWithOAuth() {
    let logoSource = '/assets/gevme-new-logo.png';
    const parsedUrl = new URL(document.location).searchParams;

    const state = parsedUrl.get('state'),
        redirectUri = parsedUrl.get('redirectUri'),
        responseType = parsedUrl.get('responseType'),
        clientId = parsedUrl.get('clientId');

    const [email, setEmail] = useState('');
    const [isBusy, setIsBusy] = useState(false);

    const [otp, setOtp] = useState('');
    const [inputDisabled, setInputDisabled] = useState(false);

    const [showOtpView, setOtpView] = useState(false);

    const { alert, showAlert } = useAlert();

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input: { email },
        validationCriteria: loginValidationCriteria,
    });

    const login = async (data, validate = true) => {
        let _hasErrors = false;

        if (validate) {
            const formErrors = validateInput();

            _hasErrors = hasErrors(formErrors);
        }

        if (!_hasErrors) {
            setIsBusy(true);

            const response = await generateOtp(data);
            const responseJson = await response.json();
            setOtpView(true);

            if (!responseJson.status) showAlert(responseJson.message, 'error');
        }
    };
    const submitEmail = async (e) => {
        e.preventDefault();
        await login({ email, sms: false });
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOtpChange = (e) => {
        try {
            setOtp(e);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOtpUpdate = async () => {
        try {
            setInputDisabled(true);

            if (!state) throw new Error('Invalid state');

            if (!redirectUri) throw new Error('Invalid redirect url');

            if (!responseType) throw new Error('Invalid response type');

            if (!clientId) throw new Error('Invalid clientId');

            const { code, status, message } = await getAuthCode({
                code: otp,
                email,
                responseType,
                redirectUri,
                clientId,
            });
            if (status) {
                const redirectUrl = `${redirectUri}?state=${state}&code=${code}`;
                window.location = redirectUrl;
            } else throw new Error(message);
        } catch (error) {
            showAlert(error.message, 'error');
        } finally {
            setInputDisabled(false);
        }
    };

    useEffect(() => {
        if (otp.length === 6) {
            handleOtpUpdate();
        }
    }, [otp]);

    const inputStyles = {
        fontSize: '2.2em',
        fontWeight: 'bold',
        width: '45px',
        height: '60px',
    };

    return (
        <>
            <Alert alert={alert}></Alert>
            {!showOtpView ? (
                <div className="login">
                    <div>
                        <form className="login__box" onSubmit={submitEmail}>
                            <img
                                src={logoSource}
                                alt="logo"
                                className="login__logo"
                            />

                            <h1>Log in</h1>

                            <p>Type your email address below to proceed.</p>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    className="form-control"
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    autoComplete="off"
                                />
                                {renderFieldError('email')}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={isBusy ? true : false}
                            >
                                {isBusy ? (
                                    <span>
                                        <img
                                            src="/assets/icon_spinner.gif"
                                            alt=""
                                        />
                                        Please wait...
                                    </span>
                                ) : (
                                    'Next'
                                )}
                            </button>
                        </form>
                        <br />
                    </div>
                </div>
            ) : (
                <div className="otp">
                    <div>
                        <div className="otp__box">
                            <img src={'/assets/gevme-new-logo.png'} alt="" />

                            <img src="/assets/icon_email_otp.svg" alt="" />
                            <h1>Check your email for your OTP</h1>

                            <p>
                                To confirm your email address, we've sent an
                                One-Time Password to {email || ''}
                                .
                                <br />
                                Check your email and key it below to proceed.
                            </p>

                            <OTPInput
                                inputStyles={inputStyles}
                                value={otp}
                                onChange={handleOtpChange}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={inputDisabled}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default LoginWithOAuth;
