import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { omit } from 'lodash';
import useFileUpload from 'components/app/utils/hooks/useFileUpload';
import PropTypes from 'prop-types';
import { produce } from 'immer';
import { stringToBoolean } from 'components/app/utils/functions/common';
import emptyInput from 'components/app/utils/dataList/emptyInput';
import showcaseblockValidationCriteria from 'components/app/utils/validators/showcaseblockValidator';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import useAlertBar from 'components/app/utils/hooks/useAlertBar';

import UPDATE_SHOWCASEBLOCK from 'apis/graphql/UpdateShowcaseblock';
import SHOWCASEBLOCK from 'apis/graphql/Showcaseblock';
import USER_ALL_FIELDS from 'apis/graphql/crowdflow/AllFieldsUnderProject';
import SHOWCASE from 'apis/graphql/Showcase';
import * as ls from 'local-storage';

import {
    updateBlockCriteria,
    getBlockCriteria,
    deleteBlockCriteria,
} from 'apis/rest/AccessControlCriteria';

import GeneralPreloader from 'components/app/common/preloader/generalPreloader';

import Post from './post';
import ImageGallery from './imageGallery';
import Pdf from './pdf';
import Video from './video';
import SocialMedia from './socialMedia';
import { useSelector } from 'react-redux';

function BlockEditor(props) {
    const [loading, setLoading] = useState(true);
    const [accessCriteriaFields, setAccessCriteriaFields] = useState(null);
    const [blockPrevents, setBlockPrevents] = useState({
        preventEditTitle: false,
        preventEditSize: false,
        preventEditName: false,
    });
    const [permission, setPermission] = useState(null);
    const { showcaseblockId, type } = props.updatePayload;
    const { setModalVisibility, refetchShowcase, updateBlock } = props;
    const { projectId } = useParams();
    const { showcaseId } = useParams();
    const { showAlertBar } = useAlertBar();
    const user = useSelector(state => state.user)

    const {} = useQuery(USER_ALL_FIELDS, {
        variables: { projectId },
        context: { clientName: 'crowdflow' },
        onCompleted: (data) => {
            setAccessCriteriaFields(data);
        },
    });

    const [updateShowcaseblock] = useMutation(UPDATE_SHOWCASEBLOCK, {
        onCompleted: (data) => {
            showAlertBar('Content block updated!', 'success');
            refetchShowcase();
        },
        onError: (err) => {
            showAlertBar(err.message, 'error');
        },
    });

    const { data } = useQuery(SHOWCASEBLOCK, {
        variables: { id: showcaseblockId },
        onError: (err) => {
            showAlertBar(err.message, 'error');
        },
    });

    const [input, setInput] = useState(emptyInput);

    const [privateType, setPrivateType] = useState(false);
    const [accessCriteriaValue, setAccessCriteriaValue] = useState(null);

    const [currUploads, setCurrUploads] = useState({
        pdf: null,
        image: [],
    });

    const { renderFieldError, validateInput, hasErrors } = useFormValidator({
        input,
        validationCriteria: showcaseblockValidationCriteria,
    });

    const { prepareFileUpload, handleFileUpload, handleFileDelete } =
        useFileUpload(input, setInput);

    const handleGetBlockCriteria = async () => {
        try {
            const response = await getBlockCriteria(projectId, showcaseblockId);
            setAccessCriteriaValue(response.criteria);
            const hasCriteria =
                response.criteria == null ||
                Object.keys(response.criteria).length === 0
                    ? false
                    : true;
            setPrivateType(hasCriteria);
        } catch (error) {
            setAccessCriteriaValue(null);
            console.error('error ', error);
        }
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (data) {
            handleGetBlockCriteria();
            const showcaseblock = data.showcaseblock;
            setInput({
                title: showcaseblock.title,
                type: showcaseblock.type,
                name: showcaseblock.name,
                textContent: showcaseblock.textContent,
                contentPadding: showcaseblock.contentPadding,
                pdf: showcaseblock.pdf && showcaseblock.pdf.id,
                url: showcaseblock.url,
                showContentMobile: showcaseblock.showContentMobile,
                socialLinks: showcaseblock.socialLinks,
                // image: extractPropFromArr(showcaseblock.image, "id"),
                largeTile: showcaseblock.largeTile,
                // permission: permission
            });

            setCurrUploads({
                image: showcaseblock.image,
                pdf: showcaseblock.pdf,
            });
        }
    }, [data]);

    const currentThumbnail =
        currUploads.image.length > 0 ? currUploads.image[0].url : '';

    const handleDeleteBlockCriteria = async () => {
        await deleteBlockCriteria(projectId, showcaseblockId);
    };
    /**
     * handles submission of form
     * @param {object} e event object
     */
    const onSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInput();

        if (!hasErrors(formErrors)) {
            let data = omit(input, ['image']);
            const dataWithFileIds = await handleFileUpload('strapi', data);

            if (dataWithFileIds ? dataWithFileIds : true) {
                updateShowcaseblock({
                    variables: {
                        input: {
                            data: dataWithFileIds
                                ? dataWithFileIds
                                : { ...data, ...{ isPublic: !privateType } },
                            where: { id: showcaseblockId },
                        },
                    },
                    refetchQueries: [
                        {
                            query: SHOWCASEBLOCK,
                            variables: { id: showcaseblockId },
                        },
                    ],
                });

                setInput(emptyInput);
                setModalVisibility(false);

                // reloadIframe();
            }
            if (privateType) {
                await updateBlockCriteria(
                    projectId,
                    showcaseblockId,
                    accessCriteriaValue
                );
            } else {
                await handleDeleteBlockCriteria();
            }
        }
    };

    /**
     * handles change to the Rich Text Editor
     * @param {object} value value produced by the editor
     */
    const handleRichTextEditorChange = (rteValue) => {
        let nextState = produce(input, (draftState) => {
            draftState.textContent = rteValue;
        });

        setInput(nextState);
    };

    /**
     * gets rich text editor value from child component
     * @param {string} rteValue rich text value
     */
    const getRichTextEditorValue = (rteValue) => {
        handleRichTextEditorChange(rteValue);
    };

    /**
     * handles change input field
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let type = e.target.type;
        let isChecked = e.target.checked;

        let nextState;

        switch (type) {
            case 'text':
            case 'textarea':
            case 'select-one':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            case 'checkbox':
                nextState = produce(input, (draftState) => {
                    draftState[name] = isChecked;
                });
                break;
            case 'radio':
                nextState = produce(input, (draftState) => {
                    draftState[name] = stringToBoolean(value);
                });
                break;
            default:
                break;
        }
        setInput(nextState);
    };
    const handleSocialMediaChange = (socialLinks) => {
        let nextState = produce(input, (draftState) => {
            draftState.socialLinks = socialLinks;
        });
        setInput(nextState);
    };
    const ContentTypeDropDown = ({ type }) => {
        const typeMap = {
            Image: { name: 'Image Gallery', url: '/assets/icon_image.svg' },
            Pdf: { name: 'PDF', url: '/assets/icon_pdf.svg' },
            Post: { name: 'Rich Content', url: '/assets/icon_rich_text.svg' },
            Video: { name: 'Video', url: '/assets/icon_video.svg' },
            SocialLink: {
                name: 'Social Media',
                url: '/assets/icon_rich_text.svg',
            },
        };
        const dropDownChanged = async (eventKey) => {
            updateShowcaseblock({
                variables: {
                    input: {
                        data: { type: eventKey },
                        where: { id: showcaseblockId },
                    },
                },
            });
            updateBlock(showcaseblockId, eventKey);
        };

        useEffect(() => {
            let dropBtn = document.getElementById('contentTypeDrpBtn');
            dropBtn.innerHTML =
                `<img src="${typeMap[type].url}" alt="" />` +
                dropBtn.innerHTML +
                `<svg style="margin-left:70px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>`;
        }, [type]);
        return (
            <DropdownButton
                className="contentChange"
                style={{
                    marginBottom: '30px',
                    marginLeft: '-7px',
                }}
                id="contentTypeDrpBtn"
                title={typeMap[type].name}
                variant="light"
                onSelect={dropDownChanged}
                size="lg"
            >
                <Dropdown.Item eventKey={'Post'}>
                    <img src="/assets/icon_rich_text.svg" alt="" />
                    Rich Content
                </Dropdown.Item>
                <Dropdown.Item eventKey={'Image'}>
                    <img src="/assets/icon_image.svg" alt="" />
                    Image Gallery
                </Dropdown.Item>
                <Dropdown.Item eventKey={'Pdf'}>
                    <img src="/assets/icon_pdf.svg" alt="" />
                    PDF
                </Dropdown.Item>
                <Dropdown.Item eventKey={'Video'}>
                    <img src="/assets/icon_video.svg" alt="" />
                    Video
                </Dropdown.Item>
                <Dropdown.Item eventKey={'SocialLink'}>
                    <img src="/assets/icon_rich_text.svg" alt="" />
                    Social Media
                </Dropdown.Item>
            </DropdownButton>
        );
    };

    const {} = useQuery(SHOWCASE, {
        variables: { id: showcaseId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { showcase } = data;
            if (user.roleId === 4) {
                let blockPrevents = {
                    preventEditTitle: showcase.preventEditTitle,
                    preventEditSize: showcase.preventEditSize,
                    preventEditName: showcase.preventEditName,
                };
                setBlockPrevents(blockPrevents);
                if (showcase.permission) {
                    setPermission(showcase.permission);
                }
            }
        },
    });

    const blockEditorProps = {
        handleInputChange,
        prepareFileUpload,
        handleFileDelete,
        currUploads,
        input,
        renderFieldError,
        validateInput,
        accessCriteriaValue,
        privateType,
        projectId,
        accessCriteriaFields,
        permission,
        ...blockPrevents,
    };

    let isContentTypeDropDown = blockEditorProps?.input?.name
        ? blockEditorProps?.input?.name.match(
              /logo|thumbnail|image|write-up|links|link|video/
          )
        : null;

    return (
        <div className="block-creator">
            <div className="row">
                <div className="col-12">
                    <div className="block-creator__body">
                        <img
                            src="/assets/icon_close.svg"
                            alt=""
                            className="icon-close"
                            onClick={() => setModalVisibility(false)}
                        />

                        {!loading && (
                            <>
                                {!isContentTypeDropDown && (
                                    <ContentTypeDropDown
                                        type={type}
                                        blockName={
                                            blockEditorProps?.input?.name
                                        }
                                    />
                                )}

                                {type === 'Image' && (
                                    <>
                                        <ImageGallery
                                            onChangeAccessCriteria={(e) =>
                                                setAccessCriteriaValue(e)
                                            }
                                            onChangePrivateType={(e) =>
                                                setPrivateType(e)
                                            }
                                            {...blockEditorProps}
                                        ></ImageGallery>
                                    </>
                                )}

                                {type === 'Pdf' && (
                                    <>
                                        <Pdf
                                            onChangeAccessCriteria={(e) =>
                                                setAccessCriteriaValue(e)
                                            }
                                            onChangePrivateType={(e) =>
                                                setPrivateType(e)
                                            }
                                            {...blockEditorProps}
                                        ></Pdf>
                                    </>
                                )}

                                {type === 'Video' && (
                                    <>
                                        <Video
                                            onChangeAccessCriteria={(e) =>
                                                setAccessCriteriaValue(e)
                                            }
                                            onChangePrivateType={(e) =>
                                                setPrivateType(e)
                                            }
                                            {...blockEditorProps}
                                        ></Video>
                                    </>
                                )}

                                {type === 'Post' && (
                                    <>
                                        {/* <h2 className="block-creator__title">
                                            Rich Content
                                        </h2> */}

                                        <Post
                                            onChangeAccessCriteria={(e) =>
                                                setAccessCriteriaValue(e)
                                            }
                                            onChangePrivateType={(e) =>
                                                setPrivateType(e)
                                            }
                                            getRichTextEditorValue={
                                                getRichTextEditorValue
                                            }
                                            {...blockEditorProps}
                                            permissions={
                                                props.permissions
                                                    ? props.permissions
                                                    : null
                                            }
                                        ></Post>
                                    </>
                                )}
                                {type === 'SocialLink' && (
                                    <>
                                        {blockEditorProps.input.socialLinks && (
                                            <SocialMedia
                                                getSocialMediaList={(
                                                    socialLinks
                                                ) =>
                                                    handleSocialMediaChange(
                                                        socialLinks
                                                    )
                                                }
                                                {...blockEditorProps}
                                            />
                                        )}
                                        {!blockEditorProps.input
                                            .socialLinks && (
                                            <GeneralPreloader />
                                        )}
                                    </>
                                )}

                                <div className="grouper grouper--right mt-3">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setModalVisibility(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <input
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={onSubmit}
                                        value="Submit"
                                    />
                                </div>
                            </>
                        )}

                        {loading && <GeneralPreloader></GeneralPreloader>}
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockEditor.propTypes = {
    reloadIframe: PropTypes.func,
    setModalVisibility: PropTypes.func.isRequired,
};

export default withRouter(BlockEditor);
