import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCalendlyEventListener, PopupModal } from 'react-calendly';

import { getCompanyDetails } from 'apis/rest/livestream/GetSettings';
import { store } from 'redux/store';
import { store as venueStore } from 'Apps/VenueBuilder/redux/store';
import { setCompanyDetails } from 'redux/actions/projects/projectSettings';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

export default function ChatWithSales({ className }) {
    const { companyId } = useParams();
    const companyDetails = useSelector((state) => state?.projects?.company);
    const user = useSelector((state) => state.user);
    // const calendlyDetails = companyDetails?.calendlyDetails || null;
    // const disableChatWithSales = !!(
    //     calendlyDetails !== null &&
    //     (calendlyDetails.length || Object.keys(calendlyDetails).length)
    // );
    const [scheduledData, setScheduledData] = useState(null);
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const calendlyConfig = {
        url: process.env.REACT_APP_CALENDLY_URL,
        pageSettings: {
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '006bff',
            textColor: '4d5055',
        },
        prefill: {
            firstName: user?.firstname || '',
            lastName: user?.lastname || '',
            name: `${user?.firstname} ${user?.lastname}`,
            email: user?.email || '',
            customAnswers: {
                a2: companyId || '',
            },
        },
        utm: {
            utmCampaign: '',
            utmContent: JSON.stringify({
                userEmail: user?.email || '',
                companyId,
            }),
            utmMedium: '',
            utmSource: '',
            utmTerm: '',
        },
    };

    function handleChatWithSales() {
        if (!user?.isVerified) {
            return openNotification('error', {
                message: 'Please verify your email and try again!',
            });
        }
        setShowCalendlyModal(true);
    }

    function handleModalClose() {
        try {
            setShowCalendlyModal(false);
            const newCompanyDetails = {
                ...companyDetails,
                // calendlyDetails: scheduledData || calendlyDetails,
            };
            store.dispatch(setCompanyDetails(newCompanyDetails));
            venueStore.dispatch({
                type: 'SET_COMPANY_DETAILS',
                payload: newCompanyDetails,
            });
            getCompanyDetails(companyId);
        } catch (e) {
            console.error(e);
        }
    }

    useCalendlyEventListener({
        onEventScheduled: (e) => setScheduledData(e.data),
    });

    // Removed for now as per Jon's feedback
    // if (disableChatWithSales) {
    //     return null;
    // }

    return (
        <>
            <button
                className={`tw-px-4 tw-py-[7px] tw-font-openSans tw-text-xssm tw-font-bold tw-text-center tw-text-white tw-bg-black tw-rounded tw-border-0 ${className}`}
                type="button"
                onClick={handleChatWithSales}
            >
                Chat with sales
            </button>
            {user?.isVerified ? (
                <PopupModal
                    url={calendlyConfig.url}
                    pageSettings={calendlyConfig.pageSettings}
                    prefill={calendlyConfig.prefill}
                    utm={calendlyConfig.utm}
                    onModalClose={handleModalClose}
                    open={showCalendlyModal}
                    rootElement={document.getElementById('root')}
                />
            ) : null}
        </>
    );
}

ChatWithSales.propTypes = {
    className: PropTypes.string,
};

ChatWithSales.defaultProps = {
    className: '',
};
