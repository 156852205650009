import React from 'react';

export default function CloseIcon() {
    return (
        <svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.8804 5.84061L8.5653 3.16195C8.68288 3.04437 8.74893 2.8849 8.74893 2.71863C8.74893 2.55235 8.68288 2.39288 8.5653 2.2753C8.44773 2.15773 8.28826 2.09167 8.12198 2.09167C7.95571 2.09167 7.79624 2.15773 7.67866 2.2753L5 4.96021L2.32134 2.2753C2.20376 2.15773 2.04429 2.09167 1.87802 2.09167C1.71174 2.09167 1.55227 2.15773 1.4347 2.2753C1.31712 2.39288 1.25107 2.55235 1.25107 2.71863C1.25107 2.8849 1.31712 3.04437 1.4347 3.16195L4.1196 5.84061L1.4347 8.51927C1.37617 8.57732 1.32972 8.64637 1.29802 8.72246C1.26632 8.79855 1.25 8.88016 1.25 8.96259C1.25 9.04502 1.26632 9.12663 1.29802 9.20272C1.32972 9.27881 1.37617 9.34787 1.4347 9.40591C1.49274 9.46444 1.5618 9.51089 1.63789 9.54259C1.71398 9.57429 1.79559 9.59061 1.87802 9.59061C1.96044 9.59061 2.04206 9.57429 2.11815 9.54259C2.19423 9.51089 2.26329 9.46444 2.32134 9.40591L5 6.72101L7.67866 9.40591C7.73671 9.46444 7.80577 9.51089 7.88185 9.54259C7.95794 9.57429 8.03956 9.59061 8.12198 9.59061C8.20441 9.59061 8.28602 9.57429 8.36211 9.54259C8.4382 9.51089 8.50726 9.46444 8.5653 9.40591C8.62383 9.34787 8.67028 9.27881 8.70198 9.20272C8.73368 9.12663 8.75 9.04502 8.75 8.96259C8.75 8.88016 8.73368 8.79855 8.70198 8.72246C8.67028 8.64637 8.62383 8.57732 8.5653 8.51927L5.8804 5.84061Z"
                fill="black"
            />
        </svg>
    );
}
