import React from "react";
import { withRouter } from "react-router-dom";

import "./headerAdmin.scss";

function HeaderAdmin() {
  return (
    <div className="header-admin">
      <div className="row">
        <div className="col-2 col-md-6">
          <div className="header__inner"></div>
        </div>
        <div className="col-10 col-md-6">
          <div className="header__inner header-admin__title">
            <span>Super Admin</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HeaderAdmin);
