import React from 'react';

export default function UploadIcon() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 0.0488281C9.26323 0.0878789 6.17003 1.39104 3.88112 3.67995C1.59221 5.96886 0.289051 9.06206 0.25 12.2988C0.289051 15.5356 1.59221 18.6288 3.88112 20.9177C6.17003 23.2066 9.26323 24.5098 12.5 24.5488C15.7368 24.5098 18.83 23.2066 21.1189 20.9177C23.4078 18.6288 24.7109 15.5356 24.75 12.2988C24.7109 9.06206 23.4078 5.96886 21.1189 3.67995C18.83 1.39104 15.7368 0.0878789 12.5 0.0488281ZM19.5 13.1738H13.375V19.2988H11.625V13.1738H5.5V11.4238H11.625V5.29883H13.375V11.4238H19.5V13.1738Z"
                fill="#027BFF"
            />
        </svg>
    );
}
