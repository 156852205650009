import * as React from 'react';

const SponsorsBlockIcon = (props) => {
    return (
        <svg
          width={48}
          height={48}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width={48} height={48} rx={4} fill="#E1E7FA" />
          <rect x={8} y={26} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={8} y={15} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={19} y={26} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={19} y={15} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={30} y={26} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={30} y={15} width={10} height={10} rx={2} fill="#7A829D" />
          <rect x={14} y={8} width={20} height={5} rx={2} fill="#B9C1DB" />
        </svg>
    )
};

export default SponsorsBlockIcon;
