import React from "react";
import { Link, withRouter } from "react-router-dom";

import * as ls from "local-storage";

import "./headerApp.scss";
import { useSelector } from "react-redux";

function HeaderApp(props) {
  const {
    location: { pathname }
  } = props;

  const user = useSelector(state => state.user)
  const project = ls.get("project");

  const getAppName = () => {
    switch (true) {
      case pathname.includes("showcase"):
        return "showcase";
      case pathname.includes("leads"):
        return "leads";
      default:
        break;
    }
  };

  return (
    <div className="header-app">
      <div className="row">
        <div className="col-2 col-md-6">
          <div className="header__inner">
            <Link to="/" className="header__back">
              <img src="/assets/icon_back_arrow.svg" alt="" />
            </Link>
            <h2 className="header__title">
              {user && user !== "undefined" && project.name}
            </h2>
          </div>
        </div>
        <div className="col-10 col-md-6">
          <div className="header__inner header-app__title">
            <img
              src={`/assets/apps/${getAppName()}/icon_${getAppName()}_small.svg`}
              alt="app logo"
            />
            <span>{getAppName()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HeaderApp);
