import * as React from 'react';

export default function LinkSimpleIcon(props) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_20435_4689)">
                <path
                    d="M5.625 9H12.375"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.3125 12.375H4.5C3.60489 12.375 2.74645 12.0194 2.11351 11.3865C1.48058 10.7536 1.125 9.89511 1.125 9C1.125 8.10489 1.48058 7.24645 2.11351 6.61351C2.74645 5.98058 3.60489 5.625 4.5 5.625H7.3125"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.6875 5.625H13.5C14.3951 5.625 15.2536 5.98058 15.8865 6.61351C16.5194 7.24645 16.875 8.10489 16.875 9C16.875 9.89511 16.5194 10.7536 15.8865 11.3865C15.2536 12.0194 14.3951 12.375 13.5 12.375H10.6875"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_20435_4689">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
