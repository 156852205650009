import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * gets the current user based from authorisation token
 */
export async function getSponsor(companyId) {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/users/profile?exhibitorCompanyProfileId=${companyId}`,
        {
            method: 'GET',
        }
    );

    return await response.json();
}

export async function refreshSponsorTempToken(token) {
    if (!ls.get('sponsorAuth')) throw new Error('invalid auth');

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_V2}/sponsor/auth/refresh`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        }
    );

    return await response.json();
}
