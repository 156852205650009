import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';

import { produce } from 'immer';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { uploadFile } from 'apis/rest/strapi/UploadFile';

import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import personInfoValidationCriteria from 'components/app/utils/validators/personInfoValidator';

import countryList from 'components/app/utils/dataList/countryList';

import FileUploader from 'components/app/common/fileUploader';

import UPDATE_PERSON from 'apis/graphql/UpdatePerson';
import PERSON from 'apis/graphql/Person';

import './personEditor.scss';

function PersonEditor(props) {
    const {
        match: { params },
    } = props;

    const personId = params.personId;

    const {
        setModalVisibility,
        reloadIframe,
        updatePayload: { person, type },
        showAlert,
    } = props;

    const [updatePerson] = useMutation(UPDATE_PERSON, {
        onCompleted: (data) => {
            showAlert('Profile updated!', 'success');
        },
        onError: (err) => {
            showAlert(err.message, 'error');
        },
    });

    const [input, setInput] = useState({});
    const [currUploads, setCurrUploads] = useState({});

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input,
        validationCriteria: personInfoValidationCriteria,
    });

    useEffect(() => {
        if (person) {
            setInput({
                companyName: person.companyName,
                address: person.address || '',
                website: person.website || '',
                phone: person.phone || '',
                email: person.email || '',
                booth: person.booth || '',

                linkedin: person.linkedin || '',
                facebook: person.facebook || '',
                twitter: person.twitter || '',

                contactPersonFirstName: person.contactPersonFirstName || '',
                contactPersonLastName: person.contactPersonLastName || '',
                contactPersonJobTitle: person.contactPersonJobTitle || '',
                contactPersonEmail: person.contactPersonEmail || '',
                contactPersonMobile: person.contactPersonMobile || '',
                contactPersonWorkPhone: person.contactPersonWorkPhone || '',
                contactPersonLinkedin: person.contactPersonLinkedin || '',
                contactPersonTwitter: person.contactPersonTwitter || '',

                locationCity: person.locationCity || '',
                locationCountry: person.locationCountry || 'Singapore',
            });

            setCurrUploads({
                logo: person.logo,
            });
        }
    }, [person]);

    const onSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateInput();

        if (!hasErrors(formErrors)) {
            updatePerson({
                variables: {
                    input: {
                        data: input,
                        where: {
                            id: personId,
                        },
                    },
                },
                refetchQueries: [
                    { query: PERSON, variables: { id: personId } },
                ],
            });

            reloadIframe();
            setModalVisibility(false);
        } else {
            showAlert(
                'Please check the form for wrong / missing input',
                'error'
            );
        }
    };

    /**
     * handles change input field
     * @param {object} e event object
     */
    const handleInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let type = e.target.type;

        let nextState;

        switch (type) {
            case 'text':
            case 'textarea':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            case 'select-one':
                nextState = produce(input, (draftState) => {
                    draftState[name] = value;
                });
                break;
            default:
                break;
        }

        setInput(nextState);
    };

    /**
     * sets `uploadId` coming from `uploadFile()` to input state
     * @param {object} e event object
     */
    const handleFileUpload = async (files, fileType) => {
        const uploadId = await uploadFile(files, 'person', personId);
        let nextState = produce(input, (draftState) => {
            draftState[fileType] = uploadId;
        });

        setInput(nextState);
    };

    const { logo } = currUploads;

    if (person && !isEmpty(currUploads)) {
        return (
            <div className="person-editor">
                <img
                    src="/assets/icon_close.svg"
                    alt=""
                    className="icon-close"
                    onClick={() => setModalVisibility(false)}
                />
                <form onSubmit={onSubmit}>
                    <div>
                        <div>
                            {type === 'logo' && (
                                <div className="person-editor__logo form-group">
                                    <h2>Company Logo</h2>

                                    <p>
                                        Drag your logo below or click box to
                                        select a file.
                                    </p>

                                    <div className="person-editor__logo-wrap">
                                        <FileUploader
                                            handleFileUpload={handleFileUpload}
                                            fileType="logo"
                                            currentFile={logo}
                                        ></FileUploader>
                                    </div>

                                    <p className="person-editor__logo-note">
                                        Please note that the logo is pulled from
                                        your company profile, which is used
                                        across all your showcases. Editing the
                                        logo here will therefore affect the
                                        other showcases where it’s also being
                                        used and displayed.
                                    </p>
                                </div>
                            )}

                            {type === 'companyProfile' && (
                                <>
                                    <h2 className="mb-4">Company Profile</h2>

                                    <h4 className="mb-4">
                                        <FontAwesomeIcon
                                            icon="info-circle"
                                            className="mr-1"
                                            color="#777777"
                                        />{' '}
                                        General Information
                                    </h4>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Company Name{' '}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={input.companyName}
                                            name="companyName"
                                            className="form-control"
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            maxLength={200}
                                        />
                                        {renderFieldError('companyName')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Booth No.
                                        </label>
                                        <input
                                            type="text"
                                            value={input.booth}
                                            name="booth"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={20}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Website{' '}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={input.website}
                                            name="website"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('website')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            LinkedIn URL
                                        </label>
                                        <input
                                            type="text"
                                            value={input.linkedin}
                                            name="linkedin"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('linkedin')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Facebook URL
                                        </label>
                                        <input
                                            type="text"
                                            value={input.facebook}
                                            name="facebook"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('facebook')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Twitter URL
                                        </label>
                                        <input
                                            type="text"
                                            value={input.twitter}
                                            name="twitter"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('twitter')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Phone{' '}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={input.phone}
                                            name="phone"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('phone')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Email{' '}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={input.email}
                                            name="email"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('email')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            value={input.address}
                                            name="address"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            value={input.locationCity}
                                            name="locationCity"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="form-group  mb-5">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Country{' '}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <select
                                            className="form-control"
                                            name="locationCountry"
                                            onChange={handleInputChange}
                                            value={input.locationCountry}
                                        >
                                            {countryList.map(
                                                (country, index) => {
                                                    return (
                                                        <option
                                                            value={country}
                                                            key={index}
                                                        >
                                                            {country}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>

                                    <h4 className="mb-4">
                                        <FontAwesomeIcon
                                            icon="user"
                                            className="mr-1"
                                            color="#777777"
                                        />{' '}
                                        Contact Person
                                    </h4>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonFirstName}
                                            name="contactPersonFirstName"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonLastName}
                                            name="contactPersonLastName"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Job Title
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonJobTitle}
                                            name="contactPersonJobTitle"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonEmail}
                                            name="contactPersonEmail"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={200}
                                        />
                                        {renderFieldError('contactPersonEmail')}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Company Phone
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonWorkPhone}
                                            name="contactPersonWorkPhone"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={50}
                                        />
                                        {renderFieldError(
                                            'contactPersonWorkPhone'
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Mobile Phone
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonMobile}
                                            name="contactPersonMobile"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={50}
                                        />
                                        {renderFieldError(
                                            'contactPersonMobile'
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            LinkedIn URL
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonLinkedin}
                                            name="contactPersonLinkedin"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={50}
                                        />
                                        {renderFieldError(
                                            'contactPersonLinkedin'
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            Twitter URL
                                        </label>
                                        <input
                                            type="text"
                                            value={input.contactPersonTwitter}
                                            name="contactPersonTwitter"
                                            className="form-control"
                                            onChange={handleInputChange}
                                            maxLength={50}
                                        />
                                        {renderFieldError(
                                            'contactPersonTwitter'
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="grouper grouper--right">
                        <button
                            className="btn btn-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalVisibility(false);
                            }}
                        >
                            Cancel
                        </button>
                        <input className="btn btn-primary" type="submit" />
                    </div>
                </form>
            </div>
        );
    } else {
        return <p>Loading form...</p>;
    }
}

export default withRouter(PersonEditor);
