import * as React from 'react';

const SuccessCircleIcon = (props) => (
    <svg
        width={57}
        height={56}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M38.125 22.75 25.285 35l-6.41-6.125"
            stroke="#5C9500"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.5 49c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21Z"
            stroke="#5C9500"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SuccessCircleIcon;
