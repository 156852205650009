import React, { useState } from 'react';
import { Drawer } from 'antd';
import { getNameInitials } from 'components/app/utils/functions/common';
import Menu from 'components/app/components/admin/menus/mainMenu';
import LogoutButton from '../../../../components/app/common/assets/svgIcons/LogoutButton';
import { sponsorLogout } from 'apis/rest/Authentication';
import { useHistory } from 'react-router-dom';
import * as ls from 'local-storage';
import Prompt from '../../../../components/app/common/prompt';

export default function SidebarDrawer({ visible, setVisible, user }) {
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const history = useHistory();

    const handleLogout = async () => {
        try {
            const res = await sponsorLogout();
            if (res?.status) {
                ls.clear();
                history.replace('/sponsor/login');
            } else throw res;
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <Drawer
                placement="left"
                className="sidebarDrawer"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={'306px'}
                padding={'10px'}
            >
                <div className="tw-flex tw-flex-col tw-gap-5">
                    {/* //icons */}
                    <div className="tw-flex tw-gap-2">
                        <a href="/sponsor/companyProfiles">
                            <img
                                className="solo"
                                src="/assets/GEVME_LOGO.svg"
                                alt="Gevme Admin"
                            />
                        </a>
                        <span className="tw-font-bold tw-text-normal">
                            Gevme
                        </span>
                    </div>
                    <Menu priActive="sponsorHome" all useGlobalSlider={false} />

                    {/* //footer */}
                    <hr className="tw-w-full tw-bg-divider-gray tw-m-0" />
                    <div className="tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-2 tw-overflow-auto">
                        <div className="tw-flex tw-items-center tw-justify-center tw-font-extrabold tw-text-xs tw-bg-[#e4eaf7] tw-w-[40px] tw-h-[40px] tw-overflow-hidden tw-rounded-full">
                            <span>{getNameInitials(user)}</span>
                        </div>
                        <div className="tw-flex tw-flex-col tw-overflow-auto">
                            <span className="tw-text-sm tw-font-bold">
                                {' '}
                                {user?.firstname + user?.lastname}
                            </span>
                            <span className="tw-text-sm tw-text-text-gray-dark">
                                {' '}
                                {user?.email}
                            </span>
                        </div>
                    </div>
                    <div
                        className="tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-2 tw-text-sm tw-text-text-gray-dark"
                        onClick={() => setShowLogoutConfirm(true)}
                    >
                        <LogoutButton />
                        <span className="tw-text-sm tw-font-semibold">
                            Logout
                        </span>
                    </div>
                </div>
            </Drawer>
            <Prompt
                show={showLogoutConfirm}
                message={`Are you sure you want to Logout?`}
                buttons={['Cancel', 'Proceed to Logout']}
                onHide={() => setShowLogoutConfirm(false)}
                callback={() => handleLogout()}
            />
            <style>{`
            .sidebarDrawer .ant-drawer-body{
            padding: 10px;
            padding-top: 20px;
            }

            .sidebarDrawer .sponsorMenu.ant-menu {
            padding: 0 !important;
            }
            `}</style>
        </>
    );
}
