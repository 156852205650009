import React from 'react';

const CheckedIcon = () => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
            fill="#EFF4FF"
        />
        <path
            d="M16.125 9.75 10.622 15l-2.747-2.625"
            stroke="#1D51C8"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CheckedIcon;
