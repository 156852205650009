import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import useChargebee from 'ComponentsV2/ProjectDashboard/utils/useChargebee';
import Loader from 'components/app/components/venue/Loader';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import TagManager from 'react-gtm-module';

import './billing.scss';

const billingBlocks = [
    {
        title: 'Account Information',
        content: 'Access your account information.',
        key: 'ACCOUNT_DETAILS',
        linkText: 'Manage Account Information',
    },
    {
        title: 'Payment Method',
        content: 'View and update your payment method.',
        key: 'PAYMENT_SOURCES',
        linkText: 'Manage Payment Methods',
    },
    {
        title: 'Billing History',
        content: 'View and download past invoices.',
        key: 'BILLING_HISTORY',
        linkText: 'View Billing History',
    },
    {
        title: 'Subscription',
        key: 'EDIT_SUBSCRIPTION',
        linkText: 'Edit Subscription',
        key2: 'SUBSCRIPTION_CANCELLATION',
        linkText2: 'Cancel Subscription',
    },
];

const Billing = () => {
    const { companyId } = useParams();
    const companyDetails = useSelector((state) => state?.projects?.company);
    const { instance, loading, portals } = useChargebee(companyId);

    const handleBillingLink = (key) => {
        try {
            const cbPortal = instance?.createChargebeePortal();
            const callbacks = {
                subscriptionCancelled: function (data) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'cancelAccount',
                            info: {
                                companyId,
                                data,
                            },
                        },
                    });
                },
            };
            cbPortal.open(callbacks, {
                sectionType: portals[key],
                params: {
                    subscriptionId: companyDetails?.subscriptionId,
                },
            });
        } catch (error) {
            openNotification('error', { message: error.message });
        }
    };

    return (
        <div className="billing-container">
            {loading ? (
                <div className="loader-wrapper">
                    <Loader />
                </div>
            ) : (
                <Row gutter={16}>
                    {billingBlocks.map((block) => (
                        <Col
                            xs={24}
                            sm={24}
                            md={8}
                            lg={8}
                            xl={8}
                            key={block.key}
                            className="mb-2"
                        >
                            <div className="billing-block">
                                <div className="billing-block-title">
                                    {block.title}
                                </div>
                                <div className="billing-block-content">
                                    {block.content}
                                </div>
                                <div
                                    className="billing-block-content-link"
                                    onClick={() => handleBillingLink(block.key)}
                                >
                                    <img
                                        src="/assets/arrow-right-blue.svg"
                                        alt="arrow"
                                    />
                                    {block.linkText}
                                </div>
                                {block.key2 && (
                                    <div
                                        className="billing-block-content-link mt-2"
                                        onClick={() =>
                                            handleBillingLink(block.key2)
                                        }
                                    >
                                        <img
                                            src="/assets/arrow-right-blue.svg"
                                            alt="arrow"
                                        />
                                        {block.linkText2}
                                    </div>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};

export default Billing;
