import getAuthBearer from '../../../utils/getAuthBearer';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function ClearCacheProject(projectId) {
    const authBearer = getAuthBearer();
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API_VIRTUAL}/v1/manager/projects/${projectId}/clear-cache`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
