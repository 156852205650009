import React from 'react';

export default function VideoUploadIcon(props) {
    return (
        <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17 12V2C17 0.9 16.1 0 15 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14H15C16.1 14 17 13.1 17 12ZM6 11V3L12 7L6 11Z"
                fill="black"
            />
        </svg>
    );
}
