import { gql } from "apollo-boost";

const USER_ALL_FIELDS = gql`
  query AllFieldsUnderProject($projectId: String!) {
    AllFieldsUnderProject(projectId: $projectId) {
      fields {
        field
        label
      }
    }
  }
`;

export default USER_ALL_FIELDS;
