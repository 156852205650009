export function setLivePagLabels(payload) {
    return { type: 'SET_PAGE_LABELS', payload };
}

export function addLivePagLabel(payload) {
    return { type: 'ADD_PAGE_LABEL', payload };
}

export function updateLivePagLabel(payload) {
    return { type: 'UPDATE_PAGE_LABEL', payload };
}

export function deleteLivePagLabel(payload) {
    return { type: 'DELETE_PAGE_LABEL', payload };
}

export function filterLivePagLabel(payload) {
    return { type: 'SET_PAGE_FILTER', payload };
}

export function resetFilter() {
    return { type: 'RESET_PAGE_FILTER' };
}

export function updatePageCollection(payload) {
    return { type: 'UPDATE_PAGE_COLLECTION', payload };
}

export function getAgendaSessions(payload) {
    return { type: 'GET_AGENDA_SESSIONS', payload };
}

export function addSessionDate(payload) {
    return { type: 'ADD_SESSION_DATE', payload };
}

export function deleteSessionDate(payload) {
    return { type: 'DELETE_SESSION_DATE', payload };
}

export function editAgendaSession(payload) {
    return { type: 'EDIT_AGENDA_SESSION', payload };
}

export function deleteAgendaSession(payload) {
    return { type: 'DELETE_AGENDA_SESSION', payload };
}

export function updateSpeaker(payload) {
    return { type: 'UPDATE_SPEAKER', payload };
}

export function getAgendaSessionDates(payload) {
    return { type: 'GET_AGENDA_SESSIONS_DATES', payload };
}

export function getAgendaSpeakers(payload) {
    return { type: 'GET_AGENDA_SPEAKERS', payload };
}

export function getAgendaSessionTags(payload) {
    return { type: 'GET_AGENDA_SESSION_TAGS', payload };
}

export function clearAllAgendaStates() {
    return { type: 'CLEAR_ALL_AGENDA_SESSIONS_DATA' };
}
