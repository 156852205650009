import * as React from 'react';

const KeyNoteSpeakerBlockIcon = (props) => {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width={32} height={32} rx={4} fill="#E1E7FA" />
            <path
            d="M16 6a9 9 0 00-9 9v7c0 1.66 1.34 3 3 3h3v-8H9v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1h-7v2h6c1.66 0 3-1.34 3-3V15a9 9 0 00-9-9z"
            fill="#7A829D"
            />
        </svg>
    );
};

export default KeyNoteSpeakerBlockIcon;
