import moment from 'moment';
import momentTimezone from 'moment-timezone';

function getStartDate() {
    return moment()
        .startOf('day')
        .subtract(30, 'days')
        .format('YYYY-MM-DDTHH:mm');
}

function getEndDate() {
    return moment().endOf('day').format('YYYY-MM-DDTHH:mm');
}

export const initialState = {
    startDate: getStartDate(),
    endDate: getEndDate(),
    livePageId: 'all',
    selLivepageData: null,
    livePages: [],
    livePageSearchText: '',
    isFetching: true,
    timezone: momentTimezone.tz.guess(),
    activeMenu: 'absolute',
};

function analyticsReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_DATE':
        case 'SET_LIVEPAGEID':
        case 'SET_LIVEPAGES':
        case 'SET_FETCHING':
        case 'SET_TIMEZONE':
        case 'SET_LIVEPAGE_SEARCH':
        case 'SET_ACTIVE_MENU':
            return { ...state, ...action.payload };
        case 'RESET_DEFAULT':
            return {
                ...initialState,
                startDate: getStartDate(),
                endDate: getEndDate(),
                timezone: state.timezone,
            };
        default:
            return state;
    }
}

export default analyticsReducer;
