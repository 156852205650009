import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as ls from 'local-storage';

import { getStreamToken } from 'apis/rest/livestream/GetStreamToken';

import { StreamChat } from 'stream-chat';
import { Tabs } from 'antd';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';
import MainMenu from 'components/app/components/admin/menus/mainMenu';

import * as uiActionsLive from 'redux/actions/live/uiActionsLive';
import { isEmpty } from 'validate.js';
import { store } from 'redux/store';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Dashboard from './Dashboard';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const ChatModeration = ({
    showcaseId = null,
    projectId = null,
    user = null,
}) => {
    const isSponsorDashboard = !isEmpty(window?.SPONSOR_AUTH);
    const params = useParams();
    const userObject = store?.getState()?.user;
    const defaultTabs = ls.get('cdtabs');
    const projectIdFallback = projectId || params?.projectId || '';

    const dispatch = useDispatch;
    dispatch(uiActionsLive.updateSubMenuVisibility(false));

    const [chatClient, setChatClient] = useState(null);
    const [tabs, setTabs] = useState(
        defaultTabs
            ? JSON.parse(defaultTabs)
            : [
                  {
                      name: '1. Firtst 10 Channels',
                      key: `tab-${0}`,
                      length: 10,
                      active: true,
                  },
              ]
    );
    const [loading, setLoading] = useState(true);
    const [clientUser, setClientUser] = useState({});
    const [channels, setChannels] = useState([]);

    const initChat = async () => {
        try {
            const client = StreamChat.getInstance(
                process.env.REACT_APP_STREAM_CHAT_KEY,
                { timeout: 6000 }
            );

            // client.revokeUserToken()

            const res = await getStreamToken(projectId || params.projectId);

            if (res.status) {
                const { me } = await client.connectUser(
                    {
                        id: userObject.id,
                        name: `${userObject.firstname} ${userObject.lastname}`,
                        projectId: projectIdFallback,
                    },
                    res.streamToken
                );

                setClientUser(me);
                setChatClient(client);
            } else throw res;
        } catch (error) {
            openNotification('error', { message: error?.message });
        } finally {
            setLoading(false);
        }
    };

    const textNormalizer = (link) => {
        return link?.toLowerCase().split(' ').join('');
    };

    useEffect(() => {
        initChat();

        return () => {
            if (chatClient) {
                chatClient.disconnectUser();
            }
        };
    }, []);

    return (
        <Layout background="#F7F9FA" className="tw-p-0">
            <div className={`${styles.Wrap} tw-bg-white tw-p-7 md:tw-pb-20`}>
                <h1 className="tw-hidden md:tw-block">Chat Moderation</h1>
                {!loading && chatClient ? (
                    <Dashboard
                        showcaseId={showcaseId || params.showcaseId}
                        projectId={projectId || params.projectId}
                        user={user || userObject}
                        chatClient={chatClient}
                        clientUser={clientUser}
                        setChannels={setChannels}
                        channels={channels}
                        isSponsorDashboard={isSponsorDashboard}
                    />
                ) : (
                    ''
                )}
            </div>
            <SidebarContainer>
                <MainMenu priActive={textNormalizer('chat-moderation')} all />
            </SidebarContainer>
        </Layout>
    );
};
export default ChatModeration;
