import * as React from "react"

const LiveStreamIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 20a4 4 0 100-8 4 4 0 000 8z" fill="#7A829D" />
      <path
        d="M9.636 22.364a8.992 8.992 0 010-12.728M22.364 9.636a8.992 8.992 0 010 12.728M6.1 25.9a13.987 13.987 0 010-19.8M25.9 6.1a13.988 13.988 0 010 19.8"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LiveStreamIcon
