import React, { useState } from 'react';

import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import FullscreenIcon from 'Apps/VenueBuilder/Icons/FullscreenIcon';

import styles from './index.module.css';

const CodeEditor = ({
    headingTitle,
    tooltipText,
    isRequired,
    value,
    name,
    setValue,
    hasError,
    controlled = true,
    allowedTags = [],
}) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleChange = (value) => {
        if (controlled) setValue(name, value);
        else setValue(value);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 9) {
            let val = e.target.value;
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            e.target.value =
                val.substring(0, start) + '\t' + val.substring(end);

            e.target.selectionStart = e.target.selectionEnd = start + 1;
            handleChange(e.target.value);
            e.preventDefault();
        }
    };

    return (
        <div className={styles.CodeEditorContainer}>
            <div className={styles.Heading}>
                <h3 className="title">
                    {headingTitle}{' '}
                    {isRequired && <span className={styles.Required}>*</span>}
                    {tooltipText && (
                        <Tooltip placement="right" title={tooltipText}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    )}
                </h3>
                {allowedTags?.length ? (
                    <small>
                        HTML tags allowed:{' '}
                        <span style={{ color: 'red' }}>
                            {allowedTags?.join(', ')}
                        </span>
                    </small>
                ) : (
                    ''
                )}
            </div>
            <div
                className={`${styles.EditorContent} ${
                    isFullScreen ? styles.IsFullScreen : ''
                } ${hasError ? 'error' : ''}`}
            >
                <div className={styles.EditorContentHead}>
                    <span
                        onClick={() => {
                            setIsFullScreen(!isFullScreen);
                        }}
                    >
                        <FullscreenIcon isActive={isFullScreen} />
                    </span>
                </div>
                <div
                    className={`${styles.Editor} custom-editor ${
                        isFullScreen ? 'fullscreen' : ''
                    }`}
                >
                    <textarea
                        name={name}
                        rows={10}
                        cols={40}
                        value={value}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                    {/* {!santitized && value?.length > 0 && <p>Invalid format</p>} */}
                </div>
            </div>
            <style jsx="true">{`
                .custom-editor.fullscreen textarea {
                    width: 100vh !important;
                    height: 100vh;
                }
                .custom-editor textarea {
                    background: url(https://files-myxp.gevme.com/default_background/bg-line.png);
                    background-attachment: local;
                    background-repeat: no-repeat;
                    padding-left: 35px;
                    padding-top: 10px;
                    border: 0;
                    resize: none;
                    height: 200px;
                    min-width: 100%;
                    max-width: 100%;
                }
                .error {
                    border: 1px solid red;
                    border-radius: 2px;
                }
            `}</style>
        </div>
    );
};

export default CodeEditor;
