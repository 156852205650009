import React from 'react';

export default function DuplicateIcon() {
    return (
        <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 8.5H1C0.734783 8.5 0.48043 8.39464 0.292893 8.20711C0.105357 8.01957 0 7.76522 0 7.5V1.5C0 1.23478 0.105357 0.98043 0.292893 0.792893C0.48043 0.605357 0.734783 0.5 1 0.5H7V1.5H1V7.5H4.5V6.5L6.5 8L4.5 9.5V8.5ZM8.5 10.5V3.5H3V6.5H2V3.5C2 3.23478 2.10536 2.98043 2.29289 2.79289C2.48043 2.60536 2.73478 2.5 3 2.5H8.5C8.76522 2.5 9.01957 2.60536 9.20711 2.79289C9.39464 2.98043 9.5 3.23478 9.5 3.5V10.5C9.5 10.7652 9.39464 11.0196 9.20711 11.2071C9.01957 11.3946 8.76522 11.5 8.5 11.5H3C2.73478 11.5 2.48043 11.3946 2.29289 11.2071C2.10536 11.0196 2 10.7652 2 10.5V9.5H3V10.5H8.5Z"
                fill="black"
            />
        </svg>
    );
}
