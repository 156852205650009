import React from 'react';

export default function CheckIcon({ isFilled = false }) {
    if (isFilled) {
        return (
            <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="13.5" cy="13.2988" r="13" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 2.29883C7.425 2.29883 2.5 7.22383 2.5 13.2988C2.5 19.3738 7.425 24.2988 13.5 24.2988C19.575 24.2988 24.5 19.3738 24.5 13.2988C24.5 7.22383 19.575 2.29883 13.5 2.29883ZM18.268 11.4388C18.3558 11.3385 18.4226 11.2216 18.4646 11.095C18.5065 10.9684 18.5227 10.8347 18.5123 10.7018C18.5018 10.5689 18.4648 10.4394 18.4036 10.321C18.3423 10.2025 18.258 10.0975 18.1555 10.0122C18.0531 9.92681 17.9346 9.86279 17.8071 9.82389C17.6796 9.78499 17.5455 9.77199 17.4129 9.78566C17.2802 9.79932 17.1517 9.83938 17.0347 9.90346C16.9178 9.96755 16.8149 10.0544 16.732 10.1588L12.432 15.3178L10.207 13.0918C10.0184 12.9097 9.7658 12.8089 9.5036 12.8112C9.2414 12.8134 8.99059 12.9186 8.80518 13.104C8.61977 13.2894 8.5146 13.5402 8.51233 13.8024C8.51005 14.0646 8.61084 14.3172 8.793 14.5058L11.793 17.5058C11.8913 17.604 12.0089 17.6807 12.1384 17.7309C12.2679 17.7811 12.4065 17.8038 12.5453 17.7975C12.684 17.7912 12.82 17.756 12.9444 17.6943C13.0688 17.6325 13.1791 17.5455 13.268 17.4388L18.268 11.4388Z"
                    fill="#377BF7"
                />
            </svg>
        );
    }

    return (
        <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.5" cy="13.2988" r="13" fill="#377BF7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 2.29883C7.425 2.29883 2.5 7.22383 2.5 13.2988C2.5 19.3738 7.425 24.2988 13.5 24.2988C19.575 24.2988 24.5 19.3738 24.5 13.2988C24.5 7.22383 19.575 2.29883 13.5 2.29883ZM18.268 11.4388C18.3558 11.3385 18.4226 11.2216 18.4646 11.095C18.5065 10.9684 18.5227 10.8347 18.5123 10.7018C18.5018 10.5689 18.4648 10.4394 18.4036 10.321C18.3423 10.2025 18.258 10.0975 18.1555 10.0122C18.0531 9.92681 17.9346 9.86279 17.8071 9.82389C17.6796 9.78499 17.5455 9.77199 17.4129 9.78566C17.2802 9.79932 17.1517 9.83937 17.0347 9.90346C16.9178 9.96755 16.8149 10.0544 16.732 10.1588L12.432 15.3178L10.207 13.0918C10.0184 12.9097 9.7658 12.8089 9.5036 12.8112C9.2414 12.8134 8.99059 12.9186 8.80518 13.104C8.61977 13.2894 8.5146 13.5402 8.51233 13.8024C8.51005 14.0646 8.61084 14.3172 8.793 14.5058L11.793 17.5058C11.8913 17.604 12.0089 17.6807 12.1384 17.7309C12.2679 17.7811 12.4065 17.8038 12.5453 17.7975C12.684 17.7912 12.82 17.756 12.9444 17.6943C13.0688 17.6325 13.1791 17.5455 13.268 17.4388L18.268 11.4388Z"
                fill="white"
            />
        </svg>
    );
}
