import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import Usage from './usage';
import Billing from './billing';
import Plans from 'ComponentsV2/ProjectDashboard/Components/UpgradeScreen';

import { limitsByPlans } from 'ComponentsV2/Plans/limits.js';

import './usageAndBillings.scss';

const { TabPane } = Tabs;

const UsageAndBillings = () => {
    const [currentTab, setCurrentTab] = useState('usage');
    const companyDetails = useSelector((state) => state?.projects?.company);
    const companyActivePlan = limitsByPlans[companyDetails?.package?.id];

    return (
        <div className={`container usage-and-billing-container`}>
            <div className="row">
                <div className="col-12">
                    <Tabs
                        activeKey={currentTab}
                        onChange={setCurrentTab}
                        tabBarStyle={{ color: '#0000' }}
                        defaultActiveKey="1"
                        destroyInactiveTabPane={true}
                    >
                        <TabPane tab="Usage" key="usage">
                            <Usage
                                onUpgradeClick={() => setCurrentTab('plans')}
                                companyActivePlan={companyActivePlan}
                            />
                        </TabPane>
                        <TabPane tab="Plans" key="plans">
                            <Plans isStandAlone={false} />
                        </TabPane>
                        <TabPane tab="Billing" key="billing">
                            <Billing />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <style>{`
        ant-tabs-tab ant-tabs-tab-active div{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
        }
        .ant-tabs-tab div{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #B7C6D6;
        }
        .ant-tabs 
        {
            margin-top:18px;
        }
        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            background-color: black;
        }
        .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active
        {
        color:#000000;
        }
    `}</style>
        </div>
    );
};

export default UsageAndBillings;
