import { apiInterceptor } from 'utils/apiInterceptor';

/**
 * gets the current user based from authorisation token
 */
export async function getAnnouncements() {
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/announcements/public`,
        {
            method: 'GET'
        }
    );

    return await response.json();
}
