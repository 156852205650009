import React from 'react';

export default function UserProfileEditIcon({ stroke = 'black' }) {
    return (
        <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_18531_23381)">
                <path
                    d="M5.6 6.50001C7.3675 6.50001 8.8 5.06751 8.8 3.30001C8.8 1.53251 7.3675 0.100006 5.6 0.100006C3.8325 0.100006 2.4 1.53251 2.4 3.30001C2.4 5.06751 3.8325 6.50001 5.6 6.50001ZM7.84 7.30001H7.4225C6.8675 7.55501 6.25 7.70001 5.6 7.70001C4.95 7.70001 4.335 7.55501 3.7775 7.30001H3.36C1.505 7.30001 0 8.80501 0 10.66V11.7C0 12.3625 0.5375 12.9 1.2 12.9H8.0725C8.0125 12.73 7.9875 12.55 8.0075 12.3675L8.1775 10.845L8.2075 10.5675L8.405 10.37L10.3375 8.43751C9.725 7.74501 8.8375 7.30001 7.84 7.30001ZM8.9725 10.9325L8.8025 12.4575C8.775 12.7125 8.99 12.9275 9.2425 12.8975L10.765 12.7275L14.2125 9.28001L12.42 7.48751L8.9725 10.9325ZM15.825 6.82251L14.8775 5.87501C14.645 5.64251 14.265 5.64251 14.0325 5.87501L13.0875 6.82001L12.985 6.92251L14.78 8.71501L15.825 7.67001C16.0575 7.43501 16.0575 7.05751 15.825 6.82251Z"
                    fill={stroke || 'black'}
                />
            </g>
            <defs>
                <clipPath id="clip0_18531_23381">
                    <rect
                        width="16"
                        height="12.8"
                        fill="white"
                        transform="translate(0 0.100006)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
