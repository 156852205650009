import React from 'react';
import { PropTypes } from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import EyeIcon from '../../../Icons/EyeIcon';
import EyeClosedIcon from '../../../Icons/EyeClosedIcon';
import DraggableIcon from '../../../Icons/DraggableIcon';
import validator from 'validator';

import { Switch } from 'antd';
import getIconByType from '../../../helpers/getIconByType';
import { checkYoutubeOrVimeoLink } from 'components/app/views/apps/products/products/helpers';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const Visibility = ({
    requestLoading,
    type,
    onVisibilityChange,
    isVisible,
    data,
}) => {
    let icon = null;
    if (type === 'eye')
        icon = (
            <span
                onClick={() => onVisibilityChange(!isVisible, data)}
                className={styles.VisibilityIcon}
            >
                {isVisible || data?.blockVisibility ? (
                    <EyeIcon isVisible={isVisible} />
                ) : (
                    <EyeClosedIcon isVisible={isVisible} />
                )}
            </span>
        );
    else if (type === 'close')
        icon = (
            <span
                onClick={() => onVisibilityChange(!isVisible, data)}
                className={styles.VisibilityIcon}
            >
                <CloseOutlined />
            </span>
        );
    else
        icon = (
            <span className={styles.VisibilityIcon}>
                {' '}
                <Switch
                    size={'small'}
                    checked={isVisible}
                    onChange={(val) => onVisibilityChange(val, data)}
                    disabled={requestLoading}
                    loading={requestLoading}
                />
            </span>
        );
    return icon;
};
const Item = ({
    requestLoading,
    data,
    index,
    visibilityIcon,
    onVisibilityChange,
    isVisible,
    onItemClicked,
    onItemHovered,
    uniqueIdentifier,
    hasImage,
    hasLabel,
    trimNames,
    isDropDisabled,
    userType,
}) => {
    let showVisibilityIcon = true;
    if (
        userType &&
        data.preventBlockVisibilityChange &&
        typeof data.preventBlockVisibilityChange[userType] === 'boolean'
    )
        showVisibilityIcon = !data.preventBlockVisibilityChange[userType];

    return (
        <Draggable
            draggableId={
                uniqueIdentifier ? data[uniqueIdentifier] : `${data.id}`
            }
            index={index}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={index}
                    className={`${styles.dragItemContent} ${
                        hasLabel ? styles.dragItemContentWithLabel : ''
                    } ${isVisible ? 'visible' : 'not-visible'} block-item`}
                    id={`${data.id}`}
                    onMouseLeave={
                        onItemHovered ? () => onItemHovered(data, true) : null
                    }
                    onMouseEnter={
                        onItemHovered ? () => onItemHovered(data) : null
                    }
                >
                    <div
                        onClick={() => onItemClicked(data, index)}
                        className={`${styles.dragPosLeft} ${
                            hasLabel ? styles.dragPosLeftCol : ''
                        } postleft`}
                    >
                        {hasImage && (
                            <span className={styles.PostLeftImage}>
                                {data?.imageUrl ? (
                                    <img src={data.imageUrl} />
                                ) : data?.logo ? (
                                    <img src={data.logo} />
                                ) : (
                                    getIconByType(data?.feature || data?.type)
                                )}
                            </span>
                        )}

                        {hasLabel ? (
                            <>
                                <span
                                    className={`${styles.DragTitle} DragTitle ${
                                        hasLabel ? styles.DragTitleLabel : ''
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html: data?.label,
                                    }}
                                />
                                {data?.field === 'session' ? (
                                    <span className={styles.DragLabel}>
                                        Based whether a session is from a
                                        sponsor livepage or not
                                    </span>
                                ) : (
                                    <span
                                        className={`${styles.DragLabel} DragLabel`}
                                    >
                                        Based on the field
                                        <span
                                            title={
                                                data?.type === 'field'
                                                    ? data.field
                                                    : data?.type === 'tagGroup'
                                                    ? 'Tag Group'
                                                    : 'Tag'
                                            }
                                            className={styles.DragLabelValue}
                                        >
                                            {data?.type === 'field'
                                                ? data.field
                                                : data?.type === 'tagGroup'
                                                ? 'Tag Group'
                                                : 'Tag'}
                                        </span>
                                    </span>
                                )}
                            </>
                        ) : (
                            <>
                                {trimNames && (
                                    <span
                                        className={`${styles.DragTitle} ${
                                            hasLabel
                                                ? styles.DragTitleLabel
                                                : ''
                                        }`}
                                        style={{ width: 'auto' }}
                                        dangerouslySetInnerHTML={{
                                            __html: data.heading
                                                ? data.heading
                                                : validator.isURL(data?.name)
                                                ? checkYoutubeOrVimeoLink(
                                                      data?.name
                                                  )
                                                    ? data?.name
                                                    : data?.name
                                                          ?.split('/')
                                                          .pop()
                                                : data?.name,
                                        }}
                                    />
                                )}
                                {!trimNames && (
                                    <span
                                        className={`${styles.DragTitle} ${
                                            hasLabel
                                                ? styles.DragTitleLabel
                                                : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: data.heading
                                                ? data.heading
                                                : data?.name ||
                                                  `${data?.firstname} ${data?.lastname}`,
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div className={`${styles.dragPosRight} posright`}>
                        {visibilityIcon && showVisibilityIcon && (
                            <Visibility
                                type={visibilityIcon}
                                onVisibilityChange={onVisibilityChange}
                                isVisible={isVisible}
                                data={data}
                                requestLoading={requestLoading}
                            />
                        )}
                        {!isDropDisabled && (
                            <span className={styles.DraggableIcon}>
                                <DraggableIcon />
                            </span>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};
const List = React.memo(function GenerateList({
    requestLoading,
    listData,
    visibilityIcon,
    onVisibilityChange,
    onItemClicked,
    onItemHovered,
    visibilityKey,
    uniqueIdentifier,
    hasImage,
    hasLabel,
    trimNames,
    isDropDisabled,
    userType,
}) {
    return listData.map((data, index) => (
        <Item
            {...{
                requestLoading,
                data,
                uniqueIdentifier,
                onItemClicked,
                onItemHovered,
                index,
                visibilityIcon,
                onVisibilityChange,
                isVisible: data[visibilityKey] ? data[visibilityKey] : false,
                hasImage,
                hasLabel,
                trimNames,
                isDropDisabled,
                userType,
            }}
            key={uniqueIdentifier ? data[uniqueIdentifier] : `${data.id}`}
        />
    ));
});
/**
 * DraggableList
 *
 * @DraggableList
 * @category Venue Builder
 */
function DraggableList(props) {
    const {
        listData,
        onReordered,
        visibilityKey,
        visibilityIcon,
        onVisibilityChange,
        onItemClicked,
        onItemHovered,
        uniqueIdentifier,
        hasImage = true,
        hasLabel = false,
        requestLoading,
        trimNames = false,
        isDropDisabled = false,
        userType = null,
    } = props;
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        onReordered(
            reorder(listData, result.source.index, result.destination.index)
        );
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable isDropDisabled={isDropDisabled} droppableId="list">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <List
                            {...{
                                listData,
                                visibilityIcon,
                                onVisibilityChange,
                                onItemClicked,
                                onItemHovered,
                                visibilityKey,
                                uniqueIdentifier,
                                hasImage,
                                hasLabel,
                                requestLoading,
                                trimNames,
                                isDropDisabled,
                                userType,
                            }}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

            <style jsx="true">{`
                .ant-switch-checked {
                    background: #0b1c2c;
                }
            `}</style>
        </DragDropContext>
    );
}
DraggableList.propTypes = {
    /** array of objects to be made draggable */
    listData: PropTypes.array,
    /** callback triggered on reordering of list items with the entire reordered listData */
    onReordered: PropTypes.func,
    /** key whose visibility needs to be checked in listData */
    visibilityKey: PropTypes.string,
    /** unique Key */
    uniqueIdentifier: PropTypes.string,
    /** signifies the type of icon "eye" | "switch" */
    visibilityIcon: PropTypes.oneOf(['eye', 'switch', 'close']),
    /** callback triggered on visibility change with requestedVisibility and item details */
    onVisibilityChange: PropTypes.func,
    /** callback to provide the clicked item details */
    onItemClicked: PropTypes.func,
    isDropDisabled: PropTypes.bool,
    userType: PropTypes.string,
};
export default DraggableList;
