import * as React from "react"

const ProductIcon = (props) => {
  return (
    <svg
      width={26}
      height={22}
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 7v13H4V7" fill="#7A829D" />
      <path
        d="M22 7v13H4V7"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11h4"
        stroke="#E1E7FA"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 2H2v5h22V2z"
        stroke="#7A829D"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProductIcon
