/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as userAct from 'redux/actions/common/userActions';
import Prompt from 'components/app/common/prompt';
import * as ls from 'local-storage';
import { getSponsorCompanies } from 'apis/rest/GetSponsorCompanies';
import { sponsorAuthorize, sponsorLogout } from 'apis/rest/Authentication';
import { getSponsor, refreshSponsorTempToken } from 'apis/rest/Sponsor';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { Dropdown, Table, Tooltip } from 'antd';
import { LogoutIcon } from 'components/app/common/header/UserProfile/icons';
import * as act from '../../../../../../redux/actions/live/uiActionsLive';
import ListingCards from '../../../../../../ComponentsV2/CommonComponents/MobileView/listingCards/ListingCards';
import GevmeLogoPortrait from '../../../../../../ComponentsV2/Icons/GevmeLogoPortrait';
import { getNameInitials } from '../../../../utils/functions/common';
import './index.scss';
import 'antd/dist/antd.css';

const maxNameLength = 30;
const PAGE_SIZE = 10;

function SponsorCompanyProfiles() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const [companyList, setCompanyList] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const firstRender = useRef(true);

    const columns = [
        { title: 'Company Name', dataIndex: 'companyName', key: '1' },
        { title: 'Project Name', dataIndex: 'projectName', key: '2' },
        {
            title: null,
            key: 'action',
            render: (text, record) => {
                return (
                    <button
                        onClick={() =>
                            handleCompanyClick(record.key, record.project)
                        }
                        className="btn myxp btn-primary ml-auto"
                    >
                        Go to project
                    </button>
                );
            },
        },
    ];

    const handleSponsorCompanyListing = async () => {
        const sponsorTempAuthToken = ls.get('sponsorTempAuth');
        try {
            if (sponsorTempAuthToken) {
                const { status, message, companyProfiles, pagination } =
                    await getSponsorCompanies(currentPage);
                if (status) {
                    setCompanyList(
                        companyProfiles.map((data) => ({
                            key: data.id,
                            projectName: data.project.name,
                            companyName: data.name,
                            project: data.project,
                        }))
                    );
                    setPaginationData(pagination);
                } else throw new Error(message);
            } else history.push('/sponsor/login');
        } catch (error) {
            if (error?.message && error.message.includes('expired')) {
                handleSponsorTempToken(sponsorTempAuthToken);
            } else
                openNotification(
                    'error',
                    {
                        message:
                            error?.message ||
                            'Something went wrong, please try to login and logout again.',
                    },
                    0
                );
        }
    };

    const handleSponsorTempToken = async (sponsorTempAuthToken) => {
        try {
            const { status, token, message } = await refreshSponsorTempToken(
                sponsorTempAuthToken?.access_token
            );

            if (status) {
                ls.set('sponsorTempAuth', token);
                handleSponsorCompanyListing();
            } else throw new Error(message);
        } catch (error) {
            if (error?.message && error.message.includes('invalid auth')) {
                ls.clear();
                history.replace('/sponsor/login');
            } else
                openNotification(
                    'error',
                    {
                        message:
                            error?.message ||
                            'Something went wrong, please try to login and logout again.',
                    },
                    0
                );
        }
    };

    const handleGetSponsor = async (companyId, project) => {
        const user = await getSponsor(companyId);

        dispatch(userAct.setUser(user));
        if (user?.user?.permission)
            dispatch({
                type: 'UPDATE_SPONSOR_MENU_DATA',
                payload: user.user.permission,
            });

        // ls('user', user.user);
        ls('project', project);
        ls.set('tempProjId', project.projectId);

        history.push(
            `/company/${companyId}/project/${project.projectId}/sponsor-home`
        );
    };

    const handleCompanyClick = async (companyId, project) => {
        try {
            const identifier = ls.get('sponsorTempAuth');

            if (!identifier) {
                ls.clear();
                history.push('/sponsor/login');
            } else {
                const resp = await sponsorAuthorize({
                    identifier,
                    exhibitorCompanyProfileId: companyId,
                });

                if (resp?.status) {
                    ls.set('sponsorAuth', 'authenticated');
                    window.SPONSOR_AUTH = 'authenticated';

                    Object.freeze(window.SPONSOR_AUTH);

                    await handleGetSponsor(companyId, project);
                } else throw resp;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogout = async () => {
        try {
            const res = await sponsorLogout();
            if (res?.status) {
                ls.clear();
                history.replace('/sponsor/login');
            } else throw res;
        } catch (error) {
            console.error(error);
        }
    };

    const avatar = (
        <div className="tw-flex tw-flex-1 tw-min-h-[40px] tw-rounded-full tw-bg-[#e4eaf7] tw-w-[40px] tw-h-[40px] tw-text-sm tw-font-bold tw-items-center tw-justify-center">
            <span>{getNameInitials(user)}</span>
        </div>
    );
    const fullName = `${user?.firstname} ${user?.lastname}`;

    const profile = (
        <div className="tw-flex tw-p-4 tw-shadow-lg tw-flex-col tw-items-center tw-justify-center tw-w-72 tw-h-auto tw-mt-3 tw-bg-white tw-rounded-md tw-cursor-default">
            {avatar}
            <div className="tooltip-fullname tw-m-0 tw-p-0 tw-text-sm tw-mt-2 tw-font-semibold tw-text-black tw-break-words tw-text-center">
                {fullName?.length > maxNameLength ? (
                    <Tooltip
                        placement="top"
                        title={fullName}
                        zIndex={9999}
                        getPopupContainer={(triggerNode) => triggerNode}
                    >
                        <span className="tw-p-0 tw-m-0 tw-break-words">
                            {`${fullName.slice(0, maxNameLength - 1)} ...`}
                        </span>
                    </Tooltip>
                ) : (
                    fullName
                )}
            </div>
            <div className="tw-m-0 tw-p-0 tw-text-xs tw-mt-2 tw-font-extralight tw-mb-4">
                {user?.email}
            </div>

            <div
                className="tw-m-0 tw-p-0 tw-w-full hover:tw-bg-gray-100 tw-mt-0 tw-flex tw-flex-row tw-items-center tw-py-2 tw-cursor-pointer"
                onClick={() => setShowLogoutConfirm(true)}
            >
                <span className="tw-m-0 tw-p-0 tw-pl-4 tw-pr-2">
                    <LogoutIcon />
                </span>
                <span className="tw-m-0 tw-p-0 tw-text-sm tw-font-light">
                    Logout
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        dispatch(act.updateAllowFX(false));
        // Let Layout component know that menus are not visible
        dispatch(act.updateMainMenuVisibility(false));
        dispatch(act.updateSubMenuVisibility(false));
        // Reset defaults
        dispatch(act.updateHeaderTitle(''));
        dispatch(act.updateActiveLivePageName(''));

        handleSponsorCompanyListing();
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        handleSponsorCompanyListing();
    }, [currentPage]);

    useEffect(() => {
        if (companyList.length === 1 && paginationData?.total < 2) {
            dispatch({
                type: 'UPDATE_SINGLECOMPANY_SPONSOR',
                payload: true,
            });
            handleCompanyClick(companyList[0]?.key, companyList[0]?.project);
        }
    }, [companyList]);

    return (
        <>
            <div className="companyProfiles">
                <div className="header header--xpmanager shadow-sm">
                    <div className="header__custom">
                        <div className="header-main">
                            <div className="header-main__title-group">
                                <a
                                    href="/sponsor/companyProfiles"
                                    title="Gevme"
                                >
                                    <GevmeLogoPortrait />
                                </a>
                            </div>
                            <div className="header-main__title tw-font-semibold">
                                Exhibitor Dashboard
                            </div>
                        </div>
                    </div>
                    {user?.id && (
                        <div className="flex">
                            <Dropdown
                                overlay={profile}
                                trigger={['click']}
                                getPopupContainer={(node) => node.parentElement}
                            >
                                {avatar}
                            </Dropdown>
                        </div>
                    )}
                </div>
                <Prompt
                    show={showLogoutConfirm}
                    message="Are you sure you want to Logout?"
                    buttons={['Cancel', 'Proceed to Logout']}
                    onHide={() => setShowLogoutConfirm(false)}
                    callback={() => handleLogout()}
                />
                <Table
                    style={{
                        width: '100%',
                        padding: '0 160px 0 160px',
                        marginTop: '80px',
                        background: '#f5f6fc',
                    }}
                    columns={columns}
                    dataSource={companyList}
                    loading={companyList.length === 0}
                    className="tw-hidden md:tw-block"
                    pagination={{
                        position: ['none', 'bottomCenter'],
                        onChange: setCurrentPage,
                        pageSize: PAGE_SIZE,
                        total: paginationData?.total,
                        showSizeChanger: false,
                        current: currentPage,
                    }}
                />

                <ListingCards
                    companyList={companyList}
                    gotToDashboard={handleCompanyClick}
                    paginationData={{
                        currentPage,
                        total: paginationData?.total,
                        pageSize: PAGE_SIZE,
                        onChange: setCurrentPage,
                    }}
                />
            </div>
        </>
    );
}

export default withRouter(SponsorCompanyProfiles);
