import React, { useState, useEffect } from "react";

import FileUploader from "components/app/common/fileUploader";
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function Pdf(props) {
  const {
    handleInputChange,
    input,
    handleFileDelete,
    prepareFileUpload,
    renderFieldError,
    blockContentData = null,
    permission = null,
    preventEditName = false,
    preventEditSize = false,
    preventEditTitle = false,
  } = props;

  const showcaseblock = blockContentData?.showcaseblock
  const [permissionSize, setPermissionSize] = useState(null)
  const [permissionDimension, setPermissionDimension] = useState(null)

  useEffect(() => {
    if (permission !== permissionSize) {
      const currentPermissonSize = permission[0].dimension
      setPermissionSize(permission)
      if (currentPermissonSize) {
        Object.keys(currentPermissonSize).map(k => {
          if (showcaseblock?.name === k) {
            setPermissionDimension(currentPermissonSize[k])
          }
        })
      }

    }
  }, [permission])

  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="input-set">
            <label>Block title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              onChange={e => handleInputChange(e)}
              autoFocus
              value={showcaseblock?.title || input.title}
              disabled={preventEditTitle}
              maxLength={70}
            />
            {renderFieldError("title")}
          </div>
          {!preventEditSize &&
            <>
              <label>Block size</label>
              <div className="radio-set">
                <span>
                  <input
                    type="radio"
                    value={false}
                    name="largeTile"
                    defaultChecked
                    onChange={e => handleInputChange(e)}
                    id="normalBlockSize"
                    disabled={preventEditSize}
                  />{" "}
                  <label htmlFor="normalBlockSize">Normal</label>
                </span>
                <span>
                  <input
                    type="radio"
                    value={true}
                    name="largeTile"
                    onChange={e => handleInputChange(e)}
                    disabled={preventEditSize}
                    id="bigBlockSize"
                  />{" "}
                  <label htmlFor="bigBlockSize">Big</label>
                </span>
              </div>
            </>
          }

        </div>

        <div className="col-6">
          <label>
            Thumbnail{" "}
            <span className="small">(.gif, .jpg/jpeg, .png, .svg)</span>
          </label>
          <ImageUpload
            prepareFilesUpload={files => {
              prepareFileUpload(files, 'image')
            }}
            handleFileDelete={(files) => {
              handleFileDelete(files, 'image')
            }}
            imageCropRecommendedSize={{
              width: (permissionDimension &&
                permissionDimension[0]) ||
                null,
              height: (permissionDimension &&
                permissionDimension[1]) ||
                null
            }}
          />
          {permissionDimension &&
            <p>Recommended size: within {permissionDimension[0]} x {permissionDimension[1]} px</p>
          }
          {renderFieldError("image")}
        </div>
      </div>

      <label>Your PDF</label>

      <p>Choose your PDF file below.</p>
      <p className="form-text text-muted">Max File Size - 10 MB</p>
      <div>
        <FileUploader
          prepareFileUpload={prepareFileUpload}
          handleFileDelete={handleFileDelete}
          fileType="pdf"
          size="large"
          controlsPosition="bottom"
        ></FileUploader>
        {/* {renderFieldError("pdf")} */}
      </div>
    </div>
  );
}

export default Pdf;
