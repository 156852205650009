import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { isObject } from 'lodash';

import './notificationBox.scss';

export const POSITIONS = {
    TOP_LEFT: 'topLeft',
    TOP_CENTERED: 'top',
    TOP_RIGHT: 'topRight',
    CENTERED: 'center',
    BOTTOM_LEFT: 'bottomLeft',
    BOTTOM_CENTERED: 'bottom',
    BOTTOM_RIGHT: 'bottomRight'
}

function NotificationBox({ question, options, position, buttonLabel, buttonColor, buttonTextColor, previewMode }) {
    const renderPositionCSS = () => {
        switch (position) {
            case POSITIONS.TOP_LEFT:
                return ({ top: 10, left: 15 })
            case POSITIONS.TOP_CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto', top: 15, maxWidth: 540 })
            case POSITIONS.TOP_RIGHT:
                return ({ top: 10, right: 15 })
            case POSITIONS.CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto' })
            case POSITIONS.BOTTOM_LEFT:
                return ({ bottom: 10, left: 15 })
            case POSITIONS.BOTTOM_CENTERED:
                return ({ marginRight: 'auto', marginLeft: 'auto', bottom: 15, maxWidth: 540 })
            case POSITIONS.BOTTOM_RIGHT:
                return ({ bottom: 10, right: 15 })
            default:
                return ({ bottom: 10, left: 15 })
        }
    }
    if (!question) return null
    return (
        <div className={`notification-box-container shadow p-3 mb-5 bg-white rounded ${previewMode ? 'position-relative mx-auto' : 'position-absolute'}`} style={!previewMode ? renderPositionCSS() : {maxHeight:370}}>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className="mb-1 title">{question}</h3>
                </div>
                <div>
                    <CloseOutlined role="button" />
                </div>
            </div>
            <div>
                <div >
                    <Radio.Group className="w-100 mt-2" >
                        <Space direction="vertical" className="w-100">
                            {options?.map((item, index) => {
                                if (isObject(item)) {
                                    return (<Radio className="description border w-100 p-1 rounded poll-radio-item" value={index}>{item?.label}</Radio>)
                                } else {
                                    return (<Radio className="description border w-100 p-1 rounded poll-radio-item" value={index}>{item}</Radio>)
                                }
                            })}
                        </Space>
                    </Radio.Group>
                </div>

                <button className={`myxp btn btn-sm ml-0 mt-2 w-100 `} style={{ whiteSpace: 'nowrap', backgroundColor: buttonColor, color: buttonTextColor, }}>{buttonLabel}</button>
            </div>
        </div>
    )
}

NotificationBox.propTypes = {
    question: PropTypes.string,
    options: PropTypes.array,
    position: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    previewMode: PropTypes.bool,

}

NotificationBox.defaultProps = {
    question: null,
    options: [],
    position: POSITIONS.BOTTOM_LEFT,
    buttonLabel: null,
    buttonColor: '#e2e9f3',
    buttonTextColor: '#000000',
    previewMode: false,

};

export default NotificationBox

