import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import parse from 'html-react-parser';

import './prompt.scss';
import { Progress } from 'antd';

/**
 * Will display a prompt depending on the case
 * @param show {boolean} will set if modal should be shown or not
 * @param message {string} the message
 * @param buttons {array} index 0 will be the left button, index 1 the right
 * @param callback {function} the action that will be fired upon proceeding
 * @param onHide {function} the action that will be fired upon close
 * @param description {string} message on what will happen if they proceed
 * @param type {string} is it warning, question, information
 * @returns {*}
 * @constructor
 */
const PromptWithInput = ({
    show,
    message,
    buttons,
    callback,
    onHide,
    description = '',
    icon = 'question',
    dataID,
    inputValue,
    inputField,
    progressBar=false,
    progressData,
    isLoading=false
}) => {
    const [showStat, setShowStat] = useState(show);
    const [newInputValue, setNewInputValue] = useState('');

    const headerImages = {
        question: '/assets/icon_question.svg',
        warning: '/assets/icon_warning.svg',
    };

    useEffect(() => {
        setShowStat(show);
    }, [show]);

    useEffect(() => {
        setNewInputValue(inputValue);
    }, [inputValue]);

    const handleInputChange = (e) => {
        const { value } = e.target;

        setNewInputValue(value);
    };

    return (
        <Modal
            dialogClassName="myxp-prompt"
            show={showStat}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            {Boolean(progressData) && progressBar && (
                <Progress
                    percent={progressData}
                    status={'active'}
                    showInfo={false}
                    strokeLinecap="square"
                    strokeColor={'#236DF2'}
                />
            )}
            <Modal.Header>
                <div
                    className="closeButton"
                    onClick={isLoading ? () => {} : onHide}
                />
                <Modal.Title>
                    {headerImages[icon] ? (
                        <img
                            src={headerImages[icon]}
                            alt=""
                            width="50"
                            height="50"
                        />
                    ) : (
                        icon
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                    <div className="prompt-message">{parse(message)}</div>
                    <div className="prompt-description left">
                        {parse(description)}
                    </div>
                    {inputField && (
                        <div className="prompt-input tw-mt-2">
                            <input
                                type="text"
                                className="form-control"
                                name={inputField}
                                id={inputField}
                                value={newInputValue}
                                onChange={handleInputChange}
                                disabled={isLoading}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span
                    className={`myxp btn btn-cancel ${
                        isLoading ? 'opactiy-50' : ''
                    } `}
                    onClick={isLoading ? () => {} : onHide}
                >
                    {buttons[0]}
                </span>
                <Button
                    variant="primary"
                    className={`myxp btn-primary ${
                        isLoading ? 'opactiy-50' : ''
                    }`}
                    onClick={
                        isLoading
                            ? () => {}
                            : () => callback(dataID, newInputValue)
                    }
                >
                    {isLoading ? buttons[2] : buttons[1]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PromptWithInput;
