import React, { Suspense, useEffect } from 'react';
import Spinner from './Components/Common/Spinner';

// Styles
import styles from './index.module.css';

// Dynamic import
const VenueBuilder = React.lazy(() =>
    import(/* webpackChunkName: "venue-builder" */ './entry')
);

export default ({
    livepageId = null,
    projectId = null,
    companyId = null,
    type = '',
    onCancel = null,
    isInStarterPlan = false,
    customDomain = '',
}) => {
    return (
        <>
            <Suspense fallback={<Spinner tip={'Loading Venue Builder...'} />}>
                <VenueBuilder
                    livepageId={livepageId}
                    projectId={projectId}
                    companyId={companyId}
                    type={type}
                    onCancel={onCancel}
                    isInStarterPlan={isInStarterPlan}
                    customDomain={customDomain}
                />
            </Suspense>
        </>
    );
};
