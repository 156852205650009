import plans from '../Plans/plans.json';

export default {
    identifyPlan: (id) => {
        return plans.filter((plan) => plan.id === id)[0];
    },
    STARTER_PLAN: '09534317-8b60-494b-bbc4-03aab9a0e074',
    STARTER_UPGRADE_PLAN: 'b2e2d0a6-d5f1-46c5-b9cb-af78193c7baa',
    PRO_PLAN: '27364dcd-bbb2-43dc-8476-25d1226a98a9',
};
