import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import { MediaAssetCardModal } from 'components/app/common/mediaAssetCard';
import { MediaAssetsVideoIcon } from '../../icons';
import './index.scss';

export default function NoMediaAssets({
    mediaName,
    allowedMediaTypes,
    maxMediaSize,
    children,
}) {
    const [showVideoModal, setShowVideoModal] = useState(false);
    return (
        <>
            <div className="tw-w-full tw-bg-white tw-flex tw-flex-row tw-drop-shadow-lg tw-rounded-lg">
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-border tw-border-dashed tw-border-slate-400 tw-bg-slate-100 tw-rounded-lg tw-w-full tw-m-5 tw-p-5">
                    <div className="tw-self-center tw-pt-9">
                        <MediaAssetsVideoIcon />
                    </div>
                    <div className="tw-self-center tw-flex tw-flex-col tw-text-center tw-pt-5">
                        <span className="tw-text-xl tw-font-semibold tw-tracking-tight tw-font-montserrat">
                            No {startCase(mediaName)} files
                        </span>
                        <span className="tw-text-xssm tw-font-light tw-pt-4">
                            No {startCase(mediaName)} files have been uploaded
                            yet
                        </span>
                        <span className="tw-text-xssm tw-font-light tw-pt-1">
                            Allowed file types -{' '}
                            <span className="tw-text-blue-600">
                                {allowedMediaTypes.length > 0
                                    ? allowedMediaTypes.join(', ').toUpperCase()
                                    : ''}
                            </span>
                        </span>
                        <span className="tw-text-xssm tw-font-light tw-pt-1">
                            Maximum upload file size :{' '}
                            <span className="tw-text-blue-600">
                                {maxMediaSize}
                            </span>
                        </span>
                        {children}
                    </div>
                </div>
                {/* Removed for now */}
                {/*<div className="tw-flex tw-flex-col tw-text-center tw-items-center tw-justify-center tw-w-6/12 tw-m-5">*/}
                {/*    <div className="tw-self-center tw-pt-4">*/}
                {/*        <img*/}
                {/*            src="assets/media-assets-sample.png"*/}
                {/*            className="tw-w-full"*/}
                {/*            alt="media-assets-sample.png"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <span className="tw-w-3/4 tw-text-xs tw-font-light tw-pt-4">*/}
                {/*        {startCase(mediaName)} inside “Media Assets” can be use*/}
                {/*        to store all your {startCase(mediaName)}s file of this*/}
                {/*        project and can be find inside the{' '}*/}
                {/*        {startCase(mediaName)} block on venue editor*/}
                {/*    </span>*/}
                {/*    <div className="tw-flex tw-flex-row tw-text-center tw-items-center tw-justify-center tw-mb-3">*/}
                {/*        <div*/}
                {/*            className="tw-self-center tw-mt-6 tw-w-28 tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-xssm tw-tracking-wide tw-font-semibold tw-rounded-md tw-bg-blue-500 tw-py-2 tw-px-3 tw-text-white tw-border tw-border-solid tw-border-blue-500 tw-cursor-pointer"*/}
                {/*            onClick={() => setShowVideoModal(true)}*/}
                {/*        >*/}
                {/*            Watch video*/}
                {/*        </div>*/}
                {/*        <span className="tw-text-xssm tw-font-light tw-mt-6 tw-py-2 tw-pl-6">*/}
                {/*            How Video files work*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <MediaAssetCardModal
                mediaAsset={{
                    url: 'https://www.youtube.com/watch?v=tsDVVri8f5Q',
                }}
                visible={showVideoModal}
                onClose={() => setShowVideoModal(false)}
                mediaPlayerSettings={{ playing: true }}
                showDetails={false}
            />
        </>
    );
}

NoMediaAssets.propTypes = {
    mediaName: PropTypes.string,
    allowedMediaTypes: PropTypes.array,
    maxMediaSize: PropTypes.string,
    children: PropTypes.node,
};

NoMediaAssets.defaultProps = {
    mediaName: 'media',
    allowedMediaTypes: [],
    maxMediaSize: '5 MB',
    children: null,
};
