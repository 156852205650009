import React, { createRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormSlider from '../utility/FormSlider';
import speakerEndPoints from 'apis/rest/livestream/Speakers';
import { Form, Button } from 'react-bootstrap';
import FroalaEditor from 'react-froala-wysiwyg';

import './speakers.scss';
import ImageUpload from 'components/app/common/imageUpload';
import { isEmpty } from 'lodash';
import GlobalLoading from 'components/app/components/common/globalLoading';
import sanitizeContent from 'utils/InputSanitization';

const { createSpeaker, getSpeakerDetails, updateSpeaker } = speakerEndPoints;

export default function SpeakerForm({
    showForm,
    toggleForm,
    getSpeakers,
    showAlertBar,
    speakerId,
    setSpeakerId,
    showBlanket,
    onSave,
    zIndex,
}) {
    const { projectId } = useParams();
    const formRef = createRef();
    const formButton = createRef();
    const [initFormData, setInitData] = useState({});
    const [bio, setBio] = useState('');
    const [isVisibleUploadComponent, setIsVisibleUploadComponent] =
        useState(false);

    const { firstname, lastname, company, jobTitle, profilePhoto } =
        initFormData;

    useEffect(() => {
        if (!showForm) {
            setInitData({});
            if (speakerId) {
                setSpeakerId('');
            }
            setBio('');
            setIsVisibleUploadComponent(showForm);
        }

        setTimeout(() => {
            setIsVisibleUploadComponent(showForm);
        }, 1000);
    }, [showForm]);

    const [prevImgSrc, updatePreview] = useState(
        profilePhoto ? profilePhoto : null
    );

    useEffect(() => {
        const getSpeakerInfo = async () => {
            try {
                let { status, speaker } = await getSpeakerDetails(
                    projectId,
                    speakerId
                );
                if (status) {
                    setBio(speaker.bio);
                    setInitData(speaker);
                } else throw 'error';
            } catch (error) {
                showAlertBar('Failed to fetch Speaker Details', 'error');
            }
        };

        if (speakerId) {
            getSpeakerInfo(speakerId);
        }
    }, [speakerId]);

    useEffect(() => {
        if ([undefined, 'undefined'].includes(profilePhoto)) {
            updatePreview(null);
        } else {
            updatePreview(profilePhoto);
        }
    }, [profilePhoto]);

    // const selectFile = () => fileInputRef.current.click();
    // useEffect(() => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         updatePreview(e.target.result);
    //     };
    //     fileInputRef.current.addEventListener('change', (e) => {
    //         let targetFile = e.target.files[0];
    //         if (targetFile) reader.readAsDataURL(targetFile);
    //     });
    // }, []);

    const handleModelChange = (model) => {
        setBio(model);
    };

    const onUploadLogoFile = (file) => {
        updatePreview(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formRef.current.checkValidity()) {
            let response = '',
                succMsg = '';
            try {
                let formDetails = new FormData(formRef.current);
                const sanitizedBio = sanitizeContent({
                    str: bio,
                    type: 'bio',
                });
                new FormData(formRef.current);
                formDetails.append('bio', sanitizedBio);
                formDetails.append('profilePhoto', prevImgSrc);

                if (speakerId) {
                    response = await updateSpeaker(
                        projectId,
                        formDetails,
                        speakerId
                    );
                    succMsg = 'Speaker Updated Successfully';
                } else {
                    response = await createSpeaker(projectId, formDetails);
                    succMsg = 'Speaker Created Successfully';
                }
                if (response.status) {
                    if (getSpeakers) getSpeakers();
                    if (onSave) {
                        onSave(formDetails, prevImgSrc, speakerId);
                    }
                    showAlertBar(succMsg, 'success');
                } else throw '';
            } catch (e) {
                showAlertBar('', 'error');
            }
        }
    };
    return (
        <FormSlider
            zIndex={zIndex}
            showBlanket={showBlanket}
            width="500px"
            isOpen={showForm}
            toggleSideBar={toggleForm}
        >
            <div className="speakerForm">
                <h1>{speakerId ? 'Edit' : 'Add'} Speaker</h1>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">
                            Profile Picture
                        </Form.Label>
                        <div className="fileOptions">
                            {!isVisibleUploadComponent && (
                                <GlobalLoading loadingText={'Loading'} />
                            )}
                            {isVisibleUploadComponent && (
                                <ImageUpload
                                    previewImages={
                                        !isEmpty(prevImgSrc) ? [prevImgSrc] : []
                                    }
                                    prepareFilesUpload={(files) => {
                                        onUploadLogoFile(files[0]);
                                    }}
                                    imageCropAspectRatio={1}
                                    imageCropRecommendedSize={{
                                        width: 200,
                                        height: 200,
                                    }}
                                />
                            )}
                        </div>
                        <p className="lightTxt">Recommended Size : 200x200</p>
                    </Form.Group>

                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">
                            First Name
                        </Form.Label>
                        <Form.Control
                            key={initFormData.firstname}
                            defaultValue={firstname}
                            title="Name should only contain alphabets"
                            name="firstname"
                            maxLength={120}
                            required
                            // pattern="[a-zA-Z\s]+"
                            type="text"
                            placeholder="Enter First Name"
                        />
                    </Form.Group>

                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">Last Name</Form.Label>
                        <Form.Control
                            key={initFormData.lastname}
                            defaultValue={lastname}
                            name="lastname"
                            title="Name should only contain alphabets"
                            maxLength={120}
                            type="text"
                            // pattern="[a-zA-Z\s]+"
                            placeholder="Enter Last Name"
                        />
                    </Form.Group>

                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">Job Title</Form.Label>
                        <Form.Control
                            key={initFormData.jobTitle}
                            defaultValue={jobTitle}
                            name="jobTitle"
                            maxLength={1024}
                            type="text"
                            placeholder="Enter Job Title"
                        />
                    </Form.Group>

                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">Company</Form.Label>
                        <Form.Control
                            key={initFormData.company}
                            defaultValue={company}
                            name="company"
                            maxLength={1024}
                            type="text"
                            placeholder="Enter Company Name"
                        />
                    </Form.Group>

                    <Form.Group className="mt14">
                        <Form.Label className="formLabel">Biography</Form.Label>
                        <FroalaEditor
                            key={initFormData.bio}
                            tag="textarea"
                            config={{
                                key: process.env.REACT_APP_FROALA_LICENSE_KEY,
                                pastePlain: true,
                                attribution: false,
                                placeholderText: 'Edit your content here!',
                                htmlRemoveTags: ['script', 'base'],
                                heightMin: 150,
                                quickInsertTags: [''],
                                toolbarButtons: {
                                    moreText: {
                                        buttons: [
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strikeThrough',
                                            'subscript',
                                            'superscript',
                                            'fontFamily',
                                            'fontSize',
                                            'textColor',
                                            'backgroundColor',
                                            'inlineClass',
                                            'inlineStyle',
                                            'clearFormatting',
                                        ],
                                        buttonsVisible: 0,
                                    },
                                    moreParagraph: {
                                        buttons: [
                                            'alignLeft',
                                            'alignCenter',
                                            'alignRight',
                                            'formatOLSimple',
                                            'alignJustify',
                                            'formatOL',
                                            'formatUL',
                                            'paragraphFormat',
                                            'paragraphStyle',
                                            'lineHeight',
                                            'outdent',
                                            'indent',
                                            'quote',
                                        ],
                                        buttonsVisible: 0,
                                    },
                                },
                                events: {
                                    'paste.beforeCleanup': function (
                                        clipboard_html
                                    ) {
                                        return sanitizeContent({
                                            str: clipboard_html,
                                            type: 'bio',
                                        });
                                    },
                                },
                            }}
                            model={bio}
                            onModelChange={handleModelChange}
                            className="rich-text-editor"
                        />
                    </Form.Group>
                    <button
                        style={{ display: 'none' }}
                        ref={formButton}
                        type="submit"
                    ></button>
                </Form>
            </div>

            <div className="speakerFormButtons">
                <Button
                    variant="light"
                    onClick={() => {
                        formRef.current.reset();
                        toggleForm(false);
                    }}
                >
                    Cancel
                </Button>
                {!speakerId ? (
                    <Button
                        variant="primary"
                        onClick={() => {
                            formButton.current.click();
                            if (formRef.current.checkValidity()) {
                                formRef.current.reset();
                                updatePreview(null);
                                setInitData({});
                                if (speakerId) setSpeakerId('');
                            }
                        }}
                    >
                        Save and create another
                    </Button>
                ) : null}
                <Button
                    variant="primary"
                    onClick={() => {
                        formButton.current.click();
                        if (formRef.current.checkValidity()) {
                            formRef.current.reset();
                            updatePreview(null);
                            toggleForm(false);
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </FormSlider>
    );
}
