import React from 'react';

export default function MediaAssetsVideoIcon() {
    return (
        <svg
            width="151"
            height="90"
            viewBox="0 0 151 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_19412_19276)">
                <path
                    d="M116.576 45.9863C116.226 45.986 115.881 45.9124 115.561 45.77C115.487 45.7371 115.416 45.6968 115.35 45.6498L108.893 41.1048C108.565 40.8742 108.298 40.5684 108.114 40.213C107.929 39.8575 107.833 39.463 107.833 39.0625V32.864C107.833 32.4635 107.929 32.069 108.114 31.7136C108.298 31.3581 108.565 31.0523 108.893 30.8218L115.35 26.2767C115.416 26.2297 115.487 26.1895 115.561 26.1565C115.942 25.9874 116.358 25.916 116.773 25.9488C117.188 25.9816 117.588 26.1176 117.937 26.3444C118.286 26.5712 118.573 26.8816 118.772 27.2474C118.97 27.6133 119.074 28.0229 119.074 28.4392V43.4874C119.074 44.1499 118.811 44.7853 118.343 45.2538C117.874 45.7223 117.239 45.9855 116.576 45.9855L116.576 45.9863Z"
                    fill="#3F3D56"
                />
                <path
                    d="M102.126 47.205H84.1169C80.4428 47.2019 77.4652 44.827 77.4615 41.8964V30.0302C77.4652 27.0996 80.4428 24.7246 84.1169 24.7216H102.173C105.82 24.7251 108.777 27.083 108.781 29.9927V41.8964C108.777 44.827 105.8 47.2019 102.126 47.205Z"
                    fill="#3F3D56"
                />
                <path
                    d="M127.251 78.3431C127.254 78.9456 127.018 79.5247 126.594 79.9529C126.17 80.3811 125.594 80.6235 124.991 80.6266H25.5646C25.2663 80.6251 24.9712 80.5648 24.6962 80.4492C24.4212 80.3336 24.1716 80.165 23.9618 79.9529C23.752 79.7409 23.5859 79.4896 23.4732 79.2134C23.3605 78.9372 23.3033 78.6415 23.3048 78.3432V10.4284C23.3017 9.82588 23.538 9.24683 23.9618 8.81859C24.3855 8.39034 24.9621 8.14799 25.5646 8.14484H124.991C125.289 8.14639 125.585 8.20668 125.86 8.32228C126.135 8.43787 126.384 8.6065 126.594 8.81854C126.804 9.03058 126.97 9.28188 127.083 9.55808C127.195 9.83428 127.253 10.13 127.251 10.4283V10.4284V78.3431Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M126.078 77.2311C126.078 77.8167 125.845 78.3783 125.431 78.7924C125.017 79.2064 124.456 79.4391 123.87 79.4391H26.7538C26.1682 79.4391 25.6066 79.2064 25.1925 78.7924C24.7784 78.3783 24.5458 77.8167 24.5458 77.2311V11.5716C24.5458 10.986 24.7784 10.4244 25.1925 10.0104C25.6066 9.59627 26.1682 9.36365 26.7538 9.36365H123.874C124.459 9.36482 125.02 9.59797 125.433 10.0119C125.846 10.4259 126.078 10.9868 126.078 11.5716V77.2311Z"
                    fill="white"
                />
                <path
                    d="M29.7363 12.2144H120.991C121.339 12.2144 121.672 12.3524 121.918 12.5982C122.164 12.844 122.302 13.1773 122.302 13.5249V75.023C122.302 75.3706 122.164 75.704 121.918 75.9498C121.672 76.1955 121.339 76.3336 120.991 76.3336H29.7363C29.3887 76.3336 29.0553 76.1955 28.8095 75.9498C28.5638 75.704 28.4257 75.3706 28.4257 75.023V13.5249C28.4257 13.1773 28.5638 12.844 28.8095 12.5982C29.0553 12.3524 29.3887 12.2144 29.7363 12.2144Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M30.8914 13.6893H119.737C120.084 13.6893 120.418 13.8273 120.664 14.0731C120.909 14.3189 121.047 14.6523 121.047 14.9999V73.2163C121.047 73.5639 120.909 73.8973 120.664 74.143C120.418 74.3888 120.084 74.5269 119.737 74.5269H30.8914C30.5438 74.5269 30.2104 74.3888 29.9646 74.143C29.7189 73.8973 29.5808 73.5639 29.5808 73.2163V14.9999C29.5808 14.6523 29.7189 14.3189 29.9646 14.0731C30.2104 13.8273 30.5438 13.6893 30.8914 13.6893Z"
                    fill="white"
                />
                <path
                    d="M93.6224 54.1311C93.2727 54.1309 92.927 54.0572 92.6076 53.9149C92.5333 53.8819 92.4624 53.8416 92.396 53.7947L85.9391 49.2496C85.6116 49.0191 85.3444 48.7132 85.1599 48.3578C84.9754 48.0024 84.879 47.6078 84.8789 47.2074V41.0088C84.879 40.6084 84.9754 40.2138 85.1599 39.8584C85.3444 39.503 85.6116 39.1971 85.9391 38.9666L92.396 34.4215C92.4624 34.3746 92.5332 34.3343 92.6076 34.3013C92.9879 34.1322 93.4045 34.0608 93.8194 34.0937C94.2344 34.1265 94.6345 34.2625 94.9836 34.4893C95.3326 34.716 95.6194 35.0265 95.818 35.3923C96.0166 35.7581 96.1206 36.1678 96.1206 36.584V51.6322C96.1206 52.2947 95.8574 52.9302 95.3889 53.3987C94.9204 53.8671 94.285 54.1303 93.6224 54.1303L93.6224 54.1311Z"
                    fill="#3F3D56"
                />
                <path
                    d="M79.1718 55.3498H61.1631C57.489 55.3468 54.5115 52.9718 54.5077 50.0412V38.175C54.5115 35.2444 57.489 32.8695 61.1631 32.8665H79.2188C82.8667 32.8699 85.8228 35.2278 85.8272 38.1375V50.0412C85.8234 52.9718 82.8459 55.3468 79.1718 55.3498Z"
                    fill="#3F3D56"
                />
                <path
                    d="M111.251 70.7834C114.209 70.7834 116.607 68.3854 116.607 65.4274C116.607 62.4694 114.209 60.0715 111.251 60.0715C108.293 60.0715 105.895 62.4694 105.895 65.4274C105.895 68.3854 108.293 70.7834 111.251 70.7834Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M114.033 65.3315L109.764 62.8666C109.747 62.8569 109.728 62.8517 109.708 62.8517C109.689 62.8517 109.67 62.8569 109.653 62.8666C109.636 62.8763 109.622 62.8903 109.613 62.9072C109.603 62.924 109.598 62.9431 109.598 62.9626V67.8923C109.598 67.9118 109.603 67.9309 109.613 67.9478C109.622 67.9646 109.636 67.9786 109.653 67.9883C109.67 67.9981 109.689 68.0032 109.708 68.0032C109.728 68.0032 109.747 67.9981 109.764 67.9883L114.033 65.5235C114.05 65.5138 114.064 65.4998 114.074 65.4829C114.084 65.4661 114.089 65.4469 114.089 65.4275C114.089 65.408 114.084 65.3889 114.074 65.372C114.064 65.3552 114.05 65.3412 114.033 65.3315L109.764 62.8666C109.747 62.8569 109.728 62.8518 109.708 62.8518C109.689 62.8518 109.67 62.8569 109.653 62.8666C109.636 62.8763 109.622 62.8903 109.613 62.9072C109.603 62.924 109.598 62.9432 109.598 62.9626V67.8923C109.598 67.9118 109.603 67.9309 109.613 67.9478C109.622 67.9646 109.636 67.9786 109.653 67.9883C109.67 67.9981 109.689 68.0032 109.708 68.0032C109.728 68.0032 109.747 67.9981 109.764 67.9883L114.033 65.5235C114.05 65.5138 114.064 65.4998 114.074 65.4829C114.084 65.4661 114.089 65.4469 114.089 65.4275C114.089 65.408 114.084 65.3889 114.074 65.372C114.064 65.3552 114.05 65.3412 114.033 65.3315Z"
                    fill="white"
                />
                <path
                    d="M104.742 87.6727C104.745 88.2752 104.508 88.8542 104.085 89.2825C103.661 89.7107 103.084 89.9531 102.482 89.9562H3.05521C2.75689 89.9547 2.4618 89.8944 2.18679 89.7788C1.91177 89.6632 1.66222 89.4946 1.45237 89.2825C1.24253 89.0705 1.0765 88.8192 0.963775 88.543C0.851048 88.2668 0.793828 87.9711 0.79538 87.6728V19.7579C0.79223 19.1555 1.02854 18.5764 1.45233 18.1482C1.87612 17.7199 2.45267 17.4776 3.05514 17.4744H102.482C102.78 17.476 103.075 17.5363 103.35 17.6519C103.625 17.7675 103.875 17.9361 104.085 18.1481C104.294 18.3602 104.46 18.6115 104.573 18.8877C104.686 19.1639 104.743 19.4596 104.742 19.7579V19.7579V87.6727Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M103.569 86.5606C103.569 87.1462 103.336 87.7078 102.922 88.1219C102.508 88.536 101.946 88.7686 101.361 88.7686H4.24437C3.65878 88.7686 3.09716 88.536 2.68308 88.1219C2.26901 87.7078 2.03638 87.1462 2.03638 86.5606V20.9012C2.03638 20.3156 2.26901 19.754 2.68308 19.3399C3.09716 18.9258 3.65878 18.6932 4.24437 18.6932H101.365C101.95 18.6944 102.51 18.9275 102.924 19.3415C103.337 19.7554 103.569 20.3163 103.569 20.9012V86.5606Z"
                    fill="white"
                />
                <path
                    d="M7.22684 21.5439H98.4818C98.8294 21.5439 99.1628 21.682 99.4086 21.9277C99.6543 22.1735 99.7924 22.5069 99.7924 22.8545V84.3526C99.7924 84.7002 99.6543 85.0335 99.4086 85.2793C99.1628 85.5251 98.8294 85.6632 98.4818 85.6632H7.22684C6.87925 85.6632 6.5459 85.5251 6.30012 85.2793C6.05434 85.0335 5.91626 84.7002 5.91626 84.3526V22.8545C5.91626 22.5069 6.05434 22.1735 6.30012 21.9277C6.5459 21.682 6.87925 21.5439 7.22684 21.5439Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M8.38193 23.0189H97.2274C97.3995 23.0189 97.57 23.0528 97.729 23.1186C97.888 23.1845 98.0325 23.281 98.1542 23.4027C98.2759 23.5244 98.3724 23.6689 98.4383 23.8279C98.5041 23.9869 98.538 24.1573 98.538 24.3294V82.5459C98.538 82.718 98.5041 82.8884 98.4383 83.0474C98.3724 83.2065 98.2759 83.3509 98.1542 83.4726C98.0325 83.5943 97.888 83.6909 97.729 83.7567C97.57 83.8226 97.3995 83.8565 97.2274 83.8565H8.38193C8.03434 83.8565 7.70099 83.7184 7.45521 83.4726C7.20943 83.2269 7.07135 82.8935 7.07135 82.5459V24.3294C7.07135 23.9819 7.20943 23.6485 7.45521 23.4027C7.70099 23.1569 8.03434 23.0189 8.38193 23.0189Z"
                    fill="white"
                />
                <path
                    d="M71.113 63.4607C70.7633 63.4605 70.4175 63.3868 70.0981 63.2445C70.0238 63.2115 69.9529 63.1712 69.8865 63.1242L63.4296 58.5792C63.1021 58.3487 62.8349 58.0428 62.6504 57.6874C62.4659 57.332 62.3695 56.9374 62.3694 56.5369V50.3384C62.3695 49.938 62.4659 49.5434 62.6504 49.188C62.8349 48.8326 63.1021 48.5267 63.4296 48.2962L69.8865 43.7511C69.9529 43.7042 70.0238 43.6639 70.0981 43.6309C70.4784 43.4618 70.895 43.3904 71.3099 43.4232C71.7249 43.4561 72.1251 43.5921 72.4741 43.8188C72.8231 44.0456 73.1099 44.356 73.3085 44.7219C73.5071 45.0877 73.6111 45.4973 73.6111 45.9136V60.9618C73.6111 61.6243 73.3479 62.2597 72.8794 62.7282C72.4109 63.1967 71.7755 63.4599 71.1129 63.4599L71.113 63.4607Z"
                    fill="#125DFF"
                />
                <path
                    d="M56.6624 64.6794H38.6537C34.9796 64.6764 32.0021 62.3014 31.9983 59.3708V47.5046C32.0021 44.574 34.9796 42.1991 38.6537 42.196H56.7094C60.3573 42.1995 63.3134 44.5574 63.3178 47.4671V59.3708C63.314 62.3014 60.3365 64.6764 56.6624 64.6794Z"
                    fill="#125DFF"
                />
                <path
                    d="M88.7419 80.113C91.7 80.113 94.0979 77.7151 94.0979 74.7571C94.0979 71.7991 91.7 69.4011 88.7419 69.4011C85.7839 69.4011 83.386 71.7991 83.386 74.7571C83.386 77.7151 85.7839 80.113 88.7419 80.113Z"
                    fill="#3F3D56"
                />
                <path
                    d="M91.5238 74.661L87.2545 72.1961C87.2377 72.1864 87.2186 72.1813 87.1991 72.1813C87.1797 72.1813 87.1605 72.1864 87.1437 72.1961C87.1268 72.2059 87.1128 72.2199 87.1031 72.2367C87.0934 72.2536 87.0883 72.2727 87.0883 72.2921V77.2219C87.0883 77.2413 87.0934 77.2604 87.1031 77.2773C87.1128 77.2941 87.1268 77.3081 87.1437 77.3179C87.1605 77.3276 87.1797 77.3327 87.1991 77.3327C87.2186 77.3327 87.2377 77.3276 87.2545 77.3179L91.5238 74.853C91.5407 74.8433 91.5547 74.8293 91.5644 74.8124C91.5741 74.7956 91.5792 74.7765 91.5792 74.757C91.5792 74.7375 91.5741 74.7184 91.5644 74.7016C91.5547 74.6847 91.5407 74.6707 91.5238 74.661L87.2545 72.1961C87.2377 72.1864 87.2186 72.1813 87.1991 72.1813C87.1797 72.1813 87.1605 72.1864 87.1437 72.1961C87.1268 72.2059 87.1128 72.2199 87.1031 72.2367C87.0934 72.2536 87.0883 72.2727 87.0883 72.2921V77.2219C87.0883 77.2413 87.0934 77.2604 87.1031 77.2773C87.1128 77.2941 87.1268 77.3081 87.1437 77.3179C87.1605 77.3276 87.1797 77.3327 87.1991 77.3327C87.2186 77.3327 87.2377 77.3276 87.2545 77.3179L91.5238 74.853C91.5407 74.8433 91.5547 74.8293 91.5644 74.8124C91.5741 74.7956 91.5793 74.7765 91.5793 74.757C91.5793 74.7375 91.5741 74.7184 91.5644 74.7016C91.5547 74.6847 91.5407 74.6707 91.5238 74.661Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_19412_19276">
                    <rect
                        width="149.409"
                        height="89.9563"
                        fill="white"
                        transform="translate(0.795349)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
