import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Get all projects for Livestream
 */
export async function getStreamToken(projectId) {
    setAuthBearer();
    if (authBearer) {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        };
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/stream/${projectId}`,
            {
                method: 'GET',
                headers: headers,
            }
        );

        return await response.json();
    } else return null;
}
