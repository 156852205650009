import React from "react";

import PropTypes from "prop-types";

import "./boxPreloader.scss";

function BoxPreloader(props) {
    const { color, noOfBoxes = 2, hasTitle = true} = props;

    const noOfboxesToRender = [...Array(noOfBoxes).keys()];

    return (
    <div className="box-preloader">
        {hasTitle && 
            <div
            className="box-preloader__title"
            style={{ backgroundColor: color }}
            ></div>
        }
        <div>
        {noOfboxesToRender?.map((line, i) => (
            <div
            key={i}
            className="box-preloader__item"
            style={{ backgroundColor: color }}
            ></div>
        ))}
        </div>
    </div>
    );
}

BoxPreloader.propTypes = {
  color: PropTypes.string
};

export default BoxPreloader;
