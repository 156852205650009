import React from 'react';

export default function ArchiveIcon() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 1.5C0 1.30109 0.0790176 1.11032 0.21967 0.96967C0.360322 0.829018 0.551088 0.75 0.75 0.75H11.25C11.4489 0.75 11.6397 0.829018 11.7803 0.96967C11.921 1.11032 12 1.30109 12 1.5V3C12 3.19891 11.921 3.38968 11.7803 3.53033C11.6397 3.67098 11.4489 3.75 11.25 3.75V9.375C11.25 9.87228 11.0525 10.3492 10.7008 10.7008C10.3492 11.0525 9.87228 11.25 9.375 11.25H2.625C2.12772 11.25 1.65081 11.0525 1.29917 10.7008C0.947544 10.3492 0.75 9.87228 0.75 9.375V3.75C0.551088 3.75 0.360322 3.67098 0.21967 3.53033C0.0790176 3.38968 0 3.19891 0 3V1.5ZM1.5 3.75V9.375C1.5 9.67337 1.61853 9.95952 1.8295 10.1705C2.04048 10.3815 2.32663 10.5 2.625 10.5H9.375C9.67337 10.5 9.95952 10.3815 10.1705 10.1705C10.3815 9.95952 10.5 9.67337 10.5 9.375V3.75H1.5ZM11.25 1.5H0.75V3H11.25V1.5ZM3.75 5.625C3.75 5.52554 3.78951 5.43016 3.85984 5.35984C3.93016 5.28951 4.02554 5.25 4.125 5.25H7.875C7.97446 5.25 8.06984 5.28951 8.14017 5.35984C8.21049 5.43016 8.25 5.52554 8.25 5.625C8.25 5.72446 8.21049 5.81984 8.14017 5.89016C8.06984 5.96049 7.97446 6 7.875 6H4.125C4.02554 6 3.93016 5.96049 3.85984 5.89016C3.78951 5.81984 3.75 5.72446 3.75 5.625Z"
                fill="black"
            />
        </svg>
    );
}
