import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// API
import {
    getInstallationIds,
    getRepos,
    getBranches,
    saveRepoBranch,
    updateRepoBranch,
} from 'apis/rest/installation-apps/Github';

import { Button, Modal, Avatar, Image, Select } from 'antd';
import CloseIcon from '../Icons/CloseIcon';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import FileUploader from 'components/app/common/fileUploader';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

import 'antd/dist/antd.css';
import './uploadvenue.scss';
import Spinner from 'Apps/VenueBuilder/Components/Common/Spinner';
import { uploadZipVenue } from 'apis/rest/venue/Venues';

const Uploadvenue = ({
    installationId,
    isVisible,
    setUploadModal,
    configureThis,
    setModal,
    type
}) => {
    const history = useHistory();
    const { companyId, projectId } = useParams();

    const [openModalUpload, setOpenModalUpload] = useState(false);
    const [installationIds, setInstallationIds] = useState([]);
    const [openChooseRepo, setOpenChooseRepo] = useState(false);
    const [chosenRepo, setChosenRepo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [repos, setRepos] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [reposFetching, setReposFetching] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchesFetching, setBranchesFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [zipVenueFile, setZipVenueFile] = useState(null);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const { Option } = Select;

    const onUploadFile = (files) => {
        if (files && files.length) {
            setZipVenueFile(files);
        } else {
            openNotification('error', {
                message:
                    'We have encounted some error while uploading the file. Please try again',
            });
            setZipVenueFile(null);
        }
    };

    const uploadZipeVenueFile = async () => {
        try {
            setLoadingUpload(true);
            let formData = new FormData();
            formData.append('file', zipVenueFile[0]);

            const res = await uploadZipVenue(projectId, formData);
            if (res && res.status) {
                setUploadModal(false);
                setOpenModalUpload(false);
                openNotification('success', { message: res.message });
            } else {
                throw res;
            }
        } catch (error) {
            console.error(error);
            openNotification('error', { message: error.message });
        } finally {
            setLoadingUpload(false);
        }
    };

    const fetchInstallationIds = async () => {
        try {
            const res = await getInstallationIds(projectId, companyId);
            if (res.status) {
                const results = res.installations;
                setInstallationIds(
                    results.length
                        ? results.sort(
                              (a, b) =>
                                  parseInt(b.installationId) -
                                  parseInt(a.installationId)
                          )
                        : results
                );
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            openNotification('error', { message: error.message });
        }
    };

    const handleChooseRepo = (e) => {
        e.preventDefault();
        setOpenChooseRepo(true);
    };

    const searchRepo = async () => {
        setReposFetching(true);
        try {
            const res = await getRepos(
                chosenRepo.installationId,
                projectId,
                companyId
            );

            if (res.status) {
                setRepos(res.repos);
                setBranches([]);
            }

            setReposFetching(false);
        } catch (error) {
            console.error(error);
            setReposFetching(false);
            openNotification('error', { message: error.message });
        }
    };

    const searchBranch = async () => {
        setBranchesFetching(true);
        try {
            if (!isEmpty(selectedRepo)) {
                const res = await getBranches(
                    chosenRepo.installationId,
                    selectedRepo?.owner,
                    selectedRepo?.reponame,
                    projectId,
                    companyId
                );
                if (res.status) {
                    setBranches(res.repos);
                }
                setBranchesFetching(false);
            }
        } catch (error) {
            console.error(error);
            setBranchesFetching(false);
            openNotification('error', { message: error.message });
        }
    };

    const handleSelectedRepo = (e) => {
        try {
            if (e) {
                const repo = e.split('/');
                setSelectedRepo({ owner: repo[0], reponame: repo[1] });
            }
        } catch (error) {
            console.error(error);
            openNotification('error', { message: error.message });
        }
    };

    const handleSelectedBranch = (e) => {
        try {
            if (e) {
                setSelectedBranch(e);
            }
        } catch (error) {
            console.error(error);
            openNotification('error', { message: error.message });
        }
    };

    const handleGithubAccountChange = (e) => {
        try {
            if (installationIds.length) {
                const selected = installationIds.filter((d) => d.id === e)[0];

                if (!isEmpty(selected)) {
                    setSelectedRepo(null);
                    setSelectedBranch(null);
                    setChosenRepo(selected);
                }
            }
        } catch (error) {
            console.error(error);
            openNotification('error', { message: error.message });
        }
    };

    const handleSaveRepoAndBranch = async (e) => {
        try {
            e.preventDefault();

            setSaving(true);
            if (selectedBranch && selectedRepo) {
                const payload = {
                    repo: `${selectedRepo.owner}/${selectedRepo.reponame}`,
                    branch: selectedBranch,
                    installationId: chosenRepo.installationId,
                    projectId,
                    companyId,
                };

                if (payload) {
                    let res = '';

                    if (configureThis) {
                        res = await updateRepoBranch(
                            payload,
                            configureThis.venueId
                        );
                    } else {
                        res = await saveRepoBranch(payload);
                    }

                    if (res.status) {
                        openNotification('success', {
                            message: configureThis
                                ? 'Update successfully'
                                : res?.message || 'Saved successfully',
                        });

                        setOpenChooseRepo(false);
                        setUploadModal(false);
                    } else {
                        openNotification('error', { message: res.message });
                    }

                    setSaving(false);
                }
            }
        } catch (error) {
            console.error(error);
            openNotification('error', { message: error.message });
        }
    };

    useEffect(() => {
        if (installationIds.length === 0 && loading) {
            fetchInstallationIds();
        }

        return () => {
            setSaving(false);
        };
    }, []);

    useEffect(() => {
        if (installationIds.length) {
            if (!isEmpty(configureThis)) {
                const repo = configureThis?.repo.split('/');
                const configureThisRepo = installationIds.filter(
                    (e) =>
                        e.organizationName.toLowerCase() ===
                        repo[0]?.toLowerCase()
                );
                if (configureThisRepo?.length) {
                    setOpenChooseRepo(true);
                    setChosenRepo(configureThisRepo[0]);
                    setSelectedRepo({ owner: repo[0], reponame: repo[1] });
                    setSelectedBranch(configureThis?.branch);
                    setLoading(false);
                }
            } else {
                setChosenRepo(installationIds[0]);
            }
        }

        return () => {
            setChosenRepo(null);
        };
    }, [installationIds]);

    useEffect(() => {
        if (typeof openChooseRepo === 'boolean' && openChooseRepo === false) {
            setSelectedRepo(null);
            setSelectedBranch(null);
            setRepos([]);
            setBranches([]);
        }
    }, [openChooseRepo]);

    return (
        <>
            <Modal
                centered
                visible={isVisible}
                onCancel={() => {
                    setUploadModal(false);
                    setModal(false);
                }}
                footer={null}
                destroyOnClose={true}
                width={1072}
                closeIcon={<CloseIcon />}
                transitionName="none"
                maskTransitionName="none"
                className="venue-store-modal-details"
            >
                <h2
                    onClick={() => {
                        setUploadModal(false);
                        type && setModal(true);
                    }}
                >
                    <LeftOutlined /> Back
                </h2>

                <div className="venue-options">
                    <h3>Choose how you want to add your {type ? type : 'theme'}</h3>
                    {loading ? (
                        <Spinner tip="Loading..." />
                    ) : (
                        <ul className="venue-option-list">
                            <li className="venue-option-list-item">
                                <div className="content">
                                    <div className="left-content">
                                        <span className="icon">
                                            <svg
                                                width="38"
                                                height="38"
                                                viewBox="0 0 38 38"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.9265 0.5C8.47504 0.5 0 8.98126 0 19.4437C0 27.8137 5.42302 34.9146 12.9432 37.4195C13.889 37.5948 14.2363 37.0085 14.2363 36.5082C14.2363 36.0565 14.2187 34.5641 14.2107 32.9812C8.94514 34.1271 7.83409 30.7461 7.83409 30.7461C6.97315 28.5564 5.73266 27.9743 5.73266 27.9743C4.01553 26.7985 5.86209 26.8226 5.86209 26.8226C7.76269 26.9563 8.76345 28.7748 8.76345 28.7748C10.4515 31.6709 13.191 30.8336 14.2711 30.3497C14.4409 29.1251 14.9315 28.2896 15.4727 27.8165C11.2689 27.3374 6.84965 25.713 6.84965 18.4544C6.84965 16.3863 7.58902 14.6962 8.79982 13.3696C8.60329 12.8924 7.95551 10.9657 8.98314 8.35636C8.98314 8.35636 10.5725 7.8472 14.1893 10.2982C15.6989 9.87833 17.3181 9.66796 18.9265 9.66083C20.535 9.66796 22.1553 9.87833 23.6679 10.2982C27.2804 7.8472 28.8675 8.35636 28.8675 8.35636C29.8977 10.9657 29.2496 12.8924 29.0531 13.3696C30.2666 14.6962 31.0009 16.3861 31.0009 18.4544C31.0009 25.7303 26.5733 27.3325 22.3588 27.8014C23.0377 28.3893 23.6425 29.5422 23.6425 31.3095C23.6425 33.8441 23.6206 35.8842 23.6206 36.5082C23.6206 37.0123 23.9612 37.603 24.9207 37.417C32.4367 34.9092 37.8529 27.8109 37.8529 19.4437C37.8529 8.98126 29.3791 0.5 18.9265 0.5Z"
                                                    fill="#161614"
                                                />
                                            </svg>
                                        </span>
                                        <span className="title">
                                            <b>Pull from GitHub</b>
                                            <small>
                                                Choose your venue from a GitHub
                                                repo and branch.
                                            </small>
                                        </span>
                                    </div>
                                    <div className="gh-cta-actions">
                                        {installationIds.length > 0 ? (
                                            chosenRepo && (
                                                <Button
                                                    type="primary"
                                                    className="item-button"
                                                    onClick={handleChooseRepo}
                                                >
                                                    Choose Repo
                                                </Button>
                                            )
                                        ) : (
                                            <Button
                                                type="primary"
                                                className={`item-button ${
                                                    installationIds.length !==
                                                        0 && isEmpty(chosenRepo)
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    (window.location.href = `${process.env.REACT_APP_GITHUB_APP_URL}`)
                                                }
                                            >
                                                Connect to a GitHub Account
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className="content-bottom list-of-gh-account">
                                    {installationIds.length === 0 ? (
                                        <p>No GitHub account connected </p>
                                    ) : chosenRepo ? (
                                        <>
                                            <p className="head">
                                                {`GitHub Account${
                                                    installationIds.length > 1
                                                        ? 's'
                                                        : ''
                                                }`}
                                                :{' '}
                                                {chosenRepo.avatarUrl ? (
                                                    <Avatar
                                                        size="small"
                                                        src={
                                                            <Image
                                                                src={
                                                                    chosenRepo.avatarUrl
                                                                }
                                                                alt={
                                                                    chosenRepo.organizationName ||
                                                                    'Avatar'
                                                                }
                                                            />
                                                        }
                                                        alt={
                                                            chosenRepo.organizationName ||
                                                            'Avatar'
                                                        }
                                                    />
                                                ) : (
                                                    <Avatar
                                                        size="small"
                                                        icon={<UserOutlined />}
                                                    />
                                                )}
                                                <span className="name">
                                                    <Select
                                                        defaultValue={
                                                            chosenRepo.id
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        bordered={false}
                                                        onChange={
                                                            handleGithubAccountChange
                                                        }
                                                    >
                                                        {installationIds.map(
                                                            (e, index) => (
                                                                <Option
                                                                    key={index}
                                                                    value={e.id}
                                                                >
                                                                    {e.organizationName ||
                                                                        e.id}
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </span>
                                            </p>
                                            <p>
                                                <a
                                                    href={`${process.env.REACT_APP_GITHUB_APP_URL}`}
                                                >
                                                    Create a new github
                                                    connection
                                                </a>
                                            </p>
                                        </>
                                    ) : configureThis ? (
                                        <p>
                                            <span>
                                                <a
                                                    href={`https://www.github.com/${configureThis?.repo}/tree/${configureThis?.branch}`}
                                                    target="_blank"
                                                >
                                                    <b>{configureThis.repo}</b>
                                                </a>{' '}
                                                cannot be found. Account is
                                                either not existing or was
                                                previously uninstalled. Please
                                                connect the account to proceed.
                                            </span>
                                        </p>
                                    ) : (
                                        <p>
                                            <span>
                                                Account is either not existing
                                                or was previously uninstalled.
                                                Please connect the account to
                                                proceed.
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </li>
                            {!configureThis && (
                                <li
                                    className="venue-option-list-item"
                                    onClick={() => setOpenModalUpload(true)}
                                >
                                    <div className="content">
                                        <div className="left-content">
                                            <span className="icon">
                                                <svg
                                                    width="38"
                                                    height="31"
                                                    viewBox="0 0 38 31"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M29.0001 27.1667H34.0001V7.16667L27.3334 7.16666V8.83333H29.0001V12.1667H27.3334V13.8333H29.0001V17.1667H27.3334V18.8333H29.0001V22.1667H27.3334V23.8333H29.0001V27.1667ZM24.0001 27.1667V25.5H25.6667V23.8333H24.0001V20.5H25.6667V18.8333H24.0001V15.5H25.6667V13.8333H24.0001V10.5H25.6667V8.83333H24.0001V7.16665L18.9912 7.16664C17.7942 7.16023 17.0146 6.59985 16.1765 5.5609C16.0608 5.41751 15.6932 4.94067 15.6361 4.8686C15.0118 4.08095 14.6405 3.83333 14.0001 3.83333H4.00008V27.1667H24.0001ZM34.0001 3.83333C35.841 3.83333 37.3334 5.32572 37.3334 7.16667V27.1667C37.3334 29.0076 35.841 30.5 34.0001 30.5H4.00008C2.15913 30.5 0.666748 29.0076 0.666748 27.1667V3.83333C0.666748 1.99238 2.15913 0.5 4.00008 0.5H14.0001C15.8668 0.5 17.055 1.29242 18.2484 2.79809C18.328 2.89856 18.6836 3.3598 18.7709 3.46803C19.033 3.79291 19.0864 3.83288 19.0031 3.83333H34.0001Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="title">
                                                <b>Upload a file</b>
                                                <small>
                                                    Upload a zip file containing
                                                    your venue files.
                                                </small>
                                            </span>
                                        </div>
                                        <Button
                                            type="primary"
                                            className="item-button"
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    )}
                </div>

                {openModalUpload && (
                    <Modal
                        centered
                        visible={isVisible}
                        onCancel={() => setOpenModalUpload(false)}
                        footer={null}
                        destroyOnClose={true}
                        width={'100%'}
                        closeIcon={<CloseIcon />}
                        className="venue-store-modal-details"
                    >
                        <h2 onClick={() => setOpenModalUpload(false)}>
                            <LeftOutlined /> Back
                        </h2>
                        <div className="add-venue-modal">
                            <div className="uploader">
                                <div className="upload-div">
                                    <FileUploader
                                        fileType="venueFile"
                                        handleFileUpload={onUploadFile}
                                        prepareFileUpload={onUploadFile}
                                        size="small"
                                        controlsPosition="bottom"
                                        uploadText="Select File"
                                        currentFile={zipVenueFile}
                                        maxSize={100000000}
                                    />
                                    {!zipVenueFile && (
                                        <span>Or drag here to upload</span>
                                    )}
                                </div>

                                {!zipVenueFile && (
                                    <span className="recommendation">
                                        Upload in .zip format. Max file size: 100
                                        MB
                                    </span>
                                )}
                            </div>

                            <div className="buttons">
                                {loadingUpload ? (
                                    <button
                                        className="btn btn-default"
                                        disabled
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-default"
                                        onClick={() =>
                                            setOpenModalUpload(false)
                                        }
                                    >
                                        Cancel
                                    </button>
                                )}
                                {zipVenueFile ? (
                                    <button
                                        className="btn btn-primary"
                                        onClick={uploadZipeVenueFile}
                                    >
                                        {loadingUpload
                                            ? 'Uploading...'
                                            : 'Upload File'}
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary"
                                        disabled
                                    >
                                        Upload File
                                    </button>
                                )}
                            </div>
                        </div>
                    </Modal>
                )}

                {installationIds &&
                installationIds.length &&
                chosenRepo &&
                (selectedRepo || openChooseRepo) ? (
                    <Modal
                        centered
                        visible={isVisible}
                        onCancel={() => setOpenChooseRepo(false)}
                        footer={null}
                        destroyOnClose={true}
                        width={'100%'}
                        closeIcon={<CloseIcon />}
                        className="venue-store-modal-details choose-git-hub-repo"
                    >
                        <h2 onClick={() => setOpenChooseRepo(false)}>
                            <LeftOutlined /> Back
                        </h2>
                        <div className="github-options">
                            <h3>
                                Choose a repository and branch{' '}
                                {configureThis && configureThis.name && (
                                    <span>
                                        {' '}
                                        for <b>{configureThis.name}</b>
                                    </span>
                                )}
                            </h3>
                            <div className="github-selection">
                                {saving && (
                                    <div className="Loader">
                                        <Spinner
                                            tip={
                                                configureThis
                                                    ? 'Updating...'
                                                    : 'Saving...'
                                            }
                                            style={{ color: '#fff' }}
                                        />
                                    </div>
                                )}
                                <div className="github-selection-header">
                                    <p className="head">
                                        {`GitHub Account${
                                            installationIds.length > 1
                                                ? 's'
                                                : ''
                                        }`}
                                        :{' '}
                                        {chosenRepo.avatarUrl ? (
                                            <Avatar
                                                size="small"
                                                src={
                                                    <Image
                                                        src={
                                                            chosenRepo.avatarUrl
                                                        }
                                                        alt={
                                                            chosenRepo.organizationName ||
                                                            'Avatar'
                                                        }
                                                    />
                                                }
                                                alt={
                                                    chosenRepo.organizationName ||
                                                    'Avatar'
                                                }
                                            />
                                        ) : (
                                            <Avatar
                                                size="small"
                                                icon={<UserOutlined />}
                                            />
                                        )}
                                        <span className="name">
                                            <Select
                                                defaultValue={chosenRepo.id}
                                                style={{
                                                    width: '100%',
                                                }}
                                                bordered={false}
                                                onChange={
                                                    handleGithubAccountChange
                                                }
                                            >
                                                {installationIds.map(
                                                    (e, index) => (
                                                        <Option
                                                            key={index}
                                                            value={e.id}
                                                        >
                                                            {e.organizationName ||
                                                                e.id}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        </span>
                                    </p>
                                </div>
                                <div className="github-selection-body">
                                    <div className="input-group repository">
                                        <p>
                                            <b>Repository</b>
                                        </p>
                                        <Select
                                            showSearch
                                            allowClear={true}
                                            notFoundContent={
                                                reposFetching
                                                    ? 'Fetching repositories'
                                                    : repos.length === 0
                                                    ? 'No found repositories'
                                                    : null
                                            }
                                            onFocus={searchRepo}
                                            onSelect={handleSelectedRepo}
                                            onClear={() =>
                                                setSelectedRepo(null)
                                            }
                                            style={{ width: '100%' }}
                                            loading={reposFetching}
                                            placeholder="Choose a repository from your GitHub account"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        optionB.children.toLowerCase()
                                                    )
                                            }
                                            defaultValue={
                                                !isEmpty(selectedRepo)
                                                    ? `${selectedRepo.owner}/${selectedRepo.reponame}`
                                                    : ''
                                            }
                                        >
                                            {repos &&
                                                repos.length !== 0 &&
                                                repos.map((repo, index) => (
                                                    <Option
                                                        key={index}
                                                        value={repo.full_name}
                                                    >
                                                        {repo.full_name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </div>
                                    <div className="input-group repository">
                                        <p>
                                            <b>Branch</b>
                                        </p>

                                        <Select
                                            showSearch
                                            allowClear={true}
                                            onFocus={searchBranch}
                                            onSelect={handleSelectedBranch}
                                            onClear={() =>
                                                setSelectedBranch(null)
                                            }
                                            notFoundContent={
                                                branchesFetching
                                                    ? 'Fetching branches'
                                                    : branches.length === 0
                                                    ? 'No found branches'
                                                    : null
                                            }
                                            style={{ width: '100%' }}
                                            loading={branchesFetching}
                                            placeholder="Choose a branch of the repository"
                                            optionFilterProp="children"
                                            disabled={
                                                isEmpty(selectedRepo) ||
                                                selectedRepo === null
                                            }
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        optionB.children.toLowerCase()
                                                    )
                                            }
                                            defaultValue={selectedBranch || ''}
                                        >
                                            {branches &&
                                                branches.length !== 0 &&
                                                branches.map(
                                                    (branches, index) => (
                                                        <Option
                                                            key={index}
                                                            value={
                                                                branches.name
                                                            }
                                                        >
                                                            {branches.name}
                                                        </Option>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="github-options-cta">
                            <Button onClick={() => setOpenChooseRepo(false)}>
                                Cancel
                            </Button>
                            {selectedBranch === null || saving ? (
                                <Button disabled={true} type="primary">
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSaveRepoAndBranch}
                                    type="primary"
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </Modal>
                ) : null}
            </Modal>
        </>
    );
};

export default Uploadvenue;
