import { getAuth } from '../helpers';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function getAllMediaAssets({
    projectId,
    type = 'audio',
    search = '',
    userIds = [],
    startDate = '',
    endDate = '',
    sortBy = 'name',
    sortOrder = 'desc',
    page = 1,
}) {
    const auth = getAuth();

    const options = {
        projectId,
        type,
        search,
        userIds,
        startDate,
        endDate,
        sortBy,
        sortOrder,
    };

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/list?page=${page}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(options),
        }
    );
    return await response.json();
}

export async function getAllMediaAssetsUsers({
    projectId,
    dropDownSearch = '',
    type = 'audio',
    globalSearch = '',
    startDate = '',
    endDate = '',
}) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    let url = `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/users?projectId=${projectId}&dropDownSearch=${dropDownSearch}&type=${type}&globalSearch=${globalSearch}`;
    if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    const { response } = await apiInterceptor(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        },
    });
    return await response.json();
}

export async function uploadMediaAsset({ projectId, file, filetype }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { name: filename } = file;
    const { response: res } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/file-upload?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ filename }),
        }
    );
    const { status, signedUrl, token, message } = await res.json();

    if (!status) {
        throw new Error(
            message || 'Failed to Upload file while generating signed s3 url'
        );
    }

    const { response } = await apiInterceptor(signedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': filetype,
        },
        body: file,
    });

    if (response.status !== 200) {
        throw new Error('Failed to Upload file to s3 amazon');
    }

    return { filename, token };
}

export async function createMediaAsset({
    projectId,
    type = 'audio',
    name = '',
    token = '',
}) {
    const auth = getAuth();

    const data = {
        name,
        token,
        type,
    };

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(data),
        }
    );
    return await response.json();
}

export async function deleteMediaAsset({ projectId, mediaAssetId }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/${mediaAssetId}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function deleteManyMediaAsset({ projectId, mediaAssetIds }) {
    const auth = getAuth();

    const authBearer = `Bearer ${auth?.access_token}`;
    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/assets/bulk-delete?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify({ ids: mediaAssetIds }),
        }
    );
    return await response.json();
}
