import { separateCamelCase } from 'components/app/utils/functions/common';

const createProjectValidationCriteria = (input) => {
    const formErrors = {};

    const required = ['name', 'contactPersonFirstname', 'contactPersonLastname', 'contactPersonEmail'];

    required.forEach((requiredField) => {
        if (!input[requiredField]) {
            formErrors[requiredField] = `Please enter ${separateCamelCase(
                requiredField
            )}.`;
        } else {
            formErrors[requiredField] = '';
        }
    });

    return formErrors;
};

export default createProjectValidationCriteria;
