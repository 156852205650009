import * as React from 'react';

const ParticipantsIcon = (props) => {
    return (
        <svg
            width={24}
            height={20}
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.344 10.026a5.375 5.375 0 001.857-4.064 5.462 5.462 0 10-10.924 0c0 1.56.678 3.043 1.857 4.064A8.74 8.74 0 000 17.978a1.092 1.092 0 002.185 0 6.554 6.554 0 1113.108 0 1.092 1.092 0 002.185 0 8.74 8.74 0 00-5.134-7.952zm-3.605-.787a3.277 3.277 0 110-6.554 3.277 3.277 0 010 6.554zm10.64.35A5.462 5.462 0 0015.293.5a1.092 1.092 0 000 2.185 3.277 3.277 0 013.278 3.277 3.277 3.277 0 01-1.639 2.83 1.092 1.092 0 00-.054 1.856l.425.284.142.077a7.647 7.647 0 014.37 6.97 1.092 1.092 0 002.185 0 9.832 9.832 0 00-4.621-8.39z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default ParticipantsIcon;
