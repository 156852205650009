import * as React from 'react';

const PlusIcon = (props) => {
    return (
        <svg
            width={10}
            height={11}
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 .5a1 1 0 011 1v3h3a1 1 0 110 2H6v3a1 1 0 11-2 0v-3H1a1 1 0 110-2h3v-3a1 1 0 011-1z"
                fill="#0B1C2C"
            />
        </svg>
    );
};

export default PlusIcon;
