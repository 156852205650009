import React from 'react';
import { Tooltip } from 'antd';

export const getEllipseTextWithTooltip = (
    text,
    maxLength = 15,
    placement = 'top',
    maxWidth = null
) =>
    text?.length > maxLength ? (
        <>
            <Tooltip placement={placement} title={text} zIndex={9999}>
                <span className="tw-p-0 tw-m-0 tw-break-words">
                    {text.slice(0, maxLength - 1) + ' ...'}
                </span>
            </Tooltip>
            {maxWidth ? (
                <style jsx="true">
                    {`
                        .ant-tooltip-content {
                            max-width: ${maxWidth}px;
                        }
                    `}
                </style>
            ) : null}
        </>
    ) : (
        text
    );
