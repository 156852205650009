import * as React from "react"

const VideoIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 3a13 13 0 1013 13A13.015 13.015 0 0016 3zm4.555 13.832l-6 4A1 1 0 0113 20v-8a.999.999 0 011.555-.832l6 4a1 1 0 010 1.664z"
        fill="#7A829D"
      />
    </svg>
  )
}

export default VideoIcon
