import React from 'react';
import { Pagination } from 'antd';

export default function ListingCards({
    companyList = [],
    gotToDashboard = () => {},
    paginationData,
}) {
    const { currentPage, total, pageSize, onChange } = paginationData || {};
    return (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start md:tw-hidden tw-px-3 tw-py-7">
            {/* Projectlistsing */}
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-sm">Choose a project</span>
            </div>
            {/* cards */}
            {companyList &&
                companyList?.map((company) => (
                    <div
                        key={company.key}
                        className="tw-w-full tw-mt-4 tw-bg-white tw-rounded-lg tw-shadow-md tw-flex tw-flex-col tw-pt-6 tw-pb-2 tw-gap-3 "
                    >
                        <div className="tw-flex tw-flex-col tw-item-start tw-px-3 ">
                            <span className="tw-text-gray-800 tw-font-sans tw-text-base tw-font-semibold ">
                                {company.companyName}
                            </span>
                            <span className="tw-text-sm tw-font-normal tw-text-gray-600 tw-mb-2">
                                {company.projectName}
                            </span>
                        </div>
                        <hr className="tw-w-full tw-bg-divider-gray tw-m-0" />
                        <div className="tw-w-full tw-flex tw-justify-end tw-px-3">
                            <button
                                className="btn myxp btn-primary ml-auto tw-flex tw-w-full"
                                onClick={() => {
                                    gotToDashboard(
                                        company.key,
                                        company.project
                                    );
                                }}
                            >
                                Go to Dashboard
                            </button>
                        </div>
                    </div>
                ))}

            {/* pagination  */}
            <div className="tw-flex tw-justify-center tw-w-full">
                <Pagination
                    position={['none', 'bottomCenter']}
                    currentPage={currentPage}
                    total={total}
                    onChange={onChange}
                    pageSize={pageSize}
                />
            </div>
        </div>
    );
}
