import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
export async function GetNotification(projectId, notificationId) {
    setAuthBearer();
    const user = store?.getState()?.user;
    if (authBearer && user) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_NOTIFICATION}/xpmgr/notifications/${projectId}/${notificationId}`,

            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );

        return await response.json();
    }
}
