import * as ls from 'local-storage';
import isEmpty from 'lodash/isEmpty';
import {
    setCompanyDetails,
    setProject,
    updateCompanyDetails as updateCompanyDetailsAction,
} from 'redux/actions/projects/projectSettings';
import { setVenueSettings } from 'redux/actions/venues/actionsVenues';
import { store } from 'redux/store';
import { store as venueStore } from 'Apps/VenueBuilder/redux/store';
import { updateUser } from 'redux/actions/common/userActions';
import { apiInterceptor } from 'utils/apiInterceptor';
import { handleLogout } from 'ComponentsV2/ProjectDashboard/utils/handleLogout';

let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * gets livestream settings
 */
export async function getSettings(projectId) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (authBearer && user) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/settings/${projectId}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        const res = await response.json();
        ls.set('projectId', { projectId });
        if (res.status) {
            store.dispatch(setProject(res.setting));
            store.dispatch(
                setVenueSettings({
                    venueId: res?.setting?.venueId,
                    enableVenue: res?.setting?.enableVenue,
                    analyticsSiteId: res?.setting?.analyticsSiteId,
                    experience: res?.setting?.experience?.name,
                    venueName: res?.setting?.venueName,
                    customDomain: res?.setting?.customDomain,
                    favicon: res?.setting?.favicon,
                    cookieConsent: res?.setting?.cookieConsent,
                    fbPixelId: res?.setting?.fbPixelId,
                    gaId: res?.setting?.gaId,
                    gtmCode: res?.setting?.gtmCode,
                    removeBrandmarkWebsites:
                        res?.setting?.removeBrandmarkWebsites,
                    privateLandingPage: res?.setting?.privateLandingPage,
                    publicLandingPage: res?.setting?.publicLandingPage,
                    clearCacheJobIds: res?.setting?.clearCacheJobIds,
                    bulkPublishPagesDetails:
                        res?.setting?.bulkPublishPagesDetails || [],
                    wsToken: res?.setting?.wsToken,
                })
            );
            venueStore.dispatch({
                type: 'SET_VENUE_DETAILS',
                payload: {
                    venueId: res?.setting?.venueId,
                    enableVenue: res?.setting?.enableVenue,
                    analyticsSiteId: res?.setting?.analyticsSiteId,
                    experience: res?.setting?.experience?.name,
                    venueName: res?.setting?.venueName,
                },
            });
        } else {
            if (res?.message === 'ACCESS_TOKEN_EXPIRED') {
                handleLogout(false);
            }
        }

        return res;
    }
}

export async function getCompanyDetails(companyId) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (authBearer && user) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );

        ls.set('companyId', { companyId });

        const res = await response.json();
        if (res.status) {
            const companyDetails = {
                id: res.company?.id,
                name: res.company?.name,
                country: res?.company?.country,
                goalsCompleted: res.company?.goalsCompleted,
                goalsSkipped: res.company?.goalsSkipped,
                // calendlyDetails: res.company?.calendlyDetails,
                enable2FA: res.company?.enable2FA,
            };
            const updatedUserDetails = {
                ...user,
                company: companyDetails,
            };

            const userDetails = ls.get('userDetails');
            ls.set('userDetails', {
                ...userDetails,
                company: companyDetails,
                package: res?.company?.packageId ? res?.company?.package : null,
            });
            store.dispatch(updateUser({ user: updatedUserDetails }));
            store.dispatch(setCompanyDetails(res.company));

            venueStore.dispatch({
                type: 'SET_COMPANY_DETAILS',
                payload: res.company,
            });
        } else {
            if (res?.message === 'ACCESS_TOKEN_EXPIRED') {
                handleLogout(false);
            }
        }

        return res;
    }
}

export async function updateCompanyDetails(
    reqBody,
    companyId,
    reFetchCompanyDetails = true
) {
    setAuthBearer();
    const user = store?.getState()?.user;

    if (authBearer && user) {
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API_V2}/manager/companies/${companyId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
                body: JSON.stringify(reqBody),
            }
        );

        const res = await response.json();
        if (res.status && reFetchCompanyDetails) {
            const response = await getCompanyDetails(companyId);
            if (response.status) {
                const companyData = response?.company || {};
                if (!isEmpty(companyData)) {
                    const userObjectFromLocalStorage = store?.getState()?.user;
                    let updatedCompanies = [
                        ...userObjectFromLocalStorage?.companies,
                    ]?.map((company) =>
                        company?.id === companyData?.id
                            ? {
                                  ...company,
                                  name: companyData?.name,
                                  enable2FA:
                                      typeof companyData?.enable2FA === 'number'
                                          ? companyData?.enable2FA
                                          : company?.enable2FA,
                              }
                            : { ...company }
                    );

                    store.dispatch(
                        updateUser({
                            user: {
                                ...userObjectFromLocalStorage,
                                company: {
                                    ...userObjectFromLocalStorage?.company,
                                    name: companyData?.name,
                                    enable2FA:
                                        typeof companyData?.enable2FA ===
                                        'number'
                                            ? companyData?.enable2FA
                                            : userObjectFromLocalStorage
                                                  ?.company?.enable2FA,
                                },
                                companies: updatedCompanies,
                            },
                        })
                    );
                }

                store.dispatch(updateCompanyDetailsAction(companyData));
                venueStore.dispatch({
                    type: 'UPDATE_COMPANY_DETAILS',
                    payload: companyData,
                });
            }
        } else {
            if (res?.message === 'ACCESS_TOKEN_EXPIRED') {
                handleLogout(false);
            }
        }

        return res;
    }
}
