import { gql } from "apollo-boost";

const CREATE_PERSON = gql`
  mutation CreatePerson($input: createPersonInput) {
    createPerson(input: $input) {
      person {
        id
      }
    }
  }
`;

export default CREATE_PERSON;
