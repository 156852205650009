import { gql } from 'apollo-boost';

const SHOWCASE = gql`
    query Showcase($id: ID!) {
        showcase(id: $id) {
            title
            titleColor
            titleHidden
            featuredLivestreamTitle
            slug
            isPublished
            banner {
                id
                name
                url
            }
            customCss
            backgroundImage {
                id
                name
                url
            }
            backgroundColor
            logo {
                id
                name
                url
            }
            hasCustomLogo
            hasCustomBackground
            overviewTitle
            overviewHidden
            bannerHidden
            companyProfileHidden
            logoHidden
            allCompanyProfileHidden
            callToActionText
            callToActionValue
            callToActionAction
            callToActionHidden
            callToActionIcon
            overviewSummary
            overview
            categoriesToShow
            isPublic
            preventAddBlock
            preventDeleteBlock
            showcaseblocks {
                id
                title
                type
                weight
                name
            }
            id
            permission
            preventEditTitle
            preventEditName
            preventEditSize
            mobileBreakpoints {
                hasCustomBackground
                backgroundImage {
                    id
                    name
                    url
                }
            }
            tabletBreakpoints {
                hasCustomBackground
                backgroundImage {
                    id
                    name
                    url
                }
            }
        }
    }
`;

export default SHOWCASE;
