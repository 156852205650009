import React, { useEffect, useState } from 'react';

import EyeIcon from 'Apps/VenueBuilder/Icons/EyeIcon';
import EyeClosedIcon from 'Apps/VenueBuilder/Icons/EyeClosedIcon';
import { Tooltip, Input, Slider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import RichTextEditor from 'components/app/common/richTextEditor';
import styles from './index.module.css';
import CodeEditor from '../CodeEditor';
import sanitizeContent from 'utils/InputSanitization';

const SettingsText = ({
    headingTitle,
    tooltipText,
    isVisible,
    setVisibility,
    isToggleble,
    type = 'text',
    placeholder,
    value,
    name,
    setValue,
    error,
    errorType = '',
    hint,
    isRequired = false,
    required,
    iframe,
    textType = '',
    twCss = '',
    maxRange = 1,
}) => {
    const [isEyeVisible, setIsEyeVisible] = useState(true);

    const handleHideContent = (value) => {
        setVisibility(value);
    };

    useEffect(() => {
        setIsEyeVisible(isVisible);
    }, [isVisible]);

    const renderInputs = (type) => {
        switch (type) {
            case 'text':
                return (
                    <Input
                        name={name}
                        onChange={(e) =>
                            setValue(
                                `${name}`,
                                sanitizeContent({
                                    str: e.target.value,
                                    sanitizeOptions: {
                                        ALLOWED_TAGS: [],
                                        ALLOWED_ATTR: [],
                                    },
                                })
                            )
                        }
                        defaultValue={value}
                        value={value}
                        placeholder={placeholder}
                        disabled={name !== 'heading' && !isEyeVisible}
                    />
                );
            case 'color':
                return (
                    <div className="input-group w-100">
                        <input
                            name={name}
                            onChange={(e) =>
                                setValue(`${name}`, e.target.value)
                            }
                            value={value}
                            defaultValue={value}
                            placeholder={placeholder}
                            disabled={name !== 'heading' && !isEyeVisible}
                            type="color"
                            className="input--color"
                        />
                        <input
                            type="text"
                            disabled
                            className="form-control input--color_content cta-color-input"
                            value={value}
                            defaultValue={value}
                            name={name}
                            onChange={(e) =>
                                setValue(`${name}`, e.target.value)
                            }
                        />
                    </div>
                );
            case 'richtext':
                return (
                    // <RichTextEditor
                    //     id={name}
                    //     model={value}
                    //     onModelChange={setValue}
                    //     allowOnlyLineBreaks
                    //     htmlAllowedTags={['br', 'span', 'p', 'div', 'iframe']}
                    //     textType={textType || 'description'}
                    //     heightMin={150}
                    //     iframe={iframe}
                    // />
                    <CodeEditor
                        // headingTitle="Content"
                        // tooltipText="HTML or plain text"
                        allowedTags={['br', 'span', 'p', 'div', 'iframe']}
                        isRequired={false}
                        setValue={setValue}
                        value={value}
                        controlled={false}
                    />
                );
            case 'range':
                return (
                    <Slider
                        style={{ width: '100%' }}
                        tooltip={{ open: true }}
                        defaultValue={parseInt(value)}
                        min={1}
                        max={maxRange}
                        onChange={(num) => setValue(`${name}`, num)}
                    />
                );
            default:
                return (
                    <Input.TextArea
                        type="text"
                        onChange={(e) => setValue(`${name}`, e.target.value)}
                        defaultValue={value}
                        placeholder={placeholder}
                        disabled={name !== 'heading' && !isEyeVisible}
                    />
                );
        }
    };

    return (
        <div
            className={`${styles.SettingsTextContainer} settingstext ${twCss}`}
        >
            <div className={styles.Heading}>
                <h3>
                    {headingTitle}{' '}
                    {(required || isRequired) && (
                        <span className="required">*</span>
                    )}
                    {tooltipText && (
                        <Tooltip placement="right" title={tooltipText}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    )}
                </h3>
            </div>
            <div
                className={`${type !== 'richtext' && styles.InputContainer} ${
                    error ? styles.InputError : ''
                }`}
            >
                {renderInputs(type)}
                {/* {type === 'text' ? (
                    <Input
                        name={name}
                        onChange={(e) => setValue(`${name}`, e.target.value)}
                        defaultValue={value}
                        placeholder={placeholder}
                        disabled={name !== 'heading' && !isEyeVisible}
                    />
                ) : (
                    <Input.TextArea
                        type="text"
                        onChange={(e) => setValue(`${name}`, e.target.value)}
                        defaultValue={value}
                        placeholder={placeholder}
                        disabled={name !== 'heading' && !isEyeVisible}
                    />
                )}
                {type === 'color' &&
                    <input
                        name={name}
                        type="color"
                        className="input--color"
                        onChange={(e) => setValue(`${name}`, e.target.value)}
                        value={value}
                        defaultValue={value}
                        placeholder={placeholder}
                        disabled={name !== 'heading' && !isEyeVisible}
                    />
                } */}
                {isToggleble ? (
                    isEyeVisible ? (
                        <span onClick={() => handleHideContent(false)}>
                            <EyeIcon isVisible={isEyeVisible} />
                        </span>
                    ) : (
                        <span onClick={() => handleHideContent(true)}>
                            <EyeClosedIcon isVisible={isEyeVisible} />
                        </span>
                    )
                ) : null}
            </div>
            {hint && <span className="hint">{hint}</span>}
            {error && (
                <p className={styles.ErrorMessage}>
                    {error.type === 'required'
                        ? 'Required'
                        : errorType === 'message'
                        ? error.message
                        : error.type === 'validate'
                        ? 'Invalid URL'
                        : error.type}
                </p>
            )}
            <style jsx="true">{`
                .settingstext .hint {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    white-space: pre-wrap;
                }
                .settingstext input[type='text'] {
                    margin-bottom: 0px;
                }
                .ant-input {
                    margin-bottom: 0px;
                    padding: 8px 11px;
                    border-radius: 4px;
                }
                .ant-input-affix-wrapper > input.ant-input {
                    margin-bottom: 0px;
                }
                .ant-input-affix-wrapper {
                    border-radius: 4px;
                }
                .settingstext span.required {
                    color: #dc3545 !important;
                }
            `}</style>
        </div>
    );
};

export default SettingsText;
