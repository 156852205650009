import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import {
    exportChatData,
    getChatCount,
    exportAllChatData,
} from 'apis/rest/Utilities.js';
import { Table, Button } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import FileSaver from 'file-saver';
import PageSpinner from 'components/app/common/pageSpinner';

import './chat.scss';
import Search from 'components/app/components/venue/search';

const Chat = ({ projectId }) => {
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [chatExporting, setChatExporting] = useState(null);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [searchDebounceText, setSearchDebounceText] = useState(null);

    const handleSearchInput = (searchTerm) => {
        setSearchValue(searchTerm);
        searchDebounce(searchTerm);
    };

    const searchDebounce = useCallback(
        debounce((searchTerm) => setSearchDebounceText(searchTerm), 500),
        []
    );

    const getChats = async (id) => {
        setLoading(true);
        try {
            const res = await getChatCount(
                id,
                pagination?.currentPage || 1,
                searchValue
            );
            if (res.status) {
                setData(res.chats);
                setPagination(res.pagination);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setError(error.message);
            setLoading(false);
        }
    };

    const handleExport = async (showcaseId, title) => {
        setChatExporting(showcaseId);
        try {
            const res = await exportChatData(projectId, showcaseId);
            FileSaver.saveAs(res, `${title}.csv`);
            setChatExporting(null);
        } catch (error) {
            console.error(error);
            setChatExporting(null);
        }
    };

    const exportAllChats = async () => {
        setExporting(true);
        try {
            const res = await exportAllChatData(projectId);
            FileSaver.saveAs(res, `${projectId}.zip`);
            setExporting(false);
        } catch (error) {
            console.error(error);
            setExporting(false);
        }
    };

    const handlePageChange = async (selected) => {
        try {
            setLoading(true);
            const res = await getChatCount(projectId, selected, searchValue);
            if (res.status) {
                setData(res.chats);
                setPagination(res.pagination);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearch = async (value) => {
        try {
            setLoading(true);
            setSearchValue(value);
            const res = await getChatCount(projectId, 1, value);
            if (res.status) {
                setData(res.chats);
                setPagination(res.pagination);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (projectId) {
            setLoading(true);
            getChats(projectId);
        }
    }, [projectId]);

    useEffect(() => {
        if (searchDebounceText !== null) {
            handleSearch(searchDebounceText);
        }
    }, [searchDebounceText]);

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="ChatData tw-w-full tw-py-4">
            <div className="tw-flex tw-flex-row tw-gap-2 tw-w-full tw-justify-end tw-mb-2 tw-p-4">
                <Button
                    variant="primary"
                    className="chat-export-all"
                    disabled={loading || exporting || chatExporting}
                    onClick={exportAllChats}
                >
                    {exporting ? 'Exporting...' : 'Export all'}
                </Button>
                <div className="input-group">
                    <Search
                        searchRef={inputRef}
                        searchValue={searchValue}
                        disabled={exporting || chatExporting}
                        handleSearch={handleSearchInput}
                        placeholderText="Search"
                    />
                </div>
            </div>
            {loading && (
                <Table
                    borderless
                    hover
                    size="sm"
                    responsive
                    className="table table-borderless chat-data-table chat-data-table-preloader"
                >
                    <PageSpinner
                        type="Oval"
                        color="#ACBDC9"
                        height={45}
                        width={45}
                        msg="Fetching data..."
                        custom={{ marginTop: '200px' }}
                    />
                </Table>
            )}
            {!loading && (!data || data.length === 0) && (
                <div>No chats found</div>
            )}
            {!loading && data && data.length > 0 && (
                <>
                    <Table
                        borderless
                        hover
                        size="sm"
                        responsive
                        className="table table-borderless chat-data-table"
                    >
                        <thead className="text-small text-muted">
                            <tr>
                                <th>LivePage</th>
                                <th>Messages</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(({ id, count, name }, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{name ?? 'NA'}</td>
                                        <td>{count ?? 'NA'}</td>
                                        <td>
                                            <button
                                                className="btn btn-export"
                                                disabled={
                                                    exporting ||
                                                    chatExporting === id
                                                }
                                                onClick={() =>
                                                    handleExport(id, name)
                                                }
                                            >
                                                {chatExporting === id
                                                    ? 'Exporting...'
                                                    : 'Export'}{' '}
                                                <img src="/assets/icon_download.svg" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <div className="chat-table-paginate align-items-center d-flex justify-content-between tw-p-7">
                        <p className="mb-0 font-weight-bold mr-1">
                            Total: {pagination?.total || 0}
                        </p>
                        <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel="..."
                            breakClassName="break-me"
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active"
                            pageCount={pagination?.totalPage || 0}
                            initialPage={pagination?.currentPage - 1}
                            onPageChange={({ selected }) => {
                                if (selected !== pagination?.currentPage - 1)
                                    handlePageChange(selected + 1);
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
export default Chat;
