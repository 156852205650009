import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as ls from 'local-storage';

import {
    generateSponsorOtp,
    sponsorTempAuthenticate,
} from 'apis/rest/Authentication';
import loginValidationCriteria from 'components/app/utils/validators/loginValidator';
import useAlert from 'components/app/utils/hooks/useAlert';
import useFormValidator from 'components/app/utils/hooks/useFormValidator';
import Alert from 'components/app/common/alert';
import OTPInput from 'otp-input-react';
import NotificationPopup from 'ComponentsV2/CommonComponents/NotificationPopup';

import '../login.scss';
import '../otp/otp.scss';

import PhoneInput from 'react-phone-input-2';
import styles from '../index.module.scss';

const logoSource = '/assets/gevme-new-logo.png';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

function SponsorLogin() {
    const [email, setEmail] = useState('');
    const [sendBySms, setSendBySms] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const history = useHistory();

    const [otp, setOtp] = useState('');
    const [inputDisabled, setInputDisabled] = useState(false);
    const [loginByEmail, setLoginByEmail] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState({});
    const [showOtpView, setOtpView] = useState(false);

    const { alert, showAlert } = useAlert();

    const { validateInput, renderFieldError, hasErrors } = useFormValidator({
        input: { email },
        validationCriteria: loginValidationCriteria,
    });

    const login = async (data, validate = true) => {
        setIsBusy(true);
        let _hasErrors = false;

        if (validate) {
            const formErrors = validateInput();

            _hasErrors = hasErrors(formErrors);
        }

        if (!_hasErrors) {
            const response = await generateSponsorOtp(data);
            const responseJson = await response.json();

            if (responseJson.status) {
                setOtpView(true);
            }

            if (!responseJson.status) showAlert(responseJson.message, 'error');
            setIsBusy(false);
        }
    };
    const submitEmail = async (e) => {
        try {
            e.preventDefault();
            if (loginByEmail) {
                await login({
                    identifier: email,
                    sms: false,
                });
            } else {
                await login({
                    identifier: phoneNumber?.value,
                    sms: true,
                });
            }
        } catch (error) {
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOtpChange = (e) => {
        try {
            setOtp(e);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOtpUpdate = async () => {
        try {
            setInputDisabled(true);
            const response = await sponsorTempAuthenticate({
                code: otp,
                identifier: email,
            });
            if (response.status) {
                ls.set('sponsorTempAuth', response.token);
                ls.set('sponsorIdentifier', email);
                history.push('/sponsor/companyProfiles');
            } else {
                setInputDisabled(false);
                showAlert(response?.message || DEFAULT_ERROR_MESSAGE, 'error');
            }
        } catch (error) {
            setInputDisabled(false);
            showAlert(error.message || DEFAULT_ERROR_MESSAGE, 'error');
        }
    };

    const handleInputChangePhone = (value, countryCode) => {
        setPhoneNumber({ value, countryCode });
    };

    useEffect(() => {
        if (otp.length === 6) {
            handleOtpUpdate();
        }
    }, [otp]);

    useEffect(() => {
        if (ls.get('sponsorTempAuth')) {
            history.replace('/sponsor/companyProfiles');
        } else if (ls.get('auth')) {
            history.replace('/');
        }
    }, []);

    const inputStyles = {
        fontSize: '2.2em',
        fontWeight: 'bold',
        width: '45px',
        height: '60px',
    };

    return (
        <>
            <Alert alert={alert} />
            {!showOtpView ? (
                <div className={`${styles.login} login`}>
                    <div className={styles.wrapper}>
                        <NotificationPopup />
                        <div
                            className={`${styles.innerWrapper} tw-p-4 tw-pt-10`}
                        >
                            <form className="login__box" onSubmit={submitEmail}>
                                <img
                                    src={logoSource}
                                    alt="logo"
                                    className="login__logo"
                                />

                                <h1>Exhibitor Dashboard</h1>

                                <p>
                                    Log in to your Exhibitor Dashboard account
                                </p>

                                <div className="tw-flex tw-justify-between tw-w-full mb-2">
                                    <span className="tw-text-gray-700 tw-font-open-sans tw-text-sm tw-font-semibold tw-leading-normal">
                                        {loginByEmail
                                            ? 'Email'
                                            : 'Phone Number'}
                                    </span>
                                    <span
                                        className="tw-text-blue-500 tw-font-open-sans tw-text-sm tw-font-semibold tw-leading-normal tw-text-right tw-cursor-pointer "
                                        onClick={() =>
                                            setLoginByEmail(!loginByEmail)
                                        }
                                    >
                                        {loginByEmail
                                            ? 'Log in with Phone Number'
                                            : 'Log in with Email'}
                                    </span>
                                </div>

                                {loginByEmail ? (
                                    <div className="form-group tw-mb-8">
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            className="form-control tw-py-3.5 tw-px-4 tw-min-h-[48px]"
                                            onChange={handleInputChange}
                                            placeholder="Enter your email"
                                            autoComplete="off"
                                            required={loginByEmail}
                                        />
                                        {renderFieldError('email')}
                                    </div>
                                ) : (
                                    <>
                                        <div className="form-group tw-mb-8">
                                            <PhoneInput
                                                name="phoneNo"
                                                country="sg"
                                                enableSearch
                                                value={phoneNumber?.value}
                                                inputStyle={{
                                                    width: '100%',
                                                    minHeight: '48px',
                                                }}
                                                className="phoneInput-Login"
                                                onChange={(
                                                    value,
                                                    data,
                                                    _,
                                                    formattedValue
                                                ) => {
                                                    handleInputChangePhone(
                                                        formattedValue.replaceAll(
                                                            '-',
                                                            ''
                                                        ),
                                                        data.countryCode
                                                    );
                                                }}
                                            />

                                            {renderFieldError('phoneNumber')}
                                        </div>
                                        <style>{`
                                    .phoneInput-Login .country-list .country{
                                        display: flex;
                                        align-items: center;
                                    }
                                    .phoneInput-Login .country-list .search{
                                        display: flex;
                                        align-items: center;
                                    }
                                    `}</style>
                                    </>
                                )}

                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    disabled={!!isBusy}
                                >
                                    {isBusy ? (
                                        <span>
                                            <img
                                                src="/assets/icon_spinner.gif"
                                                alt=""
                                            />
                                            Please wait...
                                        </span>
                                    ) : (
                                        'Next'
                                    )}
                                </button>
                            </form>

                            <p className="foot-note">
                                By logging in you agree to the{' '}
                                <a
                                    href="https://www.gevme.com/en/terms-of-use/"
                                    target="_blank"
                                    className=""
                                    rel="noreferrer"
                                >
                                    Terms of Use
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://www.gevme.com/en/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${styles.login} otp`}>
                    <div className={styles.wrapper}>
                        <div className={styles.innerWrapper}>
                            <div className={`${styles.otpBox} otp__box`}>
                                <img src="/assets/gevme-new-logo.png" alt="" />

                                <h1>
                                    Check your {sendBySms ? 'phone' : 'email'}{' '}
                                    for your OTP
                                </h1>

                                <p>
                                    To confirm your email address, we&apos;ve
                                    sent an One-Time Password to{' '}
                                    {sendBySms
                                        ? 'your phone number which is associated to'
                                        : ''}{' '}
                                    {email || ''}
                                    .
                                    <br />
                                    Check your {sendBySms
                                        ? 'phone'
                                        : 'email'}{' '}
                                    and key it below to proceed.
                                </p>

                                <OTPInput
                                    inputStyles={inputStyles}
                                    value={otp}
                                    onChange={handleOtpChange}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={inputDisabled}
                                    className="otp-box"
                                />

                                <p>
                                    An OTP has been sent to your{' '}
                                    {sendBySms
                                        ? 'phone number'
                                        : 'email address'}{' '}
                                    if you are registered.
                                </p>
                                <div className="mt-4">
                                    <p className="foot-note">
                                        By logging in you agree to the{' '}
                                        <a
                                            href="https://www.gevme.com/en/terms-of-use/"
                                            target="_blank"
                                            className=""
                                            rel="noreferrer"
                                        >
                                            Terms of Use
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://www.gevme.com/en/privacy-policy/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SponsorLogin;
