import * as ls from 'local-storage';
import { checkIfAnalyticsExpired } from 'utils/auth';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearerXP = null;
let auth = {};
const env = process.env.REACT_APP_ENVIRONMENT.includes('dev')
    ? 'development'
    : process.env.REACT_APP_ENVIRONMENT.includes('staging')
    ? 'staging'
    : 'production';

const setAuth = async () => {
    const isSponsor = !!ls.get('sponsorIdentifier');
    const token = ls.get('analyticsAuth');
    if (isSponsor) {
        const isTokenExpired = checkIfAnalyticsExpired(
            token?.[window?.SPONSOR_AUTH?.companyId] || {
                accessToken: token?.accessToken,
            }
        );
        if (!isTokenExpired) {
            auth = token?.[window?.SPONSOR_AUTH?.companyId] || {
                accessToken: token?.accessToken,
            };
        } else {
            const { status, accessToken, message } = await getAnalyticsToken();
            if (status) {
                if (window?.SPONSOR_AUTH?.companyId) {
                    const analyticsAuthValue = token || {};

                    ls.set('analyticsAuth', {
                        ...analyticsAuthValue,
                        [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                    });
                } else {
                    ls.set('analyticsAuth', { accessToken });
                }

                auth = { status, accessToken, message };
            }
        }
    } else {
        const isTokenExpired = checkIfAnalyticsExpired(token);
        if (!isTokenExpired) auth = token;
        else {
            const { status, accessToken, message } = await getAnalyticsToken();
            if (status) {
                if (window?.SPONSOR_AUTH?.companyId) {
                    const analyticsAuthValue = token || {};

                    ls.set('analyticsAuth', {
                        ...analyticsAuthValue,
                        [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                    });
                } else {
                    ls.set('analyticsAuth', { accessToken });
                }

                auth = { status, accessToken, message };
            }
        }
    }
};

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearerXP = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearerXP = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

const fetchWrapper = async (url, options) => {
    try {
        const { response } = await apiInterceptor(url, options, false);

        if (!response.ok && response.status === 403) {
            const { accessToken = null } = await getAnalyticsToken();
            if (accessToken) {
                if (window?.SPONSOR_AUTH?.companyId) {
                    const analyticsAuthValue = ls.get('analyticsAuth') || {};

                    ls.set('analyticsAuth', {
                        ...analyticsAuthValue,
                        [window?.SPONSOR_AUTH?.companyId]: { accessToken },
                    });
                } else {
                    ls.set('analyticsAuth', { accessToken });
                }
                options['headers']['Authorization'] = `Bearer ${accessToken}`;
                return await fetch(url, options, false);
            }
        }
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getVisitors = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    page = 1,
    limit = 10,
    sortBy = null,
    sortOrder = null
) => {
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/visits?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${sortBy ? `&sortBy=${sortBy}` : ''}` +
                `${sortOrder ? `&sortOrder=${sortOrder}` : ''}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportVisitors = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    viewType
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${process.env.REACT_APP_ANALYTICS_API}/visits/${
                viewType ? 'users/' : ''
            }export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getVisitorUsers = async (
    livepageId,
    projectId,
    userId,
    timezone,
    startDate,
    endDate,
    page = 1,
    sortBy = 'visit_last_action_time',
    sortOrder = 'desc',
    limit = 10
) => {
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/visits/users?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&userId=${userId}&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}&sortBy=${sortBy}&sortOrder=${sortOrder}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportVisitorUsers = async (
    livepageId,
    projectId,
    userId,
    timezone,
    startDate,
    endDate
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/visits/users/export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&userId=${userId}&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getPageViews = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    page = 1,
    limit = 10,
    sortBy = null,
    sortOrder = null
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/page-views?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${sortBy ? `&sortBy=${sortBy}` : ''}` +
                `${sortOrder ? `&sortOrder=${sortOrder}` : ''}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportPageViews = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    viewType = null
) => {
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${process.env.REACT_APP_ANALYTICS_API}/actions/page-views/${
                viewType ? 'users/' : ''
            }export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${viewType && viewType !== 'all' ? `&view=${viewType}` : ''}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getPageViewsUsers = async (
    livepageId,
    projectId,
    timezone,
    pageName,
    view,
    startDate,
    endDate,
    page = 1,
    sortBy = 'visit_last_action_time',
    sortOrder = 'desc',
    limit = 10
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/page-views/users?pageName=${encodeURIComponent(
                pageName
            )}&view=${view}&projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}&sortBy=${sortBy}&sortOrder=${sortOrder}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportPageViewsUsers = async (
    livepageId,
    projectId,
    timezone,
    pageName,
    view,
    startDate,
    endDate
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/page-views/users/export?pageName=${pageName}&view=${view}&projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getContentInteractions = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    contentName = null,
    contentType = null,
    page = 1,
    limit = 10,
    sortBy,
    sortOrder
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/interactions?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${
                    contentName
                        ? `&contentName=${encodeURIComponent(contentName)}`
                        : ''
                }` +
                `${
                    contentType
                        ? `&contentType=${encodeURIComponent(contentType)}`
                        : ''
                }` +
                `${sortBy ? `&sortBy=${sortBy}` : ''}` +
                `${sortOrder ? `&sortOrder=${sortOrder}` : ''}`,

            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportContentInteractions = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    contentName = null,
    contentType = null,
    viewType = null
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${process.env.REACT_APP_ANALYTICS_API}/actions/interactions/${
                viewType ? 'users/' : ''
            }export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${
                    contentName
                        ? `&contentName=${encodeURIComponent(contentName)}`
                        : ''
                }` +
                `${
                    contentType
                        ? `&contentType=${encodeURIComponent(contentType)}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getContentInteractionUsers = async (
    livepageId,
    projectId,
    timezone,
    contentName,
    contentType,
    contentTarget = null,
    startDate,
    endDate,
    page = 1,
    sortBy = 'activity_time',
    sortOrder = 'desc',
    limit = 10
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/interactions/users?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&contentName=${encodeURIComponent(
                contentName
            )}&contentType=${encodeURIComponent(contentType)}${
                contentTarget
                    ? `&contentTarget=${encodeURIComponent(contentTarget)}`
                    : ''
            }&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}&sortBy=${sortBy}&sortOrder=${sortOrder}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportContentInteractionUsers = async (
    livepageId,
    projectId,
    timezone,
    contentName,
    contentType,
    contentTarget = null,
    startDate,
    endDate
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/actions/interactions/users/export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&contentName=${encodeURIComponent(
                contentName
            )}&contentType=${encodeURIComponent(contentType)}${
                contentTarget
                    ? `&contentTarget=${encodeURIComponent(contentTarget)}`
                    : ''
            }&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getMediaViews = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    page = 1,
    limit = 10,
    searchTerm = null,
    sortBy = null,
    sortOrder = null
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/media-views?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&page=${page}&limit=${limit}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${searchTerm ? `&search=${searchTerm}` : ''}` +
                `${sortBy ? `&sortBy=${sortBy}` : ''}` +
                `${sortOrder ? `&sortOrder=${sortOrder}` : ''}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportMediaViews = async (
    livepageId,
    projectId,
    timezone,
    startDate,
    endDate,
    viewType = null
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${process.env.REACT_APP_ANALYTICS_API}/media-views/${
                viewType ? 'users/' : ''
            }export?projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }` +
                `${viewType && viewType !== 'all' ? `&view=${viewType}` : ''}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getMediaUsers = async (
    livepageId,
    projectId,
    timezone,
    resource,
    view,
    mediaTitle,
    startDate,
    endDate,
    page = 1,
    limit = 10
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/media-views/users?resource=${encodeURIComponent(
                resource
            )}&projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&view=${view}&mediaTitle=${encodeURIComponent(
                mediaTitle
            )}&page=${page}&limit=${limit}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
export const exportMediaUsers = async (
    livepageId,
    projectId,
    timezone,
    resource,
    view,
    mediaTitle,
    startDate,
    endDate
) => {
    // const auth = ls.get('auth');
    try {
        await setAuth();
        const authBearer = `Bearer ${auth.accessToken}`;
        const exhibitorCompanyProfileId = window?.SPONSOR_AUTH?.companyId;
        const response = await fetchWrapper(
            `${
                process.env.REACT_APP_ANALYTICS_API
            }/media-views/users/export?resource=${encodeURIComponent(
                resource
            )}&projectId=${projectId}&livepageId=${
                livepageId === 'all' ? '' : livepageId
            }&timezone=${timezone}&startDateTime=${startDate}&endDateTime=${endDate}&view=${view}&mediaTitle=${encodeURIComponent(
                mediaTitle
            )}&env=${env}` +
                `${
                    exhibitorCompanyProfileId
                        ? `&exhibitorCompanyProfileId=${exhibitorCompanyProfileId}`
                        : ''
                }`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearer,
                },
            }
        );
        return await response.blob();
    } catch (error) {
        console.error(error);
    }
};
export const getAnalyticsToken = async () => {
    try {
        setAuthBearer();
        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/analytics/getAccessToken`,
            {
                method: 'GET',
                headers: {
                    Authorization: authBearerXP,
                },
            }
        );
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
