import * as React from 'react';

const AttendeeProfile = (props) => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12c0 6.628-5.372 12-12 12-6.627 0-12-5.372-12-12C0 5.373 5.373 0 12 0c6.628 0 12 5.373 12 12zm-9-4.5a3 3 0 11-6 0 3 3 0 016 0zm-3 6a7.501 7.501 0 00-6.82 4.374A8.98 8.98 0 0012 21a8.98 8.98 0 006.82-3.126A7.501 7.501 0 0012 13.5z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default AttendeeProfile;
