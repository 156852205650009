import React, { useState, useEffect } from 'react';

import NameEditor from 'components/app/components/showcase/blockEditor/nameEditor';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function SocialMedia(props) {
    const {
        handleInputChange,
        input,
        handleFileDelete,
        prepareFileUpload,
        renderFieldError,
        blockContentData = null,
        permissionDimension = null,
        permission = null,
        getSocialMediaValue,
        preventEditName = false,
        preventEditSize = false,
        preventEditTitle = false,
        getSocialMediaList,
    } = props;

    const showcaseblock = blockContentData?.showcaseblock;
    const { name } = input;

    const [facebook, setFacebook] = useState(input?.socialLinks?.facebook ? input?.socialLinks?.facebook : '')
    const [instagram, setInstagram] = useState(input?.socialLinks?.instagram ? input?.socialLinks?.instagram : '')
    const [linkedIn, setLinkedIn] = useState(input?.socialLinks?.linkedIn ? input?.socialLinks?.linkedIn : '')
    const [weChat, setWeChat] = useState(input?.socialLinks?.weChat ? input?.socialLinks?.weChat : '')
    const [youtube, setYouTube] = useState(input?.socialLinks?.youtube ? input?.socialLinks?.youtube : '')
    const [website, setWebsite] = useState(input?.socialLinks?.website ? input?.socialLinks?.website : '')

    useEffect(() => {
        getSocialMediaList(
            {
                facebook,
                instagram,
                linkedIn,
                weChat,
                youtube,
                website
            }
        )
    }, [facebook, instagram, linkedIn, weChat,youtube, website])

    return (
        <div>
            <div>
                <div className="row mb-3">
                    <div className="col-6">
                        <div className="input-set">
                            <label>Block title</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                onChange={(e) => handleInputChange(e)}
                                autoFocus
                                value={showcaseblock?.title || input.title}
                                disabled={preventEditTitle}
                                maxLength={70}
                            />
                            {renderFieldError('title')}
                        </div>
                        {/* <NameEditor
              handleInputChange={handleInputChange}
              name={name}
            /> */}
                        {!preventEditSize && (
                            <>
                                <label>Block size</label>
                                <div className="radio-set">
                                    <span>
                                        <input
                                            type="radio"
                                            value={false}
                                            name="largeTile"
                                            defaultChecked
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            id="normalBlockSize"
                                            disabled={preventEditSize}
                                        />{' '}
                                        <label htmlFor="normalBlockSize">
                                            Normal
                                        </label>
                                    </span>
                                    <span>
                                        <input
                                            type="radio"
                                            value={true}
                                            name="largeTile"
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            disabled={preventEditSize}
                                            id="bigBlockSize"
                                        />{' '}
                                        <label htmlFor="bigBlockSize">
                                            Big
                                        </label>
                                    </span>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="col-6">
                        {/* <label>
                            Thumbnail{' '}
                            <span className="small">
                                (.gif, .jpg/jpeg, .png, .svg)
                            </span>
                        </label>
                        <ImageUpload
                            // previewImages={image ? image : []}
                            prepareFilesUpload={files => {
                                prepareFileUpload(files, 'image')
                            }}
                            handleFileDelete={(files) => {
                                handleFileDelete(files, 'image')
                            }}
                            imageCropRecommendedSize={{
                                width: (permissionDimension &&
                                    permissionDimension[0]) ||
                                    null,
                                height: (permissionDimension &&
                                    permissionDimension[1]) ||
                                    null
                            }}
                        />
                       
                        {permissionDimension && (
                            <p>
                                Recommended size: within{' '}
                                {permissionDimension[0]} x{' '}
                                {permissionDimension[1]} px
                            </p>
                        )}
                        {renderFieldError('image')} */}
                    </div>
                </div>

                <label>Your Social Media Links</label>
                <div className="row">
                    <div className="col-12">
                        <div className="input-set">
                            <label>Facebook</label>
                            <input
                                type="text"
                                name="facebook"
                                className="form-control"
                                onChange={(e) => setFacebook(e.target.value)}
                                value={facebook}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-set">
                            <label>Instagram</label>
                            <input
                                type="text"
                                name="instagram"
                                className="form-control"
                                onChange={(e) => setInstagram(e.target.value)}
                                value={instagram}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-set">
                            <label>LinkedIn</label>
                            <input
                                type="text"
                                name="linkedIn"
                                className="form-control"
                                onChange={(e) => setLinkedIn(e.target.value)}
                                value={linkedIn}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-set">
                            <label>WeChat</label>
                            <input
                                type="text"
                                name="weChat"
                                className="form-control"
                                onChange={(e) => setWeChat(e.target.value)}
                                value={weChat}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-set">
                            <label>YouTube</label>
                            <input
                                type="text"
                                name="youtube"
                                className="form-control"
                                onChange={(e) => setYouTube(e.target.value)}
                                value={youtube}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-set">
                            <label>Web Site</label>
                            <input
                                type="text"
                                name="website"
                                className="form-control"
                                onChange={(e) => setWebsite(e.target.value)}
                                value={website}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialMedia;
