import React from 'react';

function GevmeLogoPortrait() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="32"
            fill="none"
            viewBox="0 0 46 32"
        >
            <path
                fill="#000"
                d="M5.34 32c2.03 0 3.097-.942 3.433-1.39l.274 1.18h1.267v-5.77H6.149v1.555h2.319c-.092 1.24-.9 2.736-3.037 2.736-2.12 0-3.57-1.615-3.57-3.753 0-2.047 1.435-3.752 3.54-3.752 1.556 0 2.365.778 2.777 1.81l1.754-.599c-.625-1.614-2.044-2.9-4.623-2.9C2.197 21.117 0 23.494 0 26.558 0 29.713 2.166 32 5.34 32zM18.494 28.651v-.687c0-2.272-1.389-3.842-3.601-3.842-2.167 0-3.845 1.674-3.845 3.931 0 2.302 1.678 3.947 3.86 3.947 1.861 0 2.96-.957 3.402-2.153l-1.54-.463c-.184.508-.794 1.136-1.862 1.136-1.205 0-2.03-.927-2.105-1.869h5.69zm-1.618-1.375h-4.058c.168-.852.854-1.674 2.09-1.674 1.312 0 1.923 1.001 1.968 1.674zM24.105 24.331l-1.968 5.427-1.968-5.427h-1.877l2.884 7.46H23.1l2.883-7.46h-1.877zM26.352 31.79h1.83v-4.23c0-1.196.657-1.779 1.48-1.779.763 0 1.313.434 1.313 1.57v4.44h1.83v-4.23c0-1.182.641-1.78 1.48-1.78.794 0 1.297.434 1.297 1.57v4.44h1.83v-4.933c0-1.914-1.036-2.736-2.455-2.736-1.175 0-1.969.523-2.441 1.121-.306-.583-.916-1.121-2.06-1.121-1.084 0-1.892.493-2.35 1.091l-.244-.882h-1.51v7.46zM45.587 28.651v-.687c0-2.272-1.389-3.842-3.601-3.842-2.167 0-3.845 1.674-3.845 3.931 0 2.302 1.678 3.947 3.86 3.947 1.861 0 2.96-.957 3.402-2.153l-1.54-.463c-.184.508-.794 1.136-1.862 1.136-1.205 0-2.029-.927-2.105-1.869h5.69zm-1.618-1.375h-4.058c.168-.852.854-1.674 2.09-1.674 1.312 0 1.922 1.001 1.968 1.674z"
            />
            <path
                fill="#FADA30"
                fillRule="evenodd"
                d="M7.916 1.048c-.209.411-.209.949-.209 2.024v6.144c0 1.075 0 1.613.21 2.024.183.361.477.655.838.839.411.21.949.21 2.024.21h4.606V9.18c0-.508 0-.942.03-1.3.03-.375.097-.743.277-1.096.27-.53.7-.96 1.23-1.23.354-.18.722-.247 1.097-.278.357-.03.791-.03 1.3-.03H29.338V3.073c0-1.075 0-1.613-.21-2.024a1.92 1.92 0 00-.838-.839C27.88 0 27.343 0 26.267 0H10.78C9.704 0 9.166 0 8.755.21a1.92 1.92 0 00-.839.838zM29.34 6.144h-9.986c-1.075 0-1.613 0-2.024.21a1.92 1.92 0 00-.839.838c-.209.411-.209.949-.209 2.024v3.072h9.986c1.076 0 1.613 0 2.024-.21a1.92 1.92 0 00.839-.838c.21-.411.21-.949.21-2.024V6.144z"
                clipRule="evenodd"
            />
            <path
                fill="url(#paint0_linear_21262_3195)"
                fillRule="evenodd"
                d="M7.916 1.048c-.209.411-.209.949-.209 2.024v6.144c0 1.075 0 1.613.21 2.024.183.361.477.655.838.839.411.21.949.21 2.024.21h4.606V9.18c0-.508 0-.942.03-1.3.03-.375.097-.743.277-1.096.27-.53.7-.96 1.23-1.23.354-.18.722-.247 1.097-.278.357-.03.791-.03 1.3-.03H29.338V3.073c0-1.075 0-1.613-.21-2.024a1.92 1.92 0 00-.838-.839C27.88 0 27.343 0 26.267 0H10.78C9.704 0 9.166 0 8.755.21a1.92 1.92 0 00-.839.838zM29.34 6.144h-9.986c-1.075 0-1.613 0-2.024.21a1.92 1.92 0 00-.839.838c-.209.411-.209.949-.209 2.024v3.072h9.986c1.076 0 1.613 0 2.024-.21a1.92 1.92 0 00.839-.838c.21-.411.21-.949.21-2.024V6.144z"
                clipRule="evenodd"
            />
            <rect
                width="21.632"
                height="12.288"
                x="16.281"
                y="6.144"
                fill="#FADA30"
                rx="1.92"
            />
            <path
                fill="url(#paint1_linear_21262_3195)"
                fillRule="evenodd"
                d="M29.341 6.144v3.072c0 1.075 0 1.613-.21 2.024a1.92 1.92 0 01-.838.839c-.411.209-.949.209-2.024.209h-9.986V9.216c0-1.075 0-1.613.21-2.024a1.92 1.92 0 01.839-.839c.41-.209.948-.209 2.023-.209h9.986z"
                clipRule="evenodd"
                opacity="0.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_21262_3195"
                    x1="7.733"
                    x2="29.328"
                    y1="0.008"
                    y2="12.421"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF2CFF" />
                    <stop offset="1" stopColor="#FF0C1B" stopOpacity="0.2" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21262_3195"
                    x1="7.732"
                    x2="29.328"
                    y1="0.008"
                    y2="12.424"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF2CFF" />
                    <stop offset="1" stopColor="#FF0C1B" stopOpacity="0.2" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default GevmeLogoPortrait;
