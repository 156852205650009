import React from 'react';

export default function CrossLinkIcon({ stroke = 'white' }) {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="20" rx="4" fill="#E1E7FA"/>
            <g clip-path="url(#clip0_17120_157113)">
                <path d="M11.1426 6.62922L11.8099 6.02255C12.0439 5.80959 12.3219 5.64063 12.6277 5.52534C12.9336 5.41004 13.2615 5.35066 13.5926 5.35059C13.9238 5.35052 14.2517 5.40976 14.5576 5.52493C14.8636 5.6401 15.1416 5.80894 15.3757 6.0218C15.6099 6.23466 15.7956 6.48738 15.9223 6.76552C16.049 7.04365 16.1141 7.34175 16.1141 7.64279C16.114 7.94382 16.0487 8.24189 15.9218 8.51998C15.795 8.79806 15.6092 9.05069 15.3749 9.26346L13.7545 10.7366C13.5204 10.9494 13.2425 11.1182 12.9366 11.2334C12.6308 11.3485 12.303 11.4078 11.972 11.4078C11.6409 11.4078 11.3131 11.3485 11.0073 11.2334C10.7014 11.1182 10.4235 10.9494 10.1895 10.7366" stroke="#7A829D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.8562 13.371L10.1889 13.9775C9.95489 14.1905 9.67698 14.3595 9.37109 14.4748C9.06521 14.5901 8.73733 14.6494 8.40619 14.6495C8.07505 14.6496 7.74714 14.5903 7.44119 14.4752C7.13524 14.36 6.85725 14.1912 6.6231 13.9783C6.38895 13.7654 6.20323 13.5127 6.07654 13.2346C5.94985 12.9564 5.88469 12.6583 5.88477 12.3573C5.88484 12.0563 5.95016 11.7582 6.07699 11.4801C6.20382 11.202 6.38967 10.9494 6.62393 10.7366L8.24438 9.2635C8.47846 9.0507 8.75636 8.88189 9.0622 8.76673C9.36804 8.65156 9.69584 8.59229 10.0269 8.59229C10.3579 8.59229 10.6857 8.65156 10.9916 8.76673C11.2974 8.88189 11.5753 9.0507 11.8094 9.2635" stroke="#7A829D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_17120_157113">
                    <rect width="14.6667" height="13.3333" fill={stroke || 'white'} transform="translate(3.66602 3.3335)"/>
                </clipPath>
            </defs>
        </svg>
    );
}
