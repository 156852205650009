import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function createLocation(inputs) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(inputs),
        }
    );
    return await response.json();
}

export async function getLocations(
    projectId,
    page = 1,
    search = '',
    sortOrder = 'asc',
    limit = 10
) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues?projectId=${projectId}` +
            `&page=${page}` +
            `&limit=${limit}` +
            `&search=${search}` +
            `&sortBy=name` +
            `&sortOrder=${sortOrder}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function getLocation(projectId, locationId) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${locationId}?projectId=${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function updateLocation(projectId, locationId, inputs) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${locationId}?projectId=${projectId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
            body: JSON.stringify(inputs),
        }
    );
    return await response.json();
}

export async function deleteLocation(projectId, locationId) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/${locationId}?projectId=${projectId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function getSpecificLocations(projectId, locations) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/physical-venues/specific?ids=${locations}&projectId=${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}

export async function getLocationsDetails(
    projectId,
    showcaseId,
    date,
    timezone = ''
) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/sessions/locations?showcaseId=${showcaseId}&date=${date}&timezone=${timezone}&projectId=${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
