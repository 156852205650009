import React, { useEffect, useState } from 'react';

import {
    Chat,
    Channel,
    ChannelHeader,
    ChannelList,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    Window,
    MessageInputSmall,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';

import { updateStreamUserRole } from 'apis/rest/livestream/UpdateStreamUserRole';
import PageSpinner from 'components/app/common/pageSpinner/pageSpinner';
import { getLivePages } from 'apis/rest/livestream/GetLivePages';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import styles from './index.module.scss';

const Dashboard = ({
    showcaseId,
    projectId,
    user,
    chatClient,
    clientUser,
    channelCount,
    setChannels,
    channels = [],
    setNotifyTab,
    isSponsorDashboard = false,
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [option, setOption] = useState({ limit: 10, offset: 0 });
    const [tabChannels, setTabChannels] = useState([]);

    const getAllSponsorPages = async (limit = 10, offset = 0) => {
        try {
            const res = await getLivePages(
                projectId,
                'title',
                'asc',
                limit,
                offset,
                '',
                [],
                '',
                1
            );
            if (res && res.status) {
                if (res.showcases.length === 0)
                    openNotification('success', { message: res.message });
                if (res.showcases.length !== 0) {
                    return res.showcases[0].id;
                }
            } else throw res;
        } catch (error) {
            openNotification('error', { message: error.message });
        }
    };

    const updateUserRoleRecursively = async (
        client,
        channelCount,
        offset = 0
    ) => {
        setOption({ limit: 10, offset });
        try {
            setLoading(true);
            let cs = '';

            if (isSponsorDashboard) {
                const id = await getAllSponsorPages(channelCount, offset);
                cs = id;
            }

            const res = await updateStreamUserRole(projectId, offset, cs);

            if (res?.status && res.channels.length) {
                setTabChannels(res.channels);
                setChannels((prev) => [...prev, ...res.channels]);
                setLoading(false);
            } else throw res;
        } catch (error) {
            console.error(error);
            setError(error);
            openNotification('error', {
                message: error?.message || 'Something went wrong!',
            });
        }
    };

    const removeNotification = (e) => {
        try {
            if (e.target.classList.contains('notified')) {
                e.target.classList.remove('notified');
            } else {
                e.target.removeEventListener('click', removeNotification);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const channelEvents = async (e) => {
        if (e.type === 'message.new') {
            setTimeout(() => {
                const btns = document.querySelectorAll(
                    'button.str-chat__channel-preview-messenger'
                );

                if (btns.length) {
                    btns.forEach((btn) => {
                        if (
                            btn.classList.contains(
                                'str-chat__channel-preview-messenger--unread'
                            )
                        ) {
                            btn.classList.add('notified');
                            btn.removeEventListener(
                                'click',
                                removeNotification
                            );
                            btn.addEventListener('click', removeNotification);
                        }
                    });
                }
            }, 1000);
        }
    };

    useEffect(() => {
        updateUserRoleRecursively(
            chatClient,
            channelCount,
            channels.length === 0 ? 0 : channels.length + 1
        );
    }, []);

    if (error) {
        return <p>{error.message}</p>;
    }

    const filters = { id: { $in: tabChannels } };
    const sort = isSponsorDashboard ? {} : { last_message_at: -1 };

    return (
        <div
            className={`${styles.DashboardWrapper} Wrapper ${
                loading ? `${styles.Loading}` : ''
            }`}
        >
            {loading ? (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={48}
                    width={48}
                    msg="Collecting all channels..."
                />
            ) : (
                <Chat client={chatClient} theme="messaging light">
                    <ChannelList
                        filters={filters}
                        sort={sort}
                        options={{ limit: 10 }}
                    />
                    <Channel>
                        <Window>
                            <ChannelHeader />
                            <MessageList messageActions={['edit', 'delete']} />
                            <MessageInput Input={MessageInputSmall} />
                        </Window>
                        <Thread />
                    </Channel>
                </Chat>
            )}
            <style jsx="true">
                {`
                    .str-chat__load-more-button {
                        display: none;
                    }
                    .str-chat {
                        height: 100%;
                    }
                    .str-chat.str-chat-channel {
                        flex: 1 1;
                    }
                    .str-chat__message-actions-box {
                        bottom: 10px;
                    }
                    .notified {
                        position: relative;
                    }
                    .notified:after {
                        content: '';
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        background: #f44336;
                        border-radius: 100%;
                        top: 0;
                        right: 0;
                    }

                    .str-chat__channel-preview-messenger--unread
                        .str-chat__channel-preview-messenger--last-message {
                        opacity: 0.5;
                        font-weight: normal;
                    }

                    .notified .str-chat__channel-preview-messenger--name {
                        color: #006cff;
                    }
                    .str-chat__channel-preview-messenger * {
                        pointer-events: none;
                    }
                    .str-chat__header-livestream-left--members {
                        display: none;
                    }
                    .str-chat-channel-list .str-chat__channel-list-messenger,
                    .str-chat-channel-list
                        .str-chat__channel-list-messenger__main {
                        height: 100%;
                    }
                `}
            </style>
        </div>
    );
};

export default Dashboard;
