import React from 'react';

export default function DeleteIcon({ stroke = '#000' }) {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 5H10.5C10.5 4.60217 10.342 4.22064 10.0607 3.93934C9.77935 3.65803 9.39782 3.5 9 3.5C8.60217 3.5 8.22064 3.65803 7.93934 3.93934C7.65803 4.22064 7.5 4.60217 7.5 5ZM6 5C6 4.20435 6.31607 3.44129 6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2C9.79565 2 10.5587 2.31607 11.1213 2.87868C11.6839 3.44129 12 4.20435 12 5H15.75C15.9489 5 16.1397 5.07902 16.2803 5.21967C16.421 5.36032 16.5 5.55109 16.5 5.75C16.5 5.94891 16.421 6.13968 16.2803 6.28033C16.1397 6.42098 15.9489 6.5 15.75 6.5H15.0885L14.424 14.255C14.3601 15.0038 14.0175 15.7014 13.4639 16.2098C12.9103 16.7181 12.1861 17.0001 11.4345 17H6.5655C5.81393 17.0001 5.08971 16.7181 4.53611 16.2098C3.98252 15.7014 3.63988 15.0038 3.576 14.255L2.9115 6.5H2.25C2.05109 6.5 1.86032 6.42098 1.71967 6.28033C1.57902 6.13968 1.5 5.94891 1.5 5.75C1.5 5.55109 1.57902 5.36032 1.71967 5.21967C1.86032 5.07902 2.05109 5 2.25 5H6ZM11.25 9.5C11.25 9.30109 11.171 9.11032 11.0303 8.96967C10.8897 8.82902 10.6989 8.75 10.5 8.75C10.3011 8.75 10.1103 8.82902 9.96967 8.96967C9.82902 9.11032 9.75 9.30109 9.75 9.5V12.5C9.75 12.6989 9.82902 12.8897 9.96967 13.0303C10.1103 13.171 10.3011 13.25 10.5 13.25C10.6989 13.25 10.8897 13.171 11.0303 13.0303C11.171 12.8897 11.25 12.6989 11.25 12.5V9.5ZM7.5 8.75C7.69891 8.75 7.88968 8.82902 8.03033 8.96967C8.17098 9.11032 8.25 9.30109 8.25 9.5V12.5C8.25 12.6989 8.17098 12.8897 8.03033 13.0303C7.88968 13.171 7.69891 13.25 7.5 13.25C7.30109 13.25 7.11032 13.171 6.96967 13.0303C6.82902 12.8897 6.75 12.6989 6.75 12.5V9.5C6.75 9.30109 6.82902 9.11032 6.96967 8.96967C7.11032 8.82902 7.30109 8.75 7.5 8.75ZM5.07 14.1275C5.10195 14.5021 5.27338 14.851 5.55033 15.1051C5.82729 15.3593 6.18959 15.5002 6.5655 15.5H11.4345C11.8102 15.4999 12.1721 15.3588 12.4487 15.1046C12.7254 14.8505 12.8966 14.5018 12.9285 14.1275L13.5825 6.5H4.4175L5.0715 14.1275H5.07Z"
                fill={stroke || '#000'}
            />
        </svg>
    );
}
