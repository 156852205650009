import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};

export async function GetChannels(
    projectId,
    page = 1,
    search = '',
    status = null
) {
    setAuthBearer();

    let url = `${process.env.REACT_APP_MYXP_API_V2}/manager/livestream/channels?projectId=${projectId}&page=${page}&limit=10&search=${search}`;
    if (status) {
        url += `&status=${status}`;
    }

    const { response } = await apiInterceptor(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authBearer,
        },
    });
    return await response.json();
}

export async function GetAllChannels(projectId) {
    setAuthBearer();

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/channels/allChannels?projectId=${projectId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authBearer,
            },
        }
    );
    return await response.json();
}
