import * as React from 'react';

const HeroContentBlockIcon = (props) => {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <rect width={32} height={32} rx={4} fill="#E1E7FA" />
            <rect x={5} y={5} width={22} height={10} rx={2} fill="#7A829D" />
            <rect x={11} y={17} width={10} height={3} rx={1.5} fill="#7A829D" />
            <rect x={11} y={17} width={10} height={3} rx={1.5} fill="#B9C1DB" />
            <rect x={8} y={22} width={16} height={3} rx={1.5} fill="#B9C1DB" />
        </svg>
    );
};

export default HeroContentBlockIcon;
