import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Menu, Dropdown, Button, Radio, Input } from 'antd';
import {
    separatePixels,
    getNumbers,
    blockWidthType,
} from '../../../helpers/common';
import BlockViewConfiguration from '../BlockViewConfiguration';
import { DownOutlined } from '@ant-design/icons';

import styles from './index.module.css';

const showHideTypes = [
    'boundingBox',
    'tabletBreakpoints.boundingBox',
    'mobileBreakpoints.boundingBox',
    'sessionTitleVisibility',
    'sessionDescriptionVisibility',
    'speakersVisibility',
    'moderatorsVisibility',
    'panelistsVisibility',
    'speakerJobTitleVisibility',
    'speakerCompanyVisibility',
    'speakerBioVisibility',
    'cardTitleVisibility',
    'cardDescriptionVisibility',
    'cardBoundingBox',
    'sessionLogoVisibility',
];

const SettingsRadio = ({
    property,
    headingTitle,
    choices,
    value,
    setValue,
    getValues,
    name,
    ref,
    displayMode,
    linkedSettings,
    setLinkedSettings,
}) => {
    const displayModeRedux = useSelector((state) => state?.header.display);
    const [widthType, setWidthType] = useState('full');

    const [pixelSelection, setPixelSelection] = useState(
        !showHideTypes.includes(name) ? separatePixels(value) : ''
    );

    const onChange = (name) => (e) => {
        if (showHideTypes.includes(name)) {
            setValue(`${name}`, e.target.value);
        } else {
            let blockwidth = e.target.value,
                blockWidthValue = '';

            if (blockwidth === 'full') {
                blockWidthValue = '100%';
            } else if (blockwidth === 'half') {
                blockWidthValue = '50%';
            } else if (blockwidth === 'fitcontent') {
                blockWidthValue = 'fit-content';
            } else {
                setValue(`${name}`, value);
            }

            setWidthType(blockWidthType(blockWidthValue));
            setValue(`${name}`, blockWidthValue);
        }
    };

    const onInputChange = (e) => {
        setValue(
            `${name}`,
            e.target.value ? e.target.value + pixelSelection : ''
        );
    };
    const handlePixelChange = (pix) => {
        const num = getNumbers(value, pixelSelection);
        setValue(`${name}`, num ? num + pix : num);
        setPixelSelection(pix);
    };

    // useEffect(() => {
    //     if (!showHideTypes.includes(name)) {
    //         setPixelSelection(separatePixels(value));
    //     }
    // }, [value]);

    useEffect(() => {
        if (property !== 'blockWidth') return;
        setWidthType(blockWidthType(value));
    }, [property]);

    const pixels = (
        <Menu>
            <Menu.Item onClick={() => handlePixelChange('px')}>px</Menu.Item>
            <Menu.Item onClick={() => handlePixelChange('rem')}>rem</Menu.Item>
            <Menu.Item onClick={() => handlePixelChange('em')}>em</Menu.Item>
            <Menu.Item onClick={() => handlePixelChange('%')}>%</Menu.Item>
            <Menu.Item onClick={() => handlePixelChange('vw')}>vw</Menu.Item>
        </Menu>
    );

    const displayStyle =
        displayMode && ['Bounding Box', 'Width'].includes(headingTitle)
            ? {
                  display:
                      (displayMode === 'desktop' && displayModeRedux.desktop) ||
                      (displayMode === 'tablet' && displayModeRedux.tablet) ||
                      (displayMode === 'mobile' && displayModeRedux.mobile)
                          ? 'block'
                          : 'none',
              }
            : { display: 'block' };

    return (
        <div
            style={{ ...displayStyle }}
            className={`${styles.SettingsRadioContainer} settingsradio`}
        >
            <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                <h3>{headingTitle}</h3>
                {displayMode &&
                ['boundingBox', 'blockWidth'].includes(property) ? (
                    <BlockViewConfiguration
                        property={property}
                        setValue={setValue}
                        getValues={getValues}
                        linkedSettings={linkedSettings}
                        setLinkedSettings={setLinkedSettings}
                    />
                ) : null}
            </div>

            <Radio.Group
                onChange={onChange(name)}
                value={
                    showHideTypes.includes(name) ? value : widthType || 'full'
                }
                type="radio"
            >
                {choices?.map((item) => {
                    if (item?.disabled) return null;
                    return (
                        <Radio
                            key={item.value}
                            value={item.value}
                            className={
                                widthType === 'custom' &&
                                item.value === 'custom'
                                    ? 'specific-width'
                                    : ''
                            }
                        >
                            {/* For specific width */}
                            {widthType === 'custom' &&
                            item.value === 'custom' ? (
                                <div className={styles.SpecificWidthContainer}>
                                    <span className={styles.RadioCustomSpan}>
                                        Specific width
                                    </span>
                                    <div className={styles.RadioDescription}>
                                        <h3>Width</h3>
                                        <div
                                            className={
                                                styles.InputDropdownGroup
                                            }
                                        >
                                            <Input
                                                type="number"
                                                onChange={(e) =>
                                                    onInputChange(e)
                                                }
                                                defaultValue={
                                                    getNumbers(
                                                        value,
                                                        pixelSelection
                                                    ) || ''
                                                }
                                            />
                                            <Dropdown
                                                className={
                                                    styles.PixelCustomDropdown
                                                }
                                                trigger={['click']}
                                                overlay={pixels}
                                            >
                                                <Button>
                                                    {pixelSelection}
                                                    <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                item.name
                            )}
                        </Radio>
                    );
                })}
            </Radio.Group>
            <style jsx="true">{`
                .ant-radio-group {
                    border: 1px solid #dadeeb;
                    border-radius: 4px;
                    width: 100%;
                }
                .ant-radio-wrapper {
                    display: flex !important;
                    align-items: center;
                    padding: 16px;
                    border-bottom: 1px solid #e3e8f7;
                    margin: 0;
                }
                .ant-radio-wrapper:last-child {
                    border-bottom: 0px;
                }
                span.ant-radio + * {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
                .settingsradio .specific-width .ant-radio {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                }
                /* Chrome, Safari, Edge, Opera */
                .specific-width input::-webkit-outer-spin-button,
                .specific-width input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                .specific-width input[type='number'] {
                    -moz-appearance: textfield;
                }
            `}</style>
        </div>
    );
};

export default SettingsRadio;
