import React from 'react';

import { Switch } from 'antd';
import CheckedIcon from './CheckedIcon';
import styles from './index.module.scss';

const Plans = ({
    plans,
    handleBillAnnuallyChange,
    handleSendRequest,
    setRequest,
    setRequestVisible,
    companyDetails,
    handleEditSubscription,
    isUserOnFreePlan,
}) => {
    const showSGDPricing = companyDetails?.country === 'Singapore';
    const billingPeriodUnit = companyDetails.billingPeriodUnit;
    const BillAnnuallySwitch = ({ plan, isThisCurrentPlan }) => {
        const handleSwitchChange = (value) => {
            handleBillAnnuallyChange(value, plan);
        };

        return (
            <p
                className={`${styles.BilledAnually} ${
                    !plan.hasOwnProperty('billAnually')
                        ? styles.BillBlank
                        : null
                }`}
            >
                {plan.hasOwnProperty('billAnually') ? (
                    <>
                        <span className={styles.Switch}>
                            <Switch
                                size="small"
                                onChange={handleSwitchChange}
                                defaultChecked={!plan?.isMonthly}
                            />
                        </span>
                        <span>Bill annually</span>
                    </>
                ) : (
                    <span>Bill annually</span>
                )}
            </p>
        );
    };

    const CurrentPlanRibbon = () => (
        <div className={styles.CurrentPlanRibbon}>Current Plan</div>
    );

    const handleChangePlan = (plan, isDowngrade) => {
        setRequest(plan);

        if (isUserOnFreePlan && plan.name !== 'Enterprise') {
            handleSendRequest(plan, isDowngrade);
        }
        if (!isUserOnFreePlan || plan.name === 'Enterprise')
            setRequestVisible(true);
    };

    // Refer this ticket - https://wearegsi.atlassian.net/browse/MYXP-4197
    const isNotNAFACompany =
        companyDetails?.id !== '1d04b23c-de46-4c1c-9673-78290ed0ec95';

    return (
        <div className={styles.Plans}>
            {plans.map((plan) => {
                let isThisCurrentPlan =
                    companyDetails?.package?.id === plan.package.id;
                if (isThisCurrentPlan && plan.name === 'Pro') {
                    if (billingPeriodUnit === 'month' && !plan.isMonthly) {
                        isThisCurrentPlan = false;
                    } else if (billingPeriodUnit === 'year' && plan.isMonthly) {
                        isThisCurrentPlan = false;
                    }
                }
                const currentPlanRank = plans.find(
                    (p) => p.package.id === companyDetails?.package?.id
                )?.rank;
                const isDowngrade = plan.rank > currentPlanRank;

                return (
                    <div
                        key={plan.name}
                        className={`${styles.Plan} ${
                            isThisCurrentPlan ? styles.ActivePlan : ''
                        }`}
                    >
                        {isThisCurrentPlan && <CurrentPlanRibbon />}
                        <BillAnnuallySwitch
                            plan={plan}
                            isThisCurrentPlan={isThisCurrentPlan}
                        />

                        <div className={styles.PlanDetails}>
                            <h2>{plan.name}</h2>
                            <p className={styles.PlanDesc}>{plan.desc}</p>
                            {isThisCurrentPlan ? (
                                <button
                                    onClick={handleEditSubscription}
                                    className={styles.EditSub}
                                >
                                    Edit Subscription
                                </button>
                            ) : isNotNAFACompany ? (
                                <h3
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            showSGDPricing && plan.sgdPlanType
                                                ? plan.sgdPlanType
                                                : plan.planType,
                                    }}
                                ></h3>
                            ) : null}
                            <div className={`${styles.Box} ${styles.Starter}`}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: plan?.includes?.desc,
                                    }}
                                ></p>
                                <div className={styles.Details}>
                                    {plan?.includes?.items?.map((item) => (
                                        <>
                                            <p>
                                                <b>{item.count}</b>
                                                <span> {item.desc}</span>
                                            </p>
                                        </>
                                    ))}
                                </div>
                            </div>

                            {isThisCurrentPlan ? (
                                <>
                                    <button
                                        className={`${styles.RemoveLimit} ${styles.Disabled}`}
                                    >
                                        You're on this plan
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() =>
                                            handleChangePlan(plan, isDowngrade)
                                        }
                                        className={styles.RemoveLimit}
                                    >
                                        {isUserOnFreePlan
                                            ? plan.button
                                            : 'Contact Us'}
                                    </button>
                                </>
                            )}

                            <hr
                                style={{
                                    backgroundColor: '#E1E3EB',
                                    margin: '24px 0',
                                }}
                            />

                            {/* <div className={styles.UpgradeDetails}>
                                <p className={styles.DividerLabel}>
                                    {plan?.upgrade?.desc}
                                </p>
                                <div className={styles.Packages}>
                                    {plan?.upgrade?.package?.map((pack) => (
                                        <p
                                            className={styles.Package}
                                            dangerouslySetInnerHTML={{
                                                __html: showSGDPricing
                                                    ? pack.sgdItem
                                                    : pack.item,
                                            }}
                                        ></p>
                                    ))}
                                </div>
                            </div>

                            <hr
                                style={{
                                    backgroundColor: '#E1E3EB',
                                    margin: '24px 0',
                                }}
                            /> */}

                            <div className={styles.Features}>
                                <p className={styles.DividerLabel}>
                                    {plan?.features?.desc}
                                </p>
                                <div className={styles.Packages}>
                                    {plan?.features?.list?.map((feature) => (
                                        <p className={styles.Package}>
                                            <CheckedIcon />
                                            <span>{feature}</span>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Plans;
