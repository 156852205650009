import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import ReactCountryFlag from 'react-country-flag';

import './countrySelect.scss';

import { getData, overwrite } from 'country-list';

const { Option } = Select;

overwrite([
    {
        code: 'TW',
        name: 'Taiwan',
    },
]);

function CountrySelect(props) {
    const { country, setCountry, disabled } = props;

    const handleCountrySelection = (data) => {
        let selectedCountry = data;
        if (selectedCountry === 'Taiwan')
            selectedCountry = `${data}, Province of China`;

        setCountry(selectedCountry);
    };

    const renderCountryFlag = (country) => {
        return (
            <ReactCountryFlag
                countryCode={country.code}
                aria-label={country.name}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
            />
        );
    };

    return (
        <Select
            showSearch={true}
            style={{ width: '100%' }}
            value={country === 'Taiwan, Province of China' ? 'Taiwan' : country}
            placeholder="Select a country"
            onChange={handleCountrySelection}
            disabled={disabled}
        >
            {getData().map((country, i) => {
                return (
                    <Option key={country?.name}>
                        {renderCountryFlag(country)}
                        <span className="ml-1">{country.name}</span>
                    </Option>
                );
            })}
        </Select>
    );
}

export default CountrySelect;
