import React from 'react';

export default function PublicHomeIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.1813 16.293V12.0544C11.1813 11.8671 11.1047 11.6874 10.9683 11.5549C10.8319 11.4224 10.6469 11.348 10.454 11.348H7.54492C7.35204 11.348 7.16705 11.4224 7.03066 11.5549C6.89427 11.6874 6.81765 11.8671 6.81765 12.0544V16.293C6.81765 16.4804 6.74104 16.66 6.60467 16.7925C6.4683 16.925 6.28334 16.9994 6.09047 16.9994L1.72736 17C1.63185 17 1.53727 16.9817 1.44902 16.9462C1.36077 16.9108 1.28059 16.8587 1.21305 16.7931C1.1455 16.7275 1.09192 16.6496 1.05537 16.5639C1.01881 16.4782 1 16.3864 1 16.2936V8.12909C1 8.03067 1.02117 7.93334 1.06216 7.84333C1.10315 7.75332 1.16304 7.67263 1.23801 7.60642L8.51024 1.18375C8.64411 1.06552 8.81854 1.00001 8.99948 1C9.18041 0.999994 9.35485 1.0655 9.48873 1.18372L16.762 7.60642C16.8369 7.67263 16.8968 7.75332 16.9378 7.84334C16.9788 7.93335 17 8.03069 17 8.12912V16.2936C17 16.3864 16.9812 16.4782 16.9446 16.5639C16.9081 16.6496 16.8545 16.7275 16.787 16.7931C16.7194 16.8587 16.6392 16.9108 16.551 16.9463C16.4627 16.9817 16.3682 17 16.2726 17L11.9085 16.9994C11.7156 16.9994 11.5306 16.925 11.3943 16.7925C11.2579 16.66 11.1813 16.4804 11.1813 16.293V16.293Z"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
