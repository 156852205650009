import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OTPInput from 'otp-input-react';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { requestOTP, verifyAccount } from 'ComponentsV2/ProjectDashboard/API';
import { updateProfile } from 'apis/rest/UpdateProfile';
import { updateUser } from 'redux/actions/common/userActions';

import { getFullname } from '../../../utils/functions/common';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import Verifier from 'ComponentsV2/ProjectDashboard/Icons/Common/Verifier';
import EditPencil from 'ComponentsV2/ProjectDashboard/Icons/Common/EditPencil';

import Modal from '../../modal';

const inputStyles = {
    width: '45px',
    height: '40px',
    borderWidth: '1px',
    borderRadius: '0.375rem',
};

export default function UserProfileVerifier({
    user,
    className,
    sendRequestOtp,
    setSendRequestOtp,
}) {
    const { projectId } = useParams();
    const dispatch = useDispatch();

    const isUserVerified =
        typeof user?.isVerified !== 'undefined' ? user?.isVerified : true;
    const [sendingOtp, setSendingOtp] = useState(false);

    const [error, setError] = useState('');
    const [showUserProfileChangeEmail, setShowUserProfileChangeEmail] =
        useState(false);
    const [optEmail, setOptEmail] = useState('');
    const [showEmailChangeOtp, setShowEmailChangeOtp] = useState(false);

    const [showVerifyAccount, setShowVerifyAccount] = useState(false);

    const [changeEmailOtp, changeEmailSetOtp] = useState('');
    const [changeEmailUserEmail, changeEmailSetUserEmail] = useState(optEmail);
    const debounceChangeEmailSetUserEmail = debounce(
        changeEmailSetUserEmail,
        250
    );

    const [verifyEmailOtp, verifyEmailSetOtp] = useState('');

    async function requestOtpHandler(isVerifyAccount = true, userEmail) {
        try {
            if (sendingOtp) return;
            setSendingOtp(true);
            setError('');
            setOptEmail(isVerifyAccount ? user?.email : userEmail);
            const response = await requestOTP(
                isVerifyAccount ? user?.email : userEmail
            );
            if (!response.status) {
                throw response;
            }
            if (isVerifyAccount) {
                setShowVerifyAccount(true);
            } else {
                setShowEmailChangeOtp(true);
            }
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong');
        } finally {
            setSendingOtp(false);
            setSendRequestOtp && setSendRequestOtp(false);
        }
    }

    async function changeEmailHandler(userEmail, code) {
        try {
            const res = await updateProfile({
                firstname: user?.firstname,
                lastname: user?.lastname,
                email: userEmail,
                code1: code,
            });
            if (res && res.status) {
                const updatedUserData = {
                    ...user,
                    email: userEmail,
                    isVerified: true,
                };
                dispatch(updateUser({ user: updatedUserData }));
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'emailVerified',
                        data: {
                            user: updatedUserData,
                            projectId: projectId,
                        },
                    },
                });
                openNotification('success', {
                    message: 'Account Verified!',
                });
                setShowUserProfileChangeEmail(false);
            } else throw res;
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong');
        }
    }

    async function verifyAccountHandler(code) {
        try {
            setError('');
            const res = await verifyAccount(code);
            if (res?.status) {
                const updatedUserData = { ...user, isVerified: true };
                dispatch(updateUser({ user: updatedUserData }));
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'emailVerified',
                        data: {
                            user: updatedUserData,
                            projectId: projectId,
                        },
                    },
                });
                openNotification('success', {
                    message: 'Account Verified!',
                });
                window.location.reload();
            } else throw res;
        } catch (error) {
            console.error(error);
            setError(error?.message || 'Something went wrong');
        }
    }

    useEffect(() => {
        if (sendRequestOtp) {
            requestOtpHandler();
        }
    }, [sendRequestOtp]);

    useEffect(() => {
        if (verifyEmailOtp.length === 6) {
            verifyAccountHandler(verifyEmailOtp);
        }
    }, [verifyEmailOtp]);

    useEffect(() => {
        if (!showUserProfileChangeEmail) {
            changeEmailSetOtp('');
        }
        if (!showVerifyAccount) {
            verifyEmailSetOtp('');
        }
        if (!showVerifyAccount && !showUserProfileChangeEmail) {
            setError('');
        }
        if (showUserProfileChangeEmail) {
            setError(
                changeEmailUserEmail === user?.email
                    ? 'Your new email is same as your current email, please enter a different email'
                    : ''
            );
        }
    }, [
        showUserProfileChangeEmail,
        showVerifyAccount,
        changeEmailUserEmail,
        user,
    ]);

    if (isUserVerified) {
        return null;
    }

    const getChangeEmailModal = () => (
        <Modal
            visible={showUserProfileChangeEmail}
            onClose={() => setShowUserProfileChangeEmail(false)}
            showCloseIcon
            preventCloseOnOutsideClick
        >
            <div className="md:tw-flex md:tw-items-center md:tw-justify-center tw-mt-5 tw-pb-8">
                <div className="tw-text-gray-400 tw-font-semibold">
                    <span className="tw-text-base tw-text-gray-400">
                        {getFullname(user)}{' '}
                    </span>{' '}
                    /
                    <span className="tw-text-base tw-text-black tw-font-semibold">
                        {' '}
                        Change email
                    </span>
                </div>
            </div>
            <div className="md:tw-col-span-2">
                <label
                    htmlFor="email"
                    className="tw-block tw-text-xssm tw-font-openSans tw-font-semibold tw-capitalize tw-text-gray-700"
                >
                    New Email
                </label>
                <div className="tw-relative tw-mt-1">
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your new Email"
                        defaultValue={changeEmailUserEmail}
                        onChange={(e) =>
                            debounceChangeEmailSetUserEmail(e.target.value)
                        }
                        className="tw-py-3 tw-px-4 tw-block tw-w-full tw-font-openSans tw-text-sm tw-font-semibold tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-border-solid tw-border tw-rounded-md"
                    />
                </div>
            </div>
            <div
                className={`${
                    showEmailChangeOtp
                        ? 'tw-block tw-opacity-100'
                        : 'tw-hidden tw-opacity-0'
                } md:tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-transition-all tw-duration-1000 tw-ease-in-out`}
            >
                <p className="tw-m-0 tw-p-0 tw-mt-2 tw-mb-2 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-4/5">
                    Before changing your current unverified email please verify
                    your authenticity by providing OTP sent on your new email
                    &nbsp;(
                    <span className="tw-font-semibold">
                        {changeEmailUserEmail}
                    </span>
                    )
                </p>
                <OTPInput
                    inputStyles={inputStyles}
                    className="tw-font-bold tw-text-lg"
                    value={changeEmailOtp}
                    onChange={changeEmailSetOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={sendingOtp}
                />
            </div>
            {error && (
                <p className="tw-m-0 tw-p-0 tw-mt-1 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-full tw-text-rose-700">
                    {error}
                </p>
            )}
            <div className="md:tw-col-span-2 tw-mt-5 md:tw-mt-8 md:tw-flex md:tw-flex-row-reverse md:tw-content-around md:tw-justify-center">
                <button
                    type="button"
                    className={`${
                        sendingOtp ||
                        !changeEmailUserEmail ||
                        changeEmailUserEmail === user?.email
                            ? 'tw-bg-blue-300 hover:tw-bg-blue-300'
                            : 'tw-bg-blue-600 hover:tw-bg-blue-700'
                    } tw-w-full tw-font-openSans tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-transparent tw-shadow-sm tw-px-5 tw-py-2 tw-text-base tw-font-semibold tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-bg-blue-500 md:tw-ml-3 md:tw-w-auto md:tw-text-lg`}
                    onClick={
                        !showEmailChangeOtp || changeEmailOtp.length !== 6
                            ? () =>
                                  requestOtpHandler(false, changeEmailUserEmail)
                            : () =>
                                  changeEmailHandler(
                                      changeEmailUserEmail,
                                      changeEmailOtp
                                  )
                    }
                    disabled={
                        sendingOtp ||
                        !changeEmailUserEmail ||
                        changeEmailUserEmail === user?.email
                    }
                >
                    {changeEmailOtp.length !== 6
                        ? sendingOtp
                            ? 'Sending OTP...'
                            : 'Request OTP'
                        : 'Save Changes'}
                </button>
                <button
                    type="button"
                    className="tw-mt-3 tw-w-full tw-font-openSans tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm tw-px-6 tw-py-2 tw-bg-white tw-text-base tw-font-semibold tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 md:tw-mt-0 md:tw-mr-5 md:tw-w-auto md:tw-text-xssm"
                    onClick={() => setShowUserProfileChangeEmail(false)}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );

    const getVerifyAccountModal = () => (
        <Modal
            visible={showVerifyAccount}
            onClose={() => setShowVerifyAccount(false)}
            showCloseIcon
            preventCloseOnOutsideClick
        >
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                <p className="tw-m-0 tw-p-0 tw-mb-4 tw-font-openSans tw-text-lg tw-font-semibold tw-text-center tw-w-4/5">
                    We’ve sent a One-Time Password to{' '}
                    <span className="tw-m-0 tw-p-0 tw-font-bold">
                        {user?.email}
                    </span>
                </p>
                <p className="tw-m-0 tw-p-0 tw-mb-4 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-4/5">
                    Enter the OTP below to verify your account
                </p>
                <OTPInput
                    inputStyles={inputStyles}
                    className="tw-font-bold tw-text-lg"
                    value={verifyEmailOtp}
                    onChange={verifyEmailSetOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={sendingOtp}
                />
                {error && (
                    <p className="tw-m-0 tw-p-0 tw-mb-4 tw-font-openSans tw-text-xs tw-font-normal tw-text-center tw-w-4/5 tw-text-rose-700">
                        {error}
                    </p>
                )}
                <p
                    className="tw-m-0 tw-p-0 tw-mt-2 tw-mb-2 tw-font-openSans tw-text-sm tw-font-semibold tw-text-center tw-w-4/5 tw-text-blue-500 tw-cursor-pointer"
                    onClick={requestOtpHandler}
                >
                    Resend OTP
                </p>
            </div>
        </Modal>
    );

    return (
        <>
            <div
                className={`tw-fixed tw-z-[30] tw-w-full tw-h-16 tw-flex tw-flex-row tw-items-center tw-justify-items-center tw-bg-rose-100 ${className}`}
            >
                <p className="tw-py-0 tw-px-5 tw-m-0 tw-text-xs tw-font-normal">
                    This account (with email address{' '}
                    <span className="tw-font-semibold">{user?.email} </span>){' '}
                    <span
                        className="tw-cursor-pointer"
                        onClick={() => setShowUserProfileChangeEmail(true)}
                    >
                        <EditPencil />
                    </span>{' '}
                    is unverified. To unlock all the features of the Starter
                    plan please verify your account.
                </p>
                <span
                    className="tw-text-xs tw-font-normal tw-cursor-pointer"
                    style={{ color: '#DB3314' }}
                    onClick={() => (sendingOtp ? null : requestOtpHandler())}
                >
                    <Verifier />{' '}
                    {sendingOtp ? 'Sending OTP...' : 'Verify account'}
                </span>
            </div>
            {showUserProfileChangeEmail ? getChangeEmailModal() : null}
            {showVerifyAccount ? getVerifyAccountModal() : null}
        </>
    );
}

UserProfileVerifier.propTypes = {
    user: PropTypes.object.isRequired,
    className: PropTypes.string,
    sendRequestOtp: PropTypes.bool,
    setSendRequestOtp: PropTypes.func,
};

UserProfileVerifier.defaultProps = {
    className: '',
    sendRequestOtp: false,
    setSendRequestOtp: () => {},
};
