import React from 'react';

export default function SecurityIcon() {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.835429 1.40758L6.88171 0.0639648L12.928 1.40758C13.0914 1.44391 13.2375 1.53486 13.3423 1.66542C13.447 1.79599 13.5041 1.95836 13.5041 2.12575V9.47445C13.5041 10.2012 13.3246 10.9168 12.9816 11.5576C12.6386 12.1984 12.1428 12.7446 11.538 13.1477L6.88171 16.2521L2.22541 13.1477C1.62076 12.7446 1.12495 12.1985 0.78197 11.5579C0.438992 10.9173 0.259451 10.2019 0.259277 9.47518V2.12575C0.259306 1.95836 0.316405 1.79599 0.421151 1.66542C0.525897 1.53486 0.67203 1.44391 0.835429 1.40758V1.40758Z"
                fill="black"
            />
        </svg>
    );
}
