import React from 'react';

export default function DetailsDotsIcon({ stroke = 'white' }) {
    return (
        <svg
            width="10"
            height="3"
            viewBox="0 0 10 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.875 1.45609C6.875 0.834764 7.37868 0.331085 8 0.331085C8.62132 0.331085 9.125 0.834765 9.125 1.45609C9.125 2.07742 8.62132 2.58109 8 2.58109C7.37868 2.58109 6.875 2.07742 6.875 1.45609ZM3.5 1.45608C3.5 0.834764 4.00366 0.331085 4.625 0.331085C5.24632 0.331085 5.75 0.834764 5.75 1.45609C5.75 2.07742 5.24632 2.58109 4.625 2.58109C4.00366 2.58108 3.5 2.07742 3.5 1.45608ZM0.125 1.45608C0.125 0.834764 0.628662 0.331085 1.25 0.331085C1.87134 0.331085 2.375 0.834764 2.375 1.45608C2.375 2.07742 1.87134 2.58108 1.25 2.58108C0.628662 2.58108 0.125 2.07742 0.125 1.45608Z"
                fill={stroke || 'white'}
            />
        </svg>
    );
}
