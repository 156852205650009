import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';

import BlockViewConfiguration from '../BlockViewConfiguration';

const SettingsCheckbox = ({
    setValue,
    setFitToContent,
    name,
    value,
    title,
    displayMode,
}) => {
    const displayModeRedux = useSelector((state) => state?.header.display);

    const onChange = (e) => {
        setValue(`${name}`, e.target.checked);
        if (setFitToContent) setFitToContent(e.target.checked);

        return;
    };

    // (name?.toLowerCase() === 'fitHeightToContent')
    const displayStyle = displayMode
        ? {
              display:
                  (displayMode === 'desktop' && displayModeRedux.desktop) ||
                  (displayMode === 'tablet' && displayModeRedux.tablet) ||
                  (displayMode === 'mobile' && displayModeRedux.mobile)
                      ? 'block'
                      : 'none',
          }
        : { display: 'block' };

    return (
        <div style={{ padding: '0 16px', ...displayStyle }}>
            <Checkbox
                value={value}
                checked={value}
                onChange={(e) => onChange(e)}
            >
                {title ? title : 'Fit to content'}
            </Checkbox>
        </div>
    );
};

export default SettingsCheckbox;
