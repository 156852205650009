import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withRouter, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import * as ls from 'local-storage';

import { Row, Tag, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

// API
import { getPhysicalVenueDetail } from 'apis/rest/venue/Venues';

// Components

import Store from 'components/app/components/venue/store';
import Uploadvenue from 'components/app/components/venue/uploadvenue';

// redux
import * as venuesActions from 'redux/actions/venues/actionsVenues';

import './virtual.scss';
import { githubInstall } from 'apis/rest/installation-apps/Github';
import Spinner from 'Apps/VenueBuilder/Components/Common/Spinner';
import { ClearCacheProject } from 'apis/rest/cache/ClearCacheProject';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';

const VirtualHome = ({ location, match }) => {
    const dispatch = useDispatch();
    const { projectId, companyId } = useParams();

    const history = useHistory();
    const activeApp =
        location?.state?.activeApp || location?.pathname?.split('/')[6];
    const projects = useSelector((state) => state?.projects?.list);
    const currentProjectData =
        projects && projects.length
            ? projects.filter((p) => p.id === projectId)[0] ||
              ls.get('activeProjData')
            : ls.get('activeProjData');
    const user = useSelector(state => state.user)

    const venueStoreCount = useSelector(
        (state) => state?.venues?.venuestore?.venues
    );
    const venueCollectionsCount = useSelector(
        (state) => state?.venues?.venuecollections?.venues
    );

    const venuesettings = useSelector((state) => state?.venuesettings);
    const venueId = venuesettings?.venueId;
    const enabledVenue = venuesettings?.enableVenue;
    const selectedVenue = venuesettings?.venueDetails?.venue;

    const queryParams = new URLSearchParams(location.search);
    const installationId = queryParams.get('installation_id') || null;
    const setupAction = queryParams.get('setup_action') || null;
    const loadCollection = queryParams.get('loadCollection') || null;
    const isSponsor = !!ls.get('sponsorAuth');

    const projectIdFromState = useSelector((state) => state?.header?.projectId);

    if (projectId || projectIdFromState) {
        ls.set('projIdSlctd', projectId || projectIdFromState);
    }

    // state

    // modal
    const [addVenueModalVisible, setAddVenueModalVisible] = useState(false);
    const [uploadVenueModalVisible, setUploadVenueModalVisible] =
        useState(false);
    const [activeModalTab, setActiveModalTab] = useState('');

    // loading for github
    const [connectLoading, setConnectLoading] = useState(
        installationId !== null
    );

    const browseVenue = () => {
        setAddVenueModalVisible(true);
        setActiveModalTab('store');
    };

    const uploadVenue = () => setUploadVenueModalVisible(true);

    const browseVenueCollection = () => {
        setAddVenueModalVisible(true);
        setActiveModalTab('collections');
    };

    const getDetails = async (id) => {
        const venueDetails = await getPhysicalVenueDetail(projectId, id);

        if (venueDetails.status) {
            dispatch(venuesActions.setCompanyVenueDetails(venueDetails));
        } else {
            openNotification('error', { message: `${venueDetails.message}` });
        }
    };
    const handleClearCache = async () => {
        const response = await ClearCacheProject(projectId);
        if (response.status) {
            openNotification('success', { message: `${response.message}` });
        } else {
            openNotification('error', { message: `${response.message}` });
        }
    };
    // Install github
    const installGithub = async (installationId) => {
        try {
            const res = await githubInstall({
                installationId,
                projectId,
                companyId,
            });
            if (res.status) {
                setAddVenueModalVisible(true);
                setActiveModalTab('collection');
                setUploadVenueModalVisible(true);
                setConnectLoading(false);
                openNotification(
                    res?.message || 'Account installed successfully',
                    'success'
                );
            } else {
                openNotification('success', {
                    message:
                        res.message ||
                        'Error on installing account. Please try again',
                });
            }
        } catch (error) {
            console.error(error);
            openNotification(error.message, 'success');
        }
    };

    useEffect(() => {
        if (venueId) {
            getDetails(venueId);
        }
    }, [venueId]);

    useEffect(() => {
        if (isSponsor) return;
        if (installationId && setupAction && connectLoading) {
            installGithub(installationId);
        }

        if (loadCollection) {
            browseVenueCollection();
        }
    }, [installationId, setupAction, loadCollection]);

    return (
        <div className="container2 tw-p-10">
            <div className="virtual">
                {enabledVenue && venueId && (
                    <>
                        <div className="dashboard dashboard__xpmgr venue-list livepages-list ml-auto">
                            {!isSponsor && (
                                <div className="d-flex align-items-center search-style ">
                                    <button
                                        onClick={() => handleClearCache()}
                                        className="btn btn-preview mr-2"
                                    >
                                        Clear Cache
                                    </button>
                                    <button
                                        onClick={browseVenue}
                                        className="btn btn-primary browse"
                                    >
                                        View Themes/Experiences
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="venue-details-wrapper mt-2">
                            <Row
                                justify="space-between"
                                align="start"
                                className="venue-item-collection"
                            >
                                <div className="venue-details">
                                    {selectedVenue?.thumbnail ? (
                                        <img
                                            width={128}
                                            height={84}
                                            alt={selectedVenue?.name}
                                            src={selectedVenue?.thumbnail}
                                        />
                                    ) : (
                                        <div className="card-image-placeholder" />
                                    )}
                                    <div className="venue-details-section">
                                        <h2>{selectedVenue?.name}</h2>
                                        <div className="venue-labels">
                                            <label>Labels:</label>
                                            <div className="venue-labels-tags">
                                                {selectedVenue?.goodFor
                                                    ?.split(', ')
                                                    .map((tag, idx) => {
                                                        return (
                                                            <Tag key={idx}>
                                                                {tag}
                                                            </Tag>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className="venue-edits-logs">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedVenue?.description,
                                                }}
                                            />
                                        </div>
                                        {/* <div className="venue-more-actions">
                                            <span>More details</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="venue-ctas">
                                    <Link
                                        to={`/company/${companyId}/project/${projectId}/venue-builder/${
                                            user?.isFirstVenue
                                                ? '?firstVenue=1'
                                                : ''
                                        }`}
                                        className="btn btn-preview"
                                    >
                                        Editor
                                    </Link>
                                </div>
                            </Row>
                        </div>
                    </>
                )}

                {/* Empty state */}
                {enabledVenue && !venueId && !isSponsor && (
                    <div className="mt-2 empty">
                        <div className="circle"></div>
                        <h2>Setup a Virtual Venue</h2>
                        <div className="row">
                            <div className="column">
                                <h4>From the Experiences Marketplace</h4>
                                <span>
                                    {venueStoreCount?.length > 0
                                        ? venueStoreCount?.length
                                        : 0}{' '}
                                    experiences to choose from.
                                </span>
                            </div>
                            <div className="column buttons">
                                <button
                                    onClick={browseVenue}
                                    className="btn btn-primary"
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <h4>From my theme collection</h4>
                                <span>
                                    {venueCollectionsCount?.length > 0
                                        ? venueCollectionsCount?.length
                                        : 0}{' '}
                                    venue to choose from.
                                </span>
                            </div>
                            <div className="column buttons">
                                <button
                                    onClick={uploadVenue}
                                    className="btn btn-primary"
                                >
                                    Upload
                                </button>
                                <button
                                    onClick={browseVenueCollection}
                                    className="btn btn-primary"
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {!enabledVenue && (
                    <div className="mt-2 empty">
                        <h2>Sorry, there is no such page!</h2>
                    </div>
                )}
            </div>

            {!isSponsor && (
                <Store
                    isVisible={addVenueModalVisible}
                    setModal={setAddVenueModalVisible}
                    projectId={projectId}
                    activeTab={activeModalTab}
                    loadCollection={loadCollection}
                />
            )}
            {!isSponsor &&
                uploadVenueModalVisible &&
                (!connectLoading ? (
                    <Uploadvenue
                        installationId={installationId}
                        isVisible={uploadVenueModalVisible}
                        setUploadModal={setUploadVenueModalVisible}
                        setModal={() => setUploadVenueModalVisible}
                    />
                ) : (
                    <Spinner />
                ))}
        </div>
    );
};

export default withRouter(VirtualHome);
