import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Dropdown } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import SideModal from 'components/app/common/sideModal';
import EmptyPage from '../../../../../../components/app/common/empty';
import TablePreloader from '../../../../common/preloader/tablePreloader';
import PageSpinner from '../../../../common/pageSpinner';
import Inputs from './inputs';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import PopUp from 'ComponentsV2/ProjectDashboard/Components/Common/UpgradeMessage/Popup';
import DisclaimerForLimitBreach from 'ComponentsV2/ProjectDashboard/Components/Common/UpgradeMessage/DisclaimerForLimitBreach';
import Data from 'ComponentsV2/ProjectDashboard/Components/Common/UpgradeMessage/Data';
import plans from 'ComponentsV2/CommonUtils/IdentifyPlan.js';
// redux
import { getCompanyUsers } from '../../../../../../apis/rest/livestream/GetCompanyUsers';
import { createCompanyUser } from '../../../../../../apis/rest/livestream/CreateCompanyUser';

import './users.scss';
import { getCompanyUsage } from 'apis/rest/Companies';

const starterPlanLimit = 1; // free plan
const { STARTER_PLAN } = plans;

const Users = () => {
    const { companyId } = useParams();

    const [userFetchLoader, setUserFetchLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showDisclaimerForLimitBreach, setShowDisclaimerForLimitBreach] =
        useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit, setCurrentLimit] = useState(10);

    const [sideModalVisibility, setSideModalVisibility] = useState(false);
    const [userFormData, setUserFormData] = useState(null);
    const [apiInProgress, setApiInProgress] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const companyDetails = useSelector((state) => state?.projects?.company);

    const isUserOnFreePlan = STARTER_PLAN === companyDetails?.package?.id;

    const fetchUsers = async () => {
        try {
            setUserFetchLoader(true);
            const response = await getCompanyUsers({
                companyId,
                page: currentPage,
                limit: currentLimit,
            });
            if (!response?.status) throw response;
            setUsers(response?.users);
            setPagination(response?.pagination);
        } catch (e) {
            console.error('Error in fetching users: ', e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setUserFetchLoader(false);
        }
    };

    const createUser = async () => {
        try {
            setApiInProgress(true);
            const response = await createCompanyUser({
                ...userFormData,
                companyId,
            });
            if (response.status && response.message === 'successfully added') {
                setSideModalVisibility(false);
                setUserFormData(null);
                fetchUsers();
                openNotification('success', { message: 'User Added!' });
            } else openNotification('error', { message: response.message });
        } catch (e) {
            openNotification('error', { message: e?.message });
        } finally {
            setApiInProgress(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [currentPage, currentLimit, companyId]);

    const tableHeaders = [
        { id: 0, name: 'name', desc: 'Name', isSortShown: false },
        { id: 1, name: 'eail', desc: 'Email', isSortShown: false },
    ];

    const emptyPageCustomIcon = () => {
        return (
            <i
                className="fa fa-10x fa-folder"
                style={{ color: '#B7C3CF', opacity: '35%' }}
            />
        );
    };

    const handlePageCount = () => {
        return pagination ? Math.ceil(pagination?.total / currentLimit) : 0;
    };

    const handlePaginationClick = (data) => setCurrentPage(data.selected + 1);

    const handleCurrentLimit = (limit) => {
        setCurrentLimit(limit);
        let computedPage = Math.ceil(pagination?.total / limit);
        let userPage = computedPage < currentPage ? computedPage : currentPage;

        setCurrentPage(userPage);
    };

    const handleInviteMoreUser = async () => {
        const disclaimerDisplayed = localStorage.getItem(
            `disclaimerDisplayed_${companyId}`
        );
        const { quota, status, usage } = await getCompanyUsage(
            companyId,
            'organiser'
        );

        if (status && usage && usage !== 0 && usage < quota) {
            setUserFormData(null);
            setSideModalVisibility(true);
            return;
        } else if (status && usage >= quota && !disclaimerDisplayed) {
            setShowDisclaimerForLimitBreach(true);
            return;
        }

        if (isUserOnFreePlan && pagination?.total === starterPlanLimit) {
            setShowUpgradeModal(true);
            return;
        }
        setUserFormData(null);
        setSideModalVisibility(true);
    };

    return (
        <>
            <div className="container user-container">
                <div className="sponsor-dashboard sponsor-dashboard__xpmgr sponsor-user-list">
                    <div className="sponsor-dashboard__left">
                        <h1 className="active">Users</h1>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button
                            onClick={handleInviteMoreUser}
                            className="invite-btn btn btn-primary ml-3"
                        >
                            Invite more users
                        </Button>
                    </div>
                </div>
                {isUserOnFreePlan && pagination?.total ? (
                    <div className="my-3">
                        <Data
                            occupied={pagination?.totalUsage || 1}
                            limit={starterPlanLimit}
                            name={'Users'}
                        />
                    </div>
                ) : null}
                <div className="mt-3">
                    <table className="table table-borderless user-list-table">
                        <thead>
                            <tr>
                                {tableHeaders.map((table) => (
                                    <th scope="col" key={table.name}>
                                        <div>{table.desc}</div>
                                    </th>
                                ))}
                                <th scope="col" />
                            </tr>
                        </thead>
                        {!userFetchLoader &&
                            (users?.length ? (
                                <tbody>
                                    {users.map((user, i) => {
                                        const {
                                            id,
                                            firstname = '',
                                            lastname = '',
                                            email,
                                        } = user;
                                        const name = `${firstname} ${lastname}`;
                                        return (
                                            <tr key={`user-row-${id}`}>
                                                <td>{name} </td>
                                                <td>{email} </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tr>
                                    <td
                                        colSpan="5"
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    ></td>
                                </tr>
                            ))}
                        {userFetchLoader && (
                            <TablePreloader columns={6}> </TablePreloader>
                        )}
                    </table>

                    {!!pagination?.total && (
                        <div className="align-items-center d-flex justify-content-between">
                            <Dropdown style={{ width: '100px' }}>
                                <Dropdown.Toggle
                                    variant="secondary"
                                    id=""
                                    style={{
                                        padding: '8px 16px',
                                    }}
                                    className="m-0 myxp bg-white w-100 border rounded font-weight-bold justify-content-between"
                                >
                                    <span>Limit</span> {currentLimit}
                                </Dropdown.Toggle>

                                <Dropdown.Menu alignRight={true}>
                                    {Array(3)
                                        .fill(5)
                                        .map((el, i) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    handleCurrentLimit(
                                                        el * (i + 1)
                                                    );
                                                }}
                                                key={i}
                                            >
                                                {el * (i + 1)}
                                            </Dropdown.Item>
                                        ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={handlePageCount()}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) =>
                                    handlePaginationClick(data)
                                }
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    )}
                </div>
            </div>
            {userFetchLoader && (
                <PageSpinner
                    type="Oval"
                    color="#ACBDC9"
                    height={48}
                    width={48}
                />
            )}
            {!pagination?.total && (
                <EmptyPage
                    title="You don’t have any users yet"
                    customIcon={emptyPageCustomIcon}
                />
            )}
            <SideModal
                visibility={sideModalVisibility}
                setVisibility={(status) => setSideModalVisibility(status)}
                title={'Create New User'}
            >
                <Inputs
                    data={userFormData}
                    loading={apiInProgress}
                    onChangeData={(data) => setUserFormData(data)}
                    onSubmitForm={createUser}
                    onCancel={() => {
                        setSideModalVisibility(false);
                        setUserFormData(null);
                    }}
                />
            </SideModal>
            <PopUp
                modalVisibilty={showUpgradeModal}
                setModalVisibility={setShowUpgradeModal}
            >
                <p>
                    Upgrade your plan in order to be able to have more than{' '}
                    {starterPlanLimit} organiser users under this company.
                </p>
            </PopUp>
            <DisclaimerForLimitBreach
                modalVisibilty={showDisclaimerForLimitBreach}
                setModalVisibility={setShowDisclaimerForLimitBreach}
                callback={(accept) => {
                    if (accept) {
                        localStorage.setItem(
                            `disclaimerDisplayed_${companyId}`,
                            'true'
                        );
                        setUserFormData(null);
                        setSideModalVisibility(true);
                    }
                }}
            />
        </>
    );
};

export default Users;
