import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './dropdownPanel.scss';

function DropdownPanel(props) {
    const { panelVisibility, className, children } = props;

    return (
        <CSSTransition
            in={panelVisibility}
            timeout={300}
            classNames="dropdown-panel"
            unmountOnExit
        >
            <div className={`dropdown-panel ${className || ''}`}>
                {children}
            </div>
        </CSSTransition>
    );
}

DropdownPanel.propTypes = {
    panelVisibility: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
        .isRequired,
};

export default withRouter(DropdownPanel);
