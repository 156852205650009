import React from 'react';
import PropTypes from 'prop-types';
import './tablePreloader.scss';

function TablePreloader(props) {
    const { color, rows, columns, custom } = props;

    if (custom) return <>{custom}</>;

    return (
        <tbody className="table-preloader">
            {[...Array(rows)].map((rowValue, rowIndex) => (
                <tr key={rowIndex}>
                    {[...Array(columns)].map((columnValue, columnIndex) => (
                        <td key={rowIndex + columnIndex}>
                            <div style={{ backgroundColor: color }}> </div>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
}

TablePreloader.propTypes = {
    color: PropTypes.string,
    rows: PropTypes.number,
    columns: PropTypes.number.isRequired,
};

TablePreloader.defaultProps = {
    rows: 10,
};

export default TablePreloader;
