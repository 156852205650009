import React, { useEffect } from 'react';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import * as ls from 'local-storage';

import GlobalSidebar from '../../../../../common/globalBar/globalSidebar';

// Redux - actions
import * as act from '../../../../../../../redux/actions/live/uiActionsLive';
import { setVenueSettings } from '../../../../../../../redux/actions/venues/actionsVenues';

// api
import { getSettings } from 'apis/rest/livestream/GetSettings';
import { getTriggers } from 'apis/rest/GetTriggers';

import './sponsorMenu.scss';

const SponsorMenu = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { SubMenu } = Menu;

    // params
    const { projectId: directProjectId, companyId } = useParams();

    useEffect(() => {
        // Ensure that menu takes up space
        dispatch(act.updateMainMenuVisibility(true));
    }, []);

    // TODO: put this logic somewhere else? why is the menu handling this ui update?
    // this is the same issue in mainMenu.jsx
    useEffect(() => {
        async function fetchProject() {
            //If super admin no need fetch
            const response = await getSettings(directProjectId);
            if (response.message) {
                history.push('/?err=snf');
            } else {
                dispatch(act.updateHeaderTitle(response.setting.title));
                // save user timezone
                ls('timezone', response.setting.timezone);

                // save venueId and if enableVenue
                dispatch(
                    setVenueSettings({
                        venueId: response.setting.venueId,
                        enableVenue: response.setting.enableVenue,
                        analyticsSiteId: response?.setting?.analyticsSiteId,
                        experience: response?.setting?.experience?.name,
                    })
                );
                dispatch({
                    type: 'SET_CUSTOM_DOMAIN',
                    payload: response?.setting?.customDomain,
                });
                dispatch({
                    type: 'SET_PROJECT_TIMEZONE',
                    payload: response?.setting?.timezone,
                });
                // if project has venue
                // setHasAlreadyVenue(response?.setting?.venueId ? true : false)
            }
        }

        fetchProject();
        dispatch(act.updateProjectId(directProjectId));
    }, []);

    // placement: boolean => if true then set element to right, else left
    // priActive: string => the main link, this case, People and Live
    // secActive: string => the sub link of main
    // all: boolean => determine if all menu will be returned
    const { placement, priActive, secActive, all, match, useGlobalSlider=true } = props;
    // Component states

    const { sponsorMenuData, sponsorMenuOpenKeys } = useSelector(
        (state) => state.sponsorMenu
    );
    const sponsorMenuSelectedKeys = sponsorMenuData
        .flatMap((menuItem) => {
            if (menuItem.sublinks) {
                return menuItem.sublinks.map((subMenuItem) => subMenuItem.link);
            }

            return [menuItem.link];
        })
        .filter((link) => {
            if (link === '') {
                return match.path === '/company/:companyId/project/:projectId';
            }
            return match.path.includes(link);
        });

    // triggers
    const { triggers } = useSelector((state) => state?.settings);
    const fetchTriggers = async () => {
        try {
            const {
                triggers: resTriggers,
                status,
                message = null,
            } = await getTriggers();
            if (status) {
                dispatch({
                    type: 'SET_TRIGGERS',
                    payload: resTriggers,
                });
            } else throw new Error(message);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (triggers?.length === 0) fetchTriggers();
    }, [triggers]);

    const handleSubMenuClick = (event) => {
        if (sponsorMenuOpenKeys.includes(event.key)) {
            dispatch({
                type: 'UPDATE_SPONSOR_MENU',
                payload: {
                    sponsorMenuOpenKeys: sponsorMenuOpenKeys.filter(
                        (item) => item !== event.key
                    ),
                },
            });
        } else {
            dispatch({
                type: 'UPDATE_SPONSOR_MENU',
                payload: {
                    sponsorMenuOpenKeys: [...sponsorMenuOpenKeys, event.key],
                },
            });
        }
    };

    return (
        <GlobalSidebar
            right={placement && true}
            width={195}
            zIndex={50}
            top={50}
            useGlobalSlider={useGlobalSlider}
        >
            <Menu
                className={'sponsorMenu'}
                style={{ width: '100%' }}
                mode="inline"
                defaultOpenKeys={sponsorMenuOpenKeys}
                defaultSelectedKeys={sponsorMenuSelectedKeys}
            >
                {sponsorMenuData.map((menuItem, index) => {
                    if (!menuItem.sublinks) {
                        return (
                            <Menu.Item
                                className={'sponsorMenuItem'}
                                icon={
                                    menuItem.icon && (
                                        <svg height={16} width={16}>
                                            <use
                                                href={`/assets/sponsor-menu/${menuItem.icon}#sponsor-menu-icon`}
                                            />
                                        </svg>
                                    )
                                }
                                key={menuItem.link}
                                style={{ display: 'flex', width: 'initial' }}
                            >
                                <Link
                                    to={`/company/${companyId}/project/${directProjectId}/${menuItem.link}`}
                                >
                                    <p style={{ margin: 0 }}>{menuItem.name}</p>
                                </Link>
                            </Menu.Item>
                        );
                    }

                    return (
                        <SubMenu
                            className={'sponsorMenuSubMenu'}
                            key={menuItem.name}
                            icon={
                                menuItem.icon && (
                                    <img
                                        src={`/assets/sponsor-menu/${menuItem.icon}`}
                                        height={16}
                                        width={16}
                                    />
                                )
                            }
                            title={menuItem.name}
                            onTitleClick={handleSubMenuClick}
                        >
                            {menuItem.sublinks.map((subMenuItem, index2) => {
                                return (
                                    <Menu.Item
                                        className={'sponsorMenuSubMenuItem'}
                                        icon={
                                            subMenuItem.icon && (
                                                <svg
                                                    href={`/assets/sponsor-menu/${menuItem.icon}`}
                                                    height={16}
                                                    width={16}
                                                />
                                            )
                                        }
                                        key={subMenuItem.link}
                                        style={{
                                            display: 'flex',
                                            width: 'initial',
                                        }}
                                    >
                                        <Link
                                            to={`/company/${companyId}/project/${directProjectId}/${subMenuItem.link}?sponsor=1`}
                                        >
                                            <p style={{ margin: 0 }}>
                                                {subMenuItem.name}
                                            </p>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                })}
            </Menu>
         </GlobalSidebar>
    );
};

export default withRouter(SponsorMenu);
