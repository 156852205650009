import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'antd';

import {
    getAllReactions,
    createReaction,
    deleteReaction,
    setFrontEmojiReaction,
    restoreAllReactions,
} from 'apis/rest/reactions';

import { generateValidation } from 'utils/inputValidation';
import GlobalLoading from 'components/app/components/common/globalLoading';
import Prompt from 'components/app/common/prompt';
import ImageUpload from 'components/app/common/imageUpload';
import { openNotification } from 'Apps/VenueBuilder/helpers/openNotification';
import { CheckIcon, DeleteIcon, UploadIcon, RestoreIcon } from './icons';
import './index.scss';

export default function Reactions({
    isModalPopup = false,
    setFrontEmoji = null,
}) {
    const { projectId } = useParams();

    const { register, errors, handleSubmit, unregister, setValue } = useForm();

    const [loadingReactions, setLoadingReactions] = useState(false);
    const [reactions, setReactions] = useState([]);
    const [hasCustomReactions, setHasCustomReactions] = useState(false);

    // Create Emoji States
    const uploadImageDivRef = useRef(null);
    const [isEmojiUploading, setIsEmojiUploading] = useState(false);
    const [emojiName, setEmojiName] = useState('');
    const [emojiIcon, setEmojiIcon] = useState(null);
    const [emojiIconError, setEmojiIconError] = useState(false);
    const isCreateEmojiDisabled =
        isEmojiUploading ||
        !emojiName ||
        !emojiIcon ||
        emojiIconError ||
        errors?.emojiName?.message;

    // Delete Emoji States
    const [selectedDeleteEmoji, setSelectedDeleteEmoji] = useState(null);
    // Front Emoji States
    const [selectedFrontEmoji, setSelectedFrontEmoji] = useState(null);
    // Restore Emoji States
    const [showRestoreEmojiPopup, setShowRestoreEmojiPopup] = useState(false);

    async function fetchAllReactions() {
        try {
            if (loadingReactions) return;
            setLoadingReactions(true);
            const resp = await getAllReactions({ projectId, type: 'all' });
            if (!resp.status) throw resp;
            const frontEmojiReaction = resp?.reactions?.find(
                (reaction) => reaction?.isFrontEmoji
            );
            const sortedReactions = [
                frontEmojiReaction,
                ...resp?.reactions?.filter(
                    (reaction) => !reaction?.isFrontEmoji
                ),
            ];
            setHasCustomReactions(
                sortedReactions.some((reaction) => reaction?.type !== 'system')
            );
            setReactions(sortedReactions);
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setLoadingReactions(false);
        }
    }

    async function createReactionHandler() {
        try {
            if (loadingReactions || isCreateEmojiDisabled || isEmojiUploading)
                return;
            if (reactions.length >= 10) {
                return openNotification('error', {
                    message: 'Maximum 10 emojis allowed',
                });
            }
            setIsEmojiUploading(true);
            const resp = await createReaction({
                projectId,
                payload: {
                    name: emojiName,
                    icon: emojiIcon,
                },
            });
            if (!resp.status) throw resp;
            resetEmojiUpload();
            fetchAllReactions();
            openNotification('success', {
                message: 'Emoji created successfully',
            });
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setIsEmojiUploading(false);
        }
    }

    async function deleteReactionHandler() {
        try {
            if (!selectedDeleteEmoji) return;
            if (selectedDeleteEmoji?.isFrontEmoji) {
                return openNotification('error', {
                    message: "You can't delete the Front Emoji",
                });
            }
            if (reactions.length <= 4) {
                return openNotification('error', {
                    message:
                        'Unable to delete Emoji. You need to have at least 4 Emojis',
                });
            }
            const resp = await deleteReaction({
                projectId,
                reactionId: selectedDeleteEmoji?.id,
            });
            if (!resp.status) throw resp;
            fetchAllReactions();
            openNotification('success', {
                message: 'Emoji deleted successfully',
            });
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setSelectedDeleteEmoji(null);
        }
    }

    async function setFrontEmojiReactionHandler() {
        try {
            if (selectedFrontEmoji?.isFrontEmoji) return;
            const reactionId = selectedFrontEmoji?.id;
            const resp = await setFrontEmojiReaction({
                projectId,
                reactionId,
            });
            if (!resp.status) throw resp;
            if (typeof setFrontEmoji === 'function') {
                setFrontEmoji(selectedFrontEmoji);
            }
            fetchAllReactions();
            openNotification('success', {
                message: 'Front Emoji set successfully',
            });
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setSelectedFrontEmoji(null);
        }
    }

    async function restoreAllReactionsHandler() {
        try {
            if (!showRestoreEmojiPopup) return;
            const resp = await restoreAllReactions({
                projectId,
            });
            if (!resp.status) throw resp;
            fetchAllReactions();
            openNotification('success', {
                message: 'Restored default emojis successfully',
            });
        } catch (e) {
            console.error(e);
            openNotification('error', {
                message: e?.message || 'Something went wrong',
            });
        } finally {
            setShowRestoreEmojiPopup(false);
        }
    }

    function openEmojiSelectDialogueHandler() {
        if (emojiIcon || !uploadImageDivRef.current) return;
        if (reactions.length >= 10) {
            return openNotification('error', {
                message: 'Maximum 10 emojis allowed',
            });
        }
        const input = uploadImageDivRef.current.querySelector(
            'div.image-upload-dropzone input[type="file"]'
        );
        if (!input) return;
        input.click();
    }

    function prepareEmojiUploadHandler(files) {
        if (reactions.length >= 10) {
            return openNotification('error', {
                message: 'Maximum 10 emojis allowed',
            });
        }
        if (isEmpty(files)) {
            setEmojiIcon(null);
            setEmojiIconError(true);
        } else {
            setEmojiIcon(files[0]);
            setEmojiIconError(false);
        }
    }

    function resetEmojiUpload() {
        setEmojiName('');
        setEmojiIcon(null);
        setEmojiIconError(false);
        unregister('emojiName');
    }

    useEffect(() => {
        fetchAllReactions();
    }, []);

    const getReactions = () =>
        loadingReactions ? (
            <GlobalLoading loadingText="Loading Reactions..." />
        ) : (
            reactions?.map((reaction) => (
                <div
                    key={reaction?.id}
                    className="emojiSelect tw-group tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-px-3 tw-py-3 hover:tw-bg-blue-100"
                >
                    <div className="shakeParent tw-w-fit tw-flex tw-flex-row tw-flex-nowrap tw-justify-start tw-items-center">
                        <div className="shake tw-p-0 tw-m-0">
                            <img
                                src={reaction?.icon}
                                className="tw-w-8 tw-h-8"
                                alt={reaction?.name}
                            />
                        </div>
                        <p className="tw-p-0 tw-m-0 tw-pl-2 tw-text-xssm">
                            {reaction?.name}
                        </p>
                    </div>
                    <div className="tw-w-fit tw-flex tw-pr-2 tw-flex-row tw-flex-nowrap tw-justify-start tw-items-center">
                        <div className="tw-w-fit tw-flex tw-flex-row tw-flex-nowrap tw-items-center">
                            <span
                                className={`${
                                    reaction?.isFrontEmoji
                                        ? ''
                                        : 'tw-hidden group-hover:tw-block'
                                } tw-p-0 tw-m-0 tw-mr-2 tw-w-fit tw-font-montserrat tw-font-extralight tw-slate-700 tw-text-xs`}
                            >
                                {reaction?.isFrontEmoji
                                    ? 'This is your Front Emoji'
                                    : 'Select to make this front emoji'}
                            </span>
                            <span
                                className={`${
                                    reaction?.isFrontEmoji
                                        ? 'scale'
                                        : 'scale-sm tw-hidden group-hover:tw-block'
                                } tw-p-0 tw-m-0 tw-mr-2 tw-w-fit tw-cursor-pointer tw-transition-all tw-duration-200 tw-ease-in-out`}
                                onClick={() => setSelectedFrontEmoji(reaction)}
                            >
                                <CheckIcon isFilled={reaction?.isFrontEmoji} />
                            </span>
                        </div>
                        <span
                            className="scale tw-hidden group-hover:tw-block tw-p-1 tw-m-0 tw--mt-1 tw-w-fit tw-cursor-pointer tw-transition-all tw-duration-200 tw-ease-in-out"
                            onClick={() => {
                                if (reaction?.isFrontEmoji) {
                                    return openNotification('error', {
                                        message:
                                            "You can't delete the Front Emoji",
                                    });
                                }
                                if (reactions.length <= 4) {
                                    return openNotification('error', {
                                        message:
                                            'Unable to delete Emoji. You need to have at least 4 Emojis',
                                    });
                                }
                                setSelectedDeleteEmoji(reaction);
                            }}
                        >
                            <DeleteIcon />
                        </span>
                    </div>
                </div>
            ))
        );

    const getReactionUploader = () => (
        <div
            className={`${
                emojiIcon
                    ? 'tw-p-2 tw-border-solid tw-border-blue-antd'
                    : 'tw-p-3 tw-border-dashed tw-border-black'
            } tw-absolute tw-bottom-0 tw-w-full tw-flex tw-flex-row tw-items-center tw-border tw-rounded-md tw-shadow-lg`}
        >
            <div ref={uploadImageDivRef} className="tw-p-0 tw-pr-2">
                {reactions.length >= 10 ? (
                    <span
                        className="tw-p-0 tw-m-0 tw-cursor-pointer"
                        onClick={() =>
                            openNotification('error', {
                                message: 'Maximum 10 emojis allowed',
                            })
                        }
                    >
                        <UploadIcon />
                    </span>
                ) : emojiIcon ? (
                    <img
                        src={emojiIcon?.preview}
                        alt={emojiName}
                        className="tw-w-8 tw-h-8"
                    />
                ) : (
                    <ImageUpload
                        disableStyle={true}
                        thumbsHeight={12}
                        autoUpload={false}
                        previewImages={emojiIcon ? [emojiIcon] : []}
                        prepareFilesUpload={prepareEmojiUploadHandler}
                        imageCropRecommendedSize={{
                            width: 30,
                            height: 30,
                        }}
                        imageCropAspectRatio={1}
                        uploadIcon={<UploadIcon />}
                        classicButtons={true}
                        showRecommendedSize={false}
                    />
                )}
            </div>
            {emojiIcon ? (
                <input
                    name="emojiName"
                    className="tw-w-full tw-mt-0 tw-p-0 tw-border-0 tw-rounded placeholder:tw-text-gray-500 placeholder:tw-text-sm"
                    placeholder="Enter emoji name"
                    value={emojiName}
                    autoFocus
                    onChange={(e) => {
                        setEmojiName(e.target.value);
                        setValue('emojiName', e.target.value, {
                            shouldValidate: true,
                        });
                    }}
                    ref={register(
                        'emojiName',
                        generateValidation({
                            name: 'name',
                            inputLabel: 'Emoji Name',
                            isRequired: true,
                            maxLength: 50,
                        })
                    )}
                />
            ) : (
                <p
                    onClick={openEmojiSelectDialogueHandler}
                    className="tw-p-0 tw-m-0 tw-text-xssm tw-text-black tw-cursor-pointer"
                >
                    Upload custom emoji{' '}
                    <span className="tw-text-blue-500 tw-text-xs">
                        ( recommended size 30*30 & file type: PNG, JPG, SVG )
                    </span>
                </p>
            )}
            {emojiIcon ? (
                <Tooltip placement="top" title="Reset">
                    <span
                        className="flip tw-p-0 tw-m-0 tw-ml-2 tw-w-fit tw-cursor-pointer tw-transition-all tw-duration-200 tw-ease-in-out"
                        onClick={resetEmojiUpload}
                    >
                        <img
                            src="./assets/refresh.svg"
                            alt="Refresh"
                            className="tw-w-6 tw-h-6"
                        />
                    </span>
                </Tooltip>
            ) : null}
        </div>
    );

    const getReactionsView = (className = 'tw-rounded-md') => (
        <div
            className={`tw-w-full tw-my-6 tw-mx-0 tw-py-8 tw-px-6 tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center ${className}`}
        >
            <div className="tw-w-full tw-flex tw-flex-row tw-rounded-md tw-py-1 tw-px-3">
                <div className="tw-w-1/2 tw-flex tw-flex-col">
                    <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-start">
                        <p className="tw-p-0 tw-m-0 tw-text-black tw-text-base tw-font-semibold tw-font-montserrat">
                            Change the front emoji
                        </p>
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-start">
                        <span className="tw-w-8/12 tw-text-sm tw-font-medium tw-pt-6 tw-tracking-wide tw-font-montserrat">
                            Front emoji is the minimised state icon for the
                            reaction feature on video and live stream block.
                        </span>
                        <span className="tw-w-8/12 tw-text-sm tw-font-normal tw-pt-3 tw-tracking-wide tw-font-montserrat">
                            <span className="tw-font-semibold">Note :</span>{' '}
                            Maximum 10 emojis
                        </span>
                        <img
                            src="./assets/reactions-livePage-example.png"
                            className="tw-mt-5"
                        />
                    </div>
                </div>
                <div className="tw-w-1/2 tw-flex tw-flex-col">
                    <div className="tw-flex tw-flex-col tw-mt-0">
                        <div className="tw-w-full tw-flex tw-flex-col tw-relative">
                            <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-border tw-border-b-0 tw-border-solid tw-border-gray-300 tw-rounded-md tw-p-0 tw-pb-14">
                                <div className="tw-p-0 tw-m-0 tw-w-full tw-h-full tw-max-h-96 tw-overflow-y-scroll">
                                    {getReactions()}
                                    <Prompt
                                        show={!!selectedDeleteEmoji}
                                        message={`Are you sure you want to delete the "${selectedDeleteEmoji?.name}" emoji?`}
                                        buttons={['Cancel', 'Delete']}
                                        onHide={() =>
                                            setSelectedDeleteEmoji(null)
                                        }
                                        callback={() => deleteReactionHandler()}
                                    />
                                    <Prompt
                                        show={!!selectedFrontEmoji}
                                        message={`Are you sure you want to select "${selectedFrontEmoji?.name}" as front emoji?`}
                                        description="This will be updated on all the video and live stream block"
                                        buttons={[
                                            'Cancel',
                                            'Proceed to Change',
                                        ]}
                                        onHide={() =>
                                            setSelectedFrontEmoji(null)
                                        }
                                        callback={() =>
                                            setFrontEmojiReactionHandler()
                                        }
                                    />
                                    <Prompt
                                        show={showRestoreEmojiPopup}
                                        message="Are you sure you want to restore the default emojis?"
                                        description="This will discard all your changes"
                                        buttons={[
                                            'Cancel',
                                            'Proceed to Restore',
                                        ]}
                                        onHide={() =>
                                            setShowRestoreEmojiPopup(false)
                                        }
                                        callback={() =>
                                            restoreAllReactionsHandler()
                                        }
                                    />
                                </div>
                                {getReactionUploader()}
                            </div>
                        </div>
                        {emojiIconError && (
                            <p className="tw-w-full tw-p-0 tw-m-0 tw-mt-2 tw-text-rose-700">
                                Emoji Icon is required
                            </p>
                        )}
                        {errors?.emojiName?.message && (
                            <p className="tw-w-full tw-p-0 tw-m-0 tw-mt-2 tw-text-rose-700">
                                {errors.emojiName.message}
                            </p>
                        )}
                        <div className="tw-mt-2 tw-flex tw-flex-row">
                            <button
                                type="button"
                                disabled={isCreateEmojiDisabled}
                                className={`${
                                    isCreateEmojiDisabled
                                        ? 'tw-bg-blue-antd/50'
                                        : 'tw-bg-blue-antd'
                                } tw-w-auto tw-mt-6 tw-text-sm tw-text-white tw-font-openSans tw-font-semibold tw-rounded tw-py-2 tw-px-4 tw-border-0 tw-whitespace-nowrap`}
                                onClick={createReactionHandler}
                            >
                                {isEmojiUploading
                                    ? 'Saving...'
                                    : 'Save Changes'}
                            </button>
                            {hasCustomReactions || reactions.length < 10 ? (
                                <button
                                    type="button"
                                    className="tw-w-auto tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-6 tw-ml-4 tw-text-sm tw-text-blue-antd tw-font-openSans tw-font-semibold tw-rounded tw-py-2 tw-px-4 tw-bg-white tw-border tw-border-solid tw-border-blue-antd tw-whitespace-nowrap"
                                    onClick={() =>
                                        setShowRestoreEmojiPopup(true)
                                    }
                                >
                                    <span className="tw-p-0 tw-m-0 tw-mr-2">
                                        <RestoreIcon />
                                    </span>
                                    Restore default emojis
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (isModalPopup) {
        return getReactionsView('tw-rounded-none');
    }

    return (
        <div className="tw-w-full tw-pr-2 tw-overflow-y-scroll">
            <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-8">
                <div className="tw- tw-flex tw-flex-col tw-w-full tw-">
                    <p className="tw-p-0 tw-m-0 tw-pt-2 tw-text-sm tw-font-light">
                        This is a collection of all your emojis that can be used
                        by users to react on live streaming or on any video.
                    </p>
                </div>
            </div>

            {getReactionsView('tw-rounded-md')}
        </div>
    );
}
