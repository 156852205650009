import * as ls from 'local-storage';
import {
    UPDATE_LIVEPAGES_LIST,
    UPDATE_LIVEPAGE_STATUS,
    UPDATE_VIEW,
    UPDATE_SIDEBAR_MENU,
    UPDATE_VENUE_BUILDER,
    UPDATE_VERSION,
    ADD_CONTENT_BLOCK_SUCCESS,
    EDIT_CONTENT_BLOCK_SUCCESS,
    UPDATE_PREVIEW_TOKEN,
    UPDATE_BLOCK_ORDER,
    UPDATE_BLOCK_VISIBILITY,
    UPLOAD_IMAGE_SUCCESS,
    DELETE_BLOCK_SUCCESS,
    UPDATE_LIVEBAR_SETTINGS,
    DELETE_IMAGE,
    UPDATE_MENU_LIST,
    REMOVE_IMAGE_URL,
    SET_ACTIVE_CONTENT,
    SET_PREVIEW_LOADING,
    SET_SUBMIT_LOADING,
    GEVME_LIVE_CHANNELS,
    GEME_LIVE_PEOPLE,
    SET_VENUE_DETAILS,
    SET_COMPANY_DETAILS,
    UPDATE_VERSION_COUNT,
} from '../actionTypes';
import { initialState } from '../store';
import { produce } from 'immer';

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VENUE_DETAILS: {
            return {
                ...state,
                venue: action.payload,
            };
        }
        case SET_COMPANY_DETAILS: {
            return {
                ...state,
                companyDetails: action.payload,
            };
        }
        case UPDATE_LIVEPAGES_LIST: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case UPDATE_VENUE_BUILDER: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case UPDATE_VERSION_COUNT: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case UPDATE_VIEW: {
            return {
                ...state,
                header: {
                    ...state.header,
                    ...action.payload,
                },
            };
        }
        case UPDATE_SIDEBAR_MENU:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...action.payload,
                },
            };
        case UPDATE_VERSION:
            if (action.payload === 'tosave') {
                ls.set('saveandpublished', true);
            } else {
                ls.set('saveandpublished', false);
            }
            return {
                ...state,
                version: action.payload ? action.payload : Date.now(), //ideally should be updated to block ids/widgets updated
                versionCount:
                    action.payload === 'saved'
                        ? state?.versionCount + 1
                        : state?.versionCount,
            };
        case ADD_CONTENT_BLOCK_SUCCESS:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    blocks: [
                        ...state.sidebar.blocks,
                        {
                            ...state.sidebar.item,
                            ...action.payload,
                        },
                    ],
                    item: {
                        ...state.sidebar.item,
                        ...action.payload,
                    },
                    imageurls: [],
                },
            };
        case EDIT_CONTENT_BLOCK_SUCCESS:
            return produce(state, (draftState) => {
                const blockIndex = draftState.sidebar.blocks.findIndex(
                    (block) => block.id === action.payload.id
                );
                draftState.sidebar.blocks[blockIndex] = {
                    ...draftState.sidebar.blocks[blockIndex],
                    ...action.payload,
                };
                draftState.sidebar.item = {
                    ...state.sidebar.item,
                    ...action.payload,
                };
                delete draftState.sidebar.imageurls;
            });
        case UPDATE_PREVIEW_TOKEN:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    ...action.payload,
                },
            };
        case UPDATE_BLOCK_ORDER:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    blocks: action.payload,
                },
            };
        case UPDATE_LIVEPAGE_STATUS:
            return {
                ...state,
                header: {
                    ...state.header,
                    selection: {
                        ...state.header.selection,
                        ...action.payload,
                    },
                },
            };
        case UPDATE_BLOCK_VISIBILITY:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    blocks: action.payload,
                },
            };
        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    imageurls:
                        state.sidebar?.imageurls?.length > 0
                            ? [...state.sidebar?.imageurls, action.payload]
                            : [action.payload],
                },
            };
        case DELETE_BLOCK_SUCCESS:
            return produce(state, (draftState) => {
                const deleteBlockIndex = draftState.sidebar.blocks.findIndex(
                    (block) => block.id === action.payload
                );
                if (deleteBlockIndex !== -1)
                    draftState.sidebar.blocks.splice(deleteBlockIndex, 1);
            });
        case UPDATE_LIVEBAR_SETTINGS:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    widgets: action.payload,
                },
            };

        case UPDATE_MENU_LIST:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    menuList: action.payload,
                },
            };
        case DELETE_IMAGE:
            return produce(state, (draftState) => {
                const deleteImageIndex =
                    draftState.sidebar?.item?.images?.findIndex(
                        (image) => image.url === action.payload.imageUrl
                    );
                if (deleteImageIndex !== -1)
                    draftState.sidebar.item.images.splice(deleteImageIndex, 1);
            });
        case REMOVE_IMAGE_URL:
            if (action.payload) {
                let images = state.sidebar?.imageurls?.filter(
                    (image) => image.url !== action.payload
                );
                return {
                    ...state,
                    sidebar: {
                        ...state.sidebar,
                        imageurls: images,
                    },
                };
            } else {
                return {
                    ...state,
                    sidebar: {
                        ...state.sidebar,
                        imageurls: [],
                    },
                };
            }
        case SET_ACTIVE_CONTENT:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    contentLayout: action.payload,
                },
            };
        case SET_PREVIEW_LOADING:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    loading: action.payload,
                },
            };
        case SET_SUBMIT_LOADING:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    loading: action.payload,
                },
            };
        case GEVME_LIVE_CHANNELS:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    channels: action.payload,
                },
            };
        case GEME_LIVE_PEOPLE:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    people: action.payload,
                },
            };
        default:
            return state;
    }
}
