import { gql } from "apollo-boost";

const SHOWCASES = gql`
  query Showcases($where: JSON) {
    showcases(where: $where) {
      id
      title
      templateType
    }
  }
`;
 
export default SHOWCASES;
