import React from 'react';

import { useSelector } from 'react-redux';

import ContentInteractions from './contentInteractions';
import Content from './content';

const Main = () => {
    const enableVenue = useSelector(
        (state) => state?.venuesettings?.enableVenue
    );
    return <>{enableVenue ? <ContentInteractions /> : <Content />}</>;
};

export default Main;
