import { gql } from "apollo-boost";

const PERSON = gql`
  query Person($id: ID!) {
    person(id: $id) {
      id
      companyName
      address
      website
      email
      phone
      industry

      linkedin
      facebook
      twitter

      contactPersonFirstName
      contactPersonLastName
      contactPersonJobTitle
      contactPersonEmail
      contactPersonMobile
      contactPersonWorkPhone
      contactPersonLinkedin
      contactPersonTwitter

      locationCity
      locationCountry

      booth
      logo {
        id
        name
        url
      }
    }
  }
`;

export default PERSON;
