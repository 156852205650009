import { isEmail } from "validator";

const loginValidationCriteria = input => {
  const formErrors = {};

  switch (input.type) {
    case 'email':
      if (!input.email) {
        formErrors.email = "Please enter your email.";
      } else {
        if (isEmail(input.email)) {
          formErrors.email = "";
        } else {
          formErrors.email = "Please enter the correct email format.";
        }
      }
      break;

    case 'phoneNumber':
      if (!input.phoneNumber?.value) {
        formErrors.phoneNumber = "Please enter your phone number.";
      }
      break;

  }

  return formErrors;
};

export default loginValidationCriteria;
