import React, { useState, useEffect } from 'react';
import { withRouter, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery, useMutation } from '@apollo/react-hooks';

import * as actUi from 'redux/actions/live/uiActionsLive';
import * as actSettings from 'redux/actions/live/settingsActionsLive';

import { generateLivePagePreviewLink } from 'components/app/utils/functions/xpmgr/common';
import { getSettings } from 'apis/rest/livestream/GetSettings';
import { isEmpty } from 'lodash';

import RestrictedElement from 'components/app/common/restrictedElement';

import useAlertBar from 'components/app/utils/hooks/useAlertBar';

import SHOWCASE_PUBLISHED from 'apis/graphql/ShowcasePublished';
import UPDATE_SHOWCASE from 'apis/graphql/UpdateShowcase';
import SHOWCASE from 'apis/graphql/Showcase';

import Layout from 'components/app/common/layout';
import SidebarContainer from 'components/app/common/globalBar/sidebarContainer';

import MainMenu from 'components/app/components/admin/menus/mainMenu';
import SubMenu from 'components/app/components/admin/menus/subMenu';

import updateLivePageStatus from 'apis/rest/UpdateLivePageStatus';

const LivePageWrapper = (WrappedComponent) => {
    function LiveSite(props) {
        const { projectId } = useParams();
        const baseRouteToMatch = '/project/:projectId/live/pages/:showcaseId/';
        const settings = useRouteMatch(`${baseRouteToMatch}settings`);
        const content = useRouteMatch(`${baseRouteToMatch}content`);
        const widgets = useRouteMatch(`${baseRouteToMatch}widgets`);
        const [projectSettings, setProjectSettings] = useState({});
        const [menuPermissions, setMenuPermissions] = useState(null);
        const [showcase, setShowcase] = useState();

        const params = useParams();
        const dispatch = useDispatch();
        const enabledVenue = useSelector(
            (state) => state?.venuesettings?.enableVenue
        );
        const livePageSlug = useSelector(
            (state) => state.settings.showcaseSlug
        );

        const userRole = useSelector((state) => state?.user?.roleId);

        const livePageShowcaseId = params.showcaseId;

        const [isLivePagePublished, setIsLivePagePublished] =
            useState(undefined);

        // get project settings
        const getProjectSettings = async () => {
            try {
                const { setting } = await getSettings(projectId);
                if (!isEmpty(setting)) {
                    setProjectSettings(setting);
                }
            } catch (error) {
                console.error(error);
            }
        };

        useEffect(() => {
            // Reset allowFX to false, to disable transition effects
            dispatch(actUi.updateAllowFX(false));
            if (isEmpty(projectSettings)) {
                getProjectSettings();
            }
            if (isEmpty(showcase)) {
                fetchShowcase();
            }
            return () => {
                setProjectSettings(null);
            };
        }, []);

        const { showAlertBar } = useAlertBar();

        const { refetch: refetchShowcase } = useQuery(SHOWCASE_PUBLISHED, {
            variables: { id: livePageShowcaseId },
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                setIsLivePagePublished(data.showcase.isPublished);
                dispatch(actSettings.setShowcaseSlug(data.showcase.slug));
            },
        });

        const [updateShowcase] = useMutation(UPDATE_SHOWCASE, {
            onCompleted: (data) => {
                refetchShowcase();
                let status = isLivePagePublished ? 'Draft' : 'Live';
                showAlertBar(
                    `Your LivePage has been successfully updated to <span class="font-weight-bold">${status}</span>`,
                    'success'
                );
            },
            onError: (err) => {
                showAlertBar(err.message, 'error');
            },
        });

        /**
         * get the specific showcase (proper HOC)
         */
        const { loading, refetch: fetchShowcase } = useQuery(SHOWCASE, {
            variables: { id: livePageShowcaseId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                const { showcase } = data;

                setShowcase(showcase, userRole);
                if (showcase.permission) {
                    // permission only for user roleId 4
                    // console.log(JSON.stringify(showcase.permission.menu))
                    // setMenuPermissions(
                    //     {"setting":"hidden","liveWidget":"visible","content":"visible","agenda":"hidden"}
                    // )
                    setMenuPermissions(showcase.permission.menu || null);
                }
            },
        });
        /**
         * toggles `isPublished` status for a livepage
         */
        const toggleLivePagePublishedStatus = () => {
            updateLivePageStatus(
                livePageShowcaseId,
                projectId,
                isLivePagePublished ? 'unpublish' : 'publish'
            );
            updateShowcase({
                variables: {
                    input: {
                        data: {
                            isPublished: !isLivePagePublished,
                        },
                        where: {
                            id: livePageShowcaseId,
                        },
                    },
                },
            });
        };

        /**
         * determines active status of selected submenu item
         */
        const determineSubMenuActiveState = () => {
            switch (true) {
                case settings && settings.isExact:
                    return 'settings';
                case content && content.isExact:
                    return 'content';
                case widgets && widgets.isExact:
                    return 'livewidgets';
                default:
                    break;
            }
        };

        return (
            <Layout background="#f3f5fa" restrictAccess={props.restrictAccess}>
                {isLivePagePublished !== undefined &&
                    !isEmpty(projectSettings) && (
                        <WrappedComponent
                            showcase={showcase}
                            projSettings={projectSettings}
                            livePageSlug={livePageSlug}
                        />
                    )}

                <SidebarContainer>
                    <RestrictedElement
                        elementName="mainMenu"
                        element={
                            <MainMenu
                                priActive={
                                    enabledVenue ? 'virtual' : 'livepages'
                                }
                                secActive="livepages"
                                all
                            />
                        }
                    />

                    <SubMenu
                        menuPermissions={menuPermissions}
                        active={determineSubMenuActiveState()}
                        all
                        externalLiveSettings={{
                            isPublished: isLivePagePublished,
                            togglePublishedStatus:
                                toggleLivePagePublishedStatus,
                            previewLink:
                                generateLivePagePreviewLink(livePageSlug),
                        }}
                    />
                </SidebarContainer>
            </Layout>
        );
    }

    return withRouter(LiveSite);
};

export default LivePageWrapper;
