import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import BlockPrivate from '../../blockPrivate/blockPrivate';
import NameEditor from 'components/app/components/showcase/blockEditor/nameEditor';
import { PERMISSION_TYPE } from 'components/app/utils/permissions';
import ImageUpload from 'components/app/common/imageUpload/imageUpload';

function Video(props) {
    const {
        handleInputChange,
        prepareFileUpload,
        handleFileDelete,
        input,
        currUploads,
        renderFieldError,
        preventEditTitle = false,
        preventEditSize = false,
        preventEditName = false,
        permission = null,
    } = props;

    const { largeTile, title, url, name } = input;
    const { image } = currUploads;

    const [permissionSize, setPermissionSize] = useState(null);
    const [permissionDimension, setPermissionDimension] = useState(null);
    const [currentVisibilityControl, setCurrentVisibilityControl] = useState(
        PERMISSION_TYPE.VISIBLE
    );

    useEffect(() => {
        if (permission !== permissionSize) {
            const currentPermissonSize = permission[0]?.dimension;
            const currentVisibilityControl =
                permission[0]?.access?.content?.visibilityControl;

            if (currentVisibilityControl === PERMISSION_TYPE.HIDDEN) {
                setCurrentVisibilityControl(PERMISSION_TYPE.HIDDEN);
            }

            setPermissionSize(permission);
            if (currentPermissonSize) {
                Object.keys(currentPermissonSize).map((k) => {
                    if (name === k) {
                        setPermissionDimension(currentPermissonSize[k]);
                    }
                });
            }
        }
    }, [permission]);

    return (
        <div>
            <Tabs
                className="visibility-tabs"
                defaultActiveKey="content"
                id="visibility-tab-content"
            >
                <Tab eventKey="content" title="Content">
                    <div className="row mb-3">
                        <div className="col-6">
                            <div className="input-set">
                                <label>Block title</label>
                                <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    value={title}
                                    onChange={(e) => handleInputChange(e)}
                                    autoFocus
                                    maxLength={70}
                                    disabled={preventEditTitle}
                                />
                                {renderFieldError('title')}
                            </div>

                            {/* <NameEditor
                                disabled={preventEditName}
                                handleInputChange={handleInputChange}
                                name={name}
                            /> */}
                            {!preventEditSize && (
                                <>
                                    <label>Block size</label>
                                    <div className="radio-set">
                                        <span>
                                            <input
                                                type="radio"
                                                value={false}
                                                checked={largeTile === false}
                                                name="largeTile"
                                                onChange={(e) =>
                                                    handleInputChange(e)
                                                }
                                                id="normalBlockSize"
                                                disabled={preventEditSize}
                                            />{' '}
                                            <label htmlFor="normalBlockSize">
                                                Normal
                                            </label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={largeTile === true}
                                                name="largeTile"
                                                onChange={(e) =>
                                                    handleInputChange(e)
                                                }
                                                id="bigBlockSize"
                                                maxLength={200}
                                                disabled={preventEditSize}
                                            />{' '}
                                            <label htmlFor="bigBlockSize">
                                                Big
                                            </label>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="col-6">
                            <label>
                                Thumbnail{' '}
                                <span className="small">
                                    (.gif, .jpg/jpeg, .png, .svg)
                                </span>
                            </label>
                            <ImageUpload
                                previewImages={image ? image : []}
                                prepareFilesUpload={files => {
                                    prepareFileUpload(files, 'image')
                                }}
                                handleFileDelete={(files) => {
                                    handleFileDelete(files, 'image')
                                }}
                                imageCropRecommendedSize={{
                                    width: (permissionDimension &&
                                        permissionDimension[0]) ||
                                        null,
                                    height: (permissionDimension &&
                                        permissionDimension[1]) ||
                                        null
                                }}
                            />
                            {permissionDimension && (
                                <p>
                                    Recommended size: within{' '}
                                    {permissionDimension[0]} x{' '}
                                    {permissionDimension[1]} px
                                </p>
                            )}
                            {renderFieldError('image')}
                        </div>
                    </div>
                    <label>Video URL</label>
                    <p>Enter the URL of the video below.</p>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="url"
                            value={url}
                            className="form-control mb-2"
                            placeholder="e.g. http://www.youtube.com/watch?v=abcde"
                            id=""
                            onChange={(e) => handleInputChange(e)}
                        />
                        {renderFieldError('url')}
                    </div>
                </Tab>
                {currentVisibilityControl !== PERMISSION_TYPE.HIDDEN && (
                    <Tab
                        eventKey="visibility-control"
                        title="Visibility control"
                    >
                        <div>
                            <BlockPrivate {...props} />
                        </div>
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default Video;
