import { isURL, isEmail } from 'validator';

import { separateCamelCase } from 'components/app/utils/functions/common';

const personInfoValidationCriteria = (input) => {
    const formErrors = {};

    const phoneRegex = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

    const links = [
        'website',
        'linkedin',
        'twitter',
        'facebook',
        'contactPersonLinkedin',
        'contactPersonTwitter',
    ];
    const phoneNos = ['phone', 'contactPersonMobile', 'contactPersonWorkPhone'];
    const emails = ['email', 'contactPersonEmail'];

    const required = [
        'companyName',
        'website',
        'email',
        'phone',
        'locationCountry',
    ];

    required.forEach((requiredField) => {
        if (!input[requiredField]) {
            formErrors[requiredField] = `Please enter ${separateCamelCase(
                requiredField
            )}.`;
        } else {
            formErrors[requiredField] = '';
        }
    });

    links.forEach((link) => {
        if (input[link]) {
            if (isURL(input[link])) {
                formErrors[link] = '';
            } else {
                formErrors[link] = 'Please enter correct URL format.';
            }
        }
    });

    emails.forEach((email) => {
        if (input[email]) {
            if (isEmail(input[email])) {
                formErrors[email] = '';
            } else {
                formErrors[email] = 'Please enter correct email format.';
            }
        }
    });

    phoneNos.forEach((phoneNo) => {
        if (input[phoneNo]) {
            if (phoneRegex.test(input[phoneNo])) {
                formErrors[phoneNo] = '';
            } else {
                formErrors[phoneNo] =
                    'Please enter correct phone number format.';
            }
        }
    });

    return formErrors;
};

export default personInfoValidationCriteria;
