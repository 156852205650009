import React from 'react';

export default function BackupCodesIcon() {
    return (
        <svg
            width="50"
            height="51"
            viewBox="0 0 50 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_41_1107)">
                <path
                    d="M31.25 8.58366H10.4167V41.917H39.5833V16.917H31.25V8.58366ZM6.25 6.48366C6.25 5.34199 7.18125 4.41699 8.33125 4.41699H33.3333L43.75 14.8337V43.9857C43.7519 44.2593 43.6999 44.5306 43.597 44.7841C43.4941 45.0376 43.3422 45.2684 43.1501 45.4632C42.958 45.658 42.7294 45.813 42.4774 45.9195C42.2254 46.026 41.9548 46.0818 41.6813 46.0837H8.31875C7.77162 46.0798 7.24795 45.861 6.86087 45.4743C6.47379 45.0876 6.25436 44.5641 6.25 44.017V6.48366ZM36.7854 25.2503L29.4188 32.617L26.4729 29.6691L30.8917 25.2503L26.475 20.8316L29.4188 17.8837L36.7854 25.2503ZM13.2146 25.2503L20.5833 17.8837L23.5292 20.8316L19.1083 25.2503L23.525 29.6691L20.5813 32.617L13.2146 25.2503Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_41_1107">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                        transform="translate(0 0.250488)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
