import React, { useState, useEffect } from 'react';

const CountDownTimer = ({ timeLimit, onEnd, reset }) => {
    const initialMinutes = timeLimit;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let timer;

        if (isRunning) {
            timer = setInterval(() => {
                if (minutes === 0 && seconds === 0) {
                    clearInterval(timer);
                    onEnd();
                    setIsRunning(false);
                } else if (seconds === 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    setSeconds(seconds - 1);
                }
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [minutes, seconds, isRunning]);

    useEffect(() => {
        handleReset();
    }, [reset]);

    const handleReset = () => {
        setMinutes(initialMinutes);
        setSeconds(0);
        setIsRunning(true);
    };

    return (
        <span>
            {String(minutes).padStart(2, '0')}:
            {String(seconds).padStart(2, '0')}
        </span>
    );
};

export default CountDownTimer;
