import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Space, Input, Button, Menu, Dropdown } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import SideModal from 'components/app/common/sideModal/';
import FileSaver from 'file-saver';

import GlobalLoading from 'components/app/components/common/globalLoading/globalLoading';
import { GetExportInteractionsNotification } from 'apis/rest/notifications/GetExportInteractionsNotification';
import NoDataComponent from '../../../../../../../../ComponentsV2/CommonComponents/MobileView/noDataComponent/NoDataComponent';

import NotificationAnalytics from './notificationAnalytics';

import './sentTable.scss';

const SentTable = ({
    data,
    onRefreshData,
    pagination,
    loading,
    search,
    onClickDuplicateNotification,
    onClickDeleteNotification,
}) => {
    const { projectId, companyId } = useParams();
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [sideModalVisibility, setSideModalVisibility] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const { activeTimezone: timezone } = useSelector(
        (state) => state?.settings
    );

    useEffect(() => {
        const trimSearchText = searchText?.trim();
        search(trimSearchText);
    }, [searchText]);

    //Search part
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder="Search"
                    value={selectedKeys[0]}
                    defaultValue={searchText}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        const searchTextSelect = selectedKeys[0] ? selectedKeys[0] : searchText;
        if (!/\s/.test(searchTextSelect)) {
            confirm();
            setSearchText(searchTextSelect);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleGetExportInteractions = async (item) => {
        const { projectId } = item;
        const notificationId = item?.id || item?.notificationId;

        try {
            const response = await GetExportInteractionsNotification(
                projectId,
                notificationId
            );

            FileSaver.saveAs(response, `${notificationId}-all-report.csv`);
        } catch (error) {
            console.error('ERROR handleGetExportInteractions', error);
        }
    };

    const renderDropDownMenu = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <a onClick={() => onClickDuplicateNotification(item)}>
                        Duplicate
                    </a>
                </Menu.Item>
                <Menu.Item key="1">
                    <a onClick={() => handleGetExportInteractions(item)}>
                        Export data
                    </a>
                </Menu.Item>
                <Menu.Item key="2">
                    <a
                        onClick={() =>
                            onClickDeleteNotification(item?.notificationId)
                        }
                    >
                        Delete
                    </a>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: (a, b) => a?.title.localeCompare(b?.title),
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Sent on',
            dataIndex: 'updatedAt',
            sorter: (a, b) =>
                moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            render: (updatedAt, item) => {
                if (!updatedAt) return null;
                return (
                    <span>
                        {moment(updatedAt)
                            .tz(timezone)
                            .format('DD MMMM YYYY [at] h:mm:ss a')}
                    </span>
                );
            },
        },
        {
            title: 'Trigger',
            dataIndex: 'action',
            sorter: (a, b) => a?.target?.length - b?.target?.length,
            render: (action, item) => {
                let triggerTime = item?.triggerConfig?.timeInSecs;

                if (item?.triggerConfig?.timeUnit === 'mins') triggerTime /= 60;

                return (
                    <>
                        {triggerTime && (
                            <span>
                                {triggerTime} {item?.triggerConfig?.timeUnit}{' '}
                                after livepage loads
                            </span>
                        )}
                        {item?.trigger?.id && !triggerTime && (
                            <span>when livepage loads</span>
                        )}
                        {!item?.trigger?.id && <span>-</span>}
                    </>
                );
            },
        },
        {
            title: 'Target',
            dataIndex: 'targetId',
            sorter: (a, b) => a?.targetType.localeCompare(b?.targetType),
            render: (targetId, item) => {
                return (
                    <span>
                        {item?.targetType === 'project'
                            ? 'All Livepages'
                            : targetId?.length}
                    </span>
                );
            },
        },
        {
            title: 'Sent by',
            dataIndex: 'createdBySponsor.firstname',
            sorter: (a, b) =>
                a?.createdBySponsor?.firstname?.localeCompare(
                    b?.createdBySponsor?.firstname
                ),
            render: (updatedBySponsor, item) => {
                return (
                    <span>
                        {item?.createdBySponsor?.firstname
                            ? `${item?.createdBySponsor?.firstname} ${item?.createdBySponsor?.lastname}`
                            : `${item?.createdByUser?.firstname} ${item?.createdByUser?.lastname}`}
                    </span>
                );
            },
        },
        {
            title: 'Views',
            dataIndex: 'totalViews',
            sorter: (a, b) => a?.totalViews - b?.totalViews,
        },
        {
            title: 'Clicks',
            dataIndex: 'totalClicks',
            sorter: (a, b) => a?.totalClicks - b?.totalClicks,
        },
        {
            render: (item) => {
                return (
                    <div className="d-flex justify-content-end">
                        <button
                            className="myxp btn btn-sm btn-link tw-hidden md:tw-flex"
                            onClick={() =>
                                history.push({
                                    pathname: `/company/${companyId}/project/${projectId}/notification/edit-announcement/${item?.notificationId}`,
                                    state: {
                                        previewMode: true,
                                        sentTableType: true,
                                    },
                                })
                            }
                        >
                            View
                        </button>
                        <button
                            className="myxp btn btn-sm btn-link"
                            onClick={() => {
                                setSideModalVisibility(true);
                                setSelectedNotification(item);
                            }}
                        >
                            Report
                        </button>
                        <Dropdown
                            overlay={renderDropDownMenu(item)}
                            trigger={['click']}
                        >
                            <MoreOutlined
                                onClick={(e) => e.preventDefault()}
                                style={{ fontSize: 19 }}
                                className="pt-2"
                            />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const globalLoadingComponent = {
        spinning: loading,
        indicator: <GlobalLoading />,
    };

    const renderPagination = (current, type, originalElement) => {
        return <a onClick={() => pagination(current)}>{originalElement}</a>;
    };

    return (
        <>
            {!loading && data?.notifications?.length ? (
                <Table
                    className="sponsors-company-table"
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.notifications}
                    loading={globalLoadingComponent}
                    pagination={
                        pagination
                            ? {
                                  itemRender: renderPagination,
                                  pageSize: 10,
                                  total: data?.pagination?.total,
                                  current: data?.pagination?.currentPage,
                              }
                            : false
                    }
                />
            ) : (
                <NoDataComponent
                    loading={loading}
                    buttonText="Create announcement"
                    description="No announcement have been Created"
                    onClickHandler={() =>
                        history.push(
                            `/company/${companyId}/project/${projectId}/notification/create-announcement`
                        )
                    }
                />
            )}
            <style>{`
            .sponsors-company-table .ant-table{
                overflow: auto;
                }
            `}</style>
            <SideModal
                visibility={sideModalVisibility}
                size="xl"
                setVisibility={(status) => {
                    setSideModalVisibility(status);
                    setSelectedNotification(null);
                }}
                title={`"${selectedNotification?.title}"`}
                subTitle="Announcement report"
            >
                <NotificationAnalytics
                    selectedNotification={selectedNotification}
                />
            </SideModal>
        </>
    );
};

SentTable.propTypes = {
    data: PropTypes.object,
    onRefreshData: PropTypes.func,
    pagination: PropTypes.func,
    search: PropTypes.func,
    onClickDuplicateNotification: PropTypes.func,
    onClickDeleteNotification: PropTypes.func,
    loading: PropTypes.bool,
};

SentTable.defaultProps = {
    data: null,
    loading: false,
};

export default SentTable;
