import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';

import MediaAssets from 'ComponentsV2/ProjectDashboard/Components/Virtual/media-assets';
import CloseIcon from 'ComponentsV2/ProjectDashboard/Icons/Common/CloseIcon';
import defaultMenus from 'ComponentsV2/ProjectDashboard/config/menu.json';
import Reactions from 'ComponentsV2/ProjectDashboard/Components/Engagement/Reactions';

const ERROR_INITIAL_STATE = {
    audio: false,
    audioType: false,
};

export default function AudioModal({
    isVisible,
    setModal,
    setSelectedMediaAsset,
    setError,
    mediaAsset,
    modalType = 'audio',
    multiple=false
}) {
    const [selectedFileData, setSelectedFileData] = useState({});
    // disabling for now
    // const companyDetails = useSelector((state) => state?.projects?.company);
    // const appMenus = companyDetails?.menu || defaultMenus;
    // let mediaAssetsMenu = defaultMenus?.find((menu) => menu.app === 'event-content')?.menus?.find((menu) => menu.name === 'media-assets');
    // if (appMenus) {
    //     mediaAssetsMenu = appMenus?.find((menu) => menu.app === 'event-content')?.menus?.find((menu) => menu.name === 'media-assets');
    // }

    return (
        <Modal
            centered
            visible={isVisible}
            onCancel={() => setModal(false)}
            footer={
                modalType === 'reactions' ? null : (
                    <button
                        className="myxp btn btn-primary mx-auto"
                        disabled={
                            !selectedFileData || isEmpty(selectedFileData)
                        }
                        onClick={() => {
                            setSelectedMediaAsset(selectedFileData);
                            setError(ERROR_INITIAL_STATE);
                            setModal(false);
                        }}
                    >
                        Apply
                    </button>
                )
            }
            destroyOnClose={true}
            width={936}
            maskClosable={false}
            closeIcon={<CloseIcon color="#fff" />}
            className="audio-modal tw-relative"
            zIndex={2000}
        >
            {modalType === 'reactions' ? (
                <Reactions
                    isModalPopup={true}
                    setFrontEmoji={setSelectedMediaAsset}
                />
            ) : (
                <MediaAssets
                    multiple={multiple}
                    handleSelectFile={setSelectedFileData}
                    isTypeModal
                    modalType={modalType}
                    modalSelectedAssetId={mediaAsset?.id}
                    // menu={mediaAssetsMenu || {}}
                />
            )}
        </Modal>
    );
}
