import * as ls from 'local-storage';
import { apiInterceptor } from 'utils/apiInterceptor';
let authBearer = null;

//sponsor multi-login handling
const setAuthBearer = () => {
    try {
        if (window.SPONSOR_AUTH) {
            authBearer = `Bearer ${window.SPONSOR_AUTH?.access_token}`;
        } else {
            let auth = ls.get('auth');
            authBearer = auth && `Bearer ${auth?.access_token}`;
        }
    } catch (error) {
        console.error(error);
    }
};
/**
 * Get all projects for Livestream
 */
export async function updateStreamUserRole(projectId, offset = 0, showcaseId) {
    setAuthBearer();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: authBearer,
    };

    let payload = {
        offset,
    };

    if (showcaseId) payload.sponsorChannelId = showcaseId;

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/stream/${projectId}/assign`,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
        }
    );

    return await response.json();
}

export async function truncateAllMessages({ projectId, channelIds = null }) {
    setAuthBearer();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: authBearer,
    };

    let options = {
        method: 'POST',
        headers: headers,
    };

    if (channelIds) options.body = JSON.stringify({ channelIds });

    const { response } = await apiInterceptor(
        `${process.env.REACT_APP_MYXP_API}/manager/livestream/stream/${projectId}/truncate-channels`,
        options
    );

    return await response.json();
}
