import * as React from 'react';

const AaBlockIcon = (props) => {
    return (
        <svg
            width={20}
            height={14}
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.608.548l4.878 12.274a.8.8 0 01-.027.641.876.876 0 01-.499.438.944.944 0 01-.68-.02.867.867 0 01-.468-.467l-1.47-3.698a.078.078 0 00-.03-.037.085.085 0 00-.047-.013h-4.99a.085.085 0 00-.046.013.078.078 0 00-.03.037l-1.47 3.698a.867.867 0 01-.468.466.944.944 0 01-.68.02.876.876 0 01-.499-.437.8.8 0 01-.028-.641L4.933.548c.064-.161.178-.3.328-.398a.932.932 0 011.019 0c.15.098.264.237.328.398zm-.914 2.89L3.931 7.874a.073.073 0 00.01.071.08.08 0 00.028.024.085.085 0 00.038.009h3.527a.085.085 0 00.037-.009.08.08 0 00.03-.024.074.074 0 00.009-.07L5.846 3.437a.078.078 0 00-.03-.036.085.085 0 00-.092 0 .078.078 0 00-.03.036zm6.328 2.6c.647-1.44 2.166-2.367 3.862-2.356C18.17 3.698 20 5.477 20 7.632v5.485c0 .11-.024.22-.07.322a.844.844 0 01-.195.273.9.9 0 01-.29.182.942.942 0 01-.975-.187.84.84 0 01-.192-.274.8.8 0 01-.067-.324h-.002c-.824.584-1.909.891-3.17.891-.894 0-1.723-.29-2.33-.818-.647-.563-1.003-1.361-1.003-2.25 0-1.671 1.329-2.816 3.555-3.063.943-.105 2-.161 2.891-.184.016 0 .03-.007.042-.018a.056.056 0 00.017-.04c0-1.24-1.06-2.266-2.375-2.257-.957.006-1.806.526-2.168 1.327a.872.872 0 01-.488.453.944.944 0 01-.684-.006.87.87 0 01-.48-.461.8.8 0 01.006-.646zm1.473 4.893c0 1.02.832 1.381 1.545 1.381 2.01 0 3.05-.936 3.16-2.858a.074.074 0 00-.023-.057.085.085 0 00-.059-.024c-.826.024-1.79.076-2.648.172-.608.067-1.13.23-1.47.457-.35.234-.505.52-.505.93z"
                fill="#7A829D"
            />
        </svg>
    );
};

export default AaBlockIcon;
