import * as React from "react"

const SlideShowIcon = (props) => {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={48} height={48} rx={4} fill="#E1E7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 18.75l6.071 7.525L30.071 15 41 30H7l8.5-11.25z"
        fill="#7A829D"
      />
      <circle cx={18} cy={36} r={2} fill="#7A829D" />
      <circle cx={24} cy={36} r={2} fill="#BDC2D1" />
      <circle cx={30} cy={36} r={2} fill="#BDC2D1" />
    </svg>
  )
}

export default SlideShowIcon
