const showcaseNameValidationCriteria = input => {
  const formErrors = {};

  if (!input.title) {
    formErrors.title = "Required";
  } else {
    formErrors.title = "";
  }

  return formErrors;
};

export default showcaseNameValidationCriteria;
