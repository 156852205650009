import React, { useEffect, useState } from 'react';
import {
    Query,
    Builder,
    BasicConfig,
    Utils as QbUtils,
} from 'react-awesome-query-builder';

import PropTypes from 'prop-types';

import './queryBuilder.scss';
import { isEmpty } from 'lodash';

function QueryBuilder(props) {
    let queryDefaultValue = {
        id: QbUtils.uuid(),
        type: 'group',
        children1: {
            '88ab989b89ab-4cde-b012-3174fe035d83': {
                type: 'rule',
                properties: {
                    field: 'firstname',
                    operator: 'equal',
                    value: [null],
                    valueSrc: ['value'],
                    valueType: ['text'],
                },
            },
        },
        properties: { conjunction: 'AND', not: false },
    };
    const queryValue =
        props.value == null || Object.keys(props.value).length === 0
            ? queryDefaultValue
            : props.value;

    const queryFields = props?.fields?.AllFieldsUnderProject?.fields;

    const overWriteFieldKey = (
        oldKey,
        newKey,
        { [oldKey]: old, ...othersFields }
    ) => ({ [newKey]: old, ...othersFields });

    const createField = (field, label) => {
        return overWriteFieldKey('defaultFieldKey', field, {
            defaultFieldKey: {
                label: label,
                type: 'text',
                valueSources: ['value'],
                operators: [
                    'equal',
                    'like',
                    'not_equal',
                    'ends_with',
                    'is_empty',
                    'is_not_empty',
                    // 'not_in', not supported
                    //'between', not Supported
                    //'not_between', not supported
                ],
            },
        });
    };

    let overWriteFields = {};
    if (queryFields) {
        queryFields.map((value) => {
            if (!value?.field || !value?.label) return null;
            const result = createField(value?.field, value?.label);
            overWriteFields = { ...overWriteFields, ...result };
        });
    }

    const InitialConfig = BasicConfig;
    const defaultConfig = {
        ...InitialConfig,
        fields: {
            ...overWriteFields,
        },
        settings: {
            showNot: false,
        },
    };
    const [tree, setTree] = useState(
        QbUtils.checkTree(QbUtils.loadTree(queryValue), defaultConfig)
    );
    const [config, setConfig] = useState(defaultConfig);

    useEffect(() => {
        setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), defaultConfig));
    }, [props.value]);

    const renderBuilder = (props) => (
        <div className="query-builder-container">
            <div className="query-builder qb-lite ">
                <Builder {...props} />
            </div>
        </div>
    );

    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        setConfig(config);
        const jsonTree = QbUtils.getTree(immutableTree);
        console.log({ jsonTree });
        props.onChange(jsonTree);
    };
    return (
        <div>
            <Query
                {...config}
                value={tree}
                onChange={onChange}
                renderBuilder={renderBuilder}
            />
        </div>
    );
}
QueryBuilder.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    fields: PropTypes.object,
};

export default QueryBuilder;
