import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { signup, validateSignupOtp } from '../Api';
import { LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import styles from './index.module.scss';
import { generateValidation } from 'utils/inputValidation';

// Components

const SignupOtp = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        getValues,
    } = useForm();

    const history = useHistory();
    const form = useRef();
    const email = history?.location?.state?.email;
    const otp = history?.location?.state?.otp;
    const inputEl = useRef();

    const [disableInput, setDisableInput] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [sentLoading, setSentLoading] = useState(false);
    const [error, setError] = useState('');
    const [sentCounter, setSentCounter] = useState(0);

    const handleOnSubmit = async (data) => {
        try {
            setFormSubmitting(true);
            setDisableInput(true);
            setError('');
            const res = await validateSignupOtp({ code: data.otp, email });
            if (res && res.status) {
                return history.push('/signup/steps', { id: res.id });
            }
            throw res;
        } catch (error) {
            console.error(error);
            setError(
                error?.message || 'Something went wrong. Please try again.'
            );
        } finally {
            setFormSubmitting(false);
            setDisableInput(false);
        }
    };

    const handleSendAgain = async (e) => {
        try {
            e.preventDefault();

            if (!sentLoading) {
                setDisableInput(true);
                setSentLoading(true);
                setError('');
                reset({ otp: '' });

                const res = await signup({ email });
                if (res && res.status) {
                    form.current.reset();
                    return setSentCounter((prev) => prev + 1);
                }
                throw res;
            }
        } catch (error) {
            console.error(error);
            setError(error?.message);
        } finally {
            setSentLoading(false);
            setDisableInput(false);
        }
    };

    const handleOnChange = (e) => {
        setValue('otp', e.target.value, {
            shouldValidate: true,
        });
        setError('');
        if (
            e?.target?.value?.length > 5 &&
            !formSubmitting &&
            isEmpty(errors)
        ) {
            setDisableInput(true);
            handleSubmit(handleOnSubmit)();
        }
    };

    useEffect(() => {
        if (otp) {
            handleOnSubmit({ otp });
        }
    }, [otp]);

    if (!email) return history.push('/signup');

    return (
        <div className={styles.SignupOtpWrapper}>
            <div className={styles.Top}>
                <h2>Check your email</h2>
                {email && (
                    <p className={styles.SubTitle}>
                        We've sent {sentCounter > 0 ? 'another' : 'a'} 6-digit
                        OTP to <b>{email}</b>.
                        <br />
                        Please enter it below to confirm your email address.
                    </p>
                )}
                <ul className={styles.ChannelList}>
                    <li className={styles.ChannelItem} key={'otp'}>
                        <form
                            ref={form}
                            onSubmit={handleSubmit(handleOnSubmit)}
                        >
                            <label htmlFor="otp">
                                <input
                                    className={styles.Input}
                                    id={'otp'}
                                    name={'otp'}
                                    placeholder={'Enter 6-digit OTP'}
                                    maxLength={6}
                                    disabled={disableInput ? true : false}
                                    ref={register(
                                        'otp',
                                        generateValidation({
                                            name: 'number',
                                            inputLabel: 'OTP',
                                            isRequired: true,
                                            maxLength: 6,
                                        })
                                    )}
                                    onChange={handleOnChange}
                                />
                                {formSubmitting && (
                                    <>
                                        <LoadingOutlined />{' '}
                                    </>
                                )}
                            </label>
                            {errors['otp'] && (
                                <span className={styles.Error}>
                                    {errors['otp'].message}
                                </span>
                            )}
                        </form>
                        <p className={styles.SendAgain}>
                            Don't received the OTP?{' '}
                            <a
                                href="/signup/otp"
                                className={
                                    sentLoading || formSubmitting
                                        ? styles.DisabledLink
                                        : ''
                                }
                                onClick={handleSendAgain}
                            >
                                {sentLoading ? (
                                    <>
                                        <LoadingOutlined /> Sending...
                                    </>
                                ) : (
                                    'Send again'
                                )}
                            </a>
                        </p>
                        {error && <span className={styles.Error}>{error}</span>}
                    </li>
                </ul>
            </div>
            <div className={styles.Bottom}>
                <a
                    className={styles.Link}
                    title="Go back"
                    onClick={() => history.push('/signup')}
                >
                    Go back
                </a>
            </div>
        </div>
    );
};

export default React.memo(SignupOtp);
