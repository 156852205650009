import {
    CATCH_ERROR,
    UPDATE_VERSION,
    SET_PREVIEW_LOADING,
    SET_SUBMIT_LOADING,
} from './actionTypes';
import {
    saveLivepageSettings,
    saveWidgetSettings,
} from '../apis/saveLivepageSettings';
import { openNotification } from '../helpers/openNotification';

// To dispatch once there's an error
export const errorHandler = (error) => (dispatch) => {
    dispatch({
        type: CATCH_ERROR,
        payload: {
            error,
        },
    });
};

export const updateVersionTimeStamp =
    (type = '', livepageId = '', projectId, isPublished, callback) =>
    async (dispatch, getState) => {
        if (type === 'save' && livepageId && projectId) {
            try {
                let res;
                const selection = getState()?.header?.selection;
                dispatch({ type: SET_PREVIEW_LOADING, payload: true });
                dispatch({
                    type: SET_SUBMIT_LOADING,
                    payload: { status: true },
                });

                if (selection?.type === 'widget') {
                    res = await saveWidgetSettings(livepageId, projectId);
                } else {
                    res = await saveLivepageSettings(
                        livepageId,
                        projectId,
                        isPublished
                    );
                }

                const { status, message } = res;

                if (status) {
                    openNotification('success', { message });
                    dispatch({
                        type: UPDATE_VERSION,
                        payload: 'saved',
                    });
                    dispatch({ type: SET_PREVIEW_LOADING, payload: false });
                    dispatch({
                        type: SET_SUBMIT_LOADING,
                        payload: { status: false },
                    });
                } else throw new Error(message);
            } catch (error) {
                openNotification('error', { message: error.message });
                dispatch({ type: SET_PREVIEW_LOADING, payload: false });
                dispatch({
                    type: SET_SUBMIT_LOADING,
                    payload: { status: false },
                });
            } finally {
                callback && callback();
            }
        } else {
            dispatch({
                type: UPDATE_VERSION,
            });
        }
    };

export const updatePreviewLoader = (payload) => (dispatch) =>
    dispatch({ type: SET_PREVIEW_LOADING, payload });
