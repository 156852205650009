import * as React from "react"

function FullscreenIcon(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
    {/* fill={props?.isActive ? '#236DF2' : '#000000'} */}
      <path fill="url(#prefix__pattern0)" d="M0 0h22v22H0z" />
      <defs>
        <pattern
          id="prefix__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#prefix__image0" transform="scale(.04545)" />
        </pattern>
        <image
          id="prefix__image0"
          width={22}
          height={22}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAeUlEQVRIDe2SQQoAIQhFu1P3P5brBhcOw5MfEdRCZhH18fs0q5nZOLHaCagzC4B774OL42LcNT1pFCtJKx4JZgdKRxHGC4B5pV2dRrELYl4BsPo+vGpo5U+jUMYAcVf+++DoxHfV5cwjO54lfWNxZvEEpmFX/+D3oR+ViL6T6/lpkwAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

export default FullscreenIcon
