import validator from 'validator';

export const generateValidation = ({
    name,
    inputLabel = 'First Name',
    isRequired = true,
    pattern = '',
    maxLength = 254,
    minLength = 1,
}) => {
    try {
        const hookFormValidationTemplate = {
            required: {
                value: isRequired?.value || isRequired,
                message: isRequired?.message || `${inputLabel} is required.`,
            },
            minLength: {
                value: minLength,
                message: `Minimum length is ${minLength} characters.`,
            },
            pattern: {
                value: pattern,
                message: '',
            },
            maxLength: {
                value: maxLength,
                message: `Maximum length is ${maxLength} characters.`,
            },
        };

        const validationForEmail = {
            pattern: {
                value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
                message: `${inputLabel} is invalid`,
            },
        };

        const validationForDomain = {
            pattern: {
                // With asterisk allowed
                // value: /^(([\w\d]+\.)|(\*\.))+[\w\d]+$/,

                value: /^[\w|:.?/-]{0,254}$/,
                message: `${inputLabel} is invalid. Please avoid using special characters.`,
            },
        };

        const validationForPersonName = {
            pattern: {
                value: /^(?![&.',-/])[^_!¡?÷?¿\\+=@#$%ˆ*^"(){}|~<>;:[\]]{1,}$/,
                message: `Only period (.), comma (,), &, /, hyphen (-) and apostrophe (') are allowed as special characters, and they are not allowed as the first character.`,
            },
        };

        const validationForNumberOnly = {
            pattern: {
                value: /^[0-9]*$/,
                message: `Only numeric value`,
            },
        };

        const validationForDescription = {
            // _!¡?÷?¿\\\\+=@#$%ˆ*^"(){}|~<>;:[\\]
            pattern: {
                value: /^(?![&.\',-/])[^]{1,}$/,
                message: `Only period (.), comma (,), &, /, hyphen (-) and apostrophe (') are allowed as special characters, and they are not allowed as the first character.`,
            },
        };

        const validationForOrganizationName = {
            pattern: {
                value: /^(?![&().',-])[^_!¡?÷?¿/\\+=@#$%ˆ*{}|~<>;:[\]]{1,}$/,
                message:
                    'Only period (.), &, comma (,), hyphen (-), qoute ("), apostrophe (\'), caret (^), accent mark (`) and parenthesis () are allowed as special characters, but they are not allowed as the first character.',
            },
        };

        const validationForSlug = {
            pattern: {
                value: /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/,
                message: 'Can only contain a-z, 0-9, and dashes',
            },
        };

        const validationForUrl = {
            validate: (url) =>
                validator.isURL(url, { protocols: ['http', 'https'] }) ||
                'Is not a valid url',
        };

        const validationForPageTitle = {
            pattern: {
                value: /^[^`~!@#$%^*_+\=?;:".<>\\]+$/,
                message: `Is not a valid title`,
            },
        };

        if (name && inputLabel) {
            switch (name?.toLowerCase()) {
                case 'name':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForPersonName,
                    };
                case 'domain':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForDomain,
                    };
                case 'email':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForEmail,
                    };
                case 'number':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForNumberOnly,
                    };
                case 'companyname':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForOrganizationName,
                    };
                case 'description':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForDescription,
                    };
                case 'slug':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForSlug,
                    };
                case 'url':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForUrl,
                    };
                case 'title':
                case 'pagetitle':
                case 'pageTitle':
                    return {
                        ...hookFormValidationTemplate,
                        ...validationForPageTitle,
                    };
                default:
                    return hookFormValidationTemplate;
            }
        } else
            throw {
                name: 'Invalid parameters',
                error: '[name] and [inputLabel] is required',
            };
    } catch (error) {
        console.error('inputValidation :', error);
    }
};
