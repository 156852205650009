import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';

const Data = ({ occupied = 0, name = '', limit = 10, planName }) => {
    const history = useHistory();
    const { companyId } = useParams();
    const pathname = `/company/${companyId}/upgrade`;

    return (
        <div className={styles.Data}>
            <p>
                <span
                    className={styles.Calculation}
                    style={{
                        width: `${limit * 10}px`,
                    }}
                >
                    {[...Array(occupied).keys()].map((el) => (
                        <span
                            className={styles.Piece}
                            style={{
                                background:
                                    limit === occupied ? '#F34711' : '#aba74b',
                            }}
                        ></span>
                    ))}
                </span>
                <span className={styles.Message}>
                    <b>
                        {occupied}/{limit}
                    </b>
                    {planName === 'Pro' ? ' ' : ' free '} {name} used.
                </span>
                <button onClick={() => (window.location.href = pathname)}>
                    Upgrade
                </button>
            </p>
        </div>
    );
};

export default Data;
