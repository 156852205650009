import React from 'react';

export default function AddIcon({ stroke = '#0B1C2C' }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0.651855C8.82845 0.651855 9.5 1.32344 9.5 2.15186V6.65186H14C14.8285 6.65186 15.5 7.32344 15.5 8.15186C15.5 8.98031 14.8285 9.65186 14 9.65186H9.5V14.1519C9.5 14.9803 8.82845 15.6519 8 15.6519C7.17157 15.6519 6.5 14.9803 6.5 14.1519V9.65186H2C1.17158 9.65186 0.5 8.98031 0.5 8.15186C0.5 7.32342 1.17158 6.65186 2 6.65186H6.5V2.15186C6.5 1.32344 7.17157 0.651855 8 0.651855Z"
                fill={stroke || '#0B1C2C'}
            />
        </svg>
    );
}
