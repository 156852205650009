import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './inputs.scss';

const errorMessages = {
    firstname: {
        required: 'Name is required',
        maxLength: 'Name exceed max length(80)',
        minLength: 'Name min length(3)',
    },
    lastname: {
        required: 'Last Name is required',
        maxLength: 'Last Name exceed max length(80)',
        minLength: 'Last Name min length(3)',
    },
    email: {
        required: 'Email is required',
        pattern: 'Email type is not correct',
    },
};

const Inputs = ({ data, loading, onChangeData, onSubmitForm, onCancel }) => {
    const { register, errors, handleSubmit } = useForm();

    const [firstname, setFirstname] = useState(data?.firstname || '');
    const [lastname, setLastname] = useState(data?.lastname || '');
    const [email, setEmail] = useState(data?.email || '');
    const [inputError, setInputError] = useState({
        isFirstNameInvalid: false,
        isLastNameInvalid: false,
    });

    function isValidInput(inputValue) {
        const disallowedCharsRegex = /[|;$%@"<>.()/:{}\[\]\\^~`*!#=?_&,+]/;
        return disallowedCharsRegex.test(inputValue);
    }
    useEffect(() => {
        const updatedData = {
            ...data,
            firstname,
            lastname,
            email,
        };
        setInputError({
            ...inputError,
            isFirstNameInvalid: isValidInput(firstname),
            isLastNameInvalid: isValidInput(lastname),
        });
        onChangeData(updatedData);
    }, [firstname, lastname, email]);

    return (
        <>
            <div className="mt-3 form-group">
                <label>
                    First Name{' '}
                    <span className="font-weight-bold text-danger">*</span>
                </label>
                <input
                    className="form-control"
                    name="firstname"
                    defaultValue={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    ref={register({
                        required: true,
                        maxLength: 80,
                        minLength: 2,
                    })}
                />
                {['required', 'maxLength', 'minLength'].includes(
                    errors.firstname?.type
                ) && (
                    <div className="invalid-feedback d-block">
                        {errorMessages.firstname[errors.firstname?.type]}
                    </div>
                )}
                {inputError.isFirstNameInvalid && (
                    <div className="invalid-feedback d-block">
                        Enter valid first name.
                    </div>
                )}
            </div>
            <div className="form-group">
                <label>
                    Last Name{' '}
                    <span className="font-weight-bold text-danger">*</span>
                </label>
                <input
                    className="form-control"
                    name="lastname"
                    defaultValue={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    ref={register({
                        required: true,
                        maxLength: 80,
                        minLength: 1,
                    })}
                />
                {['required', 'maxLength', 'minLength'].includes(
                    errors.lastname?.type
                ) && (
                    <div className="invalid-feedback d-block">
                        {errorMessages.lastname[errors.lastname?.type]}
                    </div>
                )}
                {inputError.isLastNameInvalid && (
                    <div className="invalid-feedback d-block">
                        Enter valid last name.
                    </div>
                )}
            </div>
            <div className="form-group">
                <label>
                    Email{' '}
                    <span className="font-weight-bold text-danger">*</span>
                </label>
                <input
                    className="form-control"
                    name="email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                    ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
                />
                {['required', 'pattern'].includes(errors.email?.type) && (
                    <div className="invalid-feedback d-block">
                        {errorMessages.email[errors.email?.type]}
                    </div>
                )}
            </div>

            <div className="d-flex justify-content-end mt-5">
                <button
                    onClick={() => onCancel()}
                    className="myxp btn btn-sm btn-secondary mr-2"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit(onSubmitForm)}
                    className="myxp btn btn-sm btn-primary"
                    disabled={
                        loading ||
                        inputError.isFirstNameInvalid ||
                        inputError.isLastNameInvalid
                    }
                >
                    {loading ? 'Saving...' : 'Save'}
                </button>
            </div>
        </>
    );
};

Inputs.propTypes = {
    data: PropTypes.object,
    onChangeData: PropTypes.func,
    onSubmitForm: PropTypes.func,
    onCancel: PropTypes.func,
};

Inputs.defaultProps = {
    data: null,
};

export default Inputs;
