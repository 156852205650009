import { gql } from "apollo-boost";

const DELETE_SHOWCASE = gql`
  mutation DeleteShowcase($input: deleteShowcaseInput) {
    deleteShowcase(input: $input) {
      showcase {
        id
      }
    }
  }
`;

export default DELETE_SHOWCASE;