import React from 'react';

export default function InfoIcon({ stroke = 'black' }) {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 5.24C20.0008 5.10839 19.9756 4.97793 19.9258 4.85609C19.876 4.73426 19.8027 4.62344 19.71 4.53L15.47 0.290001C15.3766 0.19732 15.2658 0.123995 15.1439 0.0742302C15.0221 0.0244656 14.8916 -0.000759438 14.76 1.4555e-06C14.6284 -0.000759438 14.4979 0.0244656 14.3761 0.0742302C14.2543 0.123995 14.1435 0.19732 14.05 0.290001L11.22 3.12L0.290017 14.05C0.197335 14.1434 0.12401 14.2543 0.0742455 14.3761C0.0244809 14.4979 -0.000744179 14.6284 1.67143e-05 14.76V19C1.67143e-05 19.2652 0.105374 19.5196 0.29291 19.7071C0.480446 19.8946 0.7348 20 1.00002 20H5.24002C5.37994 20.0076 5.51991 19.9857 5.65084 19.9358C5.78176 19.8858 5.90073 19.8089 6.00002 19.71L16.87 8.78L19.71 6C19.8013 5.90308 19.8757 5.79153 19.93 5.67C19.9397 5.59029 19.9397 5.50971 19.93 5.43C19.9347 5.38345 19.9347 5.33655 19.93 5.29L20 5.24ZM4.83002 18H2.00002V15.17L11.93 5.24L14.76 8.07L4.83002 18ZM16.17 6.66L13.34 3.83L14.76 2.42L17.58 5.24L16.17 6.66Z"
                fill={stroke || 'black'}
            />
        </svg>
    );
}
