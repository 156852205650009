import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as ls from 'local-storage';
import { useSelector } from 'react-redux';

const qs = require('qs');

function GevmeRedirect() {
    const location = useLocation();
    const projects = useSelector((state) => state?.projects);
    const redirect =
        projects?.company?.id && projects?.currentProject?.id
            ? `/company/${projects?.company?.id}/project/${projects?.currentProject?.id}/dashboard/registration/gevme-integration/select-events`
            : window?.opener?.location?.href + '?gevme-events=1';

    const [error, setError] = useState('');

    const isAccessTokenIsPresent = () => {
        const queryString = qs.parse(location.hash, {
            ignoreQueryPrefix: true,
        });
        const accessToken = queryString['#access_token'];
        return accessToken;
    };

    useEffect(() => {
        window.onunload = () => {
            window.opener.location.href = redirect;
        };
        if (isAccessTokenIsPresent()) {
            ls.set('gevmeToken', isAccessTokenIsPresent());
            window.self.close();
        } else {
            setError('Sorry, something went wrong with logging in to GEVME.');
        }
    }, []);

    return (
        <div>
            <p>{error}</p>
            {!error && <p>Login success...</p>}
        </div>
    );
}

export default GevmeRedirect;
