import * as ls from 'local-storage';
import { store } from 'redux/store';
import { apiInterceptor } from 'utils/apiInterceptor';

export async function GetContentSecurity(projectId) {
    const auth = ls.get('auth');
    const user = store?.getState()?.user;

    if (user) {
        const authBearer = `Bearer ${auth?.access_token}`;

        const { response } = await apiInterceptor(
            `${process.env.REACT_APP_MYXP_API}/manager/livestream/content-security/${projectId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authBearer,
                },
            }
        );
        return await response.json();
    }
}
