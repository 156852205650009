import * as React from "react"

const MarginLinkedIcon = (props) => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.647 4.964l1.237-1.237a2.75 2.75 0 113.89 3.89l-1.769 1.767a2.75 2.75 0 01-3.889 0"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.354 12.035l-1.238 1.238a2.75 2.75 0 11-3.889-3.89l1.768-1.767a2.75 2.75 0 013.89 0"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MarginLinkedIcon
