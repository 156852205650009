import React from 'react';

export default function PauseIcon({ stroke = '#fff' }) {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 14C28 17.713 26.525 21.274 23.8995 23.8995C21.274 26.525 17.713 28 14 28C10.287 28 6.72601 26.525 4.10051 23.8995C1.475 21.274 0 17.713 0 14C0 10.287 1.475 6.72601 4.10051 4.1005C6.72601 1.475 10.287 0 14 0C17.713 0 21.274 1.475 23.8995 4.1005C26.525 6.72601 28 10.287 28 14ZM10.9375 8.75C9.73 8.75 8.75 9.73 8.75 10.9375V17.0625C8.75 17.6427 8.98047 18.1991 9.3907 18.6093C9.80094 19.0195 10.3573 19.25 10.9375 19.25C11.5177 19.25 12.0741 19.0195 12.4843 18.6093C12.8945 18.1991 13.125 17.6427 13.125 17.0625V10.9375C13.125 9.73 12.145 8.75 10.9375 8.75ZM17.0625 8.75C15.855 8.75 14.875 9.73 14.875 10.9375V17.0625C14.875 17.6427 15.1055 18.1991 15.5157 18.6093C15.9259 19.0195 16.4823 19.25 17.0625 19.25C17.6427 19.25 18.1991 19.0195 18.6093 18.6093C19.0195 18.1991 19.25 17.6427 19.25 17.0625V10.9375C19.25 9.73 18.27 8.75 17.0625 8.75Z"
                fill={stroke || '#fff'}
            />
        </svg>
    );
}
